import React, { Component } from 'react'
import Text, { TextProps } from './styled/Text'

export interface LabelProps extends TextProps {
    title: string
}
export default class Label extends Component<LabelProps> {

    render() {
        const { title, ...restProps } = this.props
        
        return <Text {...restProps}>{title}</Text>
    }

}