import { matchPath } from "react-router-dom";
import { CsvRow, LooseObject, SelectOption } from "./types"
import { SelectOptionTicker, Ticker } from "../actions/types/portfolioDesigner";

export const getPathsClientId = () => {
  const match = matchPath('/software/clients/:clientId/*', window.location.pathname);
  const { params } = match || { }
  const { clientId } = params || { }

  return clientId
}

export const hasClientIdChanged = (prevProps: LooseObject, props: LooseObject) => {
  const { clientId: prevClientId } = prevProps
  const { clientId } = props

  return clientId && clientId !== prevClientId
}

export const getQueryParams = () => {
  try {
    const search = window.location.search.substring(1)
    
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
  } catch(e: any) {

  }

  return { }
}
export const cleanPhoneNumber = (str: string) => { 
    //Filter only numbers from the input
    return ('' + str).replace(/\D/g, '')
}

export const formatPhoneNumber = (str: string = '') => {
  //Filter only numbers from the input
  const cleaned = cleanPhoneNumber(str)
  
  //Check if the input is of correct
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  
  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    const intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  
  return str;
}

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

//https://stackoverflow.com/a/65996386/1286412
export const copyToClipboard = (textToCopy: string) => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
  } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand('copy') ? res(true) : rej();
          textArea.remove();
      });
  }
}

interface DecimalFormatParameters {
  value?: number | string
  multiplier?: number
  decimalPlaces?: number
  defaultValue?: number
}

export const decimalFormat = ({ value = 0, multiplier = 100, decimalPlaces = 2, defaultValue = 0 }: DecimalFormatParameters) => {
  const safeValue = isNaN(value as number) ? defaultValue : parseFloat(value as string)
  
  return getDisplayValue(safeValue * multiplier, decimalPlaces)
}

export const legacyDecimalFormat = ({ value = 0, multiplier = 100, decimalPlaces = 2, defaultValue = 0 }: DecimalFormatParameters) => {
  const safeValue = isNaN(value as number) ? defaultValue : parseFloat(value as string)
  const fixedValue = (safeValue * multiplier).toFixed(decimalPlaces)
  const negativeZeroHack = parseFloat(fixedValue)
  
  return negativeZeroHack.toFixed(decimalPlaces)
}

export const numberToString = (value: string | number, defaultValue: number = 0) => {
  let stringValue = value
  if(typeof(value) != 'string' ) {
    stringValue = stringValue == null || stringValue == undefined || isNaN(stringValue as number) ? `${defaultValue}` : stringValue
  }
  
  return stringValue
}

export const getDisplayValue = (value: number, decimals: number = 2) => {
  const display = value.toFixed(10)
  const displayParts = display.split('.')

  if(decimals === 0) {
    return displayParts[0]
  }

  return `${displayParts[0]}.${displayParts[1].substring(0, decimals)}`
}

export const sanatizeNumericValue = (number: number | string) => {
  if(typeof(number) === 'number') {
    return number
  }

  if(!number) {
    return 0
  }

  const parseFunction = number.includes('.') ? parseFloat : parseInt
  const value = parseFunction(number.replace(/,/g, '').replace(/[^0-9.]/g,''), 10)
  if(isNaN(value)) {
      return 0
  }

  return value
}

export const commaClean = (value: string | number): string | number => {
  if (typeof(value) === 'string') {
    return value.replace(',', '') || '0'
  }

  if(!value) {
    return 0
  }

  return value
}


export const sleep = (timeInMs: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, timeInMs))
}


export const hasValue = (value?: string | any[]): boolean => {
  return (value && value.length > 0) ? true : false
}

export const validateEmailLegacy = (email: string): boolean => {
  return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
}

export const stringWithMaxLength = (str: string, maxLength: number) => {
  return str.length > maxLength ? `${str.substring(0, maxLength)}...`: str;
}

export async function csvFileToJson<T = CsvRow>(file: File, params: { columns?: string[], firstRowHeader?: boolean }): Promise<T[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
        resolve(csvStringToJson<T>({ csv: reader.result as string, ...params }))
    }
    reader.onerror = (e) => {
      reject(e)
    }
    reader.readAsText(file);
  })
}
export function csvStringToJson<T = CsvRow>(params: {csv: string, columns?: string[], firstRowHeader?: boolean }): T[] {
  const { csv, columns, firstRowHeader } = params
  if (!columns && !firstRowHeader) {
    throw new Error('Columns must be defined or firstRowHeader must be true')
  }
  let csvLines = csv.split('\n');
  let headers: string[]
  if (columns) {
    headers = columns
    if (headers.join(',').toLowerCase() === csvLines[0].toLowerCase()) {
      csvLines = csvLines.filter((_, index) => index !== 0)
    }
  } else {
    headers = csvLines[0].split(',')
    csvLines = csvLines.filter((_, index) => index !== 0)
  }

  const rows: T[] = csvLines.map((line: string) => {
    // Split each line by comma and create a new object with header-value pairs
    const values = line.split(',');
    const row: any = {};
    headers.forEach((header, index) => {
      row[header] = values[index];
    });

    return row;
  });

  return rows
};

export const jsonToCsvString = (data: any[]): string => {
  if (data.length === 0) {
    throw new Error('Data array is empty');
  }

  const headers = Object.keys(data[0]);
  const headersString = `${headers.join(',')}\n`;

  const lines = data.map((item) => {
    // Convert each object to a CSV row
    const values = Object.values(item).map((value) =>
      value ? value.toString() : '',
    );
    return `${values.join(',')}`;
  });

  return `${headersString}${lines.join('\n')}`;
};

export const debounce = (targetFunction: any, wait: number) => {
  let timeout: NodeJS.Timeout;

  return (...args: any[]) => {
      const later = () => {
          clearTimeout(timeout);
          targetFunction(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
  };
};

export const safeParseJSONObject = (json: string, defaultValue: any = {}) => {
  try {
    const value = JSON.parse(json)
    return value ?? defaultValue;
  } catch(e: any) {

  }

  return defaultValue
}


export const safeParseJSONArray = (json: string, defaultValue: any = []) => {
  try {
    const value = JSON.parse(json)
    return value ?? defaultValue;
  } catch(e: any) {

  }

  return defaultValue
}

export const getTickerSymbolAndDescription = (item: Ticker) => {
  const symbol = item.symbol.endsWith('-US') ? item.symbol_display : item.symbol
  const name = item.name ?? item.description
  const description = name ? ` - ${name}` : ''

  return { symbol, description }
}

export const getTickerSelectionOption = (item: Ticker): SelectOptionTicker => {
  const { symbol, description } = getTickerSymbolAndDescription(item)
    return {
      value: symbol,
      label: symbol + description,
      item,
      ticker: item,
    }
}

export const getTickerSelectionOptions = (items: Ticker[]): SelectOptionTicker[] => {
  return items.map((item) => getTickerSelectionOption(item));
}


export const roundDownToNearest15Minutes = (date: moment.Moment): moment.Moment => {
  const minutes = date.minutes();
  const remainder = minutes % 15;

  return date.subtract(remainder, 'minutes').seconds(0).milliseconds(0);
}

export const roundToNearest15Minutes = (date: moment.Moment): moment.Moment => {
  const minutes = date.minutes();
  const remainder = minutes % 15;
  
  if (remainder < 7.5) {
    return date.subtract(remainder, 'minutes').seconds(0).milliseconds(0);
  } else {
    return date.add(15 - remainder, 'minutes').seconds(0).milliseconds(0);
  }
}

export function didValuesStayEqual<T>(key: keyof T, itemA: T, itemB: T) {
  const valueA = itemA[key];
  const valueB = itemB[key];

  return valueA === valueB;
}

export function didValuesChange<T>(key: keyof T, itemA: T, itemB: T) {
  const valueA = itemA[key];
  const valueB = itemB[key];

  return valueA !== valueB;
}

export function camelCaseToWords(input: string): string {
  // This regular expression finds all occurrences of a lowercase letter
  // followed by an uppercase letter, and inserts a space between them
  const result = input.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Split the result into words, capitalize the first letter of each word, and join them back
  return result
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}


export const formatNumberAsPercentage = (value: number, locales: string | string[] = 'en-US', maximumFractionDigits: number = 2): string => {
  const formatter = new Intl.NumberFormat(locales, {
      style: 'percent',
      maximumFractionDigits,
  });

  return formatter.format(value);
}

export const openLink = (url: string, name = url) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const sanitizeNumericInput = (input: string): string => {
  // First, remove any character that is not a digit, dash, or period
  let sanitizedInput = input.replace(/[^0-9.-]/g, '');

  // Ensure the string starts with a valid character before proceeding
  if (sanitizedInput.length === 0) {
      return '';
  }

  // Remove any dashes that are not at the start
  sanitizedInput = `${sanitizedInput[0]}${sanitizedInput.substring(1).replace(/-/g, '')}`;

  // Split the string at the period to handle the decimal part
  const parts = sanitizedInput.split('.');

  // Join the first part with the rest (if any), removing additional periods
  sanitizedInput = parts.shift() + (parts.length > 0 ? '.' : '') + parts.join('');

  return sanitizedInput;
};


export const snakeCaseToWords = (input: string): string => {
  return input.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}