import React from 'react'
import { Table as MUITable, TableHead, TableRow, TableBody, TableCell, TableBodyProps, TableProps as MUITableProps, TableCellProps as MUITableCellProps, TableHeadProps } from '@mui/material'
import { FONT_STYLES_BY_TYPE } from './styled/Text'

export const TableHeaderCell = ({ sx = { }, children, ...restProps}: TableCellProps) => {
    return (
        <TableCell sx={{
            backgroundColor: (theme) => theme.palette.secondary.main2,
            borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
            color: (theme) => theme.palette.primary.main,
            font: FONT_STYLES_BY_TYPE.table_header,
            ...sx,
        }} {...restProps}>{children}</TableCell>
    )
}

export interface TableHeadCellProps extends MUITableCellProps{
    key?: string
}
export interface TableHeadRowProps extends MUITableCellProps{
    key?: string
    columns: TableHeadCellProps[]
}

export interface TableProps extends MUITableProps {
    bodyProps?: TableBodyProps
    headerColumns?: TableHeadCellProps[]
    headerRows?: TableHeadRowProps[]
    headProps?: TableHeadProps
}

export const Table = ({ sx = { }, children, headerColumns = [], headerRows = [], bodyProps, headProps = {}, ...restProps}: TableProps) => {
    const headers = headerColumns.length > 0 ? [...headerRows, { key: '', columns: headerColumns }] : headerRows
    const hasHead = headers?.length > 0
    return (
        //@ts-ignore
        <MUITable sx={{ 
            borderSpacing: '0 !important',
            borderCollapse: 'collapse !important',
            border: (theme) => `1px solid ${theme.palette.primary.main}`,
            ...sx ,
            '& td': {
                border: (theme) => `1px solid ${theme.palette.primary.main}`,
                ...(sx && sx['& td'] ? sx['& td'] : { } ),
            },}}
            {...restProps}>
            {hasHead && 
                <TableHead sx={{ border: (theme) => `1px solid ${theme.palette.primary.main}`,}} {...headProps}>
                    {headers.map((row, rowIndex) => (
                        <TableRow key={row.key ?? rowIndex}>
                            {row.columns.map((header) => <TableHeaderCell key={`${header.children}`} {...header} />)}
                        </TableRow>
                    ))}
                </TableHead>
            }
            <TableBody {...bodyProps}>
                {children}
            </TableBody>
        </MUITable>
    )
}