import React, { Component} from "react"
import Needle from './assets/Needle.svg'
import Text from "../common/styled/Text"
import { Box, SxProps, Theme } from "@mui/material"
import { BaseProps } from "../types"

interface GaugeState {
  rawId: string
  title: string
  titleSx: SxProps<Theme>
  value: number
  valueAngle: number
  innerCircleSx?: SxProps<Theme>
  hidden?: boolean
  needleAngleOffset?: number
  angleStep: number
  angleLength: number
  startingAngle: number
  sectionAngleSize: number
  startingValueAngle: number
  endingAngle: number
}

interface GaugeProps extends BaseProps {
  value: number
  data: any
  title: string
  style?: SxProps<Theme>
  titleSx?: SxProps<Theme>
  innerCircleSx?: SxProps<Theme>
  startingAngle?: number
  endingAngle?: number
  hidden?: boolean
  gaugeSVG: any
  showNeedleValue?:boolean
  type?: string
  titleGlossaryTerm?: string
}

class Gauge extends Component <GaugeProps, GaugeState> {

  constructor(props) {
    super(props)
    const { value, data, title, titleSx, innerCircleSx = { }, startingAngle: propStartingAngle, endingAngle: propEndingAngle, hidden } = props
    const angleStep = data ? data.findIndex(({ label }) => value == label) : 0

    const startingAngle = propStartingAngle || -120
    const endingAngle = propEndingAngle || 120
    const angleLength = Math.abs(startingAngle) + Math.abs(endingAngle)

    const sectionAngleSize = (angleLength / data.length)
    const startingValueAngle = startingAngle + (sectionAngleSize / 2)
    const valueAngle = (startingValueAngle - 0) + (angleStep * sectionAngleSize)
    const needleAngleOffset = data && data[angleStep] ? data[angleStep].needleAngleOffset : 0

    this.state = {
      rawId: `gaugeId${new Date().getTime()}`,
      startingAngle,
      endingAngle,
      angleLength,
      sectionAngleSize,
      startingValueAngle,
      value,
      angleStep,
      valueAngle,
      title,
      titleSx,
      innerCircleSx,
      hidden,
      needleAngleOffset,
    }
  }

  componentDidUpdate(prevProps) {
    const { startingAngle: prevStartingAngle, endingAngle: prevEndingAngle } = prevProps
    const { startingAngle, endingAngle, value, data, hidden } = this.props
    const { value: currentValue } = this.state

    if(hidden != undefined && hidden != this.state.hidden) {
      this.setState({ hidden })
    }

    if((startingAngle != undefined && endingAngle != undefined) && (prevStartingAngle != startingAngle || prevEndingAngle != endingAngle)) {
      this.updateGaugeAngles()
    }

    
    if(value != currentValue) {
      const { sectionAngleSize, startingValueAngle } = this.state
      
      const angleStep = data ? data.findIndex(({ label }) => value == label) : 0
      const valueAngle = (startingValueAngle - 0) + (angleStep * sectionAngleSize)
      const needleAngleOffset = data && data[angleStep] ? data[angleStep].needleAngleOffset : 0

      this.setState({ valueAngle, value, angleStep, needleAngleOffset: needleAngleOffset || 0 })
    }
  }

  updateGaugeAngles = () => {
    const { startingAngle, endingAngle } = this.props
    const safeStartingAngle = startingAngle || -120
    const safeEndingAngle = endingAngle || 120
    const angleLength = Math.abs(safeStartingAngle) + Math.abs(safeEndingAngle)


    this.setState({ 
      startingAngle: safeStartingAngle,
      endingAngle: safeEndingAngle,
      angleLength
    })
  }

  render(){
    const { title, titleSx = { }, value, valueAngle, innerCircleSx = { }, hidden, needleAngleOffset, angleStep } = this.state
    const { data, sx = { }, gaugeSVG: GaugeSVG, showNeedleValue = true, type, titleGlossaryTerm } = this.props
    
    const hiddenGaugeStyle = hidden ? { display: 'none' } : { }
    const showNeedle = data && data[angleStep]

    return(
        <Box sx={{ 
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            height: '360px',
            width: '400px',
            ...sx
          }}>
            
              <Box className={`${this.state.rawId}`} sx={hiddenGaugeStyle}>
                <img src={GaugeSVG} alt={'Gauge'} />
                {!hidden &&
                <Box sx={{ position: 'absolute', top: '250px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <Box sx={{
                      color: '#171443',
                      fontSize: '34px',
                      fontWeight: '300',
                      lineHeight: '40px',
                      display: 'flex',
                      textAlign: 'center',
                      zIndex: '25 !important',
                      flexDirection: 'column',
                      ...titleSx
                    }}>
                      {type !== undefined && <div>{type}</div>}
                      <Text type={'h2'} glossaryTerm={titleGlossaryTerm}>{title}</Text>
                    </Box>
                </Box>
                }
              </Box>
            
            {!hidden &&
              <Box sx={{
                  position: 'absolute',
                  backgroundColor: 'white',
                  boxShadow: '0px -2px 2px #00000054',
                  top: '200px',
                  left: '190px',
                  marginTop: '-70px',
                  marginLeft: '-70px',
                  height: '160px',
                  width: '160px',
                  borderRadius: '80px',
                  zIndex: 4,
                  ['@media print']: {
                    boxShadow: 'unset',
                  },
                ...innerCircleSx
              }}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    {showNeedle && 
                      <Box sx={{
                        '& img': {
                            display: 'block',
                            marginTop: '-35px',
                            marginLeft: '0px',
                            transformOrigin: '21px 103px',
                            transform: `rotate(${valueAngle + (needleAngleOffset || 0)}deg)`
                        }
                      }}>
                        <img src={Needle} alt={'Gauge Needle'} />
                      </Box>
                      }
                    {showNeedleValue && <Text>{value}</Text>}
                  </Box>
              </Box>
            }
            
        </Box>
    )
  }
}

export default Gauge;