import React, { FunctionComponent } from 'react'
import ResultGague, { BasicResultGaugeProps } from './ResultGauge'
import svg from './assets/RiskAversionGauge.svg'

export const RESULT_MAP = {
    1.5: "LOW",
    2: "LOW",
    3: "MED",
    4.5: "MED",
    7: "HIGH",
    12: "HIGH" 
}
const RiskAversionGauge: FunctionComponent<BasicResultGaugeProps> = ({ type, value = 0, style, className, titleGlossaryTerm }) => {
    return <ResultGague
                className={className}
                svg={svg}
                style={style}
                type={type}
                title={`Risk Aversion`}
                titleGlossaryTerm={titleGlossaryTerm}
                value={RESULT_MAP[value]}
                leftLabel={'HIGH COMFORT WITH VOLATILITY'}
                rightLabel={'LOW COMFORT WITH VOLATILITY'}
                showNeedleValue={false}
            />
}


export default RiskAversionGauge
