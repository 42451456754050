import { ModelExpectedReturns } from '../actions/types/financialPlanAssumptions';
import { ReduxAction } from '.';
import { 
    FINANCIAL_PLAN_ASSUMPTIONS, FINANCIAL_PLAN_ASSUMPTIONS_CUSTOM_MODELS, FINANCIAL_PLAN_ASSUMPTIONS_UPDATE
} from '../actions/types/actions';

export interface FinancialPlanAssumptionsState {
    inflation_rate: number;
    simplifyModels: ModelExpectedReturns[],
    customModels: ModelExpectedReturns[],
}

const INITIAL_PROFILE_STATE = {
    inflation_rate: 0,
}

const INITIAL_STATE: FinancialPlanAssumptionsState = {
  ...INITIAL_PROFILE_STATE,
  simplifyModels: [],
  customModels: [],
};

export default (state = INITIAL_STATE, action: ReduxAction): FinancialPlanAssumptionsState => {
  const { payload, type } = action

  switch (type) {
    case FINANCIAL_PLAN_ASSUMPTIONS:
        return { ...state, inflation_rate: payload.inflation_rate, simplifyModels: payload.simplifyModels, customModels: payload.customModels }
    case FINANCIAL_PLAN_ASSUMPTIONS_CUSTOM_MODELS:
        return { ...state, customModels: payload }
    case FINANCIAL_PLAN_ASSUMPTIONS_UPDATE:
        return { ...state, ...payload }
    default:
        return state;
  }
};

