import { SelectOption } from "../../common/types";
import { StandardApiResponse } from "./responses";

export enum ExpirationPeriod {
    NONE = '',
    ONE_MONTH = 'ONE_MONTH',
    THREE_MONTHS = 'THREE_MONTHS',
    SIX_MONTHS = 'SIX_MONTHS',
    ONE_YEAR = 'ONE_YEAR',
}

export const EXPERIATION_PERIOD_OPTIONS: SelectOption[] = [
    { label: 'None', value: ExpirationPeriod.NONE },
    { label: '1 Month', value: ExpirationPeriod.ONE_MONTH},
    { label: '3 Month', value: ExpirationPeriod.THREE_MONTHS},
    { label: '6 Month', value: ExpirationPeriod.SIX_MONTHS},
    { label: '1 Year', value: ExpirationPeriod.ONE_YEAR},
]
  
export interface ApiKey {
    id: string;
    key: string;
    secret?: string;
    active: boolean;
    createdAt: string;
    expiresAt: string | null;
}


export interface RetrieveKeysResult extends StandardApiResponse {
    keys: ApiKey[]
}
export interface CreateKeysResult extends StandardApiResponse {
    key: ApiKey
}