import React, { Component } from 'react'
import { onSLRPage } from '../../actions/standardLivingRisk';
import { connect } from 'react-redux';
import TabFooter from '../Software/TabFooter';
import { BaseProps } from '../types';
import { Box, SxProps } from '@mui/material';
import { Text } from '../common/styled/Text';
import { onComponentSizeType, SizeAwareBox } from '../common/styled/SizeAwareBox';
import { Theme } from '@emotion/react';
import Button from '../common/Button';
import { withClientParameter, WithClientParameterProps } from '../common/withClientParameter';

export interface SLRBaseProps {
    onComponentSize?: onComponentSizeType
}

interface CardProps extends BaseProps, WithClientParameterProps {
    onComponentSize?: onComponentSizeType
    title: string | React.ReactNode
    hideHeader?: boolean,
    hideFooter?: boolean,
    containerSx?: SxProps<Theme>;
    showAddProfileHistory?: boolean
    financial_plan_source: string
}

class Card extends Component <CardProps> {

    render() {
        const { title, children, hideHeader = false, hideFooter = false, sx = { }, containerSx = {}, onComponentSize, showAddProfileHistory, clientId, financial_plan_source } = this.props
        return (
            <div>
                <SizeAwareBox onComponentSize={onComponentSize} sx={sx}>
                    {!hideHeader &&
                        <>
                            <Text type='h2'>{title}</Text>
                            <hr />
                        </>
                    }
                    <Box sx={containerSx}>
                        {children}
                    </Box>
                </SizeAwareBox>
            </div>
        )
    }

}

const mapStateToProps = ({ session, standardLivingRisk }) => {
    const { activeIndex, financial_plan_source } = standardLivingRisk
    const { views } = session

    return { activeIndex, views, financial_plan_source }
}

export default withClientParameter(connect(mapStateToProps, { onSLRPage })(Card))