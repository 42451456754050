import { Box, TableCell, TableRow, Typography } from '@mui/material'
import React, { Component } from 'react'
import QuestionChoice from './QuestionChoice'

export interface QuestionSelection {
    questionNumber: number
    choiceLetter: string
    value: number
}
interface QuestionTableProps  {
    questionNumber: number
    onChoiceSelected: (selection: QuestionSelection) => void
    question: string | React.ReactNode
    selectedChoice: number
    choiceAText: string | React.ReactNode
    choiceBText: string | React.ReactNode
}

interface QuestionTableState {
    selectedChoice: string
}

class QuestionTable extends Component <QuestionTableProps, QuestionTableState> {
    constructor(props) {
        super(props)
        this.state = {
            selectedChoice: ''
        }
    }

    onChoiceSelected = (choiceLetter, value) => {
        this.setState({ selectedChoice: choiceLetter })
        const { questionNumber, onChoiceSelected } = this.props
        if(typeof(onChoiceSelected) === 'function') {
            this.props.onChoiceSelected({ questionNumber, choiceLetter, value })
        }
    }

    render() {
        const { questionNumber, question, selectedChoice, choiceAText, choiceBText } = this.props

        return (
            <>
                <TableRow sx={{
                    borderLeft: (theme) => `1px solid ${theme.palette.borders.level7}`,
                    borderRight: (theme) => `1px solid ${theme.palette.borders.level7}`,
                    borderBottom: `unset`,
                }}>
                    <TableCell colSpan={2} sx={{
                        backgroundColor: (theme) => theme.palette.background.level8,
                        fontFamily: "Montserrat",
                        color: (theme) => theme.palette.primary.dark,
                        fontWeight: 500,
                        fontSize: (theme) => theme.typography.pxToRem(28),
                        padding: '8px',
                        borderBottom: (theme) => `unset`,
                    }}>{questionNumber}. {question}</TableCell>
                </TableRow>
                <TableRow>
                    <QuestionChoice contentSx={{ borderRight: (theme) => `1px solid ${theme.palette.borders.level9}` }} choiceLetter={'A'} checked={selectedChoice == 1} choiceContent={choiceAText} onChoiceSelected={this.onChoiceSelected} value={1} />
                    <QuestionChoice choiceLetter={'B'} checked={selectedChoice == 0} choiceContent={choiceBText} onChoiceSelected={this.onChoiceSelected} value={0} />
                </TableRow>
            </>
        )
    }

}

export default QuestionTable