import { Component } from 'react'
import { Box, ListItem } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'


import { Ticker, TickerAllocation, ETFTicker, TickerProxy, MetricDataMapped, SnapshotItems } from '../../actions/types/portfolioDesigner';
import { BaseParameterListRowProps } from '../common/ParameterListRow';
import Input from '../common/Input';
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import { getTickerProxy, removeTickerProxy, updateTickerProxy } from '../../actions/portfolioDesigner';
import TickerInput from './TickerInput';
import { connect } from 'react-redux';
import { addError } from '../../actions/notifications';
import TickerProxyInput from './TickerProxyInput';
import TooltippedTickerInput from './TooltippedTickerInput';

interface ComponentProps {
    item: TickerAllocation
    index: number
    showMCTR: boolean
    snapshots: SnapshotItems
    snapshotLoading: boolean
    snapshotTargetItem: TickerAllocation
    snapshotTargetItemUpdateType: 'weight' | 'ticker'
    metrics: MetricDataMapped
    onItemChanged: (item: TickerAllocation, index: number, type: 'ticker' | 'weight') => void
    onTickerProxyUpdated?: (ticker?: Ticker ) => void
    onTickerProxyEnabledToggled?: (enabled: boolean) => void
    onItemRemoved: (item: TickerAllocation, index: number) => void
    onFocusNext?: (currentView: PortfolioListIem) => void
    proxyRef?: (view: PortfolioListIem, index: number) => void
}
interface PortfolioListIemProps extends ComponentProps {
    addError: typeof addError
}

class PortfolioListIem extends Component<PortfolioListIemProps & {forwardRef?: React.Ref<any>}, PortfolioListIemState> {
    inputRef: any
    tickerInputRef: any
    tickerProxyInputRef: any

    componentDidMount() {
        if (this.props.proxyRef) {
            this.props.proxyRef(this, this.props.index);
        }
    }

    onChange = (value: string | number) => {
        const { item, index, onItemChanged } = this.props
        const weight = value as number

        onItemChanged({ ...item, weight }, index, 'weight')
    }

    onItemRemoved = () => {
        const { item, index, onItemRemoved } = this.props

        if(onItemRemoved) {
            onItemRemoved(item, index)
        }
    }

    onSelectedTicker = async (ticker?: Ticker ) => {
        const { item, onItemChanged, index } = this.props;

        onItemChanged({ ...item, ticker }, index, 'ticker');
        if (ticker && this.inputRef) {
            this.inputRef.focus();
        }
        this.setState({ tickerProxy: undefined })
    }

    onTickerProxyEnabledToggled = async(enabled: boolean) => {
        if (this.props.onTickerProxyEnabledToggled) {
            this.props.onTickerProxyEnabledToggled(enabled)
        }
    }

    onSelectedTickerProxy = async(ticker?: Ticker ) => {
        if (this.props.onTickerProxyUpdated) {
            this.props.onTickerProxyUpdated(ticker)
        }

        if (ticker && this.inputRef) {
            this.inputRef.focus();
        }
    }

    onEnterPressed = () => {
        this.blur();
        if (this.props.onFocusNext) {
            this.props.onFocusNext(this)
        }
    }
    
    focus = () => {
        this.tickerInputRef.focus()
    }
    
    blur = () => {
        if (this.inputRef) {
            this.inputRef.blur()
        }
    }
    
    focusWeightInput = () => {
        if (this.inputRef) {
            this.inputRef.focus()
        }
    }

    render() {
        const { item, snapshots, metrics, snapshotLoading, snapshotTargetItem, snapshotTargetItemUpdateType } = this.props
        const { ticker, weight } = item

        return (
                <ListItem sx={{ position: 'relative', border: 'unset', borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`, padding: '.175rem' }}>
                    <Box
                        sx={{
                        display: 'flex',
                        flex: 1,
                        width: '100%',
                        flexDirection: 'row',
                        marginRight: '1rem',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <TooltippedTickerInput
                            containerSx={{ flex: 1, marginRight: '2.5rem' }}
                            onTickerInputRef={(view: any) => this.tickerInputRef = view}
                            value={ticker}
                            snapshots={snapshots}
                            metrics={metrics}
                            snapshotLoading={snapshotLoading}
                            snapshotTargetItem={snapshotTargetItem}
                            snapshotTargetItemUpdateType={snapshotTargetItemUpdateType}
                            showMCTR
                            onTickerSelected={this.onSelectedTicker}
                        />
                        {ticker && 
                            <TickerProxyInput
                                ticker={ticker}
                                onTickerProxyUpdated={this.onSelectedTickerProxy}
                                onEnableToggled={this.onTickerProxyEnabledToggled}
                            />
                        }
                        <Input
                            ref={(view) => this.inputRef = view}
                            sx={{ width: '5rem', height: '3rem', marginRight: '3rem', font: FONT_STYLES_BY_TYPE.text_tiny, textAlign: 'center' }}
                            accuracy={3}
                            step={.1}
                            value={weight}
                            placeholder={'0.00'}
                            decimalPlaces={2}
                            onChange={this.onChange}
                            onEnterPressed={this.onEnterPressed}
                            onlyNotifyOnBlur
                        />
                        <Box sx={{ display: 'flex', width: '2.5rem', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                            <FontAwesomeIcon size={'2x'} icon={faTimes} onClick={this.onItemRemoved}/>
                        </Box>
                    </Box>
                </ListItem>
        )
    }

}
export default connect<any, any, ComponentProps, any>(null, { addError })(PortfolioListIem as any);
