import { Component } from 'react'

import { Ticker, SelectOptionTicker } from '../../actions/types/portfolioDesigner';
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import { getTickers, searchTicker } from '../../actions/portfolioDesigner';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete'
import { BaseProps } from '../types';
import { Box, SxProps, Theme as MuiTheme } from '@mui/material';
import { Theme } from '../../theme/theme';
import { debounce, getTickerSelectionOption, getTickerSelectionOptions } from '../../common/utils';

export interface TickerInputProps extends BaseProps {
    containerSx?: SxProps<Theme & MuiTheme>;
    value?: Ticker ;
    placeholder?: string;
    onFocusNext?: (currentView: TickerInput) => void;
    onTickerSelected: (item?: Ticker ) => void;
}

interface TickerInputState {
    allowOpen: boolean
    currentInputText?: string
    selectedItem: SelectOptionTicker | null
    tickers: SelectOptionTicker[]
}

class TickerInput extends Component<TickerInputProps, TickerInputState> {
    autocompleteInputRef: any

    constructor(props: TickerInputProps) {
        super(props)
        let selectedItem = null
        if (props.value) {
            selectedItem = getTickerSelectionOption(props.value)
        }

        this.state = {
            allowOpen: false,
            tickers: [],
            selectedItem,
        }
    }

    componentDidUpdate(prevProps: Readonly<TickerInputProps>, prevState: Readonly<TickerInputState>, snapshot?: any): void {
        const { value: prevValue } = prevProps
        const { value } = this.props
        const { selectedItem } = this.state

        if (value) {
            if(value.id !== prevValue?.id) {
               const selectedItem = getTickerSelectionOption(value)

                this.setState({ selectedItem })
            }
        } else if(selectedItem !== null){
            this.setState({ selectedItem: null });
        }
    }

    onSelectedTicker = async (_: any, value: any) => {
        const { onTickerSelected } = this.props;

        onTickerSelected(value?.ticker)
        if (value) {
            const tickers = await getTickers(value?.ticker.symbol_display)
            const selectedTicker = tickers.find((ticker) => ticker.id === value?.ticker.id)
            this.setState({ selectedItem: { ...value, ticker: selectedTicker } })

            if (selectedTicker) {
                onTickerSelected({...selectedTicker, forceUpdate: true})
            }
        }

    }

    onLoadOptions = async(ticker: string): Promise<void> => {
        if (this.state.currentInputText !== ticker) {
            return;
        }

        const tickers = await searchTicker(ticker);
        const tickerOptions = getTickerSelectionOptions(tickers);
        if (this.state.currentInputText !== ticker) {
            return;
        }

        this.setState({ tickers: tickerOptions })
    }

    onInputChange = async (_: any, value: string, reason: any) => {
        if (reason === 'reset') {
            return;
        }

        const hasEnoughText = value?.length > 1
        this.setState({ allowOpen: hasEnoughText, currentInputText: value }, async() => {
            if (hasEnoughText) {
                await this.onLoadOptions(value)
            }
        })
    }

    onClose = () => {
        this.setState({ allowOpen: false })
    }

    onEnterPressed = () => {
        if (this.props.onFocusNext) {
            this.props.onFocusNext(this)
        }
    }
    
    focus = () => {
        this.autocompleteInputRef.getElementsByTagName('input')[0].focus()
    }

    onTickerKeyDown = (e: any) => {
        if(['Backspace', 'Delete'].includes(e.key)) {
            const { onTickerSelected } = this.props;

            this.setState({ selectedItem: null })
            onTickerSelected(undefined)
        }
    }

    render() {
        const { allowOpen, tickers, selectedItem } = this.state
        const { value, placeholder = 'Enter Ticker', sx = { }, containerSx = { } } = this.props
        const invalidTickerStyle = value && (!value.id || value.id.toString() === '0') ? { color: 'red' } : { }
        return (
            <Box
                sx={{
                display: 'flex',
                flex: 1,
                position: 'relative',
                ...containerSx,
            }}>            
                <Autocomplete
                    disablePortal
                    options={tickers}
                    sx={{ 
                        width: '100%',
                        marginRight: '1.5rem',
                        ...sx,
                        '& .MuiInputBase-root': {
                            padding: 0,
                            ...invalidTickerStyle,
                            ...(sx ? (sx as any)['& .MuiInputBase-root'] : { }),
                        },
                        '& .MuiAutocomplete-endAdornment': {
                            display: 'none',
                            ...(sx ? (sx as any)['& .MuiAutocomplete-endAdornment'] : { }),
                        },
                        '& fieldset': {
                            border: 'unset',
                            ...(sx ? (sx as any)['& fieldset'] : { }),
                        },
                        '& input': {
                            font: FONT_STYLES_BY_TYPE.text_small,
                            ...(sx ? (sx as any)['& input'] : { }),
                        },
                        '& .MuiAutocomplete-popper': {
                            font: (theme) => `${FONT_STYLES_BY_TYPE.text_small(theme)} important!`,
                            ...(sx ? (sx as any)['& .MuiAutocomplete-popper'] : { }),
                        },
                    }}
                    openOnFocus={false}
                    filterOptions={(x) => x}
                    open={allowOpen}
                    placeholder={placeholder}
                    value={selectedItem}
                    onClose={this.onClose}
                    onInputChange={this.onInputChange}
                    isOptionEqualToValue={(option, value) => {
                        return option.ticker?.id === value.ticker?.id
                    }}
                    autoHighlight
                    onChange={this.onSelectedTicker}
                    PopperComponent={({ children, ...props }) => {
                        if (!props.open) {
                            return null;
                        }

                        return <Box {...props} sx={{ ...props.style, position:'absolute', top: '4rem', left: '0rem' }}>{(children as any)}</Box>
                    }}
                    renderInput={(params) => {
                        return  <TextField placeholder={placeholder} onKeyDown={this.onTickerKeyDown} ref={(view) => this.autocompleteInputRef = view} sx={{ font: FONT_STYLES_BY_TYPE.text_large, }} {...params} />
                    }}
                />
            </Box>
        )
    }

}

export default TickerInput