import React, { FunctionComponent } from 'react';
import { BaseProps } from '../types';
import { AccountPanel } from './AccountPanel';
import { Box, Typography } from '@mui/material';
import { TextFieldItem } from '../common/FormField';
import Loader from 'react-spinners/ClipLoader';
import { getOrionConnected, removeOrionCredentials, saveOrionCredentials } from '../../actions/portfolioManager';
import { Button } from '../common/Button';
import Text from '../common/Text';
import SecurityInformation from './SecurityInformation';

interface State {
    connected: boolean;
    initialLoading: boolean;
    loading: boolean;
    username: string;
    password: string
}

interface Props extends BaseProps {}

class OrionCredentials extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            initialLoading: true,
            loading: false,
            connected: false,
            username: '',
            password: ''
        }
    }

    async componentDidMount() {
        const connected = await getOrionConnected()
        this.setState({ connected, initialLoading: false })
    }

    setEmail = (username: string) => {
        this.setState({ username })
    }

    setPassword = (password: string) => {
        this.setState({ password })
    }

    onSaveCredentials = async() => {
        const { username, password } = this.state;
        this.setState({ loading: true })
        const connected = await saveOrionCredentials(username, password)
        this.setState({ loading: false, connected })
    }

    onRemoveCredentials = async() => {
        this.setState({ loading: true })
        const connected = await removeOrionCredentials()
        this.setState({ loading: false, connected })
    }

    render() {
        const { username, password, initialLoading, connected, loading } = this.state;

        if (initialLoading) {
            return (
            <AccountPanel
                title={'Orion Credentials'}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '2.5rem'
                }}>
                    <Loader />
                </Box>
            </AccountPanel>
            )
        }

        return (
            <AccountPanel
                title={'Orion Credentials'}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '2.5rem'
                }}>
                    {connected && 
                        <Box>
                            <SecurityInformation />
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '2rem'}}>
                                <Button 
                                    title={'Remove Credentials'} 
                                    onClick={this.onRemoveCredentials}
                                    loading={loading}
                                    inlineLoading
                                />
                            </Box>
                        </Box>
                    }
                    {!connected &&
                    <>
                        <SecurityInformation><Text>Your Orion Credentials Are Encrypted and Securely Stored</Text></SecurityInformation>
                        <TextFieldItem
                            sx={{ 
                                width: {
                                    xs: '100%',
                                    md: '35rem'
                                }
                            }}
                            type={'text'}
                            title={'Username'}
                            value={username}
                            onValueChange={this.setEmail}
                            inline
                        />
                        <TextFieldItem
                            sx={{ 
                                width: {
                                    xs: '100%',
                                    md: '35rem'
                                }
                            }}
                            type={'password'}
                            title={'Password'}
                            value={password}
                            onValueChange={this.setPassword}
                            inline
                        />
                        <Box>
                            <Button 
                                title={'Save'} 
                                onClick={this.onSaveCredentials}
                                loading={loading}
                                inlineLoading
                            />
                        </Box>
                    </>
                    }
                </Box>
            </AccountPanel>
        )
    }
}


export default OrionCredentials