import { 
    HIDE_USER_UTILITIES,
    SHOW_USER_UTILITIES,
    CLIENTS_RETRIEVED,
    CLIENT_DATA_RETRIEVED,
    LOGOUT,
    PROFILE_RESET,
    USERS_RECEIVED,
} from '../actions/types/actions';
import { Client } from '../actions/types/clientManagement';
import { User } from '../actions/types/users';

export interface UserManagementState {
    showModal: boolean,
    userId?: string
    clients: Client[],
    clientLogs: string[],
    clientFiles: string[],
    users: User[]
}

const INITIAL_STATE: UserManagementState = {
    showModal: false,
    userId: undefined,
    clients: [],
    clientLogs: [],
    clientFiles: [],
    users: [],
};

export default (state = INITIAL_STATE, action): UserManagementState => {
  const { payload, type } = action

  switch (type) {
    case SHOW_USER_UTILITIES:
        return { ...state, showModal: true, userId: payload }
    case HIDE_USER_UTILITIES:
        return { ...state, ...INITIAL_STATE }
    case CLIENTS_RETRIEVED:
        return { ...state, clients: payload }
    case CLIENT_DATA_RETRIEVED:
        return { ...state, ...payload }
    case USERS_RECEIVED:
        return { ...state, users: payload }
    case PROFILE_RESET:
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

