import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableCell, TableRow } from '@mui/material'
import { CircleActionButton } from '../../common/styled/ShapedActionButton'
import { AssetSet } from '../../../actions/types/customAssets'
import Text from '../../common/Text'

interface Props {
    onRemoveAsset: (id: number) => void
    item: AssetSet
}

class AssetItemComponent extends Component<Props> {

    onRemoveAsset = () => {
        const { item } = this.props
        const { id } = item
        if(this.props.onRemoveAsset) {
            this.props.onRemoveAsset(id)
        }
    }

    render() {
        const { item } = this.props
        const { name  } = item

        return (
            <TableRow>
                <TableCell sx={{
                    padding: '.5rem',
                    width: '5rem',
                }}>
                    <CircleActionButton
                        type={'filled'}
                        action={'delete'}
                        onClick={this.onRemoveAsset}
                    />
                </TableCell>
                <TableCell>
                    <Text>{name}</Text>
                </TableCell>
            </TableRow>
        )
    }

}

export default AssetItemComponent