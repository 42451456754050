import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPen, faCheck } from '@fortawesome/free-solid-svg-icons'
import { Box, ListItem } from '@mui/material';

import Text from './Text';
import { TextField } from './FormField';
import { BaseProps } from '../types';

export interface BaseParameterListRowProps extends BaseProps {
    onItemRemoved: (item: any, index: number) => void
    onItemChanged?: (item: any, value: any) => void
    item: any
    index: number
}

export interface ParameterListRowProps extends BaseParameterListRowProps {
    onItemRemoved: (item: string, index: number) => void
    onItemChanged?: (item: any, value: any) => void
    item: any
    index: number
}

interface State {
    inEditMode: boolean
    itemText: string
}

class ParameterListRow extends Component <ParameterListRowProps, State> {
    constructor(props) {
        super(props)
        this.state = {
            itemText: '',
            inEditMode: false,
        }
    }

    onItemTextChange = (e) => {
        const value = e.target.value

        this.setState({ itemText: value })
    }

    onItemRemoved = () => {
        const { onItemRemoved, item, index } = this.props

        if(onItemRemoved) {
            onItemRemoved(item, index)
        } 
    }

    onEditingItemSave = () => {
        const { item } = this.props
        const { itemText } = this.state
        
        if(this.props.onItemChanged) {
            this.props.onItemChanged(item, itemText)
        }
        
        this.setState({ inEditMode: false })
    }

    onEditingItem = (editItem) => {
        const itemText = typeof(editItem) == 'object' ? editItem.value : `${editItem}`

        this.setState({ itemText, inEditMode: true })
    }


    render() {
        const { item } = this.props
        const { inEditMode, itemText } = this.state

        const value = typeof(item) == 'object' ? item.value : `${item}`

        return (
            <ListItem sx={{ position: 'relative', border: (theme) => `1px solid ${theme.palette.primary.main}`, padding: '1.5rem' }}>
                {inEditMode && <TextField sx={{ width: '100%', marginRight: '4.5rem' }} value={itemText} onChange={this.onItemTextChange} />}
                {!inEditMode && <Text>{value}</Text>}
                <Box 
                    sx={{ 
                        position: 'absolute',
                        top: inEditMode ? '2.5rem' : '1.5rem',
                        bottom: 0,
                        right: '2rem',
                        display: 'flex',
                        cursor: 'pointer',
                        '-webkit-appearance': 'button',
                    }}
                >
                    {inEditMode &&
                        <Box sx={{ marginRight: '.5rem ' }}><FontAwesomeIcon icon={faCheck} onClick={this.onEditingItemSave}/></Box>
                    }

                    {!inEditMode &&
                        <Box sx={{ marginRight: '.5rem ' }}><FontAwesomeIcon className={`ListEditItem`} icon={faPen} onClick={() => this.onEditingItem(item)} /></Box>
                    }

                    <FontAwesomeIcon icon={faTimes} onClick={this.onItemRemoved}/>
                </Box>
            </ListItem>    
        )
    }

}

export default ParameterListRow