import { flags } from "../../features";
import { Column } from "../common/DataTable";

export const columns: Column[] = [
    {
        label: 'Last Name',
        field: 'last_name'
    },{
        label: 'First Name',
        field: 'first_name'
    },{
        label: 'Income',
        field: 'annual_income'
    },{
        label: 'Email',
        field: 'email'
    },{
        label: 'Risk Assessment',
        field: 'risk_assessment'
    },
    {
        label: 'Portfolio Manager',
        field: 'portfolio_manager',
        requiresScopes: ['pd:portfolio_manager']
    },
    {
        label: 'Financial Plan',
        field: 'financial_plan'
    },{
        label: 'Actions',
        field: 'actions'
    }
].filter((item) => item.field);