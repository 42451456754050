import React, { Component } from 'react'
import { connect } from 'react-redux'

import { yearsToFinancialGoalUpdate, yearsOfFinancialGoalUpdate, discountRateUpdate, inflationRateUpdate } from '../../actions/standardLivingRisk'

import Card, { SLRBaseProps } from './Card'
import RiskAversionGauge from '../Gauge/RiskAversionGauge'
import LossAversionGauge from '../Gauge/LossAversionGauge'
import SLRGauge from './SLRGauge'
import { RiskParameters } from '../../actions/types/riskPreferences'
import { Box } from '@mui/material'

interface RecommendedParametersProps extends SLRBaseProps {
    recommended: RiskParameters
    preferred: RiskParameters
    hideHeader?: boolean
    hideFooter?: boolean
}

class RecommendedParameters extends Component <RecommendedParametersProps> {

    constructor(props) {
        super(props)
    }

    render() {
        const { recommended, preferred, onComponentSize } = this.props
        const { lambda: recommendedLambda, gamma: recommendedGamma } = recommended
        const { lambda: preferredLambda, gamma: preferredGamma } = preferred

        return (
            <Card title={'ALM-Adjusted Risk Preferences'} hideHeader={this.props.hideHeader} hideFooter={this.props.hideFooter} onComponentSize={onComponentSize} containerSx={{ overflow: 'hidden' }}>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    marginTop: '-25.5rem',
                    marginBottom: '-25.5rem',
                    width: '125rem',
                    height: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '& .preferred-gauges .tooltip-container, .recommended-gauges .tooltip-container': {
                        transform: 'scale(1.5)',
                    }
                }}>
                    <Box className={'preferred-gauges'} sx={{ transform: 'scale(.5)' }}>
                        <RiskAversionGauge className={'risk-aversion slrGauges'} value={preferredGamma} />
                        <LossAversionGauge className={'loss-aversion slrGauges'} value={preferredLambda} />
                    </Box>
                    <Box sx={{ fontSize: (theme) => theme.typography.pxToRem(35) }}>
                        X
                    </Box>
                    <Box sx={{ height: '100%', transform: 'scale(.8)' }}>
                        <SLRGauge />
                    </Box>
                    <Box sx={{ fontSize: (theme) => theme.typography.pxToRem(35) }}>
                        =
                    </Box>
                    <Box className={'recommended-gauges'}  sx={{ transform: 'scale(.5)' }}>
                        <RiskAversionGauge className={'risk-aversion slrGauges'} type={'ALM-Adjusted'} value={recommendedGamma} />
                        <LossAversionGauge className={'loss-aversion slrGauges'} type={'ALM-Adjusted'} value={recommendedLambda} />
                    </Box>
                </Box>
            </Card>
        )
    }

}

const mapStateToProps = ({ investmentPortfolio }) => {
    const { recommended, preferred } = investmentPortfolio
    
    return {
        recommended,
        preferred
    }
}

export default connect(mapStateToProps, { yearsToFinancialGoalUpdate, yearsOfFinancialGoalUpdate, discountRateUpdate, inflationRateUpdate })(RecommendedParameters)