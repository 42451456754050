import { Box } from "@mui/material"
import { Component } from "react"
import { connect } from 'react-redux'
import { SelectOption } from "../../common/types"
import { addError, addSuccessNotification } from "../../actions/notifications"
import { withClientParameter } from "../common/withClientParameter"

import { withWindowDimensions, WithWindowDimensionsProps } from "../common/withWindowDimensions"
import { Select } from "../common/styled/Select"
import { updateModelsForType, updateParameter, updateSelectedModelForType, loadAccountOptions, loadSelectedAccountAssets } from "../../actions/portfolioManager"
import Text from "../common/Text"
import { PortfolioManagerConfigurationType } from "../../actions/types/portfolioManager"
import { ModelItemOption, PortfolioDesignerConfiguration, TickerAllocation, TickerAllocationFlat } from "../../actions/types/portfolioDesigner"
import CommonTable from "../common/CommonTable"
import Button from "../common/Button"
import moment from 'moment';
import { Bucket } from "../../actions/types/standardLivingRisk"
import { loadModelOptions } from "../../actions/portfolioDesigner"
import { didValuesStayEqual, didValuesChange } from "../../common/utils"
import { ReduxState } from "../../reducers"

const COLUMNS = [
    {
        key: 'Ticker',
        children: 'Ticker',
    },
    {
        key: 'Target %',
        children: 'Target %',
    },
    {
        key: 'Account %',
        children: 'Account %',
    },
    {
        key: 'Target - Account',
        children: 'Target - Account',
    },
];

export interface PortfolioManagerProps extends WithWindowDimensionsProps {
    accountOptions: SelectOption[],
    portfolioModelOptions: SelectOption[],
    accountSelectedOption: SelectOption | null,
    portfolioSelectedOption: SelectOption | null,
    discrepancyTable: any,
    needsPortfolio: number,
    wantsPortfolio: number,
    wishesPortfolio: number,
    bands: any,
    needsPortfolioModel: ModelItemOption | null
    wantsPortfolioModel: ModelItemOption | null
    wishesPortfolioModel: ModelItemOption | null
    portfolio_manager?: string
    portfolio_manager_client_id?: number
    loadSelectedAccountAssets: typeof loadSelectedAccountAssets,
    loadAccountOptions: typeof loadAccountOptions,
    loadModelOptions: typeof loadModelOptions,
    addError: (title: string, message: string) => any,
    addSuccessNotification: (notification: { title?: string, message: string }) => any,
    updateModelsForType: (type: PortfolioManagerConfigurationType, models: SelectOption[]) => any,
    updateSelectedModelForType: (type: PortfolioManagerConfigurationType, selectedOption: SelectOption) => any,
    updateParameter: (payload: any) => any
}
export interface PortfolioManagerState {
    needsPortfolioModel: SelectOption
}

const TOTAL_PORTFOLIO_OPTION: SelectOption = { label: <Text sx={{ fontWeight: 'bold' }}>Total Portfolio</Text>, value: 'Total Portfolio', item: {} }
const TOTAL_PORTFOLIO_MODEL_KEYS = ['needs', 'wants', 'wishes'];

const PORTFOLIO_NEEDS_MODEL_LABEL = 'Custom Treasury Ladder';

interface TotalWeights {
    needs?: number
    wants?: number
    wishes?: number
}
interface PortfolioTotalWeights {
    [key: string]: TotalWeights
}

interface PortfolioTotalItem extends TotalWeights {
    label: string;
}
class PortfolioManager extends Component<PortfolioManagerProps, PortfolioManagerState> {
    mainView?: any
    resultsRef?: any

    constructor(props: PortfolioManagerProps) {
        super(props)
        this.state = {
            needsPortfolioModel: {
                label: PORTFOLIO_NEEDS_MODEL_LABEL,
                value: PORTFOLIO_NEEDS_MODEL_LABEL,
                item: {} 
            }
        };
    }

    componentDidMount(): void {
        this.props.loadModelOptions();
        this.props.loadAccountOptions();
    }

    componentDidUpdate(prevProps: Readonly<PortfolioManagerProps>, prevState: Readonly<PortfolioManagerState>, snapshot?: any): void {
        if(this.props.portfolioSelectedOption) {
            const optionsChanged = didValuesChange<PortfolioManagerProps>('accountSelectedOption', this.props, prevProps) || didValuesChange<PortfolioManagerProps>('portfolioSelectedOption', this.props, prevProps);
            const portfoliosChanged = didValuesChange<PortfolioManagerProps>('needsPortfolio', this.props, prevProps) || didValuesChange<PortfolioManagerProps>('wantsPortfolio', this.props, prevProps) || didValuesChange<PortfolioManagerProps>('wishesPortfolio', this.props, prevProps);
            const plansChanged = didValuesChange<PortfolioManagerProps>('needsPortfolioModel', this.props, prevProps) || didValuesChange<PortfolioManagerProps>('wantsPortfolioModel', this.props, prevProps) || didValuesChange<PortfolioManagerProps>('wishesPortfolioModel', this.props, prevProps);
            const requiresTableUpdate = optionsChanged || portfoliosChanged || plansChanged;

            if (requiresTableUpdate) {
                this.prepareDiscrepencyTable()
            } 
        }

        if( didValuesChange<PortfolioManagerProps>('bands', this.props, prevProps) ||
            didValuesChange<PortfolioManagerProps>('needsPortfolio', this.props, prevProps)) {
                this.loadUsTreasuryModels()
        }

        if( didValuesChange<PortfolioManagerProps>('portfolio_manager', this.props, prevProps) ||
            didValuesChange<PortfolioManagerProps>('portfolio_manager_client_id', this.props, prevProps)) {
                this.props.loadAccountOptions()
        }
    }

    prepareDiscrepencyTable = () => {
        if (this.props.portfolioSelectedOption?.value === 'Total Portfolio') {
            this.updateTotalDiscrepencyTable();

            return;
        }
        
        
        this.updateRegularDiscrepencyTable();
    }

    updateTotalDiscrepencyTable = () => {
        const { needsPortfolioModel, wantsPortfolioModel, wishesPortfolioModel, needsPortfolio, wantsPortfolio, wishesPortfolio } = this.props;
        if (!needsPortfolioModel || !wantsPortfolioModel || !wishesPortfolioModel) {
            return;
        }

        const tickers = [needsPortfolioModel.item, wantsPortfolioModel.item, wishesPortfolioModel.item].reduce((accum: PortfolioTotalWeights, item: PortfolioDesignerConfiguration, modelIndex: number) => {
            const update = item.portfolio.reduce((updateAccum: PortfolioTotalWeights, tickerAllocation: TickerAllocation | TickerAllocationFlat) => {
                const symbol = typeof tickerAllocation.ticker === 'string' ? tickerAllocation.ticker : tickerAllocation.ticker?.symbol ?? '';
                const currentItem = accum[symbol] || { needs: 0, wants: 0, wishes: 0 };

                return { ...updateAccum, [symbol]: { ...currentItem, [TOTAL_PORTFOLIO_MODEL_KEYS[modelIndex]]: tickerAllocation.weight || 0 } }
            }, {...accum} as PortfolioTotalWeights);

            return { ...accum, ...update }
        }, {} as PortfolioTotalWeights);

        const total = needsPortfolio+wantsPortfolio+wishesPortfolio;
        const totals = Object.keys(tickers).filter(key => key).map((key: string) => {
            const { needs, wants, wishes }: TotalWeights = tickers[key] || {};
            const needsTotal = (needsPortfolio/total) * (needs ?? 0);
            const wantsTotal = (wantsPortfolio/total) * (wants ?? 0);
            const wishesTotal = (wishesPortfolio/total) * (wishes ?? 0);

            const value = needsTotal + wantsTotal + wishesTotal;
            
            return { title: key, key, values: [Number(value).toFixed(2), Number(0).toFixed(2), Number(value - 0).toFixed(2)] }
        });

        this.props.updateParameter({ discrepancyTable: totals })
    }

    updateRegularDiscrepencyTable = () => {
        const { accountSelectedOption, portfolioSelectedOption } = this.props;
        const { item: account } = accountSelectedOption || { item: null };
        const { item: modelPortfolio } = portfolioSelectedOption || { item: null };
        let mergedPayload = account?.portfolio?.reduce((accum: any, curr: TickerAllocation) => {
            const symbol = typeof curr.ticker === 'string' ? curr.ticker : curr.ticker?.symbol ?? '';
            const currentItem = accum[symbol] || { id: curr.id, ticker: curr.ticker };

            return { ...accum, [symbol]: { ...currentItem, account: curr.weight || 0 } }
        }, {});

        mergedPayload = modelPortfolio?.portfolio?.reduce((accum: any, curr: TickerAllocation) => {
            const symbol = typeof curr.ticker === 'string' ? curr.ticker : curr.ticker?.symbol ?? '';
            const currentItem = accum[symbol] || { id: curr.id, ticker: curr.ticker };

            return { ...accum, [symbol]: { ...currentItem, model: curr.weight || 0 } }
        }, {...mergedPayload});

        const payload = Object.values(mergedPayload || {}).filter((item: any) => item.ticker).map((item: any) => {
            const { ticker, account, model, id } = item;
            const symbol = typeof ticker === 'string' ? ticker : ticker?.symbol ?? '';
            const symbolDisplay = ticker?.symbol_display || '';
            const display = symbol?.endsWith('-US') ? symbolDisplay : symbol
            const accountWeight = account || 0;
            const modelWeight = model || 0;

            return { title: display, key: `${id}-${display}`,  values: [Number(modelWeight).toFixed(2), Number(accountWeight).toFixed(2), Number((modelWeight ?? 0) - (accountWeight ?? 0)).toFixed(2)] }
        });
        this.props.updateParameter({ discrepancyTable: payload })
    }

    loadUsTreasuryModels = () => {
        const { needsPortfolio, bands = [] } = this.props
        let treasuryBuckets = bands || []
        if (treasuryBuckets.length > 0) {
            treasuryBuckets = treasuryBuckets.slice(0, treasuryBuckets.length - 1)
        }

        const needsPortfolioItem = {
            id: PORTFOLIO_NEEDS_MODEL_LABEL,
            type: "benchmark",
            name: PORTFOLIO_NEEDS_MODEL_LABEL,
            rebalancingFrequency: "quarterly",
            portfolio: treasuryBuckets.map(({ needCashFlows, end }: Bucket) => {
                const needCashFlowsKeys = Object.keys(needCashFlows || {})
                const starting = needCashFlowsKeys.length > 0 ? parseFloat(needCashFlows[needCashFlowsKeys[0]]) : 0
                
                const year = moment().add('year', end).format('YYYY');
                const label = `US Treasury December ${year}-US`;

                return {
                    id: label,
                    weight: Number((starting / needsPortfolio) * 100).toFixed(2),
                    ticker: {
                        symbol: label,
                        symbol_display: label,
                        description: label,
                    }
                }
            })
        }

        this.setState({
            needsPortfolioModel: {
                label: PORTFOLIO_NEEDS_MODEL_LABEL,
                value: PORTFOLIO_NEEDS_MODEL_LABEL,
                item: needsPortfolioItem,
            }
         })
    }

    onSelectedAccountChanged = (item: SelectOption) => {
        this.props.updateSelectedModelForType('account', item);
        this.props.loadSelectedAccountAssets(item);
    }

    onSelectedModelPortfolioOptionChanged = (item: SelectOption) => {
        const { needsPortfolioModel, wantsPortfolioModel, wishesPortfolioModel } = this.props;
        if (item.value === 'Total Portfolio' && (!needsPortfolioModel || !wantsPortfolioModel || !wishesPortfolioModel)) {
            this.props.addError('Error', 'Please Specify Portfolios For All Buckets in the Financial Plan tab');
            return;
        }

        this.props.updateSelectedModelForType('portfolio', item);

    }

    tableToCsv = (): string => {
        const headers = COLUMNS.map(({ children }) => children);
        const separator = ',';
        const csvContent =
        headers.join(separator) +
          '\n' +
          this.props.discrepancyTable
            .map((item: any) => {
              return [item.title, ...item.values].join(separator);
            })
            .join('\n');
      
        return csvContent;
    };

    onExport = () => {
        const csv = this.tableToCsv();
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const name = typeof this.props.portfolioSelectedOption?.label === 'string' ? this.props.portfolioSelectedOption?.label : this.props.portfolioSelectedOption?.value;
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', `${name}.${new Date().getTime()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        const { accountOptions, portfolioModelOptions, accountSelectedOption, portfolioSelectedOption, discrepancyTable } = this.props;

        return (
            <Box ref={view => this.mainView = view } sx={{ display: 'flex', flexDirection: 'row', minHeight: '50rem' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '1.5rem', marginLeft: '2rem' }}>
                                <Text sx={{ width: '15rem', marginRight: '1rem' }}>Target Portfolio</Text>
                                <Select sx={{ width: '35rem', marginRight: '1rem' }} options={[TOTAL_PORTFOLIO_OPTION, ...portfolioModelOptions]} value={portfolioSelectedOption} onChange={this.onSelectedModelPortfolioOptionChanged} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '1.5rem' }}>
                                <Text sx={{ width: '15rem', marginRight: '1rem' }}>Account</Text>
                                <Select sx={{ width: '35rem', marginRight: '1rem' }} options={accountOptions} value={accountSelectedOption} onChange={this.onSelectedAccountChanged} />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                                {accountOptions && <Button sx={{ height: '5rem', width: '15rem' }} onClick={this.onExport}>Export</Button>}
                            </Box>
                    </Box>
                    {portfolioSelectedOption &&
                        <Box sx={{ width: '110rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <CommonTable
                                sx={{ 
                                    '& th': {
                                        textAlign: 'center',
                                    },
                                    '& td': {
                                        padding: 'unset',
                                        paddingTop: '.5rem',
                                        paddingBottom: '.5rem',
                                        textAlign: 'center',
                                    },
                                    marginTop: {
                                        xs: '2rem',
                                        md: 'unset', 
                                    },
                                }}
                                valueCellProps={{
                                    sx: {
                                        textAlign: 'center'
                                    }
                                }}
                                headerColumns={COLUMNS}
                                rows={discrepancyTable}
                            />
                        </Box>
                    }
                </Box>
            </Box>
        )
    }
}

const mapStateToProps = ({ portfolioManager, portfolioDesigner, standardLivingRisk, session }: ReduxState ) => {
    const {
        accountOptions,
        accountSelectedOption,
        portfolioSelectedOption,
        discrepancyTable,
     } = portfolioManager
     const { profile } = session
     const { portfolio_manager, portfolio_manager_client_id } = profile
     const portfolioModelOptions = portfolioDesigner[`portfolioModels`]

     const { portfolioModels, financial_plan_needsModels: financialPlanNeedsModels } = portfolioDesigner;
     const { needsPortfolio, wantsPortfolio, wishesPortfolio, bands, needs_model_id, wants_model_id, wishes_model_id } = standardLivingRisk

    return {
        accountOptions,
        portfolioModelOptions,
        accountSelectedOption,
        portfolioSelectedOption,
        discrepancyTable,
        needsPortfolio,
        wantsPortfolio,
        wishesPortfolio,
        bands,
        portfolio_manager,
        portfolio_manager_client_id,
        needsPortfolioModel: financialPlanNeedsModels?.find(({ value }) => value === needs_model_id) || null,
        wantsPortfolioModel: portfolioModels.find(({ value }) => value === wants_model_id) || null,
        wishesPortfolioModel: portfolioModels.find(({ value }) => value === wishes_model_id) || null,
     }
}

export default withWindowDimensions(withClientParameter(connect(mapStateToProps, { loadAccountOptions, loadSelectedAccountAssets, loadModelOptions, addError, addSuccessNotification, updateModelsForType, updateSelectedModelForType, updateParameter })(PortfolioManager)))
