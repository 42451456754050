import React, { Component } from 'react'
import ConfirmModal from '../../common/ConfirmModal'
import { TextFieldItem } from '../../common/FormField'
import { FormRow } from '../../common/FormRow'

interface Props {
    visible: boolean
    onSave: (name: string, title: string) => void
    onCancel: () => void
}

interface State {
    name: string
    title: string
}

export default class extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = { 
            name: '',
            title: '',
        }
    }

    clearState = () => {
        this.setState({ name: '', title: '' })
    }

    componentDidUpdate(prevProps: Props) {
        const { visible: prevVisible } = prevProps
        const { visible } = this.props

        if(visible !== prevVisible) {
            this.clearState()
        }
    }
    
    onNameChanged = (name: string) => {
        this.setState({ name })
    }
    
    onTitleChanged = (title: string) => {
        this.setState({ title })
    }

    onSave = () => {
        const { name, title } = this.state

        this.props.onSave(name, title)
    }

    render() {
        const { visible, onCancel } = this.props
        const { name, title } = this.state

        return (
            <ConfirmModal
                visible={visible}
                sx={{ width: '100%' }}
                title={`Create Copy Item`}
                confirmLabel={'Save'}
                cancelLabel={'Cancel'}
                onConfirm={this.onSave}
                onCancel={onCancel}>
                    <FormRow>
                        <TextFieldItem
                            title={'Name'}
                            value={name}
                            onValueChange={this.onNameChanged}
                        />
                        <TextFieldItem
                            title={'Title'}
                            value={title}
                            onValueChange={this.onTitleChanged}
                        />
                    </FormRow>
            </ConfirmModal>
        )
    }
}