import { Box } from '@mui/material';
import React, { Component } from 'react'
import RecommendedParameters from './RecommendedParameters';

class StandaloneModeration extends Component{

    render() {
        return (
            <Box sx={{ height: 'auto', overflow: 'hidden', position: 'relative' }}>
                <RecommendedParameters />
            </Box>
        )
    }

}


export default StandaloneModeration