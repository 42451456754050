import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Text from './Text';
import Tab from '@mui/material/Tab';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  sx?: any;
}

export const getTabTitleProps = (index: number, title: string) => {
  return {
    id: `tab-${index}`,
    index,
    label: <Text>{title}</Text>,
    'aria-controls': `tabs-tabpanel-${index}`,
  };
}

export interface TabTitleProps {
  index: number;
  title: string;
}

export const TabTitle = ({index, title}: TabTitleProps) => {
  return (
    <Tab id={`tab-${index}`} label={<Text>{title}</Text>} aria-controls={`tabs-tabpanel-${index}`} />
  )
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, sx = {}, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={{
        ...sx,
      }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </Box>
  );
}