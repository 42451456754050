import { Component } from 'react'
import { connect } from 'react-redux';

import { clientFinancialPlanOnLoad } from '../../actions/clientFinancialPlan'
import { PageContents } from '../Page/PageContents';
import PagerContainer from '../RiskPreferences/PagerContainer';
import Page from '../Page/Page';
import { onPageChange } from '../../actions/session';
import FinancialGoal from './FinancialGoal';
import Assets from './Assets';
import Liabilities from './Liabilities';
import { hasClientIdChanged } from '../../common/utils';
import { AccountLogo } from '../common/styled/AccountLogo';
import ReturnHome from './ReturnHome';

export interface State {
    logo?: string
}

export interface ClientRiskPreferencesProps {
    onPageChange: (key: string, index: number) => void
    clientFinancialPlanOnLoad: () => void
    submitSuccess?: boolean
    loading?: boolean
    activeIndex: number
}

class ClientRiskPreferences extends Component <ClientRiskPreferencesProps, State> {
    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
        this.props.clientFinancialPlanOnLoad()
    }

    componentDidUpdate(prevProps: ClientRiskPreferencesProps) {
        if(hasClientIdChanged(prevProps, this.props)) {
            this.props.clientFinancialPlanOnLoad()
        }
    }

    componentWillUnmount(): void {
        this.props.onPageChange('financialPlan', 0)
    }

    render() {
        const { activeIndex } = this.props

        return (
            <Page sx={{ 
                hight: '100%'
            }} contentsSx={{ display: 'flex', flexDirection: 'column', marginBottom: '5rem', paddingBottom: '2rem' }} disableResize>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem', marginBottom: '4rem' }}>
                    <AccountLogo style={{ maxWidth: 300, maxHeight: 60 }} />
                </div>
                <PageContents sx={{ paddingBottom: '4rem', overflow: 'unset', overflowY: 'visible' }}>
                    <PagerContainer activeIndex={activeIndex} gesturesEnabled={false} >
                        <FinancialGoal />
                        <Assets />
                        <Liabilities />
                        <ReturnHome />
                    </PagerContainer>
                </PageContents>
            </Page>
        )
    }
}

const mapStateToProps = ({ standardLivingRisk, session }) => {
    const { loading, submitSuccess } = standardLivingRisk
    const { firmWebsite, financialPlanActiveIndex } = session

    return {
        loading,
        activeIndex: financialPlanActiveIndex,
        submitSuccess,
        firmWebsite,
    }
}

export default connect(mapStateToProps, { clientFinancialPlanOnLoad, onPageChange })(ClientRiskPreferences)