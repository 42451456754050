import { Box, SxProps, Theme } from '@mui/material'
import React, { FunctionComponent } from 'react'
import { ResultsTableData } from '../../actions/types/portfolioDesigner'
import CommonTable from '../common/CommonTable'
import { ClipLoader } from 'react-spinners'

export interface ResultTableProps {
    results: ResultsTableData
    loadingBenchmark?: boolean;
    loadingPortfolio?: boolean;
    containerSx?: SxProps<Theme>
    tableSx?: SxProps<Theme>
    customRows?: any[]
    excludeKeys?: string[]
    noDataMessage?: string
}

export const ResultTable: FunctionComponent<ResultTableProps> = ({
    containerSx = { },
    tableSx = { },
    customRows,
    results,
    excludeKeys,
    loadingBenchmark,
    loadingPortfolio,
    noDataMessage,
}) => {
    const [columns, setColumns] = React.useState<any[]>([])
    const [rows, setRows] = React.useState<any[]>([])

    React.useEffect(() => {
        const { name, data } = results
        const { Benchmark, Strategy } = data
        const columns = [
            { key: name, children: name, colSpan: 1 },
            {
                key: 'Benchmark',
                children: 'Benchmark',
                colSpan: 1,
                sx: {
                    textAlign: 'center'
                }
            },{
                key: 'Portfolio',
                children: 'Portfolio',
                colSpan: 1,
                sx: {
                    textAlign: 'center'
                }
            }
        ]
        const keys = [...new Set([...Object.keys(Benchmark), ...Object.keys(Strategy)])].filter((key) => !excludeKeys?.includes(key));

        const rows = keys.map((key: string) => {
            const benchmarkValue = loadingBenchmark ? <ClipLoader loading={true} size={14} /> : Benchmark[key]
            const portfolioValue = loadingPortfolio ? <ClipLoader loading={true} size={14} /> : Strategy[key]

            return { title: key, key, values: [benchmarkValue, portfolioValue]}
        })

        setColumns(columns)
        setRows([...rows, ...(customRows || [])])
    }, [results, customRows, loadingBenchmark, loadingPortfolio])

    return (
            <Box sx={{
                ...containerSx
                }}>
                <CommonTable
                    sx={{
                        ...tableSx,
                        '& td': {
                            padding: 'unset',
                            paddingTop: '.5rem',
                            paddingBottom: '.5rem',
                            paddingLeft: '1.5rem',
                            paddingRight: '1.5rem',
                        },
                        marginTop: {
                            xs: '2rem',
                            md: 'unset', 
                        },
                    }}
                    valueCellProps={{
                        sx: {
                            textAlign: 'center',
                        }
                    }}
                    headerColumns={columns}
                    rows={rows}
                    noDataMessage={noDataMessage}
                />
            </Box>
    )

}