import React, { Component } from 'react'
import { connect } from 'react-redux'

import { desiredAnnualFundingUpdate, desiredAnnualWantsUpdate, desiredAnnualWishesUpdate } from '../../actions/standardLivingRisk'
import { LineItem, LineItemOnChange } from './LineItem'
import Card, { SLRBaseProps } from './Card'
import IrregularTable from './IrregularTable'
import { onComponentSizeType } from '../common/styled/SizeAwareBox'
import { IrregularItem, FUNDING_TYPE_OPTIONS, FundingType } from '../../actions/types/standardLivingRisk'
import { Box } from '@mui/material'
import { Select } from '../common/styled/Select'
import { SelectOption } from '../../common/types'

interface LiabilitiesProps extends SLRBaseProps {
    desiredAnnualFunding: number
    desiredAnnualWants: number
    desiredAnnualWishes: number
    irregularExpense: IrregularItem[]
    startYears: number[]
    numberOfYears: number[]
    desiredAnnualFundingUpdate: LineItemOnChange
    desiredAnnualWantsUpdate: LineItemOnChange
    desiredAnnualWishesUpdate: LineItemOnChange
    onAssetSize: onComponentSizeType
}

class Liabilities extends Component <LiabilitiesProps>{

    render() {
        const { desiredAnnualFunding, desiredAnnualWants, desiredAnnualWishes, irregularExpense, startYears, numberOfYears, onComponentSize, onAssetSize } = this.props

        return (
            <Card title={'Liabilities'} onComponentSize={onComponentSize}>
                <Box>
                    <LineItem number={1} label={'Annual Needs During Retirement'} value={desiredAnnualFunding} onLeave={this.props.desiredAnnualFundingUpdate} extendedInput />
                    <LineItem number={1} label={'Annual Wants During Retirement'} value={desiredAnnualWants} onLeave={this.props.desiredAnnualWantsUpdate} extendedInput />
                    <LineItem number={1} label={'Annual Wishes During Retirement'} value={desiredAnnualWishes} onLeave={this.props.desiredAnnualWishesUpdate} extendedInput />
                </Box>
                
                <IrregularTable onComponentSize={onAssetSize} title={'Additional Annual Liabilities'} type={'expense'} itemLabel={'Expense'} items={irregularExpense} startYears={startYears} numberOfYears={numberOfYears} showFundingType/>
            </Card>
        )
    }

}

const mapStateToProps = ({ standardLivingRisk }) => {
    const { desired_annual_funding: desiredAnnualFunding, desired_annual_wants: desiredAnnualWants, desired_annual_wishes: desiredAnnualWishes, expense: irregularExpense, startYears, numberOfYears } = standardLivingRisk
    return {
        desiredAnnualFunding,
        desiredAnnualWants,
        desiredAnnualWishes,
        irregularExpense,
        startYears,
        numberOfYears,
    }
}

export default connect(mapStateToProps, { desiredAnnualFundingUpdate, desiredAnnualWantsUpdate, desiredAnnualWishesUpdate })(Liabilities)