import axios from 'axios'
import { GetAssetDetailsResponse, GetCustomAssetsResponse, StandardApiResponse, UpdateCustomAssetsResponse } from './types/responses'
import { Asset, AssetAllocations, AssetItem, AssetMetrics, AssetUpdate } from './types/customAssets'

const ASSETS_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/assets`

export const getCustomAssets = async(): Promise<GetCustomAssetsResponse | StandardApiResponse> => {
    try {
        const { data } = await axios.get(`/api/account/assets?include_dates=true`)
        
        return data as GetCustomAssetsResponse
    }catch (error: any) {
        return { success: false, message: error?.toString() }
    }
}

export const updateAsset = async (asset: Asset, update: AssetUpdate): Promise<UpdateCustomAssetsResponse> => {
    try {
        const { data } = await axios.put(`/api/account/asset/${asset.id}`, update)
        
        return data as UpdateCustomAssetsResponse
    }catch (error: any) {
        return { success: false, message: error?.toString() }
    }
}

export const deleteAsset = async (asset: Asset): Promise<StandardApiResponse> => {
    try {
        const { data } = await axios.delete(`/api/account/asset/${asset.id}`)
        
        return data as StandardApiResponse
    }catch (error: any) {
        return { success: false, message: error?.toString() }
    }
}

export const getCustomAssetDetailsById = async(assetId: number): Promise<GetAssetDetailsResponse & StandardApiResponse> => {
    try {
        const { data } = await axios.get(`${ASSETS_URL}/${assetId}/details`)
        
        return data as GetAssetDetailsResponse
    }catch (error: any) {
        return { success: false, message: error?.toString() }
    }
}
export const updateAssetItem = async(assetId: number, payload: Partial<AssetItem>): Promise<GetAssetDetailsResponse & StandardApiResponse> => {
    try {
        const { data } = await axios.put(`${ASSETS_URL}/${assetId}`, payload)
        
        return data as GetAssetDetailsResponse
    }catch (error: any) {
        return { success: false, message: error?.toString() }
    }
}
export const updateAssetMetrics = async(assetId: number, payload: Partial<AssetMetrics>): Promise<GetAssetDetailsResponse & StandardApiResponse> => {
    try {
        const { data } = await axios.post(`${ASSETS_URL}/${assetId}/metrics`, payload)
        const { success, item } = data
        
        return { success, metrics: item } as GetAssetDetailsResponse
    }catch (error: any) {
        return { success: false, message: error?.toString() }
    }
}
export const updateAssetAllocationItem = async(allocation: AssetAllocations, weight: number): Promise<{ success: boolean, item?: AssetAllocations} & StandardApiResponse> => {
    try {
        const { data } = await axios.put(`${ASSETS_URL}/${allocation.asset_id}/allocations/${allocation.id}`, { weight })
        const { success, item } = data
        
        return { success, item }
    }catch (error: any) {
        return { success: false, message: error?.toString() }
    }
}