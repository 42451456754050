import axios from "axios"
import { StandardAction, StandardThunkAction } from "../store/store"
import { GET_DOMAINS, PORTFOLIO_MAPS_FOR_DOMAIN, PARAMETER_LIST_UPDATED, SELECTED_DOMAIN, DOMAIN_LOADED, ASSETS_UPDATED, SET_ERROR, ASSETS_VALUE_UPDATED, STAT_VALUE_UPDATED, PARAMETER_MAPPING_UPDATED, PORTFOLIO_MAP_RESET, TABLE_DETAIL_VALUE_UPDATED } from "./types/actions"
import { Asset } from "./types/customAssets"
import { PortfolioMap, PortfolioMapAsset } from "./types/investmentPortfolio"
import { AssetValueCollection, AssetValueUpdate, ParameterMapping, PortfolioMapDetailUpdate, PortfolioMapStat, PortfolioMapStateValuesMap, PortfolioMapStatsItem, ProcessAssetsResult, ProcessStatsResult, UpdatePortfolioMapDetailRequest, ValuesMap } from "./types/portfolioMap"
import { StandardApiResponse } from "./types/responses"
import { RiskParameters } from "./types/riskPreferences"

const PORTFOLIO_MAP_RESET_URL = `/api/admin/portfoliomap/reset`
const GET_DOMAINS_URL = `/api/admin/portfoliomap/domains`

const LOAD_PORTFOLIO_MAPS_URL = `/api/admin/portfoliomaps`
const LOAD_PORTFOLIO_MAP_URL = `/api/admin/portfoliomap/load`

const PORTFOLIOMAP_ADD = `/api/admin/portfoliomap/add`
const PORTFOLIOMAP_REMOVE = `/api/admin/portfoliomap/remove`
const PORTFOLIOMAP_RENAME = `/api/admin/portfoliomap/name`
const ASSET_ADD_URL = `/api/admin/portfoliomap/domain/asset/add`
const ASSET_REMOVE_URL = `/api/admin/portfoliomap/domain/asset/remove`
const ASSET_VALUES_UPDATE_URL = `/api/admin/portfoliomap/domain/asset/values`
const DETAIL_VALUES_UPDATE_URL = `/api/admin/portfoliomap/domain/details`
const STAT_VALUES_UPDATE_URL = `/api/admin/portfoliomap/domain/stat/values`
const ASSET_VALUE_PARAMETERS_ADD_URL = `/api/admin/portfoliomap/domain/asset/value/parameters/add`
const ASSET_VALUE_PARAMETERS_REMOVE_URL = `/api/admin/portfoliomap/domain/asset/value/parameters/remove`

const PARAMETER_MAPPING_ADD_URL = `/api/admin/portfoliomap/parameterMapping/add`
const PARAMETER_MAPPING_REMOVE_URL = `/api/admin/portfoliomap/parameterMapping/remove`

const PORTFOLIO_ASSET_UPDATE = `/api/admin/portfoliomap/updatePortfolioAsset`

export const dismissError = (): StandardAction => {
    return { type: SET_ERROR  }
}

export const setError = (title: string, message: string): StandardAction => {
    return { type: SET_ERROR, payload: { title, message }  }
}

export const getDomains = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        const { data } = await axios.get(GET_DOMAINS_URL)
        const { domains }  = data

        dispatch({ type: GET_DOMAINS, payload: domains })
    }
}

export const getPortfolioMapsForDomain = (domain: string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        const { data } = await axios.post(LOAD_PORTFOLIO_MAPS_URL, { domain })
        const { portfolioMaps } = data || { portfolioMaps: [] }
        const payload = portfolioMaps.map((item: PortfolioMap) => {
            return { ...item, label: item.name, value: item.name }
        })

        dispatch({ type: PORTFOLIO_MAPS_FOR_DOMAIN, payload })
        dispatch({ type: DOMAIN_LOADED, payload: { loadedDomain: domain }})
        
    }
}

export const loadPortfolioMap = (domain: string, portfolioMapId: number): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        const { data } = await axios.post(LOAD_PORTFOLIO_MAP_URL, { portfolioMapId })
        const { assets: domainAssets = [], lambda, gamma, stats: domainStats, accountAssets: domainAccountAssets, parameterMappings, tableDetails = [] }  = data
        const accountAssets = domainAccountAssets.map((accountAsset: Asset) => {return { ...accountAsset, label: accountAsset.name, value: accountAsset.id }})
        const { assets, valuesMap } = processAssets(domainAssets)
        const { stats, statsValuesMap } = processStats(domainStats)
        const details: { [key: string]: PortfolioMapDetailUpdate } = tableDetails.reduce((accumulator: any, item: Asset) => {
            return { ...accumulator, [`${item.id}`]: { ...item, original: item }  }
        }, { })
        
        dispatch({ type: DOMAIN_LOADED, payload: { loadedDomain: domain, loadedPortfolioMapId: portfolioMapId, lambda, gamma, assets, values: valuesMap, stats, statValues: statsValuesMap, accountAssets, parameterMappings, details } })
    }
}

export const parameterAdded = (type: string, value: string): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { loadedPortfolioMapId: portfolioMapId } = portfolioMapTool

        const items = portfolioMapTool[type]
        if(!items) {
            return
        }
        
        if(!value || value.trim().length == 0) {
            dispatch(setError('Parameter Error', 'Please include a value'))

            return
        }
        
        const matchedItem = items.filter((item: any) => item == value)
        if(matchedItem.length > 0) {
            dispatch(setError('Parameter Error', 'This is a duplicate parameter'))

            return
        }


        const { data } = await axios.post(ASSET_VALUE_PARAMETERS_ADD_URL, { portfolioMapId, type, value })
        const { success, assets: domainAssets, stats: domainStats } = data || { success: false }
        if(!success) {
            return
        }

        const { assets, valuesMap } = processAssets(domainAssets)
        const { stats, statsValuesMap } = processStats(domainStats)
        
        dispatch({ type: PARAMETER_LIST_UPDATED, payload: { type, items: [...items, value].sort((a, b) => +a - +b) } })
        dispatch({ type: ASSETS_UPDATED, payload: { assets, values: valuesMap, stats, statValues: statsValuesMap } })
    }
}

export const parameterRemoved = (type: string, value: string): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { loadedPortfolioMapId: portfolioMapId } = portfolioMapTool

        const items = portfolioMapTool[type]
        if(!items) {
            return
        }

        const { data } = await axios.post(ASSET_VALUE_PARAMETERS_REMOVE_URL, { portfolioMapId, type, value })

        const { success, assets: domainAssets, stats: domainStats  } = data || { success: false }
        if(!success) {
            return
        }

        const { assets, valuesMap } = processAssets(domainAssets)
        const { stats, statsValuesMap } = processStats(domainStats)
        
        const itemsRemovedValue = items.filter((item: any) => item != value)
        dispatch({ type: PARAMETER_LIST_UPDATED, payload: { type, items: itemsRemovedValue.sort((a: any, b: any) => +a - +b) } })
        dispatch({ type: ASSETS_UPDATED, payload: { assets, values: valuesMap, stats, statValues: statsValuesMap } })
    }
}

export const domainSelected = (value: string): StandardAction => {
    return { type: SELECTED_DOMAIN, payload: value}
}
export const onValueChanged = (value: AssetValueUpdate): StandardAction<AssetValueUpdate> => {
    return { type: ASSETS_VALUE_UPDATED, payload: { ...value, dirty: value.value != value.originalValue }}
}

const checkTableDetailDirty = (item: PortfolioMapDetailUpdate) => {
    if(!item.original) {
        return true
    }

    return item.title != item.original.title ||
        item.objective != item.original.objective ||
        item.amount_equities != item.original.amount_equities ||
        item.fixed_income != item.original.fixed_income ||
        item.alternatives != item.original.alternatives
}

export const onTableDetailChanged = (item: PortfolioMapDetailUpdate): StandardAction<PortfolioMapDetailUpdate> => {
    return { type: TABLE_DETAIL_VALUE_UPDATED, payload: { ...item, dirty: checkTableDetailDirty(item) }}
}

export const assetAdded = (assetName: string): StandardThunkAction  => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { loadedPortfolioMapId: portfolioMapId } = portfolioMapTool

        const { data } = await axios.post(ASSET_ADD_URL, { portfolioMapId, assetName })
        const { assets: domainAssets }  = data
        const { assets, valuesMap } = processAssets(domainAssets)

        dispatch({ type: ASSETS_UPDATED, payload: { assets, values: valuesMap } })
    }
}

export const processAssets = (domainAssets: PortfolioMapAsset[]): ProcessAssetsResult => {
    const valuesMap: ValuesMap = {}

    const assets: AssetValueCollection[] = domainAssets.map(({ values = [], ...asset }: PortfolioMapAsset) => {
        const valueIds: number[] = []
        for(const value of values) {
            valuesMap[value.id] = { ...value, originalValue: value.value, dirty: false }
            valueIds.push(value.id)
        }

        return { 
            ...asset,
            value: asset.asset_name, 
            valueIds
        }
    })

    return { valuesMap, assets }
}

export const processStats = (domainStats: PortfolioMapStat[]): ProcessStatsResult => {
    const statsValuesMap: PortfolioMapStateValuesMap = {}

    for(const domainStat of domainStats) {
        const { annual_return, annual_volatility } = domainStat
        statsValuesMap[domainStat.id] = {...domainStat, original_annual_return: annual_return, original_annual_volatility: annual_volatility }
    }

    const statIds = Object.keys(statsValuesMap)
    const stats: PortfolioMapStatsItem[] = [{ asset_name: 'Annual Return', type: 'annual_return', statIds }, { asset_name: 'Annual Volatility', type: 'annual_volatility', statIds }]

    return { stats, statsValuesMap }
}

export const assetRemoved = (assetId: number): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { loadedPortfolioMapId: portfolioMapId } = portfolioMapTool

        const { data } = await axios.post(ASSET_REMOVE_URL, { portfolioMapId, assetId })
        const { assets: domainAssets }  = data
        const valuesMap: ValuesMap = {}

        const assets: AssetValueCollection[] = domainAssets.map(({ values, ...asset }: any) => {
            const valueIds: number[] = []
            for(const value of values) {
                valuesMap[value.id] = { ...value, originalValue: value.value, dirty: false }
                valueIds.push(value.id)
            }

            return { 
                ...asset, 
                value: asset.asset_name, 
                valueIds
            }
        })

        dispatch({ type: ASSETS_UPDATED, payload: { assets, values: valuesMap } })
    }
}

export const saveValueUpdates = (): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { values } = portfolioMapTool
        const dirtyValues = Object.keys(values).filter((key) => values[key].dirty ).map((key) => values[key])
        const updates = dirtyValues.map(({ value, id }) => { return { value, id }})

        try {
            const { data } = await axios.post(ASSET_VALUES_UPDATE_URL, { updates })
            const { updates: savedUpdates = { } } = data || { }
            
            dispatch({ type: ASSETS_UPDATED, payload: { values: { ...values, ...savedUpdates } } })
        }catch(e: any) {
        }
    }
}

export const saveDetailUpdates = (): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { details, loadedPortfolioMapId } = portfolioMapTool
        const dirtyDetails: PortfolioMapDetailUpdate[] = Object.keys(details).filter((key) => details[key].dirty ).map((key) => details[key])
        const updates:UpdatePortfolioMapDetailRequest[] = dirtyDetails.map(({ id, portfolio_map_id = loadedPortfolioMapId, lambda, original, title = '', objective = '', amount_equities = 0, fixed_income = 0, alternatives = 0 }) => {
            const updates: UpdatePortfolioMapDetailRequest = { id, portfolio_map_id, lambda }
            if(!original || title != original.title) {
                updates.title = title
            }
            if(!original || objective != original.objective) {
                updates.objective = objective
            }
            if(!original || amount_equities != original.amount_equities) {
                updates.amount_equities = amount_equities
            }
            if(!original || fixed_income != original.fixed_income) {
                updates.fixed_income = fixed_income
            }
            if(!original || alternatives != original.alternatives) {
                updates.alternatives = alternatives
            }

            return updates
        })

        try {
            const { data } = await axios.post(DETAIL_VALUES_UPDATE_URL, { updates })
            const { updates: savedUpdates = { } } = data || { }
            
            dispatch({ type: ASSETS_UPDATED, payload: { details: { ...details, ...savedUpdates } } })
        }catch(e: any) {
        }
    }
}

export const addNewDetails = (): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { details, loadedPortfolioMapId } = portfolioMapTool

        try {
            const { data } = await axios.post(DETAIL_VALUES_UPDATE_URL, { updates: [{ portfolio_map_id: loadedPortfolioMapId }] })
            const { updates: savedUpdates = { } } = data || { }
            
            dispatch({ type: ASSETS_UPDATED, payload: { details: { ...details, ...savedUpdates } } })
        }catch(e: any) {
        }
    }
}
export const deleteDetails = (id: number): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { details } = portfolioMapTool

        try {
            await axios.delete(`${DETAIL_VALUES_UPDATE_URL}/${id}`)
            const { [id]: deletedItem , ...cleanedDetails } = details
            
            dispatch({ type: ASSETS_UPDATED, payload: { details: cleanedDetails } })
        }catch(e: any) {
        }
    }
}
export const addNewDetailsMapping = (detailsId: number, riskParameters: RiskParameters): StandardThunkAction<StandardApiResponse> => {
    return async(dispatch, getState): Promise<StandardApiResponse> => {
        const { portfolioMapTool } = getState()
        const { details, loadedPortfolioMapId } = portfolioMapTool
        const { [detailsId]: item , ...cleanedDetails } = details

        try {
            const { data } = await axios.post(`${DETAIL_VALUES_UPDATE_URL}/mappings`, { map_id: loadedPortfolioMapId, details_id: detailsId, ...riskParameters })
            const { success, message, mappings } = data || { }
            if(!success) {
                return { success: false, message }
            }
            
            dispatch({ type: ASSETS_UPDATED, payload: { details: {...cleanedDetails, [detailsId]: { ...item, mappings } } } })

            return { success: true }
        }catch(e: any) {
            return { success: false, message: `${e}` }
        }
    }
}
export const deleteDetailsMapping = (detailsId: number, mappingsId: number): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { details } = portfolioMapTool

        try {
            await axios.delete(`${DETAIL_VALUES_UPDATE_URL}/mappings/${mappingsId}`)
            const { [detailsId]: updatedItem , ...cleanedDetails } = details
            const { mappings } = updatedItem
            const updatedMappings = mappings.filter(({ id }: any) => id !== mappingsId)
            
            dispatch({ type: ASSETS_UPDATED, payload: { details: {...cleanedDetails, [detailsId]: { ...updatedItem, mappings: updatedMappings } } } })
        }catch(e: any) {
        }
    }
}

export const onParameterMappingAdded = (parameter: string, from: string, to: string): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { loadedPortfolioMapId: portfolioMapId, parameterMappings } = portfolioMapTool
        

        try {
            const { data } = await axios.post(PARAMETER_MAPPING_ADD_URL, { portfolioMapId, parameter, from, to })
            if(!data.success) {
                return
            }
            const { parameter: parameterItem } = data
            const updatedParameterMappings = [...parameterMappings, parameterItem]

            dispatch({ type: PARAMETER_MAPPING_UPDATED, payload: updatedParameterMappings })
        }catch(e: any) {
        }
    }
}

export const onParameterMappingRemoved = (item: ParameterMapping): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { parameterMappings } = portfolioMapTool
        

        try {
            const { data } = await axios.post(PARAMETER_MAPPING_REMOVE_URL, { parameter: item.id })
            if(!data.success) {
                return
            }
            const updatedParameterMappings = parameterMappings.filter((parameterMapping: ParameterMapping) => item.id != parameterMapping.id)

            dispatch({ type: PARAMETER_MAPPING_UPDATED, payload: updatedParameterMappings })
        }catch(e: any) {
        }
    }
}
export const onAccountAssetChanged = ({ values, ...asset }: PortfolioMapAsset, updatedAsset: PortfolioMapAsset): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { assets: stateAssets } = portfolioMapTool
        const assets = stateAssets.map((stateAsset: Asset) => {
            if(stateAsset.id == asset.id) {
                return {...asset, ...updatedAsset }
            }

            return { ...stateAsset }
        })
        
        try {
            await axios.post(PORTFOLIO_ASSET_UPDATE, { portfolioAsset: asset.id, accountAsset: updatedAsset.asset_id })
        }catch(e: any) {
        }
        
        dispatch({ type: ASSETS_UPDATED, payload: { assets } })
    }
}

export const onSaveStatsPressed = (): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { statValues: values } = portfolioMapTool
        const dirtyValues = Object.keys(values).filter((key) => values[key].dirty ).map((key) => values[key])
        
        const updates = dirtyValues.map(({ id, annual_volatility, annual_return }) => { return { id, annual_volatility, annual_return }})

        try {
            await axios.post(STAT_VALUES_UPDATE_URL, { updates })
        }catch(e: any) {
        }
    }
}



export const onClearAndReset = (): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { loadedPortfolioMapId: portfolioMapId, loadedDomain: domain } = portfolioMapTool

        await axios.post(PORTFOLIO_MAP_RESET_URL, { domain, portfolioMapId })
        dispatch({ type: PORTFOLIO_MAP_RESET })
        dispatch(loadPortfolioMap(domain, portfolioMapId))
    }
}

export const portfolioMapReset = (): StandardAction => {
    return { type: PORTFOLIO_MAP_RESET }
}

export const portfolioMapRename = (portfolioMapId: number, name: string): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { portfolioMaps } = portfolioMapTool

        const { data } = await axios.post(PORTFOLIOMAP_RENAME, { portfolioMapId, name })
        if(!data.success) {
            return
        }

        const payload = portfolioMaps.map((portfolioMap: PortfolioMap) => {
            if(portfolioMap.id == portfolioMapId) {
                return { ...portfolioMap, name, label: name, value: name }
            }

            return portfolioMap
        })
        dispatch({ type: PORTFOLIO_MAPS_FOR_DOMAIN, payload })
    }
}

export const portfolioMapAdd = (name: string): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { loadedDomain: domain, portfolioMaps } = portfolioMapTool

        const { data } = await axios.post(PORTFOLIOMAP_ADD, { name, domain })
        const { success, portfolioMap } = data
        if(!success) {
            return
        }

        dispatch({ type: PORTFOLIO_MAPS_FOR_DOMAIN, payload: [ ...portfolioMaps, { ...portfolioMap, value: portfolioMap.name, label: portfolioMap.name } ] })
    }
}

export const portfolioMapRemove = (portfolioMapId: number): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { portfolioMapTool } = getState()
        const { portfolioMaps } = portfolioMapTool

        const { data } = await axios.post(PORTFOLIOMAP_REMOVE, { portfolioMapId })
        const { success } = data
        if(!success) {
            return
        }

        const payload = portfolioMaps.filter(({ id }: PortfolioMap) => id != portfolioMapId)

        dispatch({ type: PORTFOLIO_MAPS_FOR_DOMAIN, payload })
    }
}