import React, { Component } from 'react'
import ConfirmModal from '../common/ConfirmModal'

import ClientDetails from '../common/ClientDetails'
import { Client, ClientUpdateRequest } from '../../actions/types/clientManagement'

export interface ClientModalProps {
    client?: Client
    visible: boolean
    onCancel: () => void
    onClientSave: (client: Client | ClientUpdateRequest) => void
    onAddClientSave: (client: Client) => void
}

export default class extends Component<ClientModalProps> {
    clientDetailsRef: any
    constructor(props) {
        super(props)

        this.clientDetailsRef
    }

    onSave = () => {
        if(!this.clientDetailsRef) {
            return
        }
        this.clientDetailsRef.confirm()
    }

    onConfirmed = ({ id, first_name, last_name, annual_income, phone, email }: Client) => {
        const { onClientSave, onAddClientSave } = this.props
        if(id && onClientSave) {
            onClientSave({ id, first_name, last_name, annual_income, phone, email } as ClientUpdateRequest)
        }

        if(!id && onAddClientSave) {
            onAddClientSave({ first_name, last_name, annual_income, phone, email } as Client)
        }
    }

    render() {
        const { client, visible, onCancel } = this.props
        const { id } = client || { }
        const verb = id ? `Edit` : 'Add'

        return (
            <ConfirmModal
                visible={visible}
                sx={{ width: '100%' }}
                title={`${verb} Client`}
                confirmLabel={'Save'}
                cancelLabel={'Cancel'}
                onConfirm={this.onSave}
                onCancel={onCancel}>
                <ClientDetails 
                    ref={(view) => {
                        if(view) {
                            this.clientDetailsRef = view
                        }
                    }}
                    onConfirmed={this.onConfirmed}
                    client={client}
                />
            </ConfirmModal>
        )
    }
}