import React from 'react';
import { REQUIRES_SKIP_PERCENT_CHANGE_FORMATTING } from '../../actions/types/marketDashboard';
import { Box } from '@mui/material';
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text';

interface ComponentState {
}

interface ComponentProps {
    identifier: string;
    value: number;
} 
class PerformanceChartChangeItem extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {}

    formatPercentChanges = (identifier: string, value: number) => {
        if (!value) {
            return '-';
        }

        if (REQUIRES_SKIP_PERCENT_CHANGE_FORMATTING.includes(identifier)) {
            return value?.toFixed(2);
        }

        return `${value.toFixed(2)}%` ;
    }

    getBackroundColor = (value: number) => {
        return '';
    }

    getColor = (value: number) => {
        if (!value) {
            return '';
        }

        if (value < 0) {
            return '#FF0000';
        }

        return '#06c720';
    }
    
    render() {
        const { identifier, value } = this.props;
        const backgroundColor = this.getBackroundColor(value);
        const formattedValue = this.formatPercentChanges(identifier, value);
        const color = this.getColor(value);

        return (<Box sx={{ padding: '1.5rem', backgroundColor, color, font: FONT_STYLES_BY_TYPE.table_header }}>{formattedValue}</Box>
        )
    }
}

export default PerformanceChartChangeItem