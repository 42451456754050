import React, { Component } from 'react'
import { connect } from 'react-redux'

import { onChosenRiskValueChanged } from '../../actions/investmentPortfolio'
import RiskProfileItem from './RiskProfileItem'
import { RESULT_MAP as GAMMA_RESULT_MAP } from '../Gauge/RiskAversionGauge'
import { RESULT_MAP as LOSS_RESULT_MAP } from '../Gauge/LossAversionGauge'
import Text from '../common/styled/Text'
import { RiskParameter, RiskParameters, RiskParameterType } from '../../actions/types/riskPreferences'
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { BaseProps } from '../types'
import { TableHeaderCell } from '../common/Table'
import { SelectOption } from '../../common/types'

const defaultParameterSets = ['preferred', 'recommended', 'chosen']

const TYPE_OPTION_MAPPING = {
    gamma: GAMMA_RESULT_MAP,
    lambda: LOSS_RESULT_MAP,
}

const OPTIONS = [{
    label: 'LOW',
    value: 'LOW'
},{
    label: 'MED',
    value: 'MED'
},{
    label: 'HIGH',
    value: 'HIGH'
}]

export interface RiskProfileTableProps extends BaseProps {
    onRiskValueChanged: (type: RiskParameter, value: string | undefined) => void
    chosen: RiskParameters
    recommended: RiskParameters
    preferred: RiskParameters
    readOnly?: boolean
    parameterSets?: RiskParameterType[]
}

class RiskProfileTable extends Component<RiskProfileTableProps>{


    onRiskValueChanged = (type: RiskParameter, { value }: SelectOption) => {
        
        const options = TYPE_OPTION_MAPPING[type]
        const mappedValue = Object.keys(options).find(key => options[key] === value)

        this.props.onRiskValueChanged(type, mappedValue)
    }

    renderRow = (title, values, readOnly) => {
        const { lambda, gamma } = values
        
        return (
            <TableRow>
                <TableCell><Text>{title}</Text></TableCell>
                <TableCell>
                    <RiskProfileItem title={'Risk Aversion'} type={'gamma'} options={OPTIONS} value={GAMMA_RESULT_MAP[gamma]} readOnly={readOnly} onRiskValueChanged={this.onRiskValueChanged} />
                </TableCell>
                <TableCell>
                    <RiskProfileItem title={'Loss Aversion'} type={'lambda'} options={OPTIONS} value={LOSS_RESULT_MAP[lambda]} readOnly={readOnly} onRiskValueChanged={this.onRiskValueChanged} />
                </TableCell>
            </TableRow>            
        )
    }

    render() {
        const { chosen: originalChosen, recommended, preferred, readOnly, parameterSets = defaultParameterSets, sx = {}} = this.props
        const { lambda: chosenLambda, gamma: chosenGamma } = originalChosen
        const { lambda: recommendedLambda , gamma: recommendedGamma } = recommended
        
        const chosen = { lambda: chosenLambda !=null ? chosenLambda : recommendedLambda, gamma: chosenGamma != null ? chosenGamma : recommendedGamma }
        const parameterSetsCleaned = parameterSets.length > 0 ? parameterSets : defaultParameterSets
        
        return (
            <Table 
                sx={{
                    width: '70rem',
                    '& thead, tr, td, th': {
                        border: 'none',
                        borderBottom: 'none',
                        backgroundColor: '#0000 !important',
                        padding: 'unset',
                    },
                    ...sx
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableHeaderCell><Text type={'form_header'}>Risk Profile</Text></TableHeaderCell>
                        <TableHeaderCell><Text type={'form_header'}>Risk Aversion</Text></TableHeaderCell>
                        <TableHeaderCell><Text type={'form_header'}>Loss Aversion</Text></TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {parameterSetsCleaned.includes('preferred') && this.renderRow('Risk Preferences', preferred, readOnly || true)}
                    {parameterSetsCleaned.includes('recommended') && this.renderRow(<Box style={{ marginTop: '.2rem', marginBottom: '.7rem' }}>ALM-Adjusted<br />Risk Preferences</Box>, recommended, readOnly || true)}
                    {parameterSetsCleaned.includes('chosen') && this.renderRow('Chosen', chosen, readOnly || false)}
                </TableBody>
            </Table>
        )
    }

}

const mapStateToProps = ({ investmentPortfolio }) => {
    const { chosen, recommended, preferred, portfolioMap } = investmentPortfolio

    return { chosen, recommended, preferred, portfolioMap }
}

export default connect(mapStateToProps, { onRiskValueChanged: onChosenRiskValueChanged })(RiskProfileTable)