import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'

import Alert, { AlertProps } from './Alert'
import { Box } from '@mui/material'

interface ErrorAlertProps extends AlertProps {
    wrapChildrenInArray?: boolean
}

export default class ErrorAlert extends Component <ErrorAlertProps> {

    componentDidUpdate(prevProps) {
        const { visible: prevVisible } = prevProps
        const { visible } = this.props

        if(!prevVisible && visible) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }

    render() {
        const { children, className, titleIcon, sx = {}, wrapChildrenInArray = true, ...restProps } = this.props
        let contents
        if(wrapChildrenInArray) {
            contents = typeof(children) == 'string' ? children.split('\r\n') : children
            contents = Array.isArray(contents) ? contents : [contents]
        } else {
            contents = children
        }
        
        return (
            <Alert 
                {...restProps}
                titleIcon={titleIcon ? titleIcon : () => <FontAwesomeIcon color={'#fff'}  icon={faBan} />}
                sx={{
                    height: 'unset !important',
                    width: '60rem',
                    backgroundColor: '#dd4b39 !important',
                    color: 'white',
                    ...sx,
                }}>
                {wrapChildrenInArray && contents.map((error) => {
                    return <Box key={`${error}`}>{`${error}`}</Box>
                })}
                {!wrapChildrenInArray && children}
            </Alert>
        )
    }

}