import React, { Component } from 'react'
import Button, { ButtonVariant } from './Button';
import Modal, { ModalProps } from './Modal';

export interface ConfirmModalProps extends ModalProps {
    onConfirm?: () => void
    onSecondaryConfirm?: () => void
    confirmLabel?: string
    secondaryConfirmLabel?: string
    cancelLabel?: string
    confirmDisabled?: boolean
    confirmSecondaryDisabled?: boolean
    confirmVariant?: ButtonVariant
    secondaryConfirmVariant?: ButtonVariant
    hideCancelButton?: boolean
}

export default class extends Component<ConfirmModalProps> {

    render() {
        const { children, confirmLabel, secondaryConfirmLabel, cancelLabel, confirmDisabled, confirmSecondaryDisabled, confirmVariant = 'primary', secondaryConfirmVariant = 'primary', hideCancelButton = false, ...props } = this.props
        return (
            <Modal
                {...props}
                footerChildren={
                    <>
                        <Button autoFit sx={{ marginRight: '1rem' }} disabled={confirmDisabled} variant={confirmVariant} onClick={this.props.onConfirm}>{confirmLabel || 'Save'}</Button>
                        {!!secondaryConfirmLabel && !!this.props.onSecondaryConfirm &&
                            <Button autoFit sx={{ marginRight: '1rem' }} disabled={confirmSecondaryDisabled} variant={secondaryConfirmVariant} onClick={this.props.onSecondaryConfirm}>{secondaryConfirmLabel}</Button>
                        }
                        {!hideCancelButton &&
                            <Button autoFit variant={'outline'} onClick={this.props.onCancel}>{cancelLabel || 'Cancel'}</Button>
                        }
                    </>
                }
            >
                        {children}
            </Modal>
        )
    }
}