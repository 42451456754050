import React, { FunctionComponent } from 'react';
import { PortfolioMapDetail } from '../../../actions/types/investmentPortfolio';
import { getDisplayValue } from '../../../common/utils';
import { GuidePanel, GuidePanelProps } from '../GuidePanel';


const ModelItem: FunctionComponent<{ item: PortfolioMapDetail }> = ({ item }) => {
    const { title, objective, amount_equities, fixed_income, alternatives } = item

    return (
        <>
            <p>
                <b>{title}: </b>{objective}<br />
                Equities: {getDisplayValue(amount_equities * 100, 0)}%<br />
                Fixed Income: {getDisplayValue(fixed_income * 100, 0)}%<br />
                Alternatives: {getDisplayValue(alternatives * 100, 0)}%<br />
            </p>
            <br />
            <br />
        </>        
    )
}

export interface ModelPortfolioProps extends GuidePanelProps {
    models: PortfolioMapDetail[]
}

export const ModelPortfolios: FunctionComponent<ModelPortfolioProps>  = ({ models, ...props}) => {
    const hasRows = models?.length > 0
    if(!hasRows) {
        return <></>
    }
    
    return (
        <GuidePanel {...props} title={'Model Portfolios'}>
            {models.map((model) => <ModelItem item={model} /> )}
        </GuidePanel>
    )
}