import React from 'react'
import { Box, Link, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import LandingButton from "./LandingButton";
import { Styles, withStyles } from '@mui/styles';
import { BaseProps } from '../../types';
import { ScheduleMeetingButton } from './ScheduleMeetingButton';

const twitterIcon = require('../assets/twitter_icon.png')
const twitterIconWebp = require('../assets/twitter_icon.webp')

const linkedInIcon = require('../assets/linked_in_icon.png')
const linkedInIconWebp = require('../assets/linked_in_icon.webp')

export const styles: Styles<any, any> = (theme) => {
    return {
        joinListInput: { 
            position: 'absolute',
            top: 9,
            left: 10,
            width: '10rem',
            bottom: 5,
            zIndex: 1,
            color: 'black',
            borderStyle: 'hidden',
            borderColor: 'transparent',
            fontSize: theme.typography.pxToRem(25),
            "&:focus": {
                borderStyle: 'hidden',
                borderColor: 'transparent',
                outline: 'none',
            }
        }
    }
}

const JoinTheListComponent: FunctionComponent<BaseProps> = ({ classes }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: {
                xs: 5,
                lg: 0,
            }
        }}>
            <Typography sx={{ marginBottom: '2rem', font: (theme) => `normal normal 300 ${theme.typography.pxToRem(35)}/${theme.typography.pxToRem(40)} Montserrat` }}>Join the List</Typography>
            <Box 
                sx={{
                    height: 50,
                    width: '28rem',
                    background: `#FFFFFF`,
                    borderRadius: 50,
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'relative',
                    justifyContent: 'flex-end',
                }}>
                    <input className={classes?.joinListInput}></input>
                    <LandingButton sx={{ width: '7.5rem', zIndex: 5, }} title={'Sign up'}/>
            </Box>
        </Box>
    )
}

const JoinTheList = withStyles(styles)(JoinTheListComponent)

const Component: FunctionComponent<BaseProps> = ({ classes }) => {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '10rem',
                paddingBottom: '10rem',
                borderBottom: (theme: any) => `${theme.palette.borders.primary} solid 1px`,
                alignItems: 'center',
            }}>
                <Typography sx={{ 
                        paddingLeft: {
                            md: 10,
                        },
                        paddingRight: {
                            md: 10,
                        },
                        textAlign: 'center',
                        font: (theme) => `normal normal 300 ${theme.typography.pxToRem(60)}/${theme.typography.pxToRem(70)} Montserrat`, marginBottom: '1.5rem'
                    }}>Learn More about Portfolio Designer Today</Typography>
                <ScheduleMeetingButton />
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    md: 'row',
                },
                height: {
                    md: '35rem',
                },
                paddingTop: '10rem',
                paddingBottom: '10rem',
                background: (theme: any) => theme.palette.primary.main,
                justifyContent: 'space-around',
                alignItems: 'center',
                color: 'white',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Typography sx={{ marginBottom: '2rem', font: (theme) => `normal normal 300 ${theme.typography.pxToRem(35)}/${theme.typography.pxToRem(40)} Montserrat` }}>Contact Us</Typography>
                    <Typography sx={{ font: (theme) => `normal normal normal ${theme.typography.pxToRem(30)}/${theme.typography.pxToRem(35)} Montserrat` }}>Questions? Suggestions? Inquiries?</Typography>
                    <Typography sx={{ font: (theme) => `normal normal normal ${theme.typography.pxToRem(30)}/${theme.typography.pxToRem(35)} Montserrat`, color: `#6666CC`}}><Link href={'mailto:info@portfoliodesigner.com'} sx={{ color: `#6666CC`, textDecoration: 'none' }}>info@portfoliodesigner.com</Link></Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: {
                        xs: 5,
                        lg: 0,
                    }
                }}>
                    <Typography sx={{ marginBottom: '1rem', font: (theme) => `normal normal 300 ${theme.typography.pxToRem(35)}/${theme.typography.pxToRem(40)} Montserrat` }}>Follow Us</Typography>
                    <Box sx={{ 
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <Link sx={{ marginRight: 1, borderRadius: 35, '& img': { width: 35, height: 35 } }} href={'https://twitter.com/PortfolioDsgnr'} target={'_blank'} rel="noopener noreferrer">
                            <picture>
                                <source type="image/webp" srcSet={twitterIconWebp}></source>
                                <img src={twitterIcon} alt={'Twitter Icon'}></img>
                            </picture>
                        </Link>
                        <Link sx={{ marginLeft: 1, borderRadius: 35, '& img': { width: 35, height: 35 } }} href={'https://www.linkedin.com/company/portfoliodesigner'} target={'_blank'} rel="noopener noreferrer">
                            <picture>
                                <source type="image/webp" srcSet={linkedInIconWebp}></source>
                                <img src={linkedInIcon} alt={'LinkedIn Icon'}></img>
                            </picture>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export const FooterBanner = withStyles(styles)(Component) 