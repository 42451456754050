import React, { Component } from "react"
import { connect } from "react-redux"

import { AdminPage } from "../AdminPage"
import { getCopyItem } from "../../../actions/adminCopyEditor"
import { CopyItem } from "../../../actions/types/copy"
import { withRouter, WithRouterProps } from "../../common/withRouter"


interface CopyPreviewProps extends WithRouterProps{
    getCopyItem: (id: string) => void
    item: CopyItem
}


class CopyPreview extends Component <CopyPreviewProps> {

    componentDidMount() {
        const { copyId = '' } = this.props.params
        this.props.getCopyItem(copyId)
    }

    render() {
        const { contents = '' } = this.props.item || { }

        return(
            <AdminPage>
                <div dangerouslySetInnerHTML={{ __html: contents }}></div>
            </AdminPage>
        )
    }

}

const mapStateToProps = ({ copy }) => {
    const { editItem } = copy

    return { item: editItem }
}

export default withRouter(connect(mapStateToProps, { getCopyItem })(CopyPreview))