import React, { Component } from 'react'
import { connect } from 'react-redux';
import RiskAversion from '../RiskPreferences/RiskAversion'
import LossAversion from '../RiskPreferences/LossAversion'
import SubmitResult from './SubmitResult'

import { clientRiskPreferencesOnLoad, riskAversionQuestionUpdate, lossAversionQuestionUpdate, riskPreferencePage } from '../../actions/clientRiskPreferences'
import { PageContents } from '../Page/PageContents';
import PagerContainer from '../RiskPreferences/PagerContainer';
import Page from '../Page/Page';
import { HeaderLogo } from '../common/styled/HeaderLogo';
import Footer from './Footer';
import { UpdateQuestionRequest } from '../../actions/types/requests';
import { getTokenLogo, onPageChange } from '../../actions/session';
import { logoHorizontal } from '../../assets';
import { TokenType } from '../../actions/types/authentication';

const tokenParameter = new URLSearchParams(window.location.search).get('token') || ''

export interface State {
    logo?: string
}

export interface ClientRiskPreferencesProps {
    clientRiskPreferencesOnLoad: () => void
    riskPreferencePage: () => void
    riskAversionQuestionUpdate: (update: UpdateQuestionRequest) => void
    lossAversionQuestionUpdate: (update: UpdateQuestionRequest) => void
    onPageChange: (key: string, page: number) => void
    submitSuccess?: boolean
    firmWebsite?: string
    loading?: boolean
    activeIndex: number
}

class ClientRiskPreferences extends Component <ClientRiskPreferencesProps, State> {
    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
        const { logo } = await getTokenLogo(tokenParameter, TokenType.QUESTIONNAIRE)
        this.setState({ logo: logo || logoHorizontal })

        this.props.clientRiskPreferencesOnLoad()
    }

    componentWillUnmount(): void {
        this.props.onPageChange('clientQuestionnaire', 0)
    }

    onRiskAversionChoiceSelected = ({ questionNumber, value }) => {
        this.props.riskAversionQuestionUpdate({ questionNumber, value })
    }

    onLossAversionChoiceSelected = ({ questionNumber, value }) => {
        this.props.lossAversionQuestionUpdate({ questionNumber, value })
    }
    
    render() {
        const { activeIndex } = this.props
        const { logo } = this.state

        return (
            <Page sx={{ 
                hight: '100%'
            }} contentsSx={{ display: 'flex', flexDirection: 'column', marginBottom: '5rem' }} disableResize>
                <HeaderLogo 
                    linkUrl={`#`}
                    logoUrl={logo}
                />
                <PageContents sx={{ paddingBottom: '4rem' }}>
                    <PagerContainer activeIndex={activeIndex} gesturesEnabled={false}>
                        <RiskAversion footer={Footer} onChoiceSelected={this.onRiskAversionChoiceSelected} />
                        <LossAversion footer={Footer} onChoiceSelected={this.onLossAversionChoiceSelected} />
                        <SubmitResult />
                    </PagerContainer>
                </PageContents>
            </Page>
        )
    }
}

const mapStateToProps = ({ riskPreferences, session }) => {
    const { loading, submitSuccess } = riskPreferences
    const { firmWebsite, clientQuestionnaireActiveIndex } = session

    return {
        loading,
        activeIndex: clientQuestionnaireActiveIndex,
        submitSuccess,
        firmWebsite,
    }
}

export default connect(mapStateToProps, { onPageChange, clientRiskPreferencesOnLoad, riskPreferencePage, riskAversionQuestionUpdate, lossAversionQuestionUpdate })(ClientRiskPreferences)