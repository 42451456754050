import React from 'react';
import { AuthenticatedUser, RefreshResponse, UserDetails } from '../../actions/types/users';
import { Authorization, LoginResponse } from '../../actions/types/responses';

export interface AuthenticationParams {
  auth?: Authorization
  user?: AuthenticatedUser
  details?: UserDetails
  onAuthUpdated?: () => Promise<void> | void
}

export interface AuthContextProps extends AuthenticationParams {
  authenticated: boolean
  loading: boolean
  setAuthentication: (authenticationParams: AuthenticationParams) => void
  refresh: () => Promise<RefreshResponse>
  updateLogo: (logoUrl: string) => void
  performLogin: (username: string, password: string) => Promise<LoginResponse>
  performRegisteredLogin: (sessionId: string) => Promise<LoginResponse>
  performLogout: () => void
}

export const AuthContext =
  React.createContext<AuthContextProps | null>(null);
