import React, { Component, FunctionComponent, useEffect, useState } from 'react'
import { Box, BoxProps } from '@mui/material';

export interface Size {
    width: number
    height: number
}
export type onComponentSizeType = (size: Size) => void
export interface SizeAwareBoxProps extends BoxProps {
    onComponentSize?: onComponentSizeType
    ref?: any
    readOnly?: boolean
}

const didSizeChange = ({ height: aHeight, width: aWidth }: Size, { height: bHeight, width: bWidth }: Size) => {
    return (aHeight !== bHeight) || (aWidth !== bWidth)
}

export const SizeAwareBox: FunctionComponent<SizeAwareBoxProps> = ({ sx = {}, readOnly, onComponentSize, ref, ...props}) => {
    const [size, setSize] = useState<Size>({ width: 0, height: 0, })
    useEffect(() => {
        if(typeof(onComponentSize) === 'function') {
            onComponentSize(size)
        }
    }, [size, onComponentSize])
    return (
        <Box sx={{ ...sx, pointerEvents: readOnly ? 'none' : 'auto' }}>
            <Box ref={(view: any) => {
                if(view) {
                    const viewSize = { height: view.clientHeight, width: view.clientWidth }
                    if(didSizeChange(viewSize, size)) {
                        setSize(viewSize)
                    }
                }
                if(ref) {
                    ref(view)
                }
            }} {...props}/>
        </Box>
    )
}