import { Box } from "@mui/material"
import { Component } from "react"
import { connect } from 'react-redux'
import { WithWindowDimensionsProps, withWindowDimensions } from "../common/withWindowDimensions"
import { getForecast, getForecastTooltip } from "../../actions/forecast"
import Chart, { ChartMouseEvent, ZoomOptions } from "../common/Chart"
import Text from "../common/Text"
import { ClipLoader } from "react-spinners"
import moment from "moment"
import TooltipContents from "../common/styled/TooltipContents"
import { debounce } from "../../common/utils"
import { ForecastTooltipTable } from "./ForecastTooltipTable"
import { ForecastTooltipResponse } from "../../actions/types/forecast"

export interface ForecastProps extends WithWindowDimensionsProps {
    getForecast: () => void
    charts: any[]
    loading: boolean
    forecasts: any
}
export interface ForecastState { 
    zoomOptions?: ZoomOptions;
    tooltipY: number;
    tooltipX: number;
    chartHovering: boolean;
    showTooltip: {
        [key: string]: boolean
    };
    tooltipPayload: {
        [key: string]: ForecastTooltipResponse | undefined
    };
    showTable: {
        [key: string]: boolean
    };
    loadingTable: boolean;
    
}

class Forecast extends Component<ForecastProps, ForecastState> {
    mainView?: any
    resultsRef?: any

    constructor(props: ForecastProps) {
        super(props)

        this.state = {
            tooltipX: 0,
            tooltipY: 0,
            showTooltip: { },
            showTable: { },
            tooltipPayload: { },
            loadingTable: false,
            chartHovering: false,
        };
    }

    componentDidMount(): void {
        this.loadData();
    }

    loadData = async () => {
        this.props.getForecast()
    }

    onZoom = (_id: string, _chart: any, zoomOptions: ZoomOptions) => {
        this.setState({ zoomOptions })
    }

    onChartMouseOver = debounce(async (id: string, event: ChartMouseEvent): Promise<boolean> => {
        if (id === 'regimes') {
            return this.onChartMouseOut(id, event);
        }
        if (!this.state.chartHovering) {
            return false;
        }

        this.setState({ loadingTable: true })

        const { x } = event
        const date = moment(new Date(x)).format('YYYY-MM-DD')
        const payload = await getForecastTooltip(id, date)
        
        this.setState({ 
            loadingTable: false,
            showTooltip: {
                ...this.state.showTooltip,
                [id]: false
            },
            showTable: {
                ...this.state.showTable,
                [id]: true
            },
            tooltipPayload: {
                ...this.state.tooltipPayload,
                [id]: payload as any
            },
        })
        return false;
    }, 500)

    onChartMouseOut = (id: string, _event: ChartMouseEvent): boolean => {
        this.setState({ 
            showTooltip: {
                ...this.state.showTooltip,
                [id]: false
            },
            showTable: {
                ...this.state.showTable,
                [id]: false
            },
            tooltipPayload: {
                ...this.state.tooltipPayload,
                [id]: undefined
            },
        })

        return false;
    }

    onChartContainerMouseOver = (event: any): void => {
        this.setState({ tooltipX: event.clientX, tooltipY: event.clientY })
    }

    onChartContainerMouseEnter = () => {
        this.setState({ 
            chartHovering: true,
        })
    }

    onChartContainerMouseLeave = () => {
        this.setState({ 
            chartHovering: false,
            showTooltip: {},
            showTable: {},
            tooltipPayload: {},
        })
    }


    render() {
        const { charts, loading, forecasts } = this.props;
        const { zoomOptions, tooltipPayload, loadingTable } = this.state
        return (
            <Box ref={view => this.mainView = view } sx={{ display: 'flex', flexDirection: 'column', minHeight: '50rem' }}>
                {loading && 
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', marginTop: '2rem' }}>
                        <Text>Loading...</Text>
                        <ClipLoader loading={true} size={14} />
                    </Box>
                }
                {!loading && charts && charts.map(({ id, title, chart }: any) => {
                        return (
                                <Box key={id} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Text type='h4'>{title}</Text>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Box onMouseOver={this.onChartContainerMouseOver} onMouseEnter={this.onChartContainerMouseEnter}  onMouseLeave={this.onChartContainerMouseLeave}  sx={{ width: '100rem' }}>
                                            <Chart id={id} onChartMouseOver={this.onChartMouseOver} chartOptions={chart} onZoom={this.onZoom} zoomOptions={zoomOptions} />
                                        </Box>
                                        {(tooltipPayload[id] || forecasts[id]) && 
                                            <Box sx={{ width: '70rem' }}>
                                                <Text type='h4' sx={{ marginBottom: '1rem' }}>Top 2, Bottom 2 Forecast Contributors ({tooltipPayload[id]?.date ?? forecasts[id].date }){loadingTable ? <ClipLoader loading={true} size={14} /> : ''}</Text>
                                                <ForecastTooltipTable
                                                    data={tooltipPayload[id] ?? forecasts[id]}
                                                />
                                            </Box>
                                        }
                                    </Box>
                                  
                                </Box>                        
                        )
                    })
                }
            </Box>
        )
    }
}

const mapStateToProps = ({ forecast }: any ) => {
    const { charts, loading, forecasts } = forecast
    return { loading, charts, forecasts }
}

export default withWindowDimensions(connect(mapStateToProps, { getForecast })(Forecast))
