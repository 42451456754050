import React, { FunctionComponent, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { BaseProps } from '../types';
import { Panel } from '../common/Panel';
import { Button } from '../common/Button';
import { TextFieldItem } from '../common/FormField';
import { updatePassword as updatePasswordAction } from '../../actions/authentication';
import ErrorAlert from '../common/ErrorAlert';
import SuccessAlert from '../common/SuccessAlert';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../common/hooks/useAuth';
import { getQueryParams } from '../../common/utils';
import { ERROR_CODE_MESSAGES } from '../../actions/types/authentication';
import Text from '../common/Text';
import { useQuery } from '../common/useQuery';

interface Props extends BaseProps {
    email?: string
    skipRedirect?: boolean
    skipAuthUpdate?: boolean
    updatePassword: (email: string, currentPassword: string, password: string, confirmPassword: string, token?: string) => Promise<any>
}

const UpdatePassword: FunctionComponent<Props> = ({ email: propEmail, skipRedirect, skipAuthUpdate, updatePassword }) => {
    const navigate = useNavigate()
    const { setAuthentication } = useAuth()
    const [startingErrorCode] = useState<string>(getQueryParams().error_code)
    const [errors, setErrors] = useState<string[]>([])
    const [message, setMessage] = useState<string>()
    const [email, setEmail] = useState<string>(propEmail ?? getQueryParams().email ?? '')
    const [currentPassword, setCurrentPassword] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false);
    const query = useQuery()
    const token = query.get('token') ?? undefined
    const hasErrors = errors?.length > 0
    const hasMessage = !!message

    React.useEffect(() => {
      setEmail(propEmail ?? getQueryParams().email ?? '')
    }, [setEmail, propEmail, getQueryParams().email])
    

    const onClearErrors = useCallback(() => {
        setErrors([])
    }, [])

    const onClearMessage = useCallback(() => {
        setMessage('')
    }, [])
    
    const onClearAllMessage = useCallback(() => {
        onClearErrors()
        onClearMessage()
    }, [])

    const onUpdatePassword = useCallback(async() => {
        onClearAllMessage()
        if (!password || !confirmPassword) {
            setErrors(['Please enter your desired password and password confirmation'])
            return
        }
        setLoading(true)
        const { success, authorization, errors, path } = await updatePassword(email, currentPassword, password, confirmPassword, token)
        setLoading(false)
        if (success) {
            setMessage('Password succesfully updated!')
            if (!skipAuthUpdate) {
                setAuthentication({ auth: authorization })
            }
            if (!skipRedirect) {
                setTimeout(() => {
                    navigate(path ?? '/dashboard')
                }, 2500);
            }
        } 

        if(!success && errors) {
            setErrors(errors)
        }
        
        if(!success && !errors) {
            setErrors(['Password reset was not successful, please try again'])
        }
        
    }, [onClearAllMessage, email, currentPassword, password, confirmPassword]);

    return (
        <Box>
            {startingErrorCode && ERROR_CODE_MESSAGES[startingErrorCode] && 
                <Box sx={{ padding: '2rem' }}>
                    {startingErrorCode && ERROR_CODE_MESSAGES[startingErrorCode].map((message: string) => {
                        return !message ? <br /> : <Box><Text type='text_small'>{message}</Text></Box>
                    })}
                </Box>
            }
            <ErrorAlert
                sx={{ margin: '1rem' }}
                visible={hasErrors}
                wrapChildrenInArray={false}
                onDismiss={onClearErrors}>
                    <Box sx={{ display: 'flex', flexDirection: 'column '}}>
                        {Array.isArray(errors) && errors.map((error: string) => {
                            return <Box>{error}</Box>
                        })}
                        {!Array.isArray(errors) && errors}
                    </Box>
            </ErrorAlert>
            <SuccessAlert
                sx={{ margin: '1rem' }}
                visible={hasMessage}
                onDismiss={onClearMessage}>
                    <Box sx={{ display: 'flex', flexDirection: 'column '}}>
                        {message}
                    </Box>
            </SuccessAlert>
                <Box sx={{ display: 'flex', flexDirection: 'column', padding: '2rem', alignItems: 'center' }}>
                    <Box>
                        {!propEmail && !getQueryParams().email &&
                        <TextFieldItem
                            sx={{ 
                                width: {
                                    xs: '100%',
                                    md: '35rem'
                                }
                            }}
                            type={'email'}
                            title={'Email Address'}
                            value={email}
                            onValueChange={setEmail}
                            inline
                        />}
                        <TextFieldItem
                            sx={{ 
                                width: {
                                    xs: '100%',
                                    md: '35rem'
                                }
                            }}
                            type={'password'}
                            title={'Current Password'}
                            value={currentPassword}
                            onValueChange={setCurrentPassword}
                            inline
                        />
                        <TextFieldItem
                            sx={{ 
                                width: {
                                    xs: '100%',
                                    md: '35rem'
                                }
                            }}
                            type={'password'}
                            title={'New Password'}
                            value={password}
                            onValueChange={setPassword}
                            inline
                        />
                        <TextFieldItem
                            sx={{ 
                                width: {
                                    xs: '100%',
                                    md: '35rem'
                                }
                            }}
                            type={'password'}
                            title={'Confirm New Password'}
                            value={confirmPassword}
                            onValueChange={setConfirmPassword}
                            inline
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2rem'}}>
                            <Button 
                                title={'Update Password'} 
                                onClick={onUpdatePassword}
                                loading={loading}
                                inlineLoading
                            />
                        </Box>
                    </Box>
                </Box>
        </Box>
    )
}

export default connect(null, { updatePassword: updatePasswordAction })(UpdatePassword)