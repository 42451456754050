import * as React from 'react';
import BaseButton, { ButtonProps as BaseButtonProps } from './BaseThemedButton';

export type ButtonVariant = 'primary' | 'secondary' | 'outline' | 'danger'
export interface ButtonProps extends BaseButtonProps {
    variant?: ButtonVariant
    autoFit?: boolean
}

export const Button = ({ className, variant = 'primary', autoFit, sx = { }, disabled, ...props}: ButtonProps) => {
    const autoFitProps = autoFit ? { width: 'unset', minWidth: 'unset', paddingLeft: '2rem', paddingRight: '2rem', height: '4rem', marginRight: '1rem', minHeight: 'unset' } : { }
    const disabledStyle = disabled ? { background: 'grey', color: '#FFF', pointerEvents: 'none' } : { }

    return (
        <BaseButton 
            {...props} 
            sx={{
                background: (theme) => `${theme.palette.button[variant].background} 0% 0% no-repeat padding-box`,
                borderRadius: 120,
                color: (theme) => theme.palette.button[variant].color,
                border: (theme) => `2px solid ${theme.palette.button[variant].border}`,
                width: `20em`,
                maxWidth: `30em`,
                paddingLeft: 'unset',
                paddingRight: 'unset',
                ...autoFitProps,
                ...disabledStyle,
                ...sx,
            }}
            disabled={disabled}
        />
    )
}

export default Button