import React, { Component } from 'react'
import { connect } from 'react-redux';
import Button from '../common/Button';

//@ts-ignore
import { onSelectedKeyChanged, onPageChange, resetActiveIndex } from '../../actions/session';
import { addProfileHistory } from '../../actions/profileHistory';
import VIEWS, { VIEW_KEYS } from './views'
import { Box } from '@mui/material';
import { CircleActionButton } from '../common/styled/ShapedActionButton'

interface TabFooterProps {
    views: any[]
    view: any
    pageCount: number
    tabCount: number
    tabIndex: number
    pageIndex: number
    showPrev: boolean
    showNextPage: boolean
    showNextModule: boolean
    selectedKey: string
    tab?: string
    showAddProfileHistory: boolean
    hasHistoryFeature: boolean
    style?: any
    title: string
    hidePageInfo?: boolean
    additionalActions?: React.ReactNode
    onSelectedKeyChanged: (key: string, backToStart: boolean) => void
    addProfileHistory: () => void
    onPageChange: (key: string, pageIndex: number) => void
    resetActiveIndex: (key: string) => void
}

class TabFooter extends Component <TabFooterProps> {

    constructor(props) {
        super(props)
    }

    onNextPage = () => {
        const { pageIndex, pageCount, selectedKey } = this.props
        window.scrollTo({
            top: 0,
            behavior: "auto"
        });

        const nextPage = pageIndex + 1
        if (nextPage < pageCount) {
            this.props.onPageChange(selectedKey, nextPage)

            return
        } 
        
        this.onNextModule()
    }

    onNextModule = () => {
        const { tabIndex, tabCount, views } = this.props
        window.scrollTo({
            top: 0,
            behavior: "auto"
        });

        const nextTabIndex = tabIndex + 1
        if(nextTabIndex < tabCount) {
            const nextTabView = views[nextTabIndex]

            const key = VIEWS[nextTabView].eventKey
            this.props.resetActiveIndex(key)
            this.props.onSelectedKeyChanged(key, false)
        }
    }

    onPrevPage = () => {
        const { tabIndex, pageIndex, views, selectedKey } = this.props
        window.scrollTo({
            top: 0,
            behavior: "auto"
        });
        
        if (pageIndex > 0) {
            const prevPage = pageIndex - 1

            this.props.onPageChange(selectedKey, prevPage)

            return
        }

        if(tabIndex > 0) {
            const prevTabIndex = tabIndex - 1
            const prevTabView = views[prevTabIndex]

            this.props.onSelectedKeyChanged(VIEWS[prevTabView].eventKey, false)
        }
    }

    render() {
        const { title: propTitle, view, pageIndex, pageCount, showNextPage, showNextModule, showPrev, showAddProfileHistory = true, hasHistoryFeature, additionalActions, hidePageInfo } = this.props
        const title = propTitle || view?.title
        const shouldShowNextModule = showNextModule && pageIndex >= (pageCount - 1)
        const shouldShowPageInfo = !hidePageInfo

        return (
            <Box sx={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '2rem',
                height: '5rem',
                width: '100%',
            }}>
                
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    position: 'absolute',
                    left: 0,
                }}>
                </Box>

                {shouldShowPageInfo &&
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                        width: '100%',
                    }}>
                        <CircleActionButton
                            action={'previous'}
                            onClick={this.onPrevPage}
                            disabled={!showPrev}
                        />
                        {title && 
                            <Box
                                sx={{ 
                                    marginLeft: '2rem',
                                    marginRight: '2rem',
                                    color: (theme) => theme.palette.primary.main,
                                    font: (theme) => `normal normal 600 ${theme.typography.pxToRem(24)}/${theme.typography.pxToRem(31)} Montserrat`,
                                    textAlign: 'center',
                                }}>
                                {title}<br />{pageIndex + 1} of {pageCount}
                            </Box>
                        }
                        <CircleActionButton
                            action={'next'}
                            onClick={this.onNextPage}
                            disabled={!showNextPage && !showNextModule}
                        />
                    </Box>
                }
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    position: 'absolute',
                    right: 0,
                    overflow: 'visible',
                }}>
                    {additionalActions &&
                        <Box sx={{ marginRight: '1rem', height: '4.5rem' }}>
                            {additionalActions}
                        </Box>
                    }
                </Box>
                <Box sx={{ 
                    display: 'flex',
                    position: 'absolute',
                    top: '6.75rem',
                    right: 0,
                }}>
                </Box>
            </Box>
        )
    }
}

const mapStateToProps = ({ session }, componentProps) => {
    const { views, selectedKey: sessionSelectedKey } = session
    const selectedKey = sessionSelectedKey || componentProps.tab
    
    const viewKey = VIEW_KEYS.find((key) => VIEWS[key].eventKey === selectedKey)
    const view = VIEWS[viewKey || '']
    const hasHistoryFeature = views?.includes('profile_history')
    
    const tabCount = views?.length || 0
    const pageCount = view?.pages?.length || 1

    const tabIndex = Math.max(views?.indexOf(view?.key), 0)
    const pageIndex = session[`${selectedKey}ActiveIndex`] || 0
    const showPrev = pageIndex > 0 || tabIndex > 0
    const showNextPage = pageIndex < (pageCount - 1)
    const showNextModule = !showNextPage && tabIndex < (tabCount - 1)

    return { viewKey, selectedKey, views, view, tabCount, pageCount, tabIndex, pageIndex, showPrev, showNextPage, showNextModule, hasHistoryFeature, ...componentProps }
}

export default connect(mapStateToProps, { onSelectedKeyChanged, onPageChange, addProfileHistory, resetActiveIndex })(TabFooter)
