import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../reducers';
import { Box } from '@mui/material';
import { ChartOptions } from 'highcharts';
import { getHistoricalTimesChart } from '../../actions/marketDashboard';
import { HISTORICAL_TIME_SERIES_CHART_OPTIONS, HistoricalSeriesType, Type } from '../../actions/types/marketDashboard';
import Chart from '../common/Chart';
import { Select } from '../common/styled/Select';
import { SelectOption } from '../../common/types';
import { TimeRangePicker } from '../common/TimeRangePicker';
import { DatePicker } from '../common/styled/DatePicker';
import { DEFAULT_LIVE_MAX_DATE, DEFAULT_MIN_DATE } from '../../actions/types/portfolioDesigner';
import { TIME_RANGES, TimeRange, TimeRangeDates } from '../common/ranges';

import { WithWindowDimensionsProps, withWindowDimensions } from '../common/withWindowDimensions';
import Text from '../common/Text';
import PollingComponent from './PollingComponent';

interface ReduxStateProps {
    historicalSeriesChart?: ChartOptions,
    historicalSeriesLastTradeTime?: string,
}

interface ReduxActionProps extends WithWindowDimensionsProps {
    getHistoricalTimesChart: typeof getHistoricalTimesChart
}


interface ComponentState {
    selectedHistoricalTimeSeriesOption: SelectOption<HistoricalSeriesType> | null;
    start: Date;
    end: Date;
    range?: TimeRange;
}

type ComponentProps = ReduxActionProps & ReduxStateProps

class HistoricalSeries extends PollingComponent<ComponentProps, ComponentState> {
    state: ComponentState = {
        selectedHistoricalTimeSeriesOption: HISTORICAL_TIME_SERIES_CHART_OPTIONS[0],
        start: DEFAULT_MIN_DATE,
        end: DEFAULT_LIVE_MAX_DATE,
        range: TIME_RANGES.find(range => range.unit === 'max'),
    }

    poll = async (): Promise<void> => {
        const { start, end, selectedHistoricalTimeSeriesOption } = this.state;
        if (selectedHistoricalTimeSeriesOption?.item) {
            this.props.getHistoricalTimesChart(selectedHistoricalTimeSeriesOption.item, start, end)
        }
    }

    onHistoricalTimeSeriesChange = (option: SelectOption<HistoricalSeriesType> | null) => {
        this.setState({ selectedHistoricalTimeSeriesOption: option })
        const { start, end } = this.state;
        if (option?.item) {
            this.props.getHistoricalTimesChart(option.item, start, end)
        }
    }

    onStartDateUpdated = (date: Date) => {
        const {end, selectedHistoricalTimeSeriesOption} = this.state;
        this.setState({ start: date, range: undefined })
        if (selectedHistoricalTimeSeriesOption?.item) {
            this.props.getHistoricalTimesChart(selectedHistoricalTimeSeriesOption.item, date, end)
        }
    }

    onEndDateUpdated = (date: Date) => {
        const {start, selectedHistoricalTimeSeriesOption} = this.state;
        this.setState({ end: date, range: undefined })
        if (selectedHistoricalTimeSeriesOption?.item) {
            this.props.getHistoricalTimesChart(selectedHistoricalTimeSeriesOption.item, start, date)
        }
    }

    onRangePicker = (range: TimeRange, { start_date, end_date }: TimeRangeDates) => {
        const {range: stateRange, selectedHistoricalTimeSeriesOption} = this.state;

        if (range.unit === stateRange?.unit && range.duration === stateRange?.duration) {
            this.setState({ range: undefined })

            return;
        }

        this.setState({ range, start: start_date, end: end_date })
        if (selectedHistoricalTimeSeriesOption?.item) {
            this.props.getHistoricalTimesChart(selectedHistoricalTimeSeriesOption.item, start_date, end_date)
        }
    }
    
    render() {
        const { start, end, range } = this.state;
        const { historicalSeriesChart, historicalSeriesLastTradeTime } = this.props;
        return (
            <Box>
                <Box sx={{ display: 'flex', marginTop: '5rem', marginBottom: '1rem', flexDirection: { xs: 'column',  xxl: 'row' }, alignItems: { xs: 'flex-start', xxl: 'center'} }}>
                    <Select sx={{ width: '15rem', marginRight: '2rem', height: '5rem' }} options={HISTORICAL_TIME_SERIES_CHART_OPTIONS} value={this.state.selectedHistoricalTimeSeriesOption} onChange={this.onHistoricalTimeSeriesChange} />
                    
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'column', xxl: 'row' } , alignItems: { xs: 'center', md: 'flex-start', xxl: 'center' }, marginTop: { xs: '1rem', xxl: 'unset' }, }}>
                        <TimeRangePicker ranges={['1M', '3M', 'YTD', '1Y', '3Y', 'MAX']} onRangePicker={this.onRangePicker} value={range} />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: { xs: '0rem', md: '1rem', xxl: '0rem' },
                            marginLeft: { xs: '2.5rem', md: '0rem', xxl: '2.5rem' },
                        }}>
                            <DatePicker
                                sx={{ marginRight: '1rem'}}
                                date={start ?? DEFAULT_MIN_DATE}
                                format={'MM/dd/yyyy'}
                                minDate={DEFAULT_MIN_DATE}
                                maxDate={DEFAULT_LIVE_MAX_DATE}
                                onDateChanged={this.onStartDateUpdated} 
                            />
                            <DatePicker
                                date={end ?? DEFAULT_LIVE_MAX_DATE}
                                format={'MM/dd/yyyy'}
                                minDate={DEFAULT_MIN_DATE}
                                maxDate={DEFAULT_LIVE_MAX_DATE}
                                onDateChanged={this.onEndDateUpdated} 
                            />
                        </Box>
                    </Box>
                </Box>
                <Chart id='historicalSeriesChart' chartOptions={historicalSeriesChart as any} sx={{ width: { xs: this.props.dimensions.width - 100, lg: this.props.dimensions.width - 950} }} />
                {historicalSeriesLastTradeTime && <Text sx={{ marginTop: '1rem' }}>As of: {historicalSeriesLastTradeTime}</Text>}
            </Box>
        )
    }
}


const mapStateToProps = ({ marketDashboard }: ReduxState): ReduxStateProps => {
    const { historicalSeriesChart, historicalSeriesLastTradeTime } = marketDashboard;

    return { historicalSeriesChart, historicalSeriesLastTradeTime }
}

export default withWindowDimensions(connect(mapStateToProps, { getHistoricalTimesChart })(HistoricalSeries as any))