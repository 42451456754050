import { ChartOptions, SelectOption, TableRowData } from '../common/types';
import {
    PROFILE_RESET,
    LOGOUT,
    SIMPLIFY_RISK_ANALYZER_LOADING,
    SIMPLIFY_RISK_ANALYZER_RECEIVED,
    SIMPLIFY_RISK_ANALYZER_LOADING_USER_INPUTS,
    SIMPLIFY_RISK_ANALYZER_LOADING_RISK_OUTPUT
} from '../actions/types/actions';
import { PositionDetail, ShockValue, Shocks } from '../actions/types/simplifyTools';

export interface SimplifyRiskAnalyzerState {
  loadingUserInputs: boolean;
  loadingRiskOutput: boolean;
  tickers: string[];
  tickerOptions: SelectOption[];
  selectedTicker: SelectOption | null;
  hasResults: boolean;
  userInputs: Shocks;
  userInputHeaders: string[];
  userInputRows: TableRowData<ShockValue>[];
  riskOutputHeaders: string[];
  riskOutputPercentageRows: TableRowData<PositionDetail>[];
  riskOutputDollarRows: TableRowData<PositionDetail>[];
  pnlHeaders: string[],
  pnlRows: TableRowData[],
  pnlTimestamp?: string;
}

export interface SimplifyRiskAnalyzerProps extends Omit<SimplifyRiskAnalyzerState, 'loading'> {}

const INITIAL_STATE: SimplifyRiskAnalyzerState = { 
  loadingUserInputs: false,
  loadingRiskOutput: false,
  selectedTicker: null,
  tickers: [],
  tickerOptions: [],
  hasResults: false,
  userInputs: {},
  userInputHeaders: [],
  userInputRows: [],
  riskOutputHeaders: [],
  riskOutputPercentageRows: [],
  riskOutputDollarRows: [],
  pnlHeaders: [],
  pnlRows: [],
};

export default (state = INITIAL_STATE, action: any): SimplifyRiskAnalyzerState => {
  const { payload, type } = action

  switch (type) {
    case SIMPLIFY_RISK_ANALYZER_LOADING_USER_INPUTS:
        return { ...state, loadingUserInputs: payload }
    case SIMPLIFY_RISK_ANALYZER_LOADING_RISK_OUTPUT:
        return { ...state, loadingRiskOutput: payload }
    case SIMPLIFY_RISK_ANALYZER_RECEIVED:
        return { ...state, ...payload }
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

