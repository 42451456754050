import { Component } from "react"
import { connect } from 'react-redux'
import { ResultTable } from "../../../ResultTable"
import { updateParameter } from "../../../../../actions/portfolioDesigner"
import { ResultsTableData } from "../../../../../actions/types/portfolioDesigner"
import { ReduxState } from "../../../../../reducers"

export interface SnapshotTableProps {
    snapshot: ResultsTableData
    benchmarkSnapshotLoading: boolean
    portfolioSnapshotLoading: boolean
    updateParameter: (payload: any) => any
}
export interface SnapshotTableState { }
class SnapshotTable extends Component<SnapshotTableProps, SnapshotTableState> {
    mainView?: any
    resultsRef?: any

    constructor(props: SnapshotTableProps) {
        super(props)

        this.state = {
            runningAnalyzer: false,
            runningHistoricalAnalysis: false,
            reportItems: [],
        };
    }

    render() {
        const { snapshot, benchmarkSnapshotLoading, portfolioSnapshotLoading } = this.props
        if (!snapshot) {
            return null;
        }

        return (
            <ResultTable
                loadingBenchmark={benchmarkSnapshotLoading}
                loadingPortfolio={portfolioSnapshotLoading}
                containerSx={{
                    width: '45rem',
                    marginTop: {
                        xs: '2rem',
                        md: undefined, 
                    },
                    marginRight: {
                        xs: undefined,
                        md: '5rem',
                    }
                }}
                results={snapshot}
            />
        )
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState ) => {
    const { benchmarkSnapshotLoading, portfolioSnapshotLoading, snapshot } = portfolioDesigner

    return { benchmarkSnapshotLoading, portfolioSnapshotLoading, snapshot }
}

export default connect(mapStateToProps, { updateParameter })(SnapshotTable as  any)