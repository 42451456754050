import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { Box, List } from '@mui/material';
import Button from '../../common/Button';
import { DueDiligenceChartAnnotations, DueDiligenceChartAnnotationsEdit, DueDiligenceChartDTO, Metric } from '../../../actions/types/dueDiligence';
import { updateDueDiligenceChart, processDueDiligenceChart } from '../../../actions/dueDiligence';
import TickerInput from '../../PortfolioDesigner/TickerInput';
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, ModelItemOption, Ticker } from '../../../actions/types/portfolioDesigner';
import { createDueDiligenceChartAnnotations, updateDueDiligenceChartAnnotations } from '../../../actions/dueDiligence';
import Text from '../../common/Text';
import ListRow, { RowType } from './ListRow';
import moment from 'moment';
import { LooseObject } from '../../../common/types';
import TickerRow from './TickerRow';
import AnnotationEditModal from './AnnotationEditModal';
import { getTickerSymbolAndDescription } from '../../../common/utils';
import { addError } from '../../../actions/notifications';
import { NumericFilterInput } from '../Screener/NumericFilterInput';
import { Select } from '../../../components/common/styled/Select';

const METRIC_ITEMS: Metric[] = [Metric.TOTAL_RETURN, Metric.PRICE, Metric.PRICE_RETURNS, Metric.DRAWDOWN]
const METRIC_ITEM_LABELS: { [key: string]: string } = {
    [Metric.TOTAL_RETURN]: 'Total Return',
    [Metric.PRICE]: 'Price',
    [Metric.PRICE_RETURNS]: 'Price (%)',
    [Metric.DRAWDOWN]: 'Drawdown'
}

interface ReduxStateProps {
    item?: DueDiligenceChartDTO
    annotations: DueDiligenceChartAnnotations[]
    dueDiligenceChartOptions: LooseObject
    processingChart: boolean    
    hypotheticalDataUsed?: boolean
    models: ModelItemOption[];
    configs: ModelItemOption[];
}

interface ReduxActionProps {
    updateDueDiligenceChart: typeof updateDueDiligenceChart;
    processDueDiligenceChart: typeof processDueDiligenceChart;
    createDueDiligenceChartAnnotations: typeof createDueDiligenceChartAnnotations;
    updateDueDiligenceChartAnnotations: typeof updateDueDiligenceChartAnnotations;
    addError: typeof addError;
}

type ComponentProps = ReduxActionProps & ReduxStateProps

interface ComponentState {
    start_date?: Date;
    end_date?: Date;
    showEditAnnotation: boolean;
    annotationItem?: DueDiligenceChartAnnotationsEdit
}

class InputTab extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {
        start_date: DEFAULT_MIN_DATE,
        end_date: DEFAULT_MAX_DATE,
        showEditAnnotation: false
    }

    componentDidMount(): void {
        this.updateDateRangeFromItem(this.props.item);
    }

    updateDateRangeFromItem = (item?: DueDiligenceChartDTO) => {
        if (!item) {
            this.setState({ start_date: DEFAULT_MIN_DATE, end_date: DEFAULT_MAX_DATE })

            return;
        }

        const {start_date, end_date} = item;
        this.setState({ start_date: moment(start_date ?? DEFAULT_MIN_DATE).toDate(), end_date: moment(end_date ?? DEFAULT_MAX_DATE).toDate()})
    }

    componentDidUpdate(prevProps: Readonly<ComponentProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.item?.start_date !== this.props.item?.start_date || prevProps.item?.end_date !== this.props.item?.end_date) {
            this.updateDateRangeFromItem(this.props.item);
        }
        if (this.props.hypotheticalDataUsed && prevProps.hypotheticalDataUsed !== this.props.hypotheticalDataUsed) {
            this.onMetricCheckChanged(Metric.TOTAL_RETURN, true)
        }
    }

    onTickerSelected = (item?: Ticker) => {
        const {item: chart} = this.props;
        if(!chart || !item?.symbol) {
            return;
        }

        const { symbol } = getTickerSymbolAndDescription(item);

        const {id, tickers, selected_tickers } = chart;
        if (tickers?.includes(symbol)) {
            return;
        }
        const isCustomOrIndex = symbol.toUpperCase().endsWith('-CUSTOM') || symbol.toUpperCase().endsWith('-INDEX')
        const metrics = isCustomOrIndex ? [Metric.TOTAL_RETURN] : chart.metrics
        if (isCustomOrIndex) {
            this.onMetricCheckChanged(Metric.TOTAL_RETURN, true)
        }

        const selectedTickers = [...selected_tickers, symbol];
        this.props.updateDueDiligenceChart(id, { metrics, tickers: [...(tickers ?? []), symbol], selected_tickers: selectedTickers })
    }

    onTickerRemoved = (ticker: string) => {
        const {item: chart} = this.props;
        if(!chart) {
            return;
        }

        const {id, tickers, selected_tickers, rc_tickers} = chart;
        const tickerUpdate = tickers?.filter(item => item !== ticker);
        let payload: any = {
            tickers: tickerUpdate,
        }
        if (selected_tickers?.includes(ticker)) {
            payload = {
                ...payload,
                selected_tickers: selected_tickers?.filter(item => item !== ticker),
            }
        }
        if (rc_tickers?.includes(ticker)) {
            payload = {
                ...payload,
                rc_tickers: rc_tickers?.filter(item => item !== ticker),
            }
        }
        
        this.props.updateDueDiligenceChart(id, payload)
    }

    onTickerCheckChanged = (ticker: string, checked: boolean) => {
        const {item: chart} = this.props;
        if(!chart) {
            return;
        }

        const {id, selected_tickers} = chart;
        let tickerUpdate = checked ? [...(selected_tickers ?? []), ticker] : selected_tickers?.filter(item => item !== ticker);

        this.props.updateDueDiligenceChart(id, { selected_tickers: tickerUpdate })
    }

    onModelSelected = (modelItem: ModelItemOption) => {
        const {item: chart} = this.props;
        if(!chart) {
            return;
        }

        const models = chart?.models ?? [];
        const filtreredModels = models.filter((modelId) => modelId && modelId !== modelItem.item.id);
        const updatedModels = [...filtreredModels, modelItem.item.id];

        const selectedModels = chart?.selected_models ?? [];
        const selectedModelsFiltered = selectedModels.filter((modelId) => modelId && modelId !== modelItem.item.id);
        const updatedSelectedModels = [...selectedModelsFiltered, modelItem.item.id];

        this.props.updateDueDiligenceChart(chart?.id, { models: updatedModels, selected_models: updatedSelectedModels })
    }

    onRemoveModel = (id: string) => {
        const {item: chart} = this.props;
        if(!chart) {
            return;
        }

        const models = chart?.models ?? [];
        const filtreredModels = models.filter((modelId) => modelId && modelId !== Number(id));

        const selectedModels = chart?.selected_models ?? [];
        const selectedModelsFiltered = selectedModels.filter((modelId) => modelId && modelId !== Number(id));

        this.props.updateDueDiligenceChart(chart?.id, { models: filtreredModels, selected_models: selectedModelsFiltered })
    }


    onModelCheckChanged = (id: string, checked: boolean) => {
        const {item: chart} = this.props;
        if(!chart) {
            return;
        }

        const {selected_models} = chart;
        const filtered = selected_models?.filter(item => Number(item) !== Number(id))
        let selectedModelsUpdate = checked ? [...(filtered ?? []), Number(id)] : filtered;

        this.props.updateDueDiligenceChart(chart.id, { selected_models: selectedModelsUpdate })
    }

    onMetricCheckChanged = (metric: string, checked: boolean) => {
        const {item: chart} = this.props;
        if(!chart) {
            return;
        }

        const {id, metrics} = chart;
        let metricsUpdated = metrics.filter(item => item !== metric);
        if (checked) {
            metricsUpdated = [...metricsUpdated, metric as Metric]
        }
        if (metric === Metric.DRAWDOWN && checked) {
            metricsUpdated = [Metric.DRAWDOWN]
        } else {
            metricsUpdated = metricsUpdated.filter(item => item !== Metric.DRAWDOWN)
        }
        if (metricsUpdated.length === 0) {
            metricsUpdated = [metric as Metric]
        }
        

        this.props.updateDueDiligenceChart(id, { metrics: metricsUpdated })
    }

    onEditTicker = (ticker: string) => {
        const { item, annotations } = this.props;
        if (!item) {
            return;
        }
        const annotationItem = annotations.find((annotation) => annotation.due_diligence_chart_id === item.id && annotation.symbol === ticker)

        this.setState({ annotationItem: annotationItem ?? { due_diligence_chart_id: item.id, symbol: ticker }, showEditAnnotation: true })
    }

    onCloseAnnotationModal = () => {
        this.setState({
            showEditAnnotation: false,
            annotationItem: undefined,
        })
    }

    onSaveAnnotation = (item: DueDiligenceChartAnnotationsEdit, requiresProcessing: boolean) => {
        if (item.id) {
            this.props.updateDueDiligenceChartAnnotations(item, requiresProcessing)
        } else {
            this.props.createDueDiligenceChartAnnotations(item, requiresProcessing)
        }
        this.onCloseAnnotationModal()
    }

    onRenderTickerRow = (ticker: string) => {
        return <TickerRow ticker={ticker} onEdit={this.onEditTicker} onCheckChanged={this.onTickerCheckChanged} onRemove={this.onTickerRemoved} checked={this.props.item?.selected_tickers?.includes(ticker) ?? false} />
    }

    onRenderModelRow = (id: number) => {
        const { models, configs } = this.props;
        const combined = [...models, ...configs];
        const model = combined.find((model) => model.item.id.toString() === id.toString());
        if (!model) {
            return null;
        }

        return <ListRow key={id} type={RowType.CHECKBOX} item={model.item.id.toString()} label={model.item.name} onCheckChanged={this.onModelCheckChanged} onRemove={this.onRemoveModel} checked={this.props.item?.selected_models?.includes(Number(id)) ?? false} showRemove showChecked />
    }

    render() {
        const {item, models, configs} = this.props;
        const {annotationItem, showEditAnnotation} = this.state;

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <AnnotationEditModal
                    item={annotationItem}
                    visible={showEditAnnotation}
                    onSave={this.onSaveAnnotation}
                    onCancel={this.onCloseAnnotationModal}
                />
                <Box sx={{ display: 'flex', width: '35rem', flexDirection: 'column' }}>
                    <Text type={'form_header'} sx={{ marginBottom: '1rem' }}>Securities</Text>
                    <Box sx={{ display: 'flex'}}>
                        <TickerInput 
                            containerSx={{ flex: undefined, padding: '.5rem', width: '30rem', border: (theme) => `1px solid ${theme.palette.primary.main}` }}
                            placeholder={'Search for a ticker'}
                            onTickerSelected={this.onTickerSelected}
                        />
                        <Button disabled={this.props.processingChart} sx={{ minHeight: 'unset', minWidth: 'unset', ml: '1rem', height: '5rem', width: '5rem' }} title={'GO'} onClick={this.props.processDueDiligenceChart} />
                    </Box>
                    <List
                     sx={{
                        marginTop: '1rem',
                        marginBottom: '2.5rem',
                        padding: 0,
                        paddingBottom: '2.5rem',
                        minHeight: '20rem',
                    }}>
                        {item?.tickers.map((item, index) => {
                            return this.onRenderTickerRow(item)
                        })}
                    </List>
                </Box>
                <Box sx={{ display: 'flex', width: '35rem', flexDirection: 'column' }}>
                    <Text type={'form_header'} sx={{ marginBottom: '1rem' }}>Models</Text>
                    <Box sx={{ display: 'flex'}}>
                        <Select sx={{ width: '30rem' }} options={[...configs, ...models]} value={null} onChange={this.onModelSelected} />
                        <Button disabled={this.props.processingChart} sx={{ minHeight: 'unset', minWidth: 'unset', ml: '1rem', height: '5rem', width: '5rem' }} title={'GO'} onClick={this.props.processDueDiligenceChart} />
                    </Box>
                    <List
                     sx={{
                        marginTop: '1rem',
                        marginBottom: '2.5rem',
                        padding: 0,
                        paddingBottom: '2.5rem',
                        minHeight: '20rem',
                    }}>
                        {item?.models?.map((item) => {
                            return this.onRenderModelRow(item)
                        })}
                    </List>
                </Box>
            </Box>
        )
    }
}


const mapStateToProps = ({ dueDiligence, portfolioDesigner }: ReduxState) => {
    const {selectedChartItem, dueDiligenceChartOptions, processingChart, selectedChartAnnotations: annotations, result} = dueDiligence;
    const models = portfolioDesigner.portfolioModels;
    const configs = portfolioDesigner.portfolioConfigs;

    return { item: selectedChartItem, dueDiligenceChartOptions, processingChart, annotations, hypotheticalDataUsed: result?.hypotheticalDataUsed, models, configs }
}

export default connect(mapStateToProps, { updateDueDiligenceChart, processDueDiligenceChart, createDueDiligenceChartAnnotations, updateDueDiligenceChartAnnotations, addError })(InputTab as any)