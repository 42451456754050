
import { StandardThunkAction } from '../store/store';
import { getLatestRiskPreferences } from './riskPreferences';
import { getStandardLivingRisk } from './standardLivingRisk';
import { SESSION } from "./types/actions"
export const VALID_GAUGE_TYPES = ['riskAversion', 'lossAversion']

export const PREFERENCES_ENDPOINT = '/api/preferences'

export const clientFinancialPlanOnLoad = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            dispatch({ type: SESSION, payload: { views: ['financial_plan'], defaultKey: 'financialPlan', selectedKey: 'financialPlan'}})
            dispatch(getLatestRiskPreferences())
            dispatch(getStandardLivingRisk())
        }catch(e: any) {
        }
    }
}