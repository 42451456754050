import React, { Component } from 'react'
import { Pager, PagerProvider } from '@crowdlinker/react-pager';
import { Size } from '../common/styled/SizeAwareBox';
import { BaseProps } from '../types';
import { Box } from '@mui/material';

interface PagerContainerProps extends BaseProps {
    activeIndex: number
    children: React.ReactNode[]
    gesturesEnabled?: boolean
    sizeOffsets?: Size
}

interface State {
    [index: number]: Size
    children: React.ReactNode[]
}
class PagerContainer extends Component <PagerContainerProps, State>{
    constructor(props) {
        super(props)
  
        this.state = {
            children: this.wrapChildren(props.children),
        }
    }

    onComponentSize = (index, size: Size) => {
        this.setState({ [index]: size })
    }

    wrapChildren = (children: React.ReactNode): React.ReactNode[] => {
        const mappedChildren = React.Children.map(children, (child: any, index) => {
            return child ? React.cloneElement(child, { onComponentSize: (size) => this.onComponentSize(index, size) }) : null
        })

        return mappedChildren || []
    }

    componentDidUpdate(prevProps: PagerContainerProps) {
        const { children: prevChildren } = prevProps
        const { children } = this.props
        if(children && prevChildren !== children) {
            this.setState({ children: this.wrapChildren(children) })
        }
    }

    render() {
        const { activeIndex = 0, sx={ }, gesturesEnabled = true, sizeOffsets = { height: 0, width: 0 } } = this.props
        const { children } = this.state
        const { height, width } = this.state[activeIndex] || { }

        return (
            <Box sx={{ position: 'relative', ...sx, height: height + sizeOffsets.height, width: width + sizeOffsets.width }}>
                <PagerProvider activeIndex={activeIndex}>
                    <Pager gestureOptions={{ enabled: gesturesEnabled }} adjacentChildOffset={0}>
                        {children}
                    </Pager>
                </PagerProvider>
            </Box>
        )
    }

}

export default PagerContainer
