import axios from 'axios'
import { getPathsClientId, sanatizeNumericValue } from '../common/utils'
import { StandardThunkAction } from '../store/store'
import { addError } from './notifications'
import { 
    INVESTMENT_POLICY_STATEMENT_RECEIVED,
    ABSOLUTE_RISK,
    RELATIVE_RISK,
    LIQUIDITY,
    TAX_CONCERNS,
    LEGAL_AND_REGULATORY_FACTORS,
    UNIQUE_CIRCUMSTANCES,
    TIME_HORIZON,
} from "./types/actions"
import { UpdateInvestmentPolicyStatementRequest } from './types/requests'

export const CLIENTS_ENDPOINT = '/api/clients'
export const INVESTMENT_POLICY_STATEMENT_ENDPOINT = 'investmentPolicyStatement'

export const getInvestmentPolicyStatement = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const profileId = getPathsClientId()
            if(!profileId) {
                return
            }
            const { data } = await axios.get(`${CLIENTS_ENDPOINT}/${profileId}/${INVESTMENT_POLICY_STATEMENT_ENDPOINT}`)
            const { success, absolute_risk, relative_risk, liquidity, tax_concerns, legal_and_regulatory_factors, unique_circumstances, time_horizon } = data
            
            if(!success) {
                return
            }
            
            dispatch({ type: INVESTMENT_POLICY_STATEMENT_RECEIVED, payload: { absolute_risk, relative_risk, liquidity, tax_concerns, legal_and_regulatory_factors, unique_circumstances, time_horizon } })
        } catch(e: any) {
            dispatch(addError('Investment Policy Statement', e.toString()))
        }
    }
}

export const sendInvestmentPolicyStatement = (payload: UpdateInvestmentPolicyStatementRequest): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const paths = window.location.pathname.split('/')
            const profileId = paths[paths.length - 1]

            const { data } = await axios.put(`${CLIENTS_ENDPOINT}/${profileId}/${INVESTMENT_POLICY_STATEMENT_ENDPOINT}`, payload)
            const { absolute_risk, relative_risk, liquidity, tax_concerns, legal_and_regulatory_factors, unique_circumstances } = data
            
    
            dispatch({ type: INVESTMENT_POLICY_STATEMENT_RECEIVED, payload: { absolute_risk, relative_risk, liquidity, tax_concerns, legal_and_regulatory_factors, unique_circumstances } })

        } catch(e: any) {
            dispatch(addError('Investment Policy Statement', e.toString()))
        }
    }
}

export const absoluteRiskUpdate = (payload: string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: ABSOLUTE_RISK, payload })
        dispatch(sendInvestmentPolicyStatement({ absolute_risk: payload }))
    }
}

export const relativeRiskUpdate = (payload: string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: RELATIVE_RISK, payload })
        dispatch(sendInvestmentPolicyStatement({ relative_risk: payload }))
    }
}

export const liquidityUpdate = (payload: string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: LIQUIDITY, payload })
        dispatch(sendInvestmentPolicyStatement({ liquidity: payload }))
    }
}

export const taxConcernsUpdate = (payload: string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: TAX_CONCERNS, payload })
        dispatch(sendInvestmentPolicyStatement({ tax_concerns: payload }))
    }
}

export const legalAndRegulatoryFactorsUpdate = (payload: string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: LEGAL_AND_REGULATORY_FACTORS, payload })
        dispatch(sendInvestmentPolicyStatement({ legal_and_regulatory_factors: payload }))
    }
}

export const uniqueCircumstancesUpdate = (payload: string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: UNIQUE_CIRCUMSTANCES, payload })
        dispatch(sendInvestmentPolicyStatement({ unique_circumstances: payload }))
    }
}

export const updateTimeHorizon = (payload: number | string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: TIME_HORIZON, payload })
        dispatch(sendInvestmentPolicyStatement({ time_horizon: sanatizeNumericValue(payload) }))
    }
}