import React, { Component } from "react"
import RichTextEditor from 'react-rte';
import { Box } from "@mui/material"
import { connect } from "react-redux"

import { TextFieldItem } from "../../common/FormField"
import { CopyItem } from "../../../actions/types/copy"
import { withRouter, WithRouterProps } from "../../common/withRouter"
import { getCopyItem, updateCopy } from "../../../actions/adminCopyEditor"
import { AdminPage } from "../AdminPage"
import Button from "../../common/Button"
import { UpdateCopyItemRequest } from "../../../actions/types/requests"

interface EditorProps extends WithRouterProps{
    editItem: CopyItem
    updateCopy: (item: UpdateCopyItemRequest) => void
    getCopyItem: (id: string) => void
}

interface EditorState extends CopyItem { 
    preview: boolean
    editorValue: any
}

class CopyEditor extends Component <EditorProps, EditorState> {
    constructor(props) {
        super(props)
        const { editItem = { } } = props
        this.state = {
            name: '',
            title: '',
            contents: '',
            system_managed: true,
            preview: false,
            ...editItem,
            editorValue: RichTextEditor.createValueFromString(editItem?.contents || '', 'html'),
        }
    }

    componentDidMount() {
        const { copyId = '' } = this.props.params
        this.props.getCopyItem(copyId)
    }

    componentDidUpdate(prevProps) {
        const { editItem: prevEditItem } = prevProps
        const { editItem = { } } = this.props
        
        if(prevEditItem !== editItem) {
            this.setState({
                name: '',
                title: '',
                contents: '',
                system_managed: true,
                ...editItem,
                editorValue: RichTextEditor.createValueFromString((editItem as CopyItem)?.contents || '', 'html'),
            })
        }
    }

    onSave = () => {
        const { id, name, title, editorValue } = this.state

        this.props.updateCopy({ id, name, title, contents: editorValue.toString('html') })
    }
    
    onSaveNameTitle = async() => {
        const { id, name, title } = this.state

        this.props.updateCopy({ id, name, title })
    }
    
    onSaveContent = async() => {
        const { id, contents } = this.state

       this.props.updateCopy({ id, contents })
    }

    onChange = (value) => {
        this.setState({ editorValue: value })
    }

    onNameChanged = (name: string) => {
        this.setState({ name })
    }

    onTitleChanged = (title: string) => {
        this.setState({ title })
    }

    onTogglePreview = () => {
        this.setState({ preview: !this.state.preview})
    }

    render() {
        const { name, title, contents, system_managed = true, preview } = this.state

        return(
            <AdminPage>
                <Box sx={{ 
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    padding: '1rem',
                    '& .ql-editor': {
                        maxHeight: '50rem',
                    },
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                            <TextFieldItem
                                sx={{ marginRight: '1rem' }}
                                title="Name"
                                value={name}
                                readOnly={!!system_managed}
                                onValueChange={this.onNameChanged}
                            />
                            <TextFieldItem
                                title="Title"
                                value={title}
                                readOnly={!!system_managed}
                                onValueChange={this.onTitleChanged}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <Button sx={{ minHeight: 'unset', height: '4rem', width: '15rem', marginBottom: '1rem', marginRight: '1rem' }} title={ preview ? 'Edit' : 'Preview'} onClick={this.onTogglePreview} />
                            <Button sx={{ minHeight: 'unset', height: '4rem', width: '15rem', marginBottom: '1rem', }} title="Save" onClick={this.onSave} />
                        </Box>
                    </Box>
                    {this.state.preview &&
                        <Box sx={{ marginTop: '5.5rem' }}>
                            <div dangerouslySetInnerHTML={{ __html: this.state.editorValue.toString('html') }}></div>
                        </Box>
                    }
                    {!this.state.preview &&
                        <RichTextEditor
                            value={this.state.editorValue}
                            onChange={this.onChange}
                        />
                    }
                </Box>
            </AdminPage>
        )
    }

}

const mapStateToProps = ({ copy }) => {
    const { editItem } = copy

    return { editItem }
}

export default withRouter(connect(mapStateToProps, { getCopyItem, updateCopy })(CopyEditor))