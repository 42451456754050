import React, { Component, FunctionComponent } from 'react'
import { connect } from 'react-redux'
import  * as moment from 'moment-timezone'
import 'moment-timezone'
import { getActivationCodes } from '../../../actions/adminActivationCodes'
import { getUsers, addUser, updateUser, deleteUser } from '../../../actions/adminUserManagement'
import { EditUser, MEMBERSHIP_MAPPING, NEW_USER, ROLE_MAPPING, User } from '../../../actions/types/users'
import { DataTable } from '../../common/DataTable'
import Button from '../../common/Button'
import { Box } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faUser } from '@fortawesome/free-solid-svg-icons'
import UserUtilities from './UserUtilities'
import { GetUsersRequest } from '../../../actions/types/requests'
import UserModal from './UserModal'
import { StandardApiResponse } from '../../../actions/types/responses'
import { StatusLevel } from '../../../common/types'
import { StatusLevelPill } from '../../common/StatusLevelPill'
import { AdminPage } from '../AdminPage'
import Text from '../../../components/common/Text'
import ConfirmDelete from '../../../components/common/ConfirmDelete'
import { withAuth, withAuthProps } from '../../../common/hooks/withAuth'
import { addSuccessNotification, addError } from '../../../actions/notifications'

interface UserRow {
    id: number
    firstName: string
    lastName: string
    fullName: string
    email: string
    role: string
    membership: string
    status: React.ReactNode
    status_index: number
    created_at: string
    created_at_ms: number
    last_activity: string
    last_activity_ms: number
    actions?: React.ReactNode
}

interface Props extends withAuthProps {
    getActivationCodes: () => void
    addError: (title: string, message: string) => void
    addSuccessNotification: (notification: { title?: string, message: string }) => void
    getUsers: (params?: GetUsersRequest) => void
    addUser: (params?: EditUser) => Promise<StandardApiResponse>
    updateUser: (params?: EditUser) => Promise<StandardApiResponse>
    deleteUser: (params?: User) => Promise<StandardApiResponse>
    users: User[]
}

interface State {
    userRows: UserRow[]
    showUtilitiesUser?: User
    showConfirmDeleteUser?: User
    addEditModalUser?: EditUser
}

interface ActionProps {
    onEdit: (user: User) => void
    onDelete: (user: User) => void
    onOpenTools: (user: User) => void
    user: User
}


const SORT_COLUMN_MAPPING: { [key: string]: string } = {
    'last_activity': 'last_activity_ms',
    'created_at': 'created_at_ms',
    'status': 'status_index',
}

export const getSubscriptionStatus = ({ is_unsubscribing, is_active }: User): StatusLevel => {
    if(is_unsubscribing) {
        return StatusLevel.WARNING
    } 
    
    if(is_active) {
        return StatusLevel.OK
    }
    
    return StatusLevel.DANGER
}
const SUBSCRIPTION_STATUS_LABEL = {
    [StatusLevel.OK]: 'Active',
    [StatusLevel.WARNING]: 'Cancelling',
    [StatusLevel.DANGER]: 'Deactivated',
}

const Actions:FunctionComponent<ActionProps>  = ({ user, onEdit, onOpenTools, onDelete }) => {

    const onEditPressed = React.useCallback(() => {
        onEdit(user)
    }, [user, onEdit])

    const onDeletePressed = React.useCallback(() => {
        onDelete(user)
    }, [user, onEdit])

    const onOpenToolsPressed = React.useCallback(() => {
        onOpenTools(user)
    }, [user, onOpenTools])

    return (
        <Box sx={{ '& svg': { cursor: 'pointer' } }}>
            <FontAwesomeIcon style={{ marginRight: '.5rem' }} color={'#297ea1'} icon={faUser} onClick={onOpenToolsPressed} />
            <FontAwesomeIcon style={{ marginRight: '.5rem' }} color={'#297ea1'} icon={faEdit} onClick={onEditPressed} />
            <FontAwesomeIcon color={'#297ea1'} icon={faTrash} onClick={onDeletePressed} />
        </Box>
    )
}

class UserManagment extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            userRows: [],
            showUtilitiesUser: undefined,
            showConfirmDeleteUser: undefined,
        }
    }
    
    componentDidMount() {
        this.props.getUsers()
        this.props.getActivationCodes()
    }

    componentDidUpdate(prevProps) {
        const { users: prevUsers } = prevProps
        const { users } = this.props

        if(prevUsers !== users) {
            const userRows = this.formatUser(users)

            this.setState({ userRows })
        }
    }

    onAddUser = () => {
        this.setState({ addEditModalUser: {...NEW_USER}})
    }

    onEditUser = (user: User) => {
        this.setState({ addEditModalUser: {...user, external_subscription_id: user.subscription?.external_subscription_id }})
    }

    onCloseUtilties = () => {
        this.setState({ showUtilitiesUser: undefined })
    }


    onOpenTools = (user: User) => {
        this.setState({ showUtilitiesUser: user })
    }

    onDelete = (user: User) => {
        this.setState({ showConfirmDeleteUser: user })
    }

    onCloseConfirmDelete = () => {
        this.setState({ showConfirmDeleteUser: undefined })
    }

    onConfirmDelete = () => {
        const { showConfirmDeleteUser } = this.state
        
        this.props.deleteUser(showConfirmDeleteUser)

        this.onCloseConfirmDelete()
    }


    formatUser = (users: User[]): UserRow[] => {
        const userRows:UserRow[] = users.map((user: User) => {
            const { id = 0, firstName, lastName, email, role_id, membership, last_activity, created_at, unsubscribed_on, activation_code } = user
            const stringStatus = getSubscriptionStatus(user)
            const statusIndex = Object.keys(SUBSCRIPTION_STATUS_LABEL).indexOf(stringStatus)
            
            const status = <StatusLevelPill status={stringStatus}>{SUBSCRIPTION_STATUS_LABEL[stringStatus]} {unsubscribed_on}</StatusLevelPill> 
            const roleName = ROLE_MAPPING[role_id]
            const role = activation_code?.domain ? `${roleName} (${activation_code.domain})` : roleName
            let membershipValue = MEMBERSHIP_MAPPING[membership]
            if (activation_code?.assigned_user) {
                const assignedUser = activation_code?.assigned_user
                membershipValue = `${membershipValue} (${assignedUser?.firstName[0]}. ${assignedUser?.lastName})`
            }
            const actions = <Actions user={user} onEdit={this.onEditUser} onDelete={this.onDelete} onOpenTools={this.onOpenTools} />
            const createdAt = created_at ? moment.utc(created_at).tz("America/New_York").format('MMM DD yyyy hh:mm a') : ''
            const lastActivity = last_activity ? moment.utc(last_activity).tz("America/New_York").format('MMM DD yyyy hh:mm a') : ''
            const lastActivityMs = last_activity ? moment.utc(last_activity).toDate().getTime() : 0;

            return { id, firstName, lastName, fullName: `${firstName} ${lastName}`, email, role, membership: membershipValue, stringStatus, status, last_activity: lastActivity, last_activity_ms: lastActivityMs, status_index: statusIndex, created_at: createdAt, actions }
        })

        return userRows
    }

    onCloseAddEditModal = () => {
        this.setState({ addEditModalUser: undefined })
    }

    onSaveUser = async(user: EditUser) => {
        let result: StandardApiResponse
        if(user.id) {
            result = await this.props.updateUser(user)
        } else {
            result = await this.props.addUser(user)
        }

        if(result?.success) {
            this.setState({ addEditModalUser: undefined })
            this.props.addSuccessNotification({ title: `${user.id ? 'Update' : 'Create'} User`, message: `User succesfully ${user.id ? 'updated' : 'created'}!`});
        } else {
            this.props.addError('Add Error', result?.errors ? result?.errors.join('\r\n') : 'An unexpected error occured adding new user');
        }
    }

    onSort = ({ column, direction }: { column: string, direction: string}) => {
        const { userRows } = this.state
        const sortColumn: string = SORT_COLUMN_MAPPING[column] ?? column
        const sortedRows = [...userRows].sort((a: any, b: any) => {
            if (direction === 'asc') {
                return a[sortColumn] > b[sortColumn] ? 1 : -1
            }

            return a[sortColumn] < b[sortColumn] ? 1 : -1
        })

        this.setState({ userRows: sortedRows })
    }

    render() {
        const { userRows, showUtilitiesUser, showConfirmDeleteUser, addEditModalUser } = this.state
        const { userDetails } = this.props
        const { scopes } = userDetails || { scopes: [''] }

        return (
            <AdminPage>
                <ConfirmDelete
                    title={'User Delete'}
                    onCancel={this.onCloseConfirmDelete}
                    onDelete={this.onConfirmDelete}
                    visible={showConfirmDeleteUser !== undefined}
                >
                    <Text>Are you sure you want to delete '{showConfirmDeleteUser?.firstName} {showConfirmDeleteUser?.lastName}'? This cannot be undone!</Text>
                </ConfirmDelete>
                <UserUtilities showModal={showUtilitiesUser !== undefined} userId={showUtilitiesUser?.id} onClose={this.onCloseUtilties} />
                <UserModal 
                    modelUser={addEditModalUser}
                    visible={addEditModalUser !== undefined}
                    onCancel={this.onCloseAddEditModal}
                    onSave={this.onSaveUser}
                />
                <DataTable
                    actionSx={{ top: '-.5rem' }}
                    actions={
                        (scopes.includes('pd:admin:*') || scopes.includes('pd:admin:users')) && 
                        <Button sx={{ minHeight: 'unset', height: '4rem', width: '15rem' }} title={'Add'} onClick={this.onAddUser} onDelete={this.onDelete} />
                    }
                    data={{ 
                        rows: userRows, 
                        columns: [{
                            label: 'Id',
                            field: 'id'
                        },{
                            label: 'Name',
                            field: 'fullName'
                        },{
                            label: 'Email',
                            field: 'email'
                        },{
                            label: 'Role',
                            field: 'role'
                        },{
                            label: 'Membership',
                            field: 'membership'
                        },{
                            label: 'Last Usage',
                            field: 'last_activity'
                        },{
                            label: 'Created On',
                            field: 'created_at'
                        },{
                            label: 'Status',
                            field: 'status'
                        },{
                            label: 'Actions',
                            field: 'actions'
                        }]
                    }}
                    onSort={this.onSort}
                /> 
            </AdminPage>
        )
    }
}

const mapStateToProps = ({ userManagment }) => {
    const { users } = userManagment

    return { users }
}

export default withAuth(connect(mapStateToProps, { getUsers, addUser, updateUser, deleteUser, getActivationCodes, addSuccessNotification, addError })(UserManagment))