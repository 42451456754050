import React, {FC} from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme as MuiTheme } from "@mui/material";
import { Theme } from '../../theme/theme';

import Text from '../common/Text';
import { BaseProps } from '../types';

export enum RowType {
  CHECKBOX = 'checkbox',
  RADIO_BUTTON = 'radio_button',
}

interface Props<T = any>  extends BaseProps {
    item: T;
    label: string;
    value: boolean;
    labelSx?: SxProps<Theme & MuiTheme>;
    onCheckChanged?: (item: T, checked: boolean) => void;
}

class Checkbox extends React.Component<Props> {

    onCheckChanged = () => {
        const { item, value, onCheckChanged } = this.props;
        if (onCheckChanged) {
            onCheckChanged(item, !value);
        }
    }

    render() {
        const { sx = {}, labelSx = {}, label, value } = this.props;

        return (
                <Box sx={{
                    ...sx,
                }}>
                    <input type='checkbox' checked={value} onChange={this.onCheckChanged}></input>
                    <Text sx={{
                        marginLeft: '1rem',
                        ...labelSx,
                    }}>{label}</Text>
                </Box>
        );
    }
}
export default Checkbox;