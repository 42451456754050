import React from 'react';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { connect } from 'react-redux';
import { Select } from '../../common/styled/Select';
import { SelectOption, TableRowData } from '../../../common/types';
import { ReduxState } from '../../../reducers';
import { PRICE_PRESETS_OPTIONS, PositionDetail, PresetItem, ShockValue, Shocks, VOL_PRESETS_OPTIONS } from '../../../actions/types/simplifyTools'
import { runRiskAnalyzer, getRiskAnalyzerTickers, onRiskAnalyzerPropUpdated, getRiskAnalyzerInputsForTicker } from '../../../actions/simplifyTools';;
import { SimplifyRiskAnalyzerProps } from '../../../reducers/SimplifyRiskAnalyzer';
import ExtendedCommonTable from '../../../components/common/ExtendedCommonTable';
import Button from '../../common/Button'
import { ClipLoader } from 'react-spinners';
import Text, { FONT_STYLES_BY_TYPE } from '../../../components/common/styled/Text';
import { CommonTableSelect } from '../../../components/common/CommonTableSelect';
import { CommonTableInput } from '../../../components/common/CommonTableInput';
import { WithWindowDimensionsProps, withWindowDimensions } from '../../../components/common/withWindowDimensions';


const SHOCK_TYPES = ['pct', 'pt', 'level'];
const PARAM_TYPES = ['stdev', 'ptile', 'literal'];

const SHOCK_VOL_TYPES = ['pct', 'pt'];
const PARAM_VOL_TYPES = ['literal'];

const SHOCK_TYPE_OPTIONS = SHOCK_TYPES.map((type) => ({ value: type, label: type }));
const PARAM_TYPES_OPTIONS = PARAM_TYPES.map((type) => ({ value: type, label: type }));

const SHOCK_TYPE_VOL_OPTIONS = SHOCK_VOL_TYPES.map((type) => ({ value: type, label: type }));
const PARAM_TYPES_VOL_OPTIONS = PARAM_VOL_TYPES.map((type) => ({ value: type, label: type }));

interface ReduxStateProps extends WithWindowDimensionsProps {
    loadingUserInputs: boolean
    loadingRiskOutput: boolean
    selectedTicker: SelectOption | null
    hasResults: boolean
    tickers: string[]
    tickerOptions: SelectOption[]
    userInputs: Shocks
    userInputHeaders: string[]
    userInputRows: TableRowData[]
    riskOutputHeaders: string[];
    riskOutputPercentageRows: TableRowData<PositionDetail>[];
    riskOutputDollarRows: TableRowData<PositionDetail>[];
    pnlHeaders: string[],
    pnlRows: TableRowData[],
    pnlTimestamp?: string;
}
interface ReduxActionProps {
    runRiskAnalyzer: (ticker: string, userInputs: Shocks) => void
    onRiskAnalyzerPropUpdated: (payload: Partial<SimplifyRiskAnalyzerProps>) => void
    getRiskAnalyzerTickers: () => void
    getRiskAnalyzerInputsForTicker: (ticker: string) => void
 }
interface ComponentState {
    outputAsPercentage: boolean
}

type ComponentProps = ReduxActionProps & ReduxStateProps

class RiskAnalyzer extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {
        outputAsPercentage: true
    }

    componentDidMount(): void {
       this.props.getRiskAnalyzerTickers()
    }

    onShockValueChanged = (rowKey: string, valueIndex: number, selection: SelectOption) => {
        const { userInputs } = this.props;
        const item = userInputs[rowKey];
        const shock_type = selection.value as 'pct' | 'pt' | 'level';
        const param = shock_type === 'level' ? 'literal' : item.param;
        const updatedItem = { ...item, shock_type, param}
        const updatedUserInputs = { ...userInputs, [rowKey]: updatedItem }

        this.props.onRiskAnalyzerPropUpdated({ userInputs: updatedUserInputs })
    }

    onParamValueChanged = (rowKey: string, valueIndex: number, selection: SelectOption) => {
        const { userInputs } = this.props;
        const item = userInputs[rowKey];
        const param = selection.value as 'stdev' | 'literal' | 'literal';
        const updatedItem = { ...item, param }
        const updatedUserInputs = { ...userInputs, [rowKey]: updatedItem }
        this.props.onRiskAnalyzerPropUpdated({ userInputs: updatedUserInputs })
    }

    onValueChanged = (rowKey: string, valueIndex: number, value: string | number) => {
        const { userInputs } = this.props;
        const item = userInputs[rowKey];
        const updatedItem = { ...item, values: item.values.map((val, i) => i === valueIndex - 2 ? Number(value) : val) }
        const updatedUserInputs = { ...userInputs, [rowKey]: updatedItem }
        this.props.onRiskAnalyzerPropUpdated({ userInputs: updatedUserInputs })
    }

    getPresetOptions = (key: string, shock: ShockValue) => {
        const { shock_type, param } = shock;
        const options = key.toUpperCase().includes('VOL') ? VOL_PRESETS_OPTIONS : PRICE_PRESETS_OPTIONS;

        return options.filter(option => option?.item?.shock.includes(shock_type) && option?.item?.param.includes(param));
    }

    onPresetOptionSelected = (rowKey: string, valueIndex: number, value: SelectOption<PresetItem>) => {
        const { userInputs } = this.props;
        const item = userInputs[rowKey];
        const updatedItem = { ...item, values: value?.item?.values ?? [] }
        const updatedUserInputs = { ...userInputs, [rowKey]: updatedItem }
        this.props.onRiskAnalyzerPropUpdated({ userInputs: updatedUserInputs })
    }

    updateUserInputRows = (userInputs: Shocks) => {
        const keys = Object.keys(userInputs);
        const userInputRows: TableRowData<ShockValue>[] = keys.map((key) => {
            const item = userInputs[key];
            const presetOptions = this.getPresetOptions(key, item);
            const isVol = key.toUpperCase().includes('VOL');
            const shockOptions = isVol ? SHOCK_TYPE_VOL_OPTIONS : SHOCK_TYPE_OPTIONS;
            const paramOptions = isVol ? PARAM_TYPES_VOL_OPTIONS : PARAM_TYPES_OPTIONS;

            return { 
                key, title: key, valueKeys:[`${key}-shock`, `${key}-param`, `${key}-presets`],
                values: [
                    <CommonTableSelect
                        sx={{ width: '10rem' }}
                        key={`${key}-shock`}
                        rowKey={key}
                        valueIndex={0}
                        value={item.shock_type}
                        options={shockOptions}
                        onChange={this.onShockValueChanged} 
                    />,
                    <CommonTableSelect 
                        sx={{ width: '10rem' }}
                        key={`${key}-param`}
                        rowKey={key}
                        valueIndex={1}
                        value={item.param}
                        options={paramOptions}
                        onChange={this.onParamValueChanged} 
                    />,
                    <CommonTableSelect 
                        sx={{ width: '15rem' }}
                        key={`${key}-presets`}
                        rowKey={key}
                        valueIndex={-1}
                        value={userInputs[key].param}
                        options={presetOptions}
                        onChange={this.onPresetOptionSelected} 
                    />,
                    ...userInputs[key].values.map((item, i) => <CommonTableInput sx={{ width: '7rem' }} key={`${key}${i}`} rowKey={key} value={item} valueIndex={i + 2} onChange={this.onValueChanged} numeric />)
                ] 
            }
        })
        this.props.onRiskAnalyzerPropUpdated({ userInputRows })
    }

    componentDidUpdate(prevProps: Readonly<ComponentProps>, prevState: Readonly<ComponentState>, snapshot?: any): void {
        if (prevProps.userInputs !== this.props.userInputs) {
            this.updateUserInputRows(this.props.userInputs)
        }        
    }

    onValueChange = (value: SelectOption) => {
        this.props.onRiskAnalyzerPropUpdated({ selectedTicker: value })
        this.props.getRiskAnalyzerInputsForTicker(value.value as string)
    }

    onRunRiskAnalyzer = () => {
        const { selectedTicker, userInputs } = this.props;
        if (!selectedTicker) return
        this.props.runRiskAnalyzer(selectedTicker.value as string, userInputs)
    }

    onFormatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ outputAsPercentage: event.target.value === 'true' })
    }

    render() {
        const { selectedTicker, tickerOptions, userInputHeaders, userInputRows, riskOutputHeaders, pnlTimestamp,
                pnlHeaders, pnlRows, riskOutputDollarRows, riskOutputPercentageRows, loadingUserInputs, loadingRiskOutput } = this.props
        const { outputAsPercentage } = this.state;
        const showingResults = !loadingUserInputs && !loadingRiskOutput && riskOutputHeaders.length > 0;
        const riskOutputRows = outputAsPercentage ? riskOutputPercentageRows : riskOutputDollarRows;
        
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: '5rem', minHeight: this.props.dimensions.height - 400, width: '100%', overflowY: 'scroll' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', height: `100%`, marginRight: '5rem'  }}>
                    <Box sx={{ height: '5rem', mt: '3rem', mr: '2rem' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '2rem', alignItems: 'center' }}>
                            <Select 
                                options={tickerOptions}
                                placeholder='Enter or select a ticker'
                                onChange={this.onValueChange}
                                value={selectedTicker}
                                sx={{ width: '33rem'}}
                                isDisabled={loadingUserInputs}
                            />
                            {loadingUserInputs && <ClipLoader color={'#000'} loading={true} />}
                            {pnlTimestamp && <Text sx={{ ml: '2rem' }}>{`Last Update: ${pnlTimestamp}`}</Text>}
                        </Box>
                        {!loadingUserInputs && pnlHeaders.length > 0 &&
                            <Box>
                                <ExtendedCommonTable
                                    containerSx={{ mt: '7.5rem', maxWidth: '185rem' }}
                                    headerColumns={pnlHeaders.map((header, index) => {
                                        const isTitle = index === 0;
                                        const sx = isTitle ? { minWidth: '30rem', backgroundColor: '#171443', color: 'white' } : { width: '7rem', backgroundColor: '#171443', color: 'white' }
                                        return { key: header, children: header, sx }
                                    })}
                                    rows={pnlRows}
                                    boldedKeys={['Total Portfolio-All Positions-total']}
                                    redNegativeValues
                                />
                            </Box>
                        }
                        {!loadingUserInputs && userInputHeaders.length > 0 &&
                            <Box>
                                <ExtendedCommonTable
                                    containerSx={{ mt: '5rem', maxWidth: '185rem' }}
                                    headerColumns={userInputHeaders.map(header => ({ key: header, children: header }))}
                                    rows={userInputRows}
                                />
                                <Button autoFit={true} sx={{ height: '4.5rem', width: '20rem', mt: '2rem' }} title={'Run'} loading={loadingRiskOutput} onClick={this.onRunRiskAnalyzer} inlineLoading />
                            </Box>
                        }
                        {showingResults &&
                            <Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: '5rem' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Text sx={{ mr: '1rem' }}>Format:</Text>
                                        <RadioGroup
                                            sx={{ mt: '.5rem', '& .MuiFormControlLabel-label': { font: FONT_STYLES_BY_TYPE.text } }} 
                                            value={outputAsPercentage === true ? 'true' : 'false'}
                                            onChange={this.onFormatChange}
                                            row
                                        >
                                            <FormControlLabel value={'true'} control={<Radio />} label="%" />
                                            <FormControlLabel value={'false'} control={<Radio />} label="$" />
                                        </RadioGroup>
                                    </Box>
                                </Box>
                                <ExtendedCommonTable
                                    headerColumns={riskOutputHeaders.map(header => ({ key: header, children: header }))}
                                    rows={riskOutputRows}
                                    redNegativeValues
                                />
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        )
    }
}

const mapStateToProps = ({ simplifyRiskAnalyzer }: ReduxState): ReduxStateProps => {
    const { loadingUserInputs, loadingRiskOutput, selectedTicker, hasResults, tickers, tickerOptions, userInputs, pnlTimestamp,
            pnlHeaders, pnlRows, userInputRows, userInputHeaders, riskOutputHeaders, riskOutputDollarRows, riskOutputPercentageRows } = simplifyRiskAnalyzer

    return { loadingUserInputs, loadingRiskOutput, selectedTicker, hasResults, tickers, tickerOptions, userInputs, pnlTimestamp,
             pnlHeaders, pnlRows, userInputRows, userInputHeaders, riskOutputHeaders, riskOutputDollarRows, riskOutputPercentageRows }
}

export default withWindowDimensions(connect(mapStateToProps, { runRiskAnalyzer, getRiskAnalyzerTickers, onRiskAnalyzerPropUpdated, getRiskAnalyzerInputsForTicker })(RiskAnalyzer as any));