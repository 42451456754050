

import RiskAnalyzer from '../RiskPreferences/RiskAnalyzer'
import RiskPreferences from '../RiskPreferences/RiskPreferences'
import StandardLivingRisk from '../StandardLivingRisk/StandardLivingRisk'
import CustomCMA from '../CustomCMA'
import OptimizedPortfolio from '../OptimizedPortfolio/OptimizedPortfolio'
import RiskAnalyzerOptimizedPortfolio from '../OptimizedPortfolio/RiskAnalyzerOptimizedPortfolio'
import ClientPortfolio from '../OptimizedPortfolio/ClientPortfolio'
import ProfileHistory from '../ProfileHistory';
import StandardLivingRiskSimple from '../ClientFinancialPlan/StandardLivingRiskSimple';
import StandaloneModeration from '../ClientFinancialPlan/StandaloneModeration';
import InvestmentPolicyStatement from '../InvestmentPolicyStatement';
import PortfolioDesigner from '../PortfolioDesigner';
import PortfolioManager from '../PortfolioManager';
import Forecast from '../Forecast';
import DueDiligenceCharts from '../DueDiligenceCharts'
import MarketDashboard from '../MarketDashboard'
import PerformanceAttribution from '../SimplifyTools/PerformanceAttribution'
import SimplifyRiskAnalyzer from '../SimplifyTools/RiskAnalyzer'
import { Component, FunctionComponent, JSXElementConstructor } from 'react'

const components: { [key: string]: React.Component | FunctionComponent | any } = {
    RiskAnalyzer,
    RiskPreferences,
    StandardLivingRisk,
    CustomCMA,
    OptimizedPortfolio,
    ClientPortfolio,
    RiskAnalyzerOptimizedPortfolio,
    ProfileHistory,
    StandardLivingRiskSimple,
    StandaloneModeration,
    InvestmentPolicyStatement,
    PortfolioDesigner,
    PortfolioManager,
    Forecast,
    DueDiligenceCharts,
    MarketDashboard,
    PerformanceAttribution,
    SimplifyRiskAnalyzer,
};

export default components;