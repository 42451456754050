import { INVESTMENT_PORTFOLIO_UPDATE, CLEAR_PROFILE, RESET_INVESTMENT_PORTFOLIO_UPDATE, RISK_VALUE_CHANGED, RUNNING_PROFILE_SCRIPT, ASSET_SET_ID_CHANGED, PRINT_DETAILS_RECEIVED, LOGOUT, PROFILE_RESET } from '../actions/types/actions';
import { AssetSet } from '../actions/types/customAssets';
import { Holdings, KeyStats, Percentiles, PortfolioMap, PortfolioMapAsset, PortfolioMapDetail, PortfolioMapRiskParameters, ScenarioPerfs } from '../actions/types/investmentPortfolio'
import { RiskParameters } from '../actions/types/riskPreferences';

export interface InvestmentPortfolioState {
	percentiles: Percentiles
	keyStats: KeyStats
	keyStatsBenchmark: KeyStats
	holdings: Holdings[]
	holdingsBenchmark: Holdings[]
	model: string
	scenarioPerfs: ScenarioPerfs[]
	scenarioPerfsBenchmark: ScenarioPerfs[]
	portfolioMap: PortfolioMapRiskParameters
	chosen: RiskParameters
	recommended: RiskParameters
	preferred: RiskParameters
	tableDetail?: PortfolioMapDetail,
	details: PortfolioMapDetail[],
	showAddHistorySuccess: boolean
	runningProfileScript: boolean
	assets: PortfolioMapAsset[],
	opt_asset_set_id?: number
	portfolioMaps: PortfolioMap[]
	global_asset_sets: AssetSet[]
}

const KEY_STATS_DEFAULT: KeyStats = {
	monteCarloAnnMean: 0.0,
	monteCarloAnnvol: 0.0,
	monteCarloAnnskew: 0.0,
	monteCarloAnnkurt: 0.0,
	monteCarloAnnmaxdrawdown: 0.0,
}

const SCENARIO_PERFS_DEFAULT: ScenarioPerfs[] = [{
	title: "Nixon Price Controls",
	value:  0,
	benchmark: 0
}, {
	title: "1982 Bull Market",
	value:  0,
	benchmark: 0
}, {
	title: "1987 Market Crash",
	value:  0,
	benchmark: 0
}, {
	title: "Surprise Fed Rate Hike",
	value:  0,
	benchmark: 0
}, {
	title: "Tech Bubble",
	value:  0,
	benchmark: 0
}, {
	title: "Tech Bust",
	value:  0,
	benchmark: 0
}, {
	title: "Easy Credit",
	value: 0,
	benchmark: 0
}, {
	title: "Credit Crisis",
	value:  0,
	benchmark: 0
}]

const INITIAL_STATE: InvestmentPortfolioState = {
	percentiles: {
		percentile95th: [0, 0, 0, 0, 0, 0, 0, 0, 0],
		percentile75th: [0, 0, 0, 0, 0, 0, 0, 0, 0],
		percentile50th:[0, 0, 0, 0, 0, 0, 0, 0, 0],
		percentile25th: [0, 0, 0, 0, 0, 0, 0, 0, 0],
		percentile5th: [0, 0, 0, 0, 0, 0, 0, 0, 0],
	},
	keyStats: { ...KEY_STATS_DEFAULT },
	keyStatsBenchmark: { ...KEY_STATS_DEFAULT },
	holdings: [],
	holdingsBenchmark: [],
	model: 'UtilityNoBars',
	scenarioPerfs: [...SCENARIO_PERFS_DEFAULT],
	scenarioPerfsBenchmark: [...SCENARIO_PERFS_DEFAULT],
	portfolioMap: {
		lambdas: [1, 1.2, 1.5, 2, 3],
		gammas: [1.5, 2, 3, 4.5, 7, 12],
	},
	chosen: {
		lambda: 0,
		gamma: 0,
	},
	recommended: {
		lambda: 0,
		gamma: 0,
	},
	preferred: {
		lambda: 0,
		gamma: 0,
	},
	tableDetail: undefined,
	details: [],
	showAddHistorySuccess: false,
	runningProfileScript: false,
	assets: [],
	opt_asset_set_id: undefined,
	portfolioMaps: [],
	global_asset_sets: [],
};

export default (state = INITIAL_STATE, action): InvestmentPortfolioState => {
  const { payload, type } = action

  switch (type) {
	case PRINT_DETAILS_RECEIVED:
		return { ...state, ...(payload.riskPreferences || { }), ...(payload.portfolio || { })}
    case INVESTMENT_PORTFOLIO_UPDATE:
        return { ...state, ...payload }
    case RESET_INVESTMENT_PORTFOLIO_UPDATE:
        return { ...state, ...INITIAL_STATE }
    case RISK_VALUE_CHANGED:
        return { ...state, chosen: { ...state.chosen, ...payload }}
    case CLEAR_PROFILE:
        return { ...state, ...INITIAL_STATE }
	case RUNNING_PROFILE_SCRIPT:
		return { ...state, runningProfileScript: payload }
	case ASSET_SET_ID_CHANGED:
		return { ...state, opt_asset_set_id: payload }
    case PROFILE_RESET:
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

