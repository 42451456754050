import React, {FC} from 'react';
import Box from '@mui/material/Box';
import { ListItem } from '@mui/material';
import Text from '../common/Text';
import { ReportItem } from '../../actions/types/portfolioDesigner';


interface Props {
    item: ReportItem;
    disabled?: boolean;
    onCheckChanged?: (item: ReportItem) => void;
}

const ReportItemComponent: FC<Props> = ({item, disabled, onCheckChanged: propsOnCheckChanged}) => {

  const onCheckChanged = React.useCallback(() => {
    if (disabled) {
      return;
    }
    if (propsOnCheckChanged) {
      propsOnCheckChanged({...item, selected: !item.selected});
    }
  }, [item, disabled, propsOnCheckChanged]);

  return (
    <ListItem 
        sx={{ 
                display: 'flex',
                position: 'relative',
                border: 'unset',
                padding: '1rem',
                alignItems: 'flex-start',
                justifyItems: 'center',
                '& input': {
                  cursor: 'pointer',
                },
                width: 'unset',
            }}>
          <input type='checkbox' checked={item.selected} onChange={onCheckChanged} disabled={disabled}></input>
        <Box
            sx={{
            display: 'flex',
            flex: 1,
            width: '100%',
            flexDirection: 'row',
            marginRight: '1rem',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: '1rem'
        }}>
            <Text>
              <Box sx={{ cursor: 'pointer',  overflow:'hidden', textOverflow:'ellipsis' }} onClick={onCheckChanged}>{item.label}</Box>
            </Text>
        </Box>
    </ListItem>
  );
}

export default ReportItemComponent;