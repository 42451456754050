import { Box } from '@mui/material'
import React, { Component } from 'react'
import { Size, SizeAwareBox } from '../common/styled/SizeAwareBox'
import Text from '../common/styled/Text'
import Button from '../common/Button'
import { connect } from 'react-redux'
import { withAuth, withAuthProps } from '../../common/hooks/withAuth'
import SessionUserDetailsSync from '../../common/SessionUserDetailsSync'
import { onSelectedKeyChanged } from '../../actions/session'

interface SubmitResultProps extends withAuthProps {
    onComponentSize?: (size: Size) => void
    onSelectedKeyChanged: (key: string) => void
    profileId?: number
}
class SubmitResult extends Component <SubmitResultProps>{
    
    onBack = () => {
        this.props.onSelectedKeyChanged('riskPreferences')
    }

    render() {
        const { profileId, authenticated } = this.props;

        return (
            <SizeAwareBox sx={{ width: '100%' }} onComponentSize={this.props.onComponentSize}>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '50rem',
                }}>
                    {!authenticated && <Text sx={{ margin: '1rem' }}>Thank you for completing your risk questionnaire.</Text>}
                    {authenticated && <SessionUserDetailsSync />}
                    {authenticated && 
                        <Box 
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <Text sx={{ margin: '1rem', marginBottom: '5rem' }}>Thank you for completing your client's risk questionnaire. You may now return to the software to see the results.</Text>
                            <Button href={`/software/clients/${profileId}`} onClick={this.onBack}>Back to Risk Profile</Button>
                        </Box>
                    }
                </Box>
            </SizeAwareBox>
        )
    }
}

export const mapStateToProps = ({ session }: any) => {
    const { profile_id: profileId } = session?.profile || {};

    return { profileId }
}

export default connect(mapStateToProps, { onSelectedKeyChanged })(withAuth(SubmitResult))