import React, { FunctionComponent } from 'react'
import { useNavigate, useParams, useLocation, Params, NavigateFunction } from 'react-router-dom';
import { useQuery } from './useQuery';
import useWindowDimensions from './useWindowDimensions';

export interface WithWindowDimensionsProps {
  dimensions: {
    width: number,
    height: number,
  }
}

export const withWindowDimensions = (Component) => {
  const Wrapper = (props) => {
    const { width, height } = useWindowDimensions();
    
    return (
      <Component
        dimensions={{ width, height }}
          {...props}
        />
    );
  };
  
  return Wrapper;
};