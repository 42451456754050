import { DueDiligenceChartDTO, DueDiligenceResultDTO, DEFAULT_NEW_CHART, DEFAULT_CHART_OPTIONS, DueDiligenceChartAnnotations, ScreenerResultItem, Order } from '../actions/types/dueDiligence';
import { DUE_DILIGENCE_ADD_TICKER, DUE_DILIGENCE_CHARTS, DUE_DILIGENCE_CHARTS_LOADING, DUE_DILIGENCE_CHARTS_SELECTED, DUE_DILIGENCE_CHARTS_SELECTED_ANNOTATIONS, DUE_DILIGENCE_CHART_ANNOTATIONS, DUE_DILIGENCE_CHART_PROCESSING, DUE_DILIGENCE_RESULTS, DUE_DILIGENCE_SCREENER_PROCESSING, DUE_DILIGENCE_SCREENER_UPDATE, DUE_DILIGENCE_TICKERS, DUE_DILIGENCE_UPDATE, LOGOUT } from '../actions/types/actions';
import { ReduxAction } from '.';
import { ChartOptions, LooseObject, TableRowData } from '../common/types';
import { Ticker } from '../actions/types/portfolioDesigner';

export interface DueDiligenceState {
  charts: DueDiligenceChartDTO[];
  annotations: DueDiligenceChartAnnotations[],
  selectedChartItem: DueDiligenceChartDTO;
  selectedChartAnnotations: DueDiligenceChartAnnotations[],
  loadingCharts: boolean;
  result?: DueDiligenceResultDTO;
  dueDiligenceChartOptions?: LooseObject;
  processingChart: boolean;
  tickers: {
    [key: string]: Ticker;
  }
  universes: string[];
  categories: string[];
  distributionYieldValue: number | string;
  expenseRatioValue: number | string;
  performanceRank6moValue: number | string;
  performanceRank1yrValue: number | string;
  screenerResults: ScreenerResultItem[];
  processingScreener: boolean;
  screenerPage: number;
  screenerRowsPerPage: number;
  screenerOrderBy: keyof ScreenerResultItem;
  screenerOrder: Order;
  correlationMatrixChart?: ChartOptions;
  statisticsTable: TableRowData[];
  statisticsColumns: LooseObject[];
}

const INITIAL_STATE: DueDiligenceState = {
  charts: [],
  annotations: [],
  loadingCharts: false,
  selectedChartItem: {...DEFAULT_NEW_CHART},
  selectedChartAnnotations: [],
  dueDiligenceChartOptions: {...DEFAULT_CHART_OPTIONS},
  processingChart: false,
  tickers: {},
  universes: [],
  categories: [],
  distributionYieldValue: '',
  expenseRatioValue: '',
  performanceRank6moValue: '',
  performanceRank1yrValue: '',
  screenerResults: [],
  processingScreener: false,
  screenerPage: 0,
  screenerRowsPerPage: 10,
  screenerOrderBy: 'symbol',
  screenerOrder: 'asc',
  statisticsTable: [],
  statisticsColumns: [],
};

export default (state = INITIAL_STATE, action: ReduxAction): DueDiligenceState => {
  const { payload, type } = action

  switch (type) {
    case DUE_DILIGENCE_CHARTS:
      return {
        ...state,
        charts: payload,
        loadingCharts: false,
      }
    case DUE_DILIGENCE_CHART_ANNOTATIONS:
      return {
        ...state,
        annotations: payload,
        loadingCharts: false,
      }
    case DUE_DILIGENCE_CHARTS_LOADING:
      return {
        ...state,
        loadingCharts: payload,
      }
    case DUE_DILIGENCE_CHARTS_SELECTED:
      return {
        ...state,
        selectedChartItem: payload.item,
        selectedChartAnnotations: payload.annotations,
        tickers: {
          ...state.tickers,
          ...payload.tickers,
        },
      }
    case DUE_DILIGENCE_CHARTS_SELECTED_ANNOTATIONS:
      return {
        ...state,
        selectedChartAnnotations: payload,
      }
    case DUE_DILIGENCE_RESULTS:
      return {
        ...state,
        result: payload.result,
        dueDiligenceChartOptions: payload.dueDiligenceChartOptions,
      }
    case DUE_DILIGENCE_RESULTS:
      return {
        ...state,
        result: payload.result,
        dueDiligenceChartOptions: payload.dueDiligenceChartOptions,
      }
    case DUE_DILIGENCE_CHART_PROCESSING:
      return {
        ...state,
        processingChart: payload,
      }
    case DUE_DILIGENCE_TICKERS:
      return {
        ...state,
        tickers: payload,
      }
    case DUE_DILIGENCE_ADD_TICKER:
      return {
        ...state,
        tickers: {
          ...state.tickers,
          [payload.symbol]: payload,
        }
      }
      case DUE_DILIGENCE_SCREENER_PROCESSING:
        return {
          ...state,
          processingScreener: payload,
        }
      case DUE_DILIGENCE_UPDATE:
        return {
          ...state,
          ...payload,
        }
      case DUE_DILIGENCE_SCREENER_UPDATE:
        return {
          ...state,
          ...payload,
        }
    case LOGOUT:
      return {...INITIAL_STATE};
    default:
        return state;
  }
};

