import React, { FC } from 'react';
import { SelectOption } from '../../common/types';
import { Select, SelectProps } from './styled/Select';
import { SxProps, TextField, Theme as MuiTheme } from '@mui/material';
import { sanitizeNumericInput } from '../../common/utils';
import { FONT_STYLES_BY_TYPE } from './styled/Text';
import { Theme } from '../../theme/theme';

export interface CommonTableInputProps {
    sx?: SxProps<Theme & MuiTheme>;
    rowKey: string;
    valueIndex: number;
    value: string | number | undefined | null;
    numeric?: boolean;
    onChange: (rowKey: string, valueIndex: number, value: string | number) => void;
}

export const CommonTableInput: FC<CommonTableInputProps> = ({ sx, rowKey, valueIndex, value, numeric, onChange }) => {
    const [inputValue, setInputValue] = React.useState<string | number | undefined| null>(value);
    const [sxProp, setSxProp] = React.useState<SxProps<Theme & MuiTheme>>(sx || {});
    React.useEffect(() => {
        setSxProp(sx || {});
    }, [sx]);
    React.useEffect(() => {
        setInputValue(value);
    }, [value]);
    const onValueChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setInputValue(numeric ? sanitizeNumericInput(value) : value);
    }, [rowKey, valueIndex, onChange]);

    const onBlur = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setInputValue(value);
        onChange(rowKey, valueIndex, value);
    }, [rowKey, valueIndex, onChange]);

    return (
    <TextField
        sx={{
            height: '5rem',
            ...sxProp,
        }}
        variant="outlined"
        value={inputValue}
        onChange={onValueChange}
        onBlur={onBlur}
        inputProps={{
            sx: {
                height: '1.5rem',
                font: FONT_STYLES_BY_TYPE.text,
            }
        }}
        InputLabelProps={{
            sx: {
                font: FONT_STYLES_BY_TYPE.text,
            }
        }}
    />        
    )
   
}