import axios from "axios"
import { LooseObject } from "../common/types"
import { getAssetAllocatioChart, getRiskAnalyzerEnterpriseChart, getRiskLevelChart } from "../components/OptimizedPortfolio/chartOptions"
import { StandardAction, StandardThunkAction } from "../store/store"
import { addError } from "./notifications"
import { renderCharts, getEnterpriseHoldings, getTableDetails, CLIENTS_ENDPOINT, ANALYZE_ENDPOINT } from "./riskPreferences"
import { ASSET_SET_ID_CHANGED, CLIENT_DETAILS_COMPLETED, INVESTMENT_PORTFOLIO_UPDATE, RISK_PREFERENCES_MEASURED_UPDATE, RUNNING_PROFILE_SCRIPT, SESSION, USER_LOADED } from "./types/actions"
import { Client } from "./types/clientManagement"
import { Holdings } from "./types/investmentPortfolio"
import { RiskAnalyzerResultsRequest, StoreContactRequest } from "./types/requests"

export const VALID_GAUGE_TYPES = ['riskAversion', 'lossAversion']

export const ENTERPRISE_ANALYZER = '/api/enterprise/analyzer'

export const updateSingleUseSoftware = (): StandardAction => {
    return { type: SESSION, payload: { views: ['single_use_software'], enterprise: true, defaultKey: 'singleUseSoftware', selectedKey: 'singleUseSoftware' } }
}

export const clientDetailsCompleted =  (client: Client): StandardAction => {
    return { type: CLIENT_DETAILS_COMPLETED , payload: client }
}

export const getEnterpriseRiskAnalyzer = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const params = new URLSearchParams(window.location.search)
            const token = params.get('token') || ''

            const { data } = await axios.get(`${ENTERPRISE_ANALYZER}?token=${token}`)
            const { success, message } = data
            if(!success) {
                dispatch(addError('Risk Preferences', message))

                return
            }
            const { portfolioMaps = [], risk_level_averages: riskLevelAverages } = data
            
            const portfolioMapsWithLabel = portfolioMaps ? portfolioMaps.map((item: any) => {
                const { name: label, id: value, details: itemDetails = [] } = item

                const details = itemDetails.reduce((accumulator: any, { mappings, ...details }: any) => {
                    const mappingsReduced = mappings.reduce((mappingAccum: any, { gamma_risk_level, lambda_risk_level }: any) => {

                        return { ...mappingAccum, [`${gamma_risk_level}_${lambda_risk_level}`]: { ...details } }
                    }, { })
                    return { ...accumulator, ...mappingsReduced  }
                }, { })

                return { ...item, details, label, value }
            }) : []

            dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { portfolioMaps: portfolioMapsWithLabel } })

            const { lambda, gamma } = riskLevelAverages
            
            const riskAversionRiskLevelsChart = getRiskLevelChart('Risk Aversion', `Risk Aversion`.trim(), gamma)
            const lossAversionRiskLevelsChart = getRiskLevelChart('Loss Aversion', `Loss Aversion`.trim(), lambda)

            dispatch({ type: RISK_PREFERENCES_MEASURED_UPDATE, payload: { riskLevelAverages, riskAversionRiskLevelsChart, lossAversionRiskLevelsChart }})
        } catch(e: any) {
            dispatch(addError('Risk Preferences', e.toString()))
        }
    }
}

export const runRiskAnalyzerResults = ({ asset_set_id, renderCharts: shouldRenderCharts = true, suppressErrors }: RiskAnalyzerResultsRequest = { }): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { session, riskPreferences, investmentPortfolio } = getState()
        const { enterprise } = session
        
        if(shouldRenderCharts) {
            dispatch(renderCharts())
        }

        if(asset_set_id) {
            dispatch({ type: ASSET_SET_ID_CHANGED, payload: asset_set_id })
        }
        
        if(enterprise) {
            const { portfolioMaps } = investmentPortfolio
            const { gammaMeasured, lambdaMeasured } = riskPreferences
            const holdings = getEnterpriseHoldings({ gamma: gammaMeasured, lambda: lambdaMeasured, portfolioMap: portfolioMaps[0] })
            const tableDetail = getTableDetails({ gamma: gammaMeasured, lambda: lambdaMeasured, portfolioMap: portfolioMaps[0] })

            dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { holdings, tableDetail, riskAnalyzerEnterpriseChart: getRiskAnalyzerEnterpriseChart(tableDetail) } })
            return
        }

        dispatch({ type: RUNNING_PROFILE_SCRIPT, payload: true })
        const paths = window.location.pathname.split('/')
        const profileId = paths[paths.length - 1]
        let data: LooseObject = { }
        try {
            const { data: payload } = await axios.post(`${CLIENTS_ENDPOINT}/${profileId}/${ANALYZE_ENDPOINT}`, { asset_set_id })
            data = payload
        } catch(e: any) {
            data = { success: false, message: e.toString() }
        }
        
        const { success, message, result } = data
        const { investmentPortfolioResultErrors, holdings: resultHoldings } = result || { }
        if(!success) {
            dispatch(addError('Script Error', message))

            dispatch({ type: RUNNING_PROFILE_SCRIPT, payload: false })
            return
        }

        const holdings = resultHoldings.map(({ title, value }: Holdings) => { 
            return { title, value: Number(value) || 0 }
        })

        if(investmentPortfolioResultErrors && investmentPortfolioResultErrors.length > 0 && !suppressErrors) {
            dispatch(addError('Script Finished with Errors!', investmentPortfolioResultErrors))
        }

        dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { holdings, analyzerAllocatioChartOptions: getAssetAllocatioChart(holdings) } })

        dispatch({ type: RUNNING_PROFILE_SCRIPT, payload: false })
    }
}

export const storeContact = ({ first_name, last_name, email, phone, annual_income }: StoreContactRequest): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const params = new URLSearchParams(window.location.search)
            const token = params.get('token') || ''

            const { data } = await axios.post(`${ENTERPRISE_ANALYZER}?token=${token}`, { first_name, last_name, email, phone, annual_income })
            const { success, message } = data
            if(!success) {
                dispatch(addError('Risk Preferences', message))

                return
            }
        } catch(e: any) {
            dispatch(addError('Risk Preferences', e.toString()))
        }
    }
}