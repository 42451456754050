import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../reducers';
import { getHistoricalPerformanceTable } from '../../actions/marketDashboard';
import { PERFORMANCE_ITEM_DIVIDERS, Performance, PerformanceDates, REQUIRES_LAST_PRICE_PERCENT_FORMATTING, REQUIRES_LAST_PRICE_SKIP_FORMATTING, REQUIRES_SKIP_PERCENT_CHANGE_FORMATTING, Type } from '../../actions/types/marketDashboard';
import CommonTable from '../common/CommonTable';
import Text from '../common/Text';
import { Box } from '@mui/material';
import moment from 'moment'
import PerformanceChartChangeItem from './PerformanceChartChangeItem';
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import PollingComponent from './PollingComponent';

interface ReduxStateProps {
    performanceData?: Performance[],
    performanceDates: PerformanceDates[];
}

interface ReduxActionProps {
    getHistoricalPerformanceTable: typeof getHistoricalPerformanceTable
}


interface ComponentState {
}

type ComponentProps = ReduxActionProps & ReduxStateProps

class PerformanceChart extends PollingComponent<ComponentProps, ComponentState> {
    state: ComponentState = {}

    poll = async (): Promise<void> => {
        this.props.getHistoricalPerformanceTable(Type.TABLE_DATA)
    }

    formatLastPrice = (identifier: string, value: number) => {
        if (!value) {
            return '-';
        }

        return value?.toFixed(2);
    }

    formatPercentChanges = (identifier: string, value: number) => {
        if (!value) {
            return '-';
        }

        if (REQUIRES_SKIP_PERCENT_CHANGE_FORMATTING.includes(identifier)) {
            return value?.toFixed(2);
        }

        return `${value.toFixed(2)}%` ;
    }

    getBackroundColor = (value: number) => {
        if (!value) {
            return '';
        }

        if (value < 0) {
            return '#FF0000';
        }

        return '#00FF00';
    }

    renderPerformanceRow = (item: Performance) => {
        const { identifier, display_name, px_last, chg_pct_1d, chg_pct_5d, chg_pct_1m, chg_pct_ytd  } = item;
        const sxProp = { borderTop: '' };
        if (PERFORMANCE_ITEM_DIVIDERS.includes(identifier)) {
            sxProp['borderTop'] = '5px solid #000';
        }

        return { 
                key: `${identifier}`,
                title: <Box sx={{ padding: '1.5rem', font: FONT_STYLES_BY_TYPE.table_header }}>{display_name}</Box>,
                values: [
                    <Box sx={{ padding: '1.5rem', font: FONT_STYLES_BY_TYPE.table_header }}>{this.formatLastPrice(identifier, px_last)}</Box>,
                    <PerformanceChartChangeItem identifier={identifier} value={chg_pct_1d} />,
                    <PerformanceChartChangeItem identifier={identifier} value={chg_pct_5d} />,
                    <PerformanceChartChangeItem identifier={identifier} value={chg_pct_1m} />,
                    <PerformanceChartChangeItem identifier={identifier} value={chg_pct_ytd} />,
                ],
                rowProp: { sx: sxProp },
            }
    }
    
    render() {
        const { performanceData, performanceDates } = this.props;
        return (
            <Box>
                <CommonTable
                    sx={{ 
                        '& th': {
                            textAlign: 'center',
                        },
                        '& td': {
                            textAlign: 'center',
                            padding: 'unset',
                        },
                    }}
                    headerColumns={[{
                        key: '',
                        children: '',
                    },
                    {
                        key: 'Last',
                        children: 'Last',
                    },
                    {
                        key: '1D Change',
                        children: '1D Change',
                    },
                    {
                        key: '5D Change',
                        children: '5D Change',
                    },
                    {
                        key: '1M Change',
                        children: '1M Change',
                    },
                    {
                        key: 'YTD Change',
                        children: 'YTD Change',
                    },]}
                    rows={performanceData?.map((item: Performance) => {
                        return this.renderPerformanceRow(item);
                    }) || []}
                    noDataMessage={`No performance data available`}
                />
                {performanceDates.map((item) =>  {
                    return (
                        <Box key={item.date} sx={{  marginTop: '1rem' }}>
                            <Text>
                                {item.items.join(', ')} as of {item.date}
                            </Text>
                        </Box>
                    )
                })}
            </Box>
        )
    }
}


const mapStateToProps = ({ marketDashboard }: ReduxState): ReduxStateProps => {
    const { performanceData, performanceDates } = marketDashboard;

    return {
        performanceData,
        performanceDates,
    }
}

export default connect(mapStateToProps, { getHistoricalPerformanceTable })(PerformanceChart as any)