import axios from 'axios'
import moment from 'moment'

import { 
    PROFILE_HISTORY_LOADING,
    PROFILE_HISTORY_RECEIVED,
    SHOW_ADD_HISTORY_SUCCESS,
    SHOW_NOTES_MODAL,
    HIDE_NOTES_MODAL,
} from "./types/actions"
import store from '../store'
import { addNotification, addError } from './notifications'
import { getPathsClientId } from '../common/utils'
import { PrepareChartDataRequest, PrepareChartsRequest } from './types/requests'
import { StandardAction, StandardThunkAction } from '../store/store'
import { ChartDataCollection, ChartLabels, ChartPayloadOptions, ChartSeriesItem, HistoryChartItem, HistoryItem, HistoryNote, ModalNoteItem, RiskParameterChartData, SLRChartData } from './types/profileHistory'
import { ChartOptions } from '../components/OptimizedPortfolio/chartOptions'
import { RANGES } from '../components/RiskPreferences/mappings'

export const CLIENTS_ENDPOINT = '/api/clients'
export const HISTORY_ENDPOINT = 'history'

export const SERIES_OPTIONS = {
    gamma_measured: {
        name: 'Risk Aversion',
        color: '#92d2e2',
        lineWidth: 2,
        zIndex: 10,
    },
    lambda_measured: {
        name: 'Loss Aversion',
        color: '#2b7ca0',
        lineWidth: 6,
        zIndex: 5,
    },
    financial_plan_needs_portfolio: {
        name: 'Needs Portfolio',
        color:  '#999999',
        lineWidth: 2,
    },
    financial_plan_wants_portfolio: {
        name: 'Wants Portfolio',
        color: '#2b7ca0',
        lineWidth: 2,
    },
    financial_plan_wishes_portfolio: {
        name: 'Wishes Portfolio',
        color: '#92d2e2',
        lineWidth: 2,
    },
    financial_plan_investment_portfolio_today: {
        name: 'Investment Portfolio Today',
        color: '#92d2e2',
        lineWidth: 2,
    }
}



export const getProfileHistory = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            dispatch({ type: PROFILE_HISTORY_LOADING })
            const profileId = getPathsClientId()
            if(!profileId) {
                return
            }

            const { data } = await axios.get(`${CLIENTS_ENDPOINT}/${profileId}/${HISTORY_ENDPOINT}`)
            const { success, message, valuesForMap, profileHistory, profileNotes } = data || { }

            if(!success) {
                if(message) {
                    dispatch(addError('Profile History', message))
                }

                return
            }
        
            const { gammas: gammaChartLabels, lambdas: lambdaChartLabels } = valuesForMap

            dispatch({ type: PROFILE_HISTORY_RECEIVED, payload: { profileHistory, profileNotes, gammaChartLabels, lambdaChartLabels }})
            dispatch(refreshCharts({ profileHistory, profileNotes }))
        } catch(e: any) {
            dispatch(addError('Profile History', e.toString()))
        }
    }
}

export const onSubmitNoteUpdate = ({ itemId, parameter, type, noteText }: ModalNoteItem): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            dispatch({ type: PROFILE_HISTORY_LOADING })
            dispatch(hideNotesModal())
            const profileId = getPathsClientId()

            const { data } = await axios.put(`${CLIENTS_ENDPOINT}/${profileId}/${HISTORY_ENDPOINT}/${itemId}/note`, { parameter, type, noteText })
            const { success, message, item } = data || { }

            if(!success) {
                dispatch(addError('Profile History', message || 'Unkown Error Submitting Note'))

                return
            }
            const { profileHistory: profileHistoryData } = getStore()
            const { profileHistory, profileNotes: currentProfileNotes } = profileHistoryData

            const profileNotes = [ ...currentProfileNotes.filter(({ id: noteId }: HistoryNote) => noteId != item.id), item ]

            dispatch({ type: PROFILE_HISTORY_RECEIVED, payload: { profileNotes }})
            dispatch(refreshCharts({ profileHistory, profileNotes }))
        } catch(e: any) {
            dispatch(addError('Profile History', e.toString()))
        }
    }
}

export const onHistoryDelete = ({ itemId }: ModalNoteItem): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            dispatch({ type: PROFILE_HISTORY_LOADING })
            const profileId = getPathsClientId()

            const { data } = await axios.delete(`${CLIENTS_ENDPOINT}/${profileId}/${HISTORY_ENDPOINT}/${itemId}`)
            const { success, message } = data || { }

            if(!success) {
                dispatch(addError('Profile History', message || 'Unkown Error Deleting History'))

                return
            }
            const { profileHistory: profileHistoryData } = getStore()
            const { profileHistory: currentProfileHistory, profileNotes } = profileHistoryData

            const profileHistory = currentProfileHistory.filter(({ id }: HistoryItem) => id != itemId)

            dispatch({ type: PROFILE_HISTORY_RECEIVED, payload: { profileHistory }})
            dispatch(refreshCharts({ profileHistory, profileNotes }))
        } catch(e: any) {
            dispatch(addError('Profile History', e.toString()))
        }
    }
}

export const addProfileHistory = (): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            const profileId = getPathsClientId()
            const { data } = await axios.post(`${CLIENTS_ENDPOINT}/${profileId}/timestamp`)
            const { item } = data || { }
    
            if(!item) {
                return
            }
            const { profileHistory: profileHistoryData } = getStore()
            const { profileHistory: currentProfileHistory, profileNotes } = profileHistoryData

            const profileHistory = [...currentProfileHistory, item]

            dispatch({ type: PROFILE_HISTORY_RECEIVED, payload: { profileHistory }})
            dispatch(refreshCharts({ profileHistory, profileNotes }))

            dispatch({ type: SHOW_ADD_HISTORY_SUCCESS, payload: true })
            dispatch(addNotification({
                type: 'Alert',
                title: 'Success!',
                content: 'Current Profile Has Been Saved to Historical Record.',
                duration: 5000,
                sx: {
                    width: '20rem',
                    height: '12.5rem !important',
                    color: 'white',
                    backgroundColor: '#00a65a',
                    borderColor: '#008d4c',
                },
            }))

        } catch(e: any) {
            dispatch(addError('Profile History', e.toString()))
        }
    }
}

const refreshCharts = ({ profileHistory, profileNotes }: PrepareChartsRequest): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        const { risks, financial_plan } = prepareData({ profileHistory, profileNotes })
        dispatch({ type: PROFILE_HISTORY_RECEIVED, payload: { risks, financial_plan  }})

        const risksChart = getChartPayload('', risks, { chartLabels: RANGES.combined, whitelistedSeries: ['gamma_measured', 'lambda_measured'], labelFormatter: getRiskPreferenceFormatter })
        const financialPlanChart = getChartPayload('', financial_plan, { labelFormatter: financialPlanFormatter, valueFormatter: financialPlanFormatter,  tickInterval: 500000 });

        dispatch({ type: PROFILE_HISTORY_RECEIVED, payload: { risksChart, financialPlanChart }})
    }
}

const prepareData = ({ profileHistory = [], profileNotes = [] }: PrepareChartsRequest): ChartDataCollection => {
    const risks = prepareDataForParameter({ charts: ['gamma', 'lambda'], profileHistory, profileNotes })
    const financial_plan = prepareDataForParameter({ charts: ['financial_plan'], profileHistory, profileNotes })

    return { risks, financial_plan }
}

const prepareDataForParameter = ({ charts, profileHistory = [], profileNotes: notes = []}: PrepareChartDataRequest): RiskParameterChartData => {

    const notesForChart = notes.filter((item) =>  charts.includes(item.chart));
    const data = profileHistory.reduce((acc, item) => {
        const { id, user_id, profile_id, created_at, updated_at, ...values } = item;
        const keys = Object.keys(values).filter((key) => charts.find((chart) => key.startsWith(chart)));
        for(const key of keys) {
            const chart = charts.find((chart) => key.startsWith(chart));
            const keyLabel = key.replace(`${chart}_`, '');
            
            const itemNotes = notesForChart.filter((note) => { return note.data_point == keyLabel && Number(note.profile_history_id) === Number(item.id) })
            const newItem = { item: id, date: created_at, y: values[key], data: item, parameter: chart, type: keyLabel, notes: itemNotes } as HistoryChartItem
            

            const currentItems = acc[key] || []
            acc[key] = [...currentItems, newItem]
        }
        
        acc['dates'] = [...(acc['dates'] || []), moment(created_at).format("MM-DD-YYYY")];

        return { ...acc }
    }, { dates: [] } as RiskParameterChartData);

    return data;
}

const valueRemapping = (item: HistoryChartItem, chartLabels?: ChartLabels): ChartSeriesItem => {
    const hasNote = item.notes && item.notes.length > 0 && item.notes[0].note && item.notes[0].note.length > 0
    return { 
                ...item,
                value: item.y,
                y: chartLabels ? chartLabels.indexOf(item.y) : item.y,
                marker: {
                    symbol:  hasNote ? "diamond" : "circle",
                    fillColor: hasNote ? "#f5d742" : "#000",
                    radius: hasNote ? 10 : null,
                },
            }
}

const getRiskPreferenceFormatter = (payload: any, chartLabels: ChartLabels) => {
    if(chartLabels[payload.value] == undefined) {
        return '--'
    }

    return chartLabels[payload.value]
}

const financialPlanFormatter = (payload: any, chartLabels: ChartLabels) => {
    return payload.value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('.')[0];
}

const getChartPayload = (title: string, { dates, ...chartData}: RiskParameterChartData, { leftOffset = 80, tickInterval = 1, chartLabels, whitelistedSeries: limitSeries, blacklistedSeries: excludeSeries, labelFormatter, valueFormatter }: ChartPayloadOptions = { }): ChartOptions => {
    let seriesKeys;
    if(limitSeries) {
        seriesKeys = Object.keys(chartData).filter((key) => limitSeries.includes(key))
    } else if (excludeSeries) {
        seriesKeys = Object.keys(chartData).filter((key) => !excludeSeries.includes(key))
    } else {
        seriesKeys = Object.keys(chartData)
    }
    const series = seriesKeys.map((key) => {
        const data = chartData[key].map((item) => valueRemapping(item, chartLabels))
        return {
            data: data,
            dashStyle: 'solid',
            color: '#92d2e2',
            lineWidth: 5,
            zIndex: 1,
            marker: {
                symbol: 'circle',
                lineWidth: 2
            },
            ...(SERIES_OPTIONS[key] || {} )
        }
    })
    return {
        title: {
            text: title,
            y: -10,
        },
        subtitle: {
            text: ' ',
            x: -20
        },
        xAxis: {
           categories:  dates,
           labels: {
             rotation: 45,  // Rotate labels by 45 degrees
               style: {
                   fontSize: '14px',
               },
           },
           tickInterval: dates.length > 10 ? Math.floor(dates.length / 10) : undefined,
        },
        yAxis: [{
            title: {
                text: ' ',
                rotation: 0,
                offset: leftOffset
            },
            labels: {
                value: 0,
                style: {
                    fontSize: '14px',
                },
                formatter: function(): any {
                    return labelFormatter(this, chartLabels)
                },
            },
            tickInterval,
            min: -1,
            tickAmount: chartLabels ? chartLabels.length + 1 : undefined,
            tickPositions: chartLabels ?  [0, ...chartLabels].map((item, index) => index - 1) : undefined,
            visible: true,
        }],
        legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'top',
            symbolWidth: 35,
            symbolHeight: 1,
            symbolRadius: 1,
            squareSymbol: true,
            itemStyle: {
                fontSize: '14px',
            }
        },
        tooltip: {
            shared: true,
            formatter: function(): any {
                return getToolTipForPoint(this, valueFormatter, chartLabels)
            },
            style: {
              fontSize: '14px',
            },
         },
        series,
        plotOptions: {
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            const pointData = this
                            showNotesModal(pointData as any)
                        }
                    }
                }
            }
        },   
    }
}

const getToolTipForPoint = (data: any, labelFormatter: any, chartOptions: any): string => {
    const points = (data.points || [ data ]).map((pointParent: any) => { return pointParent.point })
    
    const pointWithNotes = points.find((point: any) => {
        return point.notes && point.notes.length > 0
    })
    const notesLength = pointWithNotes ? pointWithNotes.notes.length : 0
    const pointYs = points.map((point: any) => {
        const value = !point.value || point.value == 'null' ? '--' : point.value
        const formattedValue = labelFormatter ? labelFormatter(point, chartOptions) : value

        return point.series.name + ': ' + formattedValue
    }).join('<br>')
    
    return data.x + ' - ' + notesLength + ' note(s)<br>' + pointYs
}

const showNotesModal = (pointData: ChartSeriesItem): void => {
    const { item: itemId, parameter, type, notes } = pointData

    const noteText = notes && notes.length > 0 ? notes[0].note : ''

    const notesItem = { itemId, parameter, type, noteText }
    
    store.dispatch({ type: SHOW_NOTES_MODAL, payload: notesItem })
}

export const hideNotesModal = (): StandardAction => {
    return { type: HIDE_NOTES_MODAL }
}