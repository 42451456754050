import React from "react"
import { Styles, withStyles } from '@mui/styles'
import { Link } from 'react-router-dom';

export const styles: Styles<any, any> = (theme) => {
  return {
    mainFooter: {
      background: '#171443',
      padding: '15px',
      color: '#FFF',
      borderTop: '1px solid #3489AA',
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      zIndex: 501,
      display: 'flex',
      alignItems: 'center',
      ['@media print']: {
          display: 'none',
      },
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    font: `normal normal normal 15px/19px Montserrat`,
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
    },
  },
  terms: {
    marginTop: 5,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginLeft: 10,
    },
  },
  copyright: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginTop: 0,
      marginLeft: 10,
    },
  },
  allRightsReserved: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 5,
    },
  }
}}
interface Props {
    classes: { [key: string]: any }
}
class SiteFooter extends React.Component<Props> {

  render() {
    const { classes } = this.props

    return(
      <footer className={classes.mainFooter}>
          <div className={classes.container}>
            <span className={classes.copyright}>
                <span><strong>Copyright © {new Date().getFullYear()} Portfolio Designer</strong></span><span className={classes.allRightsReserved}>All rights reserved.</span>
            </span>
            <span className={classes.terms}>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>{` | `}
                <Link to="/privacy-policy">Privacy Policy</Link>{` | `}
                <Link to="/contact">Contact</Link>
            </span>
          </div>
      </footer>
    )
  }
}

export default withStyles(styles)(SiteFooter)
