import React, {FC} from 'react';
import Box from '@mui/material/Box';
import { ReportItem, ReportSet } from '../../actions/types/portfolioDesigner';
import { FieldItem } from '../common/FormField';
import ReportItemComponent from './ReportItemComponent';


interface Props {
    item: ReportSet;
    onReportSetChanged?: (item: ReportSet) => void;
}

const chunk = (arr: ReportItem[], chunkSize: number) => {
  const chunked = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunked.push(arr.slice(i, i + chunkSize));
  }
  return chunked;
}

export const ReportSetComponent: FC<Props> = ({item, onReportSetChanged: propsOnReportSetChanged}) => {
  const [chunkedItems, setChunkedItems] = React.useState<ReportItem[][]>([]);
  React.useEffect(() => {
    const chunked = chunk(item.items, item.chunks ?? 3);
    setChunkedItems(chunked);
  }, [item.items, item.chunks]);

  const onReportItemCheckChanged = React.useCallback((reportItem: ReportItem) => {
    const updatedReportItems = item.items.map((item) => {
      if (item.key === reportItem.key) {
        return reportItem
      }
      return item
    })
    const updatedItem = {
      ...item,
      items: updatedReportItems,
    }

    if (propsOnReportSetChanged) {
      propsOnReportSetChanged(updatedItem);
    }
    
  }, [item, propsOnReportSetChanged]);

  return (
    
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <FieldItem title={item.label} inline={false} >
        <Box sx={{ display: 'flex', pointerEvents: item.readonly ? 'none' : undefined, flexDirection: 'column' }}>
          {chunkedItems.map((chunk, index) => (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'row' }}>
              {chunk.map((reportItem, index) => <ReportItemComponent item={reportItem} onCheckChanged={onReportItemCheckChanged} disabled={item.readonly}/>)}
            </Box>
          ))}
        </Box>
      </FieldItem>
    </Box>
  );
}

export default ReportItemComponent;