import { Box } from '@mui/material'
import React, { Component } from 'react'
import Text from '../common/styled/Text'

interface SummaryItemProps {
    label: string | React.ReactNode
    value: string | number | React.ReactNode
    displayRawValue?: boolean
    isPercentage?: boolean
    textAlign?: string
    addEquals?: boolean
    onChange?: (value: number) => void
}

export class SummaryItem extends Component <SummaryItemProps> { 
    
    onChange = (e) => {
        const value = e.target.value
        if(isNaN(value)) {
            return
        }

        const cleanedValue = parseInt(value.replace(/,/g, '').replace(/\D/g,''), 10);

        if(this.props.onChange) {
            this.props.onChange(this.props.isPercentage ? cleanedValue / 100 : cleanedValue)
        }
    }

    render() {
        const { label, value, isPercentage, textAlign, addEquals, displayRawValue } = this.props

        let displayValue: any = value
        if(!displayRawValue && typeof(value) != 'string' ) {
            displayValue = displayValue == null || displayValue == undefined || isNaN(displayValue) ? 0 : displayValue
            displayValue = isPercentage ? `${(displayValue * 100).toFixed(0)}%` : displayValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' , maximumFractionDigits: 0 })
        }
        
        return (
            <Box sx={{
                display: 'flex',
                position: 'relative',
                flex: 1,
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '1.25rem',
                marginBottom: '1.25rem',
            }}>
                <Box sx={{
                    display: 'flex',
                    flex: 2,
                    justifyContent: 'flex-end',
                }}>
                    <Text sx={{
                        flex: 1,
                        display: 'flex',
                        textAlign: 'left',
                        paddingRight: '1.5rem',
                    }} type={'form_header'} style={textAlign ? { justifyContent: textAlign } : undefined}>{label}</Text>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'flex-end',
                }}>
                    {addEquals == true && <Text type={'form_header'} sx={{
                          display: 'flex',
                          textAlign: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginRight: '1.5rem',
                    }}>=</Text>}
                    <Text sx={{
                        display: 'flex',
                        border: (theme) => `1px solid ${theme.palette.primary.main}`,
                        borderRadius: 0,
                        height: '4rem',
                        padding: '2.5rem',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '14rem',
                    }}>{displayValue}</Text>
                </Box>
            </Box>
        )
    }
}