import { SelectOption } from '../common/types';
import {
    PROFILE_RESET,
    LOGOUT,
    PORTFOLIO_MANAGER_MODELS_UPDATED,
    PORTFOLIO_MANAGER_SELECTED_MODEL_UPDATED,
    PORTFOLIO_MANAGER_UPDATE,
} from '../actions/types/actions';
import { OrionPortfolioClient } from '../actions/types/portfolioManager';

export interface PortfolioManagerState {
    accountOptions: SelectOption[],
    modelPortfolioOptions: SelectOption[],
    accountSelectedOption: SelectOption | null,
    modelPortfolioSelectedOption: SelectOption | null,
    orionClients: SelectOption<OrionPortfolioClient>[],
    discrepancyTable: any
}

const RESETTABLE_STATE = {
    accountSelectedOption: null,
    modelPortfolioSelectedOption: null,
}

const INITIAL_STATE: PortfolioManagerState = {
    accountOptions: [],
    modelPortfolioOptions: [],
    discrepancyTable: [],
    orionClients: [],
    ...RESETTABLE_STATE,
};

export default (state = INITIAL_STATE, action: any): PortfolioManagerState => {
  const { payload, type } = action

  switch (type) {
    case PORTFOLIO_MANAGER_MODELS_UPDATED:
        return { ...state, [`${payload.type}Options`]: payload.models }
    case PORTFOLIO_MANAGER_SELECTED_MODEL_UPDATED:
        return { ...state, [`${payload.type}SelectedOption`]: payload.selectedOption }
    case PORTFOLIO_MANAGER_UPDATE:
        return { ...state, ...payload }
    case PROFILE_RESET:
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

