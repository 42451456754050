import * as React from 'react';
import { useTheme } from '@emotion/react';
import { Box, Link, Typography } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

import CopyImage from '../common/CopyImage';
import FeatureCard from '../common/FeatureCard';

import quotes from './quotes'
import LandingButton from '../common/LandingButton';

const styles = {
    quote: {
        font: (theme) => `normal normal normal ${theme.typography.pxToRem(28)}/${theme.typography.pxToRem(35)} Montserrat`,
    },
    quoteOwner: {
        marginTop: `.75em`,
        font: (theme) => `normal normal normal ${theme.typography.pxToRem(28)}/${theme.typography.pxToRem(35)} Montserrat SemiBold`,
    },
    activeQuote: {
        background: (theme) => theme.palette.primary.main,
    },
    inactiveQuote: {
        width: '1em',
        height: '1em',
        borderRadius: '1em',
        background: (theme) => theme.palette.background.level1,
    },
}

export const QuotesCard = () => {
    const { breakpoints } = useTheme()
    const [activeIndex, setActiveIndex] = React.useState(0)
    const quoteCount = quotes.length

    const onPrevious = () => {
        let nextIndex = activeIndex - 1
        if(nextIndex < 0) {
            nextIndex = quoteCount - 1
        }

        setActiveIndex(nextIndex)
    }

    const onNext = () => {
        let nextIndex = activeIndex + 1
        if(nextIndex >= quoteCount) {
            nextIndex = 0
        }

        setActiveIndex(nextIndex)
    }

    return (
        <FeatureCard
            sx={{
                background: (theme) => theme.palette.secondary.main2,
                borderBottom: (theme) => `${theme.palette.borders.primary} solid 1px`,
                padding: '5em',
            }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <picture>
                    <source type="image/webp" srcSet={require('../assets/pd_book.webp')}></source>
                    <CopyImage sx={{ height: '45em' }} src={require('../assets/pd_book.png')} alt={'Book Cover of Modern Asset Allocation for wealth management by David Berns.'} />
                </picture>                
            </Box>
            <Box >
                
                <picture>
                    <source type="image/webp" srcSet={require('../assets/quotation_marks.webp')}></source>
                    <CopyImage sx={{ height: '10em', width: '8em' }} src={require('../assets/quotation_marks.png')} alt={'Image of a Quote'} />
                </picture>         
                <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        [breakpoints.up('lg')]: {
                            paddingLeft: '5em',
                        },
                    }}>
                    <Box key={quotes[activeIndex].quote}>
                        <Typography sx={styles.quote}>"{quotes[activeIndex].quote}"</Typography>
                        <Typography sx={styles.quoteOwner}>— {quotes[activeIndex].owner}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '1em' }}>
                        <ChevronLeft sx={{ fontSize: '5rem' }} onClick={onPrevious} />
                        <ChevronRight sx={{ fontSize: '5rem' }} onClick={onNext} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', minWidth: 100, justifyContent: 'space-around', alignItems: 'center' }}>
                            {[...Array(quoteCount).keys()].map((item) => {
                                const filled = item === activeIndex

                                return (
                                    <Box key={`${item}`} sx={{
                                        ...styles.inactiveQuote,
                                        ...(filled ? styles.activeQuote : { })
                                    }} />
                                )
                            })}
                        </Box>
                    </Box>
                    <LandingButton 
                        href={'https://www.amazon.com/Modern-Allocation-Wealth-Management-Finance/dp/1119566940/ref=sr_1_5?keywords=berns+asset+allocation&qid=1569703162&sr=8-5'}
                        title={'Buy Your Copy'}
                    />
                </Box>
            </Box>
        </FeatureCard>
    )
}

export default QuotesCard