import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactSearchBox from 'react-search-box'
import { getAdvisorClients, getClientDetails, loginAsUser } from '../../../actions/adminUserManagement'
import { ClientLog, ClientSkinny } from '../../../actions/types/adminUserUtils'
import MessageModal from '../../common/MessageModal'
import Label from '../../common/Label'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import CommonTable from '../../common/CommonTable'
import { Box } from '@mui/material'
import Button from '../../common/Button'
import { FONT_STYLES_BY_TYPE } from '../../common/styled/Text'
import { GetClientDetailsRequest } from '../../../actions/types/requests'
import { withAuth, withAuthProps } from '../../../common/hooks/withAuth'
import { withRouter, WithRouterProps } from '../../../components/common/withRouter'

const FILES = ["SLR", "ADBCSV", "ASSETS", "ADJSDBCSV"]
const SHOW_CLIENTS = false

interface Props extends withAuthProps, WithRouterProps {
    getAdvisorClients: (id: number) => void
    getClientDetails: (params: GetClientDetailsRequest) => void
    onClose: () => void
    loginAsUser: (id: number) => any
    clients: ClientSkinny[]
    clientFiles: string[]
    clientLogs: ClientLog[]
    userId: number
    showModal: boolean
}

interface State {
    clientId?: number
    clientName: string
}

class UserUtilities extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            clientId: undefined,
            clientName: ''
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { userId: prevUserId, showModal: prevShowModal,  } = prevProps
        const { userId, showModal } = this.props

        if(userId && prevUserId != userId) {
            this.props.getAdvisorClients(userId)
        }

        if(!showModal && prevShowModal != showModal) {
            this.setState({ clientId: undefined, clientName: '' })
        }
    }

    loginAsUser = async() => {
        const { userId } = this.props

        const data = await this.props.loginAsUser(userId);
        if (data.accessToken) {
            this.props.setAuthentication({ auth: { access_token: data.accessToken, refresh_token: '', expires_in: 0, token_type: '' }, onAuthUpdated: () => {
                this.props.navigate(data.path);
            },})
        }
    }

    downloadCSV = (csv: string) => {
        const { userId } = this.props
        const { clientId } = this.state

        window.location.href = `/admin/users/${userId}/client/${clientId}/csv/${csv}`
    }

    onProfileChanged = ({ id: clientId, name }: ClientSkinny) => {
        const { userId } = this.props
        this.setState({ clientId, clientName: name })

        this.props.getClientDetails({ userId, clientId })
      }

    render() {
        const { showModal = false, clients, clientLogs: scriptLogs = [], clientFiles = [] } = this.props
        const { clientId } = this.state

        return (
            <MessageModal size={'lg'} visible={showModal} title={'User Utilities'} buttonLabel={'Close'} onCancel={this.props.onClose} onButtonPress={this.props.onClose} >
                <Label title={'Actions'} />
                <Box sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    <Button autoFit={true} sx={{ height: '4.5rem', width: '20rem' }} title={'Login As User'} onClick={this.loginAsUser} />
                </Box>
                {SHOW_CLIENTS && <Label title={'Clients'} />}
                {SHOW_CLIENTS && 
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '.5rem',
                        marginBottom: '1.5rem',
                        maxHeight: '30rem',
                    }}>
                    <Box sx={{ position: 'relative', height: '5rem' }}>
                        <Box sx={{ position: 'absolute', top: 0, left: 0, font: FONT_STYLES_BY_TYPE.text }}>
                            <ReactSearchBox
                                placeholder={'Clients'}
                                data={clients}
                                onSelect={(value) => this.onProfileChanged(value)}
                            />
                        </Box>
                    </Box>
                    {!!clientId &&
                    <Box sx={{ marginLeft: '2rem', marginTop: '5rem' }}>
                        <Label title={'CSV Files'} />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '.5rem',
                            marginBottom: '1.5rem',
                            maxHeight: '30rem',
                        }}>
                            {FILES.map((file) => {
                                const available = clientFiles.includes(file)
                                return (
                                    <Box sx={{ 
                                            cursor: available ? 'pointer' : 'not-allowed',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginLeft: '.5rem',
                                            marginRight: '.5rem',
                                        }} onClick={available ? () => this.downloadCSV(file) : undefined}>
                                        <FontAwesomeIcon icon={faFileCsv} size={'2x'} color={!available ? 'grey' : undefined} />
                                        <Label title={`${file}.csv`} />
                                    </Box>
                                )
                            })}
                        </Box>
                        <Label title={'Recent Script Calls - Last 20'} />
                        <Box sx={{
                            maxHeight: '20rem',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                        }}>
                            <CommonTable
                                sx={{ marginBottom: '4rem' }}
                                headerColumns={[{ key: 'Date', children: 'Date (UTC)', sx: { minWidth: '15rem' } }, { key: 'Command', children: 'Command' }, { key: 'Result', children: 'Result'}]}
                                rows={scriptLogs.map(({ updated_at, command, result }) => {
                                    return { title: updated_at, values: [<Box sx={{ height: '5rem', width: '30rem', overflowX: 'clip', overflowY: 'scroll' }}>{command}</Box>, <Box sx={{ height: '5rem', width: '30rem', overflowX: 'clip', overflowY: 'scroll' }}>{result}</Box>]}
                                })}
                            />
                        </Box>
                    </Box>
                    }
                    </Box>
                }
            </MessageModal>
        )
    }
}

const mapStateToProps = ({ userManagment }) => {
    const { clients, clientLogs, clientFiles } = userManagment

    return { clients, clientLogs, clientFiles }
}

export default withRouter(withAuth(connect(mapStateToProps, { getAdvisorClients, getClientDetails, loginAsUser })(UserUtilities)))