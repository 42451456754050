import React, { Component } from 'react'

import { getClientQuestionnaireLink, riskPreferencePage } from '../../actions/riskPreferences'
import { connect } from 'react-redux'
import TabFooter from '../Software/TabFooter'
import { onComponentSizeType, Size, SizeAwareBox } from '../common/styled/SizeAwareBox'
import Button from '../common/Button'
import { Box } from '@mui/material'
import { BaseProps } from '../types'
import { ReduxState } from '../../reducers'

export interface BaseRiskPageProps extends BaseProps {
    onComponentSize?: onComponentSizeType
}
interface ReduxStateProps {
    activeIndex: number
    riskAnalyzer: boolean
    membership: string
    enterprise: boolean
}

interface ReduxActionProps {
    riskPreferencePage: (page: number) => void
    getClientQuestionnaireLink: () => Promise<string>
}

interface ComponentProps {
    footerMargin: number
    footer: React.ReactNode
    readOnly: boolean
    nextModuleKey: string
    hideFooter?: boolean
    style: React.CSSProperties
}

type Props = ReduxStateProps & ComponentProps & BaseRiskPageProps & ReduxActionProps
interface RiskPageState {
    height: number
    width: number
    clientUrl: string
}
class RiskPage extends Component<Props, RiskPageState>{
    
    constructor(props: Props) {
        super(props)
        this.state = {
            height: 0,
            width: 0,
            clientUrl: '',
        }
    }

    async componentDidMount(): Promise<void> {
        const link = await this.props.getClientQuestionnaireLink()

        this.setState({ clientUrl: link })
    }

    setSize = ({ height, width }: Size) => {
        this.setState({ height, width }, () => {
            if(this.props.onComponentSize) {
                this.props.onComponentSize({ height, width })
            }
        })
    }

    render() {
        const { footerMargin, readOnly, activeIndex, nextModuleKey, sx = { }, riskAnalyzer, enterprise, hideFooter } = this.props
        const { height, width, clientUrl } = this.state
        const footerMarginStyle = footerMargin ? { marginTop: footerMargin } : { }
        const FooterComponent: any = this.props.footer || TabFooter
        const showRiskResults = !riskAnalyzer || (riskAnalyzer && !enterprise)

        const pageCount = 1
        const title = showRiskResults && activeIndex === 1 ? 'Optimized Portfolio' : 'Risk Preference'

        return (
                <SizeAwareBox onComponentSize={this.setSize}>
                    <Box sx={sx}>
                        {this.props.children}
                    </Box>
                    {!readOnly && !hideFooter &&
                        <FooterComponent 
                            style={{ width, ...footerMarginStyle }}
                            title={title} 
                            activeIndex={activeIndex}
                            pageCount={pageCount}
                            onPageChange={this.props.riskPreferencePage}
                            nextModuleKey={nextModuleKey}
                            additionalActions={ 
                                <Button
                                    title={'Update Risk Profile'}
                                    href={clientUrl}
                                    sx={{ minWidth: 'unset', minHeight: 'unset', width: '21rem', height: '4.5rem', marginRight: '1rem' }}
                                /> }
                        /> 
                    }
                </SizeAwareBox>
        )
    }

}


const mapStateToProps = ({ riskPreferences, session }: ReduxState): ReduxStateProps => {
    const { activeIndex } = riskPreferences
    const { membership, enterprise } = session || { }
    const riskAnalyzer = membership == 'risk_analyzer'

    return { activeIndex, riskAnalyzer, membership, enterprise }
}

export default connect<any, any, any, any> (mapStateToProps, { riskPreferencePage, getClientQuestionnaireLink })(RiskPage)
