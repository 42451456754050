import axios from 'axios'
import moment from 'moment'
import { LooseObject } from '../common/types'
import { getPathsClientId } from '../common/utils'
import { StandardThunkAction } from '../store/store'
import { getInvestmentPortfolio, updateOptAssetSet } from './investmentPortfolio'
import { addError } from './notifications'
import { 
    CAPITAL_MARKET_ASSUMPTIONS_LOADING,
    CAPITAL_MARKET_ASSUMPTIONS_RECEIVED,
    CMA_DATES,
    RUNNING_CUSTOM_CMA_SCRIPT,
    ON_ASSET_SET_CHANGED,
} from "./types/actions"
import { Asset, AssetSet, CMAAsset, DateType, ReturnShiftForm } from './types/customAssets'
import { CreateAssetSetReplaceRequest, CreateAssetSetRequest, DeleteAssetSetRequest, LoadAssetSetRequest } from './types/requests'

export const CLIENTS_ENDPOINT = '/api/clients'
export const CAPITAL_MARKET_ASSUMPTIONS = 'capitalMarketAssumptions'
export const CAPITAL_MARKET_ASSUMPTIONS_RUN = 'run'
export const BUILDER_PREFERENCES = 'builderPreferences'

export const getCapitalMarketAssumptions = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_LOADING })

            const profileId = getPathsClientId()
            if(!profileId) {
                return
            }
            
            const { data } = await axios.get(`${CLIENTS_ENDPOINT}/${profileId}/${CAPITAL_MARKET_ASSUMPTIONS}`)
            const {  start_date, end_date, risk_premium, cma_asset_sets: payloadCMASets, custom_cma_assets: payloadCmaAssets, assets: payloadAssets, } = data

            const asset_sets: AssetSet[] = payloadCMASets ? payloadCMASets.map((item: AssetSet) => { return { ...item,  label: item.name, value: item.id } }) : []
            const cma_assets: CMAAsset[] = payloadCmaAssets ? payloadCmaAssets.map(({ asset: payloadAsset, asset_id, return_shift_enabled, ...item  }: any) => { 
                const { pivot = { }, ...asset } = payloadAsset || { }
                return { ...item, asset: asset ? { ...asset, label: asset.name, value: asset_id, returnShift: pivot.returnShift, returnScale: pivot.returnScale, return_shift_enabled: !!return_shift_enabled } : undefined } 
            }) : []
            const assets: Asset[] = payloadAssets ? payloadAssets.map((item: Asset) => { return { ...item, label: item.name, value: item.id } }) : []
            
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { cma_assets, asset_sets, assets, risk_premium, start_date: start_date ? moment(start_date, 'MM/YYYY').toDate() : undefined, end_date: end_date ? moment(end_date, 'MM/YYYY').toDate() : undefined }})
        } catch(e: any) {
            dispatch(addError('Custom CMA', e.toString()))
        }
    }
}

export const loadAssetSet = ({ id }: LoadAssetSetRequest): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_LOADING })

            const profileId = getPathsClientId()
            const { data } = await axios.post(`${CLIENTS_ENDPOINT}/${profileId}/${CAPITAL_MARKET_ASSUMPTIONS}/sets/${id}/load`)
            const { custom_cma_assets: payloadCmaAssets, start_date, end_date } = data

            const cma_assets = payloadCmaAssets ? payloadCmaAssets.map(({ asset, asset_id, ...item }: any) => { return { ...item, asset: asset ? { ...asset, label: asset.name, value: asset_id } : undefined } }) : []
            
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { cma_assets, start_date: start_date ? moment(start_date, 'MM/YYYY').toDate() : undefined, end_date: end_date ? moment(end_date, 'MM/YYYY').toDate() : undefined }})
            dispatch(onAssetListChanged())
            dispatch(updateOptAssetSet(id))
        } catch(e: any) {
            dispatch(addError('Custom CMA', e.toString()))
        }
    }
}

export const createAssetSet = ({ name, replace = false, replaceId }: CreateAssetSetRequest & CreateAssetSetReplaceRequest): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_LOADING })

            const profileId = getPathsClientId()
            const { data } = await axios.post(`${CLIENTS_ENDPOINT}/${profileId}/${CAPITAL_MARKET_ASSUMPTIONS}/sets`, { name, replace, replaceId })
            const { success, cmaAssetSet } = data
            if(!success) {
                return
            }
            
            dispatch(updateOptAssetSet(cmaAssetSet.id))

            const { capitalMarketAssumptions } = getStore()
            const { asset_sets } = capitalMarketAssumptions
            const enhancedAssetSet = { ...cmaAssetSet,  label: cmaAssetSet.name, value: cmaAssetSet.id }
            if(replace && replaceId) {
                const updatedAssetSets = asset_sets.map((item: AssetSet) => {
                    const { id } = item
                    if(id == replaceId) {
                        return enhancedAssetSet
                    }

                    return item
                })

                dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { asset_sets: updatedAssetSets }})
            } else {

                dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { asset_sets: [...asset_sets, enhancedAssetSet ] }})
            }

            dispatch(onAssetSetChanged(enhancedAssetSet))
        } catch(e: any) {
            dispatch(addError('Custom CMA', e.toString()))
        }
    }
}


export const deleteAssetSet = ({ id }: DeleteAssetSetRequest): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_LOADING })

            const profileId = getPathsClientId()
            const { data } = await axios.delete(`${CLIENTS_ENDPOINT}/${profileId}/${CAPITAL_MARKET_ASSUMPTIONS}/sets/${id}`)
            const { success } = data
            if(!success) {
                return
            }

            const { capitalMarketAssumptions } = getStore()
            const { asset_sets } = capitalMarketAssumptions
            
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { asset_sets: asset_sets.filter(({ id: setId }: AssetSet) => id != setId) }})
            dispatch(onAssetSetChanged(null))
        } catch(e: any) {
            dispatch(addError('Custom CMA', e.toString()))
        }
    }
}

export const onCreateAsset = (): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        try {
            const profileId = getPathsClientId()
            const { data } = await axios.post(`${CLIENTS_ENDPOINT}/${profileId}/${CAPITAL_MARKET_ASSUMPTIONS}`)
            const { asset } = data


            const { capitalMarketAssumptions } = getState()
            const { cma_assets: currentCmaAssets = []} = capitalMarketAssumptions
            const cma_assets = [...currentCmaAssets, asset]

            dispatch(updateOptAssetSet(undefined))
            
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { cma_assets }})
        } catch(e: any) { }
    }
}

export const onRemoveAsset = (id: number): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        try {
            const { capitalMarketAssumptions } = getState()
            const { cma_assets: currentCmaAssets = []} = capitalMarketAssumptions
            const cma_assets = currentCmaAssets.filter(({ id: itemId }: CMAAsset) => id != itemId)
            
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { cma_assets }})

            const profileId = getPathsClientId()
            await axios.delete(`${CLIENTS_ENDPOINT}/${profileId}/${CAPITAL_MARKET_ASSUMPTIONS}/${id}`)
            dispatch(onAssetListChanged())
            dispatch(updateOptAssetSet(undefined))

        } catch(e: any) { }
    }
}

export const updateDate = (type: DateType, value: string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            if(!['start_date', 'end_date'].includes(type)) {
                return
            }

            dispatch({ type: CMA_DATES, payload: { type, value }})
            dispatch(updateBuilderPreferences({ [type]: moment(value).format('MM/YYYY') }, false))
        } catch(e: any) { }
    }
}

export const onSelectedAssetChanged = (id: number, asset: Asset): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        try {
            const { capitalMarketAssumptions } = getState()
            const { cma_assets: currentCmaAssets = []} = capitalMarketAssumptions
            const { id: asset_id } = asset
            const cma_assets = currentCmaAssets.map((cma_asset: CMAAsset) => {
                if(cma_asset.id == id) {
                    return { ...cma_asset, asset }
                }

                return cma_asset
            })

            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { cma_assets }})

            const profileId = getPathsClientId()
            await axios.put(`${CLIENTS_ENDPOINT}/${profileId}/${CAPITAL_MARKET_ASSUMPTIONS}/${id}`, { asset_id })
            dispatch(onAssetListChanged())
            dispatch(updateOptAssetSet(undefined))
        } catch(e: any) {

        }
    }
}

export const onItemUpdated = (id: number, payload: any): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        try {
            const { capitalMarketAssumptions } = getState()
            const { cma_assets: currentCmaAssets = []} = capitalMarketAssumptions

            let cma_assets = currentCmaAssets.map((cma_asset: CMAAsset) => {
                if(cma_asset.id == id) {
                    return { ...cma_asset, ...payload }
                }

                return cma_asset
            })

            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { cma_assets }})

            const profileId = getPathsClientId()
            const { data } = await axios.put(`${CLIENTS_ENDPOINT}/${profileId}/${CAPITAL_MARKET_ASSUMPTIONS}/${id}`, payload)
            const { success, cma_asset: item } = data || { }
            if(success) {
                cma_assets = cma_assets.map((cma_asset: CMAAsset) => {
                    if(cma_asset.id == id) {
                        return { ...cma_asset, ...item }
                    }

                    return cma_asset
                })
                dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { cma_assets }})
            }
            dispatch(updateOptAssetSet(undefined))
        } catch(e: any) {

        }
    }
}

export const onReturnScaleChanged = (id: number, value: string): StandardThunkAction => onItemUpdated(id, { returnScale: value })
export const onReturnShiftChanged = (id: number, value: string, shiftForm: ReturnShiftForm): StandardThunkAction => onItemUpdated(id, { returnShift: value, return_shift_form: shiftForm })

export const onReturnShiftEnableToggled = (id: number, value: boolean): StandardThunkAction => {
    const payload = { return_shift_enabled: value }

    return onItemUpdated(id, payload)
}

export const updateRiskPremium = (risk_premium: string): StandardThunkAction => {
    return updateBuilderPreferences({ risk_premium })
}

export const updateBuilderPreferences = (payload: LooseObject, updateState: boolean = true): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            if(updateState){
                dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload})
            }

            const profileId = getPathsClientId()
            await axios.put(`${CLIENTS_ENDPOINT}/${profileId}/${BUILDER_PREFERENCES}`, payload)
            if(Object.keys(payload).includes('opt_asset_set_id') || Object.keys(payload).includes('portfolio_map_id')) {
                dispatch(getInvestmentPortfolio())
            }

        } catch(e: any) {

        }
    }
}

export const onAssetSetChanged = (selectedAssetSet: AssetSet | null): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: ON_ASSET_SET_CHANGED, payload: selectedAssetSet })
        dispatch(onAssetListChanged())
    }
}

export const runCustomCMAScripts = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        const profileId = getPathsClientId()
        
        dispatch({ type: RUNNING_CUSTOM_CMA_SCRIPT, payload: true })
        try {
            const { data: payload } = await axios.post(`${CLIENTS_ENDPOINT}/${profileId}/${CAPITAL_MARKET_ASSUMPTIONS}/run`)
            const { success, message, custom_cma_assets: payloadCmaAssets } = payload
            
            if(!success) {
                dispatch(addError('Script Error!', message))
                dispatch({ type: RUNNING_CUSTOM_CMA_SCRIPT, payload: false })

                return
            }
            
            const cma_assets = payloadCmaAssets ? payloadCmaAssets.map(({ asset, asset_id, ...item }: any) => { return { ...item, asset: asset ? { ...asset, label: asset.name, value: asset_id } : undefined } }) : []
            
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { cma_assets }})
        } catch(e: any) {
            dispatch(addError('Script Error!', e))
        }
        dispatch({ type: RUNNING_CUSTOM_CMA_SCRIPT, payload: false })
    }
}

export const onAssetListChanged = (): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        try {
            const { investmentPortfolio } = getState()
            const { opt_asset_set_id } = investmentPortfolio

            //if opt_asset_set_id is null/undefined then it means the custom cma asset set is being used in Optimized Portfolio screen and needs to be updated.
            if(!opt_asset_set_id) {
                dispatch(getInvestmentPortfolio())
            }
        } catch(e: any) {
            //console.log(e)
        }
    }
}



export const assetHasValues = (asset: CMAAsset): boolean => {
    const fields = ['mpte', 'mctu', 'mctr', 'mctv', 'mcts', 'mctk', 'mpteTop3Assets', 'pG_pI', 'pG_nI', 'nG_pI', 'nG_nI', 'return', 'returnSE', 'volatility', 'skew', 'kurtosis', 'port', 'benchmark_port'];
    for(const field of fields) {
        const value = asset[field]
        if(value && value != '0') {
            return true
        }
    }

    return false;
}