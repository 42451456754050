import { Box } from '@mui/material'
import { Component } from 'react'
import { SketchPicker } from 'react-color';
import Switch from '@mui/material/Switch';
import ConfirmModal from '../../common/ConfirmModal'
import { FieldItem } from '../../common/FormField'
import { DASH_OPTION, DueDiligenceChartAnnotationsEdit, LINE_WEIGHT_ITEMS, LineType, LineWeight } from '../../../actions/types/dueDiligence'
import { Select } from '../../../components/common/styled/Select'
import { SelectOption } from '../../../common/types'
import { connect } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { Ticker } from '../../../actions/types/portfolioDesigner';
import TickerProxyInput from '../../../components/PortfolioDesigner/TickerProxyInput';
import LineRenderer from './LineRenderer';

const LINE_DASH_STYLE: SelectOption[]  = DASH_OPTION.map((item: string) => ({ label: <Box sx={{ width: '5rem', display: 'flex' }}><LineRenderer lineType={item} /></Box>, value: item}))

export const LINE_WEIGHT_SELECT_OPTIONS: SelectOption[] = LINE_WEIGHT_ITEMS.map((value) => ({ label: <Box sx={{ width: '5rem', display: 'flex' }}><Box sx={{ width: '5rem', height: value + .25, backgroundColor: 'black'}}></Box></Box>, value  }))

interface ComponentProps {
    onSave: (item: DueDiligenceChartAnnotationsEdit, requiresProcessing: boolean) => void
    onCancel: () => void
    item?: DueDiligenceChartAnnotationsEdit
    visible: boolean
}

interface ReduxStateProps {
    tickerItem?: Ticker
}

interface AnnotationEditModalModalState {
    item: DueDiligenceChartAnnotationsEdit
    selectedWeightOption: SelectOption
    selectedLineDashOption: SelectOption
    showColorPickerModal: boolean;
    colorPickerColor?: string;
    requiresProcessing: boolean;
}

type AnnotationEditModalModalProps = ComponentProps & ReduxStateProps;

class AnnotationEditModal extends Component<AnnotationEditModalModalProps, AnnotationEditModalModalState> {
    selectedLinePath: any;

    constructor(props: AnnotationEditModalModalProps) {
        super(props);
        this.state = {
            showColorPickerModal: false,
            requiresProcessing: false,
            item: {...(props.item ?? {})},
            selectedWeightOption: LINE_WEIGHT_SELECT_OPTIONS.find(item => item.value === props.item?.line_weight) ?? LINE_WEIGHT_SELECT_OPTIONS[0],
            selectedLineDashOption: this.getSelectedLineDashOption(props.item?.line_type ?? LineType.SOLID),
        }
    }

    getSelectedLineDashOption = (lineType: LineType) => {
        return { label: <Box sx={{ width: '10rem', display: 'flex' }}><LineRenderer lineType={lineType} /></Box>, value: lineType}
    }

    componentDidUpdate(prevProps: Readonly<AnnotationEditModalModalProps>, prevState: Readonly<AnnotationEditModalModalState>, snapshot?: any): void {
        if(prevProps.item !== this.props.item) {
            const selectedWeightOption =  LINE_WEIGHT_SELECT_OPTIONS.find(item => item.value === this.props.item?.line_weight) ?? LINE_WEIGHT_SELECT_OPTIONS[0]
            const selectedLineDashOption = this.getSelectedLineDashOption(this.props.item?.line_type ?? LineType.SOLID);

            this.setState({ item: {...(this.props.item ?? {})}, selectedWeightOption, selectedLineDashOption, requiresProcessing: false })
        }
    }

    onWeightOptionChanged = (selectedWeightOption: SelectOption) => {
        this.setState({ selectedWeightOption, item: { ...this.state.item, line_weight: selectedWeightOption.value as LineWeight }})
    }

    onDashStyleOptionChanged = (selection: SelectOption) => {
        
        const selectedLineDashOption = this.getSelectedLineDashOption(selection.value as LineType);
        this.setState({ selectedLineDashOption, item: { ...this.state.item, line_type: selection.value as LineType }})
    }
    
    onSave = () => {
        if(!this.props.onSave) {
            return
        }
        const {item, requiresProcessing} = this.state
        this.props.onSave(item, requiresProcessing)
    }

    onCloseColorPicker = () => {
        this.setState({ showColorPickerModal: false, colorPickerColor: undefined })
    }

    onConfirmColorPicker = () => {
        const {item, colorPickerColor} = this.state
        this.setState({ item: {...item, line_color: colorPickerColor } })
        this.onCloseColorPicker();
    }

    onColorPickerColorChanged = (color: any) => {
        this.setState({ colorPickerColor: color.hex })
    }

    onShowColorPicker = () => {
        const {item} = this.state
        this.setState({ colorPickerColor: item?.line_color, showColorPickerModal: true })
    }

    onUseBackfilToggled = () => {
        const {item} = this.state

        this.setState({ item: {...item, use_backfill: !item?.use_backfill }, requiresProcessing: true })
    }

    onDashSelectOpened = () => {
    }

    onTickerProxyUpdated = (_?: Ticker) => {
        this.setState({ requiresProcessing: true })
    }

    render() {
        const { visible, tickerItem } = this.props
        const { item, selectedWeightOption, selectedLineDashOption, showColorPickerModal, colorPickerColor} = this.state

        return (
            <ConfirmModal
                visible={visible}
                title={`${item.symbol} - Annotations`}
                confirmLabel={'Save'}
                cancelLabel={'Cancel'}
                onConfirm={this.onSave}
                onCancel={this.props.onCancel}>
                <ConfirmModal
                    title='Line Color'
                    visible={showColorPickerModal}
                    onCancel={this.onCloseColorPicker}
                    onConfirm={this.onConfirmColorPicker}
                    size={'sm'}
                    confirmLabel={'Select'}>
                        <Box sx={{ 
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& .sketch-picker': {
                                boxShadow: 'unset !important'
                            }
                        }}>
                            <SketchPicker
                                color={ colorPickerColor }
                                onChange={ this.onColorPickerColorChanged }
                            />
                        </Box>
                </ConfirmModal>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <FieldItem title={'Line Width'} inline={false}>
                                <Select sx={{ width: '35rem', marginRight: '1rem' }} options={LINE_WEIGHT_SELECT_OPTIONS} value={selectedWeightOption} onChange={this.onWeightOptionChanged} />
                            </FieldItem>
                            <FieldItem title={'Line Style'} inline={false}>
                                <Select sx={{ width: '35rem', marginRight: '1rem' }} options={LINE_DASH_STYLE} value={selectedLineDashOption} onChange={this.onDashStyleOptionChanged} onMenuOpen={this.onDashSelectOpened} />
                            </FieldItem>
                            <FieldItem title={'Line Color'} inline={false}>
                                <Box onClick={this.onShowColorPicker} sx={{ width: '5rem', height: '5rem', backgroundColor: item?.line_color ?? 'white', border: '.1rem solid' }} />
                            </FieldItem>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <FieldItem title={'Use Backfill'} inline={false}>
                                <Switch
                                    checked={item?.use_backfill ?? false}
                                    onChange={this.onUseBackfilToggled}
                                />
                                {tickerItem &&
                                    <TickerProxyInput 
                                        inputContainerSx={{ width: '30rem', flex: undefined, padding: '.5rem', border: (theme) => `1px solid ${theme.palette.primary.main}` }}
                                        ticker={tickerItem}
                                        onTickerProxyUpdated={this.onTickerProxyUpdated}
                                        hideEnableToggle={true}
                                    />
                                }
                            </FieldItem>
                        </Box>
                    </Box>
            </ConfirmModal>
        )
    }
}

const mapStateToProps = ({dueDiligence}: ReduxState, {item}: ComponentProps) => ({
    tickerItem: dueDiligence.tickers[item?.symbol ?? ''],
})

export default connect(mapStateToProps)(AnnotationEditModal)