import { red } from '@mui/material/colors';
import { Theme as MuiTheme, createTheme } from '@mui/material/styles';
import { FONT_STYLES_BY_TYPE } from '../components/common/styled/Text';
import colors from './colors';

// A custom theme for this app
const options = {
  palette: {
    primary: {
      main: colors.dark_purple,
      main2: colors.light_purple,
      dark: colors.off_black,
      white: colors.white,
      transparent: colors.transparent,
    },
    secondary: {
      main: colors.light_purple,
      main2: colors.off_light_purple,
      main3: colors.teal
    },
    //@ts-ignore
    button: {
      primary: {
        background: colors.light_purple,
        color: colors.white,
      },
      secondary: {
        background: colors.teal,
        color: colors.white,
      },
      outline: {
        background: colors.white,
        color: colors.off_black,
        border: colors.light_purple
      },
      danger: {
        background: colors.white,
        color: colors.red,
        border: colors.red
      },
    },
    error: {
      main: red.A400,
    },
    text: {
      inverted: colors.white,
      level1: colors.grey,
      level2: colors.off_light_purple,
      level3: colors.teal,
      level4: colors.light_mint,
      level5: colors.ultra_light_purple,
      level6: colors.mint,
      level7: colors.light_purple,
      level8: colors.off_white,
      level9: colors.grey_2,
      level10: colors.dark_purple,
      level11: colors.light_grey,
      level12: colors.light_blue,
    },
    background: {
      default: colors.white,
      level1: colors.grey,
      level2: colors.off_light_purple,
      level3: colors.teal,
      level4: colors.light_mint,
      level5: colors.ultra_light_purple,
      level6: colors.mint,
      level7: colors.light_purple,
      level8: colors.off_white,
      level9: colors.grey_2,
      level10: colors.dark_purple,
      level11: colors.light_grey,
    },
    borders: {
      primary: colors.light_grey,
      level1: colors.grey,
      level2: colors.off_light_purple,
      level3: colors.teal,
      level4: colors.light_mint,
      level5: colors.ultra_light_purple,
      level6: colors.mint,
      level7: colors.light_purple,
      level8: colors.off_white,
      level9: colors.grey_2,
      level10: colors.dark_purple,
      level11: colors.light_grey,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: '10px !important',
        },
        body: {
          background: '#ecf0f5',
          fontFamily: 'Montserrat',
        },
        ".ovrimg-mx img, body iframe, body img": {
          maxWidth: '100%',
        },
        '.modal-dialog': {
          top: '10%',
        },
        '.modal-content': {
          border: '0 solid #eee',
          borderRadius: 0,
          boxShadow: '0 20px 140px 13px #666',
        },
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1750,
      xxxl: 2084,
    },
  },
}

export type Theme = (typeof options & MuiTheme) | MuiTheme

export default createTheme(options as Theme); 