import React, {FC} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabPanel } from '../../../common/TabPanel';
import Text from '../../../common/Text';
import SnapshotTable from './Summary/SnapshotTable';
import CorrelationMatrix from './CorrelationMatrix';
import FixedIncomeBreakdown from './FixedIncomeBreakdown';
import Summary from './Summary';
import EquityBreakdown from './EquityBreakdown';


const getTabTitleProps = (index: number, title: string) => {
  return {
    id: `tab-${index}`,
    index,
    label: <Text>{title}</Text>,
    'aria-controls': `tabpanel-${index}`,
  };
}

interface PortfolioAnalysisTabsProps { }

const PortfolioAnalysisTabs: FC<PortfolioAnalysisTabsProps> = ({ }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="">
          <Tab {...getTabTitleProps(0, 'Summary')} />
          <Tab {...getTabTitleProps(1, 'Fixed Income Breakdown')} />
          <Tab {...getTabTitleProps(1, 'Equity Breakdown')} />
          <Tab {...getTabTitleProps(2, '1 Year Corr. Matrix')} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Summary />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FixedIncomeBreakdown />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EquityBreakdown />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CorrelationMatrix />
      </TabPanel>
    </Box>
  );
}

export default PortfolioAnalysisTabs;