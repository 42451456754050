export interface GlossaryTerm {
    term: string
    definition: string
}

const glossary: GlossaryTerm[] = [
    {
        "term": "Forecast Adjustments",
        "definition": "We forecast the entire joint distribution rather than individual moments and co-moments. To this end we deploy joint historical return datasets as our forecasts except where returns should be shifted for secular corrections, fees, dividend taxes, or manager alpha, or returns should be scaled to account for capital gains taxes."
    },
 
    {
     "term": "Kurtosis Contribution",
     "definition": "The absolute amount the portfolio monthly kurtosis changes when we add 10% of an asset to a portfolio 100% invested in the \"risk premium comparison\" asset."
    },
 
    {
     "term": "Loss Aversion",
     "definition": "A risk preference that measures the disproportionate discomfort an investor feels from losses vs. gains, essentially a measure of preferences specifically regarding downside volatility. Higher loss aversion corresponds to a client preference for less drawdown."
    },
 
    {
     "term": "Mimicking Portfolio Tracking Error",
     "definition": "A portfolio of all the other assets in the portfolio that most closely tracks the asset being studied. Low tracking error between the mimicking portfolio and the asset being tested implies that the asset under consideration is redundant to the rest of the portfolio being considered."
    },
 
    {
     "term": "Moderated Risk Preferences",
     "definition": "Client risk preferences are moderated by their balance sheet's ability to handle their investment preferences. As SLR goes up, risk aversion is moderated up, while loss aversion and reflection are moderated away since they are sub-optimal. Risk aversion and loss aversion are both moderated proportionally to SLR, while reflection is discretely moderated if SLR goes above 80%."
    },
 
    {
     "term": "Moment Contribution",
     "definition": "The absolute amount the portfolio moment changes when we add 10% of an asset to a portfolio 100% invested in the \"risk premium comparison\" asset."
    },
 
    {
     "term": "Optimization",
     "definition": "The mathematical process of creating an optimal portfolio of assets given your capital market assumptions and the precise target function you are optimzing. We are squarely focused on maximizing our client's utility function but also provide other common target functions or heuristics for comparison. Below is a list of all optimization options available to users. All optimizer models are run long-only with no leverage."
    },
 
    {
     "term": "Reflection",
     "definition": "A risk preference that measures whether an investor prefers to roll the dice on losing positions i.e. is risk-seeking when it comes to losses (reflection=HIGH) rather than following the neo-classical assumption of being risk-averse for both gains and losses (reflection=LOW)."
    },
 
    {
     "term": "Risk Aversion",
     "definition": "Represents the client’s aversion to volatility in the absence of loss aversion and reflection. Higher risk aversion corresponds to a client preference for less volatility."
    },
 
    {
     "term": "Historical Scenario Analysis",
     "definition": "Shows the portfolio performance during a set of prespecified historical scenarios. This “what if” tool is helpful to see how the given portfolio responds to different economic and market events."
    },
 
    {
     "term": "Skew Contribution",
     "definition": "The absolute amount the portfolio monthly skew changes when we add 10% of an asset to a portfolio 100% invested in the \"risk premium comparison\" asset."
    },
 
    {
     "term": "Volatility Contribution",
     "definition": 'The absolute amount the portfolio monthly volatility changes when we add 10% of an asset to a portfolio 100% invested in the “Risk Premium comparison” asset.'
    },
 
    {
     "term": "Living Risk",
     "definition": " Measures how at risk the desired level of retirement consumption is based on the simple assumption that assets grow at the risk free rate. SLR is used as an \"objective\" measure of risk aversion and is used to moderate our three risk preference parameters."
    },
 
    {
     "term": "Stationary",
     "definition": "A stochastic process is stationary if its return distribution doesn\u2019t change in time. If a process is stationary then we can use history as a reasonable forecast of the future. Here we deploy the Kolmogorov-Smirnov test to test whether we can reject the fact that the distribution under consideration is indeed stationary."
    },
 
    {
     "term": "Utility",
     "definition": "Measures the value an individual gets from a particular outcome beyond just it’s monetary value.For example, someone who is hungry gets a lot more value from a slice of pizza than someone who is full. In portfolio theory, a utility function codifies an investor’s preferences for earning more money while bearing the risk of losing some of that money in the process.We can fully specify client preferences with the following three utility functions."
    },
 
    {
     "term": "Windsorize",
     "definition": "The process of removing the top and bottom X number of data points from the return distribution, typically to study the effect of extreme events/outliers."
    },

    {
     "term": "Nixon Price Controls",
     "definition": "1/72 - 4/74"
    },

    {
        "term": "1982 Bull Market",
        "definition": "9/82 - 3/84"
    },

    {
        "term": "1987 Market Crash",
        "definition": "10/87"
    },

    {
        "term": "Surprise Fed Rate Hike",
        "definition": "2/94 - 3/94"
    },

    {
        "term": "Tech Bubble",
        "definition": "1/99 - 3/00"
    },

    {
        "term": "Tech Bust",
        "definition": "4/00 - 2/03"
    },

    {
        "term": "Easy Credit",
        "definition": "8/02 - 3/04"
    },

    {
        "term": "Credit Crisis",
        "definition": "7/07 - 3/09"
    },

    {
        "term": "γ",
        "definition": "Represents the client’s aversion to volatility in the absence of loss aversion and reflection. Higher risk aversion corresponds to a client preference for less volatility."
    },

    {
        "term": "λ",
        "definition": "A risk preference that measures the disproportionate discomfort an investor feels from losses vs. gains, essentially a measure of preferences specifically regarding downside volatility. Higher loss aversion corresponds to a client preference for less drawdown."
    },

    {
        "term": "φ",
        "definition": "A risk preference that measures whether an investor prefers to roll the dice on losing positions i.e. is risk-seeking when it comes to losses (reflection=HIGH) rather than following the neo-classical assumption of being risk-averse for both gains and losses (reflection=LOW)."
    },
    
    {
        "term": "Historical Return",
        "definition": "Return is calculated to be the historical arithmetic annual return."
    },
    {
        "term": "Additional Retirement Income",
        "definition": "Additional retirement income can be considered to be Social Security, Pensions, Annuities, and/or any additional income sources used for retirement funding.  For example, in 2022 the average Social Security check is roughly $1660/mnth, with a spousal benefit of $840/mnth, which for a married couple would equate to $30,000/year input.",
    },
    {
        "term": "Standard of Living",
        "definition": "Standard of Living Risk (SLR) is an asset-liability matching framework that measures the risk of a client not meeting their retirement goals.  High SLR implies a client's retirement goals are lofty relative to their current savings plan, while low SLR implies retirement goals that can easily be met with the current savings plan."
    },
    {
        "term": "ALM-Adjusted Risk Aversion",
        "definition": "The amount of risk aversion a client should be allowed to express given their balance sheet.  Weak balance sheets are moderated away from risk-taking to ensure goals are met with certainty.  Healthy balance sheets allow for full expression of one's risk preference."
    },
    {
        "term": "ALM-Adjusted Loss Aversion",
        "definition": "The amount of loss aversion a client should be allowed to express given their balance sheet.  Weak balance sheets are moderated away from risk-taking to ensure goals are met with certainty.  Healthy balance sheets allow for full expression of one's risk preference."
    },
    {
        "term": "ALM-Adjusted Reflection",
        "definition": "The amount of reflection a client should be allowed to express given their balance sheet.  Weak balance sheets are moderated away from risk-taking to ensure goals are met with certainty.  Healthy balance sheets allow for full expression of one's risk preference."
    },
    {
        "term": "Absolute Risk",
        "definition": "Absolute Risk is the downside threshold a client is willing to risk e.g. a 10% absolute risk states that the portfolio is not too fall by more than 10%."
    },
    {
        "term": "Relative Risk",
        "definition": "Relative Risk is a client’s tracking error comfort limit to a stated benchmark e.g. the portfolio should generally deviate from the Morningstar Moderate Index by more than 2%."
    },
    {
        "term": "Tax Concerns",
        "definition": "Are there any tax issues which need to be addressed with this account e.g. trust taxation, taxable accounts to include primarily municipal securities, etc."
    },
    {
        "term": "Legal And Regulatory Factors",
        "definition": "Are there any legal or regulatory issues that account must be managed around e.g. conditions for contributions and withdrawals in a retirement account."
    },
    {
        "term": "Unique Circumstances",
        "definition": "Are there any other particular mandates that must be followed for the client e.g. ESG investing focus, exclusion of certain tickers or investments, etc."
    },
    {
        "term": "ALM Risk",
        "definition": "ALM Risk is an asset-liability matching framework that measures the risk of a client not meeting their retirement goals.  High ALM Risk implies a client's retirement goals are lofty relative to their current savings plan, while low ALM Risk implies retirement goals that can easily be met with the current savings plan."
    },
    {
     "term": "Volmageddon 2018",
     "definition": "1/26/2018 - 2/8/2018"
    },
    {
     "term": "Q4 2018",
     "definition": "9/20/2018 - 12/24/2018"
    },
    {
     "term": "COVID 2020",
     "definition": "2/20/2020 - 3/23/2020"
    },
    {
     "term": "Current Percentile",
     "definition": "The current percentile of the factor value within all historical data points within the relevant regime."
    },
 ]

export default glossary
