import { Box } from '@mui/material'
import React, { FunctionComponent } from 'react'
import { BaseProps } from '../types'

export const GaugeContainer: FunctionComponent<BaseProps> = ({ sx = {}, children }) => {
    return (
        <Box sx={{
            position: 'relative',
            marginLeft: '2rem',
            marginRight: '2rem',
            marginTop: '2rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            ...sx
        }}>
            {children}
        </Box>
    )
}

export default GaugeContainer