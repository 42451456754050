import React, { Component, FunctionComponent, LiHTMLAttributes, OlHTMLAttributes } from 'react'
import { connect } from 'react-redux'
import ClipLoader from "react-spinners/ClipLoader";

import { getPrintDetails } from '../../actions/print'
import RiskAversionGauge from '../Gauge/RiskAversionGauge'
import LossAversionGauge from '../Gauge/LossAversionGauge'
import CommonTable from '../common/CommonTable'
import { getDisplayValue } from '../../common/utils'
import Chart from '../common/Chart'
import PageBreak from './PageBreak';
import { LooseObject } from '../../common/types';
import { Holdings, KeyStats, PortfolioMapDetails } from '../../actions/types/investmentPortfolio';
import { InvestmentPolicyStatement } from '../../actions/types/investmentPolicyStatement';
import { RiskParameters } from '../../actions/types/riskPreferences';
import { StandardLivingRiskSimple } from '../../actions/types/standardLivingRisk';
import { Box, GlobalStyles, ListItemProps, SxProps, Theme } from '@mui/material';
import Text, { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import GaugeContainer from '../Gauge/GaugeContainer';
import { BaseProps } from '../types';
import { Span } from '../common/styled/Span';
import { withAuth } from 'src/common/hooks/withAuth';
import { AccountLogo } from '../common/styled/AccountLogo';

export interface SignatureProps extends BaseProps {
    title: string
}

export interface SignatureSectionProps extends SignatureProps {
    lineSx?: SxProps<Theme>
}

export const SignatureSection: FunctionComponent<SignatureSectionProps> = ({ title, lineSx = { } }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: '1'
        }}>
            <Box sx={{
                display: 'flex',
                fontWeight: 'bold'
            }}><Text>{title}</Text></Box>
            <Box sx={{
                display: 'flex',
                borderBottom: '2px solid black',
                flex: '1',
                marginTop: '-.2rem',
                marginRight: '5rem',
                marginLeft: '1rem',
                ...lineSx
            }}></Box>
        </Box>        
    )
}

export const SignatureLine: FunctionComponent<SignatureProps> = ({ title }) => {
    return (
        <Box 
            sx={{
                display: 'flex',
                width: '100%',
                marginTop: '2rem'
            }}
        >
            <SignatureSection title={title} />
            <SignatureSection title={'Date'} sx={{ maxWidth: '20rem' }} />
        </Box>
    )
}

export const SubSectionItem: FunctionComponent<BaseProps> = ({ children, sx = { }, ...props}) => {
    return (
        <Box
            sx={{
                paddingLeft: '6rem',
                marginTop: '1rem',
            }}
            {...props}
        >
            {children}
        </Box>
    )
}

export interface PrintListProps extends OlHTMLAttributes<any>, BaseProps {
}

export const PrintList: FunctionComponent<PrintListProps> = ({ children, ...restProps}) => {
    return (
            <Box
                component={'ol'}
                type={'1'}
                {...restProps}>
                    {children}
            </Box> 
        )
}
export interface PrintListItemProps extends LiHTMLAttributes<any>, BaseProps {
}

export const PrintListItem: FunctionComponent<PrintListItemProps> = ({ children, sx = { }, ...restProps}) => {
    return (
            <Box
                component={'li'}
                sx={{ 
                    alignItems: 'flex-start',
                    font: FONT_STYLES_BY_TYPE.text,
                    ...sx
                }}
                {...restProps}>
                    {children}
            </Box> 
        )
}

export const ListSection: FunctionComponent<PrintListProps> = ({ children, sx = { }, ...props }) => {
    return (
        <PrintList
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                '&>li': {
                    marginBottom: '.5rem',
                },
                ...sx,
            }}
            {...props}
        >{children}</PrintList>
    );
}

export const ListSectionItem: FunctionComponent<ListItemProps> = ({ children, sx = { }, ...props }) => {
    return (
        <PrintListItem
            sx={{
                marginTop: '2rem',
                flexDirection: 'column',
                width: '100%',
                '&::marker': {
                    fontWeight: '600',
                },
                ...sx,
            }}
            {...props}
        >{children}</PrintListItem>
    );
}

export const PrintGaugeContainer: FunctionComponent<BaseProps> = ({ children }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            transform: 'scale(.5)',
            marginTop: '-8rem',
            marginLeft: '-40rem',
            marginBottom: '-11rem'
        }}>{children}</Box>
    )
}


export interface PrintProps {
    getPrintDetails: () => void
    advisorName: string
    clientName: string
    firmAddress: string
    ips: InvestmentPolicyStatement
    riskPreferences: RiskParameters
    keyStats: KeyStats
    slr: StandardLivingRiskSimple
    enterprise?: boolean
    hasSLR?: boolean
    loaded?: boolean
    timeHorizon: number
    ips_disclosures?: string
    holdings: Holdings[]
    tableDetail: PortfolioMapDetails
    assetAllocationChart: LooseObject
    riskAnalyzerEnterpriseChart: LooseObject
}

class Print extends Component<PrintProps> {
    
    componentDidMount() {
        this.props.getPrintDetails()
    }

    componentDidUpdate(prevProps) {
        if(!location.search.includes('noprint=1') && !prevProps.loaded && this.props.loaded) {
            setTimeout(() => {
                window.print()
            }, 1000)
        }
    }

    render() {
        const { advisorName, clientName, firmAddress, ips, riskPreferences, keyStats, assetAllocationChart, enterprise,
                slr, hasSLR, loaded, timeHorizon, ips_disclosures, holdings, tableDetail, riskAnalyzerEnterpriseChart } = this.props
        if (!loaded) {
            return (
                <Box sx={{
                    display: 'flex',
                    flex: 1,
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <ClipLoader color={'#297ea1'} loading={true} size={35} />
                </Box>
            )
        }
        const { discount_rate, inflation_rate, pv_savings, pv_liabilities, assets_discreationary_wealth, standardLivingRisk } = slr
        const assetLiability = [
            {
                title: 'Discount Rate',
                value: `${((discount_rate || 0) * 100).toFixed(0)}%`,
            },
            {
                title: 'Inflation Rate',
                value: `${((inflation_rate || 0) * 100).toFixed(0)}%`,
            },
            {
                title: 'PV of Assets',
                value: (pv_savings || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' , maximumFractionDigits: 0 }),
            },
            {
                title: 'PV Liabilities',
                value: (pv_liabilities || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' , maximumFractionDigits: 0 }),
            },
            {
                title: 'Discretionary Wealth',
                value: (assets_discreationary_wealth || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' , maximumFractionDigits: 0 }),
            },
            {
                title: 'ALM Risk',
                value: `${((standardLivingRisk || 0)).toFixed(2)}%`,
            },
        ].filter(({ value }) => value)

        const { absolute_risk, relative_risk, liquidity, tax_concerns, legal_and_regulatory_factors, unique_circumstances } = ips
        const constraints = [
            {
                title: 'Liquidity',
                value: liquidity,
            },
            {
                title: 'Time Horizon',
                value: timeHorizon,
            },
            {
                title: 'Tax Concerns',
                value: tax_concerns,
            },
            {
                title: 'Legal and Regulatory Factors',
                value: legal_and_regulatory_factors,
            },
            {
                title: 'Unique Circumstances',
                value: unique_circumstances,
            },
        ].filter(({ value }) => value)

        const { gamma, lambda } = riskPreferences

        const { monteCarloAnnMean, monteCarloAnnvol, monteCarloAnnmaxdrawdown } = keyStats

        const keyStatsMapped = [{ title: 'Annual Return', values: [`${getDisplayValue((monteCarloAnnMean * 12) * 100, 1)}%`] }, { title: 'Annual Volatility', values: [`${(parseFloat(monteCarloAnnvol || "0")  * Math.sqrt(12) * 100) .toFixed(1)}%`] }, { title: 'Maximum Drawdown', values: [`${parseFloat((monteCarloAnnmaxdrawdown || "0") * 100).toFixed(0)}%`] }]

        let riskAItems:React.ReactNode[] = []
        if(absolute_risk) {
            riskAItems = [...riskAItems, <Box key={'absolute_risk'}>Absolute risk: {absolute_risk}</Box>]
        }
        if(relative_risk) {
            riskAItems = [...riskAItems, <Box key={'relative_risk'}>Relative risk: {relative_risk}</Box>]
        }
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '114rem',
                background: 'white',
                padding: '2rem',
                marginTop: '8.5rem',
                font: FONT_STYLES_BY_TYPE.text,
                ['@page']: {
                    size: 'letter portrait',
                    margin: '0mm',
                },
                ['@media print']: {
                    marginTop: 'unset',
                    padding: 'unset',
                    ['@page']: {
                    size: 'letter portrait',
                    margin: '2rem',
                    },
                    body: {
                    ['-webkit-print-color-adjust']: 'exact',
                    margin: '0px',
                    },
                    '& *': {
                        '&::before': {
                            '-webkit-print-color-adjust': 'exact',
                            '-webkit-transition': 'none !important',
                            printColorAdjust: 'exact',
                            transition: 'none !important',
                        },
                        '&::after': {
                        '-webkit-print-color-adjust': 'exact',
                        '-webkit-transition': 'none !important',
                        printColorAdjust: 'exact',
                        transition: 'none !important',
                        }
                    }
                },
                '& *': {
                    pointerEvents: location.search.includes('noprint=1') ? 'auto': 'none',
                    '&::after': {
                        pointerEvents: location.search.includes('noprint=1') ? 'auto': 'none',
                    },
                    '&::before': {
                        pointerEvents: location.search.includes('noprint=1') ? 'auto': 'none',
                    }
                }
            }} >
            <GlobalStyles
                styles={{
                    body: { backgroundColor: 'white' },
                }}/>
                <Box sx={{
                        display: 'flex',
                        width: '100%',
                        position: 'relative',
                        justifyContent: 'center',
                        alignItems: 'center',
                }}>
                    <Box sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        height: '10rem',
                        width: '30rem',
                    }}>
                        <Box>{advisorName}</Box>
                        <Box>{firmAddress}</Box>
                    </Box>
                    <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '10rem',
                            width: '20rem',
                            '& img': {
                                maxWidth: '30rem',
                                maxHeight: '6rem',
                            }
                        }}
                    >
                        <AccountLogo />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        display: 'flex',
                        width: '100%',
                        position: 'relative',
                        flexDirection: 'column',
                        flex: 1,
                    }}
                >
                    <Box sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                    }}>
                        <Text asDiv type='form_header' sx={{ textDecoration: 'underline' }}>Investment Policy Statement for {clientName}</Text>
                        <Text asDiv type='form_header'>Created by {advisorName}</Text>
                    </Box>
                    
                    <ListSection type={'1'}>
                        <ListSectionItem>
                            <Text type='table_header' sx={{ textDecoration: 'underline' }}>Risk Assessment</Text>
                            <SubSectionItem>
                                <PrintList type={'A'}>
                                    {riskAItems.length > 0 &&
                                        <PrintListItem>
                                            {riskAItems}
                                        </PrintListItem>
                                    }
                                    <PrintListItem>
                                        <Box><Text>Risk Preferences: </Text></Box>
                                        <PrintGaugeContainer>
                                            <GaugeContainer>
                                                <RiskAversionGauge value={gamma} />
                                            </GaugeContainer>
                                            <GaugeContainer>
                                                <LossAversionGauge value={lambda} />
                                            </GaugeContainer>
                                        </PrintGaugeContainer>                                            
                                    </PrintListItem>
                                </PrintList> 
                            </SubSectionItem>
                        </ListSectionItem>
                        {hasSLR &&
                            <ListSectionItem>
                                <Text type='table_header' sx={{ textDecoration: 'underline' }}>Asset-Liability Matching</Text>
                                <SubSectionItem>
                                    <PrintList type={'A'}>
                                        {assetLiability.map(({ title, value }, index) => {
                                            return <PrintListItem key={title}>{title}: <Text type={'text_small'}>{value}</Text></PrintListItem>
                                        })}
                                    </PrintList> 
                                </SubSectionItem>
                            </ListSectionItem>
                        }
                        <ListSectionItem>
                            <Box><Text type='table_header' sx={{ textDecoration: 'underline' }}>Constraints</Text></Box>
                            <SubSectionItem>
                                <PrintList type={'A'}>
                                {constraints.map(({ title, value }, index) => {
                                    return <PrintListItem key={title}>{title}: <Text type={'text_small'}>{value}</Text></PrintListItem>
                                })}
                                </PrintList> 
                            </SubSectionItem>
                        </ListSectionItem>
                        <PageBreak />
                        <ListSectionItem>
                            <Box sx={{ paddingTop: '2.5rem'}}><Text type='table_header' sx={{ textDecoration: 'underline' }}>Portfolio Recommendation</Text></Box>
                            <SubSectionItem>
                                {enterprise && tableDetail &&
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                        <Span 
                                            sx={{
                                                fontSize: '2rem',
                                                marginBottom: '.5rem'
                                            }}>{tableDetail?.title}</Span>
                                        <Span
                                            sx={{
                                                maxWidth: '65rem',
                                                marginBottom: '.5rem'
                                            }}>{tableDetail?.objective}</Span>
                                        <Chart id="analyzerAllocatioChart" sx={{ width: '35rem', height: '15rem', backgroundColor: '#0000', zIndex: 0 }} chartOptions={riskAnalyzerEnterpriseChart} />
                                    </Box>
                                }
                                {enterprise && 
                                    holdings.map((item) => {
                                        const { title, value } = item
                                        const valuePercent = (value || 0) * 100
                                        return (
                                            <Box key={title} sx={{
                                                marginTop: '1rem',
                                                display: 'flex'
                                            }}>
                                                <Span sx={{
                                                    fontSize: 'medium',
                                                    fontWeight: 'bold',
                                                    flex: '1'
                                                }}>{title}</Span>
                                                <Span sx={{
                                                    display: 'flex',
                                                    fontSize: 'medium',
                                                    textAlign: 'end',
                                                    paddingLeft: '1.5rem',
                                                    flex: '1'
                                                }}>{(valuePercent).toFixed(2)}%</Span>
                                            </Box>
                                        )
                                    })
                                }
                                {!enterprise &&
                                    <Box sx={{
                                        display: 'flex',
                                        position: 'relative',
                                        height: '25rem'
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            transform: 'scale(.8)',
                                            marginTop: '-5rem',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <Box>
                                                <Chart id="allocationChart" sx={{ width: '40rem', height: '27.5rem', marginLeft: '-10rem', marginTop: '2.5rem', borderRadius: 400, zIndex: 0 }} chartOptions={assetAllocationChart} />
                                            </Box>
                                            <Box>
                                                <CommonTable
                                                    headerColumns={[{
                                                        key: 'Forecasted Stats',
                                                        children: 'Forecasted Stats',
                                                        colSpan: 2,
                                                    }]}
                                                    rows={keyStatsMapped}
                                                />
                                            </Box> 
                                        </Box>
                                    </Box>
                                }
                            </SubSectionItem>
                        </ListSectionItem>
                    </ListSection>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginTop: '7.5rem',
                        marginBottom: '5rem'
                    }}>
                        <SignatureLine title={'Client Signature'} />
                        <SignatureLine title={'Advisor Signature'} />
                        <Box sx={{
                            marginTop: '2.5rem',
                            fontStyle: 'italic'
                        }}>
                            <Text>{ips_disclosures}</Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

}

const mapStateToProps = ({ session, investmentPolicyStatement, investmentPortfolio, standardLivingRisk }, { user, userDetails }) => {
    const { absolute_risk, relative_risk, liquidity, tax_concerns, legal_and_regulatory_factors, unique_circumstances, time_horizon } = investmentPolicyStatement
    const { preferred, recommended, chosen, keyStats, holdings, tableDetail, riskAnalyzerEnterpriseChart, assetAllocationChart } = investmentPortfolio
    
    const { membership } = user
    const { advisor, firm, views = [], enterprise, logo } = userDetails
    const { profile } = session
    const { first_name = '', last_name = '' } = profile
    
    let { gamma, lambda } = chosen || { }
    if(enterprise) {
        const hasRecommended = recommended?.gamma && recommended.lambda
        gamma = hasRecommended ? recommended?.gamma : preferred.gamma
        lambda = hasRecommended ? recommended?.lambda : preferred.lambda
    }
    
    const { address: firmAddress, ips_disclosures } = firm || { }
    const { name: advisorName } = advisor || { }
    const ips = { absolute_risk, relative_risk, liquidity, tax_concerns, legal_and_regulatory_factors, unique_circumstances, time_horizon }
    const riskPreferences = { gamma, lambda }
    const hasSLR = views.includes('standard_living_risk_simple') || views.includes('standard_living_risk')
    const { discount_rate, inflation_rate, investment_portfolio_today, pv_savings, pv_liabilities, assets_discreationary_wealth, standardLivingRisk: slrValue } = standardLivingRisk

    const slr = { discount_rate, inflation_rate, pv_savings: pv_savings + investment_portfolio_today, pv_liabilities, assets_discreationary_wealth, standardLivingRisk: slrValue }

    return { clientName: `${first_name} ${last_name}`, membership, advisorName, firmAddress, ips, riskPreferences, keyStats, holdings, enterprise, tableDetail,
             assetAllocationChart, slr, hasSLR, timeHorizon: time_horizon, loaded: first_name || last_name, ips_disclosures, riskAnalyzerEnterpriseChart, logo }
}

export default withAuth(connect(mapStateToProps, { getPrintDetails })(Print))