import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';

import { ReduxState } from '../../../reducers';
import { DueDiligenceChartDTO, Metric, Order, ScreenerResultItem } from '../../../actions/types/dueDiligence';
import { updateDueDiligenceChart, processDueDiligenceChart, screenerUpdate } from '../../../actions/dueDiligence';
import { WithWindowDimensionsProps, withWindowDimensions } from '../../common/withWindowDimensions';
import ScreenerResultTable from './ScreenerResultTable';
import { getTickerSymbolAndDescription } from '../../../common/utils';

interface ReduxStateProps extends WithWindowDimensionsProps{
    items: ScreenerResultItem[];
    selectedChartItem: DueDiligenceChartDTO;
    processingScreener: boolean;
    screenerPage: number;
    screenerRowsPerPage: number;
    screenerOrderBy: keyof ScreenerResultItem;
    screenerOrder: Order;
    screenerUpdate: typeof screenerUpdate;
}

interface ReduxActionProps {
    updateDueDiligenceChart: typeof updateDueDiligenceChart;
}

type ComponentProps = ReduxActionProps & ReduxStateProps

interface ComponentState {
}

class ScreenerResults extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {
    }

    onItemToggled = (item: ScreenerResultItem) => {
        const { selectedChartItem } = this.props;
        const { tickers, selected_tickers } = selectedChartItem;
        const updatedItems = this.props.items.map(i => i.id === item.id ? item: i);
        this.props.screenerUpdate({ screenerResults: updatedItems });

        const { symbol } = getTickerSymbolAndDescription(item as any);
        const cleanedTickers = tickers?.filter(ticker => ticker !== symbol);
        const cleanedSelectedTickers = selected_tickers?.filter(ticker => ticker !== symbol);
        const updatedTickers = item.checked ? [...(cleanedTickers ?? []), symbol] : cleanedTickers;
        const updatedSelectedTickers = item.checked && !selectedChartItem.metrics.includes(Metric.ROLLING_CORRELATION) ? [...selected_tickers, symbol] : cleanedSelectedTickers;
        
        this.props.updateDueDiligenceChart(selectedChartItem.id, { tickers: updatedTickers, selected_tickers: updatedSelectedTickers })
    }

    onSortChange = (orderBy: keyof ScreenerResultItem, order: Order) => {
        this.props.screenerUpdate({ screenerOrderBy: orderBy, screenerOrder: order });
    }

    onPageChange = (newPage: number) => {
        this.props.screenerUpdate({ screenerPage: newPage });
    }

    onRowsPerPageChange = (value: number) => {
        this.props.screenerUpdate({ screenerRowsPerPage: value, screenerPage: 0 });
    }

    render() {
        const { items, processingScreener, screenerOrder, screenerOrderBy, screenerPage, screenerRowsPerPage } = this.props;

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: '3rem', minHeight: '50rem', height: '100%', width: { xs: this.props.dimensions.width - 100, lg: this.props.dimensions.width - 525} , }}>
               <ScreenerResultTable 
                    loading={processingScreener}
                    items={items}
                    onItemToggled={this.onItemToggled}
                    order={screenerOrder}
                    orderBy={screenerOrderBy}
                    page={screenerPage}
                    rowsPerPage={screenerRowsPerPage}
                    onSortChange={this.onSortChange}
                    onPageChange={this.onPageChange}
                    onRowsPerPageChange={this.onRowsPerPageChange}
                />
            </Box>
        )
    }
}


const mapStateToProps = ({ dueDiligence }: ReduxState) => {
    const { screenerResults, selectedChartItem, processingScreener, screenerOrder, screenerOrderBy, screenerPage, screenerRowsPerPage } = dueDiligence;

    return { items: screenerResults, selectedChartItem, processingScreener, screenerOrder, screenerOrderBy, screenerPage, screenerRowsPerPage }
}

export default connect(mapStateToProps, { updateDueDiligenceChart, processDueDiligenceChart, screenerUpdate })(withWindowDimensions(ScreenerResults) as any)