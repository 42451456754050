import { PortfolioDesignerConfiguration } from "../../../actions/types/portfolioDesigner";


export const portfolioModels: PortfolioDesignerConfiguration[] = [{
    "id": -1,
    "type": "benchmark",
    "section": "model",
    "isSystem": true,
    "discountRate": 0.00,
    "name": "20/80",
    "rebalancingFrequency": "quarterly",
    "portfolio": [{
        "id": "351c58c0-f632-4f0c-8e0e-bfe09ce63bed",
        "weight": 20,
        "ticker": {
            "id": "B0YB1N-L",
            "type": "ETF_ETF",
            "symbol": "SPY-US",
            "symbol_display": "SPY",
            "name": "SPDR S&P 500 ETF Trust",
            "cusip": "78462F103",
            "isin": "US78462F1030"
        }
    }, {
        "id": "24fc49b1-3ffc-40ab-a9b9-6024bfed35d0",
        "weight": 80,
        "ticker": {
            "id": "TH8DPK-L",
            "type": "ETF_ETF",
            "symbol": "AGG-US",
            "symbol_display": "AGG",
            "name": "iShares Core U.S. Aggregate Bond ETF",
            "cusip": "464287226",
            "isin": "US4642872265"
        }
    },]
},{
    "id": -2,
    "type": "benchmark",
    "section": "model",
    "isSystem": true,
    "discountRate": 0.00,
    "name": "40/60",
    "rebalancingFrequency": "quarterly",
    "portfolio": [{
        "id": "351c58c0-f632-4f0c-8e0e-bfe09ce63bed",
        "weight": 40,
        "ticker": {
            "id": "B0YB1N-L",
            "type": "ETF_ETF",
            "symbol": "SPY-US",
            "symbol_display": "SPY",
            "name": "SPDR S&P 500 ETF Trust",
            "cusip": "78462F103",
            "isin": "US78462F1030"
        }
    }, {
        "id": "24fc49b1-3ffc-40ab-a9b9-6024bfed35d0",
        "weight": 60,
        "ticker": {
            "id": "TH8DPK-L",
            "type": "ETF_ETF",
            "symbol": "AGG-US",
            "symbol_display": "AGG",
            "name": "iShares Core U.S. Aggregate Bond ETF",
            "cusip": "464287226",
            "isin": "US4642872265"
        }
    },]
},
{
    "id": -3,
    "type": "benchmark",
    "section": "model",
    "isSystem": true,
    "discountRate": 0.00,
    "name": "60/40",
    "rebalancingFrequency": "quarterly",
    "portfolio": [{
        "id": "bb1fc318-b005-4149-9b63-3e2af8d35c92",
        "weight": 60,
        "ticker": {
            "id": "B0YB1N-L",
            "type": "ETF_ETF",
            "symbol": "SPY-US",
            "symbol_display": "SPY",
            "name": "SPDR S&P 500 ETF Trust",
            "cusip": "78462F103",
            "isin": "US78462F1030"
        }
    }, {
        "id": "48e5775f-804a-4091-9fd9-6d642f5e25ff",
        "weight": 40,
        "ticker": {
            "id": "TH8DPK-L",
            "type": "ETF_ETF",
            "symbol": "AGG-US",
            "symbol_display": "AGG",
            "name": "iShares Core U.S. Aggregate Bond ETF",
            "cusip": "464287226",
            "isin": "US4642872265"
        }
    },]
}, {
    "id": -4,
    "type": "benchmark",
    "section": "model",
    "isSystem": true,
    "discountRate": 0.00,
    "name": "80/20",
    "rebalancingFrequency": "quarterly",
    "portfolio": [ {
        "id": "e682c2cf-9b55-4750-8132-621f866a4a76",
        "weight": 80,
        "ticker": {
            "id": "B0YB1N-L",
            "type": "ETF_ETF",
            "symbol": "SPY-US",
            "symbol_display": "SPY",
            "name": "SPDR S&P 500 ETF Trust",
            "cusip": "78462F103",
            "isin": "US78462F1030"
        }
    },{
        "id": "508e8891-36c1-44a7-bc0e-f4322cdaf860",
        "weight": 20,
        "ticker": {
            "id": "TH8DPK-L",
            "type": "ETF_ETF",
            "symbol": "AGG-US",
            "symbol_display": "AGG",
            "name": "iShares Core U.S. Aggregate Bond ETF",
            "cusip": "464287226",
            "isin": "US4642872265"
        }
    },]
}]

export default portfolioModels;