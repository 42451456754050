import { Box } from '@mui/material'
import React from 'react'
import { Asset, AssetUpdate } from '../../actions/types/customAssets'
import Button from '../common/Button'
import CommonTable from '../common/CommonTable'
import ConfirmModal from '../common/ConfirmModal'
import { FileUpload } from '../common/FileUpload'
import Panel from '../common/Panel'
import { BaseProps } from '../types'
import { connect } from 'react-redux'
import { addError } from '../../actions/notifications'
import { InputProps, StyledInput } from '../common/StyledInput'
import { deleteAsset, updateAsset } from '../../actions/customAssets'
import ConfirmDelete from '../common/ConfirmDelete'
import Text from '../common/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

interface AssetPanelProps extends BaseProps {
    title: string;
    assets: Asset[];
    onAssetUpdated: (asset: Asset) => void;
    onAssetsUpdated: (assets: Asset[]) => void;
    addError: typeof addError;
}

interface AssetPanelState {
    showUploadAssets: boolean;
    isFileSelected: boolean;
    showConfirmDeleteAsset?: Asset;
}
const AssetInput = (props: InputProps) => (<StyledInput {...props} sx={{ width: 'unset !important', backgroundColor: 'red', ...(props.sx ?? {})}} inputSx={{ width: '100%' }} />)

class AssetsPanel extends React.Component<AssetPanelProps, AssetPanelState> {
    fileUploadRef?: FileUpload | null;

    constructor(props: AssetPanelProps) {
        super(props);
        this.state = {
            showUploadAssets: false,
            isFileSelected: false,
        }
    }

    onShowUploadAssets = () => {
        this.setState({ showUploadAssets: true });
    }
    
    onCloseModals = () => {
        this.setState({ showUploadAssets: false });
    }

    onUploadAssets = () => {
        if(this.fileUploadRef) {
            this.fileUploadRef.performUpload();
        }
    }

    onUploadComplete = ({ response, error }: any) => {
        const { success, message, assets } = response || { };
        this.onCloseModals();
        if (!success || error) {
            this.props.addError('Upload Error', message ?? error);
            return;
        }
        this.props.onAssetsUpdated(assets);
    }

    onFileSelected = (file: File) => {
        this.setState({ isFileSelected: !!file });
    }

    onAssetUpdated = async (asset: Asset, update: AssetUpdate) => {
        this.props.onAssetUpdated({ ...asset, ...update });

        try {
            const {success, asset: updatedAsset} = await updateAsset(asset, update);
            if (!success || !updatedAsset) {
                this.props.onAssetUpdated(asset);
                this.props.addError('Error', `Failed to update asset ${asset.name}, please try again.`);

                return;
            }
            this.props.onAssetUpdated(updatedAsset);
        } catch (e) {
            this.props.onAssetUpdated(asset);
            this.props.addError('Error', `Failed to update asset ${asset.name}, please try again.`);
        }
    }

    onCloseConfirmDelete = () => {
        this.setState({ showConfirmDeleteAsset: undefined });
    }

    onConfirmDelete = async () => {
        const { showConfirmDeleteAsset } = this.state;
        if (!showConfirmDeleteAsset) {
            return;
        }

        this.setState({ showConfirmDeleteAsset: undefined });
        const { assets } = this.props;
        try {
            const updatedAssets = assets.filter(a => a.id != showConfirmDeleteAsset.id)
            this.props.onAssetsUpdated(updatedAssets)

            await deleteAsset(showConfirmDeleteAsset);

        } catch(e) {
            this.props.addError('Error', `Failed to delete asset ${showConfirmDeleteAsset?.name}, please try again.`);
            this.props.onAssetsUpdated(assets)
        }
    }

    onShowConfirmDelete = (asset: Asset) => {
        this.setState({ showConfirmDeleteAsset: asset });
    }

    renderRow = (asset: Asset): any => {
        const {id, symbol_display, name, min_date, max_date, description: description, asset_class: asset_class, expense_ratio: expense_ratio, launch_date: launch_date } = asset;

        return { 
                key: `${id}_${symbol_display}`,
                title: symbol_display,
                values: [
                    `${min_date} - ${max_date}`,
                    <Box sx={{ display: 'flex', width: '4rem', alignItems: 'center', justifyContent: 'center', '& svg': { cursor: 'pointer' } }}>
                        <Link to={`/account/assets/${id}`}><Box component={FontAwesomeIcon} sx={{ mr: '1rem' }} color={'#297ea1'} icon={faEdit} /></Link>
                        <Box component={FontAwesomeIcon} color={'#297ea1'} icon={faTrash} onClick={() => this.onShowConfirmDelete(asset)} />
                    </Box>
                ]
            }
    }

    render() {
        const { title, assets, sx = {} } = this.props;
        const { showUploadAssets, isFileSelected, showConfirmDeleteAsset } = this.state;
        return (
            <Panel
                title={title}
                sx={{
                    width: '100%',
                    margin: {
                        xs: undefined,
                        md: '2rem',
                    },
                    marginBottom: '2rem',
                    ...sx,
                }}
                contentsSx={{
                    padding: '2rem',
                }}>
                <ConfirmModal
                    title='Upload'
                    visible={showUploadAssets}
                    onCancel={this.onCloseModals}
                    onConfirm={this.onUploadAssets}
                    confirmDisabled={!isFileSelected}
                >
                    <FileUpload
                        sx={{
                            width: '100%',
                            height: '25rem',
                            paddingTop: '5rem',
                            paddingBottom: '5rem',
                        }}
                        ref={(view) => this.fileUploadRef = view}
                        fileAccept={'.csv'}
                        uploadEndpoint={'/api/account/assets'}
                        parameterName={'assets'}
                        showLoader={true}
                        showProgress={false}
                        onUploadComplete={this.onUploadComplete}
                        onFileSelected={this.onFileSelected}
                    />
                </ConfirmModal>
                <ConfirmDelete
                    title={'Delete Asset'}
                    onCancel={this.onCloseConfirmDelete}
                    onDelete={this.onConfirmDelete}
                    visible={showConfirmDeleteAsset !== undefined}
                >
                    <Text>Are you sure you want to delete '{showConfirmDeleteAsset?.symbol_display}'? This cannot be undone!</Text>
                </ConfirmDelete>
                <CommonTable
                    headerColumns={[{
                        key: 'Ticker',
                        children: 'Ticker',
                    },
                    {
                        key: 'Date Range',
                        children: 'Date Range',
                    },
                    {
                        key: 'Actions',
                        children: '',
                    }]}
                    rows={assets.map((asset: Asset) => {
                        return this.renderRow(asset);
                    })}
                    noDataMessage={`Upload some assets`}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '1rem', paddingRight: '1rem', marginTop: '2rem' }}>
                    <Button 
                        sx={{ width: '15rem', height: '4.5rem', minHeight: 'unset' }}
                        title={'Upload Assets'}
                        onClick={this.onShowUploadAssets}
                    />
                </Box>
            </Panel>
        );
    }
}


const AssetsPanelRedux = connect(null, { addError })(AssetsPanel as any)
export { AssetsPanelRedux as AssetsPanel };