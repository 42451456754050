interface Quote {
    quote: string
    owner: string
}

const quotes: Quote[] = [
    {
        quote: 'Modern Asset Allocation for Wealth Management succeeds in its ambitious goal of making asset allocation more intuitive and practical.  By accounting for behavioral biases, psychometric testing, and financial goals, to name a few, David Berns enables advisors to implement asset allocation systematically with a sound scientific underpinning.  This is a must read for any advisor who wants to truly understand why we invest the way we do and how best to work with clients.',
        owner: 'Michael Pompian, CFA, CAIA, CFP, founder and CIO, Sunpointe',
    },
    {
        quote: 'Historically, Modern Portfolio Theory (MPT) has been an insightful - but utterly useless - tool of modern finances.  David Berns flips this situation around and outlines exactly how investors can make MPT great again.  I learned so much from this book. Read it.',
        owner: 'Wesley R. Gray, PhD, CEO, Alpha Architect; co-author of Quantitative Value and Quantitative Momentum',
    },
    {
        quote: 'David Berns has blazed a trail through the wilderness that lies between the practical challenges of wealth maanagement and the leading edge of quantitative finance.  Along the way, readers will learn innovative strategies to confront diverse investor preferences, taxes, risk premia strategies, estimation error, non-normal distributions, and many other real-world challenges.  The piorneering guide is rigorous, clear, and relevant.  No wealth manager should leave home without it.',
        owner: 'Will Kinlaw, Senior Managing Director and Head, State Street Associates; co-author of A Practitioner’s Guide to Asset Allocation',
    },
]

export default quotes