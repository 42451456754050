import { Component } from "react"
import { connect } from 'react-redux'
import { ResultTable } from "../ResultTable"
import { ASSET_CLASS_ORDER, GeneralResultTableData, ResultsTableData } from "../../../actions/types/portfolioDesigner"
import { ReduxState } from "../../../reducers"
import { GenericResultTable } from "../GenericResultTable"
import { Box } from "@mui/material"
import Text from "../../../components/common/Text"

export interface PerformanceAttributionProps {
    totalAttributionTable: GeneralResultTableData
    assetAllocationTable: GeneralResultTableData
    securitySelectionTable: GeneralResultTableData
}
export interface PerformanceAttributionState { }
class PerformanceAttribution extends Component<PerformanceAttributionProps, PerformanceAttributionState> {
    mainView?: any
    resultsRef?: any

    constructor(props: PerformanceAttributionProps) {
        super(props)

        this.state = {
            runningAnalyzer: false,
            runningHistoricalAnalysis: false,
            reportItems: [],
        };
    }

    render() {
        const { totalAttributionTable, assetAllocationTable, securitySelectionTable } = this.props
        if (!totalAttributionTable || !assetAllocationTable) {
            return null;
        }
        return (
            <Box>
                <Text sx={{ fontStyle: 'italic' }}>Total Attribution = Asset Allocation + Security Selection</Text>
                <GenericResultTable
                    containerSx={{
                        maxWidth: '90rem',
                        marginBottom: {
                            xs: undefined,
                            md: '5rem',
                        }
                    }}
                    results={totalAttributionTable.data}
                    boldedKeys={totalAttributionTable.boldedKeys}
                />
                
                <Text sx={{ fontStyle: 'italic' }}>Asset Allocation = (Portfolio Weight - Benchmark Weight)*Asset Class Benchmark Return</Text>
                <GenericResultTable
                    containerSx={{
                        maxWidth: '90rem',
                        marginBottom: {
                            xs: undefined,
                            md: '5rem',
                        }
                    }}
                    results={assetAllocationTable.data}
                    excludeKeys={assetAllocationTable.excludeKeys}
                    boldedKeys={assetAllocationTable.boldedKeys}
                />

                <Text sx={{ fontStyle: 'italic' }}>Security Selection = (Portfolio Weight - Benchmark Weight) * (Fund Return - Asset Class Benchmark Return)</Text>
                <GenericResultTable
                    results={securitySelectionTable.data}
                    containerSx={{
                        maxWidth: '115rem',
                        marginBottom: {
                            xs: undefined,
                            md: '5rem',
                        }
                    }}
                    boldedKeys={securitySelectionTable.boldedKeys}
                    seperateAfterKeys={securitySelectionTable.seperateAfterKeys}
                />
            </Box>
        )
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState ) => {
    const { totalAttributionTable, assetAllocationTable, securitySelectionTable } = portfolioDesigner

    return { totalAttributionTable, assetAllocationTable, securitySelectionTable  }
}

export default connect(mapStateToProps)(PerformanceAttribution as  any)