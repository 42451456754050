import React, { FunctionComponent } from "react"
import Gauge from ".";
import resultSVG from './assets/ResultGauge.svg'

export interface BasicResultGaugeProps {
  type?: string
  value: number
  style?: any
  className?: string
  titleGlossaryTerm?: string
}


export interface ResultGagueProps {
  type?: string
  value: number
  title: string
  leftLabel: string
  rightLabel: string
  showNeedleValue?: boolean
  rightLabelOffset?: number
  svg?: any
  style?: any
  className?: string
  titleGlossaryTerm?: string
}

const data = [
  { label:"LOW", number:"1", color:"#dd4b39", labelOffset: -5, needleAngleOffset: 0, angleOffset: -10},
  { label:"MED", number:"1", color:"#ff851b", labelOffset: -15 },
  { label:"HIGH", number:"1", color:"#00a65a", labelOffset: -30, needleAngleOffset: -10, angleOffset: 10 },
]

const ResultGauge: FunctionComponent<ResultGagueProps> = (props) => {
  const { type, title, titleGlossaryTerm: propTitleGlossaryTerm, svg } = props
  const titleGlossaryTerm = props.type === 'ALM-Adjusted' ? `${type} ${title}` : propTitleGlossaryTerm

  return <Gauge rightLabelOffset={310} startingAngle={-90} endingAngle={90} {...props} titleGlossaryTerm={titleGlossaryTerm} showNeedleValue={false} data={data} gaugeSVG={svg || resultSVG}  />
};

export default ResultGauge