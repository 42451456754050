import React, { FunctionComponent } from 'react'
import { Box } from '@mui/material'

import { NavLink } from 'react-router-dom';
import { Nav } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faTicketAlt, faMap, faList, faCopy, faHandsHelping, faChartLine, faNewspaper } from '@fortawesome/free-solid-svg-icons'

import { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import { useAuth } from '../../common/hooks/useAuth';

export const AdminNavigation: FunctionComponent = () => {
    const { details } = useAuth()
    const { scopes } = details || { scopes: [''] }
    return (
        <Box sx={{
            display: 'flex',
            width: '30rem',
            backgroundColor: 'white',
            marginTop: '8rem',
            flexDirection: 'column',
            boxShadow: (theme) => theme.shadows[2],
            font: FONT_STYLES_BY_TYPE.form_header,
            '& a': {
                padding: '1rem',
                borderBottom: '2px solid #eee',
                '& svg': {
                    marginRight: '1rem',
                    marginLeft: '1rem',
                    height: '2rem',
                    width: '2rem !important',
                }
            },
        }}>
            {(scopes.includes('pd:admin:*') || scopes.includes('pd:admin:users')) && <Nav.Link to={'/admin/users'} as={NavLink}><FontAwesomeIcon icon={faUser} />User Management</Nav.Link>}
            {(scopes.includes('pd:admin:*') || scopes.includes('pd:admin:metrics')) && <Nav.Link to={'/admin/designer/metrics'} as={NavLink}><FontAwesomeIcon icon={faChartLine} />Designer Metrics</Nav.Link>}
            {scopes.includes('pd:admin:*') && <Nav.Link to={'/admin/activation_codes'} as={NavLink}><FontAwesomeIcon icon={faTicketAlt} />Activation Codes</Nav.Link>}
            {scopes.includes('pd:admin:*') && <Nav.Link to={'/admin/tools/portfoliomap'} as={NavLink}><FontAwesomeIcon icon={faMap} />PortfolioMap Tool</Nav.Link>}
            {scopes.includes('pd:admin:*') && <Nav.Link to={'/admin/tools/global_custom_cma'} as={NavLink}><FontAwesomeIcon icon={faList} />Custom CMA</Nav.Link>}
            {scopes.includes('pd:admin:*') && <Nav.Link to={'/admin/copy'} as={NavLink}><FontAwesomeIcon icon={faCopy} />Copy Editor</Nav.Link>}
            {scopes.includes('pd:admin:*') && <Nav.Link to={'/admin/guides'} as={NavLink}><FontAwesomeIcon icon={faHandsHelping} />Guides</Nav.Link>}
            {(scopes.includes('analyzer:admin') || scopes.includes('analyzer:admin:debug')) && <Nav.Link to={'/admin/analyzer/logs'} as={NavLink}><FontAwesomeIcon icon={faNewspaper} />Analyzer Logs</Nav.Link>}
            {(scopes.includes('analyzer:admin') || scopes.includes('analyzer:admin:debug')) && <Nav.Link to={'/admin/pd/logs'} as={NavLink}><FontAwesomeIcon icon={faNewspaper} />Pd Logs</Nav.Link>}
        </Box>
    )
}