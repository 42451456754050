import { Box } from '@mui/material'
import React, { FunctionComponent } from 'react'
import { BaseProps } from '../types'



export const FormRow: FunctionComponent<BaseProps> = ({ children, sx = { }}): any => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            "&>div": {
                margin: '1rem',
            },
            ...sx
        }}>
            {children}
        </Box>
    )
}