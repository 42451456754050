import React from 'react';
import { Box, ButtonBase, Checkbox, SxProps } from '@mui/material';
import Text from '../../common/Text';
import { BaseProps } from '../../types';

interface ReduxStateProps { }
interface ReduxActionProps { }
interface Props extends BaseProps{
    checked: boolean;
    title: string;
    titleSx?: SxProps;
    onCheckChanged?: (title: string, checked: boolean) => void;
}

type ComponentProps = Props & ReduxActionProps & ReduxStateProps

interface ComponentState { }

export class FundCategoryItem extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {}

    onCheckChanged = () => {
        const { title, checked, onCheckChanged } = this.props;
        onCheckChanged && onCheckChanged(title, !checked);
    }

    render() {
        const {title, checked, titleSx = {}, sx = {}} = this.props;

        return (
            <Box component={ButtonBase} sx={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem', textAlign: 'start', justifyContent: 'flex-start', width: '100%', ...sx  }} onClick={this.onCheckChanged}>
                <Checkbox checked={checked} onChange={this.onCheckChanged} />
                <Text sx={{ textAlign: 'start', ...titleSx }}>{title}</Text>
            </Box>
        )
    }
}