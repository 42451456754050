import axios from 'axios';
import Highcharts from 'highcharts';
import { camelCaseToWords, formatNumberAsPercentage, getPathsClientId } from '../common/utils';
import { v4 as uuid } from 'uuid'
import { TickerAllocation, Ticker, Weights, Portfolio, AnalyzeResultsDTO, AnalyzePortfolioParameters, AnalyzePortfolioRequest, ResultResponse, TickerIds, PortfolioDesignerConfiguration, TickerAllocationFlat, PortfolioDesignerConfigurationType, HistoricalAnalysisParameters, HistoricalAnalysisResultsDTO, ActionStatusDTO, PortfolioSnapshotParameters, PortfolioSnapshotRequest, PortfolioSnapshotResultsDTO, TYPE_TABLE_MAPPING, SinglePortfolioSnapshotResultsDTO, TickerProxy, CorrelationMatrix, ReportResourceItem, AnalysisData, PerformanceAttributionData, ASSET_CLASS_ORDER, ASSET_ALLOCATION_COLUMN_MAPPING, ResultsTableData, PortfolioAllocationBreakdown, KeyPlots, PortfolioOptimizationDTO, EffData, PriceEarningsBuckets, ReportOptions, SavedReport } from './types/portfolioDesigner';
import { ANALYZE_UPDATE, CONFIGS_UPDATED, MODELS_UPDATED, PORTFOLIO_UPDATED, REBALANCING_FREQUENCY_UPDATED } from './types/actions';
import { SelectOption } from '../common/types';
import { StandardThunkAction } from '../store/store';
import { GetStateFunction, DispatchFunction, ReduxState } from '../reducers';
import { addError, addSuccessNotification } from './notifications';
import moment from 'moment';

const SAVED_REPORTS_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/saved-reports`
const TICKERS_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/tickers`
const TICKERS_SEARCH_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/tickers/search`
const TICKER_PROXIES_URL = `${TICKERS_URL}/proxies`
const ANALYZE_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/analyze`
const PORTFOLIO_OPTIMIZATION_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/portfolio-optimization`
const PORTFOLIO_SNAPSHOT_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/snapshot/table`
const SINGLE_PORTFOLIO_SNAPSHOT_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/snapshot/single`
const HISTORICAL_ANALYSIS_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/historical/analysis`
const TRADING_DATE_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/trading-date`
const REPORT_GENERATOR_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/generate-report`
const EFF_CHART_KEY_MAPPING: { [key: string]: string } = {
    'custom_60/40': '60/40',
    'custom_Benchmark': 'Benchmark',
    'custom_Strategy': 'Strategy',
    'maxSharpe_Benchmark': 'Max Sharpe (BU)',
    'maxSharpe_Strategy': 'Max Sharpe (SU)',
}

const EFF_EF_CHART_KEY_MAPPING: { [key: string]: string } = {
    'EF_Benchmark': 'Benchmark Universe (BU) Efficient Frontier',
    'EF_Strategy': 'Strategy Universe (SU) Efficient Frontier',
    'EF_Strategy_Min': 'Strategy Universe (SU) Efficient Frontier (Minimum Investment Allocation)',
    'benchmark': 'Benchmark Universe (BU)',
    'strategy': 'Strategy Universe (SU)',
}

const EFF_SERIES_COLORS: { [key: string]: string } = {
    'benchmark': '#2caffe',
    'tickers': '#2caffe',
    'strategy': '#544fc5',
    'EF_Benchmark':'#2caffe',
    'EF_Strategy': '#544fc5',
    'EF_Strategy_Min': '#4f78c5',
}

const EFF_SELECTED_MARKER_COLOR: { [key: number]: string } = {
    0: '#FFD700',
    1: '#008000',
}

const getConfigurationsUrl = (path: string) => {
    const profileId = getPathsClientId()

    if (profileId) {
        return path.replace('/api/', `/api/clients/${profileId}/`)
    }

    return path;
}

export const getConfigurations = async(): Promise<PortfolioDesignerConfiguration[]> => {
    const { data } = await axios.get(getConfigurationsUrl(`/api/designer/configurations`))
    const { configurations } = data

    return configurations;
}

export const getModels = async(): Promise<PortfolioDesignerConfiguration[]> => {
    const { data } = await axios.get(`/api/designer/models`)
    const { models } = data

    return models.filter((item: PortfolioDesignerConfiguration) => item.type !== 'financial_plan_needs');
}

export const updateIdsOnConfiguration = (configuration: PortfolioDesignerConfiguration) => {
    let portfolio = configuration.portfolio.map((item) => {
        return { ...item, id: uuid() }
    })

    return { ...configuration, portfolio }
}

export const saveConfiguration = async(configuration: PortfolioDesignerConfiguration, updateIds?: boolean): Promise<PortfolioDesignerConfiguration | undefined> => {
    try {
        let config: PortfolioDesignerConfiguration = {
            ...configuration,
            portfolio: configuration.portfolio.map(({ id, weight, ticker }) => {
                return { id: updateIds || !id ? uuid() : id, weight, ticker: typeof ticker === 'string' ? ticker : ticker?.symbol }
            })
        }

        const { data } = await axios.post(getConfigurationsUrl(`/api/designer/configurations`), config)

        return data.configuration;
    } catch(e) {
        console.log('error saving configuration', e)
    }
    return undefined;
}

export const updateConfiguration = async(configuration: PortfolioDesignerConfiguration, updateIds?: boolean): Promise<PortfolioDesignerConfiguration | undefined> => {
    try {
        let config = configuration
        if (updateIds) {
           config = updateIdsOnConfiguration(configuration) 
        }
        const { data } = await axios.put(getConfigurationsUrl(`/api/designer/configurations/${configuration.id}`), config)

        return data.configuration;
    } catch(e) {

    }
    return undefined;
}

export const deleteConfiguration = async(configuration: PortfolioDesignerConfiguration): Promise<boolean> => {
    try {
        await axios.delete(getConfigurationsUrl(`/api/designer/configurations/${configuration.id}`))
        return true;
    } catch (e) {
        return false;
    }
}

export const getTickers = async(ticker: string = ''): Promise<Ticker[]> => {
    try {
        const { data } = await axios.get(`${TICKERS_URL}?symbol=${ticker.trim().toUpperCase()}`)
        const tickers: Ticker[] = data?.tickers
    
        return tickers
    }catch(e) {

    }

    return [];
}

export const searchTicker = async(ticker: string = ''): Promise<Ticker[]> => {
    try {
        const { data } = await axios.get(`${TICKERS_SEARCH_URL}?symbol=${ticker.trim().toUpperCase()}`)
        const tickers: Ticker[] = data?.tickers
    
        return tickers
    }catch(e) {

    }

    return [];
}
export const getNewTickerListItem = (): TickerAllocation => {
    return { id: uuid() };
}

export const getStartingTickerList = (): TickerAllocation[] => {
    return [getNewTickerListItem()];
}

export const getPortfolioFromTickerAllocations = (allocations: TickerAllocation[], rebalancingFrequency?: string) => {
    const allocationsWithWeights = allocations.filter((item) => item.ticker)
    const weights: Weights = allocationsWithWeights.reduce((accum, portfolio) => {
        const ticker = portfolio?.ticker
        const display = (ticker?.symbol?.endsWith('-US') ? ticker?.symbol_display : ticker?.symbol) || ''

        return { ...accum, [display]: (portfolio.weight || 0) / 100 }
    }, { })
    const ids: TickerIds = allocationsWithWeights.reduce((accum, portfolio) => {
        const ticker = portfolio?.ticker
        const display = (ticker?.symbol?.endsWith('-US') ? ticker?.symbol_display : ticker?.symbol) || ''

        return { ...accum, [portfolio?.ticker?.id || '']: display }
    }, { })

    return {
        weights,
        ids,
        rebalancingFrequency,
    }
}

export const getTradingStartDate = async (backTestingStacurrent: TickerAllocation[], proposed: TickerAllocation[], backTestingStartDate: Date): Promise<Date> => {
    const current = backTestingStacurrent.filter((item) => item.ticker)
    const proposedTickers = proposed.filter((item) => item.ticker)
    const tickers = [...current, ...proposedTickers]
    const ids = tickers.map((item) => item.ticker?.id).filter((item) => item)

    try {
        const formattedDate = moment(backTestingStartDate).format('YYYY-MM-DD')
        const { data } = await axios.post(TRADING_DATE_URL, { ids, date: formattedDate })
        const { success, date } = data
        if (!success || !date) {
            return backTestingStartDate
        }
        
        const tradingDate = moment(date).toDate();

        return tradingDate;
    } catch(e) {

    }

    return backTestingStartDate;
}

export const validateRun = (dispatch: DispatchFunction, current: TickerAllocation[], proposed: TickerAllocation[], backTestingStartDate?: Date, backTestingEndDate?: Date): boolean => {
    if(current.length === 0 || proposed.length === 0) {
        dispatch(addError('Input Error', 'Please enter at least 1 asset for both the current and proposed portfolio'))

        return false
    }

    if(!backTestingStartDate || !backTestingEndDate) {
        dispatch(addError('Input Error', 'Please enter both a start and end dates'))

        return false
    }
    
    if(backTestingStartDate > backTestingEndDate) {
        dispatch(addError('Input Error', 'Start date cannot be more than end date'))

        return false
    }


    return true
}

export const pollForRequestId = (statusId: string) => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<void> => {
        setTimeout(async() => {
            const { runningAnalyzer } = getState().portfolioDesigner
            if(!runningAnalyzer) {
                return
            }
            const result = await getAnalyzerStatus(statusId)
            if(result.success) {
                dispatch(updateParameter({ status: (result as ActionStatusDTO).status }))

                return;
            }

            pollForRequestId(statusId)
        }, 1000);
    }
}

const transformAnalysisData = ({ columns, index, data }: AnalysisData, orderByAssetClass = false) => {
    const payload = columns.reduce((accum, column, columnIndex) => {
        const columnDate = index.reduce((accum, date, dateIndex) => {
            return { ...accum, [date]: data[dateIndex][columnIndex] }
        }, {})
        let columnKey = camelCaseToWords(column);
            columnKey = ASSET_ALLOCATION_COLUMN_MAPPING[columnKey] ?? columnKey;
        if (!orderByAssetClass) {
            return { ...accum, [columnKey]: columnDate };
        }

        const columnKeys = Object.keys(columnDate)
        const keysNeedingSorting = columnKeys.filter((key) => ASSET_CLASS_ORDER.includes(key)).sort((a, b) => {
            const aIndex = ASSET_CLASS_ORDER.indexOf(a)
            const bIndex = ASSET_CLASS_ORDER.indexOf(b)
    
            return aIndex - bIndex
        })
        const keysNotNeedingSorting = columnKeys.filter((key) => !ASSET_CLASS_ORDER.includes(key))

        const sorted: { [key: string]: number } = [...keysNeedingSorting, ...keysNotNeedingSorting].reduce((accum, key) => {
            return { ...accum, [key]: (columnDate as any)[key] }
        }, {})

        return { ...accum, [columnKey]: sorted };
    }, {});

    return payload;
}

const transformPerformanceAttributionData = (performanceAttribution: PerformanceAttributionData) => {
    const { totalAttribution, assetAllocation, securitySelection } = performanceAttribution;
    const totalAttributionTable = {
        type: 'general_table',
        name: '',
        data: [{
            name: '',
            data: transformAnalysisData(totalAttribution, true),
        }],
        boldedKeys: ['Totals'],
    }
    const assetAllocationTable = {
        type: 'general_table',
        name: '',
        data: [{
            name: '',
            data: transformAnalysisData(assetAllocation, true),
        }],
        excludeKeys: ['Totals'],
        boldedKeys: ['Totals'],
    }
    const securitySelectionData = Object.keys(securitySelection).sort((a, b) => {
        const aIndex = ASSET_CLASS_ORDER.indexOf(a)
        const bIndex = ASSET_CLASS_ORDER.indexOf(b)

        return aIndex - bIndex
    }).map((key) => {
        const data = {
            type: 'table',
            name: '',
            data: transformAnalysisData(securitySelection[key]),
        }
        return data
    })
    const securitySelectionTable = {
        type: 'general_table',
        data: securitySelectionData,
        boldedKeys: ASSET_CLASS_ORDER,
        seperateAfterKeys: ASSET_CLASS_ORDER,
    }

    return { totalAttributionTable, assetAllocationTable, securitySelectionTable }
}

export const runAnalyzer = (requestId: string) => {
    return async(dispatch: any, getState: GetStateFunction): Promise<void> => {
        const { benchmarkPortfolio, portfolioPortfolio, benchmarkRebalancingFrequency, portfolioRebalancingFrequency,
                backTestingStart: backTestingStartDate, backTestingEnd: backTestingEndDate } = getState().portfolioDesigner
        dispatch(updateParameter({ runningAnalyzer: true, status: undefined }))
        dispatch(updateParameter({ dates: { }, results: undefined, customRangeStart: undefined, customRangeEnd: undefined, updatedAnalysis: undefined }))
        if(!validateRun(dispatch, benchmarkPortfolio, portfolioPortfolio, backTestingStartDate, backTestingEndDate)) {
            return
        }
        const hasInvalidTickers = [...benchmarkPortfolio, ...portfolioPortfolio].find((item) => item.ticker && (!item.ticker.id || item.ticker.id === 0))
        if (hasInvalidTickers) {
            dispatch(addError('Invalid Portfolio', 'Invalid tickers detected, please update items in red to a valid ticker'))

            return
        }
        const current = benchmarkPortfolio.filter(({ ticker}) => ticker)
        const proposed = portfolioPortfolio.filter(({ ticker}) => ticker)

        const backTestingStart = backTestingStartDate ? moment(backTestingStartDate).format('MM/DD/YYYY') : ''
        const backTestingEnd = backTestingEndDate ? moment(backTestingEndDate).format('MM/DD/YYYY') : ''
        
        dispatch(updateParameter({ runningAnalyzer: true }))
        try {
            dispatch(pollForRequestId(requestId))
            const results = await analyze({ requestId, current, proposed, backTestingStart, backTestingEnd, currentBalancingFrequency: benchmarkRebalancingFrequency?.value as string, proposedBalancingFrequency: portfolioRebalancingFrequency?.value as string }) as AnalyzeResultsDTO
            if(!results.success) {
                throw new Error(results.error);
            }
            
            const { 
                    dates: dateLogs,
                    charts,
                    tables,
                    missingPrices,
                    correlationMatrix,
                    performanceAttribution,
                } = results
                const assetAllocation = transformPerformanceAttributionData(performanceAttribution)
                const correlationMatrixChart = getCorrelationMatrixChart(current, proposed, correlationMatrix);
                const correlationMatrixReportItem: ReportResourceItem = {
                    type: 'highchart',
                    name: 'Correlation Matrix',
                    category: 'risk',
                    section: 6,
                    priority: 1,
                    options: correlationMatrixChart,
                }
            dispatch(updateParameter({ runningAnalyzer: false, status: undefined, reportItems: [...tables, ...charts, correlationMatrixReportItem], ...assetAllocation }))
            await dispatch(updateParameter({ dates: dateLogs, results, backtestMissingPrices: missingPrices }))
            dispatch(addSuccessNotification({ message: 'Succesfully finished running!' }))
            
        } catch(e: any) {
            console.log(e)
            dispatch(updateParameter({ runningAnalyzer: false, status: undefined }))

            dispatch(addError('Analyzer Error', `${e.toString()}`))
        }
    }
}

export const runPortfolioOptimization = (requestId: string, minimumInvestmentAllocation?: string, maximumInvestmentAllocation?: string) => {
    return async(dispatch: any, getState: GetStateFunction): Promise<void> => {
        const { benchmarkPortfolio, portfolioPortfolio, benchmarkRebalancingFrequency, portfolioRebalancingFrequency,
                backTestingStart: backTestingStartDate, backTestingEnd: backTestingEndDate, clickedMarkers } = getState().portfolioDesigner
        dispatch(updateParameter({ runningEfficencyFrontier: true }))
        dispatch(updateParameter({ portfolioOptimizationOutputs: undefined, portfolioOptimizationTable: undefined, effChart: undefined, effTable: undefined, originalEffTable: undefined}))
        if(!validateRun(dispatch, benchmarkPortfolio, portfolioPortfolio, backTestingStartDate, backTestingEndDate)) {
            return
        }
        const hasInvalidTickers = [...benchmarkPortfolio, ...portfolioPortfolio].find((item) => item.ticker && (!item.ticker.id || item.ticker.id === 0))
        if (hasInvalidTickers) {
            return
        }
        const current = benchmarkPortfolio.filter(({ ticker}) => ticker)
        const proposed = portfolioPortfolio.filter(({ ticker}) => ticker)

        const backTestingStart = backTestingStartDate ? moment(backTestingStartDate).format('MM/DD/YYYY') : ''
        const backTestingEnd = backTestingEndDate ? moment(backTestingEndDate).format('MM/DD/YYYY') : ''
        
        dispatch(updateParameter({ runningEfficencyFrontier: true }))
        try {
            dispatch(pollForRequestId(requestId))
            const results = await portfolioOptimization({ requestId, current, proposed, backTestingStart, backTestingEnd, currentBalancingFrequency: benchmarkRebalancingFrequency?.value as string, proposedBalancingFrequency: portfolioRebalancingFrequency?.value as string }, minimumInvestmentAllocation, maximumInvestmentAllocation) as PortfolioOptimizationDTO
            if(!results.success) {
                throw new Error(results.error);
            }
            
            const { 
                    portfolioOptimizationSeries,
                    portfolioOptimizationTable,
                    plotMarkerData,
                } = results
            const { EF_Benchmark, EF_Strategy, ...restSeries } = portfolioOptimizationSeries
            const effChart = getEffChart(portfolioOptimizationSeries, plotMarkerData, [])
            const effTable = getEffTableData(portfolioOptimizationTable)
            dispatch(updateParameter({ effChart, effTable, originalEffTable: effTable, clickedMarkers: [], portfolioOptimizationSeries, portfolioOptimizationTable, plotMarkerData, runningEfficencyFrontier: false }))
        } catch(e: any) {
            console.log(e)
            dispatch(updateParameter({ runningEfficencyFrontier: false }))

            dispatch(addError('Analyzer Error', `${e.toString()}`))
        }
    }
}


const getEffTableData = (data: { [key: string]: { [key: string]: number }}, clickedMarkers: any[] = []) => {
    const rawColumns = Object.keys(data)
    const rowKeys = Object.keys(data[rawColumns[0]])
    const statRowKeys = rowKeys.filter((key) => key.toLowerCase().includes('Ann.'))
    const weightRowKeys = rowKeys.filter((key) => !key.toLowerCase().includes('Ann.'))
    const rowKeyMap: { [key: string]: string[] } = { stats: statRowKeys, weights: weightRowKeys }

    const rawRows = Object.keys(rowKeyMap).map((key) => {
        const keys = rowKeyMap[key]

        return keys.map((rowKey: string, index: number) => {
            const rowValues = rawColumns.map((key) => {
                const rawValue = data[key][rowKey]
                if (rawValue === null || rawValue === undefined) {
                    return ''
                }
                return formatNumberAsPercentage(data[key][rowKey])
            })
            return { key: `${rowKey}`, title: rowKey, values: [...(rowValues)] }
        });
    })

    let rows = rawRows.flat()
    let columns = rawColumns.map((key: string) => EFF_CHART_KEY_MAPPING[key] ?? key)
    if (clickedMarkers.length) {
        const mappedMarkers = clickedMarkers.reduce((accum, item) => {
            return { ...accum, ...item }
        }, {});
        const markerColumnKeys = Object.keys(mappedMarkers)

        columns = [...columns, ...markerColumnKeys.map((_, index) => `Custom Portfolio ${index + 1}`)];
        rows = rows.map((row) => {
            const { key, values, ...restRow } = row;
            const appendedValues = markerColumnKeys.map((columnKey) => {
                const value = mappedMarkers[columnKey][key];
                if (value === null || value === undefined) {
                    return '';
                }

                return formatNumberAsPercentage(value);
            });

            return { key, values: [...values, ...appendedValues], ...restRow }
        });

    }

    return { columns, rows };
}

export const onEffChartMarkerClicked = (markerData: any) => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<void> => {
        if (!markerData) {
            return;
        }
        const { portfolioOptimizationTable, clickedMarkers, plotMarkerData, portfolioOptimizationSeries } = getState().portfolioDesigner
        const markerKey = Object.keys(markerData)[0]
        const exists = clickedMarkers.find((item) => item[markerKey])
        let updatedClickedMarkers
        if (exists) {
            updatedClickedMarkers = clickedMarkers.filter((item) => !item[markerKey])
        } else {
            const lastClickedMarker = clickedMarkers.length ? clickedMarkers[clickedMarkers.length - 1] : undefined
            updatedClickedMarkers = [lastClickedMarker, markerData].filter((item) => item)
        }
        const effChart = getEffChart(portfolioOptimizationSeries, plotMarkerData, updatedClickedMarkers)
        const effTable = getEffTableData(portfolioOptimizationTable, updatedClickedMarkers)
        dispatch(updateParameter({ effChart, effTable, clickedMarkers: updatedClickedMarkers, runningEfficencyFrontier: false }))
    }
}

export const portfolioOptimization = async({ requestId, current, proposed, backTestingStart, backTestingEnd, currentBalancingFrequency, proposedBalancingFrequency }: AnalyzePortfolioParameters, minimumInvestmentAllocation?: string, maximumInvestmentAllocation?: string): Promise<PortfolioOptimizationDTO | ResultResponse> => {
    try {
        const benchmark: Portfolio = getPortfolioFromTickerAllocations(current, currentBalancingFrequency)
        const strategy: Portfolio = getPortfolioFromTickerAllocations(proposed, proposedBalancingFrequency)
    
        const payload: AnalyzePortfolioRequest = {
            requestId,
            benchmark,
            strategy,
            backTestingStart,
            backTestingEnd,
        }

        const { data } = await axios.post(PORTFOLIO_OPTIMIZATION_URL, {
            ...payload,
            minimumInvestmentAllocation: minimumInvestmentAllocation ? Number(minimumInvestmentAllocation) : undefined,
            maximumInvestmentAllocation: maximumInvestmentAllocation ? Number(maximumInvestmentAllocation) : undefined,
        })

        return data;
    } catch(e: any) {
        if (e?.response?.data?.message) {
            return { success: false, error: e?.response?.data?.message }
        } else {
            return { success: false, error: `An unexpected error occured, please try again` }
        }
    }
}

export const analyze = async({ requestId, current, proposed, backTestingStart, backTestingEnd, currentBalancingFrequency, proposedBalancingFrequency }: AnalyzePortfolioParameters): Promise<AnalyzeResultsDTO | ResultResponse> => {
    try {
        const benchmark: Portfolio = getPortfolioFromTickerAllocations(current, currentBalancingFrequency)
        const strategy: Portfolio = getPortfolioFromTickerAllocations(proposed, proposedBalancingFrequency)
    
        const payload: AnalyzePortfolioRequest = {
            requestId,
            benchmark,
            strategy,
            backTestingStart,
            backTestingEnd,
        }

        const { data } = await axios.post(ANALYZE_URL, payload)

        return data;
    } catch(e: any) {
        if (e?.response?.data?.message) {
            return { success: false, error: e?.response?.data?.message }
        } else {
            return { success: false, error: `An unexpected error occured, please try again` }
        }
    }
}

export const historicalAnalysis = async({ id, startDate, endDate }: HistoricalAnalysisParameters): Promise<HistoricalAnalysisResultsDTO | ResultResponse> => {
    try {
        const payload: HistoricalAnalysisParameters = {
            id, 
            startDate, 
            endDate,
        }
    
        const { data } = await axios.post(HISTORICAL_ANALYSIS_URL, payload)

        return data;
    } catch(e: any) {
        if (e?.response?.data?.message) {
            return { success: false, error: e?.response?.data?.message }
        } else {
            return { success: false, error: `An unexpected error occured, please try again` }
        }
    }
}

export const getModelsForType = async(type: PortfolioDesignerConfigurationType): Promise<PortfolioDesignerConfiguration[]> => {
    const { data } = await axios.get(`/api/designer/models?type=${type}`)
    const { models } = data
    const modelsWithoutBlanks = models.map((item: PortfolioDesignerConfiguration) => {
        const { portfolio } = item
        const portfolioWithoutBlanks = portfolio.filter((item: TickerAllocation | TickerAllocationFlat) => ((item.weight ?? 0) > 0 || (item.weight ?? 0) < 0) || ( typeof item.ticker === 'string' ? item.ticker : item.ticker?.symbol))

        return {
            ...item,
            portfolio: portfolioWithoutBlanks,
        };
    })

    return modelsWithoutBlanks;
}

export const saveModel = async(configuration: PortfolioDesignerConfiguration, updateIds?: boolean): Promise<PortfolioDesignerConfiguration | undefined> => {
    try {
        const config: PortfolioDesignerConfiguration = {
            ...configuration,
            portfolio: configuration.portfolio.map(({ id, weight, ticker }) => {
                return { id: updateIds || !id ? uuid() : id, weight, ticker: typeof ticker === 'string' ? ticker : ticker?.symbol }
            })
        }
        const { data } = await axios.post(`/api/designer/models`, config)

        return data.model;
    } catch(e) {

    }
    return undefined;
}
export const deleteModel = async(model: PortfolioDesignerConfiguration): Promise<boolean> => {
    try {
        await axios.delete(`/api/designer/models/${model.id}`)
        return true;
    } catch (e) {
        return false;
    }
}

export const expandTickerAllocation = async(tickers: TickerAllocationFlat[]): Promise<TickerAllocation[]> => {
    const populatedTickers = tickers.filter((item) => item.ticker)
    const tickerItems: any[] = await Promise.all(populatedTickers.map(item => getBestMatchedTicker(typeof item.ticker === 'string' ? item.ticker : item.ticker?.symbol)))
    return populatedTickers.map((allocation, index): TickerAllocation => {
        const weight = Number(allocation.weight)
        const symbol = typeof allocation.ticker === 'string' ? allocation.ticker : allocation.ticker?.symbol
        return {
            id: uuid(),
            weight: isNaN(weight) ? undefined : weight,
            ticker: tickerItems[index] ?? {
                id: '0',
                symbol: symbol,
                symbol_display: symbol,
                name: symbol,
                isin: '',
                cusip: '',
                type: '',
            }
        }
    })
}

export const getBestMatchedTicker = async(ticker: string) => {
    const tickers = await getTickers(ticker)
    if(tickers.length === 0) {
        return undefined
    }

    if(tickers.length === 1) {
        return tickers[0]
    }

    const tickerBySymbol = tickers.find((item) => item.symbol.endsWith('-US') ? item.symbol_display  === ticker : item.symbol === ticker)
    if (tickerBySymbol) {
        return tickerBySymbol
    }

    const tickerByCusip = tickers.find((item) => item.cusip === ticker)
    if (tickerByCusip) {
        return tickerByCusip
    }

    const tickerByIsin= tickers.find((item) => item.isin === ticker)
    if (tickerByIsin) {
        return tickerByIsin
    }

    const tickerByDescription = tickers.find((item) => item.name === ticker)
    if (tickerByDescription) {
        return tickerByDescription
    }
    return tickers[0]
}

export const updatePortfolioForType = (type: PortfolioDesignerConfigurationType, portfolio: TickerAllocation[], changedItem?: TickerAllocation, itemUpdateType?: 'weight' | 'ticker'): StandardThunkAction => {
    return async(dispatch: any): Promise<void> => {
        await dispatch({ type: PORTFOLIO_UPDATED, payload: { type, portfolio: [...portfolio] } })
        dispatch(runSinglePortfolioSnapshotUpdate(type, changedItem, itemUpdateType))
    }
}
export const updateRebalancingFrequencyForType = (type: PortfolioDesignerConfigurationType, rebalancingFrequency: SelectOption): StandardThunkAction => {
    return async(dispatch: any): Promise<void> => {
        dispatch({ type: REBALANCING_FREQUENCY_UPDATED, payload: { type, rebalancingFrequency } })
    }
}
export const updateConfigsForType = (type: PortfolioDesignerConfigurationType, configs: SelectOption[]): StandardThunkAction => {
    return async(dispatch: any): Promise<void> => {
        dispatch({ type: CONFIGS_UPDATED, payload: { type, configs } })
    }
}
export const updateModelsForType = (type: PortfolioDesignerConfigurationType, models: SelectOption[]): StandardThunkAction => {
    return async(dispatch: any): Promise<void> => {
        dispatch({ type: MODELS_UPDATED, payload: { type, models } })
    }
}

export const updateParameter = (payload: any) => {
    return { type: ANALYZE_UPDATE, payload}
}

export const createAnalyzerRequestId = (): string => {
    return uuid()
}

export const downloadReport = async(requestId: string, reportType: 'Excel' | 'HTML', options?: ReportOptions): Promise<{ url: string }> => {
    try {
        const items = options?.items?.map((item) => item.key) ?? []
        const { data } = await axios.post(REPORT_GENERATOR_URL, {
            requestId,
            reportType,
            options: {
                strategyName: options?.strategyName,
                items,
                data: options?.data,
            }
        })
        const { success, url } = data
        if (!success) {
            throw new Error()
        }

        return { url };
    } catch(e) {
        throw new Error('An unexpected error occured, please try again')
    }
}

export const getAnalyzerStatus = async(statusId: string): Promise<ActionStatusDTO | ResultResponse> => {
    try {
        const { data } = await axios.get(`${ANALYZE_URL}/${statusId}`)
        return data as ActionStatusDTO;
    } catch (e: any) {
        if (e?.response?.data?.message) {
            return { success: false, error: e?.response?.data?.message }
        } else {
            return { success: false, error: `An unexpected error occured, please try again` }
        }
    }
}



export const runPortfolioSnapshot = async({  current, proposed, }: PortfolioSnapshotParameters): Promise<PortfolioSnapshotResultsDTO | ResultResponse> => {
    try {
        const benchmark: Portfolio = getPortfolioFromTickerAllocations(current)
        const strategy: Portfolio = getPortfolioFromTickerAllocations(proposed)
    
        const payload: PortfolioSnapshotRequest = {
            benchmark,
            strategy,
        }
    
        const { data } = await axios.post(PORTFOLIO_SNAPSHOT_URL, payload)

        return data;
    } catch(e: any) {
        if (e?.response?.data?.message) {
            return { success: false, error: e?.response?.data?.message }
        } else {
            return { success: false, error: `An unexpected error occured, please try again` }
        }
    }
}

export const runSinglePortfolioSnapshot = async(portfolio: Portfolio): Promise<SinglePortfolioSnapshotResultsDTO | ResultResponse> => {
    try {
    
        const { data } = await axios.post(SINGLE_PORTFOLIO_SNAPSHOT_URL, portfolio)

        return data;
    } catch(e: any) {
        if (e?.response?.data?.message) {
            return { success: false, error: e?.response?.data?.message }
        } else {
            return { success: false, error: `An unexpected error occured, please try again` }
        }
    }
}

export const getResultTableData = (stateTable: ResultsTableData, updateTable: PortfolioAllocationBreakdown): ResultsTableData => {
    return {
        ...stateTable,
        data: {
            ...stateTable.data,
            [TYPE_TABLE_MAPPING['benchmark']]: {
                ...updateTable.benchmark,
            },
            [TYPE_TABLE_MAPPING['portfolio']]: {
                ...updateTable.strategy,
            }
        }
    };
}

export const runSinglePortfolioSnapshotUpdate = (type: PortfolioDesignerConfigurationType, snapshotTargetItem?: TickerAllocation, itemUpdateType?: 'weight' | 'ticker') => {
    return async(dispatch: any, getState: () => ReduxState): Promise<void> => {
        try {
            await dispatch(updateParameter({ [`${type}SnapshotLoading`]: true, [`${type}SnapshotTargetItem`]: snapshotTargetItem, [`${type}SnapshotTargetItemUpdateType`]: itemUpdateType }))
            const { portfolioDesigner } = getState()
            const { portfolioPortfolio, benchmarkPortfolio } = portfolioDesigner;

            const { benchmark, strategy, correlationMatrix, missingPrices, metrics, asset_class, fixed_income_maturity, fixed_income_credit_quality, fixed_income_sectors, equity_regions, equity_sectors, price_over_earnings_mapping, avg_credit_quality_mapping } = await runPortfolioSnapshot({ current: benchmarkPortfolio, proposed: portfolioPortfolio }) as PortfolioSnapshotResultsDTO
            const { snapshot: stateSnapshot, asset_class: stateAssetClass, fixed_income_maturity: stateFixedIncomeMaturity, fixed_income_credit_quality: stateFixedIncomeCreditQuality, fixed_income_sectors: stateFixedIncomeSectors, equity_regions: stateEquityRegion, equity_sectors: stateEquitySectors } = portfolioDesigner

            const correlationMatrixChart = getCorrelationMatrixChart(benchmarkPortfolio, portfolioPortfolio, correlationMatrix);
            const { ['Not Rated']: notRatedCreditQualityBenchmark, ['Not Available']: notAvailableCreditQualityBenchmark, ...creditQualityBenchmark } = fixed_income_credit_quality.benchmark
            const { ['Not Rated']: notRatedCreditQualityStrategy, ['Not Available']: notAvailableCreditQualityStrategy, ...creditQualityStrategy } = fixed_income_credit_quality.strategy

            const priceOverEarningsChart = getPriceOverEarningsChart(price_over_earnings_mapping);
            const payload = {
                snapshotCorrelationMatrixChart: correlationMatrixChart,
                metrics,
                snapshotMissingPrices: missingPrices,
                [`benchmarkSnapshotLoading`]: false,
                [`portfolioSnapshotLoading`]: false,
                [`${type}SnapshotTargetItem`]: undefined,
                [`${type}SnapshotTargetItemUpdateType`]: undefined,
                [`benchmarkSnapshots`]: benchmark.snapshots,
                [`portfolioSnapshots`]: strategy.snapshots,
                snapshot: {
                    ...stateSnapshot,
                    data: {
                        ...stateSnapshot.data,
                        [TYPE_TABLE_MAPPING['benchmark']]: {
                            ...benchmark.snapshots.complete.snapshot,
                            'Average P/E': Number(price_over_earnings_mapping.benchmark_average).toLocaleString('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                            }),
                            // 'Average Credit Quality': avg_credit_quality_mapping.benchmark_avg_credit_quality ?? '--',
                        },
                        [TYPE_TABLE_MAPPING['portfolio']]: {
                            ...strategy.snapshots.complete.snapshot,
                            'Average P/E': Number(price_over_earnings_mapping.strategy_average).toLocaleString('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                            }),
                            // 'Average Credit Quality': avg_credit_quality_mapping.strategy_avg_credit_quality ?? '--',
                        }
                    }
                },
                asset_class: {
                    ...stateAssetClass,
                    data: {
                        ...stateAssetClass.data,
                        [TYPE_TABLE_MAPPING['benchmark']]: {
                            ...Object.keys(asset_class.benchmark).sort((a, b) => {
                                const aIndex = ASSET_CLASS_ORDER.indexOf(a)
                                const bIndex = ASSET_CLASS_ORDER.indexOf(b)
                        
                                return aIndex - bIndex
                            }).reduce((accum, key) => {
                                return { ...accum, [key]: (asset_class.benchmark as any)[key] }
                            }, {})
                        },
                        [TYPE_TABLE_MAPPING['portfolio']]: {
                            ...Object.keys(asset_class.strategy).sort((a, b) => {
                                const aIndex = ASSET_CLASS_ORDER.indexOf(a)
                                const bIndex = ASSET_CLASS_ORDER.indexOf(b)
                        
                                return aIndex - bIndex
                            }).reduce((accum, key) => {
                                return { ...accum, [key]: (asset_class.strategy as any)[key] }
                            }, {})
                        }
                    }
                },
                fixed_income_credit_quality: {
                    ...stateFixedIncomeCreditQuality,
                    data: {
                        ...stateFixedIncomeCreditQuality.data,
                        [TYPE_TABLE_MAPPING['benchmark']]: {
                            ...creditQualityBenchmark,
                            'Not Rated': notRatedCreditQualityBenchmark ?? undefined,
                            'Not Available': notAvailableCreditQualityBenchmark ?? undefined,
                        },
                        [TYPE_TABLE_MAPPING['portfolio']]: {
                            ...creditQualityStrategy,
                            'Not Rated': notRatedCreditQualityStrategy ?? undefined,
                            'Not Available': notAvailableCreditQualityStrategy ?? undefined,
                        }
                    }
                },
                fixed_income_sectors: getResultTableData(stateFixedIncomeSectors, fixed_income_sectors),
                fixed_income_maturity: getResultTableData(stateFixedIncomeMaturity, fixed_income_maturity),
                equity_sectors: getResultTableData(portfolioDesigner.equity_sectors, equity_sectors),
                equity_regions: getResultTableData(portfolioDesigner.equity_regions, equity_regions),
                priceOverEarningsChart,
            }
            dispatch(updateParameter(payload))
        }catch(e) {
            dispatch(updateSnapshotPortfolioDefault(type))
        }
    }
}

const updateSnapshotPortfolioDefault = (type: PortfolioDesignerConfigurationType) => {
    return async(dispatch: any, getState: any): Promise<void> => {
        const { portfolioDesigner } = getState()
        const { snapshot } = portfolioDesigner

        dispatch(updateParameter({ snapshot: {...snapshot, data: {
            ...snapshot.data,
            [TYPE_TABLE_MAPPING[type]]: {
                "1Yr Equity Beta": "--",
                "1Yr Volatility": "--",
                "Distribution Yield": "--",
                "Effective Duration": "--",
                "Expense Ratio": "--",
            }
        }}}))
    }
}

export const loadModelOptions = () => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<void> => {

        const models = await getModels();
        const modelOptions: SelectOption[] = models?.length > 0 ? models.map((item) => {
            return {
                label: item.name,
                value: item.id,
                item 
            }
        }) : [];

        const systemBenchmarkModelUserOptions = modelOptions.filter((option) => option.item.type === 'benchmark' && option.item.isSystem)
        const systemPortfolioModelUserOptions = modelOptions.filter((option) => option.item.type === 'portfolio' && option.item.isSystem)
        const systemFinancialPlanModelUserOptions = modelOptions.filter((option) => option.item.type === 'financial_plan' && option.item.isSystem)
        const systemFinancialPlanNeedsModelUserOptions = modelOptions.filter((option) => option.item.type === 'financial_plan_needs' && option.item.isSystem)
        
        const benchmarkModelUserOptions = modelOptions.filter((option) => option.item.type === 'benchmark' && !option.item.isSystem)
        const portfolioModelUserOptions = modelOptions.filter((option) => option.item.type === 'portfolio' && !option.item.isSystem)
        

        const { scopes } = getState().session;
        const hasFinancialPlan = scopes?.includes('pd:view:standard_living_risk');

        const systemPortfolioModelOptions = hasFinancialPlan ? [...systemPortfolioModelUserOptions, ...systemFinancialPlanModelUserOptions] : systemPortfolioModelUserOptions

        dispatch(updateModelsForType('benchmark', [...benchmarkModelUserOptions, ...systemBenchmarkModelUserOptions]))
        dispatch(updateModelsForType('portfolio', [...portfolioModelUserOptions, ...systemPortfolioModelOptions]))
        dispatch(updateModelsForType('financial_plan_needs', [...portfolioModelUserOptions, ...systemPortfolioModelOptions, ...systemFinancialPlanNeedsModelUserOptions]))
    }
}

export const loadConfigurationOptions = () => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        
        const configs = await getConfigurations();
        const configOptions: SelectOption[] = configs?.length > 0 ? configs.map((item) => {
            return {
                label: item.name,
                value: item.id,
                item 
            }
        }) : [];


        const benchmarkOptions = configOptions.filter((option) => option.item.type === 'benchmark')
        const portfolioOptions = configOptions.filter((option) => option.item.type === 'portfolio')
        

        dispatch(updateConfigsForType('benchmark', benchmarkOptions))
        dispatch(updateConfigsForType('portfolio', portfolioOptions))

    }
}

export const getTickerProxy = async(ticker: Ticker): Promise<TickerProxy | undefined> => {
    const { data } = await axios.get(`${TICKER_PROXIES_URL}/${ticker.id}`)

    if (!data?.success) {
        throw new Error('Failed to update proxy')
    }

    return data.proxy
}

export const updateTickerProxy = async(ticker: Ticker, proxyTicker: Ticker, enabled: boolean) => {
    const { data } = await axios.post(TICKER_PROXIES_URL, {
        symbolId: ticker.id,
        proxySymbolId: proxyTicker.id,
        enabled,
    })

    if (!data?.success) {
        throw new Error('Failed to update proxy')
    }
}

export const removeTickerProxy = async(ticker: Ticker) => {
    const { data } = await axios.delete(`${TICKER_PROXIES_URL}/${ticker.id}`)

    if (!data?.success) {
        throw new Error('Failed to update proxy')
    }
}


function drawCustomLines(chart: any, items: number, crossIntersection: number) {
    const plotTop = chart.plotTop;
    const plotLeft = chart.plotLeft;
    const plotHeight = chart.plotHeight;
    const plotWidth = chart.plotWidth;

    const blockWidth = Number((plotWidth / items).toFixed(0));
    const blockHeight = Number((plotHeight / items).toFixed(0));
    
    const horizontalLineStart = plotLeft + (blockWidth * crossIntersection);
    const horizontalLineEnd = plotLeft + plotWidth;
    const verticalLineStart = plotTop + (blockHeight * crossIntersection);
    const verticalLineEnd = plotTop + plotHeight;

    chart.renderer.path(['M', horizontalLineStart, verticalLineStart, 'L', horizontalLineEnd, verticalLineStart])
        .attr({
            'stroke-width': 2,
            stroke: 'black',
            zIndex: 5
        })
        .add();
    
    chart.renderer.path(['M', horizontalLineStart, verticalLineStart, 'L', horizontalLineStart, verticalLineEnd])
        .attr({
            'stroke-width': 2,
            stroke: 'black',
            zIndex: 5
        })
        .add();
}

export const getEffChart = (data: any, plotMarkerData: any, clickedMarkers: any[] = []) => {
    const { EF_Benchmark, EF_Strategy, EF_Strategy_Min = {}, 'custom_60/40': custom6040, tickers, ...singleItems } = data;
    const mergedItems = { ...EF_Benchmark, ...EF_Strategy, ...EF_Strategy_Min, ...tickers, ...singleItems, custom6040 };
    const mergedPlots = Object.keys(mergedItems).filter(key => mergedItems[key]).map((key) => mergedItems[key].flat()).flat();
    const maxNumber = Math.max(...mergedPlots);
    const minNumber = Math.min(0, ...mergedPlots);
    const bestInterval = (maxNumber - minNumber) / 10;
    const bestIntervalRounded = Math.round(bestInterval * 100) / 100;

    const clickedMarkersKeys = clickedMarkers.map((item) => Object.keys(item)[0]);

    const efData = Object.keys({ EF_Strategy, EF_Benchmark, EF_Strategy_Min}).filter(key => data[key]).map((efSetKey, index) => {
        return Object.keys(data[efSetKey]).map((efItemKey: string) => {
            const item = data[efSetKey][efItemKey];
            const efSetLabel = EFF_EF_CHART_KEY_MAPPING[efSetKey] ?? efSetKey;
            const efPoints = item[0].map((value: number, index: number) => {
                const markerData = plotMarkerData[efSetKey] ? plotMarkerData[efSetKey][efItemKey] : []
                const formattedX = formatNumberAsPercentage(value);
                const formattedY = formatNumberAsPercentage(item[1][index]);
                const markerDataKey = `${efSetLabel} ${formattedX}, ${formattedY}`;
                const selectedMarker = clickedMarkersKeys.includes(markerDataKey);
                const selectedMarkerText = `${selectedMarker ? `<br> Custom Portfolio ${clickedMarkersKeys.indexOf(markerDataKey) + 1}` : ''}`
                
                let dataLabel = undefined;
                if (selectedMarker) {
                    dataLabel = `Custom Portfolio ${clickedMarkersKeys.indexOf(markerDataKey) + 1}`
                }
                return {
                    x: value,
                    y: item[1][index],
                    type: selectedMarker ? 'scatter' : undefined,
                    marker: selectedMarker ? { enabled: true, fillColor: EFF_SELECTED_MARKER_COLOR[clickedMarkersKeys.indexOf(markerDataKey)], lineWidth: 2, lineColor: 'black', radius: 6, symbol: 'circle' } : undefined,
                    plotParentKey: efSetKey,
                    plotKey: efItemKey,
                    plotIndex: index,
                    markerData: { [`${markerDataKey}`]: markerData[index] },
                    selectedMarker,
                    selectedMarkerText,
                    dataLabelText: dataLabel,
                    dataLabels: selectedMarker ? {
                        enabled: true,
                        format: `{point.dataLabelText}`,
                        style: {
                          fontSize: '16px',
                          color: EFF_SELECTED_MARKER_COLOR[clickedMarkersKeys.indexOf(markerDataKey)] ,
                        }
                    } : undefined
                };
            });
            return {
                name: efSetLabel,
                data: efPoints,
                linkedTo: efItemKey === 'ef' ? undefined : ':previous',
                dashStyle: efItemKey === 'beforeEf' ? 'Dash' : 'Solid',
                type: efItemKey === 'simulations' ? 'scatter' : 'spline',
                color: EFF_SERIES_COLORS[efSetKey] !== undefined ? EFF_SERIES_COLORS[efSetKey] : 'black', 
                marker: efItemKey === 'simulations' ? {
                    symbol: 'circle',
                    radius: 2,
                    fillOpacity: 0.25,
                } : {},
            }
        })
    });

    const benchmarkItems = Object.keys(singleItems).filter(key => key.includes('Benchmark'));
    const strategyItems = Object.keys(singleItems).filter(key => !key.includes('Benchmark'));
    const plotItems: { [key: string]: string[]} = { custom: ['custom_60/40'], tickers: Object.keys(tickers), benchmark: benchmarkItems, strategy: strategyItems };
    const plotData = { ...data, ...tickers };

    const plotSeries = Object.keys(plotItems).map((plotKey, index) => {
        const item = plotItems[plotKey];
        return {
            name: EFF_EF_CHART_KEY_MAPPING[plotKey] ?? plotKey,
            data: item.map((key, index) => {
                const label = EFF_CHART_KEY_MAPPING[key] ?? key;
                const markerData = plotKey === 'tickers' ? plotMarkerData[plotKey][index] : plotMarkerData[key][0];

                const formattedX = formatNumberAsPercentage(plotData[key][0]);
                const formattedY = formatNumberAsPercentage(plotData[key][1]);
                const markerDataKey = `${label} ${formattedX}, ${formattedY}`;
                const selectedMarker = clickedMarkersKeys.includes(markerDataKey);
                const selectedMarkerText = `${selectedMarker ? `<br> Custom Portfolio ${clickedMarkersKeys.indexOf(markerDataKey) + 1}` : ''}`
                const selectedMarkerColor = selectedMarker ? EFF_SELECTED_MARKER_COLOR[clickedMarkersKeys.indexOf(markerDataKey)] : 'black';

                let dataLabel = `${label} ${formattedX}, ${formattedY}`;
                if (selectedMarker) {
                    dataLabel = `${dataLabel}<br />(Custom Portfolio ${clickedMarkersKeys.indexOf(markerDataKey) + 1})`
                }
                return {
                    x: plotData[key][0],
                    y: plotData[key][1],
                    marker: selectedMarker ? { fillColor: EFF_SELECTED_MARKER_COLOR[clickedMarkersKeys.indexOf(markerDataKey)], lineWidth: 2, lineColor: 'black', radius: 6 } : undefined,
                    plotParentKey: plotKey,
                    plotKey: key,
                    plotIndex: index,
                    selectedMarker,
                    selectedMarkerText,
                    markerData: { [`${markerDataKey}`]: markerData },
                    name: label,
                    dataLabelText: dataLabel,
                    dataLabels: {
                      enabled: true, // Enable data labels for each point
                      format: `{point.dataLabelText}`,
                      style: {
                        fontSize: plotKey === 'tickers' ? '14px' : '16px',
                        color: selectedMarkerColor,
                      }
                    },
                }
            }),
            type: 'scatter',
            color: EFF_SERIES_COLORS[plotKey]  !== undefined ? EFF_SERIES_COLORS[plotKey] : 'black', 
            marker: {
              symbol: 'triangle',
              radius: plotKey === 'tickers' ? 4 : 6,
            },
            showInLegend: !['custom', 'tickers'].includes(plotKey),
        }
    }).filter((item) => item);
    const series = [
        ...plotSeries,
        ...efData[0],
        ...efData[1],
        ...(efData.length > 2 ? efData[2] : []),
    ]
    return {
        chart: {
            type: 'spline',
            zoomType: 'x', // enable zoom on the X axis
        },
        title: {
            text: '',
        },
        xAxis: {
            title: {
                text: 'Annualized Volatility %',
                style: {
                    fontSize: '16px',
                },
            },
            labels: {
            formatter: function (this: any): string {
                return formatNumberAsPercentage(this.value);
            },
              rotation: 45,  // Rotate labels by 45 degrees
                style: {
                    fontSize: '16px',
                },
            },
            min: minNumber,
            max: maxNumber,
            tickInterval: bestIntervalRounded,
        },
        yAxis: {
            title: {
                text: 'Annualized Return %',
                style: {
                    fontSize: '16px',
                },
            },
            labels: {
                formatter: function (this: any): string {
                    return formatNumberAsPercentage(this.value);
                },
                style: {
                    fontSize: '16px',
                },
            },
            min: minNumber,
            max: maxNumber,
            tickInterval: bestIntervalRounded,
        },
        series,
        legend: {
            enabled: true,
            verticalAlign: 'top',
            itemStyle: {
                fontSize: '16px',
            }
        },
        tooltip: {
          style: {
            fontSize: '14px',
          },
          headerFormat: '<b>{point.name}</b><br>',
          formatter: function (this: any): string {
              const title = this.point?.name ?  `<b>${this.point?.name}</b><br>` : ''

              return `${title}${formatNumberAsPercentage(this.x)},${formatNumberAsPercentage(this.y)}${this.point?.selectedMarkerText}`;
          }
        },
    }
}
export const getCorrelationMatrixChartFromPortfolios = (benchmark: Portfolio, strategy: Portfolio, correlationMatrix: CorrelationMatrix) => {

    const strategyCategories = Object.keys(strategy.weights);

    const benchmarkCategories = Object.keys(benchmark.weights).filter((item) => !strategyCategories.includes(item));
    const categories = ['SPY', 'AGG', ...benchmarkCategories, ...strategyCategories]
    
    const data = categories.map((yCategory, yIndex) => {
        const items = categories.map((xCategory, xIndex) => {
            const value = xIndex > yIndex ? '' : correlationMatrix[xCategory][yCategory];
            return value === ''
              ? { x: xIndex, y: yIndex, value: null, color: 'white', borderColor: 'white' } // Set color to white for blank values
              : [xIndex, yIndex, value];
        });
        return items;
    }).flat();


    return {

        chart: {
            type: 'heatmap',
            marginTop: 45,
            marginBottom: 80,
            plotBorderWidth: 1,
        },


        title: {
            text: '',
        },

        xAxis: {
            categories,
            opposite: true,
            labels: {
                style: {
                    fontSize: '12px'
                }
            },
        },

        yAxis: {
            categories,
            reversed: true,
            title: null,
            labels: {
                style: {
                    fontSize: '12px'
                }
            },
        },

        colorAxis: {
            min: -1,
            max: 1,
            minColor: '#00FF00',  // Green
            maxColor: '#FF0000'   // Red
        },
        legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 280
        },

        tooltip: {
            format: '<b>{series.xAxis.categories.(point.y)}</b> / <b>{series.yAxis.categories.(point.x)}</b><br>' +
                '<b>{point.value}</b> <br>' +
                ''
        },
        series: [{
            name: 'Correlation Matrix',
            borderWidth: 1,
            data: data,
            dataLabels: {
                enabled: true,
                color: '#000000',
                style: {
                    fontSize: '11px'
                }
            }
        }],
    };

}

export const getCorrelationMatrixChart = (benchmarkAllocations: TickerAllocation[], portfolioAllocations: TickerAllocation[], correlationMatrix: CorrelationMatrix) => {
    const benchmark: Portfolio = getPortfolioFromTickerAllocations(benchmarkAllocations)
    const strategy: Portfolio = getPortfolioFromTickerAllocations(portfolioAllocations)

    return getCorrelationMatrixChartFromPortfolios(benchmark, strategy, correlationMatrix);
}

const PRICE_EARNINGS_KEY_MAP: { [key: string]: string } = {
    benchmark_buckets: 'Benchmark',
    strategy_buckets: 'Portfolio',
}

const BUCKET_X_TO_LABEL: { [key: string]: string } = {
    '5': '0 - 5',
    '10': '6 - 10',
    '15': '11 - 15',
    '20': '16 - 20',
    '25': '21 - 25',
    '30': '26+',
}
export const getPriceOverEarningsChart = ({ benchmark_buckets, strategy_buckets }: { benchmark_buckets: PriceEarningsBuckets; strategy_buckets: PriceEarningsBuckets }): any => {
    const price_earnings = { benchmark_buckets, strategy_buckets }
    const seriesKeys = Object.keys(price_earnings);
    const series = seriesKeys.map((key: string) => {
        const item = (price_earnings as any)[key] as PriceEarningsBuckets;
        const keys = Object.keys(item);
        const data: any = keys.map((key: string) => {

            return { x: Number(key), y: Number((item as any)[key]) }
        });

        return {
            name: PRICE_EARNINGS_KEY_MAP[key],
            data,
        }
    })

    return {
        chart: {
            type: 'column',
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [5, 10, 15, 20, 25],
            tickInterval: 5,
            labels: {
                style: {
                    fontSize: '14px'
                },
                enabled: true, 
                formatter: function (this: any): string {
                    const value = this.value;
                    return BUCKET_X_TO_LABEL[value];
                }
            },
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                style: {
                    fontSize: '14px'
                },
                formatter: function (this: any): string {
                    return Number(this.value).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
                }
            },
        },
        tooltip: {
          style: {
            fontSize: '14px',
          },
          formatter: function (this: any): string {
              const marker = `<span style="color:${this.color}">●</span> `;
              const yFormatted = Number(this.y).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
              const xFormatted = BUCKET_X_TO_LABEL[this.x];
              return `${marker}<b>${this.series.name}</b><br/>${xFormatted}: <b>${yFormatted}</b>`;
          }
        },
        legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'top',
            symbolWidth: 35,
            itemStyle: {
                fontSize: '14px',
            }
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: false
                },
                pointWidth: 50,
            },
            series: {
                turboThreshold: 0,
            }
        },
        series
    }
}


export const getSavedReports = async(): Promise<SavedReport[] | undefined> => {
    const { data } = await axios.get<{ success: boolean, items: SavedReport[]}>(SAVED_REPORTS_URL)

    return data?.items ?? undefined;
}

export const performCreateSavedReports = async(name: string, strategyName: string, items: string[]) => {
    const { data } = await axios.post<{ success: boolean, item: SavedReport}>(SAVED_REPORTS_URL, {
        strategy_name: strategyName,
        name,
        items,
    })

    return data?.item ?? undefined;
}

export const performUpdateSavedReport = async(id: number, strategyName: string, items: string[]) => {
    const { data } = await axios.put<{ success: boolean, item: SavedReport}>(`${SAVED_REPORTS_URL}/${id}`, {
        strategy_name: strategyName,
        items,
    })

    return data?.item ?? undefined;
}

export const performDeleteSavedReports = async(id: number) => {
    const { data } = await axios.delete<{ success: boolean}>(`${SAVED_REPORTS_URL}/${id}`)

    return data?.success ?? false;
}

export const loadSavedReports = () => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        try {
            const items = await getSavedReports();
            const savedReportOptions = items?.map((item) => {
                return {
                    label: item.name,
                    value: item.id,
                    item 
                }
            });
            dispatch({ type: ANALYZE_UPDATE, payload: { savedReports: savedReportOptions } })
        } catch (error) {
            console.error('Error loading saved reports', error)
            dispatch(addError('Orion','Error loading saved reports'));
        }
    }
}

export const createSavedReports = (name: string, strategyName: string, items: string[]) => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<void> => {
        try {
            const item = await performCreateSavedReports(name, strategyName, items);
            if (item) {
                const savedReportOption = {
                    label: item.name,
                    value: item.id,
                    item 
                }
                const savedReports = [...getState().portfolioDesigner.savedReports, savedReportOption]
                dispatch({ type: ANALYZE_UPDATE, payload: { savedReports } })
            }
        } catch (error) {
            console.error('Error loading saved reports', error)
            dispatch(addError('Orion','Error loading saved reports'));
        }
    }
}

export const updateSavedReports = (savedReport: SavedReport, strategyName: string, items: string[]) => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<void> => {
        try {
            const item = await performUpdateSavedReport(savedReport.id, strategyName, items);
            if (item) {
                const savedReportOption = {
                    label: item.name,
                    value: item.name,
                    item: {
                        ...savedReport,
                        ...item,
                    }
                }
                const existingReports = [...getState().portfolioDesigner.savedReports];
                const updatedReports = existingReports.map((report) => report.item?.id === savedReport.id ? savedReportOption : report)
                dispatch({ type: ANALYZE_UPDATE, payload: { savedReports: updatedReports } })
            }
        } catch (error) {
            console.error('Error loading saved reports', error)
            dispatch(addError('Orion','Error loading saved reports'));
        }
    }
}

export const deleteSavedReports = (id: number) => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<void> => {
        try {
            const success = await performDeleteSavedReports(id);
            if (success) {
                const savedReports = getState().portfolioDesigner.savedReports.filter((item) => item.value !== id)
                dispatch({ type: ANALYZE_UPDATE, payload: { savedReports } })
            }
        } catch (error) {
            console.error('Error loading saved reports', error)
            dispatch(addError('Orion','Error loading saved reports'));
        }
    }
}