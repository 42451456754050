import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography } from '@mui/material';
import LandingButton from '../common/LandingButton';
import Check from '@mui/icons-material/Check';
import { BaseProps } from '../../types';

interface PriceBlockProps {
    price: number
}

export const PriceBlock = ({ price }: PriceBlockProps) => {
    return (
        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-end',
         }}>
             <Typography sx={{
                font: (theme) => `normal normal 300 ${theme.typography.pxToRem(48)}/${theme.typography.pxToRem(32)} Montserrat`
             }}>${price}</Typography>
             <Typography sx={{
                font: (theme) => `normal normal normal ${theme.typography.pxToRem(26)}/${theme.typography.pxToRem(32)} Montserrat`
             }}>/ month </Typography>
        </Box>
    )
}
interface FeatureItemProps {
    item: string
}
const FeatureItem = ({ item }: FeatureItemProps) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'start',
            marginTop: '1rem',
            marginBottom: '1rem',
        }}>
            <Check fontSize={'large'} htmlColor={'#267D9E'} />
            <Typography sx={{
                marginLeft: '1.5rem',
                font: (theme) => `normal normal normal ${theme.typography.pxToRem(23)}/${theme.typography.pxToRem(30)} Montserrat`,
            }}>{item}</Typography>
        </Box>
    )
}

export type PlanAction = string | (() => Promise<void> | void)
interface PlanCardProps extends BaseProps {
    cardColor: string
    title: string | React.ReactNode
    target: string | React.ReactNode
    price: string | React.ReactNode
    actionTitle: string | React.ReactNode
    action: PlanAction
    features: string[]
    featuresNotice?: string | React.ReactNode
}

class PlanCard extends React.Component <PlanCardProps> {

    render() {
        const { sx, cardColor, title, target, price, actionTitle, features, featuresNotice, action } = this.props

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: cardColor,
                maxWidth: '80rem',
                width: {
                    md: '80rem'
                },
                color: '##171443',
                padding: '.5rem',
                flex: 1,
                borderRadius: `10px 10px 0px 0px`,
                ...sx,
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '4em',
                }}>
                    <Typography sx={{ font: (theme) => `normal normal bold ${theme.typography.pxToRem(38)}/${theme.typography.pxToRem(37)} Montserrat;` }}>{title}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    padding: '4em',
                    borderRadius: `10px`,
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                }}>
                    <Typography sx={{ font: (theme) => `normal normal normal ${theme.typography.pxToRem(28)}/${theme.typography.pxToRem(37)} Montserrat;`, minHeight: { md: '10rem', lg: '8rem' } }}>{target}</Typography>
                    <Box sx={{
                        marginTop: '4rem',
                        marginBottom: '4rem',
                    }}>
                        {price}
                    </Box>
                    <LandingButton
                    component={typeof(action) === 'function' ? undefined : RouterLink}
                    sx={{
                        border: `2px solid #6666CC`,
                        background: 'unset',
                        color: '##262626',
                        width: '100%',
                        maxWidth: '25rem',
                        marginBottom: '4rem',
                    }} title={actionTitle} onClick={typeof(action) === 'function' ? action : undefined} href={typeof(action) === 'string' ? action : undefined} />
                    {featuresNotice && 
                        <Typography sx={{ 
                            font: (theme) => `italic normal normal ${theme.typography.pxToRem(26)}/${theme.typography.pxToRem(32)} Montserrat`,
                        }}>{featuresNotice}</Typography>
                    }
                    <Box>
                        {features.map((item) => {
                            return <FeatureItem key={item} item={item} />
                        })}
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default PlanCard