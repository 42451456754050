import { NOTIFICATION_ADDED, NOTIFICATION_UPDATED, NOTIFICATION_REMOVED, LOGOUT, PROFILE_RESET } from '../actions/types/actions';
import { Notification, NotificationState as State } from '../actions/types/notifications'

export interface NotificationState extends State { }

const INITIAL_STATE: NotificationState = { }

export default (state = INITIAL_STATE, action: { type: string, payload: Notification }): NotificationState => {
  const { payload, type } = action

  switch (type) {
    case NOTIFICATION_ADDED:
    case NOTIFICATION_UPDATED:
      return { ...state, [payload.id || 0]: payload }
    case NOTIFICATION_REMOVED:
      const next = { ...state }
      delete next[payload.id || 0]

      return next
    case PROFILE_RESET:
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
      return state;
  }
};

