import React, { Component } from 'react'
import Button, { ButtonVariant } from './Button';
import Modal, { ModalProps } from './Modal';

export interface MessageModalProps extends ModalProps {
    buttonLabel?: string
    onButtonPress: () => void
}

export default class extends Component<MessageModalProps> {

    render() {
        const {children,  buttonLabel, ...props } = this.props
        return (
            <Modal
                {...props}
                footerChildren={<Button autoFit onClick={this.props.onButtonPress}>{buttonLabel || 'OK'}</Button>}
            >
                {children}
            </Modal>
        )
    }
}