import React, { Component, FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { getActivationCodes, addActivationCode, updateActivationCode, deleteActivationCode } from '../../../actions/adminActivationCodes'
import Text from '../../common/styled/Text'
import { MEMBERSHIP_MAPPING, NEW_ACTIVATION_CODE, User } from '../../../actions/types/users'
import { DataTable } from '../../common/DataTable'
import Button from '../../common/Button'
import { Box, Link } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faCopy, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import ActivationCodeModal from './ActivationCodeModal'
import { StandardApiResponse } from '../../../actions/types/responses'
import { ActivationCode } from '../../../actions/types/users'
import { StatusLevel } from '../../../common/types'
import { StatusLevelPill } from '../../common/StatusLevelPill'
import ConfirmDelete from '../../common/ConfirmDelete'
import { AdminPage } from '../AdminPage'
import { getUsers } from '../../../actions/adminUserManagement'
import TooltipWrapper from '../../../components/common/styled/TooltipWrapper'
import { copyToClipboard } from '../../../common/utils'

interface ActivationCodeRow {
    id?: number
    code: string
    domain: string
    membership: string
    status: React.ReactNode
    domain_lock: React.ReactNode
    actions: React.ReactNode
    assignedUser: string
}

interface Props {
    getUsers: () => void
    getActivationCodes: () => void
    addActivationCode: (params?: ActivationCode, logo?: string) => Promise<StandardApiResponse>
    updateActivationCode: (params?: ActivationCode, logo?: string) => Promise<StandardApiResponse>
    deleteActivationCode: (params?: ActivationCode) => Promise<StandardApiResponse>
    activationCodes: ActivationCode[]
    users: User[]
}

interface State {
    activationCodeRows: ActivationCodeRow[]
    addEditActivationCode?: ActivationCode
    confirmDeleteActivationCode?: ActivationCode
}

interface ActionProps {
    onEdit: (activationCode: ActivationCode) => void
    onDelete: (activationCode: ActivationCode) => void
    activationCode: ActivationCode
}

const STATUS_LABEL = {
    [StatusLevel.OK]: 'Active',
    [StatusLevel.DANGER]: 'Disabled',
}

const DOMAIN_LOCK_LABEL = {
    [StatusLevel.OK]: 'Unlocked',
    [StatusLevel.DANGER]: 'Locked',
}


const Actions:FunctionComponent<ActionProps>  = ({ activationCode, onEdit, onDelete }) => {
    const [linkTooltip, setLinkToolTipRef] = React.useState<TooltipWrapper | any>()
    const onEditPressed = React.useCallback(() => {
        onEdit(activationCode)
    }, [activationCode, onEdit])

    const onDeletePressed = React.useCallback(() => {
        onDelete(activationCode)
    }, [activationCode, onDelete])

    const onCopyLinkPressed = React.useCallback(() => {
        copyToClipboard(`${location.protocol}//${location.host}/signup/register?plan=enterprise&activationCode=${activationCode.code}`)
        linkTooltip.showClickedText()
    }, [activationCode, linkTooltip])

    return (
        <Box sx={{ '& svg': { cursor: 'pointer' } }}>
            <TooltipWrapper ref={setLinkToolTipRef} sx={{ marginRight: '.5rem' }} tooltipText={'Copy Link'} tooltipTextClicked={'Link Copied!'}>
                <FontAwesomeIcon color={'#297ea1'} icon={faCopy} onClick={onCopyLinkPressed} />
            </TooltipWrapper>
            <Link sx={{ marginRight: '.5rem' }} href={`/admin/designer/metrics?code=${activationCode.code}`}>
                <FontAwesomeIcon color={'#297ea1'} icon={faChartLine} />
            </Link>
            <FontAwesomeIcon style={{ marginRight: '.5rem' }} color={'#297ea1'} icon={faEdit} onClick={onEditPressed} />
            <FontAwesomeIcon color={'#297ea1'} icon={faTrash} onClick={onDeletePressed} />
        </Box>
    )
}

class ActivationCodes extends Component<Props, State> {

    constructor(props) {
        super(props)
        this.state = {
            activationCodeRows: [],
            addEditActivationCode: undefined,
        }
    }
    
    componentDidMount() {
        this.props.getActivationCodes()
        this.props.getUsers()
        
    }

    componentDidUpdate(prevProps: Props) {
        const { activationCodes: prevActivationCodes } = prevProps
        const { activationCodes } = this.props

        if(prevActivationCodes !== activationCodes) {
            const activationCodeRows = this.formatRows(activationCodes)

            this.setState({ activationCodeRows })
        }
    }

    onAddActivationCode = () => {
        this.setState({ addEditActivationCode: {...NEW_ACTIVATION_CODE}})
    }

    onEditUser = (activationCode: ActivationCode) => {
        this.setState({ addEditActivationCode: {...activationCode }})
    }

    onConfirmDelete = (activationCode: ActivationCode) => {
        this.setState({ confirmDeleteActivationCode: {...activationCode }})
    }

    onCloseAddEditModal = () => {
        this.setState({ addEditActivationCode: undefined })
    }

    onCloseConfirmDelete = () => {
        this.setState({ confirmDeleteActivationCode: undefined })
    }

    formatRows = (activationCodes: ActivationCode[]): ActivationCodeRow[] => {
        const activationCodeRows:ActivationCodeRow[] = activationCodes.map((activationCode: ActivationCode) => {
            const { id = 0, code, domain, membership, active, domain_unlocked, assigned_user: assignedUser } = activationCode

            const assignedUserString = assignedUser ? `${assignedUser.firstName[0]}. ${assignedUser.lastName}` : 'Unassigned';
            const statusLevel = active ? StatusLevel.OK : StatusLevel.DANGER
            const domainLockLevel = domain_unlocked ? StatusLevel.OK : StatusLevel.DANGER
            return { 
                id,
                code,
                domain,
                membership: MEMBERSHIP_MAPPING[membership],
                assignedUser: assignedUserString,
                status: <StatusLevelPill status={statusLevel}>{STATUS_LABEL[statusLevel]}</StatusLevelPill>,
                domain_lock: <StatusLevelPill status={domainLockLevel}>{DOMAIN_LOCK_LABEL[domainLockLevel]}</StatusLevelPill>,
                actions:  <Actions activationCode={activationCode} onEdit={this.onEditUser} onDelete={this.onConfirmDelete} />
             }
        })

        return activationCodeRows
    }

    onSave = async(activationCode: ActivationCode, logo?: Blob) => {
        let result: StandardApiResponse
        if(activationCode.id) {
            result = await this.props.updateActivationCode(activationCode, logo)
        } else {
            result = await this.props.addActivationCode(activationCode, logo)
        }

        if(result?.success) {
            this.setState({ addEditActivationCode: undefined })
        }
        
    }

    onDelete = () => {
        const { confirmDeleteActivationCode } = this.state
        if(!confirmDeleteActivationCode) {
            return
        }
        this.props.deleteActivationCode(this.state.confirmDeleteActivationCode)
        this.setState({ confirmDeleteActivationCode: undefined })
    }

    render() {
        const { activationCodeRows, addEditActivationCode, confirmDeleteActivationCode } = this.state

        return (
            <AdminPage>
                <ConfirmDelete
                    title={'Delete Activation Code'}
                    onCancel={this.onCloseConfirmDelete}
                    onDelete={this.onDelete}
                    visible={confirmDeleteActivationCode !== undefined}
                >
                    <Text>Are you sure you want to delete this activation code? This will affect anyone that signed up with the activation code</Text>
                </ConfirmDelete>
                <ActivationCodeModal 
                    activationCode={addEditActivationCode}
                    visible={addEditActivationCode !== undefined}
                    onCancel={this.onCloseAddEditModal}
                    onSave={this.onSave}
                    users={this.props.users}
                />
                <DataTable
                    actionSx={{ top: '-.5rem' }}
                    actions={<Button sx={{ minHeight: 'unset', height: '4rem', width: '15rem' }} title={'Add'} onClick={this.onAddActivationCode} />}
                    data={{ 
                        rows: activationCodeRows, 
                        columns: [{
                            label: 'Id',
                            field: 'id'
                        },{
                            label: 'Code',
                            field: 'code'
                        },{
                            label: 'Token Rep',
                            field: 'assignedUser'
                        },{
                            label: 'Domain',
                            field: 'domain'
                        },{
                            label: 'Membership',
                            field: 'membership'
                        },{
                            label: 'Active Status',
                            field: 'status'
                        },{
                            label: 'Actions',
                            field: 'actions'
                        }]
                    }}
                /> 
            </AdminPage>
        )
    }
}

const mapStateToProps = ({ activationCodes, userManagment }) => {
    const { activationCodes: items } = activationCodes
    const { users } = userManagment

    return { activationCodes: items, users }
}

export default connect(mapStateToProps, { getUsers, getActivationCodes, addActivationCode, updateActivationCode, deleteActivationCode })(ActivationCodes)