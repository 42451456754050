import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Table } from '../common/Table'
import AssetItem from './AssetItem'
import ForecastShiftCalculator from './ForecastShiftCalculator'

import { ReturnShiftForm, CMAAsset } from '../../actions/types/customAssets'
import { onSelectedAssetChanged, onCreateAsset, onRemoveAsset, onReturnScaleChanged, onReturnShiftChanged, onReturnShiftEnableToggled, assetHasValues } from '../../actions/customCMA'
import Text, { FONT_STYLES_BY_TYPE } from '../common/styled/Text'
import { CircleActionButton } from '../common/styled/ShapedActionButton'
import { Box, TableCell, TableRow } from '@mui/material'
import { BaseProps } from '../types'

export interface ShiftEditItem extends CMAAsset {
    annualizedReturn: number
}

interface AssetTableProps extends BaseProps {
    onSelectedAssetChanged: (id: number, asset: any) => void
    onRemoveAsset: (id: number) => void
    onReturnShiftEnableToggled: (id: number, value: boolean) => void
    onReturnScaleChanged: (id: number, value: string) => void
    onCreateAsset: () => void
    onReturnShiftChanged: (id: number, returnShift: number, shiftForm: ReturnShiftForm) => void
    items: CMAAsset[]
}

interface AssetTableState {
    forecastShiftItem?: ShiftEditItem
}

class AssetsTable extends Component<AssetTableProps, AssetTableState>{

    constructor(props) {
        super(props)
        this.state = {
            forecastShiftItem: undefined
        }
    }


    onForecastShiftEdit = (item) => {
        if(assetHasValues(item)) {
            this.setState({ forecastShiftItem: item })
        } else {
            alert('Please run this model before editing the Forecast Shift.')
        }
    }

    onForecastShiftCancel = () => {
        this.setState({ forecastShiftItem: undefined })
    }

    onForecastShiftSave = (returnShift, shiftForm) => {
        const { forecastShiftItem } = this.state
        if(!forecastShiftItem) {
            return
        }

        const { id } = forecastShiftItem
        
        this.props.onReturnShiftChanged(id, returnShift, shiftForm)
        this.setState({ forecastShiftItem: undefined })
    }

    render() {
        const { items = [], sx = { }  } = this.props
        const { forecastShiftItem } = this.state
        
        return (
            <Box sx={sx}>
                <ForecastShiftCalculator item={forecastShiftItem} visible={forecastShiftItem !== undefined} onSave={this.onForecastShiftSave} onCancel={this.onForecastShiftCancel} />
                
                <Table
                    sx={{
                        "& th": {
                            padding: '1rem',
                            "& td": {
                                font: FONT_STYLES_BY_TYPE.table_header,
                            }
                        }
                    }}
                    headerColumns={[
                        {
                            key: 'actions',
                            children: <CircleActionButton type='filled' action={'add'} onClick={this.props.onCreateAsset} />
                        },
                        {
                            key: 'Asset',
                            children: 'Asset'
                        },
                        {
                            key: 'Data Start',
                            children: 'Data Start'
                        },
                        {
                            key: 'Monthly Return<br />Contribution',
                            children: <Text type='table_header'>Monthly Return<br />Contribution</Text>
                        },
                        {
                            key: 'Monthly Volatility<br />Contribution',
                            children: <Text type='table_header' glossaryTerm={'Volatility Contribution'}>Monthly Volatility<br />Contribution</Text>
                        },
                        {
                            key: 'Monthly Skew<br />Contribution',
                            children: <Text type='table_header' glossaryTerm={'Skew Contribution'}>Monthly Skew<br />Contribution</Text>
                        },
                        {
                            key: 'Mimicking Portfolio<br />Tracking Error',
                            children: <Text type='table_header' glossaryTerm={'Mimicking Portfolio Tracking Error'}>Mimicking Portfolio<br />Tracking Error</Text>
                        },
                        {
                            key: 'Historical<br />Return',
                            children: <Text type='table_header' glossaryTerm={'Historical Return'}>Historical<br />Return</Text>
                        },
                        {
                            key: 'Forecast<br />Shift',
                            children: <Text type='table_header'>Forecast<br />Shift</Text>
                        },
                        {
                            key: 'Forecast<br />Return',
                            children: <Text type='table_header'>Forecast<br />Return</Text>
                        }
                    ]}
                >
                {items.length == 0 &&
                    <TableRow>
                        <TableCell colSpan={10} align="center"><Text>No Items</Text></TableCell>
                    </TableRow>
                }
                {items.map((item) => {
                    return  <AssetItem 
                                key={`${item.id}`}
                                item={item}
                                onSelectedAssetChanged={this.props.onSelectedAssetChanged}
                                onRemoveAsset={this.props.onRemoveAsset}
                                onReturnShiftEnableToggled={this.props.onReturnShiftEnableToggled}
                                onReturnScaleChanged={this.props.onReturnScaleChanged}
                                onForecastShiftEdit={this.onForecastShiftEdit}
                            />
                })}
                </Table>
            </Box>
        )
    }
}

const mapStateToProps = ({ capitalMarketAssumptions }) => {
    const { cma_assets } = capitalMarketAssumptions

    return {
        items: cma_assets
    }
}

export default connect(mapStateToProps, { onSelectedAssetChanged, onCreateAsset, onRemoveAsset, onReturnScaleChanged, onReturnShiftChanged, onReturnShiftEnableToggled })(AssetsTable)