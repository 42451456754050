import React, { useEffect } from 'react'
import { TimeRange, TimeRangeDates, TIME_RANGES, getTimeRangeDates } from './ranges'
import { Box } from '@mui/material';
import Text from './Text';
import { Theme } from '../../theme/theme';

interface TimeRangeItemProps {
    item: TimeRange;
    selected?: boolean;
    max?: Date;
    onRangePicker: (range: TimeRange, dates: TimeRangeDates) => void;
}

interface Props {
    value?: TimeRange;
    ranges?: string[];
    max?: Date;
    onRangePicker: (range: TimeRange, dates: TimeRangeDates) => void;
}

export const TimeRangeItem: React.FC<TimeRangeItemProps> = ({ item, selected, max, onRangePicker: propsOnRangePicker }) => {
    const onRangePicker = React.useCallback(() => {
        const dates = getTimeRangeDates(item, max);
        propsOnRangePicker(item, dates)
    }, [item, max, propsOnRangePicker])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem', paddingRight: '1rem',cursor: 'pointer', alignItems: 'center', justifyContent: 'center', height: '5rem' }} onClick={onRangePicker}>
            <Text 
                sx={{ 
                        borderTop: (theme: Theme) => `2px solid ${selected ? theme.palette.text.level7 : 'transparent'}`,
                        color: (theme: Theme) => selected ? theme.palette.text.level7 : undefined,
                        paddingTop: '.5rem',
                        paddingBottom: '.5rem'
                    }}>
                        {item?.label}
            </Text>
        </Box>
    )
}


export const TimeRangePicker: React.FC<Props> = ({ value, onRangePicker, ranges, max }) => {
    const [items, setItems] = React.useState<TimeRange[]>(TIME_RANGES);
    useEffect(() => {
        if (ranges) {
            setItems(TIME_RANGES.filter(item => ranges.includes(item.label)))
        } else {
            setItems(TIME_RANGES)
        }
    }, [ranges]);

    return (
            <Box sx={{ display: 'flex', flexDirection: 'row', border: (theme) => `1px solid ${theme.palette.primary.main}`, width: `${items.length * 4.5}rem`, height: '5rem' }}>
                {items.map((item) => {
                    return (<TimeRangeItem key={`${item.duration}${item.unit}`} item={item} selected={value?.unit === item.unit && item.duration === value?.duration} max={max} onRangePicker={onRangePicker} />)
                })}
            </Box>
    )
}