import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom';

import { updateSession } from '../actions/session';
import { useAuth } from './hooks/useAuth';

export interface SessionUserDetailsSyncProps {
  updateSession: (values: any) => any
  excludeRoutes?: string[]
}

  const SessionUserDetailsSync: FunctionComponent<SessionUserDetailsSyncProps> = ({ updateSession, excludeRoutes }) => {
    const { pathname } = useLocation();
    const { details } = useAuth()
    React.useEffect(() => {
      if (excludeRoutes) {
        const matchedExclusionRoutes = excludeRoutes?.find((route) => {
          return matchPath(route, window.location.pathname)
        })
        if(matchedExclusionRoutes) {
          return;
        }
      }
      updateSession({ defaultKey: details?.defaultKey, views: details?.views, scopes: details?.scopes })
    }, [updateSession, details?.defaultKey, details?.views, pathname, details?.scopes] )
    
    return null;
  }

  export default connect(null, { updateSession })(SessionUserDetailsSync)