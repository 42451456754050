import React, { Component, FunctionComponent } from 'react'
import { Box, SxProps, Theme } from '@mui/material'
import Text from '../common/styled/Text'
import { BaseProps } from '../types'
import { TextField } from '../common/FormField'

export type InputOnChange = (vaue: string | number) => void

export interface InputState  {
    inputHasFocus?: boolean
    value: string | number
}

export interface InputProps extends BaseProps {
    value: number | string
    onChange?: InputOnChange
    onLeave?: InputOnChange
    extendedInput?: boolean
    decimalPlaces?: number
    isPercentage?: boolean
    alphaNumeric?: boolean
    inputClassName?: string
    inputSx?: SxProps<Theme>
}


export class StyledInput extends Component<InputProps, InputState> {
    inputRef: any
    constructor(props: InputProps) {
        super(props)
        const { value, decimalPlaces = 0, isPercentage, alphaNumeric } = props
        let displayValue = value == null || value == undefined || isNaN(Number(value)) ? 0 : value
            displayValue = isPercentage ? (Number(displayValue) * 100).toFixed(decimalPlaces) : displayValue.toLocaleString('en-US')
            displayValue = alphaNumeric ? value : displayValue
            displayValue = displayValue == null ? '' : displayValue

        this.state = {
            value: displayValue,
            inputHasFocus: false
        }
    }

    componentDidUpdate(prevProps: InputProps) {
        const { inputHasFocus } = this.state
        const { value, decimalPlaces = 0, isPercentage, alphaNumeric } = this.props
        const { value: prevValue } = prevProps

        if(value == prevValue || (!alphaNumeric && inputHasFocus)) {
            return
        }


        let displayValue: number | string = value == null ? '' : value
        if(!alphaNumeric && typeof(value) != 'string') {
            displayValue = value == null || value == undefined || isNaN(value) ? 0 : value
            displayValue = isPercentage ? (displayValue * 100).toFixed(decimalPlaces) : displayValue.toLocaleString('en-US')
        } 
        this.setState({ value: displayValue })
    }

    onChange = (e: any) => {
        let value = e.target.value
        if(this.props.alphaNumeric) {
            this.setState({ value })

            if(this.props.onChange) {
                this.props.onChange(value)
            }
            return
        }
        const valueTest = parseFloat(value.replace(/,/g, '').replace(/[^0-9.]/g,''))
        
        if((value && value.length > 0) && isNaN(valueTest)) {
            return
        }

        this.setState({ value })

        if(this.props.onChange) {
            this.props.onChange(this.props.isPercentage ? value / 100 : value)
        }
    }

    onBlur = (e: any) => {
        const { decimalPlaces = 0, isPercentage, alphaNumeric } = this.props

        if(alphaNumeric) {
            this.setState({ inputHasFocus: false })
            if(e.target.value !== this.props.value) {
                if(this.props.onChange) {
                    this.props.onChange(e.target.value)
                }
                if(this.props.onLeave) {
                    this.props.onLeave(e.target.value)
                }
            }

            return
        }

        const parseFunction = decimalPlaces > 0 ? parseFloat : parseInt
        let value = e.target.value
        if(value != '') {
            value = parseFunction(value.replace(/,/g, '').replace(/[^0-9.]/g,''), 10)
            if(isNaN(value)) {
                return
            }
        } else {
            value = 0
        }
        value = isPercentage ? value / 100 : value

        let displayValue = value == null || value == undefined || isNaN(value) ? 0 : value
            displayValue = isPercentage ? (displayValue * 100).toFixed(decimalPlaces) : displayValue.toLocaleString('en-US')
            
        this.setState({ value: displayValue })
        if(value !== this.props.value) {
            if(this.props.onChange) {
                this.props.onChange(value)
            }
            if(this.props.onLeave) {
                this.props.onLeave(value)
            }
        }

        this.setState({ inputHasFocus: false })
    }

    render() {
        const { isPercentage, extendedInput, className = '', inputClassName = '', sx = { }, inputSx = { } } = this.props
        const { value } = this.state

        return (
            <Box sx={{width: extendedInput ? '15rem' : '7rem', ...sx, position: 'relative' }} className={className}>
                <TextField
                    forwardRef={(view: any) => this.inputRef = view}
                    sx={{
                        width: extendedInput ? '15rem' : '7rem',
                        ...inputSx,
                        '& input': {
                            paddingRight: isPercentage ? '1rem' : undefined,
                        }
                    }}
                    className={inputClassName} 
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    onFocus={() => this.setState({ inputHasFocus: true })}
                    value={value} />
                {isPercentage == true && <Text sx={{ position: 'absolute', top: 8, right: 6 }}>%</Text> }
            </Box>
        )
    }
}