import { 
    LOGOUT,
    ACTIVATION_CODES_RECEIVED,
} from '../actions/types/actions';
import { ActivationCode } from '../actions/types/users';

export interface ActivationCodesState {
    activationCodes: ActivationCode[],
}

const INITIAL_STATE: ActivationCodesState = {
    activationCodes: [],
};

export default (state = INITIAL_STATE, action): ActivationCodesState => {
  const { payload, type } = action

  switch (type) {
    case ACTIVATION_CODES_RECEIVED:
        return { ...state, activationCodes: payload }
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

