import React, { FC } from 'react';
import { SelectOption } from '../../common/types';
import { Select, SelectProps } from './styled/Select';

export interface CommonTableSelectProps extends Omit<SelectProps, 'value' | 'onChange'>{
    rowKey: string;
    valueIndex: number;
    value: string | number | undefined | null;
    onChange: (rowKey: string, valueIndex: number, value: SelectOption) => void;
}

export const CommonTableSelect: FC<CommonTableSelectProps> = ({ rowKey, valueIndex, value, onChange, options, sx, ...props }) => {
    const [sxProp, setSxProp] = React.useState<any>(sx || {});
    React.useEffect(() => {
        setSxProp(sx || {});
    }, [sx]);
    const [selectValue, setSelectValue] = React.useState<SelectOption | null>((options.find((option: any) => option.value === value)) as any ?? null);
    React.useEffect(() => {
        setSelectValue(options.find((option: any) => option.value === value) as any ?? null);
    }, [value]);
    const onValueChange = React.useCallback((option: SelectOption) => {
        setSelectValue(option);
        onChange(rowKey, valueIndex, option);
    }, [rowKey, valueIndex, onChange]);

    return <Select 
                {...props}
                sx={{
                    '& .css-tlfecz-indicatorContainer': {
                        padding: 'unset',
                        height: '3rem',
                        width: '3rem',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }, 
                    '& .css-1gtu0rj-indicatorContainer': { 
                        padding: 'unset',
                        height: '3rem',
                        width: '3rem',
                    },
                    '&>div': {
                        width: '100%',
                        '&>div': {
                            borderColor: '#0000',
                            boxShadow: 'unset',
                            '&:hover': {
                                borderColor: '#0000',
                            },
                            '&>div:nth-of-type(2)': {
                                marginLeft: '.5rem',
                                marginRight: '.5rem',
                                borderRadius: '5px',
                                '&>span': {
                                    display: 'none',
                                },
                            },
                        },
                    },
                    ...sxProp,
                }}
                options={options}
                value={selectValue}
                onChange={onValueChange}
                skinny
            />
}