import {FC} from 'react';
import Box from '@mui/material/Box';
import FixedIncomeSectors from './FixedIncomeSectors';
import FixedIncomeCreditQuality from './FixedIncomeCreditQuality';
import FixedIncomeMaturityTable from './FixedIncomeMaturityTable';

interface BreakdownProps { }

const FixedIncomeBreakdown: FC<BreakdownProps> = ({ }) => {

  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
      <FixedIncomeSectors />
      <FixedIncomeCreditQuality />
      <FixedIncomeMaturityTable />
    </Box>
  );
}

export default FixedIncomeBreakdown;