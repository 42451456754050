import { Ticker } from "./portfolioDesigner";

type FundOptions = Partial<Pick<Ticker, 'description' | 'asset_class' | 'expense_ratio' | 'launch_date'>>;

export interface AssetItem {
    id: number,
    user_id: number,
    symbol: string
    symbol_display: string
    name: string
    expense_ratio: number
    description: string
    asset_class: string
    launch_date: string
}
export interface AssetMetrics {
    distribution_yield: number
    modified_duration: number
}
export enum AllocationType {
    FIXED_INCOME_SECTOR_BREAKDOWN = 'fixed_income_sector_breakdown',
    CREDIT_QUALITY_BREAKDOWN = 'credit_quality_breakdown',
    MATURATIY_BREAKDOWN = 'maturity_breakdown',
    EQUITY_SECTOR_BREAKDOWN = 'equity_sector_breakdown',
    EQUITY_REGION_BREAKDOWN = 'equity_region_breakdown',
  }
export interface AssetAllocations {
    id: number
    asset_id: number
    type: AllocationType
    name: string
    weight: number
    rank: number
}

export interface Asset extends FundOptions {
    id: 206
    user_id: number
    min_date: string;
    max_date: string;
    symbol: string;
    symbol_display: string;
    name: string;
}

export interface AssetUpdate extends FundOptions {
    name?: string;
}

export interface AssetSet {
    id: number
    start_date: string | null
    end_date: string | null
    label: string
    name: string
    value: number
    is_global: number | boolean
    assets: Asset[]
}

export interface AssetDate {
    id: number
    user_id: number
    date: string
    formatted: string
    stock: number | boolean
}

export interface ReturnShiftForm {
    id?: number
    annualizedReturn: number
    _1a: number
    _1b: number
    _1c: number
    _1d: number
    _2a: number
    _2b: number
    _2c: number
    _2d: number
    _2e: number
    _3a: number
    _3b: number
    _3c: number
    _3d: number
}

export interface CMAAsset {
    id: number
    asset?: Asset
    benchmark_port: string | number
    idFlag: 0
    kurtosis: string | number
    kurtosisSE: string | number
    mctk: number
    mctr: number
    mcts: number
    mctu: number
    mctv: number
    mpte: number
    mpteTop3Assets: string
    nG_nI: number
    nG_pI: number
    pG_nI: number
    pG_pI: number
    port: number
    return: number
    returnSE: number
    returnScale: 1
    returnShift: 0
    return_shift_enabled: boolean
    return_shift_form: ReturnShiftForm
    skew: number
    skewSE:  number
    volatility:  number
    volatilitySE: number
}

export type DateType = 'start_date' | 'end_date'
