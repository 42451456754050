import axios from 'axios'

import { ACTIVATION_CODES_RECEIVED } from './types/actions'
import { addError } from './notifications'
import { StandardThunkAction } from '../store/store'
import { isApiError } from './utils'
import { StandardApiResponse } from './types/responses'
import { ActivationCode } from './types/users'

export const getActivationCodes = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const { data } = await axios.get(`/api/admin/activationcodes`)
            const { activationCodes } = data
            
            dispatch({ type: ACTIVATION_CODES_RECEIVED, payload: activationCodes })
        } catch(e: any) {}
    }
}

export const addActivationCode = (code: ActivationCode, logo?: string): StandardThunkAction<StandardApiResponse> => {
    return async(dispatch, getState): Promise<StandardApiResponse> => {
        try {
            const { data } = await axios.post(`/api/admin/activationcodes`, {...code, logo})
            const { activationCode } = data

            const { activationCodes: activationCodesState } = getState()
            const { activationCodes } = activationCodesState

            dispatch({ type: ACTIVATION_CODES_RECEIVED, payload: [activationCode, ...activationCodes] })
            return { success: true }
        } catch(error: any) {
            if(isApiError(error)) {
                const { errors: apiErrors } = error.response?.data || { }
                const errors = Object.keys(apiErrors).reduce((accum: string[], key: string) => {
                    const item = apiErrors[key] || []
    
                    return [...accum, ...item]
                }, [])
    
                dispatch(addError('Add User Error', errors.join('\r\n')))

                return { success: false, errors }
            }
            return { success: false, errors: [`${error.toString()}`] }
        }        
    }
}

export const updateActivationCode = (code: ActivationCode, logo?: string): StandardThunkAction<StandardApiResponse> => {
    return async(dispatch, getState): Promise<StandardApiResponse> => {
        try {
            const { data } = await axios.put(`/api/admin/activationcodes/${code.id}`, { ...code, logo })
            const { activationCode } = data

            const { activationCodes: activationCodesState } = getState()
            const { activationCodes } = activationCodesState

            const updatedActivationCodes = activationCodes.map((item: ActivationCode) => {
                if(item.id === activationCode.id) {
                    return { ...item, ...activationCode }
                }

                return item
            })

            dispatch({ type: ACTIVATION_CODES_RECEIVED, payload: updatedActivationCodes })
            return { success: true }
        } catch(error: any) {
            if(!isApiError(error)) {
                dispatch(addError('Update User Error', error.toString()))

                return { success: false, errors: [`${error.toString()}`] }
            }

            const { errors: apiErrors, message } = error.response?.data || { }
            if(apiErrors) {
                const errors = Object.keys(apiErrors).reduce((accum: string[], key: string) => {
                    const item = apiErrors[key] || []
    
                    return [...accum, ...item]
                }, [])
    
                dispatch(addError('Update User Error', errors.join('\r\n')))
                return { success: false, errors }
            }

            if(message) {
                dispatch(addError('Update User Error', message))

                return { success: false, errors: [message] }
            }
            return { success: false, errors: [`${error.toString()}`] }
        }
    }
}



export const deleteActivationCode = (code: ActivationCode): StandardThunkAction<StandardApiResponse> => {
    return async(dispatch, getState): Promise<StandardApiResponse> => {
        try {
            await axios.delete(`/api/admin/activationcodes/${code.id}`)

            const { activationCodes: activationCodesState } = getState()
            const { activationCodes } = activationCodesState

            const updatedActivationCodes = activationCodes.filter(({ id }: ActivationCode) => id !== code.id)

            dispatch({ type: ACTIVATION_CODES_RECEIVED, payload: updatedActivationCodes })
            return { success: true }
        } catch(error: any) {
            if(!isApiError(error)) {
                dispatch(addError('Update User Error', error.toString()))

                return { success: false, errors: [`${error.toString()}`] }
            }

            const { errors: apiErrors, message } = error.response?.data || { }
            if(apiErrors) {
                const errors = Object.keys(apiErrors).reduce((accum: string[], key: string) => {
                    const item = apiErrors[key] || []
    
                    return [...accum, ...item]
                }, [])
    
                dispatch(addError('Update User Error', errors.join('\r\n')))
                return { success: false, errors }
            }

            if(message) {
                dispatch(addError('Update User Error', message))

                return { success: false, errors: [message] }
            }
            return { success: false, errors: [`${error.toString()}`] }
        }
    }
}
