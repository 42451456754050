import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import Text, { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import { Page } from '../Page';

export const NotFound: FunctionComponent = () => {
    return (
        <Page
        contentsSx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}
        >
            <Box sx={{ 
                    marginTop: '5rem', 
                    font: FONT_STYLES_BY_TYPE.text,
                    padding: '5rem',
                    backgroundColor: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                 }}>
                    <Text type='h3'>404 Error!</Text>
                    <Text asDiv>You've reached a page that doesn't exist or is no longer available.</Text>
                    <Text asDiv>Please use the navigation on top and select another option.</Text>
            </Box>
        </Page>
    )
}
export default NotFound