import axios from 'axios'
import moment from 'moment-timezone'
import 'moment-timezone'


import { DispatchFunction } from '../reducers';
import { addError } from './notifications';
import { DashboardItem, HISTORICAL_CURVE_OPTION_LABELS, HistoricalCurve, HistoricalCurveData, HistoricalCurveOptions, HistoricalCurveType, HistoricalSeries, HistoricalSeriesType, PERFORMANCE_ITEM_ORDER_MAPPING, Performance, PerformanceDates, PerformanceType, TYPE_LABEL_MAPPING, Type, YIELD_MONTH_MAPPING } from './types/marketDashboard';
import { HISTORICAL_CURVE_CHART, HISTORICAL_TIME_SERIES_CHART, PERFORMANCE_DATA } from './types/actions';
import { ChartOptions } from '../components/OptimizedPortfolio/chartOptions';
import { roundToNearest15Minutes } from '../common/utils';
import { format } from 'path';

const MARKET_DASHBOARD_API = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/market-dashboard`

const getMonthDiff = (tradeDate: string, maturity: string): number => {
    const tradeDateMoment = moment(tradeDate).startOf('month');
    const maturityMoment = moment(maturity).startOf('month');

    return maturityMoment.diff(tradeDateMoment, 'months');
};

const getRoundedMonthDiff = (tradeDate: string, maturity: string): number => {
    const tradeDateMoment = moment(tradeDate);
    const maturityMoment = moment(maturity);
  
    let diffInMonths = maturityMoment.diff(tradeDateMoment, 'months');
    const remainingDays = maturityMoment.diff(tradeDateMoment.add(diffInMonths, 'months'), 'days');
  
    // If remaining days are more than half a month, round up
    if (remainingDays >= 15) {
      diffInMonths += 1;
    }
    
    return diffInMonths;
  };
  

export const getHistoricalCurveData = async (type: HistoricalCurveType, options: HistoricalCurveOptions): Promise<{ latest_trade_time: string, items: HistoricalCurveData[]}> => {
    try {
        const {data} = await axios.post(`${MARKET_DASHBOARD_API}/historical/curve/${type}`, { options })
        const {items, latest_trade_time} = data

        return {items, latest_trade_time};
    } catch (e) {
        console.error(e)
    }

    return {items: [], latest_trade_time: ''};
}

export const getHistoricalTimeData = async (type: HistoricalSeriesType, start: Date, end: Date): Promise<{ latest_trade_time: string, items: HistoricalSeries[]}> => {
    try {
        const {data} = await axios.post(`${MARKET_DASHBOARD_API}/historical/series/${type}`, { start: moment(start).format('YYYY-MM-DD'), end: moment(end).format('YYYY-MM-DD') })
        const {items, latest_trade_time} = data

        return {items, latest_trade_time};
    } catch (e) {
        console.error(e)
    }

    return {items: [], latest_trade_time: ''};
}

export const getPerformanceData = async (type: PerformanceType): Promise<Performance[]> => {
    try {
        const {data} = await axios.post(`${MARKET_DASHBOARD_API}/historical/performance/${type}`)
        const {items} = data

        return items.sort((a: Performance, b: Performance) => {
            const indexA = PERFORMANCE_ITEM_ORDER_MAPPING[a.identifier] ?? Number.MAX_SAFE_INTEGER;
            const indexB = PERFORMANCE_ITEM_ORDER_MAPPING[b.identifier] ?? Number.MAX_SAFE_INTEGER;
            return indexA - indexB;
          });
    } catch (e) {
        console.error(e)
    }

    return [];
}

const vixHistoricalChartData = (curveData: HistoricalCurveData[]) => {
    const sortedWithDates = curveData.map((data: HistoricalCurveData) => {
        let items = data.items.map((item: HistoricalCurve) => {
            const diffMonths = getMonthDiff(item.trade_date, item.last_tradeable_dt);

            return {
                ...item,
                month: diffMonths,
            }
        }).filter(item => item.px_last).sort((a, b) => a.month - b.month);
        if (items.length > 0 && items[0].month === 0) {
            items = items.map((item: HistoricalCurve) => {
                return {
                    ...item,
                    month: item.month + 1,
                }
            })
        }
        return {
            ...data,
            items,
        }
    });
    const categories = sortedWithDates.reduce((accum: string[], item: HistoricalCurveData) => {
        return [
            ...accum,
            ...item.items.map((item: HistoricalCurve) => `${item.month} Month`)
        ]
    }, [])

    const uniqueCategories = [...(new Set(categories))]
    
    const series = [
        ...sortedWithDates.map((curveItem: HistoricalCurveData) => {
            return  {
                name: `${HISTORICAL_CURVE_OPTION_LABELS[curveItem.key]}`,
                type: 'spline',
                data: curveItem.items.map((item: HistoricalCurve, i) => {
                    return {
                        x: item.month,
                        y: item.px_last,
                        item,
                    }
                })
            }
        }),
    ]

    return { series, categories: undefined }
}

const usTreasuryHistoricalChartData = (data: HistoricalCurveData[]) => {
    const sortedWithDates = data.map((dataItem: HistoricalCurveData) => {
        const items = dataItem.items.map((item: HistoricalCurve) => {

            return {
                ...item,
                month: YIELD_MONTH_MAPPING[item.identifier],
            }
        }).sort((a, b) => a.month - b.month);
        return {
            ...dataItem,
            items,
        }
    });

    const categories = sortedWithDates.reduce((accum: string[], item: HistoricalCurveData) => {
        return [
            ...accum,
            ...item.items.map((item: HistoricalCurve) => {
                const label = item.month >= 12 ? `${Math.round(item.month / 12)} Year` : `${item.month} Month`

                return label
            })
        ]
    }, [])

    const series = [
        ...sortedWithDates.map((curveItem: HistoricalCurveData) => {
            return  {
                name: `${HISTORICAL_CURVE_OPTION_LABELS[curveItem.key]}`,
                type: 'spline',
                data: curveItem.items.map((item: HistoricalCurve,i) => {
                    return {
                        x: i,
                        y: Number(item.yld_ytm_mid.toFixed(2)),
                        item,
                    }
                })
            }
        }),
    ]

    return { series, categories }
}

export const getHistoricalCurveChart = (type: HistoricalCurveType, data: HistoricalCurveData[]): ChartOptions => {
    const { categories, series } = type === Type.VIX_CURVE ? vixHistoricalChartData(data) : usTreasuryHistoricalChartData(data)

    return {
        chart: {
            type: 'spline',
        },
        title: {
            text: '',
        },
        xAxis: {
            categories,
            labels: {
              rotation: 45,  // Rotate labels by 45 degrees
                style: {
                    fontSize: '14px',
                },
                formatter: function(this: any) {
                    return categories ? this.value : `${this.value} Month`
                },
                step: 1,
            },
            tickInterval : 1,
        },
        yAxis: {
            title: {
                text: '',
            },
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        series,
        legend: {
            enabled: true,
            verticalAlign: 'top',
            itemStyle: {
                fontSize: '14px',
            }
        },
        tooltip: {
          style: {
            fontSize: '14px',
          },
          formatter: function(this: any) {
            const { series, item } = this.point
            const { month, px_last, yld_ytm_mid } = item

            return `<b>${series.name}</b><br>${month} Month: ${type === Type.VIX_CURVE ? px_last.toFixed(2) : yld_ytm_mid.toFixed(2)}`
          },
        },
    }
}

export const getHistoricalCurvesChart = (type: HistoricalCurveType, options: HistoricalCurveOptions) => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        try {
            const historicalCurveData = await getHistoricalCurveData(type, options)
            const historicalCurveChart = await getHistoricalCurveChart(type, historicalCurveData.items) 

            dispatch({
                type: HISTORICAL_CURVE_CHART,
                payload: {
                    historicalCurveChart,
                    historicalCurveData,
                    historicalCurveLastTradeTime: !historicalCurveData.latest_trade_time ? '' : roundToNearest15Minutes(moment.tz(historicalCurveData.latest_trade_time.split('.')[0], 'America/New_York')).format('MM/DD/YYYY hh:mm a'),
                }
            })
        } catch (e) {
            console.error(e)
            dispatch(addError('Load Charts', `An unknown error occurred while retrieving your saved charts: ${(e as any).toString()}`))
        }
    }
}


export const getHistoricalCurveTimeChart = (type: HistoricalSeriesType, data: HistoricalSeries[]): ChartOptions => {
    const series = [{
        name: `${TYPE_LABEL_MAPPING[type]}`,
        type: 'spline',
        data: data.filter(item => item.px_last).map((item: HistoricalSeries) => {
            return [moment(item.trade_date).toDate().getTime(), item.px_last]
        })
    }];

    return {
        chart: {
            type: 'spline',
        },
        title: {
            text: '',
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L ',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%m/%d/%Y',
            week: '%m/%d/%Y',
            month: '%m/%Y',
            year: '%Y'
          },
          labels: {
            rotation: 45,  // Rotate labels by 45 degrees
              style: {
                fontSize: '14px',
              },
          },
        },
        yAxis: {
            title: {
                text: '',
            },
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        series,
        legend: {
            enabled: false,
        },
        tooltip: {
          style: {
            fontSize: '14px',
          },
        },
    }
}

export const getHistoricalTimesChart = (type: HistoricalSeriesType, start: Date, end: Date) => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        try {
            const historicalSeriesData = await getHistoricalTimeData(type, start, end)
            const historicalSeriesChart = await getHistoricalCurveTimeChart(type, historicalSeriesData.items) 

            dispatch({
                type: HISTORICAL_TIME_SERIES_CHART,
                payload: {
                    historicalSeriesData,
                    historicalSeriesChart,
                    historicalSeriesLastTradeTime: !historicalSeriesData.latest_trade_time ? '' : roundToNearest15Minutes(moment.tz(historicalSeriesData.latest_trade_time.split('.')[0], 'America/New_York')).format('MM/DD/YYYY hh:mm a'),
                }
            })
        } catch (e) {
            console.error(e)
            dispatch(addError('Load Charts', `An unknown error occurred while retrieving your saved charts: ${(e as any).toString()}`))
        }
    }
}

export const getHistoricalPerformanceTable = (type: PerformanceType) => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        try {
            const performanceData = await getPerformanceData(type)
            const performanceDateMapping = performanceData.filter(item => item.as_of).reduce((accum: { [key: string]: string[] }, item: Performance) => {
                const key = roundToNearest15Minutes(moment.tz(item.as_of.split('.')[0], 'America/New_York')).format('MM/DD/YYYY hh:mm a')
                const dateItems = [
                    ...(accum[key] || []),
                    item.display_name,
                ]
                return {
                    ...accum, [key]: dateItems
                }
            }, {})
            const performanceDates = Object.keys(performanceDateMapping).map((key: string) => {
                const items = performanceDateMapping[key]

                return { date: key, items}
            }).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())

            dispatch({
                type: PERFORMANCE_DATA,
                payload: {
                    performanceData,
                    performanceDates
                }
            })
        } catch (e) {
            console.error(e)
            dispatch(addError('Load Charts', `An unknown error occurred while retrieving your saved charts: ${(e as any).toString()}`))
        }
    }
}
