import axios from 'axios'
import { StandardThunkAction } from '../store/store'
import { addError } from './notifications'
import { COPY_ITEMS, EDIT_COPY_ITEM, GUIDE_COPY_ITEMS } from './types/actions'
import { CopyItem } from './types/copy'
import { UpdateCopyItemRequest } from './types/requests'
import { Membership } from './types/users'

export const COPY_URL = '/api/admin/copy'
export const GUIDE_COPY_URL = '/api/admin/guide/copy'

export const getCopy = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const { data } = await axios.get(COPY_URL)

            const { items = [] } = data

            dispatch({ type: COPY_ITEMS, payload: items || []})
        } catch(e: any) {
            console.log(e)
        }
    }
}

export const getCopyItem = (id: string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const { data } = await axios.get(`${COPY_URL}/${id}`)

            const { item } = data

            dispatch({ type: EDIT_COPY_ITEM, payload: item })
        } catch(e: any) {
            console.log(e)
        }
    }
}

export const addCopy = (name: string, title: string): StandardThunkAction<boolean> => {
    return async(dispatch, getState): Promise<boolean> => {
        try {
            const { data } = await axios.post(COPY_URL, { name, title })
    
            const { item } = data

            const { copy } = getState()
            const { items } = copy

            dispatch({ type: COPY_ITEMS, payload: [...items, item]})

            return true
        } catch(e: any) {
            dispatch(addError('Add Copy', e.toString()))
        }

        return false
    }
}

export const deleteCopy = (payload: CopyItem): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        try {
            await axios.delete(`${COPY_URL}/${payload.id}`)

            const { copy } = getState()
            const { items } = copy

            dispatch({ type: COPY_ITEMS, payload: items.filter((item: CopyItem) => item.id !== payload.id )})
        } catch(e: any) {
            dispatch(addError('Delete Copy', e.toString()))
        }
    }
}

export const updateCopy = (payload: UpdateCopyItemRequest): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        try {
            const { data } = await axios.put(`${COPY_URL}/${payload.id}`, payload)

            const { item: updateItem } = data

            const { copy } = getState()
            const { items } = copy

            const updatedItems = items.map((item: CopyItem) => {
                if(item.id === updateItem.id) {
                    return { ...updateItem }
                }

                return item
            })

            dispatch({ type: COPY_ITEMS, payload: updatedItems })

        } catch(e: any) {
            dispatch(addError('Update Copy', e.toString()))
        }
    }
}

export const getGuideCopyItems = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const { data } = await axios.get(GUIDE_COPY_URL)

            const { items = [] } = data

            dispatch({ type: GUIDE_COPY_ITEMS, payload: items || []})
        } catch(e: any) {
            console.log(e)
        }
    }
}

export const addGuideCopyItem = (membership: Membership, enterprise: boolean, copy_id: number): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            const { data } = await axios.post(GUIDE_COPY_URL, { membership, enterprise, copy_id })

            const { item } = data
            if(!item) {
                return
            }

            const { copy } = getStore()
            const { guideCopyItems } = copy

            dispatch({ type: GUIDE_COPY_ITEMS, payload: [...guideCopyItems, item]})
        } catch(e: any) {
            console.log(e)
        }
    }
}

export const deleteGuideCopyItem = (id: number): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            await axios.delete(`${GUIDE_COPY_URL}/${id}`)

            const { copy } = getStore()
            const { guideCopyItems } = copy
            const updated = guideCopyItems.filter((item: CopyItem) => item.id !== id)

            dispatch({ type: GUIDE_COPY_ITEMS, payload: updated })
        } catch(e: any) {
            console.log(e)
        }
    }
}