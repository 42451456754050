import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import { Page } from '../Page';
import { getCopyByName } from '../../actions/session';

export interface CopyPageProps {
    name: string
}

export const CopyPage: FunctionComponent<CopyPageProps> = ({ name }) => {
    const [copy, setCopy] = React.useState<string>("")

    React.useEffect(() => {
        const loadCopy = async() => {
            const copy = await getCopyByName(name);

            setCopy(copy)
        }

        loadCopy()
    }, [])
    

    return (
        <Page>
            <Box sx={{ marginTop: '5rem', width: '100%', font: FONT_STYLES_BY_TYPE.text }}>
                <div dangerouslySetInnerHTML={{ __html: copy }}></div>
            </Box>
        </Page>
    )
}
export default CopyPage