import {FC} from 'react';
import Box from '@mui/material/Box';
import EquitySectors from './EquitySectors';
import EquityRegions from './EquityRegions';
import PriceEarningsChart from './PriceEarningsChart';

interface BreakdownProps { }

const EquityBreakdown: FC<BreakdownProps> = ({ }) => {
  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
      <EquitySectors />
      <EquityRegions />
      <PriceEarningsChart />
    </Box>
  );
}

export default EquityBreakdown;