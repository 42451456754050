import { Box } from '@mui/material'
import React, { Component } from 'react'
import Input from '../common/Input'

interface CalculatorRowProps {
    stepTitle: string
    title: string
    value?: number
    bold?: boolean
    underlineTitle?: boolean
    underlineValue?: boolean
    readOnly?: boolean
    header?: boolean
    onChange?: (e: any, focusLost?: boolean) => void
}

export default class extends Component <CalculatorRowProps> {

    render() {
        const { stepTitle, title, value, bold, underlineTitle, underlineValue, readOnly, onChange, header } = this.props

        const textStyle = bold ?  { fontWeight: 'bold' } : { }
        const titleStyle = underlineTitle ? { textDecoration: 'underline' } : { }
        const valueStyle = underlineValue ? { textDecoration: 'underline' } : { }

        return (
            <Box style={{ display: 'flex', flex: 1, flexDirection: 'row', marginTop: '1rem' }}>
                <Box style={{ flex: 1, ...textStyle }}>
                    {stepTitle}
                </Box>
                <Box sx={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                    <Box component={'span'} sx={{ flex: 1, display: 'flex', ...textStyle, ...titleStyle }}>{title}</Box>
                    <Box component={'span'} sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', ...textStyle }}>
                        {readOnly && !header &&
                            <Box sx={{ width: '7rem', textAlign: 'start', ...valueStyle }} >
                                {value}
                            </Box>
                        
                        }
                        {!readOnly && !header &&
                            <Box sx={{ width: '7rem', display: 'flex', flexDirection: 'row' }} >
                                <Input
                                    sx={valueStyle} 
                                    type={'number'}
                                    max={100}
                                    accuracy={3}
                                    min={-100}
                                    step={.01}
                                    value={value}
                                    decimalPlaces={2}
                                    onChange={onChange}
                                />
                                <Box component={'span'} style={{ marginLeft: 5 }}>%</Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        )
    }
}