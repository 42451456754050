export enum TokenType {
    SINGLE_USE = 'single_use',
    QUESTIONNAIRE = 'questionnaire',
}

export enum AuthenticationErrorCodes {
    NEW_PASSWORD_REQUIREMENTS_ERROR_CODE = 10001,
    PASSWORD_VALIDATION_FAILED = 10002,
    PASSWORD_REUSED_LAST_TEN = 10003,
    PASSWORD_EXPIRED = 10004,
};

export const AUTH_CODES_REQUIRING_PASSWORD_UPDATE = [
    AuthenticationErrorCodes.NEW_PASSWORD_REQUIREMENTS_ERROR_CODE,
    AuthenticationErrorCodes.PASSWORD_VALIDATION_FAILED,
    AuthenticationErrorCodes.PASSWORD_REUSED_LAST_TEN,
    AuthenticationErrorCodes.PASSWORD_EXPIRED,
]

export const ERROR_CODE_MESSAGES: { [key: string]: string[] } = {
    [AuthenticationErrorCodes.NEW_PASSWORD_REQUIREMENTS_ERROR_CODE]: [
        `We've updated our password policy to enhance the security of your account. Your current password doesn't meet the new requirements and will need to be updated.`,
        '',
        `Our new policy requires that passwords:`,
        '',
        `\u25CF Have at least 12 characters`,
        `\u25CF Include at least one uppercase letter`,
        `\u25CF Include at least one lowercase letter`,
        `\u25CF Include at least one number`,
        `\u25CF Include at least one special character (such as @, $, !, %, *, #, ?, &)`,
    ],
    [AuthenticationErrorCodes.PASSWORD_EXPIRED]: ['Your current password has expired after 180 days.', 'Please create a new password to continue using your account securely.'],
}