import { Box } from '@mui/material'
import React, { FunctionComponent } from 'react'
import axios from 'axios'

import { OpenFolderImage } from '../../assets'
import { BaseProps } from '../types'
import Text from './Text'
import { ClipLoader } from 'react-spinners'



export interface FileUploadProps extends BaseProps {
    uploadEndpoint?: string
    parameterName?: string
    fileAccept?: string
    additionalUploadParameters?: {
        [key: string]: string | Blob
    }
    showProgress?: boolean
    showLoader?: boolean
    onFileSelected?: (file?: File) => void
    onUploadComplete?: (result: { response?: any, error?: any }) => void
}

interface FileUploadState {
    file?: File
    uploadProgress: number
    isUploading: boolean;
}

const FileUploadSection: FunctionComponent<BaseProps> = ({ children }) => {
    return (
        <Box sx={{ 
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        }}>
            {children}
        </Box>
    )
}


export class FileUpload extends React.Component<FileUploadProps, FileUploadState> {
    constructor(props) {
        super(props)
        this.state = {
            file: undefined,
            uploadProgress: 0,
            isUploading: false,
        }
    }

    public performUpload = async(): Promise<any> => {
        const { uploadEndpoint, parameterName } = this.props
        const { file } = this.state
        if(!file || !uploadEndpoint || !parameterName) {
            return
        }

        return this._performUpload()
    }

    private _performUpload = async(): Promise<any> => {
        const { uploadEndpoint, parameterName, onUploadComplete, additionalUploadParameters } = this.props
        if(!uploadEndpoint || !parameterName || !this.state.file) {
            return
        }

        const config = {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              this.setState({ uploadProgress: percentCompleted })
            }
          }
        

        const payload = new FormData()
        payload.append(parameterName, this.state.file)
        if(additionalUploadParameters) {
            const keys = Object.keys(additionalUploadParameters)
            for(const key of keys) {
                payload.append(key, additionalUploadParameters[key])
            }
        }
        try {
            this.setState({ isUploading: true })
            const { data } = await axios.post(uploadEndpoint, payload, config)
            if(onUploadComplete) {
                onUploadComplete({ response: data })
            }
            
            this.setState({ uploadProgress: 0, file: undefined })
        } catch(e: any) {
            this.setState({ isUploading: false })
            if(onUploadComplete) {
                onUploadComplete({ error: e })
            }
            this.setState({ uploadProgress: 0 })
        }
    }

    private onFileChanged = (e) => {
        if(e.target?.files?.length !== 1) {
            this.setState({ file: undefined })
            if(this.props.onFileSelected) {
                this.props.onFileSelected(undefined)
            }
            return
        }
        const file = e.target.files[0];
        this.setState({ file })
        if(this.props.onFileSelected) {
            this.props.onFileSelected(file)
        }
    }

    render() {
        const { fileAccept, children, sx, showLoader = false, showProgress = true } = this.props
        const { file, uploadProgress, isUploading } = this.state

        return (
            <Box 
                sx={{
                border: '2px #c3c3c3 dashed',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                position: 'relative',
                ...sx,
                '& input': {
                    fontSize: 0,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    opacity: 0,
                    width: '100%',
                    height: '100%',
                },
            }}>
                <input type="file" accept={fileAccept} onChange={this.onFileChanged}></input>
                {children}
                {!children && !file &&
                    <>
                        <FileUploadSection>
                            <OpenFolderImage />
                        </FileUploadSection>
                        <FileUploadSection>
                            <Text asDiv>Drag &amp; Drop Here</Text>
                            <Text asDiv sx={{ margin: '1.5rem' }}>or</Text>
                            <Text asDiv>
                                <button>Upload a File</button>
                            </Text>
                        </FileUploadSection>
                    </>
                }
                {!children && file &&
                    <FileUploadSection>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                textAlign: 'center',
                                border: '2px #c3c3c3 solid',
                                height: '15rem',
                                width: '20rem',
                                padding: '1rem',
                                borderRadius: '2rem',
                            }}
                        >
                            <Text type={'text_small'} sx={{ fontWeight: 'bold', marginBottom: '1rem' }} asDiv>{file?.name}</Text>
                            <Text type={'text_small'} asDiv>{file?.type}</Text>
                            {showLoader && <Box sx={{ marginTop: '1rem' }}><ClipLoader loading={isUploading} /></Box>}
                        </Box>
                        {showProgress && <progress value={uploadProgress} max="100"></progress>}
                    </FileUploadSection>
                }
            </Box>
        )
    }
}