import React, { Component } from 'react'
import RiskAnalyzerOptimizedPortfolio from './RiskAnalyzerOptimizedPortfolio'

export default class extends Component {

    render() {
        return (
            <RiskAnalyzerOptimizedPortfolio {...this.props} useSLR={true} riskModuleName={'Risk Preferences'} />
        )
    }
}