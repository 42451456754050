import { Component } from "react"
import { connect } from 'react-redux'
import { ReduxState } from "../../../../reducers"
import { Box } from "@mui/material"
import Chart from "../../../../components/common/Chart";
import { ChartOptions } from "../../../../common/types";
import { withWindowDimensions, WithWindowDimensionsProps } from "../../../../components/common/withWindowDimensions";

export interface Props extends WithWindowDimensionsProps {
    cumReturnVsBenchmarkChartData: ChartOptions;
    fundPerfRelativeToBenchmarkChartData: ChartOptions;
}
export interface State { }

class TotalReturnsVsBenchmark extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
    }

    render() {
        const { cumReturnVsBenchmarkChartData, fundPerfRelativeToBenchmarkChartData } = this.props
        return (
            <Box>
                <Chart
                    id="cumReturnVsBenchmarkChartData" 
                    title={'Cumulative Return vs. Benchmark'}
                    sx={{ 
                        width: { xs: this.props.dimensions.width - 100, lg: this.props.dimensions.width - 525} ,
                        height: '75rem',
                        backgroundColor: '#0000',
                        zIndex: 0,
                        position: 'relative',
                        border: (theme) => `.10rem ${theme.palette.primary.main} solid`,
                        mb: '5rem',
                    }}
                    chartOptions={cumReturnVsBenchmarkChartData}
                />
                <Chart
                    id="fundPerfRelativeToBenchmarkChartData" 
                    title={'Fund Performance Relative to Benchmark'}
                    sx={{ 
                        width: { xs: this.props.dimensions.width - 100, lg: this.props.dimensions.width - 525} ,
                        height: '75rem',
                        backgroundColor: '#0000',
                        zIndex: 0,
                        position: 'relative',
                        border: (theme) => `.10rem ${theme.palette.primary.main} solid`,
                    }}
                    chartOptions={fundPerfRelativeToBenchmarkChartData}
                />
            </Box>
        )
    }
}

const mapStateToProps = ({ performanceAttribution }: ReduxState ) => {
    const { cumReturnVsBenchmarkChartData, fundPerfRelativeToBenchmarkChartData } = performanceAttribution

    return { cumReturnVsBenchmarkChartData, fundPerfRelativeToBenchmarkChartData }
}

export default connect(mapStateToProps)(withWindowDimensions(TotalReturnsVsBenchmark) as any as  any)