import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import Text, { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import { Page } from '../Page';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const Contact: FunctionComponent = () => {
    return (
        <Page
        contentsSx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}
        >
            <Box sx={{ 
                    marginTop: '5rem', 
                    font: FONT_STYLES_BY_TYPE.text,
                    padding: '5rem',
                    backgroundColor: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                 }}>
                    <Text type='h3'>Questions? Suggestions? Inquiries?</Text>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <Text asDiv><a href={'mailto:info@portfoliodesigner.com'}>info@portfoliodesigner.com</a></Text>
            </Box>
        </Page>
    )
}
export default Contact