export const LEVEL_MAPPING = {
    gamma: {
        1.5: 'LOW',
        2: 'LOW',
        3: 'MED',
        4.5: 'MED',
        7: 'HIGH',
        12: 'HIGH',
    },
    lambda: {
        1: 'LOW',
        1.2: 'MED',
        1.5: 'MED',
        2: 'HIGH',
        3: 'HIGH',
    }
}

export const RANGES = {
    gamma: Object.keys(LEVEL_MAPPING.gamma).map(key => Number(key)).sort((a, b) => Number(a) - Number(b)),
    lambda: Object.keys(LEVEL_MAPPING.lambda).map(key => Number(key)).sort((a, b) => Number(a) - Number(b)),
    combined: [... new Set([...Object.keys(LEVEL_MAPPING.gamma), ...Object.keys(LEVEL_MAPPING.lambda)].map(key => Number(key)))] .sort((a, b) => Number(a) - Number(b)),
}

export const LEVEL_TO_VALUE_MAPPING = {
    gamma: {
        LOW: 1.75,
        MED: 3.75,
        HIGH: 9.5
    },
    lambda: {
        LOW: 1,
        MED: 1.2,
        HIGH: 2.5,
    },
}