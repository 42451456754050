export const NAV_LEFT_SECTION = 0
export const NAV_RIGHT_SECTION = 1
export const NAV_USER_SECTION = 2

export interface Route {
    title: string
    type: 'divider' | 'link'
    path: string
    placement: 0 | 1 | 2
    raw_link?: boolean
}