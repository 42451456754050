import React, { Component } from 'react'
import { connect } from 'react-redux'
import QuestionTable from './QuestionTable'
import Question, { QuestionSelection } from './Question'
import RiskPage, { BaseRiskPageProps } from './RiskPage'

interface LossAversionProps extends BaseRiskPageProps {
    annualIncome: number
    question1Value: number
    question2Value: number
    question3Value: number
    question4Value: number
    readOnly: boolean
    footer?: React.ReactNode
    onChoiceSelected: (selection: QuestionSelection) => void
}

interface LossAversionState {
    lossHeads1: number | string
    lossHeads2: number | string
    lossHeads3: number | string
    lossHeads4: number | string
    lossTails: number | string
}

class LossAversion extends Component<LossAversionProps, LossAversionState> {
    constructor(props) {
        super(props)
        this.state = {
            lossHeads1: 0,
            lossHeads2: 0,
            lossHeads3: 0,
            lossHeads4: 0,
            lossTails: 0,
        }
    }

    componentDidMount() {
        this.updateChoiceValues()
    }

    componentDidUpdate(prevProps) {
        const { annualIncome: prevAnnualIncome } = prevProps
        const { annualIncome } = this.props
        
        if(prevAnnualIncome !== annualIncome) {
            this.updateChoiceValues()
        }
    }

    updateChoiceValues = () => {
        const { annualIncome = 0 } = this.props

        const lossHeads1 = Math.round(annualIncome / 2000).toLocaleString();
        const lossHeads2 = Math.round(80 / 60 * annualIncome / 2000).toLocaleString();
        const lossHeads3 = Math.round( 100 / 60 * annualIncome / 2000).toLocaleString();
        const lossHeads4 = Math.round( 120 / 60 * annualIncome / 2000).toLocaleString();
        const lossTails = (2 * (Math.round(annualIncome / 2000))).toLocaleString();

        this.setState({ lossHeads1 , lossHeads2, lossHeads3, lossHeads4, lossTails })
    }

    getQuestion = (question: number) => {
        //@ts-ignore
        const { [`lossHeads${question}`]: lossHeads, lossTails } = this.state

        return <span>A fair coin is flipped. Heads you lose $<span>{lossHeads}</span>, tails you win $<span>{lossTails}</span></span>
    }

    render() {
        const { question1Value, question2Value, question3Value, question4Value, readOnly, onComponentSize } = this.props

        return (
            <RiskPage readOnly={readOnly} footer={this.props.footer} onComponentSize={onComponentSize}>
                <QuestionTable title='Loss Aversion'>
                    <Question questionNumber={1} question={this.getQuestion(1)} choiceAText={'Accept'} choiceBText={'Reject'} selectedChoice={question1Value} onChoiceSelected={this.props.onChoiceSelected} />
                    <Question questionNumber={2} question={this.getQuestion(2)} choiceAText={'Accept'} choiceBText={'Reject'} selectedChoice={question2Value} onChoiceSelected={this.props.onChoiceSelected} />
                    <Question questionNumber={3} question={this.getQuestion(3)} choiceAText={'Accept'} choiceBText={'Reject'} selectedChoice={question3Value} onChoiceSelected={this.props.onChoiceSelected} />
                    <Question questionNumber={4} question={this.getQuestion(4)} choiceAText={'Accept'} choiceBText={'Reject'} selectedChoice={question4Value} onChoiceSelected={this.props.onChoiceSelected} />
                </QuestionTable>
            </RiskPage>
        )
    }

}

const mapStateToProps = ({ riskPreferences }) => {
    const { lossAversion, annualIncome } = riskPreferences
    
    return {
        ...lossAversion,
        annualIncome,
    }
}

export default connect(mapStateToProps)(LossAversion)