import { RISK_AVERSION, LOSS_AVERSION } from "./types/actions"
import { LossAversionQuestions, RiskAversionQuestions, RiskPreferenceQuestions, RiskPreferenceQuestionTypes } from "./types/riskPreferences";

export const calculateGammaMeasured = ({ question1Value = 0, question2Value = 0, question3Value = 0, question4Value = 0, question5Value = 0 }: RiskAversionQuestions): number => {
    let gammaMeasured = 12
    if(question1Value == 0) {
        gammaMeasured = 1.5;
    } else if (question2Value == 0) {
        gammaMeasured = 2;
    } else if (question3Value == 0 ) {
        gammaMeasured = 3;
    } else if (question4Value == 0) {
        gammaMeasured = 4.5;
    } else if (question5Value == 0) {
        gammaMeasured = 7;
    }

    return gammaMeasured
}

export const calculateLambdaMeasured = ({ question1Value = 0, question2Value = 0, question3Value = 0, question4Value = 0 }: LossAversionQuestions): number => {
    
    var lambdaMeasured = 1.0

    if(question1Value == 0) {
        lambdaMeasured = 3;
    } else if (question2Value == 0) {
        lambdaMeasured = 2;
    } else if (question3Value == 0 ) {
        lambdaMeasured = 1.5;
    } else if (question4Value == 0) {
        lambdaMeasured = 1.2;
    }

    return lambdaMeasured
}

export const calculateLambdaModerated = (standardLivingRisk: string | number, lambdaMeasured: number): number => {
    var intStandardLivingRisk = parseInt(standardLivingRisk?.toString())
    var by100intStandardLivingRisk = intStandardLivingRisk ? intStandardLivingRisk / 100 : 0
    var lambdaModerated = Math.max(lambdaMeasured,1+2*by100intStandardLivingRisk)
        lambdaModerated = lambdaSnapToValue(lambdaModerated)
        
    return lambdaModerated
}

export const calculateGammaModerated = (standardLivingRisk: string | number, gammaMeasured: number): number => {
    const by100intStandardLivingRisk = standardLivingRisk ? parseInt(standardLivingRisk?.toString()) / 100 : 0
    let gammaModerated = Math.max(gammaMeasured, 1.5 + 10.5 * by100intStandardLivingRisk)
        gammaModerated = gammaSnapToValue(gammaModerated)

    return gammaModerated
}

export const lambdaSnapToValue = (lambdaValue: number | string): number => {
    var parsedLambdaValue = parseFloat(lambdaValue?.toString())
    
    if(parsedLambdaValue < 1.2) {
        return 1
    }
    if(parsedLambdaValue >= 1.2 && parsedLambdaValue < 1.5) {
        return 1.2
    }
    if(parsedLambdaValue >= 1.5 && parsedLambdaValue < 2.0) {
        return 1.5
    }
    if(parsedLambdaValue >= 2.0 && parsedLambdaValue < 3.0) {
        return 2.0
    }

    return 3.0
}

export const gammaSnapToValue = (gammaValue: number | string): number => {
    let gammaSnapped = parseFloat(gammaValue?.toString())
    const parsedGammaValue: any = gammaSnapped.toFixed(1)
    
    if(parsedGammaValue <= 1.5) {
        gammaSnapped = 1.5;
    } 
    if (parsedGammaValue >= 1.5 && parsedGammaValue < 3) {
        gammaSnapped = 2;
    } 
    if (parsedGammaValue >= 3 && parsedGammaValue < 4.5 ) {
        gammaSnapped = 3;
    }
    if (parsedGammaValue >= 4.5 && parsedGammaValue < 7) {
        gammaSnapped = 4.5;
    }
    if (parsedGammaValue >= 7 && parsedGammaValue < 12) {
        gammaSnapped = 7;
    }
    if (parsedGammaValue >= 12 ) {
        gammaSnapped = 12;
    }

    return gammaSnapped
}

const CALCULATION_FUNCTION_MAPPING = {
    [RISK_AVERSION] : calculateGammaMeasured,
    [LOSS_AVERSION] : calculateLambdaMeasured,
}

const MODERATED_CALCULATION_FUNCTION_MAPPING = {
    [RISK_AVERSION] : calculateGammaModerated,
    [LOSS_AVERSION] : calculateLambdaModerated,
}

const MEASURED_VALUE_KEY_MAPPING = {
    [RISK_AVERSION] : 'riskAversion',
    [LOSS_AVERSION] : 'lossAversion',
}

export const MEASURED_KEY_TYPE_MAPPING = {
    [RISK_AVERSION] : 'gamma',
    [LOSS_AVERSION] : 'lambda',
}

export const calculateMeasured = (type: string, riskPreferences: RiskPreferenceQuestions) => {
    const calculationFunction = CALCULATION_FUNCTION_MAPPING[type]
    const valuesKey: RiskPreferenceQuestionTypes = MEASURED_VALUE_KEY_MAPPING[type]

    const values = riskPreferences[valuesKey]

    return calculationFunction(values)
}

export const calculateModerated = (type: string, measuredValue: number, standardLivingRisk : number): number => {
    const calculationFunction = MODERATED_CALCULATION_FUNCTION_MAPPING[type]

    return calculationFunction(standardLivingRisk, measuredValue)
}