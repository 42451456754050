import React, { FunctionComponent } from 'react'
import ResultGague, { BasicResultGaugeProps } from './ResultGauge'
import svg from './assets/LossAversionGauge.svg'

export const RESULT_MAP = {
    1: "LOW",
    1.2: "MED",
    1.5: "MED",
    2: "HIGH",
    3: "HIGH" 
}
const LossAversionGauge: FunctionComponent<BasicResultGaugeProps> = ({ type, value = 0, style, className, titleGlossaryTerm }) => {
    return <ResultGague
                className={className}
                svg={svg}
                style={style}
                type={type}
                title={`Loss Aversion`}
                value={RESULT_MAP[value]}
                titleGlossaryTerm={titleGlossaryTerm}
                leftLabel={'HIGH COMFORT WITH DRAWDOWNS'}
                rightLabel={'LOW COMFORT WITH DRAWDOWNS'}
                rightLabelOffset={300}
                showNeedleValue={false}
            />
}

export default LossAversionGauge
