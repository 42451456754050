import { LOGOUT, MARKET_DASHBOARD_CHARTS, HISTORICAL_CURVE_CHART, HISTORICAL_TIME_SERIES_CHART, PERFORMANCE_DATA } from '../actions/types/actions';
import { ReduxAction } from '.';
import { ChartOptions } from 'highcharts';
import { HistoricalSeries, Performance, PerformanceDates } from '../actions/types/marketDashboard';
import { HistoricalCurve } from '../actions/types/marketDashboard';

export interface MarketDashboardState {
  loadingCharts: boolean;
  loadingHistoricalCurve: boolean;
  loadingHistoricalTime: boolean;
  loadingPerformanceData: boolean;
  historicalCurveChart: ChartOptions;
  historicalSeriesChart?: ChartOptions,
  historicalCurveData: HistoricalCurve[];
  historicalSeriesData: HistoricalSeries[];
  historicalCurveLastTradeTime: string;
  historicalSeriesLastTradeTime: string;
  performanceData: Performance[];
  performanceDates: PerformanceDates[];
}

const INITIAL_STATE: MarketDashboardState = {
  loadingCharts: true,
  loadingHistoricalCurve: true,
  loadingHistoricalTime: true,
  loadingPerformanceData: true,
  historicalCurveChart: {},
  historicalSeriesChart: {},
  historicalCurveData: [],
  historicalSeriesData: [],
  performanceData: [],
  historicalCurveLastTradeTime: '',
  historicalSeriesLastTradeTime: '',
  performanceDates: []
};

export default (state = INITIAL_STATE, action: ReduxAction): MarketDashboardState => {
  const { payload, type } = action

  switch (type) {
    case MARKET_DASHBOARD_CHARTS:
      return {
        ...state,
        loadingCharts: false,
      }
    case HISTORICAL_CURVE_CHART:
      return {
        ...state,
        historicalCurveChart: payload.historicalCurveChart,
        historicalCurveData: payload.historicalCurveData,
        historicalCurveLastTradeTime: payload.historicalCurveLastTradeTime,
        loadingHistoricalCurve: false,
      }
    case HISTORICAL_TIME_SERIES_CHART:
      return {
        ...state,
        historicalSeriesChart: payload.historicalSeriesChart,
        historicalSeriesData: payload.historicalSeriesData,
        historicalSeriesLastTradeTime: payload.historicalSeriesLastTradeTime,
        loadingHistoricalTime: false,
      }
    case PERFORMANCE_DATA:
      return {
        ...state,
        performanceData: payload.performanceData,
        performanceDates: payload.performanceDates,
        loadingPerformanceData: false,
      }
    case LOGOUT:
      return {...INITIAL_STATE};
    default:
        return state;
  }
};

