import { Component } from 'react'



import { TickerAllocation, SnapshotItems, MetricDataMapped } from '../../actions/types/portfolioDesigner';
import TickerInput, { TickerInputProps } from './TickerInput';
import { connect } from 'react-redux';
import { addError } from '../../actions/notifications';
import TickerToolTipContainer from './TickerToolTipContainer';

interface ComponentProps extends TickerInputProps {
    onTickerInputRef: (view: any) => void
    showMCTR: boolean
    snapshots: SnapshotItems
    snapshotLoading: boolean
    snapshotTargetItem: TickerAllocation
    snapshotTargetItemUpdateType: 'weight' | 'ticker'
    metrics: MetricDataMapped
}

class TooltippedTickerInput extends Component<ComponentProps> {
    render() {
        const { showMCTR, snapshots, snapshotLoading, snapshotTargetItem, snapshotTargetItemUpdateType, metrics } = this.props
        const { containerSx, value, placeholder, onFocusNext, onTickerSelected } = this.props

        return (
            <TickerToolTipContainer
                    key={value?.id}
                    sx={{ flex: 1 }}
                    ticker={value}
                    snapshots={snapshots}
                    metrics={metrics}
                    snapshotLoading={snapshotLoading}
                    snapshotTargetItem={snapshotTargetItem}
                    snapshotTargetItemUpdateType={snapshotTargetItemUpdateType}
                    showMCTR={showMCTR}
                >
                <TickerInput 
                    ref={this.props.onTickerInputRef}
                    containerSx={containerSx}
                    value={value}
                    placeholder={placeholder}
                    onFocusNext={onFocusNext}
                    onTickerSelected={onTickerSelected}
                />
            </TickerToolTipContainer>
        )
    }

}
export default TooltippedTickerInput;
