import React, { Component } from 'react'
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import moment from 'moment';


import { AnalyzeResultsDTO, HistoricalAnalysisResultsDTO, ReportResourceItem } from '../../actions/types/portfolioDesigner';
import { addError } from '../../actions/notifications';
import { ResultTable } from './ResultTable';
import { ReduxState } from '../../reducers';
import { DatePicker } from '../common/styled/DatePicker';
import { ClipLoader } from 'react-spinners';

interface State {
    runningHistoricalAnalysis: boolean;
}

interface ComponentProps {
    item: ReportResourceItem;
}

interface ReduxProps {
    historicalAnalysisCustomRows: any;
    updatedAnalysis?: HistoricalAnalysisResultsDTO;
    results?: AnalyzeResultsDTO;
    backTestingStart?: Date;
    backTestingEnd?: Date;
    customRangeStart?: Date
    customRangeEnd?: Date
}

interface ReduxActionProps {
    addError: (title: string, message: string) => void;
}

type Props = ComponentProps & ReduxProps & ReduxActionProps;

class HistoricalScenarioAnalysis extends Component<Props, State> {
    state = {
        runningHistoricalAnalysis: false,
    }

    render() {
        const { item, historicalAnalysisCustomRows } = this.props;
        if(item.type !== 'table' || item.name !== 'Historical Scenario Analysis') {
            return null;
        }

        return (
            <ResultTable
                key={item.name}
                tableSx={{ height: '31.75rem', width: '55rem' }}
                containerSx={{
                    marginTop: {
                        xs: '1rem',
                        md: undefined, 
                    },
                    marginRight: {
                        xs: undefined,
                        md: '1rem',
                    }
                }}
                results={item}
                customRows={historicalAnalysisCustomRows}
                excludeKeys={['Custom Range']}
            />
        )
    }
}
const maptStateToProps = ({portfolioDesigner}: ReduxState): ReduxProps => {
    const {historicalAnalysisCustomRows, updatedAnalysis, backTestingStart, backTestingEnd, customRangeStart, customRangeEnd, results} = portfolioDesigner;

    return {historicalAnalysisCustomRows, updatedAnalysis, backTestingStart, backTestingEnd, customRangeStart, customRangeEnd, results};
}
export default connect<ReduxProps, ReduxActionProps, ComponentProps, ReduxState>(maptStateToProps, { addError })(HistoricalScenarioAnalysis);
