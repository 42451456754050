import React from 'react';
import { Box } from '@mui/material';
import HistoricalSeries from './HistoricalSeries';
import HistoricalCurve from './HistoricalCurve';
import PerformanceChart from './PerformanceChart';

interface ReduxStateProps { }
interface ReduxActionProps { }
interface ComponentState { }

type ComponentProps = ReduxActionProps & ReduxStateProps

class MarketDashboard extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = { }
    render() {
        return (
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row'}, marginRight: '5rem' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, marginRight: '5rem', width: { md: '100%', lg: '80rem' }}}>
                    <PerformanceChart />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: { xs: '5rem', lg: '0rem' }}}>
                    <Box>
                        <HistoricalCurve />
                    </Box>
                    <Box>
                        <HistoricalSeries />
                    </Box>
                </Box>
            </Box>
        )
    }
}



export default MarketDashboard