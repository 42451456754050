import React, { FunctionComponent } from 'react'

import RiskAversionGauge from '../Gauge/RiskAversionGauge'
import LossAversionGauge from '../Gauge/LossAversionGauge'

import { DISCLAIMER_TEXT } from '../RiskPreferences/common';

import Chart from '../common/Chart';
import Text from '../common/styled/Text';
import { Box } from '@mui/material';
import { LooseObject } from '../../common/types';
import GaugeContainer from '../Gauge/GaugeContainer';

interface RiskAnalyzerChartsProps {
    gamma: number
    lambda: number
    gaugeType?: string
    riskAversionRiskLevelsChart: LooseObject
    lossAversionRiskLevelsChart: LooseObject
    hideRiskLevelChart?: boolean
}

export const RiskAnalyzerCharts: FunctionComponent<RiskAnalyzerChartsProps> = ({ gamma, lambda, gaugeType, hideRiskLevelChart, riskAversionRiskLevelsChart, lossAversionRiskLevelsChart }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '-50px',
            marginTop: '-150px',
            marginBottom: '-150px',
            transform: 'scale(.75)',
        }}>
            <GaugeContainer>
                <RiskAversionGauge value={gamma} type={gaugeType} />
                {!hideRiskLevelChart && <Chart id="riskAversionRiskLevelsChart" sx={{ height: '30rem', width: '30rem', marginLeft: '5rem' }} chartOptions={riskAversionRiskLevelsChart} />}
            </GaugeContainer>
            <GaugeContainer>
                <LossAversionGauge value={lambda} type={gaugeType} />
                {!hideRiskLevelChart && <Chart id="lossAversionRiskLevelsChart" sx={{ height: '30rem', width: '30rem', marginLeft: '5rem' }} chartOptions={lossAversionRiskLevelsChart} />}
            </GaugeContainer>
            {!hideRiskLevelChart &&
                <GaugeContainer sx={{ justifyContent: 'flex-end'}}>
                    <Box sx={{ width: '40rem', marginRight: '-10rem' }}>
                        <Text>{DISCLAIMER_TEXT}</Text>
                    </Box>
                </GaugeContainer>
            }
        </Box>
    )
}

export default RiskAnalyzerCharts