import {FC} from 'react';
import Box from '@mui/material/Box';
import AssetClassTable from '../FixedIncomeBreakdown/AssetClassTable';
import SnapshotTable from './SnapshotTable';
import Text from '../../../../../components/common/Text';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { ReduxState } from '../../../../../reducers';

interface SummaryProps {
  missingPrices?: string[]
}

const Summary: FC<SummaryProps> = ({ missingPrices }) => {

  return (
    <Box>
      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
        <SnapshotTable />
        <AssetClassTable />
      </Box>
      {missingPrices && missingPrices.length > 0 &&
          <Box sx={{ marginTop: '1rem' }}>
              <FontAwesomeIcon size={'2x'} icon={faWarning} color={'#eed202'} /> <Text sx={{ marginLeft: '.5rem' }}>Please note the following tickers had insufficient data for 1 year calculations: {missingPrices.join(', ')}</Text>
          </Box>
      }
    </Box>
  );
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState) => {
  const { snapshotMissingPrices } = portfolioDesigner

  return { missingPrices: snapshotMissingPrices }
}

export default connect(mapStateToProps)(Summary);