import React, { FunctionComponent, ImgHTMLAttributes } from 'react'
import { Box, SxProps, Theme } from '@mui/material';

export interface ImageProps extends Omit<ImgHTMLAttributes<any>, 'style'> {
    sx?: SxProps<Theme>;
    webp?: string;
}

export const StandardImage: FunctionComponent<ImageProps> = ({ sx = {}, webp, ...props}) => {
    return (
        <Box
            sx={{
                '& img': {
                    ...sx
                },
                ...sx,
            }}>
            {!webp && <img {...props} />}
            {webp && <picture>
                <source type="image/webp" srcSet={webp}></source>
                <img {...props} />
            </picture>}
        </Box>  
    )
}