import React, {FC} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabPanel } from '../../common/TabPanel';
import Text from '../../common/Text';
import CorrelationMatrix from './BackTesting';
import PortfolioAnalysis from './PortfolioAnalysis';
import BackTesting from './BackTesting';


const getTabTitleProps = (index: number, title: string) => {
  return {
    id: `tab-${index}`,
    index,
    label: <Text>{title}</Text>,
    'aria-controls': `tabpanel-${index}`,
  };
}

interface PortfolioDesignerTabsProps { }

const PortfolioDesignerTabs: FC<PortfolioDesignerTabsProps> = ({ }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="">
          <Tab {...getTabTitleProps(0, 'Portfolio Analysis')} />
          <Tab {...getTabTitleProps(1, 'Backtesting')} />
        </Tabs>
      </Box>
      <TabPanel sx={{ minHeight: '50rem' }} value={value} index={0}>
        <PortfolioAnalysis />
      </TabPanel>
      <TabPanel sx={{ minHeight: '100rem' }} value={value} index={1}>
        <BackTesting />
      </TabPanel>
    </Box>
  );
}

export default PortfolioDesignerTabs;