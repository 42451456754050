import React, { FunctionComponent, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Page } from '../Page';
import { Box } from '@mui/material';
import { BaseProps } from '../types';
import { Panel } from '../common/Panel';
import { Button } from '../common/Button';
import { TextFieldItem } from '../common/FormField';
import { resetPassword as resetPasswordAction } from '../../actions/authentication';
import ErrorAlert from '../common/ErrorAlert';
import SuccessAlert from '../common/SuccessAlert';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../common/hooks/useAuth';

interface Props extends BaseProps {
    resetPassword: (email: string, password: string, confirmPassword: string) => Promise<any>
}

const ResetPassword: FunctionComponent<Props> = ({ resetPassword }) => {
    const navigate = useNavigate()
    const { setAuthentication } = useAuth()
    const [errors, setErrors] = useState<string[]>([])
    const [message, setMessage] = useState<string>()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const hasErrors = errors?.length > 0
    const hasMessage = !!message

    const onClearErrors = useCallback(() => {
        setErrors([])
    }, [])

    const onClearMessage = useCallback(() => {
        setMessage('')
    }, [])
    
    const onClearAllMessage = useCallback(() => {
        onClearErrors()
        onClearMessage()
    }, [])

    const onResetPassword = useCallback(async() => {
        onClearAllMessage()
        setLoading(true)
        const { success, authorization, errors, path } = await resetPassword(email, password, confirmPassword)
        setLoading(false)
        if (success) {
            setAuthentication({ auth: authorization })
            setMessage('Password succesfully reset!')
            setTimeout(() => {
                navigate(path ?? '/dashboard')
            }, 2500);
        } else if(!success && errors) {
            setErrors(errors)
        } else if(!success) {
            setErrors(['Password reset was not successful, please try again'])
        }
        
    }, [onClearAllMessage, email, password, confirmPassword]);

    return (
        <Page 
            contentsSx={{ 
                justifyContent: 'center',
                marginTop: '10rem',
            }}>
            <Panel
                sx={{ width: '85rem' }}
                title={'Reset Password'}>
                <ErrorAlert
                    sx={{ margin: '1rem' }}
                    visible={hasErrors}
                    wrapChildrenInArray={false}
                    onDismiss={onClearErrors}>
                        <Box sx={{ display: 'flex', flexDirection: 'column '}}>
                            {Array.isArray(errors) && errors.map((error: string) => {
                                return <Box>{error}</Box>
                            })}
                             {!Array.isArray(errors) && errors}
                        </Box>
                </ErrorAlert>
                <SuccessAlert
                    sx={{ margin: '1rem' }}
                    visible={hasMessage}
                    onDismiss={onClearMessage}>
                        <Box sx={{ display: 'flex', flexDirection: 'column '}}>
                            {message}
                        </Box>
                </SuccessAlert>
                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '2rem', alignItems: 'center' }}>
                        <Box>
                            <TextFieldItem
                                sx={{ 
                                    width: {
                                        xs: '100%',
                                        md: '35rem'
                                    }
                                }}
                                type={'email'}
                                title={'Email Address'}
                                value={email}
                                onValueChange={setEmail}
                                inline
                            />
                            <TextFieldItem
                                sx={{ 
                                    width: {
                                        xs: '100%',
                                        md: '35rem'
                                    }
                                }}
                                type={'password'}
                                title={'New Password'}
                                value={password}
                                onValueChange={setPassword}
                                inline
                            />
                            <TextFieldItem
                                sx={{ 
                                    width: {
                                        xs: '100%',
                                        md: '35rem'
                                    }
                                }}
                                type={'password'}
                                title={'Confirm New Password'}
                                value={confirmPassword}
                                onValueChange={setConfirmPassword}
                                inline
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2rem'}}>
                                <Button 
                                    title={'Reset Password'} 
                                    onClick={onResetPassword}
                                    loading={loading}
                                    inlineLoading
                                />
                            </Box>
                        </Box>
                    </Box>
            </Panel>
        </Page>
    )
}

export default connect(null, { resetPassword: resetPasswordAction })(ResetPassword)