import { SelectOption, TableRowData } from '../common/types';
import {
    PROFILE_RESET,
    LOGOUT,
    PORTFOLIO_UPDATED,
    REBALANCING_FREQUENCY_UPDATED,
    CONFIGS_UPDATED,
    MODELS_UPDATED,
    ANALYZE_RESET,
    ANALYZE_UPDATE,
} from '../actions/types/actions';
import { TickerAllocation, DEFAULT_OPTION, AnalyzeResultsDTO, ResultsTableData, SnapshotItems, ModelItemOption, HistoricalAnalysisResultsDTO, ResultViewKeys, MetricDataMapped, ActionStatus, ReportResourceItem, GeneralResultTableData, SavedReport  } from '../actions/types/portfolioDesigner';
import { getStartingTickerList } from '../actions/portfolioDesigner';
import moment from "moment"
import { ChartOptions } from '../components/OptimizedPortfolio/chartOptions';
export interface PortfolioDesignerState {
    benchmarkConfigs: SelectOption[];
    benchmarkModels: ModelItemOption[];
    portfolioConfigs: SelectOption[];
    portfolioModels: ModelItemOption[];
    financial_plan_needsModels: SelectOption[];
    benchmarkPortfolio: TickerAllocation[];
    portfolioPortfolio: TickerAllocation[];
    benchmarkRebalancingFrequency: SelectOption | null;
    portfolioRebalancingFrequency: SelectOption | null;
    results?: AnalyzeResultsDTO;
    backTestingStart?: Date;
    backTestingEnd?: Date;
    customRangeStart?: Date
    customRangeEnd?: Date
    keyStatistics: any[];
    allHistoricalEvents: any[];
    dates: string[];
    minDate: Date;
    maxDate: Date;
    snapshot: ResultsTableData
    asset_class: ResultsTableData
    fixed_income_credit_quality: ResultsTableData
    fixed_income_sectors: ResultsTableData
    fixed_income_maturity: ResultsTableData
    equity_sectors: ResultsTableData
    equity_regions: ResultsTableData
    benchmarkSnapshots: SnapshotItems
    portfolioSnapshots: SnapshotItems
    benchmarkSnapshotLoading: boolean
    portfolioSnapshotLoading: boolean
    snapshotMissingPrices?: string[]
    backtestMissingPrices?: string[]
    missingPrices?: string[]
    resultViewKeys: ResultViewKeys
    selectedResultViewKeys: string[]
    updatedAnalysis?: HistoricalAnalysisResultsDTO
    historicalAnalysisCustomRows: any[]
    snapshotCorrelationMatrixChart: ChartOptions;
    analyzerCorrelationMatrixChart: ChartOptions;
    priceOverEarningsChart: ChartOptions;
    metrics: MetricDataMapped


    requestId?: string
    excelUrl?: string;
    reportUrl?: string;

    runningEfficencyFrontier: boolean
    runningAnalyzer: boolean
    status?: ActionStatus
    runningHistoricalAnalysis: boolean
    reportItems: ReportResourceItem[]

    totalAttributionTable?: GeneralResultTableData
    assetAllocationTable?: GeneralResultTableData
    securitySelectionTable?: GeneralResultTableData

    effChart: any
    effTable: {
        columns: string[],
        rows: TableRowData[],
    }
    originalEffTable: {
        columns: string[],
        rows: TableRowData[],
    }
    clickedMarkers: string[]
    plotMarkerData: any
    portfolioOptimizationSeries: {
        [key: string]: any;
    }
    portfolioOptimizationTable: { [key: string]: { [key: string]: number }}
    savedReports: SelectOption<SavedReport>[]
}
const minDate = moment('2008-01-01').toDate()
const maxDate = moment().subtract(1, "day").toDate();

const RESETTABLE_STATE = {
    benchmarkPortfolio: getStartingTickerList(),
    portfolioPortfolio: getStartingTickerList(),
    benchmarkRebalancingFrequency: {...DEFAULT_OPTION},
    portfolioRebalancingFrequency: {...DEFAULT_OPTION},
    keyStatistics: [],
    allHistoricalEvents: [],
    backTestingStart: minDate,
    backTestingEnd: maxDate,
    customRangeStart: undefined,
    customRangeEnd: undefined,
    dates: [],
    snapshot: {
        name: 'Statistics',
        category: 'table',
        data: {
            Benchmark: {
                "1Yr Equity Beta": "--",
                "1Yr Volatility": "--",
                "Distribution Yield": "--",
                "Effective Duration": "--",
                "Expense Ratio": "--",
            },
            Strategy: {
                "1Yr Equity Beta": "--",
                "1Yr Volatility": "--",
                "Distribution Yield": "--",
                "Effective Duration": "--",
                "Expense Ratio": "--",
            },
        }
    },
    fixed_income_maturity: {
        name: 'Maturity',
        category: 'table',
        data: {
            Benchmark: { },
            Strategy: { },
        },
    },
    fixed_income_credit_quality: {
        name: 'Credit Quality',
        category: 'table',
        data: {
            Benchmark: { },
            Strategy: { },
        },
    },
    fixed_income_sectors: {
        name: 'Sectors',
        category: 'table',
        data: {
            Benchmark: { },
            Strategy: { },
        },
    },
    asset_class: {
        name: 'Asset Classes',
        category: 'table',
        data: {
            Benchmark: { },
            Strategy: { },
        },
    },
    equity_sectors: {
        name: 'Sectors',
        category: 'table',
        data: {
            Benchmark: { },
            Strategy: { },
        },
    },
    equity_regions: {
        name: 'Regions',
        category: 'table',
        data: {
            Benchmark: { },
            Strategy: { },
        },
    },
    snapshots: {},
    benchmarkSnapshots: {},
    portfolioSnapshots: {},
    benchmarkSnapshotLoading: false,
    portfolioSnapshotLoading: false,
    missingPrices: undefined,
    metrics: {} as MetricDataMapped,
    runningAnalyzer: false,
    runningHistoricalAnalysis: false,
    reportItems: [],
    clickedMarkers: [],
    savedReports: [],
}

const INITIAL_STATE: PortfolioDesignerState = {
    benchmarkConfigs: [],
    benchmarkModels: [],
    portfolioConfigs: [],
    portfolioModels: [],
    financial_plan_needsModels: [],
    minDate,
    maxDate,
    ...RESETTABLE_STATE,
};

export default (state = INITIAL_STATE, action: any): PortfolioDesignerState => {
  const { payload, type } = action

  switch (type) {
    case PORTFOLIO_UPDATED:
        return { ...state, [`${payload.type}Portfolio`]: payload.portfolio }
    case REBALANCING_FREQUENCY_UPDATED:
        return { ...state, [`${payload.type}RebalancingFrequency`]: payload.rebalancingFrequency }
    case CONFIGS_UPDATED:
        return { ...state, [`${payload.type}Configs`]: payload.configs }
    case MODELS_UPDATED:
        return { ...state, [`${payload.type}Models`]: payload.models }
    case ANALYZE_UPDATE:
        return { ...state, ...payload }
    case ANALYZE_RESET:
        return { ...state, ...RESETTABLE_STATE }
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

