import axios from 'axios'
import queryString from 'query-string';

import { getPathsClientId, sleep } from '../common/utils';
import { StandardAction, StandardThunkAction } from '../store/store';
import { calculateMeasured, calculateModerated, MEASURED_KEY_TYPE_MAPPING } from './measuredCalculations'
import { addError } from './notifications';
import { updateMeasuredParameter } from './riskPreferences';
import { onPageChange, updateProfile } from './session';
import { ANNUAL_INCOME, RISK_AVERSION, LOSS_AVERSION,
         RISK_PREFERENCES_LOADING, STANDARD_LIVING_RISK,
         RISK_PREFERENCE_PAGE_INDEX,
         USER_LOADED,
         SESSION} from "./types/actions"
import { UpdateQuestionRequest, UpdateRiskPreferenceRequest } from './types/requests';

export const VALID_GAUGE_TYPES = ['riskAversion', 'lossAversion']

export const CLIENTS_ENDPOINT = '/api/clients'
export const PREFERENCES_ENDPOINT = '/api/preferences'

export const clientRiskPreferencesOnLoad = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const { token } = queryString.parse(window.location.search)

            const { data } = await axios.get(`/api/clients/questionnaire/details?token=${token}`)
            const { profile_id, first_name, last_name, annual_income } = data
            
            dispatch(onPageChange('clientQuestionnaire', 0));
            dispatch(updateProfile({ profile_id, first_name, last_name, annual_income }))
            dispatch({ type: USER_LOADED, payload: { views: ['client_questionnaire'], enterprise: true, defaultKey: 'clientQuestionnaire', selectedKey: 'clientQuestionnaire' } })
            dispatch({ type: SESSION, payload: { defaultKey: 'clientQuestionnaire', selectedKey: 'clientQuestionnaire'}})
        }catch(e: any) {
        }
    }
}

export const annualIncomeUpdate = (payload: string): StandardAction => {
    return { type: ANNUAL_INCOME, payload }
}

export const standardLivingRiskUpdate = (payload: string): StandardAction => {
    return { type: STANDARD_LIVING_RISK, payload: !isNaN(Number(payload)) ? parseFloat(payload) : 0.0 }
}

export const updateRiskPreferencesAnswer = ({ type, questionNumber, value }: UpdateRiskPreferenceRequest): StandardThunkAction => {
    return async(dispatch, getState) => {
        dispatch({ type, payload: { questionNumber, value} })

        const { riskPreferences } = getState()

        const measured = calculateMeasured(type, riskPreferences)

        dispatch(updateMeasuredParameter({ type: MEASURED_KEY_TYPE_MAPPING[type], value: measured }))
    }
}

export const riskAversionQuestionUpdate = ({ questionNumber, value }: UpdateQuestionRequest): StandardThunkAction => {
    return updateRiskPreferencesAnswer({ type: RISK_AVERSION, questionNumber, value })
}

export const lossAversionQuestionUpdate = ({ questionNumber, value }: UpdateQuestionRequest): StandardThunkAction => {
    return updateRiskPreferencesAnswer({ type: LOSS_AVERSION, questionNumber, value })
}

export const riskPreferencePage = (index: number): StandardAction => {
    return { type: RISK_PREFERENCE_PAGE_INDEX, payload: index }
}

export const submitClientRiskPreferences = (skipRedirect?: boolean): StandardThunkAction => {
    return async(dispatch, getState) => {
        try {
            dispatch({ type: RISK_PREFERENCES_LOADING, payload: true })
            const { riskPreferences } = getState()

            const { 
                    lossAversion: { question1Value: loss_q1, question2Value: loss_q2, question3Value: loss_q3, question4Value: loss_q4 },
                    riskAversion: { question1Value: risk_willing_q1, question2Value: risk_willing_q2, question3Value: risk_willing_q3, question4Value: risk_willing_q4, question5Value: risk_willing_q5 },
                    standardLivingRisk,
                } = riskPreferences
            const combinedAnswers = {
                loss_q1,
                loss_q2,
                loss_q3,
                loss_q4,
                risk_willing_q1,
                risk_willing_q2,
                risk_willing_q3,
                risk_willing_q4,
                risk_willing_q5,
            }

            const unansweredQuestion = Object.keys(combinedAnswers).filter(key => combinedAnswers[key] === undefined)
            if(unansweredQuestion.length > 0) {
                alert('Please answer all the questions before submitting.')

                dispatch({ type: RISK_PREFERENCES_LOADING, payload: false })
                dispatch(onPageChange('clientQuestionnaire', 0))
                return
            }
            dispatch(onPageChange('clientQuestionnaire', 2))

            const gamma_measured = calculateMeasured(RISK_AVERSION, riskPreferences)
            const lambda_measured = calculateMeasured(LOSS_AVERSION, riskPreferences)

            const gamma_moderated = calculateModerated(RISK_AVERSION, gamma_measured, standardLivingRisk)
            const lambda_moderated = calculateModerated(LOSS_AVERSION, lambda_measured, standardLivingRisk)

            const payload = {
                ...combinedAnswers,
                lambda_measured,
                gamma_measured,
                lambda_moderated,
                gamma_moderated,
            }

            const { token } = queryString.parse(window.location.search)

            const profileId = getPathsClientId()
            const endpoint = profileId ? `${CLIENTS_ENDPOINT}/${profileId}/preferences` : `${PREFERENCES_ENDPOINT}?token=${token}`;
            const { data } = await axios.post(endpoint, payload)
            const { success, message, firmWebsite } = data
            
            if(!success) {
                dispatch(addError('Risk Preferences', message))

                return
            }
            if(firmWebsite && !skipRedirect) {
                const website = !firmWebsite.startsWith('http') ? `http://${firmWebsite}` : firmWebsite
                await sleep(1500)
                window.location.assign(website ? website : '/')
            }
        } catch(e: any) {
            dispatch(addError('Risk Preferences', e.toString()))
        }
    }
}