import { Box } from "@mui/material"
import React, { Component, FunctionComponent } from "react"
import { connect } from 'react-redux'
import { getProfileHistory, onSubmitNoteUpdate, hideNotesModal, onHistoryDelete } from "../../actions/profileHistory"
import { ModalNoteItem } from "../../actions/types/profileHistory"
import { LooseObject } from "../../common/types"
import { hasClientIdChanged } from "../../common/utils"
import Chart from "../common/Chart"
import ConfirmModal from "../common/ConfirmModal"
import Text, { FONT_STYLES_BY_TYPE } from "../common/styled/Text"
import { withClientParameter } from "../common/withClientParameter"
import { BaseProps } from "../types"
import { ReduxState } from "../../reducers"

const ChartRow: FunctionComponent<BaseProps> = ({ children, sx = { }}) => {
    return (
        <Box sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            justifyContent: 'space-between',
            ...sx,
        }}>
            {children}
        </Box> 
    )
}

const ChartContainer: FunctionComponent<BaseProps> =({ children, sx = { }}) => {
   return (
        <Box sx={{
            width: '50%',
            padding: '4rem',
            ...sx,
        }}>
            {children}
        </Box> 
   )
}

export interface ProfileHistoryProps {
    showNotesModal: boolean
    riskAnalyzer?: boolean
    enterprise?: boolean
    notesItem?: ModalNoteItem
    membership?: string
    risksChart: LooseObject
    financialPlanChart: LooseObject
    getProfileHistory: () => void
    hideNotesModal: () => void
    onSubmitNoteUpdate: (noteItem: ModalNoteItem) => void
    onHistoryDelete: (noteItem: ModalNoteItem) => void
}

export interface ProfileHistoryState {
    noteText: string
    showConfirmDelete: boolean
}

class ProfileHistory extends Component<ProfileHistoryProps, ProfileHistoryState> {

    constructor(props: ProfileHistoryProps) {
        super(props)
        this.state = {
            noteText: '',
            showConfirmDelete: false,
        }
    }

    componentDidMount() {
        this.props.getProfileHistory()
    }

    componentDidUpdate(prevProps: ProfileHistoryProps) {
        const { notesItem } = this.props
        const { notesItem: prevNotesItem } = prevProps
        const { noteText = '' } = notesItem || { }

        if(notesItem != prevNotesItem) {
            this.setState({ noteText })
        }

        if(hasClientIdChanged(prevProps, this.props)) {
            this.props.getProfileHistory()
        }

    }

    onNotesSave = () => {
        const { noteText } = this.state
        const { notesItem } = this.props
        if(!notesItem) {
            return
        }
        this.props.onSubmitNoteUpdate({ ...notesItem, noteText })
    }

    onDeletePoint = () => {
        const { notesItem } = this.props
        if(!notesItem) {
            return
        }
        
        this.setState({ showConfirmDelete: false })
        this.props.hideNotesModal()
        this.props.onHistoryDelete(notesItem)
    }

    onShowConfirmDelete = () => {
        this.setState({ showConfirmDelete: true })
    }

    getPrevKey = () => {
        const { enterprise, riskAnalyzer } = this.props
        if(riskAnalyzer) {
            return enterprise ? 'riskAnalyzerOptimizedPortfolio' : 'riskAnalyzer'
        }

        return 'optimizedPortfolio'
    }
    
    render() {
        const { showNotesModal, risksChart, financialPlanChart } = this.props
        const { noteText, showConfirmDelete } = this.state

        return (
            <Box>
                <ConfirmModal visible={showNotesModal} title={'Notes'} confirmLabel={'Done'} secondaryConfirmLabel={'Delete'} secondaryConfirmVariant={'danger'} onConfirm={this.onNotesSave} onSecondaryConfirm={this.onShowConfirmDelete} onCancel={this.props.hideNotesModal} hideCancelButton>
                    <Box sx={{
                        '& textarea': {
                            display: 1,
                            flex: 1,
                            width: '100%',
                            minHeight: '10rem',
                            maxHeight: '20rem',
                            font: FONT_STYLES_BY_TYPE.text,
                        }
                    }}>
                        <textarea onChange={(event) => this.setState({ noteText: event.target.value })} value={noteText}></textarea>
                    </Box>
                </ConfirmModal>
                <ConfirmModal visible={showConfirmDelete} title={'Delete Data Point'} confirmLabel={'Delete'} confirmVariant={'danger'} onConfirm={this.onDeletePoint} cancelLabel={'Cancel'} onCancel={() => this.setState({ showConfirmDelete: false })}>
                    <Text>Are you sure you want to delete all 4 data points (2 risk aversion, 2 loss aversion) associated with this date? This action cannot be undone.</Text>
                </ConfirmModal>
                    <ChartRow>
                        <ChartContainer>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '120rem', }}>
                                <Text type='h4' sx={{ fontWeight: 'bold' }}>Financial Plan</Text>
                            </Box>
                            <Chart id="financialPlanChart" sx={{width: '120rem', height: '50rem'}} chartOptions={financialPlanChart}></Chart>
                        </ChartContainer>
                    </ChartRow>
                    <ChartRow>
                        <ChartContainer>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '120rem', }}>
                                <Text type='h4' sx={{ fontWeight: 'bold' }}>Risk Profile</Text>
                            </Box>
                            <Chart id="risksChart" sx={{width: '120rem', height: '50rem'}} chartOptions={risksChart}></Chart>
                        </ChartContainer>
                    </ChartRow> 
            </Box>
        )
    }
}

const mapStateToProps = ({ profileHistory, session }: ReduxState) => {
    const { showNotesModal, notesItem, risksChart, lambdaChart, financialPlanChart } = profileHistory
    const { membership, enterprise } = session
    const riskAnalyzer = membership === 'risk_analyzer'

    return { showNotesModal, notesItem, membership, enterprise, riskAnalyzer, risksChart, lambdaChart, financialPlanChart }
}

export default withClientParameter(connect(mapStateToProps, { getProfileHistory, hideNotesModal, onSubmitNoteUpdate, onHistoryDelete })(ProfileHistory as any))
