import React from 'react';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import Text from '../../common/Text';
import { Select } from '../../../components/common/styled/Select';
import { SelectOption } from '../../../common/types';
import { DatePicker } from '../../../components/common/styled/DatePicker';
import { ReduxState } from '../../../reducers';
import Button from '../../../components/common/Button';
import { runPerformanceAttribution, onPerformanceAttributionPropUpdated, getPerformanceAttributionTickers } from '../../../actions/simplifyTools';
import { PerformanceAttributionProps } from '../../../reducers/PerformanceAttributionReducer';
import PerformanceAttributionResults from './PerformanceAttributionResults';
import PerformanceChartTabs from './PerformanceChartTabs';
import { InternalPerformanceAttributionTickers } from '../../../actions/types/simplifyTools';
import moment from 'moment';
import { QuarterlyDropdown } from './QuarterlyDropdown';
import { TimeRange, TimeRangeDates } from '../../../components/common/ranges';
import { openLink } from '../../../common/utils';


interface ReduxStateProps {
    loading: boolean
    selectedTicker: SelectOption | null
    startDate: Date | null
    endDate: Date | null
    hasResults: boolean
    tickers: InternalPerformanceAttributionTickers
    tickerOptions: SelectOption[]
    asset_class_level_csv: string
    security_level_csv: string
}
interface ReduxActionProps {
    runPerformanceAttribution: (ticker: string, startDate: Date, endDate: Date) => void
    onPerformanceAttributionPropUpdated: (payload: Partial<PerformanceAttributionProps>) => void
    getPerformanceAttributionTickers: () => void
 }
interface ComponentState {}

type ComponentProps = ReduxActionProps & ReduxStateProps

const EXCEL_DOWNLOAD_OPTIONS = [
    { label: 'Security Level Return Streams', value: 'security_level_csv' },
    { label: 'Asset Class Level Return Streams', value: 'asset_class_level_csv' },
]

class PerformanceAttribution extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {}

    componentDidMount(): void {
       this.props.getPerformanceAttributionTickers()
    }

    onValueChange = (value: SelectOption) => {
        const minStartDate = moment(this.props.tickers[value.value as string]?.start).toDate();
        const maxEndDate = moment(this.props.tickers[value.value as string]?.end).toDate();
        const startDate = moment(this.props.startDate).isBefore(minStartDate) ? minStartDate : this.props.startDate
        const endDate = moment(this.props.endDate).isAfter(maxEndDate) ? maxEndDate : this.props.endDate

        this.props.onPerformanceAttributionPropUpdated({ selectedTicker: value, startDate, endDate})
    }

    onStartUpdated = (date: Date) => {
        this.props.onPerformanceAttributionPropUpdated({ startDate: date })
    }

    onEndUpdated = (date: Date) => {
        this.props.onPerformanceAttributionPropUpdated({ endDate: date })
    }

    onRun = () => {
        const { startDate, endDate, selectedTicker } = this.props
        if (!selectedTicker || !startDate || !endDate) return
        this.props.runPerformanceAttribution(selectedTicker.value as string, startDate, endDate)
    }

    onItemPicked = (dates: TimeRangeDates) => {
        const { selectedTicker } = this.props
        if (!selectedTicker) return
        const minStartDate = moment(this.props.tickers[selectedTicker.value as string]?.start).toDate();
        const maxEndDate = moment(this.props.tickers[selectedTicker.value as string]?.end).toDate();
        const startDate = moment(dates.start_date).isBefore(minStartDate) ? minStartDate : dates.start_date
        const endDate = moment(dates.end_date).isAfter(maxEndDate) ? maxEndDate : dates.end_date

        this.props.onPerformanceAttributionPropUpdated({ startDate: startDate, endDate: endDate })
    }

    onExcelDownload = (value: SelectOption) => {
        const { asset_class_level_csv, security_level_csv } = this.props
        const csvs: any = {
            asset_class_level_csv,
            security_level_csv,
        }

        if (csvs[value.value as string]) {
            
            openLink(csvs[value.value as string])
        }
    }

    render() {
        const { startDate, endDate, selectedTicker, loading, hasResults, tickers, tickerOptions } = this.props
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: '5rem', minHeight: '50rem', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, marginRight: '5rem'  }}>
                    <Box sx={{ height: '5rem', mt: '3rem', mr: '2rem' }}>
                        <Select 
                            placeholder='Enter or select a ticker'
                            options={tickerOptions}
                            onChange={this.onValueChange}
                            value={selectedTicker}
                            sx={{ width: '33rem'}}
                        />
                    </Box>
                        <Box sx={{ height: '5rem', mt: '3rem', mr: '2rem', width: '18rem' }}>
                            <QuarterlyDropdown
                                placeholder='Date Presets'
                                disabled={!selectedTicker}
                                value={null}
                                onItemPicked={this.onItemPicked}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginRight: '1rem',
                            paddingTop: '1rem',
                        }}>
                            <Box>
                                <Text>Start Date</Text>
                                <DatePicker
                                    minDate={tickers[selectedTicker?.value as string]?.start ?? undefined}
                                    maxDate={tickers[selectedTicker?.value as string]?.end ?? undefined}
                                    date={startDate ?? ''}
                                    format={'MM/dd/yyyy'}
                                    onDateChanged={this.onStartUpdated}
                                    disabled={!selectedTicker}
                                />
                            </Box>
                            <Box sx={{ marginLeft: '1rem' }}>
                                <Text>End Date</Text>
                                <DatePicker
                                    minDate={tickers[selectedTicker?.value as string]?.start ?? undefined}
                                    maxDate={tickers[selectedTicker?.value as string]?.end ?? undefined}
                                    date={endDate ?? ''}
                                    format={'MM/dd/yyyy'}
                                    onDateChanged={this.onEndUpdated}
                                    disabled={!selectedTicker}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Button sx={{ mt: '4rem', minHeight: '4rem', height: '4rem', width: '10rem' }} onClick={this.onRun} loading={loading} inlineLoading disabled={!selectedTicker}>RUN</Button>
                        </Box>
                        <Box sx={{ display: 'flex', ml: '15rem', mr: '5rem', height: '10rem', alignItems: 'center' }}>
                            <Text sx={ {
                                    fontWeight: 'bold',
                                    fontSize: '20pt',
                                    color: 'red',
                                    textTransform: 'uppercase',
                                    WebkitBackgroundClip: 'text',
                                    textShadow: '2px 2px 2px #000000',
                                }}>FOR INTERNAL USE ONLY</Text>
                        </Box>
                        {hasResults &&
                            <Box sx={{ 
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                height: '5rem',
                                mt: '3rem',
                                mr: '2rem',
                            
                            }}>
                                <Select
                                    placeholder='CSV Download'
                                    options={EXCEL_DOWNLOAD_OPTIONS}
                                    onChange={this.onExcelDownload}
                                    value={null}
                                    sx={{ width: '33rem'}}
                                />
                            </Box>
                        }
                </Box>
                {hasResults && 
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <PerformanceAttributionResults />
                        <PerformanceChartTabs />
                    </Box>
                }
            </Box>
        )
    }
}

const mapStateToProps = ({ performanceAttribution }: ReduxState): ReduxStateProps => {
    const { loading, selectedTicker, startDate, endDate, hasResults, tickers, tickerOptions, asset_class_level_csv, security_level_csv } = performanceAttribution

    return { loading, selectedTicker, startDate, endDate, hasResults, tickers, tickerOptions, asset_class_level_csv, security_level_csv }
}

export default connect(mapStateToProps, { runPerformanceAttribution, onPerformanceAttributionPropUpdated, getPerformanceAttributionTickers })(PerformanceAttribution);