import React, { Component } from "react"
import { connect } from "react-redux"

import AssetsTable from "./AssetsTable";
import ConfirmLoadAssetsModal from './ConfirmLoadAssetsModal'
import ConfirmDeleteSetModal from './ConfirmDeleteSetModal'
import SaveAsModal from './SaveAsModal'
import { AssetSet } from "../../actions/types/customAssets";
import { loadAssetSet, updateDate, updateRiskPremium, runCustomCMAScripts, createAssetSet, onAssetSetChanged, deleteAssetSet, getCapitalMarketAssumptions } from "../../actions/customCMA";
import { onSelectedKeyChanged } from '../../actions/session';
import Button from "../common/Button";
import SaveModal from "./SaveModal";
import TabFooter from "../Software/TabFooter";
import { Box } from "@mui/material";
import Text from "../common/styled/Text";
import { Select } from '../common/styled/Select'
import { DatePicker } from "../common/styled/DatePicker";
import { hasClientIdChanged } from "../../common/utils";
import { withClientParameter } from "../common/withClientParameter";
import { CreateAssetSetReplaceRequest, CreateAssetSetRequest } from "../../actions/types/requests";

const RISK_PREMIUM_OPTIONS = [
  { label: 'Equity', value: 'Equity'},
  { label: 'Duration', value: 'Duration'},
  { label: 'Real Estate', value: 'Real Estate'},
  { label: 'Commodities', value: 'Commodities'},
]

interface CustomCMAProps {
  getCapitalMarketAssumptions: () => void
  onAssetSetChanged: (selectedAssetSet: AssetSet | null) => void
  loadAssetSet: (assetSet: AssetSet) => void
  updateRiskPremium: (value: string) => void
  onSelectedKeyChanged: (value: string) => void
  createAssetSet: (options: CreateAssetSetRequest | CreateAssetSetReplaceRequest) => void
  deleteAssetSet: (assetSet: AssetSet) => void
  updateDate: (type: 'start_date' | 'end_date', value: string) => void
  runCustomCMAScripts: (options?: { suppressErrors?: boolean }) => void
  selectedAssetSet: AssetSet
  asset_sets: AssetSet[]
  start_date: string
  end_date: string
  risk_premium: string
  running_script: boolean
}

interface CustomCMAState {
  showConfirmSelectionModal: boolean,
  showSaveModal: boolean,
  showSaveAsModal: boolean,
  showConfirmDeleteModal: boolean,
}

class CustomCMA extends Component<CustomCMAProps, CustomCMAState> {

  constructor(props) {
    super(props)
    this.state = {
      showConfirmSelectionModal: false,
      showSaveModal: false,
      showSaveAsModal: false,
      showConfirmDeleteModal: false,
    }
  }

  componentDidMount() {
    this.props.getCapitalMarketAssumptions()
  }

  componentDidUpdate(prevProps) {
    if(hasClientIdChanged(prevProps, this.props)) {
        this.props.getCapitalMarketAssumptions()
    }
  }

  onAssetSetChanged = (selectedAssetSet) => {
    this.props.onAssetSetChanged(selectedAssetSet)
    this.setState({ showConfirmSelectionModal: true })
  }

  onRiskPremiumChanged = ({ value }) => {
    this.props.updateRiskPremium(value)
  }

  onNextModule = () => {
      window.scrollTo({
          top: 0,
          behavior: "smooth"
      });
      this.props.onSelectedKeyChanged('optimizedPortfolio')
  }

  onSaveAs = () => {
    this.setState({ showSaveAsModal: true })
  }

  onSaveConfirmed = () => {
    this.setState({ showSaveModal: false })
    const { selectedAssetSet } = this.props
    const { id: replaceId } = selectedAssetSet || { }

    this.props.createAssetSet({ replaceId, replace: true })
  }

  onSaveAsConfirmed = (name) => {
    this.setState({ showSaveAsModal: false })

    this.props.createAssetSet({ name })
  }

  onDelete = () => {
    this.setState({ showConfirmDeleteModal: true })
  }

  onSwitchToSaveAs = () => {
    this.setState({ showSaveModal: false }, this.onSaveAs)
  }

  onSaveButtonClicked = () => {
    const { selectedAssetSet } = this.props
    const { is_global } = selectedAssetSet || { }

    if(!selectedAssetSet || is_global) {
      this.onSaveAs()

      return
    }

    this.setState({ showSaveModal: true })
  }

  render() {
    const { asset_sets, start_date, end_date, risk_premium, running_script, selectedAssetSet } = this.props
    const { showConfirmSelectionModal, showSaveModal, showSaveAsModal, showConfirmDeleteModal} = this.state
    const { is_global } = selectedAssetSet || { }

    const saveLabel = selectedAssetSet && !is_global ? 'Save' : 'Save As'
    
    return(
      <Box>
        <ConfirmLoadAssetsModal 
          visible={showConfirmSelectionModal}
          item={selectedAssetSet}
          onConfirm={() => {
            this.setState({ showConfirmSelectionModal: false, })
            this.props.loadAssetSet(selectedAssetSet)
          }}
          onCancel={() => {
            this.props.onAssetSetChanged(null)
            this.setState({ showConfirmSelectionModal: false })
          }}
        />
        <ConfirmDeleteSetModal 
          visible={showConfirmDeleteModal}
          item={selectedAssetSet}
          onConfirm={() => {
            this.setState({ showConfirmDeleteModal: false, })
            this.props.deleteAssetSet(selectedAssetSet)
          }}
          onCancel={() => {
            this.setState({ showConfirmDeleteModal: false })
          }}
        />
        <SaveAsModal 
          visible={showSaveAsModal}
          name={selectedAssetSet ? selectedAssetSet.name : '' }
          onSave={this.onSaveAsConfirmed}
          onCancel={() => {
            this.setState({ showSaveAsModal: false })
          }}
        />
        <SaveModal 
          visible={showSaveModal}
          onSave={this.onSaveConfirmed}
          onSaveAs={this.onSwitchToSaveAs}
          onCancel={() => {
            this.setState({ showSaveModal: false })
          }}
        />
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}>
          <Text type={'form_header'}>Saved CMAs</Text>
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '1.5rem'
          }}>
            <Select sx={{ width: '25rem', marginRight: '1.5rem' }} options={asset_sets} value={selectedAssetSet} onChange={this.onAssetSetChanged}/>
            <Button sx={{ width: '15rem'}} title={saveLabel} onClick={this.onSaveButtonClicked} />
            {selectedAssetSet && !is_global &&
              <Button sx={{ width: '15rem'}} title={'Delete'} onClick={this.onDelete} />
            }
          </Box>

          <Text type={'form_header'}>Time Period</Text>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            marginBottom: '2.5rem',
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
            }}>
              <DatePicker
                date={start_date}
                format={'MM/yyyy'}
                onDateChanged={date => this.props.updateDate('start_date', date)} 
                showMonthYearPicker
              />
              <DatePicker
                sx={{ marginLeft: '1rem' }}
                date={end_date}
                format={'MM/yyyy'}
                onDateChanged={date => this.props.updateDate('end_date', date)} 
                showMonthYearPicker
              />
            </Box>
            <Box sx={{
              width: '40rem',
              height: '5rem',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginLeft: '1rem',
              border: (theme) => `1px solid ${theme.palette.borders.level10}`,
            }}>
                <Text sx={{ 
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '1rem',
                    borderRight: (theme) => `1px solid ${theme.palette.borders.level10}`,
                  }}>Benchmark Return</Text>
                <Select sx={{ flex: 1, width: '100%' }} value={{ label: risk_premium, value: risk_premium }} options={RISK_PREMIUM_OPTIONS} onChange={this.onRiskPremiumChanged} borderless />
            </Box>
            <Box sx={{
              display: 'flex',
              flex: 1,
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}>
              <Button sx={{ width: '15rem'}} title={'Run'} loading={running_script} onClick={this.props.runCustomCMAScripts} />
            </Box>
          </Box>
        </Box>
        <AssetsTable sx={{ paddingBottom: '5rem' }} />
        <TabFooter title={'Custom CMA'} showAddProfileHistory={false} />
      </Box>
    )
  }
}

const mapStateToProps = ({ capitalMarketAssumptions }) => {
  const { asset_sets, start_date, end_date, risk_premium, running_script, selectedAssetSet } = capitalMarketAssumptions

  return { asset_sets, start_date, end_date, risk_premium, running_script, selectedAssetSet }
}

export default withClientParameter(connect(mapStateToProps, { getCapitalMarketAssumptions, updateDate, updateRiskPremium, loadAssetSet, runCustomCMAScripts, onSelectedKeyChanged, createAssetSet, onAssetSetChanged, deleteAssetSet })(CustomCMA))