import React, { Component } from 'react'
import { Box, List, SxProps, Theme } from '@mui/material';
import Text from './Text';
import { TextField } from './FormField';
import Button from './Button';
import { BaseProps } from '../types';
import ParameterListRow from './ParameterListRow';

export interface ParameterListProps extends BaseProps {
    onItemAdded: (type: string, name: string) => void
    onItemRemoved: (type: string, item: any, index: number) => void
    onItemChanged?: (item: any, value: any) => void
    onRenderRow?: (item: any, index: number) => React.ReactNode
    type: string
    items: any[]
    title: string
    readOnly?: boolean
    editableItems?: boolean
    listSx?: SxProps<Theme>
}

interface State {
    parameterName: string
    editItem?: any
    editItemText: string
}

class ParameterList extends Component <ParameterListProps, State> {
    constructor(props) {
        super(props)
        this.state = {
            parameterName: '',
            editItem: undefined,
            editItemText: '',
        }
    }

    onParameterChange = (e) => {
        const value = e.target.value

        this.setState({ parameterName: value })
    }

    onItemChanged = (e) => {
        const value = e.target.value

        this.setState({ editItemText: value })
    }

    onItemAdded = () => {
        const { type, onItemAdded } = this.props
        const { parameterName } = this.state

        if(onItemAdded) {
            onItemAdded(type, parameterName)
            this.setState({ parameterName: '' })
        }
    }

    onItemRemoved = (item, index) => {
        const { type } = this.props

        this.props.onItemRemoved(type, item, index)
    }

    render() {
        const { sx = { }, listSx = { }, readOnly = false, items = [], title = '', onRenderRow } = this.props
        const { parameterName } = this.state

        return (
                <Box sx={{
                    flex: 1,
                    flexDirection: 'column',
                    ...sx,
                }}>
                    <Box sx={{
                        marginRight: '2rem',
                    }}>
                            <Text>{title}</Text>
                            {!readOnly && 
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}>
                                    <TextField sx={{ marginRight: '1rem' }} value={parameterName} onChange={this.onParameterChange} />
                                    <Button autoFit onClick={this.onItemAdded}>Add</Button>
                                </Box>
                            }
                    </Box>
                    <List sx={{
                        overflowY: 'scroll',
                        height: '25rem',
                        ...listSx,
                    }}>
                        {items.map((item, index) => {
                            if(onRenderRow) {
                                return onRenderRow(item, index)
                            }
                            const key = typeof(item) == 'object' ? item.value : `${item}`
                            return (
                                    <ParameterListRow
                                        key={key}
                                        onItemRemoved={this.onItemRemoved}
                                        onItemChanged={this.onItemChanged}
                                        item={item}
                                        index={index}
                                    />
                                )
                        })}
                    </List>
                </Box>
        )
    }

}

export default ParameterList