import React, { FunctionComponent, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Text from './Text';

export interface ExportMenuProps {
    onExportData?: () => void;
    onDownloadImage?: () => void;
    onPrint?: () => void;
}

export const ExportMenu: FunctionComponent<ExportMenuProps> = ({ onExportData, onDownloadImage, onPrint }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportData = () => {
    handleClose();
    onExportData && onExportData();
  };

  const handleDownloadImage = () => {
    handleClose();
    onDownloadImage && onDownloadImage();
  };

  const handlePrint = () => {
    handleClose();
    onPrint && onPrint();
  };

  return (
    <div>
      <Button
        aria-controls="export-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        <Text>Export</Text>
      </Button>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {onExportData && <MenuItem onClick={handleExportData}><Text>Export Data</Text></MenuItem>}
        {onDownloadImage && <MenuItem onClick={handleDownloadImage}><Text>Download Image</Text></MenuItem>}
        {onPrint && <MenuItem onClick={handlePrint}><Text>Print</Text></MenuItem>}
      </Menu>
    </div>
  );
};

export default ExportMenu;
