import { SelectOption } from "../../common/types"
import { FinancialPlanSource, StandardLivingRiskSimple } from "./standardLivingRisk"

export type PortfolioManagerType = 'none' | 'orion'

export const PORTFOLIO_MANAGER_NONE_OPTIONS: SelectOption<PortfolioManagerType>[] = [{
    value: 'none',
    label: 'None'
}]

export const PORTFOLIO_MANAGER_TYPE_OPTIONS: SelectOption<PortfolioManagerType>[] = [{
    value: 'none',
    label: 'None'
}, {
    value: 'orion',
    label: 'Orion'
}]

export interface Client {
    id?: number
    first_name: string
    last_name: string
    annual_income: number
    phone: string
    email: string
    standard_living_risk: StandardLivingRiskSimple
    portfolio_manager: PortfolioManagerType
    portfolio_manager_client_id: number
}
export interface ClientUpdateRequest {
    id: number
    first_name?: string
    last_name?: string
    annual_income?: number
    phone?: string
    email?: string
    financial_plan_source?: FinancialPlanSource
    portfolio_manager?: PortfolioManagerType | null
    portfolio_manager_client_id?: number | null
}