import { Box } from '@mui/material'
import React, { JSXElementConstructor, ReactElement } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BaseProps } from '../../types'
import Text from './Text'

export interface TooltipWrapperCollection {
    [key: string]: TooltipWrapper | any
}

interface TooltipWrapperProps extends BaseProps {
    children: ReactElement<any, string | JSXElementConstructor<any>>
    tooltipText: React.ReactNode
    tooltipTextClicked: React.ReactNode
    id?: string
}

interface TooltipWrapperState {
    showClickedText: boolean
}

export class TooltipWrapper extends React.Component<TooltipWrapperProps,TooltipWrapperState> {

    constructor(props: TooltipWrapperProps) {
        super(props)
        this.state = {
            showClickedText: false
        }
    }

    showClickedText = () => {
        this.setState({ showClickedText: true })
    }
    
    clearClickedText = () => {
        this.setState({ showClickedText: false })
    }
    onMouseLeave = () => {
        this.setState({ showClickedText: false })
    }
    
    render() {
        const { showClickedText = false } = this.state || { }
        const { tooltipText, tooltipTextClicked, sx = { } } = this.props
        const text = showClickedText ? tooltipTextClicked : tooltipText

        return (
            <Box sx={sx} component={'span'} onMouseLeave={this.onMouseLeave}>
                <OverlayTrigger overlay={<Tooltip id={this.props.id || ''}><Text sx={{ color: 'white' }}>{text}</Text></Tooltip>}>
                    {this.props.children}
                </OverlayTrigger>
            </Box>
        )
    }
}

export default TooltipWrapper