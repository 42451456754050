import React from 'react'
import { StandardImage, ImageProps } from '../components/common/styled/StandardImage';

export const navLogoHorizontal = require('./pd_logo_nav.png')
export const navLogoHorizontalWebp = require('./pd_logo_nav.webp')

export const logoHorizontal = require('./pd_logo.png')
export const logoHorizontalWebp = require('./pd_logo.webp')
export const openFolder = require('./easydrag-full-th.png')
export const bucketingExample = require('./bucketing.png')

export const NavLogoHorizontalImage = ({ src, ...restProps}:  ImageProps) => <StandardImage src={src ?? navLogoHorizontal} webp={src ? undefined : navLogoHorizontalWebp} alt="Portfolio Designer Logo" {...restProps} />
export const LogoHorizontalImage = ({ src, ...restProps}:  ImageProps) => <StandardImage src={src ?? logoHorizontal} webp={src ? undefined : logoHorizontalWebp} alt="Portfolio Designer Logo" {...restProps} />
export const OpenFolderImage = ({ description = 'Open Folder'}) => <img src={openFolder} alt={description}></img>


export const BucketingExampleImage = ({ description = 'Bucketing Example'}) => <img src={bucketingExample} alt={description}></img>