import React, { Component, FunctionComponent } from 'react'

import { cleanPhoneNumber, formatPhoneNumber, validateEmail } from '../../common/utils'
import { Box } from '@mui/material'
import { BaseProps } from '../types'
import {  TextFieldItem } from './FormField'
import Text from './styled/Text'
import { Client } from '../../actions/types/clientManagement'
import { FormRow } from './FormRow'

const INITIAL_STATE = {
    id: 0,
    first_name: '',
    last_name: '',
    annual_income: 0,
    phone: '',
    email: '',
}

export interface ClientDetailProps extends BaseProps {
    fields?: string[]
    client?: Client
    onConfirmed: (client: Client) => void
    ref?: ClientDetailsComponent | any
}

export interface ClientDetailState extends Client {
    error?: string
}

class ClientDetailsComponent extends Component <ClientDetailProps, ClientDetailState> {
    constructor(props) {
        super(props)

        this.state = {
            ...INITIAL_STATE,
            ...(props.client || { }),
            error: '',
        }
    }

    componentDidUpdate(prevProps) {
        const { client: prevClient } = prevProps
        const { client } = this.props

        if(prevClient != client) {
            this.setState({
                ...INITIAL_STATE,
                ...(client || { })
            })
        }
    }

    validateValues = () => {
        const { first_name, last_name, annual_income, phone, email } = this.state
        const { fields = ['name', 'income', 'phone', 'email'] } = this.props
        if(fields.includes('name') && (!first_name || first_name.length == 0 || !last_name || last_name.length == 0)) {
            this.setState({ error: 'First and Last name fields are required' })

            return false
        }

        if(fields.includes('income') && (annual_income && isNaN(annual_income as number))) {
            this.setState({ error: 'Please enter a valid numeric value for income' })

            return false
        }

        if(fields.includes('income') && (annual_income == '')) {
            this.setState({ error: 'Income is a required field' })

            return false
        }
        
        if(fields.includes('phone') && (phone && phone.toString().length > 0 && phone.toString().length < 10)) {
            this.setState({ error: 'Please enter a 10 digit phone number' })

            return false
        }

        if(fields.includes('email') && (email && email.length > 0 && !validateEmail(email))) {
            this.setState({ error: 'Please enter a valid email address' })

            return false
        }

        this.setState({ error: '' })

        return true
    }

    confirm = () => {
        const { id, first_name, last_name, annual_income, phone, email } = this.state

        if(!this.validateValues()) {
            return
        }
        
        this.props.onConfirmed({ id, first_name, last_name, annual_income, phone, email })
    }

    onIncomeChange = (e) => {
        let amount = e.target.value
        if(amount != '') {
            amount = parseInt(amount.replace(/,/g, '').replace(/\D/g,''), 10)
            if(isNaN(amount)) {
                return
            }
        }

        this.setState({ annual_income: amount })
    }

    render() {
        const { first_name, last_name, annual_income, phone, email, error } = this.state
        const { fields = ['name', 'income', 'phone', 'email'], sx = { } } = this.props
        return (
            <Box sx={sx}>
                <FormRow>
                    {fields.includes('name') && 
                        <TextFieldItem 
                            title={'* First Name'}
                            value={first_name}
                            onValueChange={(value) => this.setState({ first_name: value })}
                        />
                    }
                    {fields.includes('name') &&
                        <TextFieldItem 
                            title={'* Last Name'}
                            value={last_name}
                            onValueChange={(value) => this.setState({ last_name: value })}
                        />
                    }
                    {fields.includes('income') &&
                        <TextFieldItem 
                            title={'* Annual Income'}
                            value={annual_income ? annual_income.toLocaleString('en-US') : '' }
                            onChange={this.onIncomeChange}
                        />
                    }
                </FormRow>
                <FormRow>
                    {fields.includes('phone') &&
                        <TextFieldItem 
                            title={'Phone'}
                            value={formatPhoneNumber(phone)}
                            onValueChange={(value) => this.setState({ phone: cleanPhoneNumber(value) })}
                        />
                    }
                    {fields.includes('email') &&
                        <TextFieldItem 
                            type={'email'}
                            title={'Email'}
                            value={email}
                            onValueChange={(value) => this.setState({ email: value })}
                        />
                    }
                </FormRow>
                <FormRow sx={{
                    margin: '1rem',
                }}>
                    <Text sx={{ color: 'red' }}>{error}</Text>
                </FormRow>
            </Box>
        )
    }
}

export default ClientDetailsComponent