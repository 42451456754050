import React from 'react';
import { Box, Paper } from '@mui/material';
import { BaseProps } from '../types';
import TooltipContainer from '../common/styled/TooltipContainer';
import Text from '../common/Text';
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faInfoCircle, faQuestion } from '@fortawesome/free-solid-svg-icons';

const SecurityInformation: React.FC<BaseProps> = ({ children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Text>Your Credentials Are Encrypted and Securely Stored</Text>
      <TooltipContainer
        hoverContentsSx={{
          width: '50rem'
        }}
        hoverContents={
            <Box>
            <Paper elevation={3} style={{ padding: '20px', background: 'transparent' }}>
              <Text type={'table_header'} asDiv>
                Security Information
              </Text>
              <Text asDiv>
                Your credentials are securely stored using industry-standard encryption methods. We employ AWS Key Management Service (KMS) to generate and manage encryption keys. This ensures that your data is protected both at rest and in transit. Our encryption process includes:
              </Text>
              <Text asDiv>
                <strong>AES-256 Encryption</strong>: Your data is encrypted using AES-256, one of the most secure encryption algorithms available.
              </Text>
              <Text asDiv>
                <strong>Key Management</strong>: We use AWS KMS to generate and securely store encryption keys, providing an additional layer of security.
              </Text>
              <Text asDiv>
                <strong>Secure Decryption</strong>: Only authorized processes can decrypt your data, ensuring that your credentials are safe and protected at all times.
              </Text>
              <Text asDiv type={'table_header'}>
                We are committed to maintaining the highest standards of security to protect your personal information.
              </Text>
            </Paper>
          </Box>
        }
      >
        <Box>
          <FontAwesomeIcon icon={faCircleInfo} size='2x' style={{ marginLeft: '0.5rem' }} />
        </Box>
      </TooltipContainer>
    </Box>
  );
};

export default SecurityInformation;
