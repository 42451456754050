import React, {FC} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabPanel, getTabTitleProps } from '../../common/TabPanel';
import InputTab from './InputTab';
import AnnotationsTab from './AnnotationsTab';
import { connect } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { DueDiligenceChartAnnotationsEdit } from 'src/actions/types/dueDiligence';


interface Props {
  selectedChartItem: DueDiligenceChartAnnotationsEdit | undefined;
}

const ChartTabs: FC<Props> = ({ selectedChartItem }) => {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    setValue(0);
  }, [selectedChartItem?.id]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '40rem', marginRight: '2.5rem' }}>
       <InputTab />
    </Box>
  );
}

const mapStateToProps = ({ dueDiligence }: ReduxState) => ({
  selectedChartItem: dueDiligence.selectedChartItem
});

export default connect(mapStateToProps)(ChartTabs);