import { Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { getCustomAssets } from '../../actions/customAssets';
import { Asset } from '../../actions/types/customAssets';
import { Page } from '../Page';
import { BaseProps } from '../types';
import { AssetsPanel } from './AssetsPanel';


export const CustomAssetsComponent: FunctionComponent<BaseProps> = () => {
    const [assets, setAssets] = React.useState<Asset[]>([])
    const [loading, setLoading] = React.useState<boolean>(true)

    const getCustomAssetsData = async() => {
       const { success, assets } = await getCustomAssets()
       if (success && assets) {
        setAssets(assets || [])
       }
       setLoading(false)
    }

    const onAssetUpdated = React.useCallback((asset: Asset) => {
        const updatedAssets = assets.map(a => a.id == asset.id ? asset : a)
        setAssets(updatedAssets)
    }, [assets]);

    React.useEffect(() => {
        getCustomAssetsData()
    }, [])

    return (
        <Page 
            contentsSx={{ 
            }}>
                <Box sx={{ marginTop: '5rem', width: '100%', }}>
                    {loading &&
                        <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <ClipLoader color={'#000'} loading={true} size={35} />
                        </Box>
                    }
                    {!loading &&
                        <Box sx={{
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                md: 'row'
                            },
                            marginBottom: '2.5rem'
                        }}>
                            <AssetsPanel 
                                title={'Custom Assets'}
                                assets={assets}
                                onAssetUpdated={onAssetUpdated}
                                onAssetsUpdated={setAssets}
                                showUpload 
                            />
                        </Box>
                    }
                </Box>
        </Page>
    )
}

export default CustomAssetsComponent