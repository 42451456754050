import { 
    styled,
    experimental_sx as sx,
} from '@mui/system';

const CopyImage = styled('img')(
    sx({
        height: '25em',
        paddingBottom: '5em'
    }),
  );

export default CopyImage