import { Box, Typography, Button, SxProps, Theme } from '@mui/material'
import React, { Component } from 'react'
import { BaseProps } from '../types'

export interface AlertProps extends BaseProps {
    visible: Boolean
    title?: string | React.ReactNode
    children?: string | React.ReactNode
    titleIcon?: any
    subtitle?: string | React.ReactNode
    className?:string
    contentsSx?: SxProps<Theme>
    duration?: number
    onDismiss: () => void
}

export default class Alert extends Component <AlertProps>{

    durationTimer: NodeJS.Timeout

    constructor(props) {
        super(props)
        if(props.visible) {
            this.updateDismissTimer()
        }
    }


    componentDidUpdate(prevProps) {
        const { visible: prevVisible, duration: prevDuration } = prevProps
        const { visible, duration } = this.props

        if((visible && !prevVisible) || prevDuration !== duration) {
            this.updateDismissTimer()
        }
    }

    updateDismissTimer = () => {
        const { duration, onDismiss } = this.props

        if(this.durationTimer) {
            clearTimeout(this.durationTimer)
            this.durationTimer = undefined
        }

        if(typeof(duration) == 'number') {
            this.durationTimer = setTimeout(() => {
                if(onDismiss) {
                    onDismiss()
                }
            }, duration)
        }
    }

    render() {
        const { sx = { }, contentsSx = {}, visible, titleIcon: TitleIcon, title, subtitle, children, className, onDismiss, ...restProps } = this.props
        if(!visible) {
            return <></>
        }
        
        return (
            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '30rem',
                    height: '15rem',
                    padding: '2rem',
                    marginBottom: '20px',
                    border: '1px solid transparent',
                    position: 'relative',
                    borderRadius: '.75rem',
                    ...sx
                }} className={[(className ? className : '')].join(' ')} {...restProps}>
                <Button
                    sx={{
                        color: '#000',
                        opacity: .2,
                        filter: 'alpha(opacity=20)',
                        fontSize: '21px',
                        fontWeight: 700,
                        textShadow: '0 1px 0 #fff',
                        padding: 0,
                        cursor: 'pointer',
                        border: 0,
                        position: 'absolute',
                        top: 2,
                        right: -5
                    }}
                    type="button"
                    onClick={onDismiss}>×</Button>
                {title && 
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 600,
                        fontSize: '18px',
                        marginBottom: '5px',
                    }}>
                        {TitleIcon && <TitleIcon />}
                        <Typography sx={{ marginLeft: '1rem', fontSize: 20 }}>
                            {title}
                        </Typography>
                    </Box>
                }
                {subtitle &&
                    <Typography sx={{ marginLeft: '1.5rem', fontWeight: 'bold' }}>
                        {subtitle}
                    </Typography>
                }
                <Box 
                    sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        marginLeft: '1.5rem',
                        ...contentsSx,
                    }}>{children}</Box>
            </Box>
        )
    }

}