import React from 'react'
import { useLocation } from 'react-router-dom'

export interface HubspotChatbotProps {
    routes?: string[]
}

export const HubspotChatbot: React.FunctionComponent<HubspotChatbotProps> = ({ routes }) => {
    const [isReady, setIsReady] = React.useState<boolean>()
    const location = useLocation()

    //@ts-ignore
    window.hsConversationsOnReady = [
        () => setIsReady(true),
      ];
    const showWidget = () => {
        //@ts-ignore
        window.HubSpotConversations.widget.load()
    }

    const hideWidget = () => {
        //@ts-ignore
        window.HubSpotConversations.widget.remove()
    }

    React.useEffect(() => {
        if(!isReady) {
            return
        }
        
        if(!routes?.length) {
            showWidget()
            return
        }

        if(routes.includes(location.pathname)) {
            showWidget()
        } else {
            hideWidget()
        }
    },[location, routes, isReady])
    
    return null
}