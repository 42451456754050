import { Component } from "react"
import { connect } from 'react-redux'
import { ResultTable } from "../../../ResultTable"
import { ResultsTableData } from "../../../../../actions/types/portfolioDesigner"
import { ReduxState } from "../../../../../reducers"

export interface AssetClassTableProps {
    asset_class: ResultsTableData
    benchmarkSnapshotLoading: boolean
    portfolioSnapshotLoading: boolean
}
export interface AssetClassTableState { }
class AssetClassTable extends Component<AssetClassTableProps, AssetClassTableState> {
    mainView?: any
    resultsRef?: any

    constructor(props: AssetClassTableProps) {
        super(props)

        this.state = {
            runningAnalyzer: false,
            runningHistoricalAnalysis: false,
            reportItems: [],
        };
    }

    render() {
        const { asset_class, benchmarkSnapshotLoading, portfolioSnapshotLoading } = this.props
        if (!asset_class) {
            return null;
        }

        return (
            <ResultTable
                loadingBenchmark={benchmarkSnapshotLoading}
                loadingPortfolio={portfolioSnapshotLoading}
                containerSx={{
                    width: '45rem',
                    marginTop: {
                        xs: '2rem',
                        md: undefined, 
                    },
                    marginRight: {
                        xs: undefined,
                        md: '5rem',
                    }
                }}
                results={asset_class}
            />
        )
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState ) => {
    const { asset_class, portfolioSnapshotLoading, benchmarkSnapshotLoading } = portfolioDesigner

    return { asset_class, portfolioSnapshotLoading, benchmarkSnapshotLoading  }
}

export default connect(mapStateToProps)(AssetClassTable as  any)