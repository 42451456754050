import React, { Component, FunctionComponent } from 'react'
import { connect } from 'react-redux'
import  * as moment from 'moment-timezone'
import 'moment-timezone'
import { getApiKeys, enableApiKey, disableApiKey, createApiKey, deleteApiKey } from '../../actions/keys'
import Text from '../common/styled/Text'
import { DataTable } from '../common/DataTable'
import Button from '../common/Button'
import { Box } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCopy, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ApiKey, CreateKeysResult, EXPERIATION_PERIOD_OPTIONS, ExpirationPeriod, RetrieveKeysResult } from '../../actions/types/keys'
import ConfirmModal from '../common/ConfirmModal'
import { AdminPage } from '../Admin/AdminPage'
import TooltipWrapper from '../common/styled/TooltipWrapper'
import { copyToClipboard } from '../../common/utils'
import ConfirmDelete from '../common/ConfirmDelete'
import { SelectOption } from '../../common/types'
import { Select } from '../common/styled/Select'
import MessageModal from '../common/MessageModal'
import { addSuccessNotification } from 'src/actions/notifications'
import { addError } from 'src/actions/notifications'

interface ApiKeyRow extends ApiKey {
    status: React.ReactNode;
    actions: React.ReactNode;
}

interface Props {
    addError: (title: string, message: string) => void
    addSuccessNotification: (notification: { title?: string, message: string }) => void
}

interface State {
    apiKeyRows: ApiKeyRow[]
    keys: ApiKey[]
    createdApiKey?: ApiKey
    confirmDeleteApiKey?: ApiKey
    creating: boolean
    copyText?: string
    showCreateModel: boolean
    createExpirationPeriodOption: SelectOption | null
}

interface StatusActionProps {
    onEnable: (apiKey: ApiKey) => void
    onDisable: (apiKey: ApiKey) => void
    apiKey: ApiKey
}

interface ActionProps {
    onDelete: (apiKey: ApiKey) => void
    apiKey: ApiKey
}


const StatusActions:FunctionComponent<StatusActionProps>  = ({ apiKey, onEnable, onDisable }) => {
    const onToggle = React.useCallback(() => {
        if  (apiKey.active) {
            onDisable({ ...apiKey, active: false })
        } else {
            onEnable({ ...apiKey, active: true })
        }
    }, [apiKey, onEnable, onDisable])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box  sx={{ '& svg': { cursor: 'pointer' }, display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
                <Box component={'button'} sx={{ all: 'unset', cursor: 'pointer', display: 'flex', alignItem: 'center', justifyContent: 'center', height: '1.65rem', width: '1.65rem', padding: '0.25rem', border: '.1rem solid' }} onClick={onToggle}>
                    {apiKey.active && <FontAwesomeIcon size={'1x'} icon={faCheck} /> }
                </Box>
            </Box>
            {apiKey.active ? 'Enabled' : 'Disabled'}
        </Box>
    )
}


const Actions:FunctionComponent<ActionProps>  = ({ apiKey, onDelete: propOnDelete }) => {
    const onDelete = React.useCallback(() => {
        propOnDelete(apiKey)
    }, [apiKey, propOnDelete])

    return (
        <Box sx={{ '& svg': { cursor: 'pointer' } }}>
            <FontAwesomeIcon color={'#297ea1'} size={'1x'} icon={faTrash} onClick={onDelete} />
        </Box>
    )
}

class ApiKeys extends Component<Props, State> {
    copiedKeyTooltip: any;
    copiedSecretTooltip: any;

    constructor(props: Props) {
        super(props)
        this.state = {
            apiKeyRows: [],
            keys: [],
            creating: false,
            showCreateModel: false,
            createExpirationPeriodOption: EXPERIATION_PERIOD_OPTIONS[0],
        }
    }
    
    componentDidMount() {
        this.loadApiKeys()
    }

    componentDidUpdate(_: Props, prevState: State) {
        const { keys: prevKeys } = prevState
        const { keys} = this.state

        if(prevKeys !== keys) {
            const apiKeyRows = this.formatRows(keys || [])

            this.setState({ apiKeyRows })
        }
    }

    async loadApiKeys() {
        try {
            const result = await getApiKeys();
            if (!result.success && result.error) {
                this.props.addError('Loading', result.error);
            } else if (!result.success) {
                this.props.addError('Loading', `Unexpected error occurred loading API keys`);
            }
            const { keys } = result as RetrieveKeysResult
    
            this.setState({ keys })
        } catch (e) {

        }
    }

    onEnable = async (apiKey: ApiKey) => {
        const { keys } = this.state
        const updatedKeys = keys.map((key) => {
            if (apiKey.id === key.id) {
                return { ...apiKey }
            }

            return key;
        })

        this.setState({ keys: updatedKeys })
        try {
            const result = await enableApiKey(apiKey)
            if (!result.success && result.error) {
                this.props.addError('Enabling', result.error);
                this.setState({ keys })
            } else if (!result.success) {
                this.props.addError('Enabling', `Unexpected error occurred loading API keys`);
                this.setState({ keys })
            }
        } catch(e) {
            this.props.addError('Enabling', `Unexpected error occurred loading API keys`);
        }
    }

    onDisable = async (apiKey: ApiKey) => {
        const { keys } = this.state
        const updatedKeys = keys.map((key) => {
            if (apiKey.id === key.id) {
                return { ...apiKey }
            }

            return key;
        })

        this.setState({ keys: updatedKeys });

        try {
            const result = await disableApiKey(apiKey)
            if (!result.success && result.error) {
                this.props.addError('Disabling', result.error);
                this.setState({ keys })
            } else if (!result.success) {
                this.props.addError('Disabling', `Unexpected error occurred disabling the API key`);
                this.setState({ keys })
            }
        } catch(e) {
            this.props.addError('Disabling', `Unexpected error occurred disabling the API key`);
        }
    }

    onDelete = (apiKey: ApiKey) => {
        this.setState({ confirmDeleteApiKey: apiKey })
    }

    formatRows = (apiKeys: ApiKey[]): ApiKeyRow[] => {
        const apiKeyRows:ApiKeyRow[] = apiKeys.map((apiKey: ApiKey) => {
            const { createdAt: createdAtString, expiresAt: expiresAtString } = apiKey
            const createdAt = moment.utc(createdAtString).tz("America/New_York").format('MMM DD yyyy hh:mm a')
            const expiresAt = expiresAtString ? moment.utc(expiresAtString).tz("America/New_York").format('MMM DD yyyy hh:mm a') : '--';

            return { 
                ...apiKey,
                createdAt,
                expiresAt,
                status: <StatusActions apiKey={apiKey} onEnable={this.onEnable} onDisable={this.onDisable} />,
                actions:  <Actions apiKey={apiKey} onDelete={this.onDelete} />
             }
        })

        return apiKeyRows
    }

    onCloseCreatedApiKey = () => {
        this.setState({ createdApiKey: undefined, copyText: undefined })
    }

    onAddApiKey = async () => {
        const { creating, createExpirationPeriodOption } = this.state
        if (creating) {
            return
        }

        this.setState({ creating: true })
        try {
            const result = await createApiKey(createExpirationPeriodOption?.value as ExpirationPeriod) 

            if (result.success) {
                const { key } = result as CreateKeysResult
                const { secret, ...keyWithoutSecret } = key
                this.setState({ createdApiKey: key })
    
                this.setState({ keys: [keyWithoutSecret, ...this.state.keys]})
            } else if (result.error) {
                this.props.addError('Creating', result.error);
            } else {
                this.props.addError('Creating', `Unexpected error occurred creating an API key`);
            }
        } catch (e) {
            this.props.addError('Creating', `Unexpected error occurred creating an API key`);
        }

        this.setState({ creating: false, showCreateModel: false, createExpirationPeriodOption: EXPERIATION_PERIOD_OPTIONS[0] })
    }

    onCopyKey = () => {
        const { createdApiKey } = this.state
        copyToClipboard(createdApiKey?.key || '')
        this.copiedKeyTooltip.showClickedText()
        this.setState({ copyText: 'Api Key Copied!'})
    }

    onCopySecret = () => {
        const { createdApiKey } = this.state
        copyToClipboard(createdApiKey?.secret || '')
        this.setState({ copyText: 'Api Secret Copied!'})
    }

    onCloseConfirmDelete = () => {
        this.setState({ confirmDeleteApiKey: undefined })
    }

    onConfirmDelete = async () => {
        const { confirmDeleteApiKey, keys } = this.state
        if (!confirmDeleteApiKey) {
            return;
        }

        const updatedKeys = keys.filter((key) => key.id !== confirmDeleteApiKey.id)

        this.setState({ keys: updatedKeys, confirmDeleteApiKey: undefined });

        try {
            const result = await deleteApiKey(confirmDeleteApiKey)
            if (!result.success && result.error) {
                this.props.addError('Deleting', result.error);
                this.setState({ keys })
            } else if (!result.success) {
                this.props.addError('Deleting', `Unexpected error occurred deleting the API key`);
                this.setState({ keys })
            }
        } catch(e) {
            this.props.addError('Deleting', `Unexpected error occurred deleting the API key`);
        }
    }

    onCloseCreateApiKey = () => {
        this.setState({ showCreateModel: false, createExpirationPeriodOption: EXPERIATION_PERIOD_OPTIONS[0] })
    }

    onShowCreateModel = () => {
        this.setState({ showCreateModel: true, createExpirationPeriodOption: EXPERIATION_PERIOD_OPTIONS[0] })
    }

    onChangeExpirationPeriod = (value: SelectOption) => {
        this.setState({ createExpirationPeriodOption: value })
    }

    render() {
        const { apiKeyRows, createdApiKey, creating, copyText, confirmDeleteApiKey, showCreateModel, createExpirationPeriodOption } = this.state

        return (
            <AdminPage>
                <ConfirmDelete
                    title={'API Key'}
                    onCancel={this.onCloseConfirmDelete}
                    onDelete={this.onConfirmDelete}
                    visible={confirmDeleteApiKey !== undefined}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Text>Are you sure you want to delete this API Key: <Text sx={{ fontWeight: 'bold' }}>{confirmDeleteApiKey?.key}</Text></Text>
                        <Text sx={{ color: '#a40e26', fontWeight: 'bold' }}>Note: This action is irreversiable</Text>
                    </Box>
                </ConfirmDelete>
                <MessageModal
                    title={'API Key'}
                    onButtonPress={this.onCloseCreatedApiKey}
                    onCancel={this.onCloseCreatedApiKey}
                    visible={createdApiKey !== undefined}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Text sx={{ color: '#a40e26', fontWeight: 'bold' }}>Make sure to copy the API Secret now. You won’t be able to see it again!</Text>
                        <Box>
                            <Text type='text_tiny' sx={{ fontWeight: 'bold' }}>API Key: </Text> <Text>{createdApiKey?.id}</Text>
                            <TooltipWrapper ref={(view) => this.copiedKeyTooltip = view } sx={{ marginLeft: '1rem', cursor: 'pointer' }} tooltipText={'Copy Secret'} tooltipTextClicked={'Key Copied!'}>
                                <FontAwesomeIcon color={'#297ea1'} icon={faCopy} onClick={this.onCopyKey} />
                            </TooltipWrapper>
                        </Box>
                        <Box>
                            <Text type='text_tiny' sx={{ fontWeight: 'bold' }}>API Secret: </Text> <Text>{createdApiKey?.secret}</Text>

                            <TooltipWrapper ref={(view) => this.copiedSecretTooltip = view } sx={{ marginLeft: '1rem', cursor: 'pointer' }} tooltipText={'Copy Secret'} tooltipTextClicked={'Secret Copied!'}>
                                <FontAwesomeIcon color={'#297ea1'} icon={faCopy} onClick={this.onCopySecret} />
                            </TooltipWrapper>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1f883d44'}}>
                        <Text>{copyText}</Text>
                    </Box>
                </MessageModal>
                <ConfirmModal
                    title={'Create API Key'}
                    onCancel={this.onCloseCreateApiKey}
                    onConfirm={this.onAddApiKey}
                    confirmLabel='Create'
                    visible={showCreateModel}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Text>Select An Expiration Period</Text>
                        <Select sx={{ width: '25rem', marginRight: '1.5rem' }} options={EXPERIATION_PERIOD_OPTIONS} value={createExpirationPeriodOption} onChange={this.onChangeExpirationPeriod}/>
                    </Box>
                </ConfirmModal>
                <DataTable
                    actionSx={{ top: '-.5rem' }}
                    actions={<Button loading={creating} sx={{ minHeight: 'unset', height: '4rem', width: '15rem' }} title={'Add'} onClick={this.onShowCreateModel} />}
                    data={{ 
                        rows: apiKeyRows, 
                        columns: [{
                            label: 'Key',
                            field: 'key'
                        },{
                            label: 'Created At',
                            field: 'createdAt'
                        },{
                            label: 'Expires At',
                            field: 'expiresAt'
                        },{
                            label: 'Status',
                            field: 'status'
                        },{
                            label: 'Actions',
                            field: 'actions'
                        },]
                    }}
                /> 
            </AdminPage>
        )
    }
}

const mapStateToProps = () => {
    return {  }
}

export default connect(mapStateToProps, { addSuccessNotification, addError })(ApiKeys)