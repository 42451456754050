import React, { Component } from 'react'
import ConfirmModal from '../common/ConfirmModal'
import Text from '../common/styled/Text'

interface Props {
    visible: boolean
    onSave: () => void
    onSaveAs: () => void
    onCancel: () => void
}

export default class extends Component<Props> {

    onSave = () => {
        if(!this.props.onSave) {
            return
        }
        this.props.onSave()
    }

    onSaveAs = () => {
        if(!this.props.onSaveAs) {
            return
        }

        this.props.onSaveAs()
    }

    render() {
        const { visible } = this.props

        return (
            <ConfirmModal
                visible={visible}
                title={`Save`}
                confirmLabel={'Save New'}
                secondaryConfirmLabel={'Replace'}
                secondaryConfirmVariant={'danger'}
                cancelLabel={'Cancel'}
                onConfirm={this.onSaveAs}
                onSecondaryConfirm={this.onSave}
                onCancel={this.props.onCancel}>
                <Text>Do you want to replace the existing model or do you want to save as a new model?</Text>
            </ConfirmModal>
        )
    }
}