import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { Box, InputBase, SxProps, InputBaseProps, Theme, Typography, TextareaAutosize } from '@mui/material';
import { BaseProps } from '../types';
import Text from './styled/Text';

export interface TextFieldProps extends InputBaseProps {
  forwardRef?: any
  onValueChange?: (value: string) => void
  onLeave?: (value: string) => void
  valueFormatter?: (value: string) => string
}

export interface FieldItemProps extends BaseProps {
    title?: string | React.ReactNode
    titleSx?: SxProps<Theme>
    contentsSx?: SxProps<Theme>
    inline?: boolean
    centerTitle?: boolean
}

export interface TextFieldItemProps extends TextFieldProps {
    title: string | React.ReactNode
    inline?: boolean
    center?: boolean
}

interface FieldTitleProps extends BaseProps{
  inline?: boolean
}

export const FieldTitle: FunctionComponent<FieldTitleProps> = ({ children, sx = { }, inline = false }) => {
    const alignment = inline ? { textAlign: { md: 'end' }, justifyContent: { md: 'flex-end' } } : undefined
    return (
        <Text 
            sx={{
                display: 'flex',
                height: '40px',
                marginRight: inline ? '1.5rem' : undefined,
                flex: 1,
                alignItems: 'center',
                ...alignment,
                ...sx
            }}>{children}</Text>)
}
  export const FieldItem: FunctionComponent<FieldItemProps> = ({ title, titleSx = { }, contentsSx = { }, sx = { }, inline = false, centerTitle = true, children }: FieldItemProps) => {
    const flexDirection = inline ? { xs: 'column', md: 'row' } : 'column'
    return (
      <Box sx={{ 
            display: 'flex', 
            marginTop: '1rem',
            marginBottom: '1rem',
            flexDirection,
            ...sx 
        }}>
        {title && <FieldTitle sx={titleSx} inline={inline && centerTitle}>{title}</FieldTitle> }
        <Box sx={{
            display: 'flex',
            flex: 1,
          ...contentsSx,
        }}>{children}</Box>
      </Box>
    );
  };

  export const TextField: FunctionComponent<TextFieldProps> = ({ forwardRef,  sx = { }, value: propValue, onChange, onBlur, onLeave, valueFormatter, onValueChange, readOnly, ...props }) => {
    const [value, setValue] = React.useState<any>(typeof(valueFormatter) === 'function' ? valueFormatter(propValue as any) : propValue)

    useEffect(() => {
      setValue(typeof(valueFormatter) === 'function' ? valueFormatter(propValue as any) : propValue)
    }, [propValue, valueFormatter]);

    const onBlurHandler = useCallback((event: any) => {
      if(typeof(onLeave) === 'function') {
        onLeave(value) 
      }

      if(typeof(onBlur) === 'function') {
        onBlur(event) 
      }
    }, [value, onLeave]);

    const onChangeHandler = useCallback((event: any) => {
      if(valueFormatter) {
        event.target.value = valueFormatter(event.target.value)
      }
      const targetValue = event.target.value

      setValue(targetValue)
      if(typeof(onChange) === 'function') {
          onChange(event)
      }

      if(typeof(onValueChange) === 'function') {
          onValueChange(targetValue)
      }
  }, [setValue, onChange, onValueChange, valueFormatter])
      return (
          <InputBase
              ref={forwardRef}
              readOnly={readOnly}
              sx={{
                  border: '1px solid #ddd',
                  borderRadius: 0,
                  maxWidth: '35rem',
                  fontSize: '14px',
                  lineHeight: 1.42857143,
                  color: '#555',
                  backgroundColor: readOnly ? '#dfe7ec' : '#fff',
                  ...sx,
                  '& input': {
                    padding: '6px 12px',
                    height: '2.5rem',
                    ...(sx && sx['& input'] ? sx['& input'] : { }),
                  },
                  '& input:read-only': {
                    backgroundColor: '#dfe7ec',
                    pointerEvents: 'none',
                    ...(sx && sx['& input:read-only'] ? sx['& input:read-only'] : { }),
                  },
                  '&:hover': {
                      borderColor: '#c2e1e6',
                      boxShadow: '0 1px 1px rgb(0 0 0 / 8%) inset, 0 0 8px #dfe7ec',
                      outline: '0 none',
                      ...(sx && sx['&:hover'] ? sx['&:hover'] : { }),
                  },
                  '& textarea': {
                    padding: '6px 12px',
                    ...(sx && sx['& textarea'] ? sx['& textarea'] : { }),
                  },
                  '& textarea:read-only': {
                    backgroundColor: '#dfe7ec',
                    pointerEvents: 'none',
                    ...(sx && sx['& textarea:read-only'] ? sx['& textarea:read-only'] : { }),
                  },
              }}
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
              {...props}
              value={value}
              ></InputBase>
      );
    };
  
  
export const TextFieldItem: FunctionComponent<TextFieldItemProps> = ({ title, inline = false, center = true, ...props }) => {
    
    return (
      <FieldItem titleSx={{ flex: 1 }} contentsSx={{ flex: 2 }} title={title} inline={inline} centerTitle={center}>
        <TextField {...props} />
      </FieldItem>
    );
  };
      