import { SxProps, TableRowProps } from "@mui/material"

export interface LooseObject {
    [key: string]: any
}
export interface ChartOptions extends LooseObject {}

export interface SelectOption<ItemType = any> {
    label: string | React.ReactNode
    value: string | number | null
    [key: string]: any
    item?: ItemType
}

export enum StatusLevel {
    OK = 'OK',
    WARNING = 'WARNING',
    DANGER = 'DANGER',
}

export interface CsvRow {
    [key: string]: string;
  }
  
  
export type RowValue = string | number | React.ReactNode | undefined | null;

export interface TableRowData<T = any> {
    key?: string;
    title?: string;
    titleRowSpan?: number;
    titleSx?: SxProps;
    valueSx?: SxProps;
    values: RowValue[];
    rowProp?: TableRowProps;
    valueKeys?: string[];
    ignoreTitle?: boolean;
    seperator?: boolean;
    item?: T;
    rows?: TableRowData[];
}
