import React, { Component } from 'react'
import { connect } from 'react-redux';
import RiskResults from './RiskResults'
import { riskAversionQuestionUpdate, lossAversionQuestionUpdate, renderCharts, runRiskAnalyzerResults, getLatestRiskPreferences, getRiskAnalyzer } from '../../actions/riskPreferences'
import RiskAnalyzerResults from './RiskAnalyzerResults';
import { Size } from '../common/styled/SizeAwareBox';
import { withClientParameter, WithClientParameterProps } from '../common/withClientParameter';
import { hasClientIdChanged } from '../../common/utils';
import PagerContainer from './PagerContainer';
import { UpdateQuestionRequest } from '../../actions/types/requests';

interface RiskPreferencesProps extends WithClientParameterProps {
    riskAversionQuestionUpdate: (update: UpdateQuestionRequest) => void
    lossAversionQuestionUpdate: (update: UpdateQuestionRequest) => void
    renderCharts: (type?: string) => void
    runRiskAnalyzerResults: () => void
    getLatestRiskPreferences: () => void
    getRiskAnalyzer: () => void
    riskAnalyzer: boolean
    enterprise: boolean
    activeIndex: number
}

class RiskPreferences extends Component <RiskPreferencesProps>{

    constructor(props: RiskPreferencesProps) {
        super(props)
    }
    
    componentDidMount() {
        this.props.getLatestRiskPreferences()
        if(this.props.riskAnalyzer) {
            this.props.getRiskAnalyzer()
        }
    }

    onRiskAversionChoiceSelected = ({ questionNumber, value }: UpdateQuestionRequest) => {
        this.props.riskAversionQuestionUpdate({ questionNumber, value })
    }

    onLossAversionChoiceSelected = ({ questionNumber, value }: UpdateQuestionRequest) => {
        this.props.lossAversionQuestionUpdate({ questionNumber, value })
    }

    componentDidUpdate(prevProps: RiskPreferencesProps) {
        const { activeIndex: prevActiveIndex } = prevProps
        const { activeIndex, riskAnalyzer } = this.props

        if(prevActiveIndex != activeIndex && activeIndex == 0) {
            if(!riskAnalyzer) {
                this.props.renderCharts('Preferred')
            }
        }

        if(hasClientIdChanged(prevProps, this.props)) {
            this.props.getLatestRiskPreferences()
            if(riskAnalyzer) {
                this.props.getRiskAnalyzer()
            }
        }
    }

    onPageHeight = ({ height: pageHeight }: Size) => {
        if(pageHeight) {
            this.setState({ pageHeight: pageHeight + 25 })
        }
    }

    render() {
        const { activeIndex, riskAnalyzer } = this.props
        const ResultsComponent = riskAnalyzer ? RiskAnalyzerResults : RiskResults

        return (
            <PagerContainer activeIndex={activeIndex} gesturesEnabled={false}>
                <ResultsComponent />
            </PagerContainer>
        )
    }

}

const mapStateToProps = ({ session }, componentProps) => {
    const { membership, enterprise, selectedKey } = session
    const riskAnalyzer = membership == 'risk_analyzer'
    
    const activeIndex = session[`${selectedKey}ActiveIndex`]

    return { activeIndex, riskAnalyzer, enterprise, ...componentProps }
}

export default withClientParameter(connect(mapStateToProps, { getLatestRiskPreferences, runRiskAnalyzerResults, renderCharts, riskAversionQuestionUpdate, lossAversionQuestionUpdate, getRiskAnalyzer })(RiskPreferences))
