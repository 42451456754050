import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { Box } from '@mui/material';
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import { Page } from '../Page';
import { BaseProps } from '../types';
import { PortfolioMapDetail } from '../../actions/types/investmentPortfolio';
import { getPortfolioMapTableDetails } from '../../actions/investmentPortfolio';
import { getGuides } from '../../actions/session';
import { CopyItem } from '../../actions/types/copy';
import { GuidePanel } from './GuidePanel';
import { ModelPortfolios } from './Guides/ModelPortfolios';

interface GuideProps extends BaseProps {
    loading: boolean
    enterprise: boolean
    membership: string
    details: PortfolioMapDetail[]
    guides: CopyItem[]
    getPortfolioMapTableDetails: () => void
    getGuides: () => void
}

export const GuideComponent: FunctionComponent<GuideProps> = ({ getPortfolioMapTableDetails, getGuides, guides, loading, enterprise, membership, details }) => {
    const [expandedItem, setExpandedItem] = React.useState<string>('')

    const onExpandedChanged = React.useCallback((expanded: boolean, id: string) => {
        if(expanded) {
            setExpandedItem(id)
        } else if(id === expandedItem) {
            setExpandedItem('')
        }
    }, [expandedItem])

    React.useEffect(() => {
        getPortfolioMapTableDetails()
        getGuides()
    }, [])

    if(loading) {
        return <></>
    }

    return (
        <Page>
            <Box sx={{ marginTop: '5rem', width: '100%', font: FONT_STYLES_BY_TYPE.text }}>
                {guides.map(({id, title, contents }) => {
                        return (
                            <GuidePanel id={`${id}`} title={title} expanded={expandedItem === `${id}`} onExpandedChanged={onExpandedChanged} key={`${id}`} >
                                <div dangerouslySetInnerHTML={{ __html: contents }}></div>
                            </GuidePanel>                                
                        )
                    
                })}
                <ModelPortfolios title={''} enterprise={enterprise} membership={membership} models={details} />
            </Box>
        </Page>
    )
}

const mapStateToProps = ({ session, investmentPortfolio }) => {
    const { enterprise, membership, loading, guides } = session
    const { details } = investmentPortfolio

    return { enterprise, membership, details, loading, guides }
}

export default connect(mapStateToProps, { getPortfolioMapTableDetails, getGuides })(GuideComponent)