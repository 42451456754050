import React, { FunctionComponent } from 'react'
import { Box } from "@mui/material"
import { BaseProps } from '../types'

export const PageContents: FunctionComponent<BaseProps> = ({ children, sx = { }}) => {
    return (
        <Box sx={{
            display: 'flex',
            flex: 1,
            border: '1px solid #92d2e2',
            backgroundColor: '#fff',
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
            padding: '3rem',
            marginBottom: '1rem',
            flexDirection: 'column',
            ...sx
        }}>
            {children}
        </Box>
    )
}