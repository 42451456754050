import { ABSOLUTE_RISK, INVESTMENT_POLICY_STATEMENT_RECEIVED, LEGAL_AND_REGULATORY_FACTORS, LIQUIDITY, LOGOUT, PRINT_DETAILS_RECEIVED, PROFILE_RESET, RELATIVE_RISK, TAX_CONCERNS, UNIQUE_CIRCUMSTANCES } from '../actions/types/actions';

export interface InvestmentPolicyStatementState {
  absolute_risk: string
  relative_risk: string
  liquidity: string
  tax_concerns: string
  legal_and_regulatory_factors: string
  unique_circumstances: string
  time_horizon: number
}

const INITIAL_STATE: InvestmentPolicyStatementState = {
    absolute_risk: '',
    relative_risk: '',
    liquidity: '',
    tax_concerns: '',
    legal_and_regulatory_factors: '',
    unique_circumstances: '',
    time_horizon: 0,
};

export default (state = INITIAL_STATE, action): InvestmentPolicyStatementState => {
  const { payload, type } = action

  switch (type) {
    case INVESTMENT_POLICY_STATEMENT_RECEIVED:
      return { ...state, ...payload }
    case PRINT_DETAILS_RECEIVED:
      return { ...state, ...(payload.ips || { }) }
    case ABSOLUTE_RISK:
      return { ...state, absolute_risk: payload }
    case RELATIVE_RISK:
      return { ...state, relative_risk: payload }
    case LIQUIDITY:
      return { ...state, liquidity: payload }
    case TAX_CONCERNS:
      return { ...state, tax_concerns: payload }
    case LEGAL_AND_REGULATORY_FACTORS:
      return { ...state, legal_and_regulatory_factors: payload }
    case UNIQUE_CIRCUMSTANCES:
      return { ...state, unique_circumstances: payload }
    case PROFILE_RESET:
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

