import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getDomains, getPortfolioMapsForDomain, parameterAdded, parameterRemoved, domainSelected, loadPortfolioMap, 
        assetAdded, assetRemoved, onValueChanged, saveValueUpdates, saveDetailUpdates, addNewDetails, 
        onSaveStatsPressed, onAccountAssetChanged, onParameterMappingAdded, onParameterMappingRemoved, onClearAndReset, 
        portfolioMapReset, portfolioMapRename, portfolioMapAdd, portfolioMapRemove, deleteDetails } from '../../../actions/portfolioMap'
import { ParameterMapping } from '../../../actions/types/portfolioMap'

import PortfolioMapPanel from './PortfolioMapPanel';
import ParameterList from '../../common/ParameterList';
import AssetsList from './AssetsList';

import PortfolioMapValues from './PortfolioMapValues';
import ParameterMappings from './ParameterMappings';
import PortfolioMapTableDetails from './PortfolioMapTableDetails';
import { AssetValue, PortfolioMap, PortfolioMapAsset, PortfolioMapDetail } from '../../../actions/types/investmentPortfolio';
import { SelectOption } from '../../../common/types';
import { Box } from '@mui/material';
import { Select } from '../../common/styled/Select';
import Button from '../../common/Button';
import Page from '../../Page/Page';
import { FONT_STYLES_BY_TYPE } from '../../common/styled/Text';
import { Asset } from '../../../actions/types/customAssets';
import { AdminPage } from '../AdminPage';

interface Props {
    getDomains: () => void
    getPortfolioMapsForDomain: (domain: string) => void
    parameterAdded: (type: string, value: string) => void
    parameterRemoved: (type: string, value: string) => void
    domainSelected: (value: string) => void
    loadPortfolioMap: (domain: string, portfolioMapId: number) => void
    assetAdded: (assetName: string) => void
    assetRemoved: (assetId: number) => void
    onValueChanged: (value: AssetValue) => void
    saveValueUpdates: () => void
    saveDetailUpdates: () => void
    onSaveStatsPressed: () => void
    onAccountAssetChanged: (asset: PortfolioMapAsset, updatedAsset: PortfolioMapAsset) => void
    onParameterMappingAdded: (parameter: string, from: string, to: string) => void
    onParameterMappingRemoved: (item: string) => void
    onClearAndReset: () => void
    portfolioMapReset: () => void
    portfolioMapRename: (id: number, name: string) => void
    addNewDetails: () => void
    deleteDetails: (id: number) => void
    portfolioMapAdd: (name: string) => void
    portfolioMapRemove: (portfolioMapId: number) => void
    domains: string[],
    assets: AssetValueCollection[]
    values: AssetValue[]
    details: PortfolioMapDetail[]
    stats: any[]
    statValues: any[]
    lambda: string[]
    gamma: string[]
    accountAssets: Asset[]
    readOnlyParameters: boolean
    parameterMappings: ParameterMapping[]
    domainLoaded: boolean
    portfolioMapLoaded: boolean
    portfolioMaps: PortfolioMap[]
}

interface State {
    selectedDomain?: string,
    selectedPortfolioMap?: PortfolioMap,
    editingPortfolioMap: boolean,
    editingPortfolioMapStats: boolean,
    domains: SelectOption[],
    stats: string[],
    assets: PortfolioMapAsset[],
    details: string[],
    editingTableDetails: boolean
}

class PortfolioMapTool extends Component <Props, State>{

    constructor(props) {
        super(props)
        this.props.getDomains()
        this.state = {
            selectedDomain: undefined,
            selectedPortfolioMap: undefined,
            editingPortfolioMap: false,
            editingTableDetails: false,
            editingPortfolioMapStats: false,
            domains: [],
            stats: [],
            assets: [],
            details: [],
        }
    }

    componentDidUpdate(prevProps) {
        const { domains: prevDomains, assets: prevAssets, values: prevValues, details: prevDetails } = prevProps
        const { domains, assets, values, details } = this.props

        if(prevDomains != domains) {
            const domainOptions = domains.map((domain) => {
                return { label: domain, value: domain }
            })

            this.setState({ domains: domainOptions })
        }

        if(prevAssets != assets || prevValues != values) {
            const assetWithValues = assets.map((asset) => {
                const assetValueIds = asset.valueIds
                const assetValues = Object.keys(values).filter((key: string) => assetValueIds.includes(parseInt(key))).map((key) => values[key])

                return { ...asset, values: assetValues }
            })

            this.setState({ assets: assetWithValues })
        }

        const prevDetailKeys = Object.keys(prevDetails)
        const detailKeys = Object.keys(details)
        if(prevDetailKeys.join(',') !== detailKeys.join(',')) {
            this.setState({ details: detailKeys })
        }
        
        
    }

    onItemAdded = (type, value) => {
        this.props.parameterAdded(type, value)
    }

    onItemRemoved = (type, value) => {
        if (!confirm(`Are you sure you want to remove this ${type}`)) {
            return
        }

        this.props.parameterRemoved(type, value)
    }

    onAssetAdded = (type, value) => {
        this.props.assetAdded(value)
    }

    onAssetRemoved = (type, value) => {
        if (!confirm(`Are you sure you want to remove this ${type}`)) {
            return
        }
        
        this.props.assetRemoved(value.id)
    }

    onParameterMappingAdded = (parameter, from, to) => {
        this.props.onParameterMappingAdded(parameter, from, to)
    }

    onParameterMappingRemoved = (item) => {
        if (!confirm(`Are you sure you want to remove this parameter removed`)) {
            return
        }
        
        this.props.onParameterMappingRemoved(item)
    }

    onDomainSelected = ({ value }) => {
        this.setState({ selectedDomain: value }, () => {
            this.props.getPortfolioMapsForDomain(value)
        })
    }

    onLoadPortfolioMapsForDomain = () => {
        const { selectedDomain } = this.state
        if(!selectedDomain) {
            return
        }

        this.setState({ selectedPortfolioMap: undefined })
        this.props.portfolioMapReset()
        this.props.getPortfolioMapsForDomain(selectedDomain)
    }

    onPortfolioMapSelected = (selectedPortfolioMap) => {
        const { selectedDomain } = this.state
        if(!selectedDomain) {
            return
        }

        this.setState({ selectedPortfolioMap }, () => {
            this.props.loadPortfolioMap(selectedDomain, selectedPortfolioMap.id)
        })

    }

    onLoadPortfolioMap = () => {
        const { selectedDomain, selectedPortfolioMap } = this.state
        if(!selectedDomain || !selectedPortfolioMap) {
            return
        }

        this.props.loadPortfolioMap(selectedDomain, selectedPortfolioMap.id)
    }

    onPortfolioMapTableSave = () => {
        this.setState({ editingPortfolioMap: false })
        this.props.saveValueUpdates()
    }

    onPortfolioMapTableEdit = () => {
        this.setState({ editingPortfolioMap: true })
    }

    onPortfolioMapStatsTableSave = () => {
        this.setState({ editingPortfolioMapStats: false })
        this.props.onSaveStatsPressed()
    }

    onPortfolioMapStatsTableEdit = () => {
        this.setState({ editingPortfolioMapStats: true })
    }

    onAccountAssetChanged = (asset: PortfolioMapAsset, updatedAsset: PortfolioMapAsset) => {
        this.props.onAccountAssetChanged(asset, updatedAsset)
    }

    onClearAndResetPressed = () => {
        if (!confirm(`Are you sure you want to reset this portfolio map, everything will be erased`)) {
            return
        }
        this.props.onClearAndReset()
    }
    
    onPortfolioMapRename = ({ id }: PortfolioMap, name: string) => {
        this.props.portfolioMapRename(id, name)
    }

    onPortfolioMapRemoved = (type, { id }) => {
        if (!confirm(`Are you sure you want to remove this portfolio map`)) {
            return
        }

        this.props.portfolioMapRemove(id)
    }

    onPortfolioMapTableDetailsSave = () => {
        this.setState({ editingTableDetails: false })
        this.props.saveDetailUpdates()
    }

    onPortfolioMapTableDetailsEdit = () => {
        this.setState({ editingTableDetails: true })
    }

    onPortfolioMapTableDetailsAdd = () => {
        this.props.addNewDetails()
    }

    renderDomainData = () => {
        const { lambda, gamma, accountAssets, parameterMappings } = this.props
        const { details, assets, editingPortfolioMap, editingTableDetails } = this.state

        return (
            <>
                <Box sx={{
                    display: 'flex',
                    flexDirection:' row',
                }}>
                    <PortfolioMapPanel title="Assets" >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                            height: '30rem',
                        }}>
                            <Box sx={{
                                flex: 1,
                                margin: '1rem',
                                flexDirection: 'column',
                            }}>
                                <AssetsList
                                    title={'Assets'}
                                    type={'assets'}
                                    items={assets}
                                    accountAssets={accountAssets}
                                    onItemAdded={this.onAssetAdded}
                                    onItemRemoved={this.onAssetRemoved}
                                    onItemChanged={this.onAccountAssetChanged} 
                                />
                            </Box>
                        </Box>
                    </PortfolioMapPanel>
                
                    <PortfolioMapPanel title="Parameter Mapping">
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                        }}>
                            <Box sx={{
                                flex: 1,
                                flexDirection: 'column',
                                paddingLeft: '2rem',
                                paddingRight: '2rem',
                            }}>
                                <ParameterMappings 
                                    items={parameterMappings}
                                    onItemAdded={this.onParameterMappingAdded}
                                    onItemRemoved={this.onParameterMappingRemoved}
                                />
                            </Box>
                        </Box>
                    </PortfolioMapPanel>
                </Box>

                <PortfolioMapPanel 
                    title="Portfolio Map Table Details"
                    actionView={() => {
                        const leftItem = editingTableDetails ? <Button autoFit onClick={this.onPortfolioMapTableDetailsSave}>Save</Button> : <Button autoFit onClick={this.onPortfolioMapTableDetailsEdit}>Edit</Button>
                        const rightItem = editingTableDetails ? <Button autoFit onClick={this.onPortfolioMapTableDetailsAdd}>Add +</Button> : undefined

                        return (
                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'space-between' }}>
                                {leftItem}
                                {rightItem}
                            </Box>
                        )
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        overflow: 'hidden',
                        overflowY: 'scroll',
                        height: '30rem',
                    }}>
                        <Box sx={{
                            flex: 1,
                            margin: '1rem',
                            flexDirection: 'column',
                        }}>
                            {details.map((id) => {
                                return <PortfolioMapTableDetails id={id} editMode={editingTableDetails} onDelete={this.props.deleteDetails} />
                            })}
                        </Box>
                    </Box>
                </PortfolioMapPanel>
                <PortfolioMapPanel title="Portfolio Map Tables" contentsSx={{ overflowX: 'scroll' }} actionView={() => {
                    return editingPortfolioMap ? <Button autoFit sx={{ width: '10rem'}} onClick={this.onPortfolioMapTableSave}>Save</Button> : <Button autoFit sx={{ width: '10rem'}} onClick={this.onPortfolioMapTableEdit}>Edit</Button> 
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        padding: '1rem',
                    }}>
                        <Box sx={{
                            flex: 1,
                            flexDirection: 'column',
                            paddingLeft: '2rem',
                            paddingRight: '2rem',
                        }}>
                            {lambda.map((lambda) => {
                                return (
                                    <Box id={`${lambda}-0`}>
                                            <PortfolioMapValues assets={assets} lambda={`${lambda}`} gammas={gamma} editMode={editingPortfolioMap} onValueChanged={this.props.onValueChanged} />
                                    </Box>              
                                )
                            })}
                        </Box>
                    </Box>
                </PortfolioMapPanel>
            </>
        )
    }

    render() {
        const { portfolioMaps, domainLoaded, portfolioMapLoaded, lambda, gamma } = this.props
        const { domains, selectedPortfolioMap } = this.state

        return (
            <AdminPage containerComponent={Box}>
                <Box sx={{ flex: 1, width: '100%'}}>
                    <PortfolioMapPanel title="Domains" sx={{ width: '100%' }}>
                        <Box>Domains</Box>
                        <Select sx={{ width: '25rem' }} options={domains} onChange={this.onDomainSelected} />
                    </PortfolioMapPanel>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                    { domainLoaded &&
                        <PortfolioMapPanel sx={{ flex: 'unset' }} title="Portfolio Maps">
                            <Box>Portfolio Maps</Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}>
                                <Select sx={{ width: '25rem', marginRight: '1rem' }} options={portfolioMaps} onChange={this.onPortfolioMapSelected} value={selectedPortfolioMap} />
                                <Box sx={{ width: '8rem' }}>
                                    { portfolioMapLoaded && 
                                        <Button autoFit sx={{ marginRight: '1.5rem' }} variant="danger" onClick={this.onClearAndResetPressed}>Reset</Button> 
                                    }
                                </Box>
                            </Box>
                            <ParameterList sx={{ marginTop: '1rem' }} title={'Portfolio Maps'} type={'portfolioMaps'} items={portfolioMaps} onItemAdded={(type, item) => this.props.portfolioMapAdd(item)} onItemRemoved={this.onPortfolioMapRemoved} onItemChanged={this.onPortfolioMapRename} editableItems />
                        </PortfolioMapPanel>
                    }
                    { domainLoaded && portfolioMapLoaded &&
                        <PortfolioMapPanel sx={{ flex: 'unset' }} title="Portfolio Map Parameters">
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                height: '30rem',
                            }}>
                                <Box sx={{
                                    flex: 1,
                                    margin: '1rem',
                                    flexDirection: 'column',
                                }}>
                                    <ParameterList title={'Lambda'} type={'lambda'} items={lambda} onItemAdded={this.onItemAdded} onItemRemoved={this.onItemRemoved} />
                                </Box>
                                <Box sx={{
                                    flex: 1,
                                    margin: '1rem',
                                    flexDirection: 'column',
                                }}>
                                    <ParameterList title={'Gamma'} type={'gamma'} items={gamma}  onItemAdded={this.onItemAdded} onItemRemoved={this.onItemRemoved} />
                                </Box>
                            </Box>
                        </PortfolioMapPanel>
                    }
                    </Box>
                    {portfolioMapLoaded && this.renderDomainData()}
                </Box>
            </AdminPage>
        )
    }
}

const mapStateToProps = ({ portfolioMapTool }) => {
    const { lambda, gamma, readOnlyParameters, accountAssets,
            domains, assets, values, stats, statValues,
            parameterMappings, error, loadedDomain, loadedPortfolioMapId, portfolioMaps, details } = portfolioMapTool

    const domainLoaded = loadedDomain != undefined
    const portfolioMapLoaded = loadedPortfolioMapId != 0
    
    return { domains, assets, values, stats, statValues, lambda, gamma, accountAssets, readOnlyParameters,
            parameterMappings, error, domainLoaded, portfolioMapLoaded, portfolioMaps, details }
}

export default connect(mapStateToProps, 
                        { getDomains, getPortfolioMapsForDomain, parameterAdded, parameterRemoved, domainSelected, loadPortfolioMap,
                          assetAdded, assetRemoved, onValueChanged, saveValueUpdates, saveDetailUpdates, onSaveStatsPressed,
                          onAccountAssetChanged, onParameterMappingAdded, onParameterMappingRemoved, onClearAndReset, portfolioMapReset, portfolioMapRename,
                          addNewDetails, deleteDetails, portfolioMapAdd, portfolioMapRemove })(PortfolioMapTool)