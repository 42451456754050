import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { TextField, TextFieldItem } from '../common/FormField';
import { cancelSubscription, deleteAccount, resumeSubscription } from '../../actions/account';
import { Subscription } from '../../actions/types/account';
import Button from '../common/Button';
import { addError, addStatusNotification, updateNotification } from '../../actions/notifications';
import { AccountPanel } from './AccountPanel';
import ConfirmModal from '../common/ConfirmModal';
import Text from '../common/styled/Text';
import { SubscriptionUpdate } from '../../actions/types/requests';
import { StatusNotificationItem } from '../../actions/types/notifications';

interface SubscriptionDetailsProps extends Subscription {
    cancel: () => void
    delete: () => void
    addError: (title: string, message: string) => void
    addStatusNotification: (message: string) => Promise<StatusNotificationItem>
    updateNotification: (notification: StatusNotificationItem) => void
    onSubscriptionUpdate: (subscription: SubscriptionUpdate) => void
}

const SubscriptionDetails: FunctionComponent<SubscriptionDetailsProps> = ({ onSubscriptionUpdate, addStatusNotification, updateNotification, plan, start_date, current_period_end, cancel_at_period_end, show }) => {
    const [showConfirmCancel, setShowConfirmCancel] = React.useState<boolean>(false);
    const [showConfirmContinue, setShowConfirmContinue] = React.useState<boolean>(false);
    const [showConfirmDelete, setShowConfirmDelete] = React.useState<boolean>(false);
    const [confirmDeleteText, setConfirmDeleteText] = React.useState<string>('');

    const onShowConfirmCancel = React.useCallback(() => {
        setShowConfirmCancel(true)
    }, [])
    const onShowConfirmContinue= React.useCallback(() => {
        setShowConfirmContinue(true)
    }, [])
    const onShowConfirmDelete= React.useCallback(() => {
        setShowConfirmDelete(true)
    }, [])
    const onHideModals = React.useCallback(() => {
        setShowConfirmCancel(false)
        setShowConfirmContinue(false)
        setShowConfirmDelete(false)
    }, [])
    

    const onResumeSubscription = React.useCallback(async() => {
        onHideModals()
        const notification = await addStatusNotification('Resuming Subscription!')
        const { success, message = '' } = await resumeSubscription()
        
        if(success) {
            updateNotification({ ...notification, content: 'Subscription resumed!', duration: 2000, showSpinner: false,  })
            onSubscriptionUpdate({ cancel_at_period_end: undefined })
        } else {
            updateNotification({ ...notification, type: 'Error', title: 'Subscription', content: message, duration: 2000  })
        }
    }, [onSubscriptionUpdate])

    const onCancelSubscription = React.useCallback(async() => {
        onHideModals()
        const notification = await addStatusNotification('Cancelling Subscription!')
        const { success, message = '' } = await cancelSubscription()
        
        if(success) {
            updateNotification({ ...notification, content: 'Subscription cancelled!', duration: 2000, showSpinner: false,  })
            onSubscriptionUpdate({ cancel_at_period_end: current_period_end })
        } else {
            updateNotification({ ...notification, type: 'Error', title: 'Subscription', content: message, duration: 2000  })
        }
        
    }, [onSubscriptionUpdate, current_period_end])
    const onDeleteAccount = React.useCallback(async() => {
        if(confirmDeleteText !== 'delete') {
            return
        }
        onHideModals()

        const notification = await addStatusNotification('Deleting Account!')
        const { success, message = '' } = await deleteAccount(confirmDeleteText)
        
        if(success) {
            updateNotification({ ...notification, content: 'Acount Deleted!', duration: 2000, showSpinner: false,  })
            window.location.href = '/'
        } else {
            updateNotification({ ...notification, type: 'Error', title: 'Account', content: message, duration: 2000  })
        }
        

    }, [confirmDeleteText])

    if(!show) {
        return <div></div>
    }

    return (
        <AccountPanel
            title={'Subscription'}>
            <ConfirmModal
                title='Subscription'
                visible={showConfirmCancel}
                onCancel={onHideModals}
                onConfirm={onCancelSubscription}
                confirmVariant={'danger'}
                confirmLabel={'Confirm'}
            >
            <Text asDiv type={'form_header'} sx={{ marginBottom: '5rem' }} >Please press 'Confirm' to confirm you want to cancel your subscription</Text>
            <Text type={'table_header'} asDiv>Note:</Text>
            <Text asDiv>You'll still have access to our services until the end of the billing cycle on {current_period_end}. <br />Also note that all account data will be saved for future re-subscription unless you fully delete account from Accounts page.</Text>
            </ConfirmModal>
            <ConfirmModal
                title='Subscription'
                visible={showConfirmContinue}
                onCancel={onHideModals}
                onConfirm={onResumeSubscription}
                confirmLabel={'Confirm'}
            >
            <Text asDiv sx={{ marginBottom: '2rem' }} >Would you like to continue your subscription?</Text>
            <Text asDiv>Pressing confirm will cancel your request to end your membership and your subscription will remain active.</Text>
            </ConfirmModal>
            <ConfirmModal
                title='Account Deletion'
                visible={showConfirmDelete}
                onCancel={onHideModals}
                onConfirm={onDeleteAccount}
                confirmLabel={'Confirm'}
                confirmVariant={'danger'}
            >
            <Text asDiv>Please confirm you would like to delete all data associated with this account by entering the word 'delete' below?</Text>
            <TextField
                sx={{ marginBottom: '2rem', width: '100%', }} 
                value={confirmDeleteText}
                onValueChange={setConfirmDeleteText}
            />
            <Text type={'table_header'} asDiv>Note:</Text>
            <Text asDiv sx={{ marginBottom: '2rem' }}>Your subscription will automatically be canceled (if you haven't already canceled) and you will no longer be able to access this account.</Text>
            <Text type={'h4'} asDiv sx={{ color: 'red' }} >This action cannot be undone.</Text>
            </ConfirmModal>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '2rem' }}>
                <TextFieldItem
                    sx={{ 
                        width: {
                            xs: '100%',
                            md: '35rem'
                        }
                    }}
                    title={'Plan'}
                    value={plan}
                    readOnly
                    inline
                />
                <TextFieldItem
                    sx={{ 
                        width: {
                            xs: '100%',
                            md: '35rem'
                        }
                    }}
                    title={'Subscription Start'}
                    value={start_date}
                    readOnly
                    inline
                />
                <TextFieldItem
                    sx={{ 
                        width: {
                            xs: '100%',
                            md: '35rem'
                        }
                    }}
                    title={cancel_at_period_end ? 'Canel Date' : 'Next Billing Date'}
                    value={current_period_end}
                    readOnly
                    inline
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '1rem', paddingRight: '1rem', }}>
                {cancel_at_period_end && 
                    <Button 
                        sx={{ width: '15rem', height: '4.5rem', minHeight: 'unset', background: 'green', marginRight: '.5rem' }}
                        title={<>Keep<br />Subscription</>}
                        onClick={onShowConfirmContinue}
                    />
                }
                {!cancel_at_period_end && 
                    <Button 
                        sx={{ width: '15rem', height: '4.5rem', minHeight: 'unset', background: 'red', marginRight: '.5rem' }}
                        title={'Cancel Subscription'}
                        onClick={onShowConfirmCancel}
                    />
                }
                <Button 
                    sx={{ width: '15rem', height: '4.5rem', minHeight: 'unset', background: 'red', marginRight: '.5rem' }}
                    title={'Delete Account'}
                    onClick={onShowConfirmDelete}
                />
            </Box>
        </AccountPanel>
    )
}

export default connect(null, { addError, addStatusNotification, updateNotification })(SubscriptionDetails)