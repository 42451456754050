import { Box } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useRef } from 'react';

interface LineRendererProps {
  lineType: string;
}

const LineRenderer: React.FC<LineRendererProps> = ({ lineType }) => {
  return (
    <Box sx={{ display: 'flex', height: '1rem', width: '10rem', alignItems: 'center' }}>
      <Box sx={{ height: '.5rem', width: '15rem', overflow: 'hidden' }}>
        <HighchartsReact
          key={lineType}
          highcharts={Highcharts}
          options={{
              chart: {
                  backgroundColor: 'transparent',
                  style: {
                      fontSize: '12px'
                  },
                  events: {
                      load: function (this: any) {
                          this.renderer.path(['M', 0, 0, 'L', 100, 0])
                            .attr({
                              'stroke-width': 2,
                              stroke: 'black',
                              dashstyle: lineType,
                            }).add();
                      }
                  }
              },
              title: {
                  style: {
                      display: 'none',
                  },
                  align: 'left'
              },

          }} 
        />
      </Box>
    </Box>
  );
};

export default LineRenderer;
