import axios from 'axios';
import { DispatchFunction } from '../reducers';
import { PERFORMANCE_ATTRIBUTION_LOADING, PERFORMANCE_ATTRIBUTION_RECEIVED, SIMPLIFY_RISK_ANALYZER_LOADING, SIMPLIFY_RISK_ANALYZER_LOADING_RISK_OUTPUT, SIMPLIFY_RISK_ANALYZER_LOADING_USER_INPUTS, SIMPLIFY_RISK_ANALYZER_RECEIVED } from './types/actions';
import { addError } from './notifications';
import { PerformanceAttributionProps } from '../reducers/PerformanceAttributionReducer';
import moment from 'moment';
import { ChartOptions } from '../components/OptimizedPortfolio/chartOptions';
import { LooseObject, SelectOption, TableRowData } from '../common/types';
import { InternalPerformanceAttributionTickers, ShockValue, Shocks } from './types/simplifyTools';

const KEY_MAPPING: { [key: string]: string } = {
    'All': 'Total',
    'Fund Total Return (NAV)': 'NAV Total Return',
    'total_portfolio': 'Total Portfolio',
    'all_positions': 'All Positions',
}

const PNL_FORMATTING: {[key: string]: Intl.NumberFormatOptions } = {
    'Pos Qty': { minimumFractionDigits: 0, maximumFractionDigits: 0 },
    'Yest Px': { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    'Last Px': { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    '$ Value': { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
    '% Notional': { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 },
    '$ PnL': { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
    '% PnL': { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 },
    'Delta': { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    'IV': { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 },
    'Beta': { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 },
    'Total $Delta': { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
    'Total $Gamma': { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
    'Total Vega': { minimumFractionDigits: 0, maximumFractionDigits: 0 },
    'Total Theta': { minimumFractionDigits: 0, maximumFractionDigits: 0 },
    'Theta(bps)': { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 },
    'Duration': { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    'Total Duration': { minimumFractionDigits: 2, maximumFractionDigits: 2 },
}


const PERFORMANCE_ATTRIBUTION_ENDPOINT = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/internal/performance-attribution`
const PERFORMANCE_ATTRIBUTION_TICKERS_ENDPOINT = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/internal/performance-attribution/tickers`

const RISK_ANALYZER_TICKERS_ENDPOINT = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/internal/risk-analyzer/tickers`
const RISK_ANALYZER_INPUTS_ENDPOINT = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/internal/risk-analyzer-user-inputs`
const RISK_ANALYZER_ENDPOINT = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/internal/risk-analyzer`


export const getPerformanceAttributionTickers = () => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        try {
            const { data } = await axios.get(`${PERFORMANCE_ATTRIBUTION_TICKERS_ENDPOINT}?t=${moment().valueOf()}`)
            const tickers: LooseObject = data.items ?? {}
            const keys = Object.keys(tickers)
            const tickerOptions: SelectOption[] = keys.map((ticker: string) => ({ value: ticker, label: ticker }))
            const tickersMapped = keys.reduce((acc: InternalPerformanceAttributionTickers, key: string) => {
                return {
                    ...acc,
                    [key]: {
                        start: moment(tickers[key].start).toDate(),
                        end: moment(tickers[key].end).toDate(),
                    }
                }
            }, {})

            dispatch({ type: PERFORMANCE_ATTRIBUTION_RECEIVED, payload: { tickers: tickersMapped, tickerOptions } })
        } catch (error) {
            dispatch(addError('Performance Attribution', 'Failed to get performance attribution tickers'))
        }
    }
}

export const runPerformanceAttribution = (ticker: string, start: Date, end: Date) => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        try {
            dispatch({ type: PERFORMANCE_ATTRIBUTION_LOADING, payload: true })
            const { data } = await axios.post(PERFORMANCE_ATTRIBUTION_ENDPOINT, {
                ticker,
                startDate: moment(start).format('YYYY-MM-DD'),
                endDate: moment(end).format('YYYY-MM-DD'),
            })
            const { description_table, total_ret_table, ret_by_asset_class_table, best_trades, worst_trades, total_return_plot_data, asset_class_return_data, worst_asset_class_ret_drivers, best_asset_class_ret_drivers, asset_class_level_csv, security_level_csv } = data
            const totalReturnsData = Object.keys(total_ret_table).map((key) => ({ title: key, key, values: [total_ret_table[key]] }))
            const performanceAttributionData = Object.keys(description_table).map((key) => ({ title: key, key, values: [description_table[key]] }))
            const assetClassReturns = Object.keys(ret_by_asset_class_table).map((key) => ({ title: KEY_MAPPING[key] ?? key, key: KEY_MAPPING[key] ?? key, values: [ret_by_asset_class_table[key]] }))

            const bestTrades = Object.keys(best_trades).map((key) => ({ ticker: key, value: best_trades[key] }))
            const worstTrades = Object.keys(worst_trades).map((key) => ({ ticker: key, value: worst_trades[key] }))
            const total = Math.max(bestTrades.length, worstTrades.length)
            const topTensData = []
            for (let i = 0; i < total; i++) {
                const best =  i >= bestTrades.length ? { ticker: '', value: '' } : bestTrades[i]
                const worst = i >= worstTrades.length ? { ticker: '', value: '' } : worstTrades[i]
                topTensData.push({ key: best.ticker ?? `Row ${i}`, values: [best.ticker, best.value, worst.ticker, worst.value] })
            }

            const assetClassTopTenKeys = [...new Set([...Object.keys(worst_asset_class_ret_drivers), ...Object.keys(best_asset_class_ret_drivers)])].filter((key) => Object.keys(worst_asset_class_ret_drivers[key]?.length > 0 || best_asset_class_ret_drivers[key]?.length > 0))
            const assetClassTopTenOptions = assetClassTopTenKeys.map((key) => ({ value: key, label: key }))
            const assetClassTopTenData = assetClassTopTenKeys.reduce((acc: any, key: string) => {
                const asset_class_best_trades = best_asset_class_ret_drivers[key] ?? {  }
                const asset_class_worst_trades = worst_asset_class_ret_drivers[key] ?? {  }
                const assetClassBestTrades = Object.keys(asset_class_best_trades).map((key) => ({ ticker: key, value: asset_class_best_trades[key] }))
                const assetClassWorstTrades = Object.keys(asset_class_worst_trades).map((key) => ({ ticker: key, value: asset_class_worst_trades[key] }))
                    
                const assetClassTopTenData = []
                for (let i = 0; i < total; i++) {
                    const assetClassBest =  i >= assetClassBestTrades.length ? { ticker: '', value: '' } : assetClassBestTrades[i]
                    const assetClassWorst = i >= assetClassWorstTrades.length ? { ticker: '', value: '' } : assetClassWorstTrades[i]
                    assetClassTopTenData.push({ key: assetClassBest.ticker ?? `Row ${i}`, values: [assetClassBest.ticker, assetClassBest.value, assetClassWorst.ticker, assetClassWorst.value] })
                }

                return {
                    ...acc,
                    [key]: assetClassTopTenData
                }
            }, {})


            const cumReturnVsBenchmarkChartData = getChartOptions(total_return_plot_data, { keys: ['Fund Total Return (NAV)', 'Benchmark Total Return'] })
            const fundPerfRelativeToBenchmarkChartData = getChartOptions(total_return_plot_data, { keys: ['Fund Relative to Benchmark'], chartType: 'area', seriesOptions: { color: 'rgba(0, 255, 0, 0.5)', negativeColor: 'rgba(255, 0, 0, 0.5)' }, yAxisPlotLines: [{ value: 0, width: 2, color: 'black' }]})
            const cumReturnByAssetClassChartData = getChartOptions(asset_class_return_data)

            dispatch({ type: PERFORMANCE_ATTRIBUTION_RECEIVED, payload: { performanceAttributionData, totalReturnsData, assetClassReturns, topTensData, cumReturnVsBenchmarkChartData, fundPerfRelativeToBenchmarkChartData, cumReturnByAssetClassChartData, assetClassTopTenData, assetClassTopTenOptions, asset_class_level_csv, security_level_csv, loading: false, hasResults: true } })

            dispatch({ type: PERFORMANCE_ATTRIBUTION_LOADING, payload: false })
        } catch (error) {
            dispatch({ type: PERFORMANCE_ATTRIBUTION_RECEIVED, payload: { loading: false, hasResults: false } })
            dispatch(addError('Performance Attribution', 'Failed to run performance attribution'))
        }
    }
};

export const onPerformanceAttributionPropUpdated = (payload: Partial<PerformanceAttributionProps>) => {
    return { type: PERFORMANCE_ATTRIBUTION_RECEIVED, payload };
}

interface PerformanceAttributionRawChartData {
    [key: string]: {
        Date: number;
        [key: string]: number;
    }
}

export const getChartOptions = (rawData: PerformanceAttributionRawChartData, options: { keys?: string[], chartType?: string, seriesOptions?: LooseObject, yAxisPlotLines?: LooseObject } = { chartType: 'spline', yAxisPlotLines: undefined}): ChartOptions => {
    const keys = options.keys ?? Object.keys(rawData[0]).filter(key => key !== 'Date')
    const data = Object.keys(rawData).map(index => rawData[index]).reduce((acc: any, item: any) => {
        for (const key of keys) {
            if (!acc[key]) {
                acc[key] = []
            }
            acc[key].push([item.Date, item[key]])
        }
        return acc;
    }, {})
    const series = Object.keys(data).map((key) => ({
        name: KEY_MAPPING[key] ?? key,
        data: data[key],
        turboThreshold: 200000,
        ...(options.seriesOptions ?? {})
    }))

    return {
        chart: {
            type: options.chartType ?? 'spline',
        },
        title: {
            text: '',
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L ',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%m/%d/%Y',
            week: '%m/%d/%Y',
            month: '%m/%Y',
            year: '%Y'
          },
          labels: {
            rotation: 45,  // Rotate labels by 45 degrees
              style: {
                fontSize: '14px',
              },
          },
        },
        yAxis: {
            title: {
                text: '',
            },
            labels: {
                style: {
                    fontSize: '14px',
                },
                formatter: function (): string {
                    return ((this as any).value * 100).toFixed(2) + '%'; // Format the label as a percentage
                },
            },
            plotLines: options.yAxisPlotLines,
        },
        series,
        legend: {
            enabled: series.length > 1,
            verticalAlign: 'top',
            symbolWidth: 35,
            symbolHeight: 1,
            symbolRadius: 1,
            squareSymbol: true,
            itemStyle: {
                fontSize: '14px',
            }
        },
        tooltip: {
          style: {
            fontSize: '14px',
          },
        },
    }
}



export const getRiskAnalyzerTickers  = (ticker: string, start: Date, end: Date) => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        try {
            const { data } = await axios.get(`${RISK_ANALYZER_TICKERS_ENDPOINT}?t=${moment().valueOf()}`)
            const tickers = data.items ?? []
            const tickerOptions: SelectOption[] = tickers.map((ticker: string) => ({ value: ticker, label: ticker }))

            dispatch({ type: SIMPLIFY_RISK_ANALYZER_RECEIVED, payload: { tickers, tickerOptions } })
        } catch (error) {
            dispatch(addError('Risk Analyzer', 'Failed to get risk analyzer tickers'))
        }
    }
};

export const onRiskAnalyzerPropUpdated = (payload: Partial<PerformanceAttributionProps>) => {
    return { type: SIMPLIFY_RISK_ANALYZER_RECEIVED, payload };
}

export const getRiskAnalyzerInputsForTicker = (ticker: string) => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        try {
            dispatch({ type: SIMPLIFY_RISK_ANALYZER_LOADING_USER_INPUTS, payload: true })
            const { data } = await axios.post(RISK_ANALYZER_INPUTS_ENDPOINT, {
                ticker,
            });

            const { fund_data: userInputs, pnl_output: pnlOutput, pnl_timestamp: pnlTimestamp } = data ?? { fund_data: {} };
            const keys = Object.keys(userInputs);
            const userInputHeaders = ['Underlying', 'Shock Type', 'Param', 'Values', keys.length ? userInputs[keys[0]].values.map((_: any, index: number) => `Slide ${index + 1}`) : []].flat()
            const userInputRows: TableRowData<ShockValue>[] = keys.map((key) => ({ key, title: key, values: [userInputs[key].shock_type, userInputs[key].param, ...userInputs[key].values] }))

            const pnlHeaders = ['Position Ticker', 'Pos Qty', 'Yest Px', 'Last Px', '$ Value', '% Notional', '$ PnL', '% PnL', 'Delta', 'IV', 'Beta', 'Total $Delta', 'Total $Gamma', 'Total Vega', 'Total Theta', 'Theta(bps)', 'Duration', 'Total Duration']
            const pnlMappedRows = Object.keys(pnlOutput).reduce((acc: any, key: string) => {
                const item = pnlOutput[key]
                const [underlying, position_ticker, position_type] = key.replace('(\'', '').replace('\')', '').replace(/'/g, '').split(', ')
                const accumUnderlying = acc[underlying] ?? { position: [], total: null }
                const values = pnlHeaders.slice(1).map((header) => {
                    const value = item[header]
                    try {
                        const formatted = value && PNL_FORMATTING[header] ? Number(value).toLocaleString('en-US', PNL_FORMATTING[header]) : value
                        if (formatted === '-0%') {
                            return '0%'
                        }

                        return formatted;
                    } catch (error) {
                        return value
                    }
                })
                const row = {
                    key: `${underlying}-${position_ticker}-${position_type}`,
                    title: position_ticker,
                    values: [...values],
                    rowProp: {
                        sx: {
                            '& td': {
                                backgroundColor: ['subtotal'].includes(position_type) ? '#EDF1FE' : undefined,
                            }
                        }
                    }
                }
                return {
                    ...acc,
                    [underlying]: {
                        total: ['subtotal', 'total'].includes(position_type) ? row : accumUnderlying.total,
                        position: position_type === 'position' ? [...accumUnderlying.position, row] : accumUnderlying.position,
                    }
                }
            }, {})
            const pnlRows = Object.keys(pnlMappedRows).map((key) => {
                const { total, position } = pnlMappedRows[key]
                return { ...total, rows: position }
            });

            dispatch({ type: SIMPLIFY_RISK_ANALYZER_RECEIVED, payload: { userInputHeaders, userInputRows, userInputs, pnlHeaders, pnlRows, pnlTimestamp, riskOutputHeaders: [], riskOutputRows: [], hasResults: true } })

            
            dispatch({ type: SIMPLIFY_RISK_ANALYZER_LOADING_USER_INPUTS, payload: false })
        } catch (error) {
            dispatch({ type: SIMPLIFY_RISK_ANALYZER_RECEIVED, payload: { hasResults: false } })
            dispatch(addError('Performance Attribution', 'Failed to run performance attribution'))
            dispatch({ type: SIMPLIFY_RISK_ANALYZER_LOADING_USER_INPUTS, payload: false })
        }
    }
};

export const formatResultRows = ({ risk_output }: LooseObject, formatAsPercentage: boolean) => {
    const keys = Object.keys(risk_output);
    const riskOutputRows: TableRowData<ShockValue>[] = keys.map((key) => {
        const { pos_qty, base_value, ...values } = risk_output[key]
        const cleanedKey = key.replace('(\'', '').replace('\')', '').replace(/\'/g, '').split(', ')
        const [underlying, ticker, outputType] = cleanedKey

        const valuesArray = Object.keys(values).map((_, index) => values[`slide${index + 1}`])
        const formattedValues = [base_value, ...valuesArray].map((value) => {
            if (formatAsPercentage) {
                return Number(value).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
            return Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })
        })
        const formattedQty = ['subtotal','total'].includes(outputType) ? '' : Number(pos_qty).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 })
        return { key, title: KEY_MAPPING[underlying] ?? underlying, values: [KEY_MAPPING[ticker] ?? ticker, outputType, formattedQty, ...formattedValues] }
    });

    return riskOutputRows;
}


export const formatResultHeaders = ({ risk_output }: LooseObject) => {
    const keys = Object.keys(risk_output);
    const baseItem = keys.length ? risk_output[keys[0]] : {};
    const { pos_qty, base_value, ...restBaseItem } = baseItem;
    const slideKeys = Object.keys(restBaseItem ?? {});
    const riskOutputHeaders = ['Underlying', 'Ticker', 'Output Type', 'Quantity', 'Base Value', ...slideKeys.map((_, index) => (`Slide ${index + 1}`))].flat()

    return riskOutputHeaders;
}

export const runRiskAnalyzer = (ticker: string, userInputs: Shocks) => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        try {
            dispatch({ type: SIMPLIFY_RISK_ANALYZER_LOADING_RISK_OUTPUT, payload: true })
            const [percentageResponse, dollarResponse] = await Promise.all([
                axios.post(RISK_ANALYZER_ENDPOINT, {
                    ticker,
                    userInputs,
                    outputAsPercentage: true,
                }),
                axios.post(RISK_ANALYZER_ENDPOINT, {
                    ticker,
                    userInputs,
                    outputAsPercentage: false,
                })
            ]);
            const { data: percentageData } = percentageResponse;
            const { data: dollarData } = dollarResponse;
            
            const riskOutputHeaders = formatResultHeaders(percentageData)

            const riskOutputPercentageRows = formatResultRows(percentageData, true)
            const riskOutputDollarRows = formatResultRows(dollarData, false)

            dispatch({ type: SIMPLIFY_RISK_ANALYZER_RECEIVED, payload: { riskOutputHeaders, riskOutputPercentageRows, riskOutputDollarRows, userInputs, loading: false, hasResults: true } })

            dispatch({ type: SIMPLIFY_RISK_ANALYZER_LOADING_RISK_OUTPUT, payload: false })
        } catch (error) {
            console.log(error)
            dispatch({ type: SIMPLIFY_RISK_ANALYZER_RECEIVED, payload: { hasResults: false } })
            dispatch({ type: SIMPLIFY_RISK_ANALYZER_LOADING_RISK_OUTPUT, payload: false })
            dispatch(addError('Performance Attribution', 'Failed to run performance attribution'))
        }
    }
};