import { LooseObject } from "../../common/types"

export interface ChartOptions extends LooseObject {}

export interface InvestmentPortfolioChartOptionsProps {
    percentile95th: number[] | string[]
    percentile75th: number[] | string[]
    percentile50th: number[] | string[]
    percentile25th: number[] | string[]
    percentile5th: number[] | string[]
}
export const getInvestmentPortfolioChartOptions = ({ percentile95th, percentile75th, percentile50th, percentile25th, percentile5th }: InvestmentPortfolioChartOptionsProps): ChartOptions => {
    return {
        title: {
            text: ' ',
            x: -20 //center
        },
        subtitle: {
            text: ' ',
            x: -20
        },
        xAxis: {
            title: {
                text: 'Year'
            },
            categories: ['0', '1', '2', '3', '4', '5',
                '7', '8', '9', '10']
        },
        yAxis: {
            title: {
                text: 'Portfolio'
            },
            labels: {
                formatter: function(): any {
                    //@ts-ignore
                    return '$' + shortenLargeNumber(this.value)
                }
            },
            plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                }]
        },
        tooltip: {
            headerFormat:'<span style="font-size: 12px">Year {point.key}</span><br/>',
            pointformatter: function(): any {
                return '<span style="color:' + this.series.color + '">●</span> ' + this.series.name + ': <b>$' + parseInt(this.y.toFixed()).toLocaleString() + '</b><br/>'
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            borderWidth: 0
        },
        series: [{
            name: '95th Percentile',
            data: percentile95th
        }, {
            name: '75th Percentile',
            data: percentile75th
        }, {
            name: '50th Percentile',
            data: percentile50th
        }, {
            name: '25th Percentile',
            data: percentile25th
        }, {
            name: '5th Percentile',
            data: percentile5th
        }]
    }
}

export interface RiskAnalyzerEnterpriseChartProps {
    fixed_income?: number
    amount_equities?: number
    alternatives?: number
}

export const getRiskAnalyzerEnterpriseChart = ({ fixed_income = 0, amount_equities = 0, alternatives = 0 }: RiskAnalyzerEnterpriseChartProps = { }): ChartOptions => {
    return {
        chart: {
          type: 'bar'
        },
        title: {
          text: 'Historic World Population by Region'
        },
        xAxis: {
          categories: ['Equities', 'Fixed Income', 'Alternatives'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Portfolio Allocation %',
          },
          labels: {
            overflow: 'justify',
          }
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              format: '<b>{y}%</b>'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [{
          name: '',
          data: [amount_equities* 100, fixed_income * 100, alternatives* 100]
        }]
      }
}

export const getAssetAllocatioChart = (allocationAssets: any[] = [], dataLabels = {}): ChartOptions => {
    return {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            backgroundColor: 'rgba(0,0,0,0)'
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size: 200,
                crop: false,
                animation:{
                    defer:0,
                    duration: 0,
                },
                dataLabels: {
                    enabled: true,
                    distance: -1,
                    format: '{point.name}: {point.display} %',
                    ...dataLabels,
                }
            },
            area: {
                fillColor: '#0000'
            }
        },
        series: [{
            name: 'Asset Allocation',
            colorByPoint: true,
            data: (allocationAssets || []).map(({ title: name, value: y }) => {
                const yRounded = Math.round((y + Number.EPSILON) * 100) / 100

                return  {
                    name,
                    display: (y * 100).toFixed(1),
                    yRounded,
                    y
                }
            }).filter(({ yRounded }) => yRounded != 0.00 )
        }]
    }
}

export const getRiskLevelChart = (title, xTitle, { LOW, MED, HIGH }): ChartOptions => {
    const low = LOW * 100
    const med = MED * 100
    const high = HIGH * 100

    return {
        chart: {
            type: 'column',
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: ['LOW', 'MED', 'HIGH'],
            title: {
                text: xTitle,
                style: {
                    fontSize: '14px',
                },
            },
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        yAxis: {
            title: {
                text: 'Percent of Population *',
                style: {
                    fontSize: '14px',
                },
            },
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        tooltip: {
            headerFormat: `<b>${title}</b><br/>`,
            pointFormat: '{point.category}: {point.y:.1f}%',
            style: {
              fontSize: '14px',
            },
        },
        series: [{
            showInLegend: false ,
            data: [low, med, high]
        }]
    }
}

/**
 * Shorten number to thousands, millions, billions, etc.
 * http://en.wikipedia.org/wiki/Metric_prefix
 *
 * @param {number} num Number to shorten.
 * @param {number} [digits=0] The number of digits to appear after the decimal point.
 * @returns {string|number}
 *
 * @example
 * // returns '12.5k'
 * shortenLargeNumber(12543, 1)
 *
 * @example
 * // returns '-13k'
 * shortenLargeNumber(-12567)
 *
 * @example
 * // returns '51M'
 * shortenLargeNumber(51000000)
 *
 * @example
 * // returns 651
 * shortenLargeNumber(651)
 *
 * @example
 * // returns 0.12345
 * shortenLargeNumber(0.12345)
 */
function shortenLargeNumber(num: number, digits: number): string | number {
    var units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
        decimal;

    for(var i=units.length-1; i>=0; i--) {
        decimal = Math.pow(1000, i+1);

        if(num <= -decimal || num >= decimal) {
            return +(num / decimal).toFixed(digits) + units[i];
        }
    }

    return num;
}