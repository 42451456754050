import React from 'react';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { ReduxState } from '../../../reducers';
import ExtendedCommonTable from '../../../components/common/ExtendedCommonTable';
import { SelectOption, TableRowData } from '../../../common/types';
import Text from '../../../components/common/Text';
import { Select } from '../../../components/common/styled/Select';
import { PerformanceAttributionProps } from '../../../reducers/PerformanceAttributionReducer';
import { onPerformanceAttributionPropUpdated } from '../../../actions/simplifyTools';


interface ReduxStateProps {
    performanceAttributionData: TableRowData[]
    totalReturnsData: TableRowData[]
    assetClassReturns: TableRowData[]
    topTensData: TableRowData[]
    assetClassTopTenData: {
        [key: string]: TableRowData[];
    }
    assetClassTopTenOptions: SelectOption[];
    selectedAssetClassTopTen: SelectOption | null;
    onPerformanceAttributionPropUpdated: (payload: Partial<PerformanceAttributionProps>) => void
}
interface ReduxActionProps {}
interface ComponentState {}

type ComponentProps = ReduxActionProps & ReduxStateProps

class PerformanceAttributionResults extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {
        selectedAssetClassTopTen: null
    }

    onAssetClassTopTenChange = (value: SelectOption | null) => {
        this.props.onPerformanceAttributionPropUpdated({ selectedAssetClassTopTen: value })
    }

    render() {
        const { performanceAttributionData, totalReturnsData, assetClassReturns, topTensData, assetClassTopTenOptions, assetClassTopTenData, selectedAssetClassTopTen } = this.props;

        return (
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, mt: '5rem' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, mr: { xs: 'unset', md: '1rem' }, mt: { xs: '1rem', md: 'unset' } }}>
                    <ExtendedCommonTable
                        containerSx={{ mt: '1rem', mb: '1rem'}}
                        headerColumns={[{
                            key: 'Performance Attribution Info',
                            children: 'Performance Attribution Info',
                            colSpan: 2,
                            sx: { textAlign: 'center' },
                        }]}
                        rows={performanceAttributionData}
                    />
                    <ExtendedCommonTable
                        containerSx={{ mt: '2rem', mb: '1rem'}}
                        headerColumns={[{
                            key: 'Returns Table',
                            children: 'Returns Table',
                            colSpan: 2,
                            sx: { textAlign: 'center' },
                        }]}
                        rows={totalReturnsData}
                        percentageAllValues
                        excludeKeys={['Fund Calculated Total Return', 'Error (Calcd vs. NAV)']}
                    />
                    <ExtendedCommonTable
                        containerSx={{ mt: '1rem', mb: '1rem'}}
                        headerRows={[
                            {
                                key: 'Top Ten Sections',
                                columns: [
                                    {
                                        key: 'Top 10 BEST Trades',
                                        children: 'Top 10 BEST Trades',
                                        sx: { textAlign: 'center' },
                                        colSpan: 2,
                                    },
                                    {
                                        key: 'Top 10 WORST Trades',
                                        children: 'Top 10 WORST Trades',
                                        sx: { textAlign: 'center' },
                                        colSpan: 2,
                                    }
                                ]
                            },
                            {
                                key: 'Column Headers',
                                columns: [
                                    {
                                        key: 'Best 10 Ticker',
                                        children: 'Ticker',
                                        sx: { textAlign: 'center' },
                                    },
                                    {
                                        key: 'Best 10 Nav Contr.',
                                        children: 'Nav Contr.',
                                        sx: { textAlign: 'center' },
                                    },
                                    {
                                        key: 'Worst 10 Ticker',
                                        children: 'Ticker',
                                        sx: { textAlign: 'center' },
                                    },
                                    {
                                        key: 'Worst 10 Nav Contr.',
                                        children: 'Nav Contr.',
                                        sx: { textAlign: 'center' },
                                    },
                                ]
                            }
                        ]}
                        rows={topTensData}
                        percentageAllValues
                        filterEmptyRows
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, ml: { xs: 'unset', md: '1rem' }, mt: { xs: '1rem', md: 'unset' } }}>
                    <ExtendedCommonTable
                        containerSx={{ mt: '1rem', mb: '1rem'}}
                        headerRows={[
                            {
                                key: 'Asset Class & Performance Contribution',
                                columns: [
                                    {
                                        key: 'Asset Class',
                                        children: 'Asset Class',
                                        sx: { textAlign: 'center' },
                                    },
                                    {
                                        key: 'NAV Contribution',
                                        children: 'NAV Contribution',
                                        sx: { textAlign: 'center' },
                                    }
                                ]
                            }
                        ]}
                        rows={assetClassReturns}
                        percentageAllValues
                    />
                    <Box>
                        <Text type={'table_header'}>Asset Class Return Drivers</Text>
                        
                        <Select 
                            options={assetClassTopTenOptions}
                            placeholder='Select an asset class...'
                            onChange={this.onAssetClassTopTenChange}
                            value={selectedAssetClassTopTen}
                            sx={{ width: '33rem'}}
                        />
                    </Box>
                    {selectedAssetClassTopTen &&
                        <ExtendedCommonTable
                            containerSx={{ mt: '1rem', mb: '1rem'}}
                            headerRows={[
                                {
                                    key: 'Asset Class Return Drivers',
                                    columns: [
                                        {
                                            key: 'Asset Class Return Drivers',
                                            children: 'Asset Class Return Drivers',
                                            sx: { textAlign: 'center' },
                                            colSpan: 4,
                                        },
                                    ]
                                },
                                {
                                    key: 'Top Ten Sections',
                                    columns: [
                                        {
                                            key: 'Top 10 BEST Trades',
                                            children: 'Top 10 BEST Trades',
                                            sx: { textAlign: 'center' },
                                            colSpan: 2,
                                        },
                                        {
                                            key: 'Top 10 WORST Trades',
                                            children: 'Top 10 WORST Trades',
                                            sx: { textAlign: 'center' },
                                            colSpan: 2,
                                        }
                                    ]
                                },
                                {
                                    key: 'Column Headers',
                                    columns: [
                                        {
                                            key: 'Best 10 Ticker',
                                            children: 'Ticker',
                                            sx: { textAlign: 'center' },
                                        },
                                        {
                                            key: 'Best 10 Nav Contr.',
                                            children: 'Nav Contr.',
                                            sx: { textAlign: 'center' },
                                        },
                                        {
                                            key: 'Worst 10 Ticker',
                                            children: 'Ticker',
                                            sx: { textAlign: 'center' },
                                        },
                                        {
                                            key: 'Worst 10 Nav Contr.',
                                            children: 'Nav Contr.',
                                            sx: { textAlign: 'center' },
                                        },
                                    ]
                                }
                            ]}
                            rows={assetClassTopTenData[selectedAssetClassTopTen?.value as string] ?? []}
                            percentageAllValues
                            filterEmptyRows
                        />
                    }
                </Box>
            </Box>
        )
    }
}

const mapStateToProps = ({ performanceAttribution }: ReduxState): ReduxStateProps => {
    const { performanceAttributionData, totalReturnsData, assetClassReturns, topTensData, assetClassTopTenOptions, assetClassTopTenData, selectedAssetClassTopTen } = performanceAttribution

    return { performanceAttributionData, totalReturnsData, assetClassReturns, topTensData, assetClassTopTenOptions, assetClassTopTenData, selectedAssetClassTopTen }
}

export default connect(mapStateToProps, { onPerformanceAttributionPropUpdated })(PerformanceAttributionResults);