import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'
import { softwareReducers } from '../reducers';

let middleware = [thunk]
if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger();
    middleware = [...middleware, logger]
}

export default class StoreProvider {
    static store: StoreProvider

    static getStore = () => {
        if(!StoreProvider.store) {
            StoreProvider.store = createStore(
                softwareReducers,
                window.devToolsExtension && window.devToolsExtension(),
                applyMiddleware(...middleware)
            );
        }
        
        return StoreProvider.store
    }
}