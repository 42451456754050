import React from 'react'
import { Box } from '@mui/material'

export default () => {
    return (
        <Box sx={{
            ['@media print']: {
                pageBreakInside: 'avoid',
                pageBreakBefore: 'always',
            }
        }}></Box>
    )
}