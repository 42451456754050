import { SelectOption } from "../../common/types";
export enum Type {
  TREASURY_YIELD_CURVE = 'treasury_yield_curve',
  VIX_CURVE = 'vix_curve',
  VIX = 'vix',
  MOVE = 'move',
  BICLB10Y = 'BICLB10Y',
  USOHHYTO = 'USOHHYTO',
  MORTGAGE_SPREAD = 'mortgage_spread',
  TABLE_DATA = 'table_data',
  TABLE_TREASURY_DATA = 'TableTreasuryData',
}

export type HistoricalCurveType = Type.TREASURY_YIELD_CURVE | Type.VIX_CURVE;
export type HistoricalSeriesType = Type.VIX | Type.MOVE | Type.BICLB10Y | Type.USOHHYTO | Type.MORTGAGE_SPREAD;
export type PerformanceType = Type.TABLE_DATA | Type.TABLE_TREASURY_DATA;
export interface PerformanceDates {
  date: string;
  items: string[];
}

export interface DashboardItem {
  type: Type;
  as_of: string;
  trade_date: string;
  identifier: string;
}

export interface HistoricalCurve extends DashboardItem {
  maturity: string;
  yld_ytm_mid: number;
  last_tradeable_dt: string;
  px_last: number;
  month: number;
}

export interface HistoricalSeries extends DashboardItem {
  px_last: number;
}

export interface Performance extends DashboardItem {
  display_name: string;
  px_last: number;
  chg_net_1d: number;
  chg_pct_1d: number;
  chg_pct_5d: number;
  chg_pct_1m: number;
  chg_pct_ytd: number;
}

export enum DashboardRepositoryType {
  CURVE = 'curve',
  SERIES = 'series',
  PERFORMANCE = 'performance',
}

export const TYPE_TO_REPOSITORY = {
  [Type.TREASURY_YIELD_CURVE]: DashboardRepositoryType.CURVE,
  [Type.VIX_CURVE]: DashboardRepositoryType.CURVE,
  [Type.VIX]: DashboardRepositoryType.SERIES,
  [Type.MOVE]: DashboardRepositoryType.SERIES,
  [Type.BICLB10Y]: DashboardRepositoryType.SERIES,
  [Type.USOHHYTO]: DashboardRepositoryType.SERIES,
  [Type.MORTGAGE_SPREAD]: DashboardRepositoryType.SERIES,
  [Type.TABLE_DATA]: DashboardRepositoryType.PERFORMANCE,
  [Type.TABLE_TREASURY_DATA]: DashboardRepositoryType.PERFORMANCE,
};

export interface HistoricalCurveData {
    key: keyof HistoricalCurveOptions;
    items: HistoricalCurve[];
  }

export interface HistoricalCurveOptions {
    today?: boolean;
    oneWeek?: boolean;
    oneMonth?: boolean;
    oneYear?: boolean;
}


export const HISTORICAL_CURVE_OPTION_LABELS: {[key: string]: string} = {
    today: 'Today',
    oneWeek: '1W Ago',
    oneMonth: '1M Ago',
    oneYear: '1Yr Ago',
}


export const TYPE_LABEL_MAPPING: { [key: string]: string } = {
    [Type.TREASURY_YIELD_CURVE]: 'US Treasury',
    [Type.VIX_CURVE]: 'VIX Futures',
    [Type.VIX]: 'VIX',
    [Type.MOVE]: 'MOVE',
    [Type.BICLB10Y]: 'IG Spread',
    [Type.USOHHYTO]: 'HY Spread',
    [Type.MORTGAGE_SPREAD]: 'Mortgage Spread',
}

export const getOptionForType = (type: Type): SelectOption => {
    return { label: TYPE_LABEL_MAPPING[type], value: type, item: type };
}

export const HISTORICAL_CURVE_CHART_OPTIONS: SelectOption<HistoricalCurveType>[] = [
    getOptionForType(Type.VIX_CURVE),
    getOptionForType(Type.TREASURY_YIELD_CURVE),
]

export const HISTORICAL_TIME_SERIES_CHART_OPTIONS: SelectOption<HistoricalSeriesType>[] = [
    getOptionForType(Type.VIX),
    getOptionForType(Type.MOVE),
    getOptionForType(Type.BICLB10Y),
    getOptionForType(Type.USOHHYTO),
    getOptionForType(Type.MORTGAGE_SPREAD),
]

export const PERFORMANCE_ITEM_ORDERING = [
    'SPX Index',
    'NDX Index',
    'DJI Index',
    'RUT Index',
    'CL1 Comdty',
    'NG1 Comdty',
    'NG Comdty',
    'HG1 Comdty',
    'GC1 Comdty',
    'EUR Curncy',
    'JPY Curncy',
    'GB3 Govt',
    'GB6 Govt',
    'GB12 Govt',
    'GT2 Govt',
    'GT5 Govt',
    'GT10 Govt',
    'GT30 Govt',
    'VIX Index',
];

export const PERFORMANCE_ITEM_DIVIDERS = [
    'CL1 Comdty',
    'EUR Curncy',
    'GB3 Govt',
    'VIX Index'
]

export const REQUIRES_LAST_PRICE_PERCENT_FORMATTING: string[] = [
]

export const REQUIRES_LAST_PRICE_SKIP_FORMATTING = [
  'VIX Index',
  'GB3 Govt',
  'GB6 Govt',
  'GB12 Govt',
  'GT2 Govt',
  'GT5 Govt',
  'GT10 Govt',
  'GT30 Govt',
]

export const REQUIRES_SKIP_PERCENT_CHANGE_FORMATTING: string[] = [
  'GB3 Govt',
  'GB6 Govt',
  'GB12 Govt',
  'GT2 Govt',
  'GT5 Govt',
  'GT10 Govt',
  'GT30 Govt',
]


export const YIELD_NAME_MAPPING = {
  'GB3 Govt': '3 Month',
  'GB6 Govt': '6 Month',
  'GB12 Govt': '1 Year',
  'GT2 Govt': '2 Year',
  'GT5 Govt': '5 Year',
  'GT10 Govt': '10 Year',
  'GT30 Govt': '30 Year',
}

export const YIELD_MONTH_MAPPING: { [key: string]: number} = {
  'GB3 Govt': 3,
  'GB6 Govt': 6,
  'GB12 Govt': 12,
  'GT2 Govt': 24,
  'GT5 Govt': 60,
  'GT10 Govt': 120,
  'GT30 Govt': 360,
}

export const PERFORMANCE_ITEM_ORDER_MAPPING = PERFORMANCE_ITEM_ORDERING.reduce((acc, item, index) => {
    return { ...acc, [item]: index};
}, {} as {[key: string]: number});