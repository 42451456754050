import axios from 'axios'
import { StandardThunkAction } from '../store/store'
import { 
    CAPITAL_MARKET_ASSUMPTIONS_LOADING,
    CAPITAL_MARKET_ASSUMPTIONS_RECEIVED,
    CAPITAL_MARKET_ASSUMPTIONS_ERROR,
} from "./types/actions"
import { Asset, AssetSet } from './types/customAssets'
import { AddRemoveAssetRequest, DeleteAssetSetRequest, UpdateAssetSetRequest } from './types/requests'

export const ASSET_SETS_URL = '/api/admin/capitalMarketAssumptions/sets'

export const getAssetSets = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_LOADING })

            const { data } = await axios.get(`${ASSET_SETS_URL}`)
            const {  asset_sets: payloadCMASets, assets: payloadAssets, } = data
            const asset_sets = payloadCMASets ? payloadCMASets.map((item: AssetSet) => { return { ...item,  label: item.name, value: item.id } }) : []
            const assets = payloadAssets ? payloadAssets.map((item: Asset) => { return { ...item, label: item.name, value: item.id } }) : []
            
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { asset_sets, assets }})
        } catch(e: any) {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_ERROR, payload: e.toString() })
        }
    }
}

export const createAssetSet = (name: string): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_LOADING })
            const { data } = await axios.post(`${ASSET_SETS_URL}`, { name })
            const { success, cmaAssetSet } = data
            if(!success) {
                return
            }
            const { capitalMarketAssumptions } = getStore()
            const { asset_sets } = capitalMarketAssumptions
            const enhancedAssetSet = { ...cmaAssetSet,  label: cmaAssetSet.name, value: cmaAssetSet.id }

            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { asset_sets: [...asset_sets, enhancedAssetSet ] }})
        } catch(e: any) {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_ERROR, payload: e.toString() })
        }
    }
}

export const updateAssetSet = ({ id, name }: UpdateAssetSetRequest): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_LOADING })

            const { data } = await axios.put(`${ASSET_SETS_URL}/${id}`, { name })
            const { success } = data
            if(!success) {
                return
            }

            const { capitalMarketAssumptions } = getStore()
            const { asset_sets } = capitalMarketAssumptions

            const updated = asset_sets.map((item: AssetSet) => {
                const { id: setId } = item
                if(setId == id) {
                    return { ...item, name, label: name }
                }

                return item
            })
            
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { asset_sets: updated} })
        } catch(e: any) {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_ERROR, payload: e.toString() })
        }
    }
}

export const deleteAssetSet = ({ id }: DeleteAssetSetRequest): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_LOADING })

            const { data } = await axios.delete(`${ASSET_SETS_URL}/${id}`)
            const { success } = data
            if(!success) {
                return
            }

            const { capitalMarketAssumptions } = getStore()
            const { asset_sets } = capitalMarketAssumptions
            
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { asset_sets: asset_sets.filter(({ id: setId }: AssetSet) => id != setId) }})
        } catch(e: any) {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_ERROR, payload: e.toString() })
        }
    }
}

export const addAssetToSet = ({ assetSetId, assetId }: AddRemoveAssetRequest): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_LOADING })
            const { data } = await axios.post(`${ASSET_SETS_URL}/${assetSetId}/asset/${assetId}`)
            const { success, asset_set } = data
            if(!success) {
                return
            }

            const { capitalMarketAssumptions } = getStore()
            const { asset_sets } = capitalMarketAssumptions

            const updated = asset_sets.map((item: AssetSet) => {
                const { id: setId } = item
                if(Number(setId) === Number(assetSetId)) {
                    return { ...item, ...asset_set,  label: asset_set.name, value: asset_set.id }
                }

                return item
            })

            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { asset_sets: updated }})
        } catch(e: any) {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_ERROR, payload: e.toString() })
        }
    }
}

export const removeAssetFromSet = ({ assetSetId, assetId }: AddRemoveAssetRequest): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_LOADING })
            const { data } = await axios.delete(`${ASSET_SETS_URL}/${assetSetId}/asset/${assetId}`)
            const { success, asset_set } = data
            if(!success) {
                return
            }

            const { capitalMarketAssumptions } = getStore()
            const { asset_sets } = capitalMarketAssumptions

            const updated = asset_sets.map((item: AssetSet) => {
                const { id: setId } = item
                if(Number(setId) === Number(assetSetId)) {
                    return { ...item, ...asset_set,  label: asset_set.name, value: asset_set.id }
                }

                return item
            })

            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { asset_sets: updated }})
        } catch(e: any) {
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_ERROR, payload: e.toString() })
        }
    }
}