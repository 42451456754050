export default {
    dark_purple: `#171443`,
    light_purple: `#6666CC`,
    ultra_light_purple: `#CACAE3`,
    teal: `#3489AA`,
    off_light_purple: `#EDF1FE`,
    off_black: `#262626`,
    white: `#FFF`,
    off_white: `#F8F8F8`,
    light_grey: `#e7e7e7`,
    grey: `#CBCAD5`,
    grey_2: `#D9D9D9`,
    mint: `#BBE8DA`,
    light_mint: `#E0F4EE`,
    transparent: '#0000',
    red: '#BB0000',
    light_blue: 'lightblue',
}