import React from 'react';
import { Box, ButtonBase, Checkbox } from '@mui/material';
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from '../../../actions/types/portfolioDesigner';
import Text from '../../common/Text';
import { BaseProps } from '../../../components/types';

interface ReduxStateProps { }
interface ReduxActionProps { }
interface Props extends BaseProps{
    checked?: boolean;
    title: string;
    titleOnly?: boolean;
    contentsOnly?: boolean;
    children: React.ReactNode;
    onCheckChanged?: (checked: boolean) => void;
}

type ComponentProps = Props & ReduxActionProps & ReduxStateProps

interface ComponentState { }

export class FilterItem extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {}

    onCheckChanged = () => {
        const { checked, onCheckChanged } = this.props;
        onCheckChanged && onCheckChanged(!checked);
    }

    render() {
        const {title, checked, children, titleOnly, contentsOnly, sx = {}} = this.props;

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
                {!contentsOnly && (
                    <Box component={ButtonBase} sx={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem', alignItems: 'center', justifyContent: 'flex-start'  }} onClick={this.onCheckChanged}>
                        {!titleOnly && <Checkbox checked={checked} onChange={this.onCheckChanged} />}
                        <Text type={'form_header'}>{title}</Text>
                    </Box>                    
                )}
                <Box
                    sx={{
                    marginTop: '1rem',
                    padding: 0,
                    paddingBottom: '2.5rem',
                    borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`
                }}>
                    {children}
                </Box>
            </Box>
        )
    }
}