import axios from 'axios'
import { PROFILE, USER_LOADED, SELECTED_KEY, PAGE_CHANGE, SESSION, SESSION_LOADING, SESSION_LOADING_DONE, NAVBAR_HEIGHT, PROFILE_RESET, AUTH_TOKENS, GUIDES, LOGO_UPDATE } from "./types/actions"
import { runRiskAnalyzerResults } from './riskPreferences'
import { renderCharts } from './investmentPortfolio'
import { StandardAction, StandardThunkAction } from '../store/store'
import { UpdateProfileRequest } from './types/requests'
import cookies from '../components/Software/cookies'
import VIEWS from '../components/Software/views'
import { GetUserDetailsResponse } from './types/responses'
import { TokenType } from './types/authentication'

export const USER_AND_DETAILS_ENDPOINT = '/api/user/details'
export const GUIDES_URL = '/api/guides'
export const COPY_URL = '/api/copy'

export const updateSession = (payload: any) => {
    return { type: SESSION, payload }
}

export const getUserDetails = async(): Promise<GetUserDetailsResponse> => {
    const { data } = await axios.get(USER_AND_DETAILS_ENDPOINT)
    const { success, user, details } = data || { }

    return { success, user, details }
}

export const updateProfile = ({ profile_id, first_name, last_name, annual_income = 0, enterprise, portfolio_manager, portfolio_manager_client_id }: UpdateProfileRequest): StandardAction => {
    const enterprisePayload = enterprise ? { enterprise } : { }
    const payload: UpdateProfileRequest = { profile_id, first_name, last_name, annual_income, portfolio_manager, portfolio_manager_client_id, ...enterprisePayload }

    return { type: PROFILE, payload }
}

export const onSelectedKeyChanged = (selectedKeyProp: string, backToStart: boolean = true): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { session } = getState()
        const { views, defaultKey } = session
        
        const selectedKeyValid = views?.includes(Object.keys(VIEWS).find((key) => VIEWS[key].eventKey == selectedKeyProp))
        const selectedKey = selectedKeyValid ? selectedKeyProp : defaultKey
        localStorage.setItem(SELECTED_KEY, selectedKey);

        if(backToStart) {
            dispatch(resetActiveIndex(selectedKeyProp))
        }

        if(selectedKey == 'optimizedPortfolio') {
            dispatch(renderCharts())
        }

        if(['clientPortfolio','riskAnalyzerOptimizedPortfolio'].includes(selectedKey)) {
            dispatch(runRiskAnalyzerResults())
        }

        dispatch({ type: SELECTED_KEY, payload: selectedKey })
    }
}

// export const refreshAccessToken = (): StandardThunkAction => {
//     return async(dispatch): Promise<void> => {
//         try {
//             const { data } = await axios.post('/api/refresh', { })
//             const { success, authorization } = data || { }

//             if(success && authorization?.access_token) {
//                 axios.defaults.headers['X-PD-ACCESS-TOKEN'] = `Bearer ${authorization?.access_token}`;
//                 dispatch({ type: AUTH_TOKENS, payload: authorization })
//             } else {
//                 window.location.reload()
//             }
//         }catch(e: any) {
//             window.location.reload()
//         }

//     }
// }
export const onPageChange = (key: string, pageIndex: number): StandardAction => {
    return { type: PAGE_CHANGE, payload: { tab: `${key}ActiveIndex`, pageIndex } }
}

export const resetActiveIndex = (key: string): StandardAction => {
    return onPageChange(key, 0);
}
export const onNavBarHeightChanged = (height: number): StandardAction => {
    return { type: NAVBAR_HEIGHT, payload: height }
}
export const profileReset = (): StandardAction => {
    localStorage.removeItem(SELECTED_KEY);
    return { type: PROFILE_RESET }
}

export const updateHubSpotForUser = async(): Promise<void> => {
    const { data } = await axios.post('/api/hubspot')
    const { token, email } = data
    if(!token) {
        return
    }

    //@ts-ignore
    window.hsConversationsSettings = {
        identificationEmail: email,
        identificationToken: token
    };
}

export const getGuides = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const { data } = await axios.get(GUIDES_URL)

            const { guides = [] } = data

            dispatch({ type: GUIDES, payload: guides})
        } catch(e: any) {
            console.log(e)
        }
    }
}

export const getCopyByName = async(name: string): Promise<string> => {
    const { data } = await axios.get(`${COPY_URL}/${name}`)

    const { copy } = data || { }

    return copy || ''
}

export const getTokenLogo = async(token: string, type: TokenType): Promise<{ success: boolean, logo?: string}> => {
    try {
        const { data } = await axios.get(`/api/token/logo?token=${token}&type=${type}`)
        const { success, logo  } = data

        return { success, logo }
    }catch (error: any) {
        return { success: false }
    }
}
