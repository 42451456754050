import { Holdings, PortfolioMapDetails } from "./investmentPortfolio"

export interface RiskAversionQuestions {
    question1Value?: number
    question2Value?: number
    question3Value?: number
    question4Value?: number
    question5Value?: number 
}

export interface LossAversionQuestions {
    question1Value?: number
    question2Value?: number
    question3Value?: number
    question4Value?: number
}

export interface RiskPreferenceQuestions {
    riskAversion: RiskAversionQuestions
    lossAversion: LossAversionQuestions
}

export type RiskPreferenceQuestionTypes = 'riskAversion' | 'lossAversion'

export type RiskParameter = 'gamma' | 'lambda'
export type RiskParameterType = 'preferred' | 'recommended' | 'chosen'
export interface RiskParameters {
    lambda: number
    gamma: number
}

export interface RiskLevelAverages {
    LOW: number
    MED: number
    HIGH: number
}

export interface RiskParameterLevelAverages {
    lambda: RiskLevelAverages
    gamma: RiskLevelAverages
}

export interface RiskAnalyzerEnterpriseResult {
    holdings: Holdings
    tableDetails: PortfolioMapDetails
}



export type AversionLevel = 'LOW' | 'MED' | 'HIGH';
export type InvestmentStrategy = 'Conservative' | 'Moderate' | 'Growth';

export const RECOMMENDED_PORTFOLIO_MATRIX: Record<AversionLevel, Record<AversionLevel, InvestmentStrategy>> = {
  HIGH: {
    LOW: 'Conservative',
    MED: 'Conservative',
    HIGH: 'Conservative',
  },
  MED: {
    LOW: 'Moderate',
    MED: 'Moderate',
    HIGH: 'Conservative',
  },
  LOW: {
    LOW: 'Growth',
    MED: 'Moderate',
    HIGH: 'Conservative',
  },
};

const riskAversion = { question1Value: undefined, question2Value: undefined, question3Value: undefined, question4Value: undefined, question5Value: undefined }
const lossAversion = { question1Value: undefined, question2Value: undefined, question3Value: undefined, question4Value: undefined }

export const DEFAULT_PREFERENCE_VALUES = { lossAversion, riskAversion }