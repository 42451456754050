import { Box, SxProps, Theme } from '@mui/material'
import React, { FunctionComponent } from 'react'
import CommonTable from '../common/CommonTable'
import { ForecastTooltipResponse } from '../../actions/types/forecast'
import Text from '../common/Text'

export interface ForecastTooltipTableProps {
    data?: ForecastTooltipResponse
    containerSx?: SxProps<Theme>
}

export const ForecastTooltipTable: FunctionComponent<ForecastTooltipTableProps> = ({
    containerSx = { },
    data,
}) => {
    const [columns, setColumns] = React.useState<any[]>([])
    const [rows, setRows] = React.useState<any[]>([])

    React.useEffect(() => {
        if (!data) {
            return;
        }

        const table = data?.table;
        const rowKeys = Object.keys(table);
        const columnsMapped: any = rowKeys.reduce((accum, key) => {
            const objectKeys = Object.keys(table[key])
            const itemColumns = objectKeys.reduce((objectAccum, objectKey) => {
                return { ...objectAccum, [objectKey]: {
                    key: objectKey,
                    children: <Text>{objectKey}</Text>,
                    colSpan: 1,
                }}
            }, { })

            return { ...accum, ...itemColumns }
        }, { '': { key: name, children: name, colSpan: 1 } })
        const columns = Object.keys(columnsMapped).map((key) => columnsMapped[key])
        const columnFields = columns.map((item) => item.key).filter((key) => key);
        const rows = rowKeys.map((key: string) => {
            return { title: key, key, values: [...columnFields.map((field: string) => {
                const value = table[key][field] === '' ? 'N/A' : table[key][field]

                return isNaN(Number(value)) ? value : (Number(value) * 100).toFixed(2) + '%'

            })]}
        })

        setColumns(columns)
        setRows([...rows])
    }, [data])

    return (
            <Box sx={containerSx}>
                <CommonTable
                    sx={{ 
                        '& td': {
                            padding: 'unset',
                            paddingLeft: '1.5rem',
                            paddingTop: '.5rem',
                            paddingBottom: '.5rem',
                        },
                        marginTop: {
                            xs: '2rem',
                            md: 'unset', 
                        },
                    }}
                    valueCellProps={{
                        sx: {
                            textAlign: 'center'
                        }
                    }}
                    headerColumns={columns}
                    rows={rows}
                />
            </Box>
    )

}