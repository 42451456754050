import React, { Component } from 'react'
import { connect } from 'react-redux';
import RiskAversion from '../RiskPreferences/RiskAversion'
import LossAversion from '../RiskPreferences/LossAversion'
import RiskAnalyzerOptimizedPortfolio from '../OptimizedPortfolio/RiskAnalyzerOptimizedPortfolio';

import { riskAversionQuestionUpdate, lossAversionQuestionUpdate } from '../../actions/clientRiskPreferences'
import { getEnterpriseRiskAnalyzer, runRiskAnalyzerResults } from '../../actions/singeUseSoftware'

import PagerContainer from '../RiskPreferences/PagerContainer';
import { PageContents } from '../Page/PageContents';
import { UpdateQuestionRequest } from '../../actions/riskPreferences';


export interface SingeUseQuestionnaireProps {
    getEnterpriseRiskAnalyzer: () => void
    runRiskAnalyzerResults: () => void
    riskAversionQuestionUpdate: (update: UpdateQuestionRequest) => void
    lossAversionQuestionUpdate: (update: UpdateQuestionRequest) => void
    activeIndex: number
}

class SingeUseQuestionnaire extends Component<SingeUseQuestionnaireProps> {
    constructor(props) {
        super(props)
        props.getEnterpriseRiskAnalyzer()
    }

    componentDidUpdate(prevProps) {
        const { activeIndex: prevActiveIndex } = prevProps
        const { activeIndex } = this.props

        if(prevActiveIndex !== activeIndex && activeIndex == 3) {
            this.props.runRiskAnalyzerResults()
        }
    }

    onRiskAversionChoiceSelected = ({ questionNumber, value }) => {
        this.props.riskAversionQuestionUpdate({ questionNumber, value })
    }

    onLossAversionChoiceSelected = ({ questionNumber, value }) => {
        this.props.lossAversionQuestionUpdate({ questionNumber, value })
    }
    
    render() {
        const { activeIndex } = this.props
        return (
            <PageContents sx={{ marginBottom: '8rem' }}>
                <PagerContainer activeIndex={activeIndex} gesturesEnabled={false} sx={{ marginBottom: '2rem' }}>
                    <RiskAversion onChoiceSelected={this.onRiskAversionChoiceSelected} />
                    <LossAversion onChoiceSelected={this.onLossAversionChoiceSelected} />
                    <RiskAnalyzerOptimizedPortfolio disclaimerText={<div>Thank you for your interest in the Newfound’s STRUCTURAL ALPHA Model Portfolio suite. Please reach out to us at <span><a href={"mailto: info@thinknewfound.com"}>info@thinknewfound.com</a></span> to learn more.</div>} />
                </PagerContainer>
            </PageContents>
        )
    }
}

const mapStateToProps = ({ session }) => {
    const { singleUseSoftwareActiveIndex: activeIndex } = session
    
    return {
        activeIndex,
    }
}

export default connect(mapStateToProps, { getEnterpriseRiskAnalyzer, runRiskAnalyzerResults, riskAversionQuestionUpdate, lossAversionQuestionUpdate })(SingeUseQuestionnaire)