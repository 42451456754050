import axios from "axios";
import { EquityForecastPayload, ForecastSeriesItems, ForecastTable, ForecastTooltipResponse } from "./types/forecast";
import { StandardThunkAction } from "../store/store";
import { FORECAST_UPDATED } from "./types/actions";
import moment from "moment";
import { ResultResponse } from "./types/portfolioDesigner";

const FORECAST_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/forecast`
export const SERIES_OPTIONS = {
    "In-Sample Forecast": {
        color: 'orange',
    },
    "In-Sample Realized": {
        color: 'dodgerblue',
    },
    "Out-of-Sample Forecast": {
        dashStyle: 'Dash',
        color: 'orange',
    },
    "Out-of-Sample Realized": {
        dashStyle: 'Dash',
        color: 'dodgerblue',
    },
    "In-Sample Regimes": {
        color: 'black',
    },
    "Out-of-Sample Regimes": {
        dashStyle: 'Dash',
        color: 'black',
    },
}
const REGIMES_MAP = {
    "Contraction": 50001,
    "Recovery": 50002,
    "Expansion": 50003,
};

export const CHART_LABEL_MAP = {
    ["SPX_2YRet_Ann"]: "2 Year Equity Forecast Vs Realized (Annualized)",
    ["10yTrsy_2YRet_Ann"]: "2 Year Long-Term Treasury Forecast Vs Realized (Annualized)",
    ["regimes"]: "Market Regimes",
}

export const getForecastTooltip = async(dataKey: string, date: string): Promise<ForecastTooltipResponse | ResultResponse> => {
    try {
        const { data } = await axios.post(`${FORECAST_URL}/tooltip`, { dataKey, date})
        const { success, durations, error, ...response } = data as ForecastTooltipResponse
        
        const table: any = {};

        //Remapping tooltip response
        for (const [key, value] of Object.entries(response.table)) {
          for (const [subKey, subValue] of Object.entries(value)) {
            if (!table[subKey]) {
                table[subKey] = {};
            }
            table[subKey][key] = subValue;
          }
        }
        
        return {
            success,
            error,
            date: moment(date).format('MM/DD/YYYY'),
            table,
        }
    } catch(e) {
        return {
            success: false,
            error: 'An unexpected error occured',
        }
    }
}

export const getForecast = (): StandardThunkAction => {
    return async(dispatch: any): Promise<void> => {
        dispatch({ type: FORECAST_UPDATED, payload: { loading: true }})
        const { data: chartPayload } = await axios.get(FORECAST_URL)

        const { success, regimes, start_date_training_set, durations, end_date_training_set, forecasts, ...charts } = chartPayload as EquityForecastPayload
        const chartKeys = Object.keys(charts)
        const forecastsMapped = Object.keys(forecasts).reduce((accum, key: string) => {
            const item: any = forecasts[key]  
            const table: any = {};
    
            //Remapping tooltip response
            for (const [key, value] of Object.entries(item.table)) {
              for (const [subKey, subValue] of Object.entries(value as any)) {
                if (!table[subKey]) {
                    table[subKey] = {};
                }
                table[subKey][key] = subValue;
              }
            }
            return {
                ...accum,
                [key]: {
                    date: moment(item.date).format('MM/DD/YYYY'),
                    table,
                }
            }
        }, { })

        const regimeChart = {
            id: 'regimes',
            title: 'Market Regimes',
            chart: getForecastChart({ id: 'regimes', items: regimes, end_date_training_set, valueMapper: REGIMES_MAP, yAxisOptions: { categories: Object.keys(REGIMES_MAP) } }),
        }

        const chartsMapped = chartKeys.map((key: string) => {
            return {
                id: key,
                chart: getForecastChart({ id: key, items: charts[key], end_date_training_set, regimes }),
                title: CHART_LABEL_MAP[key],
            }
        })

        dispatch({ type: FORECAST_UPDATED, payload: { loading: false, charts: [regimeChart, ...chartsMapped], forecasts: forecastsMapped } })
    }
}



export const getForecastChart = ( options: { id: string, items?: ForecastSeriesItems, type?: string, end_date_training_set?: string, yAxisOptions?: any, valueMapper?: any, regimes?: ForecastSeriesItems } ): any => {
    const { id, items, type, end_date_training_set, valueMapper = { }, regimes } = options
    if (!items) {
        return undefined
    }

    const reverseValueMapper = Object.keys(valueMapper).reduce((accum: any, key: any) => ({ ...accum, [valueMapper[key]]: key }), { })

    const seriesKeys = Object.keys(items)
    const series = seriesKeys.map((key: string) => {
        const item = items[key]
        const data: any = item.map(({ x, y }) => {
            const date = x;
            const dateMs = new Date(date).getTime()
            const dateMoment = moment(dateMs)
            const formatted_date = dateMoment.format('MMM DD, yyyy')
            
            let value = y === null || y === undefined ? undefined : y
            if(isNaN(value as number) && value && valueMapper && valueMapper[value]) {
                value = valueMapper[value]
            } else if(!isNaN(value as number)) {
                value = (y * 100)
            }


            return { x: dateMs, y: value, date, formatted_date, value }
        }).filter((item: any) => item.value !== undefined);

        data.sort((a: any, b: any) => a.x - b.x)

        return {
            name: key,
            data,
            type,
            ...(SERIES_OPTIONS[key] || {} )
        }
    })

    const plotLines = !end_date_training_set ? undefined : [{
        value: new Date(end_date_training_set).getTime(),
        color: 'black',
        width: 2,
        zIndex: 4,
        dashStyle: 'Dash',
    }];

    return {
        id,
        chart: {
            type: 'spline',
            zoomType: 'x', // enable zoom on the X axis
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            // tickInterval: 365 * 24 * 3600 * 1000, // Set tick interval to 1 year (365 days * 24 hours * 3600 seconds * 1000 milliseconds)
            dateTimeLabelFormats: {
              year: '%Y' // Format the year label to show only the year (e.g. "2021")
            },
            labels: {
                style: {
                    fontSize: '14px'
                },
                enabled: true, 
            },
            plotLines,
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                style: {
                    fontSize: '14px'
                },
                formatter: function (): string {
                  if(reverseValueMapper[`${this.value}`]) {
                    return reverseValueMapper[`${this.value}`]
                  }
                  return this.value + '%';
                },
            },
            ...(options.yAxisOptions || {}),
        },
        plotOptions: {
            spline: {
                dataLabels: {
                    enabled: false
                },
                marker: {
                    enabled: false
                }
            },
            series: {
                turboThreshold: 0,
            }
        },
        legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'top',
            symbolWidth: 35,
            symbolHeight: 1,
            symbolRadius: 1,
            squareSymbol: true,
            itemStyle: {
                fontSize: '14px',
            }
        },
        tooltip: {
            formatter: function(): any {
                const point = (this as any).point
                const value = reverseValueMapper[point.value] ? reverseValueMapper[point.value] : point.value.toFixed(2) + '%'
                let displayValue = `Forecast: ${value}`
                if(regimes) {
                    const regimeItem = Object.keys(regimes).map((key) => {
                        return regimes[key].find(({ x }) => point.date === x)
                    }).find(item => item);
                    if (regimeItem) {
                        displayValue = `${displayValue}<br />Regime: ${regimeItem.y}`;
                    }
                }

                return point.formatted_date + '<br />' + displayValue;
            },
            style: {
              fontSize: '14px',
            },
         },
        series
    }
}