import React, { Component } from 'react'
import { Table, TableProps } from '../common/Table'
import { Box, SxProps, TableCell, TableCellProps, TableRow, TableRowProps, Theme } from '@mui/material'
import { ChartResource } from '../../actions/types/portfolioDesigner'

export interface ChartTableProps extends TableProps {
    chart: ChartResource
    sx?: SxProps<Theme>
    tableSx?: SxProps<Theme>
}
export default class ChartTable extends Component<ChartTableProps> {

    render() {
        const { chart, sx = { }, tableSx = { } } = this.props
        const { name, url, long } = chart

        return (
            <Box sx={{ display: 'flex', ...sx }}>
                <Table
                    sx={{
                        ...tableSx,
                        width: long? '111rem' : '36.3rem',
                    }}
                    headerColumns={[
                        {
                            key: name,
                            children: name,
                            colSpan: 1,
                        }
                    ]}
                >
                    <TableCell 
                        sx={{ 
                            '& img': {
                                width: long ? '100%' : undefined,
                                height: long ? 'auto' : '100%',
                                maxHeight: '32rem',
                            },
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: long ? undefined : 'center'}}>
                                <img src={url} ></img>
                            </Box>
                    </TableCell>
                </Table>
            </Box>
        )
    }
}