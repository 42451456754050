import { Box } from '@mui/material';
import React, { Component } from 'react'
import MessageModal from '../common/MessageModal';
import Text from '../common/styled/Text';

export interface TermsAndConditionsModalProps {
    onTermsAccepted: () => void
    visible: boolean
}
export interface TermsAndConditionsModalState {
    showTermsDialog: boolean,
    acceptedTerms: boolean,
}

export default class TermsAndConditionsModal extends Component<TermsAndConditionsModalProps, TermsAndConditionsModalState> {
    constructor(props) {
        super(props)
        this.state = {
            showTermsDialog: false,
            acceptedTerms: false,
        }
    }

    onCancel = () => {

    }

    checkTermsAgreed = () => {
        if(!this.state.acceptedTerms) {
            alert('You cannot proceed without accepting the terms and conditions')

            return
        }

        if(typeof(this.props.onTermsAccepted) === 'function') {
            this.props.onTermsAccepted()
        }
    }
    
    render() {
        const { visible } = this.props
        const { acceptedTerms } = this.state

        return (
            <MessageModal onCancel={this.onCancel} visible={visible} title={''} onButtonPress={this.checkTermsAgreed}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box><Text>To continue, please accept all <a href={'/terms-and-conditions'} target='_blank' rel="noopener noreferrer">Terms & Conditions</a> of use</Text></Box>
                    <Box sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        '& input': {
                            marginRight: '.5rem'
                        }
                    }}><input type='checkbox' checked={acceptedTerms} onChange={() => this.setState({ acceptedTerms: !acceptedTerms })} ></input> <Text>Accept</Text></Box>
                </Box>
            </MessageModal>
        )
    }
}