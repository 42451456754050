import React, { FunctionComponent } from 'react'

import Text from './styled/Text'
import { Box, SxProps, Theme } from '@mui/material'
import TooltipContainer from './styled/TooltipContainer'
import { GlossaryTerm } from './glossary'
import { BaseProps } from '../types'

export interface GlossaryTextProps extends GlossaryTerm, BaseProps {
    hoverContentsSx?: SxProps<Theme>
 }

export const GlossaryText: FunctionComponent<GlossaryTextProps> = ({ definition, term, children = term, ...restProps }) => {
    return (
        <TooltipContainer
            sx={{
                cursor: 'help',
                borderBottom: '1px dashed #999',
            }}
            hoverContents={<Text noGlossary><Box sx={{ fontWeight: 'bold' }}>{term}</Box><Box>{definition}</Box></Text>} {...restProps}
        >
            {children}
        </TooltipContainer>
    )
}

export default GlossaryText