import { GET_DOMAINS, PORTFOLIO_MAPS_FOR_DOMAIN, DOMAIN_LOADED, PARAMETER_LIST_UPDATED, ASSETS_UPDATED, ASSETS_VALUE_UPDATED, STAT_VALUE_UPDATED, PARAMETER_MAPPING_UPDATED, PORTFOLIO_MAP_RESET, TABLE_DETAIL_VALUE_UPDATED, LOGOUT, PROFILE_RESET } from '../actions/types/actions';
import { Asset } from '../actions/types/customAssets';
import { PortfolioMap } from '../actions/types/investmentPortfolio';
import { LooseObject } from '../common/types';

export interface PortfolioMapToolState {
    loading: boolean
    domains : string[]
    portfolioMaps : PortfolioMap[]
    lambda: number[]
    gamma: number[]
    assets: Asset[],
    values: LooseObject
    details: LooseObject
    accountAssets: Asset[]
    parameterMappings: any[]
    readOnlyParameters: boolean
    loadedDomain?: string
    domainLoaded: boolean
    error?: any
    loadedPortfolioMapId: number
}

const INITIAL_STATE: PortfolioMapToolState = {
  loading: false,
  domains : [],
  portfolioMaps : [],
  lambda: [],
  gamma: [],
  assets: [],
  values: {},
  details: {},
  accountAssets: [],
  parameterMappings: [],
  readOnlyParameters: true,
  loadedDomain: undefined,
  domainLoaded: false,
  error: undefined,
  loadedPortfolioMapId: 0,
};

export default (state = INITIAL_STATE, action): PortfolioMapToolState => {
  const { payload, type } = action

  switch (type) {
    case GET_DOMAINS:
        return { ...state, domains: payload }
    case PORTFOLIO_MAPS_FOR_DOMAIN:
        return { ...state, portfolioMaps: payload }
    case DOMAIN_LOADED:
        return { ...state, ...payload }
    case PARAMETER_LIST_UPDATED:
        return { ...state, [payload.type]: payload.items }
    case ASSETS_UPDATED:
        return { ...state, ...payload }
    case ASSETS_VALUE_UPDATED:
        return { ...state, values: {...state.values, [payload.id]: payload }}
    case PARAMETER_MAPPING_UPDATED:
        return { ...state, parameterMappings: payload }
    case TABLE_DETAIL_VALUE_UPDATED:
        return { ...state, details: { ...state.details, [payload.id]: payload }}
    case PORTFOLIO_MAP_RESET:
        return { ...INITIAL_STATE, domains: [ ...state.domains ], portfolioMaps: [ ...state.portfolioMaps ]}
    case PROFILE_RESET:
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

