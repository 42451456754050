import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import CommonTable from '../common/CommonTable'
import { Holdings, InvestmentPortfolioType, KeyStats, ScenarioPerfs } from '../../actions/types/investmentPortfolio'
import { getOptimizedPortfolio, runInvestmentPortfolioScript, updateOptAssetSet, updateBenchmarkModel, updateMaxInv } from '../../actions/investmentPortfolio'
import Button from '../common/Button'
import RiskProfileTable from './RiskProfileTable';
import { getDisplayValue, hasClientIdChanged } from '../../common/utils';
import TabFooter from '../Software/TabFooter';
import { withClientParameter } from '../common/withClientParameter';
import { AssetSet } from '../../actions/types/customAssets';
import { Box } from '@mui/material';
import Text from '../common/styled/Text';
import { Select } from '../common/styled/Select';
import Chart from '../common/Chart';
import { LooseObject } from '../../common/types';
import TooltipContainer from '../common/styled/TooltipContainer';
import { withAuth } from 'src/common/hooks/withAuth'

const CURRENT_CUSTOM_CMA_ITEM = { id: null, label: 'Current Custom CMA', value: null, is_global: true }
const BENCHMARK_MODEL_OPTIONS = [{ label: 'Utility', value: 'Utility' }, { label: 'Risk Parity', value: 'Risk Parity' }, { label: 'Mean-Var', value: 'Mean-Var' }, { label: '1/N', value: '1/N' }, { label: 'Ivy', value: 'Ivy' }, { label: '60/40', value: '60/40' }]
const MAX_INV_OPTIONS = [{ label: 'None', value: 1 }, { label: '10%', value: 0.1 }, { label: '20%', value: 0.2 }, { label: '30%', value: 0.3 }, { label: '40%', value: 0.4 }, { label: '50%', value: 0.5 }]

interface OptimizedPortfolioProps {
    getOptimizedPortfolio: () => void
    runInvestmentPortfolioScript: (opt_asset_set_id?: number) => void
    updateOptAssetSet: (id: number) => void
    updateBenchmarkModel: (model: string) => void
    updateMaxInv: (type: InvestmentPortfolioType, value: number) => void
    holdings: Holdings[]
    holdingsBenchmark: Holdings[]
    keyStats: KeyStats
    keyStatsBenchmark: KeyStats
    asset_sets: AssetSet[]
    currentSetSelection?: AssetSet
    benchmarkModel: 'Utility' | 'Risk Parity' | 'Mean-Var' | '1/N' | 'Ivy' | '60/40'
    prevModuleKey: string
    portfolioMaxIndv: number
    benchmarkMaxIndv: number
    scenarioPerfs: ScenarioPerfs[]
    scenarioPerfsBenchmark: ScenarioPerfs[]
    portfolioChart: LooseObject
    assetAllocatioChart: LooseObject
    assetAllocatioBenchmarkChart: LooseObject
}

interface OptimizedPortfolioState {
    showSetDetails: boolean
    height: number
}

class OptimizedPortfolio extends Component<OptimizedPortfolioProps, OptimizedPortfolioState> {
    constructor(props) {
        super(props)

        this.state = {
            showSetDetails: false,
            height: 1420,
        }
    }

    componentDidMount() {
        this.props.getOptimizedPortfolio()
    }

    componentDidUpdate(prevProps) {
        if(hasClientIdChanged(prevProps, this.props)) {
            this.props.getOptimizedPortfolio()
        }
    }

    onOpAssetSetSelectionChange = ({ id }) => {
        this.props.updateOptAssetSet(id)
    }

    onBenchmarkModelChange = ({ value: benchmarkModel }) => {
        this.props.updateBenchmarkModel(benchmarkModel)
    }

    onUpdatePortfolioMaxIndv = ({ value }) => {
        this.props.updateMaxInv('portfolio', value)
    }

    onUpdateBenchmarkMaxIndv = ({ value }) => {
        this.props.updateMaxInv('benchmark', value)
    }

    runScript = () => {
        const { currentSetSelection } = this.props
        this.props.runInvestmentPortfolioScript(currentSetSelection?.id)
    }

    render() {
        const { holdings, holdingsBenchmark, keyStats, keyStatsBenchmark, asset_sets, currentSetSelection,
                benchmarkModel, portfolioMaxIndv, benchmarkMaxIndv, scenarioPerfs, scenarioPerfsBenchmark,
                portfolioChart, assetAllocatioChart, assetAllocatioBenchmarkChart } = this.props
        const { monteCarloAnnMean, monteCarloAnnvol, monteCarloAnnmaxdrawdown } = keyStats
        const { monteCarloAnnMean: monteCarloAnnMeanBenchmark, monteCarloAnnvol: monteCarloAnnvolBenchmark, monteCarloAnnmaxdrawdown: monteCarloAnnmaxdrawdownBenchmark } = keyStatsBenchmark

        const keyStatsMapped = [{ title: 'Annual Return', values: [`${getDisplayValue((monteCarloAnnMean * 12) * 100, 1)}%`] }, { title: 'Annual Volatility', values: [`${(parseFloat(monteCarloAnnvol?.toString() || "0")  * Math.sqrt(12) * 100) .toFixed(1)}%`] }, { title: 'Maximum Drawdown', values: [`${parseFloat(((monteCarloAnnmaxdrawdown || 0) * 100).toString()).toFixed(0)}%`] }]
        const keyStatsMappedBenchmark = [{ title: 'Annual Return', values: [`${getDisplayValue((monteCarloAnnMeanBenchmark * 12) * 100, 1)}%`] }, { title: 'Annual Volatility', values: [`${(parseFloat(monteCarloAnnvolBenchmark?.toString() || "0")  * Math.sqrt(12) * 100) .toFixed(1)}%`] }, { title: 'Maximum Drawdown', values: [`${parseFloat(((monteCarloAnnmaxdrawdownBenchmark || 0) * 100).toString()).toFixed(0)}%`] }]

        const showTooltipIcon = !!currentSetSelection && !currentSetSelection.is_global
        
        return (
                <Box
                    sx={{
                        paddingBottom: '5rem',
                        display: 'flex',
                        flexDirection: 'column',
                    }} >
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            zIndex: 2,
                        }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            flexWrap: 'wrap',
                            zIndex: 15,
                        }}>
                            <RiskProfileTable />
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flex: 1,
                                alignItems: 'flex-end',
                            }}>
                                <Button title={'Run'} sx={{ height: '5rem', width: '10rem' }} onClick={this.runScript} />
                            </Box>
                        </Box>
                    </Box>
                    <hr />
                    <Box sx={{
                        display: 'flex',
                        marginTop: '2rem',
                        flexWrap: 'wrap',
                        zIndex: 1,
                    }}>
                        <Box sx={{
                            width: '30rem',
                            marginRight: '.5rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '1rem',
                                marginBottom: '1rem',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            }}>
                                <Box sx={{
                                    width: '27rem',
                                    marginTop: '1rem',
                                    zIndex: 15,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative',
                                }}>
                                    <Text type={'form_header'}>Portfolio</Text>
                                    {showTooltipIcon &&
                                        <TooltipContainer 
                                            hoverContents={
                                                <>
                                                    <Box><Text><strong>Date Span: </strong>{currentSetSelection?.start_date} - {currentSetSelection?.end_date}</Text></Box>
                                                    {currentSetSelection?.assets && currentSetSelection.assets.map(({ name, returnShift }) => 
                                                        <Box><Text><strong>{name}</strong> Return Shift: {returnShift}</Text></Box>
                                                    )}
                                                </>
                                        }>
                                            <Box component={'span'} style={{ cursor: 'pointer', marginLeft: 5 }} onMouseEnter={() => this.setState({ showSetDetails: true })} onMouseLeave={() => this.setState({ showSetDetails: false })}><FontAwesomeIcon icon={faInfoCircle} /></Box>
                                        </TooltipContainer>
                                    }
                                    <Select options={asset_sets} value={currentSetSelection} onChange={this.onOpAssetSetSelectionChange}/>
                                </Box>
                                <Box sx={{
                                    width: '27rem',
                                    marginTop: '1rem',
                                    zIndex: 14,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Text type={'form_header'}>MAX. INDIV. ALLOCATION</Text>
                                    <Select options={MAX_INV_OPTIONS} value={MAX_INV_OPTIONS.find(({ value }) => value == portfolioMaxIndv)} onChange={this.onUpdatePortfolioMaxIndv}/>
                                </Box>
                            </Box>
                            <CommonTable
                                style={{ marginBottom: 40 }}
                                headerColumns={[{
                                    key: 'Forecasted Stats',
                                    children: 'Forecasted Stats',
                                    colSpan: 2,
                                }]}
                                rows={keyStatsMapped}
                            />
                            <CommonTable
                                headerColumns={[{
                                    key: 'Asset Allocation',
                                    children: 'Asset Allocation',
                                    colSpan: 2,
                                }]}
                                rows={holdings.filter(({ title }) => title).map(({ title, value }) => {
                                    return { key: `${title}_${value}`, title, values: [`${parseFloat(((value || 0) * 100).toString()).toFixed(1)}%`] }
                                })}
                            />
                            <Chart id="assetAllocatioChart" sx={{ width: '45rem', marginLeft: '-10rem', backgroundColor: '#0000', zIndex: 0 }} chartOptions={assetAllocatioChart} />
                        </Box>
                        <Box sx={{
                            width: '30rem',
                            marginLeft: '.5rem',
                            marginRight: '.5rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '1rem',
                                marginBottom: '1rem',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            }}>
                                <Box sx={{
                                    width: '27rem',
                                    marginTop: '1rem',
                                    zIndex: 15,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative',
                                }}>
                                    <Text type={'form_header'}>Benchmark</Text>
                                    <Select options={BENCHMARK_MODEL_OPTIONS} value={BENCHMARK_MODEL_OPTIONS.find(({ value }) => value == benchmarkModel )} onChange={this.onBenchmarkModelChange}/>
                                </Box>
                                <Box sx={{
                                    width: '27rem',
                                    marginTop: '1rem',
                                    zIndex: 14,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Text type={'form_header'}>MAX. INDIV. ALLOCATION</Text>
                                    <Select options={MAX_INV_OPTIONS} value={MAX_INV_OPTIONS.find(({ value }) => value == benchmarkMaxIndv)} onChange={this.onUpdateBenchmarkMaxIndv}/>
                                </Box>
                            </Box>

                            <CommonTable
                                style={{ marginBottom: 40 }}
                                headerColumns={[{
                                    key: 'Forecasted Stats',
                                    children: 'Forecasted Stats',
                                    colSpan: 2,
                                }]}
                                rows={keyStatsMappedBenchmark}
                            />
                            <CommonTable
                                headerColumns={[{
                                    key: 'Asset Allocation',
                                    children: 'Asset Allocation',
                                    colSpan: 2,
                                }]}
                                rows={holdingsBenchmark.map(({ title, value }) => {
                                    return { title, values: [`${parseFloat(((value || 0) * 100).toString()).toFixed(1)}%`] }
                                })}
                            />
                            <Chart id="assetAllocatioBenchmarkChart" sx={{ width: '45rem', marginLeft: '-10rem', backgroundColor: '#0000', zIndex: 0 }} chartOptions={assetAllocatioBenchmarkChart} />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '.5rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Box sx={{
                                    height: '42rem',
                                    width: '60rem',
                                    marginLeft: '6rem' 
                            }} className={'chartContainer'}>
                                <Chart id="portfolioChart" chartOptions={portfolioChart} />
                            </Box>
                            <CommonTable
                                sx={{
                                    width: '60rem'
                                }}
                                headerColumns={[{
                                    key: 'Historical Scenario Analysis',
                                    children: 'Historical Scenario Analysis',
                                },{
                                    key: 'Portfolio',
                                    children: 'Portfolio',
                                },{
                                    key: benchmarkModel || ' ',
                                    children: benchmarkModel || ' ',
                                }]}
                                rows={scenarioPerfs.map((item, index) => {
                                    return { ...item, values: [`${parseFloat(((item.value || 0) * 100).toString()).toFixed(2)}%`, `${parseFloat((scenarioPerfsBenchmark[index].value * 100).toString()).toFixed(2)}%` ]}
                                })}
                            />
                        </Box>
                        <TabFooter title={'Optimized Portfolio'} />
                    </Box>
                </Box>
        )
    }

}
const mapStateToProps = ({ investmentPortfolio, session, capitalMarketAssumptions }, { userDetails, clientId }) => {
    const { holdings, holdingsBenchmark, keyStats, keyStatsBenchmark, percentiles, scenarioPerfs, scenarioPerfsBenchmark, 
            chosen, recommended, preferred, showAddHistorySuccess, runningProfileScript, opt_asset_set_id, 
            benchmarkModel, portfolioMaxIndv, benchmarkMaxIndv, portfolioChart, assetAllocatioChart, 
            global_asset_sets, assetAllocatioBenchmarkChart } = investmentPortfolio
    const { asset_sets: cmaAssetSets = [], cma_assets = [] } = capitalMarketAssumptions
    
    const { views = [] } = userDetails
    const prevModuleKey = views.includes('custom_cma') ? 'customCMA' : 'standardLivingRisk'

    const assetsWithReturnShift = (cma_assets || []).filter(({ returnShift = 0.0 }) => returnShift != 0.0)
    const hasReturnShift = assetsWithReturnShift.length > 0
    const asset_sets = views.includes('custom_cma') ? cmaAssetSets : global_asset_sets
    let currentSetSelection = asset_sets.find(({ id }) => opt_asset_set_id == id)
    if(!currentSetSelection) {
        currentSetSelection = views.includes('custom_cma') ? CURRENT_CUSTOM_CMA_ITEM : global_asset_sets[0]
    }
    const assetSetsWithCurrent = views.includes('custom_cma') ? [CURRENT_CUSTOM_CMA_ITEM, ...asset_sets] : asset_sets

    return { prevModuleKey, runningProfileScript, holdings, holdingsBenchmark, keyStats, keyStatsBenchmark, percentiles,
            scenarioPerfs, scenarioPerfsBenchmark, chosen, recommended, preferred, profileId: clientId, showAddHistorySuccess,
            asset_sets: assetSetsWithCurrent, currentSetSelection, hasReturnShift,
            benchmarkModel, portfolioMaxIndv, benchmarkMaxIndv, portfolioChart, assetAllocatioChart, assetAllocatioBenchmarkChart }
}
export default withAuth(withClientParameter(connect(mapStateToProps, { getOptimizedPortfolio, runInvestmentPortfolioScript, updateOptAssetSet, updateBenchmarkModel, updateMaxInv })(OptimizedPortfolio)))