import React, {FC} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabPanel } from '../../common/TabPanel';
import {ReportResourceItem} from '../../../actions/types/portfolioDesigner';
import Text from '../../common/Text';
import { CategoryItems } from './CategoryItems';
import PerformanceAttribution from './PerformanceAttribution';
import EfficientFrontier from './EfficientFrontier';


const getTabTitleProps = (index: number, title: string) => {
  return {
    id: `tab-${index}`,
    index,
    label: <Text>{title}</Text>,
    'aria-controls': `tabpanel-${index}`,
  };
}

interface ResultsTabProps {
  items: ReportResourceItem[]
}

const ResultsTab: FC<ResultsTabProps> = ({ items }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Results Tab Example">
          <Tab {...getTabTitleProps(0, 'Performance')} />
          <Tab {...getTabTitleProps(1, 'Risk')} />
          <Tab {...getTabTitleProps(2, 'Performance Attribution')} />
          <Tab {...getTabTitleProps(3, 'Efficient Frontier')} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CategoryItems items={items} category={'performance'} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CategoryItems items={items} category={'risk'} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PerformanceAttribution />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <EfficientFrontier />
      </TabPanel>
    </Box>
  );
}

export default ResultsTab;