import { Component } from "react"
import { connect } from 'react-redux'
import { ChartOptions } from "../../../OptimizedPortfolio/chartOptions"
import { ReduxState } from "../../../../reducers"
import Chart from "../../../common/Chart"

export interface CorrelationMatrixProps {
    snapshotCorrelationMatrixChart: ChartOptions
}

export interface CorrelationMatrixState { }

class CorrelationMatrix extends Component<CorrelationMatrixProps, CorrelationMatrixState> {

    render() {
        const { snapshotCorrelationMatrixChart } = this.props
        if (!snapshotCorrelationMatrixChart) {
            return null;
        }

        return <Chart sx={{ minWidth: '50rem', maxHeight: '50rem', maxWidth: '100rem' }} id='snapshotCorrelationMatrixChart' chartOptions={snapshotCorrelationMatrixChart} />;
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState ) => {
    const { snapshotCorrelationMatrixChart } = portfolioDesigner

    return { snapshotCorrelationMatrixChart }
}

export default connect(mapStateToProps, {  })(CorrelationMatrix as  any)
