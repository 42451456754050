import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { Box, List } from '@mui/material';
import { processScreener, screenerUpdate } from '../../../actions/dueDiligence';
import Text from '../../common/Text';
import { FilterItem } from './FilterItem';
import { NumericFilterInput } from './NumericFilterInput';
import { ScreenerOptions } from '../../../actions/types/dueDiligence';
import { ClipLoader } from 'react-spinners';
import { FundCategoryGroup } from './FundCategoryGroup';
import categoryGroups from './categories.json';
import universes from './universes.json';
import { addError } from '../../../actions/notifications';
import { FundCategoryItem } from './FundCategoryItem';
import { DueDiligenceState } from '../../../reducers/DueDiligenceReducer';

interface ReduxStateProps {
    processingScreener: boolean
}

interface ReduxActionProps {
    processScreener: typeof processScreener;
    addError: typeof addError;
    screenerUpdate: (payload: Partial<DueDiligenceState>) => Promise<void>;
    universes: string[];
    categories: string[];
    distributionYieldValue: number | string;
    expenseRatioValue: number | string;
    performanceRank6moValue: number | string;
    performanceRank1yrValue: number | string;
}

type ComponentProps = ReduxActionProps & ReduxStateProps

interface ComponentState {}

class ScreenerFilters extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {}
    processScreener = () => {
        const { categories, distributionYieldValue, expenseRatioValue, performanceRank6moValue, performanceRank1yrValue, universes } = this.props;
        let payload: ScreenerOptions = {}
        if (universes.length) {
            payload = { ...payload, universes: { enabled: true, options: universes } }
        }
        if (categories.length) {
            payload = { ...payload, categories: { enabled: true, options: categories } }
        }
        if (distributionYieldValue !== '') {
            payload = { ...payload, distribution_yield: { enabled: true, value: Number(distributionYieldValue)} }
        }
        if (expenseRatioValue !== '') {
            if (Number(expenseRatioValue) <= 0) {
                this.props.addError('Screener Error', 'Expense ratio must be greater than 0')
                return;
            }
            payload = { ...payload, expense_ratio: { enabled: true, value: expenseRatioValue === 0 || isNaN(Number(expenseRatioValue)) ? '' : Number(expenseRatioValue) * 100 } }
        }
        if (performanceRank6moValue !== '') {
            payload = { ...payload, performance_rank_6mo: { enabled: true, value: Number(performanceRank6moValue) } }
            if (!payload.categories?.enabled || !payload.categories.options.length) {
                this.props.addError('Screener Error', 'Please select a category to filter by performance rank')
                return;
            }
        }
        if (performanceRank1yrValue !== '') {
            payload = { ...payload, performance_rank_1yr: { enabled: true, value: Number(performanceRank1yrValue) } }
            if (!payload.categories?.enabled || !payload.categories.options.length) {
                this.props.addError('Screener Error', 'Please select a category to filter by performance rank')
                return;
            }
        }
        this.props.processScreener(payload)
    }


    onDistributionYieldValueChanged = async(value: number | string): Promise<boolean> => {
        const numericValue = Number(value)
        if (value === this.props.distributionYieldValue || numericValue === this.props.distributionYieldValue) return false;
        if (value === '' || numericValue <= 0) {
            await this.props.screenerUpdate({ distributionYieldValue: '' })
            this.processScreener()
            return false;
        }
        await this.props.screenerUpdate({ distributionYieldValue: numericValue })
        this.processScreener()

        return true
    }

    onExpenseRatioValueChanged = async(value: number | string): Promise<boolean> => {
        const numericValue = Number(value)
        if (value === this.props.expenseRatioValue || numericValue === this.props.expenseRatioValue) return false;
        if (value === '') {
            await this.props.screenerUpdate({ expenseRatioValue: '' })
            this.processScreener()
            return false;
        }
        if (numericValue <= 0) {
            await this.props.screenerUpdate({ expenseRatioValue: '' })
            this.processScreener()
            this.props.addError('Screener Error', 'Expense ratio must be greater than 0')
            return false;
        }
        await this.props.screenerUpdate({ expenseRatioValue: numericValue })
        this.processScreener()

        return true
    }

    onPerformanceRank6moValueChanged = async(value: number | string) => {
        const numericValue = Number(value)
        if (value === this.props.performanceRank6moValue || numericValue === this.props.performanceRank6moValue) return;
        if (value === '') {
            await this.props.screenerUpdate({ performanceRank6moValue: '' })
            this.processScreener()
            return;
        }
        await this.props.screenerUpdate({ performanceRank6moValue: numericValue })
        this.processScreener()
    }

    onPerformanceRank1yrValueChanged = async(value: number | string) => {
        const numericValue = Number(value)
        if (value === this.props.performanceRank1yrValue || numericValue === this.props.performanceRank1yrValue) return;
        if (value === '') {
            await this.props.screenerUpdate({ performanceRank1yrValue: '' })
            this.processScreener()
            return;
        }
        await this.props.screenerUpdate({ performanceRank1yrValue: Number(value) })
        this.processScreener()
    }

    onCategoryCheckChanged = async(category: string, checked: boolean) => {
        const { categories } = this.props;
        const newCategories = checked ? [...categories, category] : categories.filter(c => c !== category);

        await this.props.screenerUpdate({ categories: newCategories })
        this.processScreener()
    }

    onCheckAllChanged = async(title: string, checked: boolean, categories: string[]) => {
        const { categories: selectedCategories } = this.props;
        const cleaned = selectedCategories.filter(c => !categories.includes(c));
        const newCategories = checked ? [...cleaned, ...categories] : cleaned;

        await this.props.screenerUpdate({ categories: newCategories })
        this.processScreener()
    }

    onETFCheckChanged = async(title: string, checked: boolean) => {
        const etfUniverses = universes['ETFs'];
        const cleanUniverse = this.props.universes.filter(u => !etfUniverses.includes(u));
        const newUniverses = checked ? [...cleanUniverse, ...etfUniverses] : cleanUniverse;
        await this.props.screenerUpdate({ universes: newUniverses })
        this.processScreener()
    }

    onMutualFundsChecked = async(title: string, checked: boolean) => {
        const mutualFundUniverses = universes['Mutual Funds'];
        const cleanUniverse = this.props.universes.filter(u => !mutualFundUniverses.includes(u));
        const newUniverses = checked ? [...cleanUniverse, ...mutualFundUniverses] : cleanUniverse;
        await this.props.screenerUpdate({ universes: newUniverses })
        this.processScreener()
    }

    render() {
        const { distributionYieldValue, expenseRatioValue, performanceRank1yrValue, performanceRank6moValue, categories } = this.props;
        const { processingScreener } = this.props;
        const etfChecked = this.props.universes.includes(universes['ETFs'][0]);
        const mutualFundsChecked = this.props.universes.includes(universes['Mutual Funds'][0]);

            const screenerStyle = processingScreener ? {
                pointerEvents: 'none',
                opacity: '0.4',
                cursor: 'wait'
            } : {};

        return (
            <Box sx={{ ...screenerStyle, display: 'flex', position: 'relative', flexDirection: 'column', height: '100%', width: '34rem', marginRight: '2.5rem', gap: '2rem' }}>
                {processingScreener &&
                    <Box sx={{ display: 'flex', position: 'absolute', justifyContent: 'center', alignItems: 'center', top: 0, left: '-7.5rem', right: 0, bottom: 0 }}>
                        <ClipLoader />
                    </Box>
                }
                <Box sx={{ display: 'flex', width: '34rem', flexDirection: 'column', borderBottom: '1px black solid' }}>
                    <Text type={'h4'} sx={{ marginBottom: '1rem' }}>Screener</Text>
                </Box>
                <Box sx={{ display: 'flex', width: '34rem', flexDirection: 'column', gap: '2rem' }}>
                    <FilterItem titleOnly title={'Asset Universe'}>
                        <List>
                            <FundCategoryItem titleSx={{ fontWeight: 'bold' }} title={'ETFs'} onCheckChanged={this.onETFCheckChanged} checked={etfChecked} />
                            <FundCategoryItem titleSx={{ fontWeight: 'bold' }} title={'Mutual Funds'} onCheckChanged={this.onMutualFundsChecked} checked={mutualFundsChecked} />
                        </List>
                    </FilterItem>
                    <FilterItem titleOnly title={'Fund Category'}>
                        {categoryGroups.map(({group, categories: items}) => (<FundCategoryGroup key={group} onCheckAllChanged={this.onCheckAllChanged} title={group} categories={items} selectedCategories={categories} onCheckChanged={this.onCategoryCheckChanged} />))}
                    </FilterItem>
                    <FilterItem contentsOnly title={'Distribution Yield'}>
                        <NumericFilterInput
                            title={'Distribution Yield >'}
                            titleSx={{ width: '18rem' }}
                            isNumeric
                            value={distributionYieldValue}
                            onValueChanged={this.onDistributionYieldValueChanged}
                            suffix='%'
                            allowEmptyString
                        />
                    </FilterItem>
                    <FilterItem contentsOnly title={'Expense Ratio'}>
                        <NumericFilterInput
                            title={'Expense Ratio <'}
                            titleSx={{ width: '18rem' }}
                            isNumeric
                            value={expenseRatioValue}
                            onValueChanged={this.onExpenseRatioValueChanged}
                            suffix='%'
                            allowEmptyString
                        />
                    </FilterItem>
                    <FilterItem contentsOnly title={'Performance Rank 6mo'}>
                        <NumericFilterInput
                            title={'6M Perf. %ile >'}
                            titleSx={{ width: '18rem' }}
                            isNumeric
                            value={performanceRank6moValue}
                            onValueChanged={this.onPerformanceRank6moValueChanged}
                            allowEmptyString
                        />
                    </FilterItem>
                    <FilterItem contentsOnly title={'Performance Rank 1yr'}>
                        <NumericFilterInput
                            title={'1YR Perf. %ile >'}
                            titleSx={{ width: '18rem' }}
                            isNumeric
                            value={performanceRank1yrValue}
                            onValueChanged={this.onPerformanceRank1yrValueChanged}
                            allowEmptyString
                        />
                    </FilterItem>
                </Box>
            </Box>
        )
    }
}


const mapStateToProps = ({ dueDiligence }: ReduxState) => {
    const {processingScreener, distributionYieldValue, expenseRatioValue, performanceRank1yrValue, performanceRank6moValue, categories, universes } = dueDiligence;

    return { processingScreener, distributionYieldValue, expenseRatioValue, performanceRank1yrValue, performanceRank6moValue, categories, universes }
}

export default connect(mapStateToProps, { processScreener, addError, screenerUpdate })(ScreenerFilters as any)