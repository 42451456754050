import React, { Component } from 'react'
import { Select } from '../common/styled/Select'
import { Box } from '@mui/material'
import Text from '../common/styled/Text'
import { RiskParameter } from '../../actions/types/riskPreferences'
import { SelectOption } from '../../common/types'

export interface RiskProfileItemProps {
    onRiskValueChanged: (type: RiskParameter, value: SelectOption) => void
    title: string
    type: RiskParameter
    value: string
    options: SelectOption[]
    readOnly?: boolean
}

export default class RiskProfileItem extends Component<RiskProfileItemProps> {

    onValueChanged = (value) => {
        const { onRiskValueChanged, type } = this.props
        
        if(typeof(onRiskValueChanged) == 'function') {
            onRiskValueChanged(type, value)   
        }
    }

    render() {
        const { value, options, readOnly } = this.props
        
        return (
            <Box sx={readOnly ? {
                width: '5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'hsl(0,0%,100%)',
                cursor: 'default',
                minHeight: '3.5rem',
                outline: '0 !important',
                '-webkit-transition': 'all 100ms',
                transition: 'all 100ms',
                boxSizing: 'border-box',
                textAlign: 'center',
            } : { width: '10rem' }}>
                {readOnly == true && <Text>{value}</Text>}
                {readOnly == false && 
                    <Select sx={{ width: '12.5rem' }} value={{ label: value, value: value }} options={options} onChange={this.onValueChanged} />
                }
            </Box>
        )
    }

}