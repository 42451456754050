import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { v4 as uuid } from 'uuid'
import theme from '../../theme';

const generateClassName = createGenerateClassName({
    productionPrefix: uuid(),
  });

export default ({ children }) => {
    //TODO: Should remove StylesProvider once legacy withStyles has been migrated to MUI System.
    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StylesProvider>
    )
}