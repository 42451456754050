import React, { Component } from 'react'
import { Table, TableProps } from './Table'
import { Box, SxProps, TableCell, TableCellProps, TableRow, TableRowProps } from '@mui/material'
import Text from './styled/Text'
import { Theme } from '../../theme/theme'
import CommonTable from './CommonTable'
import { TableRowData } from '../../common/types'

export interface CommonTableProps extends TableProps {
    rowProps?: TableRowProps
    valueCellProps?: TableCellProps
    rows: TableRowData[]
    containerSx?: SxProps<Theme>
    tableSx?: SxProps<Theme>
    customRows?: TableRowData[]
    excludeKeys?: string[]
    noDataMessage?: string
    boldedKeys?: string[]
    seperateAfterKeys?: string[]
    percentageKeys?: string[]
    percentageAllValues?: boolean
    centerValues?: boolean
    centerTitles?: boolean
    filterEmptyRows?: boolean
    redNegativeValues?: boolean
    title?: string | React.ReactNode;
    exportTitle?: string;
    footer?: React.ReactNode
    attribution?: React.ReactNode
    footerAttributionSx?: SxProps<Theme>
    footerOnlyInExport?: boolean
    showExport?: boolean;
    headerContainerProps?: SxProps<Theme>;
    innerTableSx?: SxProps<Theme>;
}

interface CommonTableState {
    rows: TableRowData[]
}
export default class ExtendedCommonTable extends Component<CommonTableProps, CommonTableState> {
    state: CommonTableState = {
        rows: []
    }
    componentDidMount(): void {
        this.prepareExtendedRows()
    }

    componentDidUpdate(prevProps: Readonly<CommonTableProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.rows !== this.props.rows || prevProps.customRows !== this.props.customRows || prevProps.excludeKeys !== this.props.excludeKeys || prevProps.boldedKeys !== this.props.boldedKeys || prevProps.seperateAfterKeys !== this.props.seperateAfterKeys) {
            this.prepareExtendedRows()
        }
    }

    prepareExtendedRows = () => {
        const { rows: propRows, customRows, excludeKeys, boldedKeys, seperateAfterKeys, percentageKeys, percentageAllValues } = this.props
        const keys: string[] = [...new Set(propRows.map((row: any) => row.key))].filter((key: string) => !excludeKeys?.includes(key));
        
        const styledRows = propRows.filter(item => keys.includes(item.key ?? '')).map((item: TableRowData) => {
            const { key, titleSx, valueSx, values, ...restItem } = item;
            const shouldPercentage = percentageAllValues || percentageKeys?.includes(key ?? '');
            const shouldBold = boldedKeys?.includes(key ?? '');
            const boldSx = shouldBold ? { fontWeight: 'bold' } : { };
            const formattedValues = item.values.map((value: any) => {
                if (shouldPercentage && value !== '' && !isNaN(value)) {
                    return `${(value * 100).toFixed(2)}%`
                }

                return value;
            })

            return { ...restItem, key, titleSx: {...titleSx, ...boldSx}, valueSx: {...valueSx, ...boldSx}, values: formattedValues }
        })

        const seperatedRows = styledRows.reduce((acc: any[], row: any) => {
            const shouldSeperate = seperateAfterKeys?.includes(row.key);
            if (shouldSeperate) {
                return [...acc, row, { seperator: true }]
            }

            return [...acc, row]
        }, []);

        const rows = [...seperatedRows, ...(customRows || [])]
        if (this.props.filterEmptyRows) {
            const rowsWithSomeValues = rows.filter((row: TableRowData) => {
                const validValues = row.values.filter((value: any) => value !== '')
                if (validValues.length > 0) return true;

                return validValues.length > 0;
            })
            this.setState({ rows: rowsWithSomeValues })
            return
        }

        this.setState({ rows })
    }

    render() {
        const { 
            containerSx = { },
            tableSx = { },
            headerColumns,
            headerRows,
            noDataMessage,
            centerValues,
            redNegativeValues,
            title,
            exportTitle,
            footer,
            attribution,
            footerAttributionSx,
            footerOnlyInExport,
            showExport,
            headerContainerProps,
            innerTableSx = {},
        } = this.props
        const { rows } = this.state
        return (
            <Box sx={{
                ...containerSx
                }}>
                <CommonTable
                    title={title}
                    exportTitle={exportTitle}
                    headerContainerProps={headerContainerProps}
                    tableSx={innerTableSx}
                    sx={{
                        ...tableSx,
                        '& td': {
                            padding: '1rem',
                        },
                        marginTop: {
                            xs: '2rem',
                            md: 'unset', 
                        },
                    }}
                    valueCellProps={{
                        sx: {
                            textAlign: centerValues ? 'center' : undefined,
                        }
                    }}
                    headerRows={headerRows}
                    headerColumns={headerColumns}
                    noDataMessage={noDataMessage}
                    rows={rows}
                    redNegativeValues={redNegativeValues}
                    showExport={showExport}
                    footer={footer}
                    attribution={attribution}
                    footerAttributionSx={footerAttributionSx}
                    footerOnlyInExport={footerOnlyInExport}
                />
            </Box>
        )
    }

}