import { Box, SxProps, TableCell, Theme } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import React, { Component } from 'react'
import { BaseProps } from '../types'

interface QuestionChoiceProps extends BaseProps {
    checked: boolean
    onChoiceSelected: (choiceLetter: string, value: number) => void
    choiceLetter: string
    choiceContent: string | React.ReactNode
    value: number
    contentSx?: SxProps<Theme>
}

class QuestionChoice extends Component<QuestionChoiceProps>{
    constructor(props) {
        super(props)
    }

    onChoiceSelected = () => {
        const { checked, value, onChoiceSelected, choiceLetter } = this.props
        if(onChoiceSelected && !checked) {
            onChoiceSelected(choiceLetter, value)
        }
    }

    render() {
        const { choiceLetter, checked, choiceContent, sx = { }, contentSx = { } } = this.props
        const selectedCheckStyle: SxProps<Theme> = checked ? { 
            background: (theme) => theme.palette.background.level7,
            border: `1px solid white`,
            color: 'white',
        } : { }
        const selectedCheckBackground: SxProps<Theme> = checked ? { background:  (theme) => theme.palette.background.level2 } : { }

        return (
            <TableCell sx={{ 
                    padding: 0,
                    borderBottom: `unset`,
                    ...sx
                }}>
                <Box sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.borders.level9}`,
                    borderBottom: (theme) => `1px solid ${theme.palette.borders.level7}`,
                    ...contentSx
                }} onClick={this.onChoiceSelected}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        cursor: 'pointer',
                        border: '2px solid transparent',
                        fontFamily: 'Montserrat',
                        padding: '8px',
                        lineHeight: '28px',
                        fontSize: (theme) => theme.typography.pxToRem(28),
                        color: (theme) => theme.palette.primary.dark,
                        minHeight: '80px',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        ...selectedCheckBackground,
                    }}>
                        <Box sx={{
                            width: '44px',
                            height: '44px',
                            padding: '5px',
                            borderRadius: '22px',
                            textAlign: 'center',
                            fontSize: '32px',
                            fontWeight: 100,
                            lineHeight: '28.38px',
                            textTransform: 'uppercase',
                            letterSpacing: '-1.61px',
                            color: (theme) => theme.palette.text.level5,
                            border: (theme) => `2px solid ${theme.palette.borders.level5}`,
                            ...selectedCheckStyle,
                        }}><FontAwesomeIcon icon={faCheck} fontWeight={100} /></Box>
                        <Box sx={{
                            textAlign: 'left',
                            flex: 1,
                            paddingLeft: '20px',
                            paddingRight: '20px',
                        }}>
                            {choiceContent}
                        </Box>
                    </Box>
                </Box>
            </TableCell>
        )
    }

}

export default QuestionChoice