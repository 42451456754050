import React, { Component } from 'react'
import reactStringReplace from 'react-string-replace'
import glossary from '../glossary'
import GlossaryText from '../GlossaryText'
import { Box, BoxProps, SxProps } from '@mui/material'
import { Theme } from '../../../theme/theme'

const pxToRemWithTheme = (theme: Theme, size: number) => {
    return theme.typography.pxToRem(size + 10)
}

type TextType = 'text' | 'text_tiny' | 'text_mid' | 'text_small' | 'text_large' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'table_header' | 'form_header'
type FontFunction = (theme: Theme) => string

export const FONT_STYLES_BY_TYPE = {
    text_tiny: (theme: Theme) => `normal normal normal ${pxToRemWithTheme(theme, 10)}/${pxToRemWithTheme(theme, 22)} Montserrat`,
    text_small: (theme: Theme) => `normal normal normal ${pxToRemWithTheme(theme, 14)}/${pxToRemWithTheme(theme, 20)} Montserrat`,
    text: (theme: Theme) => `normal normal normal ${pxToRemWithTheme(theme, 16)}/${pxToRemWithTheme(theme, 24)} Montserrat`,
    text_mid: (theme: Theme) => `normal normal normal ${pxToRemWithTheme(theme, 17)}/${pxToRemWithTheme(theme, 24)} Montserrat`,
    text_large: (theme: Theme) => `normal normal normal ${pxToRemWithTheme(theme, 18)}/${pxToRemWithTheme(theme, 28)} Montserrat`,
    h1: (theme: Theme) => `normal normal 700 ${pxToRemWithTheme(theme, 88)}/${pxToRemWithTheme(theme, 80)} Montserrat`,
    h2: (theme: Theme) => `normal normal 300 ${pxToRemWithTheme(theme, 40)}/${pxToRemWithTheme(theme, 60)} Montserrat`,
    h3: (theme: Theme) => `normal normal 700 ${pxToRemWithTheme(theme, 30)}/${pxToRemWithTheme(theme, 37)} Montserrat`,
    h4: (theme: Theme) => `normal normal 300 ${pxToRemWithTheme(theme, 24)}/${pxToRemWithTheme(theme, 29)} Montserrat`,
    h5: (theme: Theme) => `normal normal 500 ${pxToRemWithTheme(theme, 21)}/${pxToRemWithTheme(theme, 24)} Montserrat`,
    table_header: (theme: Theme) => `normal normal 600 ${pxToRemWithTheme(theme, 15)}/${pxToRemWithTheme(theme, 22)} Montserrat`,
    form_header: (theme: Theme) => `normal normal 600 ${pxToRemWithTheme(theme, 18)}/${pxToRemWithTheme(theme, 26)} Montserrat`,
}

export interface TextProps extends BoxProps {
    sx?: SxProps<Theme | any>
    type?: TextType
    glossaryTerm?: any
    noGlossary?: boolean
    asDiv?: boolean
}

export interface TextState {
    children: React.ReactNode
    glossaryItem: any
}

export class Text extends Component <TextProps, TextState>{

    constructor(props: TextProps) {
        super(props)
        this.state = {
            children: props.glossaryTerm || props.noGlossary ? props.children : this.addToolTipsToChildren(props.children),
            glossaryItem: props.glossaryTerm ? glossary.find(({ term }) => props.glossaryTerm == term) : undefined
        }
    }

    componentDidUpdate(prevProps: TextProps) {
        const { children: prevChildren, glossaryTerm: prevGlossaryTerm } = prevProps
        const { children, glossaryTerm } = this.props

        if(!glossaryTerm && prevChildren != children) {
            const updatedChildren = this.addToolTipsToChildren(children)
            this.setState({ children: updatedChildren })
        }

        if(prevGlossaryTerm != glossaryTerm) {
            this.setState({ glossaryItem: glossary.find(({ term }) => glossaryTerm == term )})
        }
    }

    addToolTipsToChildren = (children: React.ReactNode = []): any => {
        if(typeof(children) == 'string') {
            return this.addTextToGlossary(children)
        }

        if(Array.isArray(children)) {
            return children.map((child) => {
                return this.addToolTipsToChildren(child)
            })
        }

        return children
    }

    renderGlossaryTerm = (term: string, definition: string, contents: any) => {
        const { type = 'text', asDiv, sx = {}, style } = this.props
        const font = FONT_STYLES_BY_TYPE[type]

        return <GlossaryText key={`${term}-${definition}`} term={term} definition={definition}>
                    <Box
                        component={asDiv ? undefined : "span"}
                        sx={{
                            font,
                            color: (theme) => theme.palette.primary.dark,
                            ...style,
                            ...(sx as any)
                        }}>{contents}</Box>
                </GlossaryText>
    }

    addTextToGlossary = (text: any) => {
        let updatedText = text
        
        for(const glossaryItem of glossary) {
            const { term, definition } = glossaryItem
            const regexExpression = new RegExp(term,"g");
            if(updatedText.includes(term)) {
                updatedText = reactStringReplace(text, regexExpression, () => {
                    return this.renderGlossaryTerm(term, definition, term)
                })
            }
        }

        return updatedText
    }
    

    render() {
        const { type = 'text', asDiv, className, sx = { }, style } = this.props
        const { children, glossaryItem } = this.state

        if(glossaryItem) { 
            const { term, definition } = glossaryItem

            return this.renderGlossaryTerm(term, definition, children)
        }
        
        const font = FONT_STYLES_BY_TYPE[type]
        return <Box
                    className={className}
                    component={asDiv ? undefined : "span"}
                    sx={{
                        font,
                        color: (theme) => theme.palette.primary.dark,
                        ...style,
                        ...(sx as any)
                    }}>{children}</Box>
    }

}

export default Text