import React, { Component } from 'react'
import { Box, ListItem } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { Select } from '../../common/styled/Select';
import Text from '../../common/Text';

import { Asset } from '../../../actions/types/customAssets';
import { BaseParameterListRowProps } from './ParameterListRow';
import { PortfolioMapAsset } from '../../../actions/types/investmentPortfolio';

interface Props extends BaseParameterListRowProps {
    item: PortfolioMapAsset
    onItemChanged: (item: PortfolioMapAsset, value: PortfolioMapAsset) => void
    onItemRemoved: (item: PortfolioMapAsset, index: number) => void
    accountAssets: Asset[]
}

class AssesListRow extends Component<Props> {

    constructor(props) {
        super(props)
    }

    onAccountAssetChanged = ({ id }: Asset) => {
        const { item } = this.props
        const updatedItem = {...item, asset_id: id }
        this.props.onItemChanged(item, updatedItem)
    }

    onItemRemoved = () => {
        const { item, index, onItemRemoved } = this.props

        if(onItemRemoved) {
            onItemRemoved(item, index)
        }
    }


    render() {
        const { item, accountAssets = [] } = this.props

        const value = item.asset_name
        const accountAssetValue = accountAssets.find((accountAsset) => item.asset_id == accountAsset.id)

        return (
                <ListItem sx={{ position: 'relative', border: (theme) => `1px solid ${theme.palette.primary.main}`, padding: '1rem' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginRight: '1rem',
                    }}>
                        <Text sx={{ width: '15rem', marginRight: '1rem' }}>{value}</Text>
                        <Select sx={{ width: '25rem' }} value={accountAssetValue} options={accountAssets} onChange={this.onAccountAssetChanged}/>
                    </Box>
                    <FontAwesomeIcon icon={faTimes} onClick={this.onItemRemoved}/>
                </ListItem>
        )
    }

}

export default AssesListRow