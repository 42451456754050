import { Component } from 'react'
import { connect } from 'react-redux';
import { Box, List } from '@mui/material'

import ConfirmModal from '../../common/ConfirmModal'
import { ReduxState } from '../../../reducers';
import { addError } from '../../../actions/notifications';
import { DueDiligenceChartDTO } from '../../../actions/types/dueDiligence'
import { NumericFilterInput } from '../Screener/NumericFilterInput';
import Text from '../../../components/common/Text';
import ListRow, { RowType } from './ListRow';

interface ComponentProps {
    visible: boolean
    onConfirm: (chart: DueDiligenceChartDTO) => void
    onCancel: () => void
}

interface ReduxStateProps {
    chart: DueDiligenceChartDTO
}

interface ReduxActionProps {
    addError: (title: string, message: string) => void
}

interface RollingCorrelationInputsModalModalState {
    rc_tickers: string[]
    rc_lag: number
    disableConfirm: boolean
}

type RollingCorrelationInputsModalModalProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class RollingCorrelationInputsModal extends Component<RollingCorrelationInputsModalModalProps, RollingCorrelationInputsModalModalState> {
    selectedLinePath: any;

    constructor(props: RollingCorrelationInputsModalModalProps) {
        super(props);
        this.state = {
            rc_tickers: [],
            rc_lag: props.chart.rc_lag || 252,
            disableConfirm: true
        }
    }

    componentDidUpdate(prevProps: Readonly<RollingCorrelationInputsModalModalProps>, prevState: Readonly<RollingCorrelationInputsModalModalState>, snapshot?: any): void {
        if(prevProps.chart.rc_tickers !== this.props.chart.rc_tickers) {
            this.setState({rc_tickers: [...(this.props.chart.rc_tickers ?? [])].slice(-2)})
        }
        if(prevProps.chart.rc_lag !== this.props.chart.rc_lag) {
            this.setState({rc_lag: this.props.chart.rc_lag || 252})
        }
        if (prevProps.visible !== this.props.visible) {
            this.updateConfirmButton()
        }
    }

    
    onConfirm = () => {
        if(!this.props.onConfirm) {
            return
        }
        const {rc_tickers, rc_lag} = this.state
        if (rc_tickers.length < 2) {
            this.props.addError('Rolling Correlation', 'Please select at least 2 tickers')
            return
        }

        if (rc_lag < 1) {
            this.props.addError('Rolling Correlation', 'Please enter a valid rc_lag value')
            return
        }

        this.props.onConfirm({
            ...this.props.chart,
            rc_tickers,
            rc_lag
        })
    }

    onValueChanged = (value: string | number) => {
        this.setState({ rc_lag: Number(value) }, this.updateConfirmButton)
    }

    onCheckChanged = (item: string, checked: boolean) => {
        const { rc_tickers } = this.state
        const filtered = rc_tickers.filter(ticker => ticker !== item)
        let updated = checked ? [...filtered, item] : filtered
        if (updated.length > 2) {
            updated = updated.slice(-2)
        }

        this.setState({ rc_tickers: updated }, this.updateConfirmButton)
    }

    updateConfirmButton = () => {
        if (this.state.rc_lag < 1) {
            this.setState({ disableConfirm: true })
        }

        if (this.state.rc_tickers.length < 2) {
            this.setState({ disableConfirm: true })
        }

        if (this.state.rc_lag > 0 && this.state.rc_tickers.length === 2) {
            this.setState({ disableConfirm: false })
        }
    }

    onRenderTickerRow = (ticker: string) => {
        const { rc_tickers } = this.state
        return <ListRow type={RowType.CHECKBOX} item={ticker} checked={rc_tickers.includes(ticker)} onCheckChanged={this.onCheckChanged} showChecked />
    }
        

    render() {
        const { chart, visible } = this.props
        const { disableConfirm, rc_lag } = this.state
        const { tickers } = chart

        return (
            <ConfirmModal
                visible={visible}
                title={`Rolling Correlation`}
                confirmLabel={'Confirm'}
                cancelLabel={'Cancel'}
                confirmDisabled={disableConfirm}
                onConfirm={this.onConfirm}
                onCancel={this.props.onCancel}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <NumericFilterInput
                            title={'Rolling Lookback (Trading Days)'}
                            value={rc_lag}
                            isNumeric={true}
                            onValueChanged={this.onValueChanged}
                            inline={false}
                        />
                    </Box>
                    <Text type={'form_header'}>Select 2 Tickers:</Text>
                    <List
                     sx={{
                        padding: 0,
                    }}>
                        {tickers.map((item, index) => {
                            return this.onRenderTickerRow(item)
                        })}
                    </List>
            </ConfirmModal>
        )
    }
}

const mapStateToProps = ({dueDiligence}: ReduxState) => ({
    chart: dueDiligence.selectedChartItem,
})

export default connect(mapStateToProps, { addError })(RollingCorrelationInputsModal)