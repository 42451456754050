import React  from 'react'
import { useParams } from 'react-router-dom';

export interface WithClientParameterProps {
  clientId: string
}

export const withClientParameter = (Component) => {
  const Wrapper = (props) => {
    const { clientId } = useParams()
    
    return (
      <Component
        clientId={clientId}
          {...props}
        />
    );
  };
  
  return Wrapper;
};