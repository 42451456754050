import React, { FunctionComponent } from 'react'
import { Box } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import Collapsable from '../common/Collapsable'
import Panel from '../common/Panel'
import Text from '../common/Text'
import { BaseProps } from '../types'
import { ActionType, CircleActionButton } from '../common/styled/ShapedActionButton'

export interface GuidePanelProps extends BaseProps {
    id?: string
    title: string
    expanded?: boolean
    enterprise?: boolean
    membership?: string
    onExpandedChanged?: (expanded: boolean, id?: string) => void
}

export const GuidePanel: FunctionComponent<GuidePanelProps> = ({ id, title, expanded: propsExpanded, onExpandedChanged, children, sx = {}}) => {
    const [expanded, setExpanded] = React.useState<boolean>(false)
    const [propManaged, setPropManaged] = React.useState<boolean>(false)
    const [panelAction, setPanelAction] = React.useState<ActionType>('add')

    const toggleExpanded = React.useCallback(() => {
        const nextState = propManaged ? !propsExpanded : !expanded
        if(!propManaged) {
            setExpanded(nextState)
        }

        if (onExpandedChanged) {
            onExpandedChanged(nextState, id)
        }
    }, [propsExpanded, propManaged, expanded, onExpandedChanged])

    React.useEffect(() => {
        setPropManaged(propsExpanded !== undefined)
    }, [propsExpanded])

    React.useEffect(() => {
        const isExpanded = propManaged ? !propsExpanded : !expanded
        setPanelAction(isExpanded ? 'add' : 'remove')
    }, [propManaged, expanded, propsExpanded])
    return (
        <Panel
            sx={{
                margin: {
                    xs: undefined,
                    md: '2rem',
                },
                marginBottom: '2rem',
                ...sx,
            }}
            title={
                (<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={toggleExpanded}>
                    <CircleActionButton
                        sx={{
                            height: '3.5rem',
                            width: '3.5rem',
                            fontSize: (theme) => theme.typography.pxToRem(24),
                        }}
                        action={panelAction}
                        onClick={toggleExpanded}
                    />
                    <Text sx={{ marginLeft: '1rem' }} type={'table_header'}>{title}</Text>
                </Box>)
            }
        >
            <Collapsable
                duration={150}
                collapsed={propManaged ? !propsExpanded : !expanded}
                sx={{ padding: '1rem' }}
            >
                {children}
            </Collapsable>
        </Panel>
    )
}