import React, { Component } from 'react'
import { connect } from 'react-redux'

import { yearsToFinancialGoalUpdate, desiredAnnualFundingUpdate, investmentPortfolioTodayUpdate, currentAnnualSavingsUpdate, onUpdateIrregularItem } from '../../actions/standardLivingRisk'
import { annualIncomeUpdate } from '../../actions/riskPreferences'

import { LineItem, LineItemOnChange } from './LineItem'
import Card from './Card'
import { IrregularItem } from '../../actions/types/standardLivingRisk'

interface ClientInputsProps {
    annualIncome: number
    yearsToFinancialGoal: number
    desiredAnnualFunding: number
    investmentPortfolioToday: number
    currentAnnualSavings: number
    additionalRetirementIncome: IrregularItem
    onUpdateIrregularItem: (type: 'income' | 'expense', item: IrregularItem) => void
    annualIncomeUpdate: LineItemOnChange
    yearsToFinancialGoalUpdate: LineItemOnChange
    desiredAnnualFundingUpdate: LineItemOnChange
    investmentPortfolioTodayUpdate: LineItemOnChange
    currentAnnualSavingsUpdate: LineItemOnChange
}

class ClientInputs extends Component<ClientInputsProps> {
    constructor(props) {
        super(props)
    }

    onAdditionalRetriementIncomeChange = (value) => {
        const { additionalRetirementIncome } = this.props

        const updateItem = { ...additionalRetirementIncome, value }

        this.props.onUpdateIrregularItem('income', updateItem)
    }

    render() {
        const { annualIncome, yearsToFinancialGoal, desiredAnnualFunding, investmentPortfolioToday, currentAnnualSavings, additionalRetirementIncome } = this.props
        
        return (
            <Card title={'Client Inputs'}>
                <LineItem number={1} label={'Annual Income'} value={annualIncome} onChange={this.props.annualIncomeUpdate} extendedInput />
                <LineItem number={2} label={'Years To Retirement'} value={yearsToFinancialGoal} onChange={this.props.yearsToFinancialGoalUpdate} extendedInput />
                <LineItem number={3} label={'Annual Retirement Funding Goal'} value={desiredAnnualFunding} onChange={this.props.desiredAnnualFundingUpdate} extendedInput />
                <LineItem number={4} label={'Investment Portfolio Today'} value={investmentPortfolioToday} onChange={this.props.investmentPortfolioTodayUpdate} extendedInput />
                <LineItem number={5} label={'Annual Savings towards Retirement'} value={currentAnnualSavings} onChange={this.props.currentAnnualSavingsUpdate} extendedInput />
                <LineItem number={6} label={'Additional Retirement Income'} value={additionalRetirementIncome?.value || 0} onChange={this.onAdditionalRetriementIncomeChange} extendedInput />
            </Card>
        )
    }

}

const mapStateToProps = ({ standardLivingRisk, session }) => {
    const { years_to_financial_goal: yearsToFinancialGoal, desired_annual_funding: desiredAnnualFunding, investment_portfolio_today: investmentPortfolioToday, current_annual_savings: currentAnnualSavings, income } = standardLivingRisk
    const { annual_income: annualIncome } = session.profile || { }
    const additionalRetirementIncome = (income || []).find(({ description, type }) => description === "Additional Retirement Income" && type === 'income')
    
    return {
        yearsToFinancialGoal,
        desiredAnnualFunding,
        investmentPortfolioToday,
        currentAnnualSavings,
        annualIncome,
        additionalRetirementIncome,
    }
}

export default connect(mapStateToProps, { yearsToFinancialGoalUpdate, desiredAnnualFundingUpdate, investmentPortfolioTodayUpdate, currentAnnualSavingsUpdate, annualIncomeUpdate, onUpdateIrregularItem })(ClientInputs)
