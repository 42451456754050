import React, { Component, FunctionComponent } from 'react'
import { Box, SxProps, Theme } from '@mui/material'
import Text from '../common/styled/Text'
import { BaseProps } from '../types'
import { BorderedContainer } from '../common/styled/BorderedContainer'
import { TextField } from '../common/FormField'
import { StyledInput } from '../common/StyledInput'

export const LineItemContainer: FunctionComponent<BaseProps> = ({ sx = {}, className, children }) => {
    return (
        <Box sx={{
            display: 'flex',
            position: 'relative',
            flex: 1,
            width: '77.5rem',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '1.5rem',
            marginBottom: '1.5rem',
            ...sx
        }} className={className}>{children}</Box>
    )
}

export const LineItemLabel: FunctionComponent<BaseProps> = ({ sx = {}, children }) => {
    return (
        <Text type='h4' sx={{
            flex: 1,
            display: 'flex',
            textAlign: 'left',
            paddingRight: '1.5rem',
            ...sx,
        }}>{children}</Text>
    )
}

export const LineItemNumber: FunctionComponent<BaseProps> = ({ sx = {}, children }) => {
    return (
        <BorderedContainer sx={{
            marginRight: '1.5rem',
            ...sx
        }}>{children}</BorderedContainer>
    )
}

export type LineItemOnChange = (vaue: string | number) => void

export interface LineItemState  {
    inputHasFocus?: boolean
    value: string | number
}

export interface LineItemProps extends BaseProps {
    label: string | React.ReactNode
    value: number | string
    onChange?: LineItemOnChange
    onLeave?: LineItemOnChange
    showLineNumber?: boolean
    number?: number
    extendedInput?: boolean
    decimalPlaces?: number
    isPercentage?: boolean
    alphaNumeric?: boolean
    inputClassName?: string
    inputSx?: SxProps<Theme>
}


export class LineItem extends Component<LineItemProps, LineItemState> {
    render() {
        const { 
                showLineNumber = false,
                number,
                label,
                value,
                extendedInput,
                alphaNumeric,
                decimalPlaces,
                isPercentage,
                className = '',
                inputClassName = '',
                sx = { },
                inputSx = { } 
            } = this.props

        return (
            <LineItemContainer sx={sx} className={className}>
                {showLineNumber &&
                    <LineItemNumber>{number}</LineItemNumber>
                }
                <LineItemLabel>{label}</LineItemLabel>
                <StyledInput
                    className={inputClassName} 
                    extendedInput={extendedInput}
                    inputSx={inputSx}
                    value={value}
                    alphaNumeric={alphaNumeric}
                    decimalPlaces={decimalPlaces}
                    isPercentage={isPercentage}
                    onChange={this.props.onChange}
                    onLeave={this.props.onLeave}
                />
            </LineItemContainer>
        )
    }
}