import { SxProps, Theme } from '@mui/material'
import React, { Component } from 'react'
import { TextField } from '../common/FormField'
import { BaseProps } from '../types'
import { LineItemContainer, LineItemLabel, LineItemNumber } from './LineItem'

export type LineTextAreaOnChange = (vaue: string) => void

export interface LineTextAreaState  {
    inputHasFocus?: boolean
    value: string
}

export interface LineTextAreaProps extends BaseProps {
    label: string
    value: string
    onChange: LineTextAreaOnChange
    showLineNumber?: boolean
    number?: number
    extendedInput?: boolean
    inputSx?: SxProps<Theme>
}

export class LineTextArea extends Component <LineTextAreaProps, LineTextAreaState> {
    inputRef: any
    constructor(props) {
        super(props)
        const { value } = props

        this.state = {
            value: value === null ? '' : value,
            inputHasFocus: false
        }
    }

    componentDidUpdate(prevProps) {
        const { inputHasFocus } = this.state
        const { value } = this.props
        const { value: prevValue } = prevProps

        if(value == prevValue || inputHasFocus) {
            return
        }

        this.setState({ value: value == null ? '' : value })
    }

    onChange = (e) => {
        let value = e.target.value
        this.setState({ value })
    }

    onBlur = (e) => {
        this.setState({ inputHasFocus: false })
        if(this.props.onChange) {
            this.props.onChange(e.target.value)
        }
    }

    render() {
        const { sx = {}, showLineNumber = false, number, label, inputSx } = this.props
        const { value } = this.state

        return (
            <LineItemContainer sx={{
                width: '750px',
                resize: 'none',
                ...sx,
            }}>
                {showLineNumber &&
                    <LineItemNumber>{number}</LineItemNumber>
                }
                <LineItemLabel>{label}</LineItemLabel>
                <TextField
                    forwardRef={view => this.inputRef = view}
                    sx={{
                        width: '30rem',
                        height: '11rem',
                        alignItems: 'flex-start',
                        '& textarea': {
                            width: '30rem !important',
                            height: '8rem !important',
                        },
                        ...inputSx,
                    }}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    onFocus={() => this.setState({ inputHasFocus: true })}
                    value={value}
                ></TextField>
            </LineItemContainer>
        )
    }
}