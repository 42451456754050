import { Box, Link } from '@mui/material'
import React, { FunctionComponent } from 'react'
import { BaseProps } from '../../types'

interface HeaderLogoProps extends BaseProps {
    logoUrl?: string
    linkUrl: string
}

export const HeaderLogo: FunctionComponent<HeaderLogoProps> = ({ sx = { }, logoUrl, linkUrl}) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...sx }}>
            <Link
                sx={{ 
                    '& img': { maxWidth: '30rem', maxHeight: '6rem', marginBottom: '4.5rem' }
                }}
                href={linkUrl}
            >
                {logoUrl && <img src={logoUrl} alt='Header Logo' /> }
            </Link>
        </Box>        
    )
}