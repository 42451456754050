import axios from 'axios'
import { ChartOptions, getAssetAllocatioChart, getRiskAnalyzerEnterpriseChart } from '../components/OptimizedPortfolio/chartOptions'
import { StandardThunkAction } from '../store/store'
import { addError } from './notifications'
import { getEnterpriseHoldings, getTableDetails } from './riskPreferences'
import { 
    PRINT_DETAILS_RECEIVED,
} from "./types/actions"
import { PortfolioMapDetail } from './types/investmentPortfolio'

export const CLIENTS_ENDPOINT = '/api/clients'
export const PRINT_ENDPOINT = 'print/details'

export const getPrintDetails = (clientId: number): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const paths = window.location.pathname.split('/')
            const profileId = clientId || paths[paths.length - 2]
            const { data } = await axios.get(`${CLIENTS_ENDPOINT}/${profileId}/${PRINT_ENDPOINT}`)
            const { portfolio, session, riskPreferences } = data
            let { holdings, portfolioMaps } = portfolio
            let tableDetail: PortfolioMapDetail | undefined
            let riskAnalyzerEnterpriseChart: ChartOptions = { }

            if(session.enterprise) {
                const { preferred, recommended } = riskPreferences

                const hasRecommended = recommended?.gamma && recommended.lambda
                const gamma = hasRecommended ? recommended?.gamma : preferred.gamma
                const lambda = hasRecommended ? recommended?.lambda : preferred.lambda
                const portfolioMapsWithLabel = portfolioMaps ? portfolioMaps.map((item: any) => {
                    const { name: label, id: value, details: itemDetails = [] } = item
    
                    const details = itemDetails.reduce((accumulator: any, { mappings, ...details }: any) => {
                        const mappingsReduced = mappings.reduce((mappingAccum: any, { gamma_risk_level, lambda_risk_level }: any) => {
    
                            return { ...mappingAccum, [`${gamma_risk_level}_${lambda_risk_level}`]: { ...details } }
                        }, { })
                        return { ...accumulator, ...mappingsReduced  }
                    }, { })
    
                    return { ...item, details, label, value }
                }) : []

                holdings = getEnterpriseHoldings({ gamma, lambda, portfolioMap: portfolioMapsWithLabel[0] })
                tableDetail = getTableDetails({ gamma, lambda, portfolioMap: portfolioMapsWithLabel[0] })
                riskAnalyzerEnterpriseChart = getRiskAnalyzerEnterpriseChart(tableDetail)
            }

            const assetAllocationChart = getAssetAllocatioChart(holdings, { 
                color: '#6d7073',
                style: {
                }
            })
            
            dispatch({ type: PRINT_DETAILS_RECEIVED, payload: { ...data, portfolio: { ...portfolio, assetAllocationChart, holdings, tableDetail, riskAnalyzerEnterpriseChart } } })
        } catch(e: any) {
            dispatch(addError('Standard Living Risk', e.toString()))
        }
    }
}