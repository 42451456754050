import { SelectOption } from "../../common/types";

export interface InternalPerformanceAttributionTickers {
    [key: string]: {
      start: Date;
      end: Date;
    };
  }


export interface ShockValue {
    shock_type: 'pct' | 'pt' | 'level';
    param: 'stdev' | 'literal' | 'literal';
    values: number[];
}

export interface Shocks {
    [key: string]: ShockValue;
}

export interface PositionDetail {
  pos_qty: number | null;
  base_value: number;
  [key: string]: number | null;
}

export interface PortfolioPositions {
  [key: string]: PositionDetail;
}

export const RISK_ANALYZER_PRESETS = {
  "price": [
      {
          "name": "0",
          "shock": [
              "pct",
              "pt",
              "level"
          ],
          "param": [
              "stdev",
              "ptile",
              "literal"
          ],
          "values": [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
          ]
      },
      {
          "name": "-5sd to +5sd",
          "shock": [
              "pct",
              "pt"
          ],
          "param": [
              "stdev"
          ],
          "values": [
              -5,
              -4,
              -3,
              -2,
              -1,
              1,
              2,
              3,
              4,
              5
          ]
      },
      {
          "name": "-10sd to +10sd",
          "shock": [
              "pct",
              "pt"
          ],
          "param": [
              "stdev"
          ],
          "values": [
              -10,
              -8,
              -6,
              -4,
              -2,
              2,
              4,
              6,
              8,
              10
          ]
      },
      {
          "name": "1%tile to 99%tile",
          "shock": [
              "pct",
              "pt"
          ],
          "param": [
              "ptile"
          ],
          "values": [
              1,
              1.5,
              2.5,
              15.87,
              30.9,
              69.1,
              84.13,
              97.5,
              98.5,
              99
          ]
      },
      {
          "name": "0.1%tile to 99.9%tile",
          "shock": [
              "pct",
              "pt"
          ],
          "param": [
              "ptile"
          ],
          "values": [
              0.1,
              0.5,
              2.5,
              15.87,
              30.9,
              69.1,
              84.13,
              97.5,
              99.5,
              99.9
          ]
      },
      {
          "name": "-5% to +5%",
          "shock": [
              "pct"
          ],
          "param": [
              "literal"
          ],
          "values": [
              -0.05,
              -0.04,
              -0.03,
              -0.02,
              -0.01,
              0.01,
              0.02,
              0.03,
              0.04,
              0.05
          ]
      },
      {
          "name": "-10% to +10%",
          "shock": [
              "pct"
          ],
          "param": [
              "literal"
          ],
          "values": [
              -0.1,
              -0.08,
              -0.06,
              -0.04,
              -0.02,
              0.02,
              0.04,
              0.06,
              0.08,
              0.1
          ]
      }
  ],
  "vol": [
      {
          "name": "0",
          "shock": [
              "pct",
              "pt",
              "level"
          ],
          "param": [
              "stdev",
              "ptile",
              "literal"
          ],
          "values": [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
          ]
      },
      {
          "name": "+5% to -5%",
          "shock": [
              "pct"
          ],
          "param": [
              "literal"
          ],
          "values": [
              0.05,
              0.04,
              0.03,
              0.02,
              0.01,
              -0.01,
              -0.02,
              -0.03,
              -0.04,
              -0.05
          ]
      },
      {
          "name": "+10% to -10%",
          "shock": [
              "pct"
          ],
          "param": [
              "literal"
          ],
          "values": [
              0.1,
              0.08,
              0.06,
              0.04,
              0.02,
              -0.02,
              -0.04,
              -0.06,
              -0.08,
              -0.1
          ]
      },
      {
          "name": "+20% to -20%",
          "shock": [
              "pct"
          ],
          "param": [
              "literal"
          ],
          "values": [
              0.25,
              0.2,
              0.15,
              0.1,
              0.05,
              -0.05,
              -0.1,
              -0.15,
              -0.2,
              -0.25
          ]
      },
      {
          "name": "+25% to -25%",
          "shock": [
              "pct"
          ],
          "param": [
              "literal"
          ],
          "values": [
              0.25,
              0.2,
              0.15,
              0.1,
              0.05,
              -0.05,
              -0.1,
              -0.15,
              -0.2,
              -0.25
          ]
      },
      {
          "name": "+50% to -50%",
          "shock": [
              "pct"
          ],
          "param": [
              "literal"
          ],
          "values": [
              0.5,
              0.4,
              0.3,
              0.2,
              0.1,
              -0.1,
              -0.2,
              -0.3,
              -0.4,
              -0.5
          ]
      },
      {
          "name": "+5%",
          "shock": [
              "pct"
          ],
          "param": [
              "literal"
          ],
          "values": [
              0.05,
              0.05,
              0.05,
              0.05,
              0.05,
              0.05,
              0.05,
              0.05,
              0.05,
              0.05
          ]
      },
      {
          "name": "+10%",
          "shock": [
              "pct"
          ],
          "param": [
              "literal"
          ],
          "values": [
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1
          ]
      },
      {
          "name": "-5%",
          "shock": [
              "pct"
          ],
          "param": [
              "literal"
          ],
          "values": [
              -0.05,
              -0.05,
              -0.05,
              -0.05,
              -0.05,
              -0.05,
              -0.05,
              -0.05,
              -0.05,
              -0.05
          ]
      },
      {
          "name": "-10%",
          "shock": [
              "pct"
          ],
          "param": [
              "literal"
          ],
          "values": [
              -0.1,
              -0.1,
              -0.1,
              -0.1,
              -0.1,
              -0.1,
              -0.1,
              -0.1,
              -0.1,
              -0.1
          ]
      },
      {
          "name": "+5 to -5",
          "shock": [
              "pt"
          ],
          "param": [
              "literal"
          ],
          "values": [
              5,
              4,
              3,
              2,
              1,
              -1,
              -2,
              -3,
              -4,
              -5
          ]
      },
      {
          "name": "+10 to -10",
          "shock": [
              "pt"
          ],
          "param": [
              "literal"
          ],
          "values": [
              10,
              8,
              6,
              4,
              2,
              -2,
              -4,
              -6,
              -8,
              -10
          ]
      },
      {
          "name": "+20 to -20",
          "shock": [
              "pt"
          ],
          "param": [
              "literal"
          ],
          "values": [20, 15, 10, 5, 1, -1, -5, -10, -15, -20]
      },
      {
          "name": "+30 to -30",
          "shock": [
              "pt"
          ],
          "param": [
              "literal"
          ],
          "values": [30, 25, 20, 15, 10, -10, -15, -20, -25, -30]
      },
      {
          "name": "+5",
          "shock": [
              "pt"
          ],
          "param": [
              "literal"
          ],
          "values": [
              5,
              5,
              5,
              5,
              5,
              5,
              5,
              5,
              5,
              5
          ]
      },
      {
          "name": "+10",
          "shock": [
              "pt"
          ],
          "param": [
              "literal"
          ],
          "values": [
              10,
              10,
              10,
              10,
              10,
              10,
              10,
              10,
              10,
              10
          ]
      },
      {
          "name": "-5",
          "shock": [
              "pt"
          ],
          "param": [
              "literal"
          ],
          "values": [
              -5,
              -5,
              -5,
              -5,
              -5,
              -5,
              -5,
              -5,
              -5,
              -5
          ]
      },
      {
          "name": "-10",
          "shock": [
              "pt"
          ],
          "param": [
              "literal"
          ],
          "values": [
              -10,
              -10,
              -10,
              -10,
              -10,
              -10,
              -10,
              -10,
              -10,
              -10
          ]
      }
  ]
}

export interface PresetItem {
  name: string;
  shock: string[];
  param: string[];
  values: number[];
}


export const PRICE_PRESETS_OPTIONS: SelectOption<PresetItem>[] = RISK_ANALYZER_PRESETS.price.map((preset) => {
  return { label: preset.name, value: preset.name, item: preset }
});

export const VOL_PRESETS_OPTIONS: SelectOption<PresetItem>[] = RISK_ANALYZER_PRESETS.vol.map((preset) => {
  return { label: preset.name, value: preset.name, item: preset }
});
