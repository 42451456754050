import React, { Component } from 'react'
import { connect } from 'react-redux'
import  * as moment from 'moment-timezone'
import 'moment-timezone'
import { getUsers, getMetricUsers, addUser, updateUser } from '../../../actions/adminUserManagement'
import { EditUser, MEMBERSHIP_MAPPING, NEW_USER, User, ActivationCode, MetricUser, MEMBERSHIP_OPTION, MEMBERSHIP_OPTIONS, ACTIVATION_CODE_OPTION } from '../../../actions/types/users'
import { DataTable } from '../../common/DataTable'
import { Box } from '@mui/material'
import { GetUsersRequest } from '../../../actions/types/requests'
import { StandardApiResponse } from '../../../actions/types/responses'
import { SelectOption, StatusLevel } from '../../../common/types'
import { StatusLevelPill } from '../../common/StatusLevelPill'
import { AdminPage } from '../AdminPage'
import { Select } from '../../common/styled/Select'
import { getActivationCodes } from '../../../actions/adminActivationCodes'
import { withRouter } from '../../../common/hooks/withParams'
import { WithRouterProps } from '../../../components/common/withRouter'
import { getQueryParams } from '../../../common/utils'
import { withAuth, withAuthProps } from '../../../common/hooks/withAuth'

interface MetricRow {
    firstName: string
    lastName: string
    fullName: string
    email: string
    membership: string
    membershipFormatted: string
    assignedUserName: string
    status: React.ReactNode
    status_index: number;
    activation_code?: ActivationCode
    configurationCount: number
    created_at: string
    created_at_ms: number
    last_activity: string
    last_activity_ms: number
}

interface Props extends WithRouterProps, withAuthProps {
    getActivationCodes: () => void
    getUsers: (params?: GetUsersRequest) => void
    addUser: (params?: EditUser) => Promise<StandardApiResponse>
    updateUser: (params?: EditUser) => Promise<StandardApiResponse>
    activationCodes: ActivationCode[]
}

interface State {
    users: MetricUser[]
    userRows: MetricRow[]
    filteredRows: MetricRow[]
    memberships: SelectOption[]
    showUtilitiesUser?: User
    addEditModalUser?: EditUser
    selectedMembership: SelectOption
}

const DEFAULT_ALL = { label: 'All Token Reps', value: 'all', type: MEMBERSHIP_OPTION }

export const getSubscriptionStatus = ({ is_unsubscribing, is_active }: User): StatusLevel => {
    if(is_unsubscribing) {
        return StatusLevel.WARNING
    } 
    
    if(is_active) {
        return StatusLevel.OK
    }
    
    return StatusLevel.DANGER
}
const SUBSCRIPTION_STATUS_LABEL = {
    [StatusLevel.OK]: 'Active',
    [StatusLevel.WARNING]: 'Cancelling',
    [StatusLevel.DANGER]: 'Deactivated',
}

const SORT_COLUMN_MAPPING: { [key: string]: string } = {
    'last_activity': 'last_activity_ms',
    'created_at': 'created_at_ms',
    'status': 'status_index',
}
class DesignerMetrics extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            users: [],
            userRows: [],
            filteredRows: [],
            memberships: [],
            showUtilitiesUser: undefined,
            selectedMembership: DEFAULT_ALL,
        }
    }
    
    async componentDidMount() {
        this.loadUsers()
        await this.props.getActivationCodes()
        const { code } = getQueryParams()

        if(code) {
            const foundCode = this.state.memberships.find((item) => item.code === code);
            if(foundCode) {
                this.onSelectedMembership(foundCode)
            }
        } 
    }

    loadUsers = async() => {
        const users = await getMetricUsers()
        this.setState({ users })
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { activationCodes: prevActivationCodes, } = prevProps
        const { activationCodes } = this.props
        const { users: prevUsers, userRows: prevUserRows, selectedMembership: prevSelectedMembership } = prevState
        const { users, userRows, selectedMembership } = this.state

        if(prevUsers !== users) {
            const userRows = this.formatUser()

            this.setState({ userRows })
        }

        if(prevUserRows !== userRows) {
            const filteredRows = this.getUserRowsWithFilter(selectedMembership)

            this.setState({ filteredRows })
        }

        if(prevActivationCodes !== activationCodes) {
            const memberships = this.formatMemberships(activationCodes)

            this.setState({ memberships })
        }
    }

    formatMemberships = (activationCodes: ActivationCode[]): SelectOption[] => {
        const codesWithUsers = activationCodes.filter((item) => item.assigned_user_id);
        const activationMembershipOptions = codesWithUsers.map(({ membership, assigned_user: assignedUser, id, code }) => {
            const membershipValue: string = `${MEMBERSHIP_MAPPING[membership]} (${assignedUser?.firstName[0]}. ${assignedUser?.lastName})`

            return { label: membershipValue, value: id, type: ACTIVATION_CODE_OPTION, code }
        })
        return [DEFAULT_ALL, ...activationMembershipOptions]
    }

    onAddUser = () => {
        this.setState({ addEditModalUser: {...NEW_USER}})
    }

    onEditUser = (user: User) => {
        this.setState({ addEditModalUser: {...user, external_subscription_id: user.subscription?.external_subscription_id }})
    }

    onCloseUtilties = () => {
        this.setState({ showUtilitiesUser: undefined })
    }

    onOpenTools = (user: User) => {
        this.setState({ showUtilitiesUser: user })
    }


    formatUser = (): MetricRow[] => {
        const userRows:MetricRow[] = this.state.users.map((user: MetricUser) => {
            const { firstName, lastName, email, membership, created_at, unsubscribed_on, 
                last_activity, configurationCount, activation_code } = user
            const stringStatus = getSubscriptionStatus(user)
            const statusIndex = Object.keys(SUBSCRIPTION_STATUS_LABEL).indexOf(stringStatus)
            const status = <StatusLevelPill status={stringStatus}>{SUBSCRIPTION_STATUS_LABEL[stringStatus]} {unsubscribed_on}</StatusLevelPill> 
            let membershipFormatted = MEMBERSHIP_MAPPING[membership]
            let assignedUserName = 'Unassigned'
            if (activation_code?.assigned_user) {
                const assignedUser = activation_code?.assigned_user
                assignedUserName = `${assignedUser?.firstName[0]}. ${assignedUser?.lastName}`
                membershipFormatted = `${membershipFormatted} (${assignedUser?.firstName[0]}. ${assignedUser?.lastName})`
            }

            const lastActivity = last_activity ? moment.utc(last_activity).tz("America/New_York").format('MMM DD yyyy hh:mm a') : ''
            const lastActivityMs = last_activity ? moment.utc(last_activity).toDate().getTime() : 0;
            const createdAt = created_at ? moment.utc(created_at).tz("America/New_York").format('MMM DD yyyy hh:mm a') : '';
            const createdAtMs = created_at ? moment.utc(created_at).toDate().getTime() : 0;

            return { firstName, lastName, fullName: `${firstName} ${lastName}`, email, membership, membershipFormatted, stringStatus, status, status_index: statusIndex, configurationCount, last_activity: lastActivity, last_activity_ms: lastActivityMs, created_at: createdAt, created_at_ms: createdAtMs, activation_code, assignedUserName }
        })

        return userRows.filter(({ assignedUserName }) => assignedUserName !== 'Unassigned')
    }

    getUserRowsWithFilter = (selectedMembership: SelectOption) => {
        const { userRows: users } = this.state;
        const { value, type } = selectedMembership
        return users.filter((user) => {
            if (value === 'all') {
                return true
            }

            return user.activation_code?.id === value;
        })
    }

    onSelectedMembership = (selectedMembership: SelectOption) => {
        this.setState({ selectedMembership })
        const filteredRows = this.getUserRowsWithFilter(selectedMembership)

        this.setState({ filteredRows })
    }

    onSort = ({ column, direction }: { column: string, direction: string}) => {
        const { filteredRows } = this.state
        const sortColumn: string = SORT_COLUMN_MAPPING[column] ?? column
        const sortedRows = [...filteredRows].sort((a: any, b: any) => {
            if (direction === 'asc') {
                return a[sortColumn] > b[sortColumn] ? 1 : -1
            }

            return a[sortColumn] < b[sortColumn] ? 1 : -1
        })

        this.setState({ filteredRows: sortedRows })
    }

    render() {
        const { filteredRows } = this.state
        const { userDetails } = this.props
        const { scopes } = userDetails || { scopes: [''] }
        const fullAdmin = scopes.includes('pd:admin:*')
        const searchActions = !fullAdmin ? undefined : (
            <Box>
                Token Reps
                <Select sx={{ width: '25rem', marginRight: '1.5rem' }} options={this.state.memberships} value={this.state.selectedMembership} onChange={this.onSelectedMembership} />
            </Box>
        )
        return (
            <AdminPage containerSx={{ paddingTop: '5rem' }}>
                <DataTable
                    searchSx={fullAdmin ? { marginBottom: '1rem', marginLeft: '27rem' }: undefined}
                    searchActionSx={{ top: '-3.5rem', left: '0rem' }}
                    searchActions={searchActions}
                    onSort={this.onSort}
                    data={{ 
                        rows: filteredRows, 
                        columns: [{
                            label: 'Name',
                            field: 'fullName'
                        },{
                            label: 'Email',
                            field: 'email'
                        },{
                            label: 'Token Rep',
                            field: 'assignedUserName'
                        },{
                            label: 'Membership',
                            field: 'membershipFormatted'
                        },{
                            label: '# of Portfolios',
                            field: 'configurationCount'
                        },{
                            label: 'Last Usage',
                            field: 'last_activity'
                        },{
                            label: 'Created On',
                            field: 'created_at'
                        },{
                            label: 'Status',
                            field: 'status'
                        }]
                    }}
                /> 
            </AdminPage>
        )
    }
}

const mapStateToProps = ({ activationCodes }) => {
    const { activationCodes: items } = activationCodes

    return { activationCodes: items }
}

export default withAuth(withRouter(connect(mapStateToProps, { getActivationCodes, getUsers, addUser, updateUser })(DesignerMetrics)))