import React from 'react';
import { Box, Typography } from '@mui/material';
import { BaseProps } from '../../types';

interface BioProps extends BaseProps {
    avatarSource: string
    name: string
}

class Bio extends React.Component <BioProps> {

    render() {
        const { avatarSource, name, children, sx } = this.props

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                ...sx,
            }}>
                <Box sx={{
                    height: '18rem',
                    width: '18rem',
                    borderRadius: '18rem',
                    overflow: 'hidden',
                    '& img': {
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                    }
                }}>
                    <img src={avatarSource} />
                </Box>
                <Box sx={{
                    marginTop: '2rem',
                    marginBottom: '2rem',
                }}>
                    <Typography sx={{
                            font: (theme) => `normal normal bold ${theme.typography.pxToRem(38)} Montserrat`
                        }}>{name}</Typography>
                </Box>
                <Typography sx={{
                        font: (theme) => `normal normal normal ${theme.typography.pxToRem(28)} Montserrat`,
                    }}>{children}</Typography>
            </Box>
        )
    }
}

export default Bio