import React, { Component } from 'react';
import { Table, TableProps } from './Table';
import { Box, TableCell, TableCellProps, TableRow, TableRowProps, IconButton, ButtonBase, SxProps } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import html2canvas from 'html2canvas';
import moment from 'moment';

import Text from './styled/Text';
import { TableRowData } from '../../common/types';
import ExportMenu from './ChartExport';
import PdComponent from './PdComponent';
import { Theme } from '../../theme/theme';

export interface CommonTableProps extends TableProps {
    noDataMessage?: string;
    rowProps?: TableRowProps;
    valueCellProps?: TableCellProps;
    rows: TableRowData[];
    redNegativeValues?: boolean;
    title?: string | React.ReactNode;
    exportTitle?: string;
    footer?: React.ReactNode
    attribution?: React.ReactNode
    footerAttributionSx?: SxProps<Theme>
    footerOnlyInExport?: boolean
    showExport?: boolean;
    headerContainerProps?: SxProps<Theme>;
    tableSx?: SxProps<Theme>;
}

interface State {
    expandedRows: Set<string>;
    exporting: boolean;
}

export default class CommonTable extends PdComponent<CommonTableProps, State> {
    private tableContainerRef = React.createRef<any>();
    constructor(props: CommonTableProps) {
        super(props);
        this.state = {
            expandedRows: new Set<string>(),
            exporting: false,
        };
    }

    toggleRow = (key: string) => {
        const newSet = new Set(this.state.expandedRows);
        if (newSet.has(key)) {
            newSet.delete(key);
        } else {
            newSet.add(key);
        }
        this.setState({ expandedRows: newSet });
    };

    renderRow = (rowData: TableRowData, isSubRow: boolean = false) => {
        const { rowProps = {}, valueCellProps = {}, redNegativeValues } = this.props;
        const { key, title, titleSx = {}, valueSx = {}, titleRowSpan, values, valueKeys, ignoreTitle, seperator, rows: subRows, rowProp } = rowData;
        const shouldIncludeTitle = !ignoreTitle && title;
        const isExpanded = this.state.expandedRows.has(key || '');

        if (seperator) {
            return (
                <TableRow key={key || title} {...rowProps}>
                    <TableCell sx={{ borderBottom: '1px solid #000', borderTop: '1px solid #000', height: '2.5rem' }} colSpan={this.props.headerColumns?.length}></TableCell>
                </TableRow>
            );
        }

        const rows = [
            <TableRow key={key || title} {...rowProps} {...rowProp}>
                {shouldIncludeTitle && 
                    <Box component={TableCell} rowSpan={titleRowSpan} sx={{ ...titleSx }}>
                        {(subRows?.length ?? 0) > 0 && (
                            <ButtonBase onClick={() => this.toggleRow(key || '')}>
                                <IconButton size="small">
                                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                                <Text sx={{ ...titleSx }}>{title}</Text>
                            </ButtonBase>
                        )}
                        {!subRows?.length && (
                            <Text sx={{ ml: isSubRow ? '2.5rem' : undefined, ...titleSx }}>{title}</Text>
                        )}
                    </Box>
                }
                {values.map((value: any, index: number) => {
                    const cellKey = valueKeys && valueKeys[index] ? valueKeys[index] : `${index}${value}`;
                    const negativeSx = redNegativeValues && `${value}`.startsWith('-') ? { color: 'red' } : {};
                    if (index === 0 && !shouldIncludeTitle && subRows?.length) {
                        return (
                            <TableCell key={cellKey} {...valueCellProps}>
                                <ButtonBase onClick={() => this.toggleRow(key || '')}>
                                    <IconButton size="small">
                                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                    <Text sx={{ ...valueSx, ...negativeSx }}>{value}</Text>
                                </ButtonBase>
                            </TableCell>
                        );
                    }
                    return (
                        <TableCell key={cellKey} {...valueCellProps}><Text sx={{ ml: isSubRow && index === 0 ? '2.5rem' : undefined, ...valueSx, ...negativeSx }}>{value}</Text></TableCell>
                    )
                })}
            </TableRow>
        ];

        if (isExpanded && subRows) {
            subRows.forEach((row: TableRowData ) => {
                rows.push(this.renderRow(row, true) as any);
            });
        }

        return rows;
    };
    
    downloadImage = (dataUrl: string, filename: string) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    exportChart = async () => {
        if (!this.tableContainerRef.current) {
            return;
        }
        
        try {
            const { exportTitle, title } = this.props;
            const titleText = exportTitle ? exportTitle : typeof title === 'string' ? title : '';
            const time = moment().tz('America/New_York').format('YYYY-MM-DD.hh.mm.a');
            const name = `${titleText || 'exported-table'}-${time}.png`.replace(/ /g, '-').toLowerCase();

            await this.setStateAsync({ exporting: true });
            const canvas = await html2canvas(this.tableContainerRef.current, { useCORS : true, imageTimeout: 1000, allowTaint: true, logging: true });
            this.setState({ exporting: false });
            const image = canvas.toDataURL("image/png");
            this.downloadImage(image, name);
        } catch (error) {
            console.error('Error capturing div:', error);
        }
    }

    render() {
        const { rows, noDataMessage, tableSx = {}, footerAttributionSx = {}, headerContainerProps = {}, footerOnlyInExport, exportTitle: _exportTitle, ...restProps } = this.props;
        const { exporting } = this.state;
        const shouldShowFooter = this.props.footer !== undefined && footerOnlyInExport ? exporting : true
        return (
            <Box ref={this.tableContainerRef} sx={{ padding: exporting ? '1rem' : undefined }} >
                {(this.props.title || this.props.showExport) && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '1rem', marginBottom: '1rem', ...headerContainerProps }}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            {this.props.title && typeof this.props.title === 'string' && <Text type='table_header'>{this.props.title}</Text>}
                            {this.props.title && typeof this.props.title !== 'string' && this.props.title}
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            {!exporting && this.props.showExport && <ExportMenu onDownloadImage={this.exportChart} />}
                        </Box>
                    </Box>
                )}
                <Table {...restProps} sx={tableSx}>
                    {!rows.length && noDataMessage && 
                        <TableCell sx={{ textAlign: 'center' }} colSpan={restProps.headerColumns?.length}><Text>{noDataMessage}</Text></TableCell>
                    }
                    {rows.map(rowData => this.renderRow(rowData))}
                </Table>
                <Box sx={{ display: 'flex', ...footerAttributionSx }}>
                    {shouldShowFooter && 
                        <Box sx={{ display: 'flex', flex: 1}}>
                            {this.props.footer}
                        </Box>
                    }
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                        {this.props.attribution}
                    </Box>
                </Box>
            </Box>
        );
    }
}
