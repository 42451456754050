import { v4 as uuid } from 'uuid'
import getUuidByString from 'uuid-by-string'
import StatusNotification  from '../components/AlertContainer/StatusNotification'
import Alert from "../components/common/Alert"
import ErrorAlert from "../components/common/ErrorAlert"
import { StandardAction, StandardThunkAction } from '../store/store'
import { NOTIFICATION_ADDED, NOTIFICATION_UPDATED, NOTIFICATION_REMOVED } from "./types/actions"
import { Notification, NotificationTypeComponents, StatusNotificationItem } from './types/notifications'

export const addNotification = (notification: Notification): StandardThunkAction<Notification> => {
    if(notification.content === undefined) {
        throw new Error('Received notification without content')
    }
    return async(dispatch): Promise<Notification> => {

        const payload: Notification = { ...notification, id: notification.id || uuid(), priority: notification.priority || 0 }

        dispatch({ type: NOTIFICATION_ADDED, payload })

        return payload
    }
}

export const updateNotification = (payload: Notification | StatusNotificationItem): StandardAction => {
    if(!payload.id) {
        throw new Error('updateNotification requires a notification with an existing id')
    }

    return { type: NOTIFICATION_UPDATED, payload }
}

export const addSuccessNotification = ({ title = '', message }: { title?: string, message: string }) => {
    return addNotification({
        type: 'Alert',
        title,
        content: message,
        duration: 5000,
        sx: {
            width: '20rem',
            height: '12.5rem !important',
            color: 'white',
            backgroundColor: '#00a65a',
            borderColor: '#008d4c',
        },
    })
}

export const addError = (title: string, message: string, { duration, contentBasedId }: { duration?: number, contentBasedId?: boolean} = {}): StandardThunkAction<Notification> => {
    if(!message) {
        console.log(new Error('Received notification without content'))
    }
    const id = contentBasedId ? getUuidByString(`${title}${message}`) : undefined
    return addNotification({ id, type: 'Error', title: title || 'Error!', duration, content: message, priority: 10 })
}

export const addStatusNotification = (message: string): StandardThunkAction<Notification> => {
    return addNotification({ type: 'StatusNotification', title: '', content: message, priority: 5 })
}
export const removeNotification = (payload: Notification): StandardAction => {
    return { type: NOTIFICATION_REMOVED, payload }
}


export const NOTIFICATION_TYPE_TO_COMPONENT: { [key: string]: NotificationTypeComponents } = {
    'Alert': Alert,
    'Error': ErrorAlert,
    'StatusNotification': StatusNotification,
}