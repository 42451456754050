import { Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { getCustomAssets } from '../../actions/customAssets';
import { AllocationType, Asset, AssetAllocations } from '../../actions/types/customAssets';
import { Page } from '../Page';
import { BaseProps } from '../types';
import { AssetsPanel } from './AssetsPanel';
import ExtendedCommonTable from '../common/ExtendedCommonTable';
import { TableRowData } from '../../common/types';
import AllocationValue from './AllocationValue';

const ALLOCATION_TYPE_LABELS: { [key in AllocationType]: string } = {
    [AllocationType.FIXED_INCOME_SECTOR_BREAKDOWN]: 'Fixed Income Sector Breakdown',
    [AllocationType.CREDIT_QUALITY_BREAKDOWN]: 'Fixed Income Credit Quality Breakdown',
    [AllocationType.MATURATIY_BREAKDOWN]: 'Fixed Income Maturity Breakdown',
    [AllocationType.EQUITY_SECTOR_BREAKDOWN]: 'Equity Sector Breakdown',
    [AllocationType.EQUITY_REGION_BREAKDOWN]: 'Equity Region Breakdown',
}

interface AllocationTableProps {
    type: AllocationType;
    items: AssetAllocations[];
    onValueUpdated: (item: AssetAllocations, value: number) => void
} 


export const AllocationTable: FunctionComponent<AllocationTableProps> = ({ type, items, onValueUpdated }) => {
    const [rows, setRows] = React.useState<TableRowData[]>([])
    const [weightTotal, setWeightTotal] = React.useState<number>(0)
    React.useEffect(() => {
        const total = items.reduce((acc, item) => acc + (item.weight || 0), 0)
        setWeightTotal(total)
    }, [items])
    React.useEffect(() => {
        const data: TableRowData[] = items.map((item: AssetAllocations) => {
            return {
                key: item.id.toString(),
                title: `${item.name} (%)`,
                values: [
                    <AllocationValue item={item} onValueUpdated={onValueUpdated} />
                ]
            }
        });
        setRows(data)
    }, [items])

    return (
        <ExtendedCommonTable
            containerSx={{ mt: '2rem', mb: '1rem', width: '60rem' }}
            headerColumns={[{
                key: ALLOCATION_TYPE_LABELS[type],
                children: ALLOCATION_TYPE_LABELS[type],
                colSpan: 2,
                sx: { textAlign: 'center' },
            }]}
            rows={rows}
            customRows={[{ key: 'Weight Total', title: 'Weight Total (%)', values: [<Box sx={{ textAlign: 'center'}}>{weightTotal}</Box>], titleSx: { fontWeight: 'bold' } }]}
        />
    )
}

export default AllocationTable