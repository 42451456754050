import { Box } from '@mui/material'
import React, { FunctionComponent } from 'react'
import ReactSelect from 'react-select/async';
import { SelectOption } from '../../../common/types'
import { BaseProps } from '../../types'
import { FONT_STYLES_BY_TYPE } from './Text'

export type OptionsType = SelectOption[] | number[] | string[]
export interface SelectProps extends BaseProps {
    onMenuOpen?: () => void
    onChange: (value: any) => void
    options: OptionsType
    value?: SelectOption | null
    borderless?: boolean
    styles?: any
    placeholder?: string
    isDisabled?: boolean
    maxDefaultShown?: number
    onLoadOptions?: (searchString: string) => Promise<SelectOption[]>
    skinny?: boolean
}
export const Select: FunctionComponent<SelectProps> = ({ sx = {}, isDisabled, styles, placeholder, value, options, onChange, onMenuOpen, borderless = false, maxDefaultShown = 100, onLoadOptions: propsOnLoadOptions, skinny }) => {
    const borderColor = borderless ? () => 'transparent' : (theme) => theme.palette.primary.main
    const hoverBorderColor = borderless ? () => 'transparent' : (theme) => theme.palette.borders.level7
    const [defaultOptions, setDefaultOptions] = React.useState<OptionsType>([])

    React.useEffect(() => {
        if(!options) {
            return
        }

        setDefaultOptions(options.slice(0, maxDefaultShown))
    }, [options])
    const onLoadOptions = propsOnLoadOptions || React.useCallback(async(inputValue: string) => {
        const filteredOptions = options.filter((item: SelectOption) => {
            if(inputValue.length < 2) {
                return false
            } else {
                return item.label?.toString().toLowerCase()?.includes(inputValue.toLowerCase()) || item.value?.toString().toLowerCase()?.includes(inputValue.toLowerCase())
            }
        })

        return filteredOptions;
    }, [options])

    const skinnySx = skinny ? {
        '& .css-tlfecz-indicatorContainer': { 
            padding: 'unset',
            height: '3rem',
            width: '3rem',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& .css-1gtu0rj-indicatorContainer': { 
            padding: 'unset',
            height: '3rem',
            width: '3rem',
        }
    } : { };
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            font: FONT_STYLES_BY_TYPE.text,
            paddingTop: '.25rem',
            paddingBottom: '.25rem',
            border: (theme) => `1px solid ${borderColor(theme)}`,
            '&:hover': {
                border: (theme) => `1px solid ${hoverBorderColor(theme)}`,
                borderRadius: '5px',
            },
            '& .css-tlfecz-indicatorContainer': { 
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& .css-26l3qy-menu, .css-1g9elxf-menu': {
                border: (theme) => `1px solid ${theme.palette.borders.level7}`,
                boxShadow: `0 0 0 1px hsla(0,0%,0%,0.1),0 4px 11px hsla(0,0%,0%,0.1)`,
                textAlign: 'start',
                '&:hover': {
                    borderColor: (theme) => theme.palette.borders.level7,
                },
            },
            '&>div': {
                width: '100%',
                '&>div': {
                    borderColor: '#0000',
                    boxShadow: 'unset',
                    '&:hover': {
                        borderColor: '#0000',
                    },
                    '&>div:nth-of-type(2)': {
                        marginLeft: '.5rem',
                        marginRight: '.5rem',
                        border: (theme) => `2px solid ${theme.palette.borders.level7}`,
                        borderRadius: '5px',
                        '&>span': {
                            display: 'none',
                        },
                    },
                },
            },
            ...skinnySx,
            ...sx
        }}>
            <ReactSelect autoComplete="off" isDisabled={isDisabled} placeholder={placeholder} styles={styles} maxMenuHeight={150} value={value} cacheOptions defaultOptions={defaultOptions} loadOptions={onLoadOptions} onMenuOpen={onMenuOpen} onChange={onChange} />
        </Box>
    )
}