import React, { Component } from 'react'
import { connect } from 'react-redux';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Box, TableCell, TableRow } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AddNewDetailsMappingResponse } from '../../../actions/types/responses';
import { PortfolioMapDetail } from '../../../actions/types/investmentPortfolio';
import { RiskParameters } from '../../../actions/types/riskPreferences';
import { SelectOption } from '../../../common/types';
import { onTableDetailChanged, deleteDetailsMapping, addNewDetailsMapping } from '../../../actions/portfolioMap'
import Button from '../../common/Button';
import TextField from '../../common/InputText';
import { Select } from '../../common/styled/Select';
import { Table } from '../../common/Table';
import LabeledItem from '../../common/LabeledItem';
import Text from '../../common/Text';

const RISK_PREFERENCE_OPTIONS = ['LOW', 'MED', 'HIGH'].map((value) => { return { label: value, value} })

interface Props {
    id: number
    item: PortfolioMapDetail
    editMode?: boolean
    onDelete: (id: number) => void
    addNewDetailsMapping: (detailsId: number, riskParameters: RiskParameters) => Promise<AddNewDetailsMappingResponse>
    deleteDetailsMapping: (id: number, mappingId: number) => void
    onTableDetailChanged: (detail: PortfolioMapDetail) => void
}

interface State {
    showAddRiskParameter: boolean
    selectedGamma: SelectOption | null
    selectedLambda: SelectOption | null
}

class PortfolioMapTableDetails extends Component<Props, State> {

    constructor(props) {
        super(props) 
        
        this.state = {
            showAddRiskParameter: true,
            selectedGamma: null,
            selectedLambda: null,
        }
    }

    resetSelectedRiskParameters = () => {
        this.setState({ selectedGamma: null, selectedLambda: null })
    }

    onConfirmDelete = () => {
        const { item } = this.props
        const { title } = item
        const message = title ? `Are you sure you want to delete '${title}'?` : 'Are you sure you want this item?'
        if(this.props.onDelete && confirm(message)) {
            this.props.onDelete(this.props.id)
        }
    }

    onAddRiskParameter = async() => {
        const { selectedGamma, selectedLambda } = this.state
        if(!selectedGamma || !selectedLambda) {
            alert('Please select a value for Gamma and Lambda')
            return
        }

        const payload = { gamma: selectedGamma.value, lambda: selectedLambda.value }
        const { success, message }: AddNewDetailsMappingResponse = await this.props.addNewDetailsMapping(this.props.id, payload)

        if(!success) {
            alert(message);
        } else {
            this.resetSelectedRiskParameters()
        }
    }

    onConfirmRemoveRiskPreference = (mappingId) => {
        const { id } = this.props
        if(confirm('Are you sure you want this risk preference item?')) {
            this.props.deleteDetailsMapping(id, mappingId) 
        }
    }

    onAmountEquitiesChanged = (value: string) => {
        const { item } = this.props
        
        this.props.onTableDetailChanged({ ...item, amount_equities: parseInt(value) })
    }

    onFixedIncomeChanged = (value: string) => {
        const { item } = this.props
        
        this.props.onTableDetailChanged({ ...item, fixed_income: parseInt(value) })
    }

    onAlternativesChanged = (value: string) => {
        const { item } = this.props
        
        this.props.onTableDetailChanged({ ...item, alternatives: parseInt(value) })
    }

    render() {
        const { id, item, editMode } = this.props
        const { selectedGamma, selectedLambda } = this.state
        const { title = '', fixed_income = '', amount_equities = '', alternatives = '', objective = '', mappings = [] } = item

        const headers = [
            {
                key: 'Gamma',
                children: 'Gamma'
            },
            {
                key: 'Lambda',
                children: 'Lambda'
            },
        ]

        if(editMode) {
            headers.push({ key: '', children: '' })
        }

        return (
            <Box sx={{ display: 'flex', position: 'relative', borderBottomColor: '#5555', borderBottomStyle: 'solid', borderBottomWidth: '.2rem', paddingBottom: '1rem', marginBottom: '1rem', flexDirection: 'row' }}>
                <Box sx={{ position: 'absolute', top: 5, right: 5, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    {editMode && <Button autoFit variant={`danger`} onClick={this.onConfirmDelete}>Delete</Button> }
                </Box>
               <Box sx={{ flex: 1, paddingRight: '1rem' }}>
                    <LabeledItem title={'Title'}>
                    <TextField readOnly={!editMode} value={title} onChange={(e) => this.props.onTableDetailChanged({ ...item, title: e.target.value })} />
                    </LabeledItem>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '.5rem' }}>
                        <LabeledItem title={'Equities'}>
                            <TextField
                                value={amount_equities}
                                onValueChange={this.onAmountEquitiesChanged}
                                type={'number'}
                                max={100}
                                accuracy={3}
                                min={-100}
                                step={.01}
                                decimalPlaces={2}
                                readOnly={!editMode}
                            />
                        </LabeledItem>
                        <LabeledItem title={'Fixed Income'}>
                            <TextField
                                value={fixed_income}
                                onValueChange={this.onFixedIncomeChanged}
                                type={'number'}
                                max={100}
                                accuracy={3}
                                min={-100}
                                step={.01}
                                decimalPlaces={2}
                                readOnly={!editMode}
                            />
                        </LabeledItem>
                        <LabeledItem title={'Alternatives'}>
                            <TextField
                                value={alternatives}
                                onValueChange={this.onAlternativesChanged}
                                type={'number'}
                                max={100}
                                accuracy={3}
                                min={-100}
                                step={.01}
                                decimalPlaces={2}
                                readOnly={!editMode}
                            />
                        </LabeledItem>
                    </Box>
                    <LabeledItem title={'Objective'}>
                        <TextField 
                            sx={{
                                width: '30rem',
                                height: '11rem',
                                alignItems: 'flex-start',
                                '& textarea': {
                                    width: '30rem !important',
                                    height: '8rem !important',
                                },
                            }}
                            multiline
                            readOnly={!editMode}
                            onChange={(event) => this.props.onTableDetailChanged({ ...item, objective: event.target.value })} value={objective}
                        />
                    </LabeledItem>
               </Box>
               <Box sx={{ flex: 1, paddingLeft: '1rem' }}>   
                    <LabeledItem sx={{ marginBottom: '1rem' }} title={'Risk Preferences'}>
                        {editMode &&
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <LabeledItem sx={{ margin: '.5rem', minWidth: '15rem' }} title={'Gamma'}>
                                    <Select sx={{ width: '15rem' }} value={selectedGamma} options={RISK_PREFERENCE_OPTIONS} onChange={(item) => this.setState({ selectedGamma: item})}/>
                                </LabeledItem>
                                <LabeledItem sx={{ margin: '.5rem', minWidth: '15rem' }} title={'Lambda'}>
                                    <Select sx={{ width: '15rem' }} value={selectedLambda} options={RISK_PREFERENCE_OPTIONS} onChange={(item) => this.setState({ selectedLambda: item})}/>
                                </LabeledItem>
                                <Box sx={{ display: 'flex', alignItems: 'flex-end', margin: '.5rem', justifyContent: 'center' }}>
                                    <Button autoFix onClick={this.onAddRiskParameter}>Add Preference</Button>
                                </Box>
                            </Box>
                        }
                        <Table
                            headerColumns={headers}
                        >
                        {mappings.map(({ id, gamma_risk_level, lambda_risk_level }) => {
                            return (
                                <TableRow key={id}>
                                    <TableCell sx={{ textAlign: 'center' }}><Text>{gamma_risk_level}</Text></TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}><Text>{lambda_risk_level}</Text></TableCell>
                                    {editMode && <TableCell sx={{ textAlign: 'center' }}><Box sx={{ cursor: 'pointer' }} onClick={() => this.onConfirmRemoveRiskPreference(id)}><FontAwesomeIcon icon={faTimes}/></Box></TableCell>}
                                </TableRow>
                            )
                        })}
                        </Table>
                    </LabeledItem>
               </Box>
            </Box>
        )
    }

}

const mapStateToProps = ({ portfolioMapTool }, { id, editMode }) => {
    const { details } = portfolioMapTool
    return { item: details[id], editMode }
}

export default connect(mapStateToProps, { onTableDetailChanged, deleteDetailsMapping, addNewDetailsMapping })(PortfolioMapTableDetails)