import React, { Component } from 'react'

import { Box, SxProps, Theme } from '@mui/material'
import { BaseProps } from '../../types'
import { FONT_STYLES_BY_TYPE } from './Text'
import TooltipContents from './TooltipContents'

const ESTIMATED_BOTTOM_MARGIN = 70
const BOTTOM_DISTANCE_THRESHOLD = 250

export interface AbsolutePosition {
    top?: number
    bottom?: number
    left?: number
    right?: number
}

export interface TooltipContainerProps extends BaseProps {
    hoverContents: React.ReactNode
    hoverContentsSx?: SxProps<Theme>
    hoverDisabled?: boolean
}
export interface TooltipContainerState {
    showHoverContents?: boolean
    rect?: DOMRect
    position: AbsolutePosition
}

export class TooltipContainer extends Component<TooltipContainerProps, TooltipContainerState> {
    view?: HTMLElement = undefined

    constructor(props: TooltipContainerProps) {
        super(props)

        this.state = {
            showHoverContents: false,
            position: { }
        }
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onUpdateSize, true)
        window.addEventListener('resize', this.onUpdateSize);
    }
  
    componentWillUnmount() {
        window.removeEventListener('resize', this.onUpdateSize);
        document.removeEventListener('scroll', this.onUpdateSize, true)
    }

    onShowText = () => {
        this.setState({ showHoverContents: true })
        const { rect } = this.state
        const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
        const bottomThreshold = windowHeight - ESTIMATED_BOTTOM_MARGIN
    }

    onHideText = () => {
        this.setState({ showHoverContents: false })
    }

    onUpdateRef = (view: HTMLElement) => {
        if(!view) {
            return
        }
        this.view = view
        this.onUpdateSize()
    }

    onUpdateSize = () => {
        if(!this.view) {
            return
        }
        
        const rect = this.view.getBoundingClientRect()
        this.updatePosition(rect)
    }

    updatePosition = ({ x, y, height, width }: DOMRect) => {
        const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
        const bottomEdge = windowHeight - ESTIMATED_BOTTOM_MARGIN;
    
        let position: AbsolutePosition = {};
    
        const totalHeight = y + BOTTOM_DISTANCE_THRESHOLD;
        if (totalHeight > bottomEdge) {
            position = { bottom: windowHeight - y };
        } else {
            position = { top: y + height };
        }
    
        const totalWidth = x + 330;
        if (totalWidth > windowWidth) {
            position = { ...position, right: windowWidth - (x + width) };
        } else {
            position = { ...position, left: x };
        }

        this.setState({ position })
        
    }

    getTopPlacement = () => {
        
    }

    getBottomPlacement = () => {
        
    }

    getRightPlacement = () => {

    }

    getLeftPlacement = () => {

    }

    render() {
        const { children, hoverContents, sx = {}, hoverContentsSx = {}, hoverDisabled = false } = this.props
        const { showHoverContents, position } = this.state

        return (
            <Box sx={{
                position: 'relative',
                textDecoration: 'none',
                ...sx,
            }}
                className={'tooltip-text'} 
                component={'span'}
                ref={this.onUpdateRef}
                onMouseEnter={this.onShowText}
                onMouseLeave={this.onHideText}>
                {children}
                {!hoverDisabled && showHoverContents &&
                    <TooltipContents position={position} sx={hoverContentsSx}>
                        {hoverContents}
                    </TooltipContents>
                }
            </Box>
        )
    }
}

export default TooltipContainer