import React, { FunctionComponent } from 'react';

import { RequireAuth } from './RequireAuth';
import { Membership } from '../actions/types/users';

export const RequireAuthWrapper: FunctionComponent<{ component: any, requiresMembership?: Membership, requiresMemberships?: Membership[], componentProps?: any, requiresScope?: string }> = ({ component: Component, requiresMembership, requiresMemberships, requiresScope, componentProps = {} }) => {
  
    return (
      <RequireAuth requiresMembership={requiresMembership} requiresMemberships={requiresMemberships} requiresScope={requiresScope}>
        <Component {...componentProps} />
      </RequireAuth>
    )
  }