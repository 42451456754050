import moment, { unitOfTime } from "moment";
import { SelectOption } from "../../common/types";

export interface TimeRange {
    duration: number;
    unit: unitOfTime.DurationConstructor | 'ytd' | 'max' | 'q1' | 'q2' | 'q3' | 'q4';
    label: string;
}

export const TIME_RANGES: TimeRange[] = [
    { duration: 1, unit: 'd', label: '1D' },
    { duration: 5, unit: 'd', label: '5D' },
    { duration: 1, unit: 'M', label: '1M' },
    { duration: 3, unit: 'M', label: '3M' },
    { duration: 6, unit: 'M', label: '6M' },
    { duration: 1, unit: 'ytd', label: 'YTD' },
    { duration: 1, unit: 'y', label: '1Y' },
    { duration: 3, unit: 'y', label: '3Y' },
    { duration: 5, unit: 'y', label: '5Y' },
    { duration: 10, unit: 'y', label: '10Y' },
    { duration: 1, unit: 'max', label: 'MAX' },
]

export const QUARTERLY_TIME_RANGES: (TimeRange | any)[] = [
    { duration: 1, unit: 'q1', label: `Q1 ${new Date().getFullYear()}` },
    { duration: 1, unit: 'q2', label: `Q2 ${new Date().getFullYear()}` },
    { duration: 1, unit: 'q3', label: `Q3 ${new Date().getFullYear()}` },
    { duration: 1, unit: 'q4', label: `Q4 ${new Date().getFullYear()}` },
    { duration: 1, unit: 'ytd', label: 'YTD' },
].filter((range: TimeRange | any) => {
    if (range.unit.includes('q')) {
        const quarter = parseInt(range.unit.replace('q', ''));
        const currentQuarter = moment().quarter();
        return quarter <= currentQuarter;
    }

    return true;
})

export const QUARTERLY_TIME_RANGES_OPTIONS: SelectOption<TimeRange>[] = QUARTERLY_TIME_RANGES.map((range: TimeRange) => ({
    label: range.label,
    value: range.unit,
    item: range,
}));

export interface TimeRangeDates {
    start_date: Date;
    end_date: Date;
}

export const getTimeRangeDates = ({ duration, unit }: TimeRange, max?: Date): TimeRangeDates => {
    const end_date = max ? moment(max) : moment();
    const start_date = moment(end_date.toDate());

    if (unit === 'ytd') {
        return {
            start_date: start_date.startOf('year').toDate(),
            end_date: end_date.toDate(),
        }
    }

    if (unit === 'max') {
        return {
            start_date: moment('2008-01-01').toDate(),
            end_date: end_date.toDate(),
        }
    }

    if (unit.includes('q')) {
        const quarter = parseInt(unit.replace('q', ''));
        start_date.quarter(quarter);
        start_date.startOf('quarter');
        return {
            start_date: start_date.toDate(),
            end_date: end_date.toDate(),
        }
    }

    return {
        start_date: start_date.subtract(duration, unit as unitOfTime.DurationConstructor).toDate(),
        end_date: end_date.toDate(),
    }
}