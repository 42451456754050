import React from 'react';
import moment from 'moment';

abstract class PollingComponent<P, S> extends React.Component<P, S> {
    private initialTimeoutId?: NodeJS.Timeout;
    private intervalId?: NodeJS.Timeout;

    abstract poll: () => Promise<void>;
  
    getNextPollingTime = (): number => {
        const now = moment();
        const minutes = now.minutes();
      
        let nextPollMoment;
      
        if (minutes < 1) {
          nextPollMoment = now.clone().minute(1).startOf('minute');
        } else if (minutes < 16) {
          nextPollMoment = now.clone().minute(16).startOf('minute');
        } else if (minutes < 31) {
          nextPollMoment = now.clone().minute(31).startOf('minute');
        } else if (minutes < 46) {
          nextPollMoment = now.clone().minute(46).startOf('minute');
        } else {
          nextPollMoment = now.clone().add(1, 'hour').minute(1).startOf('minute');
        }
      
        return nextPollMoment.diff(now, 'milliseconds');
    };

    componentDidMount() {
        this.poll();
        const initialDelay = this.getNextPollingTime();
    
        this.initialTimeoutId = setTimeout(() => {
          this.poll();
    
          this.intervalId = setInterval(this.poll, 15 * 60 * 1000);
        }, initialDelay);
      }
    
      componentWillUnmount() {
        // Clear the initial timeout and interval on component unmount
        if (this.initialTimeoutId) {
          clearTimeout(this.initialTimeoutId);
        }
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }
      }
}



export default PollingComponent