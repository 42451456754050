import * as React from 'react';
import { Styles, withStyles } from '@mui/styles';
import { styles as landingStyles } from '../Home/Home'
import FeatureCard from './FeatureCard';
import { useTheme } from '@emotion/react';
import { Link, useMediaQuery } from '@mui/material';
import CopyImage from './CopyImage';

const styles: Styles<any, any> = (theme) => {
    return {
        leftCard: {
            ...landingStyles(theme).leftCard,
            height: '100%',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
                alignItems: 'center',
            },
        },
        rightCard: {
            ...landingStyles(theme).rightCard,
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        copyHeader: {
            ...landingStyles(theme).copyHeader,
            marginTop: 0,
        },
        copy: landingStyles(theme).copy,
    }
}

interface CopyCardProps {
    classes: { [key: string]: any }
    title: string,
    titleLink?: string,
    copy: string,
    imageSource: string,
    imageWebp?: string,
    imageAlt: string,
    copyPosition: 'left' | 'right'
}

export const CopyCard = (props: CopyCardProps) => {
    const { classes, title, titleLink, copy, imageSource, imageWebp, imageAlt, copyPosition  } = props
    const theme = useTheme();
    const forceLeftCopy = useMediaQuery(theme.breakpoints.down('md'));

    const position = forceLeftCopy ? 'left' : copyPosition
    const copyElement = (
                <div>
                    <Link href={titleLink} className={classes.copyHeader}><h1 className={classes.copyHeader}>{title}</h1></Link>
                    <div className={classes.copy}>{copy}</div>
                </div>
    )
    const imageElement = (
        <picture>
            <source type="image/webp" srcSet={imageWebp}></source>
            <CopyImage src={imageSource} alt={imageAlt} />
        </picture>
    )

    return (
        <FeatureCard
            sx={{
                paddingTop: '5rem',
                paddingLeft: '2rem',
                paddingRight: '2rem',
                borderBottom: `#FFF solid 2px`,
                [theme.breakpoints.up('md')]: {
                    flexDirection: 'row',
                    paddingBottom: '4rem',
                },
            }}
        >
            <div className={classes.leftCard}>
                { position === 'left' && copyElement }
                { position === 'right' && imageElement }
            </div>
            <div className={classes.rightCard}>
                { position === 'right' && copyElement }
                { position === 'left' && imageElement }
            </div>
        </FeatureCard>
    )
}

export default withStyles(styles)(CopyCard)