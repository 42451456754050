import React, {FC} from 'react';
import Box from '@mui/material/Box';
import { ListItem } from '@mui/material';
import Text from '../../common/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faTimes } from '@fortawesome/free-solid-svg-icons';

export enum RowType {
  CHECKBOX = 'checkbox',
  RADIO_BUTTON = 'radio_button',
}

interface Props {
    item: string;
    type?: RowType;
    label?: string;
    subtitle?: string;
    checked?: boolean;
    showEdit?: boolean;
    showRemove?: boolean
    showChecked?: boolean;
    disabled?: boolean;
    onEdit?: (item: string) => void;
    onRemove?: (item: string) => void;
    onCheckChanged?: (item: string, checked: boolean) => void;
}

const ListRow: FC<Props> = ({label, subtitle, item, type, checked, showChecked, onCheckChanged: propsOnCheckChanged, onRemove: propsOnRemove, onEdit: propsOnEdit, showEdit, showRemove, disabled}) => {

  const onRemove = React.useCallback(() => {
    if (propsOnRemove) {
      propsOnRemove(item);
    }
  }, [item, propsOnRemove]);

  const onEdit = React.useCallback(() => {
    if (propsOnEdit) {
      propsOnEdit(item);
    }
  }, [item, propsOnEdit]);

  const onCheckChanged = React.useCallback(() => {
    if (propsOnCheckChanged) {
      propsOnCheckChanged(item, !checked);
    }
  }, [checked, item, propsOnCheckChanged]);

  return (
    <ListItem 
        disabled={disabled}
        sx={{ 
                display: 'flex',
                position: 'relative',
                border: 'unset',
                padding: '1rem',
                alignItems: 'flex-start',
                justifyItems: 'center',
                pointerEvents: disabled ? 'none' : undefined,
                '& input': {
                  cursor: 'pointer',
                },
                width: 'unset',
            }}>
              {showChecked && type === RowType.RADIO_BUTTON && 
                <input type='radio' checked={checked} onChange={onCheckChanged} ></input>
              }
              {showChecked && type === RowType.CHECKBOX && 
                <input type='checkbox' checked={checked} onChange={onCheckChanged} ></input>
              }
        <Box
            sx={{
            display: 'flex',
            flex: 1,
            width: '100%',
            flexDirection: 'row',
            marginRight: '1rem',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: '1rem'
        }}>
            <Text>
                <Box sx={{ cursor: 'pointer',  overflow:'hidden', textOverflow:'ellipsis' }} onClick={onCheckChanged}>{label ?? item}</Box>
                {subtitle && <Box sx={{ cursor: 'pointer' }} onClick={onCheckChanged}>{subtitle}</Box>}
              </Text>
              <Box sx={{ display: 'flex', width: '2.5rem', alignItems: 'center', justifyContent: 'center' }}>
                {showEdit &&
                  <Box sx={{ 
                    ml: '.5rem',
                    pointerEvents: disabled ? 'none' : undefined,
                    cursor: 'pointer',
                  }}>
                    <FontAwesomeIcon size={'2x'} icon={faGear} onClick={onEdit}/>
                  </Box>
                }
                {showRemove &&
                  <Box sx={{ 
                    marginLeft: '1rem',
                    pointerEvents: disabled ? 'none' : undefined,
                    cursor: 'pointer',
                  }}>
                    <FontAwesomeIcon size={'2x'} icon={faTimes} onClick={onRemove}/>
                  </Box>
                }
              </Box>
        </Box>
    </ListItem>
  );
}

export default ListRow;