import axios from 'axios'
import { 
    USERS_RECEIVED,
    CLIENTS_RETRIEVED,
    CLIENT_DATA_RETRIEVED,
    CLIENT_LOGS_ERROR,
} from './types/actions'
import { addError } from './notifications'
import { StandardThunkAction } from '../store/store'
import { GetClientDetailsRequest, GetUsersRequest } from './types/requests'
import { EditUser, MetricUser, User } from './types/users'
import { isApiError } from './utils'
import { StandardApiResponse, UserSubscriptionResponse } from './types/responses'
import { ClientSkinny } from './types/adminUserUtils'

export const getUsers = (params?: GetUsersRequest): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const { data } = await axios.get(`/api/admin/users`,{ params })
            const { users } = data
            dispatch({ type: USERS_RECEIVED, payload: users })
        } catch(e: any) {
        }
    }
}

export const getMetricUsers = async(): Promise<MetricUser[]> => {
    try {
        const { data } = await axios.get(`/api/admin/metrics/users`)
        const { users } = data

        return users
    } catch(e: any) {
    }

    return []
}

export const getUserSubscription = async(id: number): Promise<UserSubscriptionResponse> => {
    try {
        const { data } = await axios.get(`/api/admin/users/${id}/subscription`)
        const { success, subscription_summary } = data

        return { success, subscription_summary }
    } catch(e: any) {
    }

    return { success: false }
}

export const addUser = ({ firstName, lastName, email, phone, membership, role_id, domain, password, activation_code, domicile }: EditUser): StandardThunkAction<StandardApiResponse> => {
    return async(dispatch, getState): Promise<StandardApiResponse> => {
        try {
            let payload: EditUser = { firstName, lastName, email, phone, membership, role_id, domain, activation_code, domicile }

            if(password) {
                payload = { ...payload, password }
            }
            const { data } = await axios.post(`/api/admin/users`, payload)

            const { userManagment } = getState()
            const { users } = userManagment

            const { user } = data

            dispatch({ type: USERS_RECEIVED, payload: [user, ...users] })

            return { success: true }
        } catch(error: any) {
            if(isApiError(error)) {
                const { errors: apiErrors } = error.response?.data || { }
                const errors = Object.keys(apiErrors).reduce((accum: string[], key: string) => {
                    const item = apiErrors[key] || []
    
                    return [...accum, ...item]
                }, [])

                return { success: false, errors }
            }
            return { success: false, errors: [`${error.toString()}`] }
        }        
    }
}

export const updateUser = ({ id, firstName, lastName, email, phone, membership, role_id, domain, external_subscription_id, activation_code, domicile }: EditUser): StandardThunkAction<StandardApiResponse> => {
    return async(dispatch, getState): Promise<StandardApiResponse> => {
        try {
            let payload: EditUser = { firstName, lastName, email, phone, membership, role_id, domain, activation_code, domicile }
            if(external_subscription_id) {
                payload = { ...payload, external_subscription_id }
            }

            const { data } = await axios.put(`/api/admin/users/${id}`, payload)
            const { user } = data

            const { userManagment } = getState()
            const { users } = userManagment

            const updatedUsers = users.map((item: User) => {
                if(item.id === user.id) {
                    return { ...item, ...user }
                }

                return item
            })

            dispatch({ type: USERS_RECEIVED, payload: updatedUsers })
            return { success: true }
        } catch(error: any) {

            if(!isApiError(error)) {
                dispatch(addError('Update User Error', error.toString()))

                return { success: false, errors: [`${error.toString()}`] }
            }

            const { errors: apiErrors, message } = error.response?.data || { }
            if(apiErrors) {
                const errors = Object.keys(apiErrors).reduce((accum: string[], key: string) => {
                    const item = apiErrors[key] || []
    
                    return [...accum, ...item]
                }, [])
    
                dispatch(addError('Update User Error', errors.join('\r\n')))
                return { success: false, errors }
            }

            if(message) {
                dispatch(addError('Update User Error', message))

                return { success: false, errors: [message] }
            }
        }

        return { success: false }
    }
}

export const deleteUser = ({ id }: User): StandardThunkAction<StandardApiResponse> => {
    return async(dispatch, getState): Promise<StandardApiResponse> => {
        try {
            await axios.delete(`/api/admin/users/${id}`)
            const { userManagment } = getState()
            const { users } = userManagment

            const updatedUsers = users.filter((user: User): boolean => user.id !== id)

            dispatch({ type: USERS_RECEIVED, payload: updatedUsers })
            return { success: true }
        } catch(error: any) {

            if(!isApiError(error)) {
                dispatch(addError('Update User Error', error.toString()))

                return { success: false, errors: [`${error.toString()}`] }
            }

            const { errors: apiErrors, message } = error.response?.data || { }
            if(apiErrors) {
                const errors = Object.keys(apiErrors).reduce((accum: string[], key: string) => {
                    const item = apiErrors[key] || []
    
                    return [...accum, ...item]
                }, [])
    
                dispatch(addError('Update User Error', errors.join('\r\n')))
                return { success: false, errors }
            }

            if(message) {
                dispatch(addError('Update User Error', message))

                return { success: false, errors: [message] }
            }
        }

        return { success: false }
    }
}

export const loginAsUser = (userId: number): StandardThunkAction => {
    return async(dispatch): Promise<any> => {
        try {
            const { data } = await axios.post(`/api/admin/users/${userId}/loginAs`)
            const { success } = data
            if(!success) {
                dispatch(addError('Unknown Error', 'Unable to perform action' ))
                
                return
            }

            return data;
        } catch(e: any) {
            dispatch(addError('Unknown Error', e.toString() ))
            return { success: false };
        }
    }
}

export const getAdvisorClients = (userId: number): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const { data } = await axios.get(`/api/admin/users/${userId}/clients`)
            const { success, message, clients } = data
            if(!success) {
                dispatch(addError('Unknown Error', message ))
                
                return
            }
            const mappedClients = clients.map((client: ClientSkinny) => {
                return { ...client, key: client.id, value: client.name }
            })
            
            dispatch({ type: CLIENTS_RETRIEVED, payload: mappedClients })
        } catch(e: any) {
            dispatch(addError('Unknown Error', e.toString() ))
        }
    }
}

export const getClientDetails = ({ userId, clientId }: GetClientDetailsRequest): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const { data } = await axios.get(`/api/admin/users/${userId}/client/${clientId}/data`)
            const { success, message, logs, files } = data
            if(!success) {
                dispatch({ type: CLIENT_LOGS_ERROR, payload: message })

                return
            }
            dispatch({ type: CLIENT_DATA_RETRIEVED, payload: { clientLogs: logs, clientFiles: files } })
        } catch(e: any) {
            dispatch({ type: CLIENT_LOGS_ERROR, payload: e.toString() })
        }
    }
}
