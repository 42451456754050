import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { TokenType } from '../actions/types/authentication';
import { validateToken } from '../actions/authentication';

export const RequiresValidToken: FunctionComponent<{ children: React.ReactNode, type: TokenType }> = ({ children, type }) => {
    const navigate = useNavigate()
    const [token, setToken] = React.useState<string | undefined>()
    const [show, setShow] = React.useState<boolean>(false)
    React.useEffect(() => {
        setToken(new URLSearchParams(window.location.search).get('token') || '')
    },[])

    React.useEffect(() => {
        if(token === undefined) {
            return
        }

        if(!token) {
            navigate("/");
    
            return
        }

        const validate = async() => {
          const { valid } = await validateToken(type, token)
          if(!valid) {
            navigate("/");
          } else {
            setShow(true)
          }
        }
        validate();
    },[token, navigate, type])

    if(!show) {
        return null;
    }
  
    return <>{children}</>
  }