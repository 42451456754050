import { Box } from '@mui/material'
import React, { Component } from 'react'
import Button from '../common/Button'

import ClientDetails from '../common/ClientDetails'
import Text from '../common/styled/Text'
import { PageContents } from '../Page/PageContents'
import { Client } from '../../actions/types/clientManagement'

export interface EnterClientDetailProps {
    onDetailsCompleted: (client: Client) => void
}

export default class extends Component <EnterClientDetailProps> {
    clientDetailsRef: any

    constructor(props) {
        super(props)

        this.clientDetailsRef
    }

    onNext = () => {
        if(!this.clientDetailsRef) {
            return
        }

        this.clientDetailsRef.confirm()
    }

    onConfirmed = (details: Client) => {
        if(this.props.onDetailsCompleted) {
            this.props.onDetailsCompleted(details)
        }
    }

    render() {

        return (
            <PageContents sx={{ height: '50rem', overflow: 'hidden', position: 'relative' }}>
                <Box>
                    <Box sx={{ maxWidth: '75rem' }}>
                        <Box><Text>Welcome to Portfolio Designer's model portfolio selector for Newfound's Structural Alpha portfolios.</Text></Box>
                        <Box><Text> This tool will guide the user through three risk questionnaires that diagnose an investor's risk aversion and loss aversion. These two dimensions of risk preferences will then be aggregated to help select the appropriate Newfound Structural Alpha portfolio.</Text></Box>
                        <Box><Text>To begin the model portfolio selection process, please enter your annual income to set the risk questionnaire parameters.</Text></Box>
                    </Box>
                    <ClientDetails ref={(view) => {
                        if(view) {
                            this.clientDetailsRef = view
                        }
                    }} fields={['income']} onConfirmed={this.onConfirmed} />
                    <Button onClick={this.onNext}>Next</Button>
                </Box>
            </PageContents>
        )
    }
}