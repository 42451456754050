import React, { FunctionComponent, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useAuth } from './hooks/useAuth';
import { Membership } from '../actions/types/users';

export const RequireAuth: FunctionComponent<{ children: React.ReactNode, requiresMembership?: Membership, requiresMemberships?: Membership[], requiresScope?: string }> = ({ children, requiresMembership, requiresMemberships, requiresScope }) => {
    const { loading, authenticated, user, details } = useAuth();
    const navigate = useNavigate()

    useEffect(() => {
        if (loading) {
            return
        }

        if (!authenticated) {
            navigate("/login");
        }
    }, [loading, authenticated])
    

    useEffect(() => {
        if(loading || !authenticated) {
            return;
        }
        if(requiresScope && !details?.scopes?.includes(requiresScope)) {
            navigate(details?.routes[0].path || "/");
        }
        if(requiresMembership && user?.membership !== requiresMembership) {
            navigate(details?.routes[0].path || "/");
        }
        if(requiresMemberships && !requiresMemberships.includes(user?.membership as any)) {
            navigate(details?.routes[0].path || "/");
        }
    }, [loading, authenticated, user, details, requiresMembership])

    if(loading) {
        return null;
    }

    if (!authenticated) {
        return null;
    }
  
    return <>{children}</>
  }