import React, { Component } from 'react'
import { AssetSet } from '../../../actions/types/customAssets'
import ConfirmModal from '../../common/ConfirmModal'
import Text from '../../common/Text'

interface Props {
    visible: boolean
    item: AssetSet
    onConfirm: () => void
    onCancel: () => void
}

export default class extends Component<Props> {

    render() {
        const { visible, item } = this.props
        const { name } = item || { }

        return (
            <ConfirmModal
                visible={visible}
                title={`${name}`}
                confirmLabel={'Delete'}
                cancelLabel={'Cancel'}
                onConfirm={this.props.onConfirm}
                onCancel={this.props.onCancel}>
                <Text>Are you sure you want to delete '{name}'?</Text>
            </ConfirmModal>
        )
    }
}