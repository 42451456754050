import { SelectOption } from "src/common/types"
import { Route } from "../../components/NavBar/types"
import { Advisor, Firm } from "./account"

export const ROLE_MAPPING = {
    1: "Admin",
    2: "Developer",
    3: "Beta Tester",
    4: "Subscriber",
    5: "Enterprise Subscriber",
    6: "Enterprise Beta",
    7: "Token Rep",
}

export enum Membership {
    PORTFOLIO_DESIGNER = 'portfolio_designer',
    ADVISOR_WORKSTATION = 'advisor_workstation',
    RISK_ANALYZER_LEGACY = 'risk_analyzer',
    SIMPLIFY_INTERNAL = 'simplify_internal',
    CORE_API = 'core_api'

}

export const MEMBERSHIP_MAPPING: { [key: string]: string } = {
    [Membership.PORTFOLIO_DESIGNER]: 'Model Portfolio Designer',
    [Membership.ADVISOR_WORKSTATION]: 'Client Portfolio Designer',
    [Membership.RISK_ANALYZER_LEGACY]: 'Risk Analyzer (Legacy)',
    [Membership.SIMPLIFY_INTERNAL]: 'Simplify Internal',
    [Membership.CORE_API]: 'Core API User',
}

export const MEMBERSHIP_OPTION = 1
export const ACTIVATION_CODE_OPTION = 2

export const MEMBERSHIP_OPTIONS: SelectOption[] = Object.keys(MEMBERSHIP_MAPPING).map((membership) => {
    return { label: MEMBERSHIP_MAPPING[membership], value: membership, type: MEMBERSHIP_OPTION }
})


export interface ActivationCode {
    id?: number
    active: number | boolean
    code: string
    domain: string
    domain_unlocked: number | boolean
    enterprise_logo: string
    enterprise_logo_url: string
    membership: Membership
    assigned_user?: User
    assigned_user_id?: number | null
    updated_at?: string
    created_at?: string
}
export const NEW_ACTIVATION_CODE: ActivationCode = {
    active: true,
    code: '',
    domain: '',
    domain_unlocked: false,
    enterprise_logo: '',
    membership: Membership.ADVISOR_WORKSTATION,
}

export interface Subscription {
    id: number
    user_id: number
    active: number | boolean
    callback_consumed: number | boolean
    customer_id: string
    external_subscription_id: string
    plan_id: string
    service: string
    session_id: string
    subscribed_on: string
    unsubscribed_on: string
    created_at: string
    updated_at: string
}
export interface SubscriptionSummary {
    cancel_at_period_end: boolean
    current_period_end: boolean
    external_subscription_id: string
    plan: string
    start_date: string

}

export enum UserDomicile {
    US = 'US',
    CA = 'CA',
    GB = 'GB',
}
export interface BaseUser {
    id?: number
    role_id: number
    membership: Membership
    firstName: string
    lastName: string
    phone: string
    domain: string
    email: string
    domicile: UserDomicile
}
export interface EditUser extends BaseUser {
    external_subscription_id?: string
    password?: string
    activation_code?: string
}
export interface User extends BaseUser {
    image: string | null
    isConfirmed: number
    is_first_login: number
    key: string
    session_id: string | null
    status: number
    is_active: boolean
    is_unsubscribing: boolean
    unsubscribed_on?: string
    activation_code?: ActivationCode
    deleted: number
    firm_id: number
    last_activity: string
    created_at: string
    subscription?: Subscription
}
export interface MetricUser extends User {
    configurationCount?: number
}

export interface AuthenticatedUser {
    id: number
    firstName: string
    lastName: string
    membership: Membership
    enterprise: boolean
}
export interface UserDetails {
    logo: string
    logoInHeader: string[]
    headerLogoOnly: boolean
    routes: Route[]
    advisor?: Advisor
    firm?: Firm
    views?: string[]
    defaultKey?: string
    selectedKey?: string
    config: string[]
    scopes: string[]
}
export const NEW_USER: EditUser = {
    firstName: '',
    lastName: '',
    email: '',
    domain: '',
    phone: '',
    role_id: 0,
    membership: Membership.PORTFOLIO_DESIGNER,
    password: '',
    domicile: UserDomicile.US,
}

export const DOMICILE_ITEMS = [{ label: 'United States', value: 'US' },{ label: 'US & Canada', value: 'CA' }]

export interface RefreshResponse {
    success: boolean;
    details?: UserDetails;  
    user?: AuthenticatedUser;
    errors?: string[];
};
