import React, { Component } from 'react'
import { connect } from 'react-redux'

import { 
    investmentPortfolioTodayUpdate,
    currentAnnualSavingsUpdate,
    plannedAnnualSavingsUpdate,
} from '../../actions/standardLivingRisk'

import { LineItem } from './LineItem'
import Card, { SLRBaseProps } from './Card'
import IrregularTable from './IrregularTable'
import { onComponentSizeType } from '../common/styled/SizeAwareBox'
import { Item } from './IrregularItem'

interface AssetsProps extends SLRBaseProps {
    investmentPortfolioToday: number
    currentAnnualSavings: number
    plannedAnnualSavings: number
    irregularIncome: Item[]
    startYears: number[]
    numberOfYears: number[]
    investmentPortfolioTodayUpdate: (value: number | string) => void
    currentAnnualSavingsUpdate: (value: number | string) => void
    plannedAnnualSavingsUpdate: (value: number | string) => void
    onAssetSize: onComponentSizeType
}

class Assets extends Component <AssetsProps>{
    constructor(props) {
        super(props)
    }

    render() {
        const { investmentPortfolioToday, currentAnnualSavings, plannedAnnualSavings, irregularIncome, startYears, numberOfYears, onComponentSize, onAssetSize } = this.props
        
        return (
            <Card title={'Assets'} onComponentSize={onComponentSize}>
                <LineItem number={1} label={'Investment Portfolio Today'} value={investmentPortfolioToday} onLeave={this.props.investmentPortfolioTodayUpdate} extendedInput />
                <LineItem number={2} label={'Annual Savings Before Retirement'} value={currentAnnualSavings} onLeave={this.props.currentAnnualSavingsUpdate} extendedInput />
                <LineItem number={3} label={'Planned Annual Savings Increase'} value={plannedAnnualSavings} onLeave={this.props.plannedAnnualSavingsUpdate} isPercentage />
                
                <IrregularTable onComponentSize={onAssetSize} title={'Additional Annual Savings'} itemLabel={'Savings'} type={'income'} items={irregularIncome}  startYears={startYears} numberOfYears={numberOfYears} />
            </Card>
        )
    }

}

const mapStateToProps = ({ standardLivingRisk }) => {
    const { investment_portfolio_today: investmentPortfolioToday, current_annual_savings: currentAnnualSavings, planned_annual_savings_increase: plannedAnnualSavings, income: irregularIncome, startYears, numberOfYears } = standardLivingRisk

    return {
        investmentPortfolioToday,
        currentAnnualSavings,
        plannedAnnualSavings,
        irregularIncome,
        startYears,
        numberOfYears,
    }
}

export default connect(mapStateToProps, { investmentPortfolioTodayUpdate, currentAnnualSavingsUpdate, plannedAnnualSavingsUpdate })(Assets)