import React, { FunctionComponent } from 'react'
import { Box } from '@mui/material'
import { BaseProps } from '../types'
import Text from './styled/Text'


export interface LabeledItemProps extends BaseProps {
    title: string | React.ReactNode
    inline?: boolean
}

export const LabeledItem: FunctionComponent<LabeledItemProps> = ({ title, children, inline = false, sx }) => {
    return (
        <Box sx={sx}>
            <Text>{title}</Text>
            {inline && children }
            {!inline && <Box>{children}</Box>}
        </Box>
    )
}

export default LabeledItem