import React, { FunctionComponent, useCallback, useState } from 'react'
import { ButtonProps } from '../../common/BaseThemedButton'
import { ScheduleMeetingModal } from '../ScheduleMeetingModal'
import LandingButton from './LandingButton'

export const ScheduleMeetingButton: FunctionComponent<ButtonProps> = ({ title = 'Schedule a Demo', ...props }) => {
    const [showMeetingModal, setShowMeetingModal] = useState<boolean>(false)
    const onCloseModal = useCallback(() => {
        setShowMeetingModal(false)
    }, [])
    const onShowModal = useCallback(() => {
        setShowMeetingModal(true)
    }, [])

    return (
        <>
            <ScheduleMeetingModal visible={showMeetingModal} onClose={onCloseModal} />
            <LandingButton {...props} title={title} onClick={onShowModal} />
        </>
    )
}