import { Box } from '@mui/material'
import ClipLoader from "react-spinners/ClipLoader";
import React, { FunctionComponent } from 'react'
import Text from '../common/styled/Text';
import { BaseProps } from '../types';

export const ScriptRunning: FunctionComponent<BaseProps> = ({ sx = { } }) => {
    return (
        <Box sx={{
            display: 'flex',
            flex: 1,
            zIndex: 50,
            backgroundColor: '#FFF',
            alignItems: 'center',
            justifyContent: 'center',
            ...sx
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Box sx={{ marginBottom: '1rem' }}><Text>Robots Optimizing Portfolio...</Text></Box>
                <ClipLoader color={'#000'} loading={true} size={35} />
            </Box>
        </Box>
    )
}