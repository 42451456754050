import * as React from 'react';
import Page from '../common/Page';
import { Box, Typography } from '@mui/material';
import { BaseProps } from '../../types';
import Bio from './Bio';

class About extends React.Component <BaseProps> {

    render() {
        return (
            <Page>
                <Box sx={{
                    display: 'flex',
                    flex: 1,
                    padding: '1rem',
                    position: 'relative',
                    background: `url(${require('../assets/about.png')}) no-repeat center center / cover transparent;`,
                }}>
                    <Box sx={{ 
                        backgroundImage: "linear-gradient(90deg,#151343,transparent)",
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        top: 0,
                        left: 0,
                        opacity: 1,
                        zIndex: 1,
                     }} />
                    <Box sx={{ padding: '5rem', zIndex: 2, maxWidth: '55rem' }}>
                        <Typography sx={{
                                zIndex: 2,
                                color: 'white',
                                marginBottom: 5,
                                font: (theme) => `normal normal bold ${theme.typography.pxToRem(88)}/${theme.typography.pxToRem(80)} Montserrat`
                            }}>Our<br />Mission</Typography>
                        <Typography sx={{
                                zIndex: 2,
                                color: 'white',
                                font: (theme) => `normal normal 300 ${theme.typography.pxToRem(28)}/${theme.typography.pxToRem(38)} Montserrat`,
                            }}>Portfolio Designer was founded to help advisors easily manage a large number of highly personalized client portfolios that are all optimized for performance.  By enabling advisors to build their own model portfolios that maximize risk-adjusted returns, and then leveraging those efficient portfolios in a sophisticated goals-based asset-liability matching framework, advisors can quickly create hyper-personalized portfolios across their client base that are all focused on optimizing risk-adjusted performance.</Typography>
                    </Box>
                </Box>
                <Box sx={{ 
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            md: 'row'
                        },
                        padding: '5rem'
                    }}>
                    <Bio
                        sx={{ flex: 1, margin: { md: '1rem' }  }}
                        avatarSource={require('../assets/david_image.png')}
                        name={'David M. Berns, PhD'}>
                            <>
                                David is the Chief Investment Officer and cofounder of Simplify ETFs where he leads the development of novel investment strategies that help advisors produce better outcomes for their clients. David began his finance career at a $5 billion multi-family office where he developed cutting-edge asset allocation, portfolio management, and risk management systems for managing private and institutional wealth across both liquid and illiquid asset classes. David then pivoted to developing short- and intermediate-term investment strategies that once layered on top of a client’s long-term strategic asset allocation improve both return and risk metrics. David is the author of “Modern Asset Allocation for Wealth Management”, a modern yet practical lens into the world of asset allocation, which was the genesis of Portfolio Designer.
                                <br /><br />
                                David has a PhD in Physics from the Massachusetts Institute of Technology in the field of Quantum Computation and currently lives in Los Angeles with his wife Carolee and son Henry.
                            </>
                    </Bio>
                    <Bio
                        sx={{ flex: 1, margin: { md: '1rem' }, marginTop: { xs: '5rem' }  }}
                        avatarSource={require('../assets/krisna_image.jpg')}
                        name={'Kris Patel, CFA'}>
                            <>
                            Kris Patel, CFA is founder of The UnBiased Advisor, a financial planning firm located in Broomfield, CO.  After graduating Indiana University, he joined a small advisory firm in Indianapolis, IN.  Understanding that there was more to financial planning than just “insurance” and MFs he decided to start his own firm in 2007.  He earned his CFA charter in 2014 and later took Aswath Damodaran’s Valuation coarse at NYU.  With over two decades in planning experience, he has seen first-hand how investment cycles impact investor behavior.  His desire to learn more about incorporating behavioral biases into financial planning led him to Modern Asset Allocation for Wealth Management and Portfolio Designer, where he now serves as the CEO.   
                            <br /><br />
                            In addition to running the firm he stays active in the local community.  He previously served as board President of the Zionsville Chamber of Commerce, sat on the Executive committee of CFA Society of Indianapolis, and was a board member of the Boone County Community Foundation.
                            <br /><br />
                            Outside of his daily duties Kris loves spending time with his wife and two daughters.  He enjoys participating in physical activities, while also coaching a group fitness class at Impact Sports Performance, having earned his Crossfit L1 and CFSC designations.
                            </>
                    </Bio>
                </Box>
            </Page>
        )
    }
}

export default About