import React, { FunctionComponent, useEffect } from 'react';
import { InputProps, StyledInput } from '../common/StyledInput';
import { AssetAllocations } from '../../actions/types/customAssets';
import { sanitizeNumericInput } from '../../common/utils';
import { TextField } from '@mui/material';
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text';

interface AllocationTableProps {
    item: AssetAllocations
    onValueUpdated: (item: AssetAllocations, value: number) => void
} 


export const AllocationValue: FunctionComponent<AllocationTableProps> = ({ item, onValueUpdated: propsOnValueUpdated }) => {
    const [inputValue, setInputValue] = React.useState<string | number>(item.weight ?? '')

    useEffect(() => {
        setInputValue(item.weight ?? '')
    }, [item])

    const onValueUpdated = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const updatedItem = { ...item, weight: Number(value) }
        
        propsOnValueUpdated(updatedItem, Number(value))

    }, [item, propsOnValueUpdated]);
    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const scrubbed = sanitizeNumericInput(value.toString())
        setInputValue(scrubbed)
    }, [item, propsOnValueUpdated]);
    return (
            <TextField
                fullWidth
                value={inputValue}
                onChange={onChange}
                onBlur={onValueUpdated}
                inputProps={{
                    sx: {
                        font: FONT_STYLES_BY_TYPE.text,
                    }
                }}
            />        
    )
}

export default AllocationValue