import { Box, SxProps, Theme } from '@mui/material'
import React, { FunctionComponent } from 'react'
import { ASSET_CLASS_ORDER, GeneralTableData, ResultsTableData } from '../../actions/types/portfolioDesigner'
import CommonTable from '../common/CommonTable'

export interface GenericResultTableProps {
    results: GeneralTableData[]
    containerSx?: SxProps<Theme>
    tableSx?: SxProps<Theme>
    customRows?: any[]
    excludeKeys?: string[]
    noDataMessage?: string
    boldedKeys?: string[]
    seperateAfterKeys?: string[]
}

export const GenericResultTable: FunctionComponent<GenericResultTableProps> = ({
    containerSx = { },
    tableSx = { },
    customRows,
    results,
    excludeKeys,
    boldedKeys,
    seperateAfterKeys,
    noDataMessage,
}) => {
    const [columns, setColumns] = React.useState<any[]>([])
    const [rows, setRows] = React.useState<any[]>([])

    const getRowsFromResultsItem = React.useCallback((data: any) => {
        const keys = [...new Set(Object.keys(data).map((key: string) => Object.keys((data as any)[key])).flatMap((key) => key))].filter((key) => !excludeKeys?.includes(key));
        const rows = keys.map((key: string) => {
            const shouldBold = boldedKeys?.includes(key);
            const sx = shouldBold ? { fontWeight: 'bold' } : { };

            return { title: key, titleSx: sx, valueSx: sx, key, values: Object.keys(data).map((column) => (data as any)[column][key] === null || (data as any)[column][key] === undefined ? '' : `${(Number((data as any)[column][key]) * 100).toFixed(2)}%`)}
        })

        return rows.reduce((acc: any[], row: any) => {
            const shouldSeperate = seperateAfterKeys?.includes(row.key);
            if (shouldSeperate) {
                return [...acc, row, { seperator: true }]
            }

            return [...acc, row]
        }, [])
    }, [boldedKeys])

    React.useEffect(() => {
        if (!results || results.length === 0) {
            return;
        }

        const { name, data } = results[0]
        const columns = [
            { key: name, children: name, colSpan: 1 },
            ...Object.keys(data).map((key) => ({
                key,
                children: key,
                colSpan: 1,
                sx: {
                    textAlign: 'center'
                }
            }))
        ];
        setColumns(columns);

        const mappedRows = results.map((result: GeneralTableData, index) => getRowsFromResultsItem(result.data)).flatMap((item) => item)
        if (mappedRows[mappedRows.length - 1]?.seperator) {
            mappedRows.pop()
        }
        setRows([...mappedRows, ...(customRows || [])])
    }, [results, customRows])

    return (
            <Box sx={{
                ...containerSx
                }}>
                <CommonTable
                    sx={{
                        ...tableSx,
                        '& td': {
                            padding: 'unset',
                            paddingTop: '.5rem',
                            paddingBottom: '.5rem',
                            paddingLeft: '1.5rem',
                            paddingRight: '1.5rem',
                        },
                        marginTop: {
                            xs: '2rem',
                            md: 'unset', 
                        },
                    }}
                    valueCellProps={{
                        sx: {
                            textAlign: 'center',
                        }
                    }}
                    headerColumns={columns}
                    rows={rows}
                    noDataMessage={noDataMessage}
                />
            </Box>
    )

}