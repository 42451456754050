import React, { FunctionComponent } from "react"

import Panel, { PanelProps } from '../../common/Panel'

export const PortfolioMapPanel: FunctionComponent<PanelProps> = ({ sx = { }, contentsSx = { }, children, ...props }) => {
  return(
      <Panel
            sx={{
                margin: '1rem',
                ...sx,
            }}
            contentsSx={{
                padding: '1rem',
                ...contentsSx,
            }}
            {...props}
      >
        {children}
      </Panel>
  )
}

export default PortfolioMapPanel