import { Box, Typography } from '@mui/material'
import React, { FunctionComponent } from 'react'
import MessageModal from "../../common/MessageModal"
interface ScheduleMeetingModalProps {
    visible: boolean,
    onClose: () => void,
}

export const ScheduleMeetingModal: FunctionComponent<ScheduleMeetingModalProps> = ({ visible, onClose }) => {
    return(
        <MessageModal
            sx={{
                paddingBottom: '10rem',
            }}
            visible={visible}
            hideHeader
            onCancel={onClose}
            buttonLabel={'Close'}
            onButtonPress={onClose}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', '& iframe': { width: 450, height: 625, border: 0 }}}>
                    <iframe 
                        title={'hubspot'}
                        src="https://meetings.hubspot.com/kris-patel1?embed=true"></iframe>
                </Box>
        </MessageModal>
    )
}

