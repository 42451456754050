import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from '../common/Button'
import { Client, ClientUpdateRequest, PORTFOLIO_MANAGER_NONE_OPTIONS, PORTFOLIO_MANAGER_TYPE_OPTIONS, PortfolioManagerType } from '../../actions/types/clientManagement'
import { getAdvisorClients, deleteClient, updateClient, addClient, getClientQuestionnaireLink, getClientFinancialPlanLink, emailClientLink, clearEmailSuccess } from '../../actions/clientManagement'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy,  faEnvelope, faEdit, faTrash, faPen } from '@fortawesome/free-solid-svg-icons'

import ConfirmDelete from '../common/ConfirmDelete'
import MessageModal from '../common/MessageModal'
import ClientModal from './ClientModal'
import ConfirmModal from '../common/ConfirmModal';
import { addError } from '../../actions/notifications';
import TooltipWrapper, { TooltipWrapperCollection } from '../common/styled/TooltipWrapper';
import { Box } from '@mui/material';
import { withRouter, WithRouterProps } from '../common/withRouter';
import { PageContents } from '../Page/PageContents';
import Text, { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import Page from '../Page/Page';
import { profileReset } from '../../actions/session';
import { Column, DataTable } from '../common/DataTable';
import { Link } from 'react-router-dom';
import { Select } from '../common/styled/Select';
import { FINANCIAL_PLAN_OPTIONS } from '../../actions/types/standardLivingRisk';
import { SelectOption } from '../../common/types';
import { FinancialPlanSource } from '../../actions/types/standardLivingRisk';
import { columns } from './columns'
import { flags } from '../../features'
import { loadOrionClients } from '../../actions/portfolioManager'
import { ReduxState } from '../../reducers'
import { OrionPortfolioClient } from '../../actions/types/portfolioManager'

export interface ClientManagementClient {
    id: number
    phone: string
    email: string
    annual_income: string
    searchText: string
    first_name: React.ReactNode
    last_name: React.ReactNode
    risk_assessment: React.ReactNode
    actions:  React.ReactNode
    item: Client
}

export interface ClientManagementProps extends WithRouterProps {
    getAdvisorClients: () => void
    getClientQuestionnaireLink: (client: Client) => Promise<string>
    getClientFinancialPlanLink: (client: Client) => void
    addClient: (client: Client) => void
    updateClient: (client: ClientUpdateRequest) => void
    emailClientLink: (id: number) => void
    deleteClient: (id: number) => void
    addError: (title: string, message: string) => void
    clearEmailSuccess: () => void
    profileReset: () => void
    loadOrionClients: () => void
    showEmailSuccess: boolean
    orionClients: SelectOption<OrionPortfolioClient>[]
    queryShowAdd?: boolean
    clients: Client[]
    scopes: string[]
}

export interface ClientManagementState {
    searchFilter: string
    clients: ClientManagementClient[]
    filterClients: ClientManagementClient[]
    actionClient?: Client
    showClientModal: boolean
    clientModalMode: string
    showConfirmDeleteModal: boolean
    showConfirmEmailModal: boolean
    linkCopied: boolean
    columns: Column[]
}
class ClientManagement extends Component<ClientManagementProps,ClientManagementState> {
    linkTooltips: TooltipWrapperCollection
    emailTooltips: TooltipWrapperCollection

    constructor(props: ClientManagementProps) {
        super(props)
        this.state = {
            searchFilter: '',
            clients: [],
            filterClients: [],
            actionClient: undefined,
            showClientModal: false,
            clientModalMode: '',
            showConfirmDeleteModal: false,
            showConfirmEmailModal: false,
            linkCopied: false,
            columns: []
        }

        this.linkTooltips = {}
        this.emailTooltips = {}
        props.getAdvisorClients()
    }

    componentDidMount(): void {
        this.updateColumnsForUser()
    }

    updateColumnsForUser = () => {
        const filtredColumns = columns.filter(({ requiresScopes }) => {
            if (!requiresScopes) {
                return true
            }
            return requiresScopes.every(scope => this.props?.scopes?.includes(scope))
        });
        this.setState({ columns: filtredColumns })
    }

    componentDidUpdate(prevProps: ClientManagementProps) {
        const { clients: prevClients, queryShowAdd: prevQueryShowAdd } = prevProps
        const { clients, orionClients,  queryShowAdd } = this.props

        const scopeChanged = (!prevProps.scopes?.includes('pd:portfolio_manager') && this.props.scopes?.includes('pd:portfolio_manager')) ||
                                (prevProps.scopes?.includes('pd:portfolio_manager') && !this.props.scopes?.includes('pd:portfolio_manager'))
        const shouldRefreshClients = (prevClients !== clients && this.props.scopes?.includes('pd:portfolio_manager')) || scopeChanged
        if(shouldRefreshClients) {
            const orionClients = clients.filter(({ portfolio_manager }) => portfolio_manager === 'orion')?.length;
            if (orionClients > 0) {
                this.props.loadOrionClients()
            }
        }
        if(prevClients !== clients || orionClients !== prevProps.orionClients) {
            const formattedClients = this.formatClients(clients) 
            this.setState({ clients: formattedClients }, this.updateSearchFilter)
        }

        if(prevQueryShowAdd === false && queryShowAdd) {
            this.setState({ showClientModal: true })
        }
        if (scopeChanged) {
            this.updateColumnsForUser()
        }
    }

    formatClients = (clients: Client[]): ClientManagementClient[] => {
        const mappedClients:ClientManagementClient[] = clients.map((client: Client) => {
            const id = client.id || 0
            const risk_assessment = this.getRiskAssessmentForClient(client)
            const financial_plan = this.getFinancialPlanForClient(client)
            const portfolio_manager = this.getPortfolioManager(client);
            const actions = this.getActionsForClient(client)
            const first_name = this.linkField(client.first_name, client)
            const last_name = this.linkField(client.last_name, client)
            const searchText = `${client.last_name}${client.first_name}${client.phone}${client.email}`

            return { ...client, id, item: client, searchText, first_name, last_name, annual_income: `$${(client.annual_income || 0).toLocaleString('en-US')}`, risk_assessment, financial_plan, portfolio_manager, actions }
        })

        return mappedClients
    }

    linkField = (value: string, client: Client) => {
        return (
            <Link to={`/software/clients/${client.id}`} onClick={this.props.profileReset}>{value}</Link>
        )
    }

    updateSearchFilter() {
        const { searchFilter, clients } = this.state

        if(!searchFilter) {
            this.setState({ filterClients: clients })

            return
        }

        const filterClients = clients.filter(({ searchText }) => {
            return searchText?.includes(searchFilter)
        })

        this.setState({ filterClients })
    }
    
    onCopyAssessmentLink = async(client: Client, navigateToLink: boolean): Promise<void> => {
        const link = await this.props.getClientQuestionnaireLink(client)
        this.linkTooltips[client.id || ''].showClickedText()

        if (navigateToLink) {
            window.location.href = link;
        }
    }
    
    onCopyFinancialPlanLink = (client: Client) => {
        this.props.getClientFinancialPlanLink(client)
        this.linkTooltips[`${client.id}_financial_plan`].showClickedText()
    }

    onConfirmEmail = (client: Client) => {
        if(!client.email) {
            this.props.addError('Error', 'An email address is required before you can email a questionnaire.');

            return
        }

        this.setState({ showConfirmEmailModal: true, actionClient: client })
    }

    onEmailClient = () => {
        const { actionClient } = this.state
        if(!actionClient || !actionClient.id) {
            return
        }
        const { id } = actionClient

        this.props.emailClientLink(id)
        this.setState({ showConfirmEmailModal: false, actionClient: undefined })
    }

    onAddClient = () => {
        this.setState({ actionClient: undefined, showClientModal: true })
    }

    onClientSave = (client: Client | ClientUpdateRequest) => {
        this.props.updateClient(client as ClientUpdateRequest)
        this.setState({ actionClient: undefined, showClientModal: false })
    }

    onAddClientSave = (client: Client) => {
        this.props.addClient(client)
        this.setState({ actionClient: undefined, showClientModal: false })
    }
    
    onSearchFilterChanged = (e: any) => {
        const searchFilter = e.target.value

        this.setState({ searchFilter }, this.updateSearchFilter)
    }

    onClientDelete = () => {
        const { actionClient } = this.state
        if(!actionClient || !actionClient.id) {
            return
        }
        const { id } = actionClient

        this.props.deleteClient(id)
        this.setState({ showConfirmDeleteModal: false, actionClient: undefined })
    }

    onEdit = (client: Client) => {
        this.setState({ showClientModal: true,  actionClient: client })
    }

    onConfirmDelete = (client: Client) => {
        this.setState({ showConfirmDeleteModal: true, actionClient: client })
    }

    getActionsForClient = (client: Client) => {
        return (
            <Box sx={{
                '& svg': {
                    margin: '.5rem',
                    cursor: 'pointer'
                }
            }}>
                <FontAwesomeIcon color={'#297ea1'} icon={faEdit} onClick={() => this.onEdit(client)} />
                <FontAwesomeIcon color={'#297ea1'} icon={faTrash} onClick={() => this.onConfirmDelete(client)} />
            </Box>
        )
    }

    getRiskAssessmentForClient = (client: Client) => {
        return (
            <Box sx={{
                '& svg': {
                    margin: '.5rem',
                    cursor: 'pointer'
                }
            }}>
                <TooltipWrapper ref={view => this.linkTooltips[client.id || ''] = view} tooltipText={'Copy Link'} tooltipTextClicked={'Link Copied!'}>
                    <FontAwesomeIcon color={'#297ea1'} icon={faCopy} onClick={() => this.onCopyAssessmentLink(client, false)} />
                </TooltipWrapper>
                <TooltipWrapper ref={view => this.emailTooltips[client.id || ''] = view}  tooltipText={'Email Link'} tooltipTextClicked={'Link Emailed!'}>
                    <FontAwesomeIcon color={'#297ea1'} icon={faEnvelope} onClick={() => this.onConfirmEmail(client)} />
                </TooltipWrapper>
                <FontAwesomeIcon color={'#297ea1'} icon={faPen} onClick={() => this.onCopyAssessmentLink(client, true)} />
            </Box>
        )
    }

    onFinancialPlanSourceChanged = (client: ClientUpdateRequest, { value }: SelectOption) => {
        this.props.updateClient({ ...client, financial_plan_source: value as FinancialPlanSource })
    }
    
    onPortfolioManagerChanged = (client: ClientUpdateRequest, { value }: SelectOption) => {
        if (value === 'none') {
            this.props.updateClient({ ...client, portfolio_manager: null, portfolio_manager_client_id: null })

            return;
        }
        this.props.updateClient({ ...client, portfolio_manager: (value as PortfolioManagerType) })
    }
    
    onPortfolioManagerClientChanged = (client: ClientUpdateRequest, { value }: SelectOption) => {
        if (value === 'none') {
            this.props.updateClient({ ...client, portfolio_manager_client_id: null })
        } else {
            this.props.updateClient({ ...client, portfolio_manager_client_id: Number(value) })
        }
    }

    getFinancialPlanForClient = (client: Client) => {
        const { standard_living_risk: standardLivingRisk } = client
        const { financial_plan_source } = standardLivingRisk || { }
        const selectedFinancialPlanSource = FINANCIAL_PLAN_OPTIONS.find(({ value }: SelectOption) => value === financial_plan_source);
        return (
            <Box sx={{
                display: 'flex',
                '& svg': {
                    margin: '.5rem',
                    cursor: 'pointer'
                },
            }}>
                {flags.financialPlanSource && <Select sx={{ width: '25rem', marginRight: '1rem' }} options={FINANCIAL_PLAN_OPTIONS} value={selectedFinancialPlanSource} onChange={(option) => this.onFinancialPlanSourceChanged(client as ClientUpdateRequest, option)} skinny />}
                {(!flags.financialPlanSource || client.standard_living_risk?.financial_plan_source !== FinancialPlanSource.MONEY_GUIDE) &&
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TooltipWrapper ref={view => this.linkTooltips[`${client.id}_financial_plan_link`] = view} tooltipText={'Copy Link'} tooltipTextClicked={'Link Copied!'}>
                            <FontAwesomeIcon color={'#297ea1'} icon={faCopy} onClick={() => this.onCopyFinancialPlanLink(client)} />
                        </TooltipWrapper>
                        <FontAwesomeIcon color={'#297ea1'} icon={faPen} onClick={() => this.props.navigate(`/software/clients/${client.id}/plan`)} />
                    </Box>
                }
            </Box>
        )
    }

    getPortfolioManager = (client: Client) => {
        const { orionClients, scopes } = this.props
        const { portfolio_manager, portfolio_manager_client_id } = client
        if (!scopes.includes('pd:portfolio_manager')) {
            return (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Box sx={{
                        display: 'flex',
                        '& svg': {
                            margin: '.5rem',
                            cursor: 'pointer'
                        },
                    }}>
                        <Select sx={{ width: '15rem' }} options={PORTFOLIO_MANAGER_NONE_OPTIONS} value={null} onChange={(option) => this.onPortfolioManagerChanged(client as ClientUpdateRequest, option)} skinny />
                    </Box>
                </Box>
            );
        }

        const portfolioManagerOption = PORTFOLIO_MANAGER_TYPE_OPTIONS.find(({ value }: SelectOption) => value === portfolio_manager) ?? null;
        let clients: SelectOption[] = [];
        let portfolioManagerClient = null;
        if (portfolio_manager === 'orion') {
            clients = orionClients;
            portfolioManagerClient = clients.find(({ value }: SelectOption) => Number(value) === Number(portfolio_manager_client_id)) ?? null;
        }

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Box sx={{
                    display: 'flex',
                    '& svg': {
                        margin: '.5rem',
                        cursor: 'pointer'
                    },
                }}>
                    <Select sx={{ width: '15rem' }} options={PORTFOLIO_MANAGER_TYPE_OPTIONS} value={portfolioManagerOption} onChange={(option) => this.onPortfolioManagerChanged(client as ClientUpdateRequest, option)} skinny />
                </Box>
                {portfolio_manager === 'orion' &&
                    <Box sx={{
                        display: 'flex',
                        '& svg': {
                            margin: '.5rem',
                            cursor: 'pointer'
                        },
                    }}>
                        <Select sx={{ width: '25rem', marginLeft: '1rem' }} placeholder='Select Client' options={clients} value={portfolioManagerClient} onChange={(option) => this.onPortfolioManagerClientChanged(client as ClientUpdateRequest, option)} skinny />
                    </Box>
                }
            </Box>
        )
    }

    render() {
        const { filterClients, showConfirmDeleteModal, showConfirmEmailModal, actionClient, showClientModal } = this.state
        const { showEmailSuccess } = this.props
        const { first_name, last_name } = actionClient || { }
        
        return (
            <Page sx={{ width: '100%' }} >
                <ConfirmDelete visible={showConfirmDeleteModal} title={'Delete Client'} onCancel={() => this.setState({ showConfirmDeleteModal: false })} onDelete={this.onClientDelete} >
                    <Text>Are you sure you want to delete {first_name} {last_name}'s profile?</Text>
                </ConfirmDelete>

                <ConfirmModal visible={showConfirmEmailModal} title={'Email Client'} confirmLabel={'Send'} onCancel={() => this.setState({ showConfirmEmailModal: false })} onConfirm={this.onEmailClient} >
                    <Text>Please confirm you would like to email {actionClient?.first_name} {actionClient?.last_name} the questionnaire link?</Text>
                </ConfirmModal>

                <MessageModal visible={showEmailSuccess} title={'Email Sent'} onCancel={this.props.clearEmailSuccess} onButtonPress={this.props.clearEmailSuccess} >
                    <Text>We've emailed the questionnaire link to your client!</Text>
                </MessageModal>
                <ClientModal 
                    visible={showClientModal} 
                    client={actionClient}
                    onClientSave={this.onClientSave} 
                    onAddClientSave={this.onAddClientSave}
                    onCancel={() => this.setState({ showClientModal: false, actionClient: undefined })} 
                />
                <PageContents 
                    sx={{
                        marginTop: '5rem',
                        paddingTop: '5rem',
                        minHeight: '80rem',
                        height: '100%',
                        position: 'relative',
                        overflowX: 'scroll',
                        font: FONT_STYLES_BY_TYPE.text,
                    }}
                >
                    <DataTable
                        actions={<Button sx={{ height: '4rem', width: '15rem' }} title={'Add'} onClick={this.onAddClient} />}
                        data={{ 
                            rows: filterClients, 
                            columns: this.state.columns,
                        }}
                    /> 
                </PageContents>
            </Page>
        )
    }

}

const mapStateToProps = ({ clientManagement, portfolioManager, session }: ReduxState) => {
    const { loading, clients, error, showEmailSuccess, queryShowAdd } = clientManagement
    const { orionClients } = portfolioManager
    const { scopes } = session
    
    return {
        loading,
        clients,
        showEmailSuccess,
        queryShowAdd,
        orionClients,
        error,
        scopes: Array.isArray(scopes) ? scopes : ((scopes as string) ?? '').split(' ')
    }

}

export default withRouter(connect(mapStateToProps, { loadOrionClients, profileReset, getAdvisorClients, deleteClient, updateClient, addClient, getClientQuestionnaireLink: getClientQuestionnaireLink, emailClientLink, clearEmailSuccess, addError, getClientFinancialPlanLink })(ClientManagement))