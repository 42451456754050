import React, { Component } from 'react'
import ConfirmModal, { ConfirmModalProps } from './ConfirmModal'

export interface ConfirmDeleteProps extends ConfirmModalProps {
    onDelete: () => void
}


export default class extends Component<ConfirmDeleteProps> {

    render() {
        const { children, onDelete, ...props } = this.props
        return (
            <ConfirmModal 
                {...props}
                onConfirm={onDelete}
                confirmLabel={'Delete'}
                confirmVariant={'danger'}>
                {children}
            </ConfirmModal>
        )
    }
}