import { 
    LOGOUT,
    PROFILE_RESET,
    GUIDE_COPY_ITEMS,
    COPY_ITEMS,
    EDIT_COPY_ITEM,
} from '../actions/types/actions';
import { CopyItem, GuideCopyItem } from '../actions/types/copy';

export interface CopyState {
    items: CopyItem[]
    guideCopyItems: GuideCopyItem[]
    editItem?: CopyItem
}

const INITIAL_STATE: CopyState = {
    items: [],
    guideCopyItems: [],
};

export default (state = INITIAL_STATE, action): CopyState => {
  const { payload, type } = action

  switch (type) {
    case COPY_ITEMS:
        return { ...state, items: payload }
    case EDIT_COPY_ITEM:
        return { ...state, editItem: payload }
    case GUIDE_COPY_ITEMS:
        return { ...state, guideCopyItems: payload }
    case PROFILE_RESET:
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

