import { Client } from '../actions/types/clientManagement';
import { ADVISOR_DASHBOARD_LOADING, QUERY_SHOW_ADD, CLIENTS_RETRIEVED, CLIENT_ADDED, CLIENT_EMAILED_SUCCESS, CLIENT_EMAILED_SUCCESS_CLEAR, LOGO_AVAILABLE, LOGOUT  } from '../actions/types/actions';
import { LooseObject } from '../common/types';
import { StandardAction } from '../store/store';

export interface ClientManagementState {
    loading: boolean,
    error?: LooseObject,
    clients: Client[],
    showEmailSuccess: boolean
    logoAvailable: boolean
    queryShowAdd: boolean
};

const INITIAL_STATE: ClientManagementState = {
    loading: false,
    error: undefined,
    clients: [],
    showEmailSuccess: false,
    logoAvailable: false,
    queryShowAdd: false,
};

export default (state: ClientManagementState = INITIAL_STATE, action: StandardAction): ClientManagementState => {
  const { payload, type } = action

  switch (type) {
    case ADVISOR_DASHBOARD_LOADING:
        return { ...state, loading: payload }
    case CLIENT_ADDED:
        return { ...state, loading: false, clients: [...state.clients, payload] }
    case CLIENTS_RETRIEVED:
        return { ...state, loading: false, clients: payload }
    case CLIENT_EMAILED_SUCCESS:
        return { ...state, error: undefined, loading: false, showEmailSuccess: true }
    case CLIENT_EMAILED_SUCCESS_CLEAR:
        return { ...state, showEmailSuccess: false }
    case LOGO_AVAILABLE:
        return { ...state, logoAvailable: payload }
    case QUERY_SHOW_ADD:
        return { ...state, queryShowAdd: payload }
    case LOGOUT:
        return { ...INITIAL_STATE }
    default:
        return state;
  }
};

