import React, { Component } from 'react'
import { connect } from 'react-redux'
import RiskAversionGauge from '../Gauge/RiskAversionGauge'
import LossAversionGauge from '../Gauge/LossAversionGauge'

import RiskPage, { BaseRiskPageProps } from './RiskPage'
import { DISCLAIMER_TEXT } from './common'
import Chart from '../common/Chart'
import Text from '../common/styled/Text'
import { Box } from '@mui/material'
import { getClientQuestionnaireLink, getRecommendedPortfolio } from '../../actions/riskPreferences'
import { PortfolioDesignerConfiguration } from '../../actions/types/portfolioDesigner'
import Button from '../common/Button'
import { WithClientParameterProps, withClientParameter } from '../common/withClientParameter'

export interface RiskResultsProps extends BaseRiskPageProps, WithClientParameterProps {
    readOnly: boolean
    gammaMeasured: number
    lambdaMeasured: number
    holdings: any[]
    asset_sets: any[]
    scriptRunning: boolean
    analyzerAllocatioChartOptions: any
    riskAversionRiskLevelsChart: any
    lossAversionRiskLevelsChart: any
    currentSetSelection: any
    getClientQuestionnaireLink: () => Promise<string>
}

export interface RiskResultsState {
    showGauges: boolean
    recommendedPortfolio?: PortfolioDesignerConfiguration
    clientUrl?: string
}

class RiskResults extends Component <RiskResultsProps, RiskResultsState> {
    constructor(props: RiskResultsProps) {
        super(props)
        const { gammaMeasured, lambdaMeasured } = props

        const recommendedPortfolio = getRecommendedPortfolio(lambdaMeasured, gammaMeasured)
        this.state = {
            showGauges: false,
            recommendedPortfolio,
        }
    }
    
    async componentDidMount(): Promise<void> {
        const link = await this.props.getClientQuestionnaireLink()

        this.setState({ clientUrl: link })
    }


    componentDidUpdate(prevProps: Readonly<RiskResultsProps>, prevState: Readonly<RiskResultsState>, snapshot?: any): void {
        const { gammaMeasured, lambdaMeasured } = this.props
        if(
            gammaMeasured !== prevProps.gammaMeasured ||
            lambdaMeasured !== prevProps.lambdaMeasured
            ) {

                const recommendedPortfolio = getRecommendedPortfolio(lambdaMeasured, gammaMeasured)

                this.setState({ recommendedPortfolio })

            }
    }

    render() {
        const { gammaMeasured, lambdaMeasured, readOnly,
                riskAversionRiskLevelsChart, lossAversionRiskLevelsChart, onComponentSize } = this.props
        const { recommendedPortfolio , clientUrl} = this.state

        return (
            <RiskPage readOnly={readOnly} hideFooter={true} sx={{ display: 'flex', flexDirection: 'row' }} nextModuleKey={'standardLivingRisk'} onComponentSize={onComponentSize}>
                <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <Box sx={{
                            marginLeft: '2rem',
                            marginRight: '2rem',
                            marginTop: '2rem',
                        }}>
                            <RiskAversionGauge value={gammaMeasured} />
                            <Chart id="riskAversionRiskLevelsChart" sx={{ marginLeft: '5rem', height: '30rem', width: '30rem', marginTop: '-2rem', marginLeft: '2.5rem' }} chartOptions={riskAversionRiskLevelsChart} />
                        </Box>
                        <Box sx={{
                            marginLeft: '2rem',
                            marginRight: '2rem',
                            marginTop: '2rem',
                        }}>
                            <LossAversionGauge value={lambdaMeasured} />
                            <Chart id="lossAversionRiskLevelsChart" sx={{ marginLeft: '5rem', height: '30rem', width: '30rem', marginTop: '-2rem', marginLeft: '2.5rem' }} chartOptions={lossAversionRiskLevelsChart} />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text sx={{ width: '750px', fontSize: '14px'}}>{DISCLAIMER_TEXT}</Text>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between',  width: '55rem' }}>
                    <Box sx={{ marginTop: '12.5rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Text type='h4' sx={{ fontWeight: 'bold', textDecoration: 'underline'}}>Recommended Wants Portfolio</Text>
                        <Text type='h5'>{recommendedPortfolio?.name}</Text>
                    </Box>
                    <Button
                        title={'Update Risk Profile'}
                        href={`/software/clients/${this.props.clientId}/risk`}
                        sx={{ minWidth: 'unset', minHeight: 'unset', width: '21rem', height: '4.5rem', marginRight: '1rem' }}
                    />
                </Box>
            </RiskPage>
        )
    }

}

const mapStateToProps = ({ riskPreferences }) => {
    const { gammaMeasured, lambdaMeasured,
            riskAversionRiskLevelsChart, lossAversionRiskLevelsChart } = riskPreferences

    return {
        gammaMeasured,
        lambdaMeasured,
        riskAversionRiskLevelsChart,
        lossAversionRiskLevelsChart,
    }
}

export default withClientParameter(connect(mapStateToProps, { getClientQuestionnaireLink })(RiskResults))