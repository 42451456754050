import axios from 'axios'

import { getPathsClientId } from '../common/utils'
import { getAssetAllocatioChart, getInvestmentPortfolioChartOptions } from '../components/OptimizedPortfolio/chartOptions'
import { StandardAction, StandardThunkAction } from '../store/store'
import { updateBuilderPreferences } from './customCMA'
import { addError, addStatusNotification, removeNotification } from './notifications'
import { INVESTMENT_PORTFOLIO_UPDATE, RESET_INVESTMENT_PORTFOLIO_UPDATE, RISK_VALUE_CHANGED, } from "./types/actions"
import { AssetSet } from './types/customAssets'
import { InvestmentPortfolioType } from './types/investmentPortfolio'
import { UpdateInvestmentPortfolioRequest, UpdateQuestionRequest } from './types/requests'
import { InvestmentPortfolioScriptResponse } from './types/responses'
import { RiskParameter, RiskParameterType } from './types/riskPreferences'

export const OPTIMIZED_PORTFOLIO_ENDPOINT = 'portfolio'
export const INVESTMENT_ENDPOINT = 'investment'
export const INVESTMENT_SCRIPT_ENDPOINT = `investment/run`
export const CLIENTS_ENDPOINT = '/api/clients'

export const getOptimizedPortfolio = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        const profileId = getPathsClientId()
        if(!profileId) {
            return
        }
        const { data } = await axios.get(`${CLIENTS_ENDPOINT}/${profileId}/${OPTIMIZED_PORTFOLIO_ENDPOINT}`)

        const { holdings, holdingsBenchmark, keyStats, percentiles, scenarioPerfs, portfolioMap, riskScores, scenarioPerfsBenchmark,
                keyStatsBenchmark, opt_asset_set_id, benchmarkModel, portfolioMaxIndv, benchmarkMaxIndv, global_asset_sets: payloadAssetSets } = data
        const { chosen, recommended, preferred } = riskScores || { }
        const global_asset_sets: AssetSet[] = payloadAssetSets ? payloadAssetSets.map((item: AssetSet) => { return { ...item,  label: item.name, value: item.id } }) : []
            
        dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { holdings, holdingsBenchmark, keyStats, percentiles, scenarioPerfs, portfolioMap, chosen, recommended, preferred, scenarioPerfsBenchmark, keyStatsBenchmark, opt_asset_set_id, benchmarkModel, portfolioMaxIndv, benchmarkMaxIndv, portfolioChart: getInvestmentPortfolioChartOptions(percentiles), assetAllocatioChart: getAssetAllocatioChart(holdings), assetAllocatioBenchmarkChart: getAssetAllocatioChart(holdingsBenchmark), global_asset_sets } })
    }
}

export const getInvestmentPortfolio = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {

        const profileId = getPathsClientId()
        if(!profileId) {
            return
        }
        const { data } = await axios.get(`${CLIENTS_ENDPOINT}/${profileId}/${INVESTMENT_ENDPOINT}`)

        const { holdings, holdingsBenchmark, keyStats, percentiles, scenarioPerfs, portfolioMap, riskScores, portfolioMaps, scenarioPerfsBenchmark, keyStatsBenchmark, opt_asset_set_id, portfolio_map_id, benchmarkModel, portfolioMaxIndv, benchmarkMaxIndv } = data
        const { chosen, recommended, preferred } = riskScores || { }
        
        if(!holdings) {
            dispatch({ type: RESET_INVESTMENT_PORTFOLIO_UPDATE })
        } else {
            const portfolioMapsWithLabel = portfolioMaps ? portfolioMaps.map((item: any) => {
                const { name: label, id: value, details: itemDetails = [] } = item

                const details = itemDetails.reduce((accumulator: any, { mappings, ...details }: any) => {
                    const mappingsReduced = mappings.reduce((mappingAccum: any, { gamma_risk_level, lambda_risk_level }: any) => {

                        return { ...mappingAccum, [`${gamma_risk_level}_${lambda_risk_level}`]: { ...details } }
                    }, { })
                    return { ...accumulator, ...mappingsReduced  }
                }, { })

                return { ...item, details, label, value }
            }) : []
            
            dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { holdings, holdingsBenchmark, keyStats, percentiles, scenarioPerfs, portfolioMap, portfolioMaps: portfolioMapsWithLabel, chosen, recommended, preferred, scenarioPerfsBenchmark, keyStatsBenchmark, opt_asset_set_id, portfolio_map_id, benchmarkModel, portfolioMaxIndv, benchmarkMaxIndv, portfolioChart: getInvestmentPortfolioChartOptions(percentiles), assetAllocatioChart: getAssetAllocatioChart(holdings), assetAllocatioBenchmarkChart: getAssetAllocatioChart(holdingsBenchmark) } })
        }
    }
}

export const getPortfolioMapTableDetails = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        const { data } = await axios.get('/api/portfolio/details')
        
        dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { details: data } })
    }
}

export const renderCharts = (): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { investmentPortfolio } = getState()
        const { holdings, percentiles, holdingsBenchmark } = investmentPortfolio

        dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { portfolioChart: getInvestmentPortfolioChartOptions(percentiles), assetAllocatioChart: getAssetAllocatioChart(holdings), assetAllocatioBenchmarkChart: getAssetAllocatioChart(holdingsBenchmark) }})
    }
}

export const getRiskScores = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const profileId = getPathsClientId()
            const { data } = await axios.get(`${CLIENTS_ENDPOINT}/${profileId}/scores`)
            const { success, message } = data
            if(!success) {
                dispatch(addError('Risk Score Error', message))

                return
            }
                
            const { riskScores } = data
            const { chosen: originalChosen, recommended, preferred } = riskScores || { }

            const { lambda: chosenLambda, gamma: chosenGamma } = originalChosen
            const { lambda: recommendedLambda , gamma: recommendedGamma } = recommended
            
            const chosen = { lambda: chosenLambda !=null ? chosenLambda : recommendedLambda, gamma: chosenGamma != null ? chosenGamma : recommendedGamma }

            dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { chosen, recommended, preferred } })
        } catch(e: any) {
            dispatch(addError('Unknown Error', e.toString()))
        }
    }
}

export const updatePortfolioRiskParameter = (type: RiskParameterType, parameter: RiskParameter, value: number): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        if(!['chosen', 'recommended', 'preferred'].includes(type)) {
            throw new Error('Received unexpected type')
        }

        if(!['lambda', 'gamma'].includes(parameter)) {
            throw new Error('Received unexpected parameter')
        }
        
        const { investmentPortfolio } = getState()
        const { [type]: parameters } = investmentPortfolio
        const updated = { ...parameters, [parameter]: value }

        dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { [type]: updated } })
    }
}

export const runInvestmentPortfolioScript = (opt_asset_set_id?: number): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        const notification = await dispatch(addStatusNotification('Robots Optimizing Portfolio...'))

        let data: InvestmentPortfolioScriptResponse = { success: false }
        try {
            const profileId = getPathsClientId()
            const { data: payload } = await axios.post(`${CLIENTS_ENDPOINT}/${profileId}/${INVESTMENT_SCRIPT_ENDPOINT}`, { opt_asset_set_id })
            data = payload
        } catch(e: any) {
            data = { success: false, message: e.toString() }
        }
        const { success, message, result, resultBenchmark, errors } = data
        const { holdings, keyStats, percentiles = { }, scenarioPerfs } = result || { }
        const { holdings: holdingsBenchmark, keyStats: keyStatsBenchmark, scenarioPerfs: scenarioPerfsBenchmark } = resultBenchmark || { }
        if(!success) {
            dispatch(addError('Script Error', message || ''))

            dispatch(removeNotification(notification))
            return
        }

        if(errors && errors.length > 0) {
            dispatch(addError('Script Finished with Errors!', errors.toString()))
        }

        dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { holdings, keyStats, percentiles, scenarioPerfs, holdingsBenchmark, keyStatsBenchmark, scenarioPerfsBenchmark, portfolioChart: getInvestmentPortfolioChartOptions(percentiles as any), assetAllocatioChart: getAssetAllocatioChart(holdings), assetAllocatioBenchmarkChart: getAssetAllocatioChart(holdingsBenchmark) } })

        dispatch(removeNotification(notification))
    }
}

export const updateBenchmarkModel = (model: string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { benchmarkModel: model} })
        dispatch(updateInvestmentPortfolio({ benchmark: { model } }))
    }
}

export const updateMaxInv = (type: InvestmentPortfolioType, value: number): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { [`${type}MaxIndv`]: value} })
        dispatch(updateInvestmentPortfolio({ [type]: { max_indiv_allocation: value } }))
    }
}

export const updateInvestmentPortfolio = (updatePayload: UpdateInvestmentPortfolioRequest, updateLocal = true): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        const notification = await dispatch(addStatusNotification('Robots Optimizing Portfolio...'))

        if(updateLocal) {
            dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: updatePayload })
        }

        try {

            const profileId = getPathsClientId()
            const { data: payload } = await axios.put(`${CLIENTS_ENDPOINT}/${profileId}/${INVESTMENT_ENDPOINT}`, updatePayload)
            const { success, message, keyStatsBenchmark, holdingsBenchmark, scenarioPerfsBenchmark } = payload
            if(!success) {
                throw new Error(message)
            }
            if(keyStatsBenchmark && holdingsBenchmark && scenarioPerfsBenchmark) {
                dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { keyStatsBenchmark, holdingsBenchmark, scenarioPerfsBenchmark, assetAllocatioBenchmarkChart: getAssetAllocatioChart(holdingsBenchmark) } })
            }
        } catch(e: any) {
            dispatch(addError("Error", e))
        }
        dispatch(removeNotification(notification))
    }
}

export const onChosenRiskValueChanged = (type: RiskParameter, value: string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try{
            const profileId = getPathsClientId()
            const { data } = await axios.post(`${CLIENTS_ENDPOINT}/${profileId}/risk/chosen`, { type, value })

            const { result, resultBenchmark } = data
            const { holdings, keyStats, percentiles, scenarioPerfs } = result || { }
            const { keyStats: keyStatsBenchmark, scenarioPerfs: scenarioPerfsBenchmark } = resultBenchmark || { }
            
            dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { holdings, keyStats, percentiles, scenarioPerfs, keyStatsBenchmark, scenarioPerfsBenchmark, portfolioChart: getInvestmentPortfolioChartOptions(percentiles), assetAllocatioChart: getAssetAllocatioChart(holdings) } })
            
            dispatch({ type: RISK_VALUE_CHANGED, payload: { [type]: value } })
        }catch(e: any) {
            dispatch(addError('Error Saving', e.toString()))
        }
    }
}

export const updateOptAssetSet = (opt_asset_set_id: number | undefined, localUpdate: boolean = false): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { opt_asset_set_id } })
            if(!localUpdate) {
                dispatch(updateBuilderPreferences({ opt_asset_set_id }, false))   
            }
        } catch(e: any) {
        }
    }
}

export const updatePortfolioMapId = (portfolio_map_id: number): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { portfolio_map_id } })
            dispatch(updateBuilderPreferences({ portfolio_map_id }, false))
        } catch(e: any) {
        }
    }
}