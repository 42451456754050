import React, { FunctionComponent } from 'react';
import CopyPage from './CopyPage';


export const PrivacyPolicy: FunctionComponent = () => {
    

    return (
        <CopyPage name={'privacy_policy'} />
    )
}
export default PrivacyPolicy