import { Box } from '@mui/system'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Text from '../../common/Text'
import { getLogs } from '../../../actions/debug'
import { addError } from '../../../actions/notifications'
import { LogItem } from '../../../actions/types/debug'
import { AdminPage } from '../AdminPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'


interface Props { 
    addError: (title: string, message: string) => any
    source: 'Pd' | 'Analyzer'
}


interface State {
    logs: LogItem[]
    loading: boolean
}


class LogsComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            logs: [],
            loading: true,
        }
    }

    componentDidMount(): void {
        this.loadLogs()
    }

    loadLogs = async() => {
        const { source } = this.props

        try {
            this.setState({ loading: true })
            const logs = await getLogs(source)

            this.setState({ logs, loading: false })
        }catch(e: any) {
            this.props.addError(`${source} Logs`, `${e.toString()}`)
            this.setState({ loading: false })
        }
    }

    render() {
        const { logs, loading } = this.state

        return (
            <AdminPage>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                        <FontAwesomeIcon icon={faRefresh} onClick={this.loadLogs} />
                    </Box>
                {loading &&
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                        <Text>Loading Logs...</Text>
                    </Box>
                }
                {!loading && logs.length === 0 &&
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                        <Text>No logs currently found</Text>
                    </Box>
                }
                {logs.map((item) => {
                    return (
                        <Text sx={{ 'white-space': 'pre-wrap' }} type={'text_tiny'} key={item.eventId}>{item?.message?.split('').join(' ')}</Text>
                    )
                })}
            </AdminPage>
        )
    }
}

export const mapStateToProps = (_: any, props: Props) => {
    return { ...props }
}
export default connect(mapStateToProps, { addError })(LogsComponent)