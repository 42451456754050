import {Helmet} from 'react-helmet';
import { logoHorizontal } from '../../assets'

const SocialMetaTags = () => {
  const title = 'Portfolio Designer - Modern portfolio analytics and tools for financial advisors'
  const description = 'Portfolio designer allows financial advisors to backtest portfolios, analyze portfolio risk on a look-through basis, and create highly customized portfolios in a goals-based framework.'

  return (
    <Helmet>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={logoHorizontal} />
      <meta property="og:url" content={location.hostname} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={logoHorizontal} />
    </Helmet>
  );
};

export default SocialMetaTags;
