import * as React from 'react';
import { withStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

const FeatureCard = ({ children, sx = { }, className = '' }) => {

    if(children?.length !== 2) {
        throw new Error('FeatureCard must have two direct children')
    }
    const { breakpoints } = useTheme()

    return (
        <Box sx={{
            position: 'relative',
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            [breakpoints.down('md')]: {
                flexDirection: 'column',
            },
            ...sx,
        }}
        className={className}>
            <Box sx={{ flex: 1 }}>
                {children[0]}
            </Box>
            <Box sx={{ flex: 1 }}>
                {children[1]}
            </Box>
        </Box>
    )
}

export default FeatureCard