import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { FieldItem, TextFieldItem } from '../common/FormField';
import { updateAdvisor } from '../../actions/account';
import { Advisor } from '../../actions/types/account';
import { formatPhoneNumber } from '../../common/utils';
import Button from '../common/Button';
import { addError, addStatusNotification, updateNotification } from '../../actions/notifications';
import { AccountPanel } from './AccountPanel';
import { StatusNotificationItem } from '../../actions/types/notifications';
import { UpdateAdvisorRequest } from '../../actions/types/requests';
import { Select } from '../common/styled/Select';
import { DOMICILE_ITEMS, UserDomicile } from '../../actions/types/users';
import { SelectOption } from '../../common/types';
import { width } from '@mui/system';

interface AdvisorDetailsProps extends Advisor {
    onAdvisorUpdated: (update: UpdateAdvisorRequest) => void
    updateAdvisor: (payload: UpdateAdvisorRequest) => void
    addError: (title: string, message: string) => void
    addStatusNotification: (message: string) => Promise<StatusNotificationItem>
    updateNotification: (notification: StatusNotificationItem) => void
}

const AdvisorDetails: FunctionComponent<AdvisorDetailsProps> = ({ onAdvisorUpdated, updateNotification, addStatusNotification, firstName: propFirstName = '', lastName: propLastName = '', email: propEmail = '', phone: propPhone = '', domicile: propDomicile = 'US' }) => {
    const [firstName, setFirstName] = React.useState<string>(propFirstName)
    const [lastName, setLastName] = React.useState<string>(propLastName)
    const [email, setEmail] = React.useState<string>(propEmail)
    const [confirmEmail, setConfirmEmail] = React.useState<string>(propEmail)
    const [phone, setPhone] = React.useState<string>(formatPhoneNumber(propPhone))
    const [domicile, setDomicile] = React.useState<SelectOption>(DOMICILE_ITEMS.find(({ value }) => value === propDomicile) || DOMICILE_ITEMS[0])
    const [dirty, setDirty] = React.useState<boolean>(false)
    
    const onUpdate = React.useCallback(async() => {
        let updatePayload = { }
        if(firstName && firstName !== propFirstName) {
            updatePayload = { ...updatePayload, firstName }
        }
        if(lastName && lastName !== propLastName) {
            updatePayload = { ...updatePayload, lastName }
        }
        if(phone && phone !== formatPhoneNumber(propPhone)) {
            updatePayload = { ...updatePayload, phone }
        }
        if(domicile && domicile.value !== propDomicile) {
            updatePayload = { ...updatePayload, domicile: domicile.value }
        }

        if(Object.keys(updatePayload).length === 0) {
            return
        }
        const notification = await addStatusNotification('Saving advisor details!')
        const { success, message = '' } = await updateAdvisor(updatePayload)
        
        if(success) {
            updateNotification({ ...notification, content: 'Succesfully saved advisor details!', duration: 2000, showSpinner: false,  })
            onAdvisorUpdated(updatePayload)
        } else {
            updateNotification({ ...notification, type: 'Error', title: 'Advisor Update', content: message, duration: 2000  })
        }

    },[firstName, lastName, phone, email, confirmEmail, domicile ])

    React.useEffect(() => {
        if(firstName && firstName !== propFirstName) {
            setDirty(true)
        } else if(lastName && lastName !== propLastName) {
            setDirty(true)
        } else if(phone && phone !== formatPhoneNumber(propPhone)) {
            setDirty(true)
        } else if(email && email !== propEmail) {
            setDirty(true)
        } else if(domicile && domicile.value !== propDomicile) {
            setDirty(true)
        } else {
            setDirty(false)
        }
    }, [firstName, lastName, email, phone, domicile, propFirstName, propLastName, propPhone, propEmail, propDomicile])

    React.useEffect(() => {
        setFirstName(propFirstName)
        setLastName(propLastName)
        setEmail(propEmail)
        setConfirmEmail(propEmail)
        setPhone(formatPhoneNumber(propPhone))
        setDomicile(DOMICILE_ITEMS.find(({ value }) => propDomicile === value) || DOMICILE_ITEMS[0])

    }, [propFirstName, propLastName, propEmail, propPhone, propDomicile])
    
    const onFormatPhone = React.useCallback((value: any) => {
        setPhone(formatPhoneNumber(value))
    }, [phone])

    return (
        <AccountPanel
            title={'Advisor Details'}
            sx={{ height: {
                xs: '60rem',
                md: '50rem'
            }}}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '2rem' }}>
                <TextFieldItem
                    sx={{ 
                        width: {
                            xs: '100%',
                            md: '35rem'
                        }
                    }}
                    title={'First Name'}
                    value={firstName}
                    onValueChange={setFirstName}
                    inline
                />
                <TextFieldItem
                    sx={{ 
                        width: {
                            xs: '100%',
                            md: '35rem'
                        }
                    }}
                    title={'Last Name'}
                    value={lastName}
                    onValueChange={setLastName}
                    inline
                />
                <TextFieldItem
                    sx={{ 
                        width: {
                            xs: '100%',
                            md: '35rem'
                        }
                    }}
                    type={'email'}
                    title={'Email Address'}
                    value={email}
                    onValueChange={setEmail}
                    inline
                />
                {email !== propEmail &&
                    <TextFieldItem
                        sx={{ 
                            width: {
                                xs: '100%',
                                md: '35rem'
                            }
                        }}
                        type={'email'}
                        title={'Confirm Email'}
                        value={confirmEmail}
                        onValueChange={setConfirmEmail}
                        inline
                    />
                }
                <TextFieldItem
                    sx={{ 
                        width: {
                            xs: '100%',
                            md: '35rem'
                        }
                    }}
                    type={'phone'}
                    title={'Phone Number'}
                    value={phone}
                    onValueChange={onFormatPhone}
                    inline
                />
                <FieldItem titleSx={{ flex: 1 }} title={'Domicile'} inline={true}>
                    <Select sx={{ width: '35rem' }} options={DOMICILE_ITEMS} value={domicile} onChange={setDomicile}/>
                </FieldItem>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '1rem', paddingRight: '1rem', }}>
                <Button 
                    sx={{ width: '15rem', height: '4.5rem', minHeight: 'unset' }}
                    title={'Update'}
                    onClick={onUpdate}
                    disabled={!dirty}
                />
            </Box>
        </AccountPanel>
    )
}

const mapStateToProps = ({ account }) => {
    return { account }
}

export default connect(mapStateToProps, { addStatusNotification, addError, updateNotification,  })(AdvisorDetails)