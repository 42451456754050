import { Box } from '@mui/material'
import React, { Component } from 'react'
import ConfirmModal from '../common/ConfirmModal'
import { FieldItem, TextField, TextFieldItem } from '../common/FormField'
import LabeledItem from '../common/LabeledItem'

interface SaveAsModalProps {
    onSave: (name: string) => void
    onCancel: () => void
    visible: boolean
    name: string
    size?: 'sm' | 'lg' | 'xl'
}

interface SaveAsModalState {
    name: string
}

export default class extends Component<SaveAsModalProps, SaveAsModalState> {

    constructor(props: SaveAsModalProps) {
        super(props)

        this.state = {
            name: props.name
        }
    }

    componentDidUpdate(prevProps: SaveAsModalProps) {
        const { visible: prevVisible } = prevProps
        const { visible, name } = this.props

        if(visible != prevVisible && visible) {
            this.setState({ name })
        }
    }

    onSave = () => {
        if(!this.props.onSave) {
            return
        }
        this.props.onSave(this.state.name)
    }

    render() {
        const { visible, size } = this.props
        const { name } = this.state

        return (
            <ConfirmModal
                size={size}
                visible={visible}
                title={`Save As`}
                confirmLabel={'Save'}
                cancelLabel={'Cancel'}
                confirmDisabled={!name}
                onConfirm={this.onSave}
                onCancel={this.props.onCancel}>
                    <Box>
                        <FieldItem title={'Enter a Name'} inline={false}>
                            <TextField value={name} onValueChange={(value) => this.setState({ name: value })} />
                        </FieldItem>
                    </Box>
            </ConfirmModal>
        )
    }
}