import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import Button from '../common/Button';
import TabFooter from '../Software/TabFooter';
import {  submitClientRiskPreferences } from '../../actions/clientRiskPreferences'
import { useAuth } from '../../common/hooks/useAuth';

interface ReflectionFooterProps {
    activeIndex: number
    submitClientRiskPreferences: (skipRedirect: boolean) => void
}

export const ReflectionFooter: FunctionComponent<ReflectionFooterProps> = ({ activeIndex = 0, submitClientRiskPreferences }) => {
    const { authenticated } = useAuth()

    const onSubmit = React.useCallback(() => {
        const skipRedirect = authenticated;

        submitClientRiskPreferences(skipRedirect)
    }, [authenticated])

    const canSubmit = activeIndex === 1
    const additionalActions = canSubmit ? <Button title="Submit" sx={{ minWidth: '10rem', minHeight: 'unset', width: '15rem', height: '4.5rem' }} onClick={onSubmit}>Submit</Button> : undefined
    
    return (
        <TabFooter 
            pageCount={2}
            showNextPage={activeIndex < 1}
            additionalActions={additionalActions}
        />
    )
}
const mapStateToProps = ({ session }) => {
    const { clientQuestionnaireActiveIndex } = session
    
    return {
        activeIndex: clientQuestionnaireActiveIndex,
    }
}

export default connect(mapStateToProps, { submitClientRiskPreferences })(ReflectionFooter)