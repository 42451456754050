import { Box } from '@mui/material'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Notification, NotificationState } from '../../actions/types/notifications'
import { NOTIFICATION_TYPE_TO_COMPONENT, removeNotification } from '../../actions/notifications'
import Alert from '../common/Alert'

interface AlertContainerProps {
    notifications?: Notification[],
    alertViews?: any[],
    alertChildren?: any[],
    removeNotification: (notification: Notification) => void,
}

class AlertContainer extends Component<AlertContainerProps> {
    constructor(props) {
        super(props)
    }


    getNotificationComponent = (notification: Notification) => {
        const { id, content, onDismiss, ...restNotification } = notification
        const NotificationComponent: any = NOTIFICATION_TYPE_TO_COMPONENT[restNotification.type] || Alert
        const dismissHandler = (...args) => {
            this.props.removeNotification(notification)
            if(onDismiss) {
                onDismiss(args)
            }
        }

        return (
            <NotificationComponent id={id} key={id} onDismiss={dismissHandler} visible={true} {...restNotification}>{content}</NotificationComponent>
        )
    }

    render() {
        const { alertViews = [], alertChildren = [], notifications = [] } = this.props
        const elements = [...notifications].sort((a: Notification, b: Notification) => {
            const bPriority = b.priority || 0
            const aPriority = a.priority || 0

            return bPriority - aPriority
        }).map((notification) => this.getNotificationComponent(notification))
        
        return (
            <Box sx={{ 
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'absolute',
                top: '8rem',
                right: 0,
                left: 0,
                zIndex: 10001,
                paddingRight: '1rem',
                pointerEvents: 'none'
             }}>
                <Box sx={{ 
                    zIndex: 10001,
                    top: 100,
                    right: 100,
                    paddingTop: '2rem',
                    pointerEvents: 'all',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                 }}>
                    {alertViews && alertViews.map((view) => view)}
                    {alertChildren && alertChildren}
                    {elements && elements}
                </Box>
            </Box>
        )
    }

}

const mapStateToProps = ({ notifications }: { notifications: NotificationState }) => {
    const notificationIds = Object.keys(notifications)
    const mappedNotifications = notificationIds.map(id => notifications[id])
    
    return { notifications: mappedNotifications  }
}

export default connect(mapStateToProps, { removeNotification })(AlertContainer)