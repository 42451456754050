import React, { Component } from 'react'
import { connect } from 'react-redux'

import { yearsToFinancialGoalUpdate, yearsOfFinancialGoalUpdate, discountRateUpdate, inflationRateUpdate } from '../../actions/standardLivingRisk'
import { LineItem } from './LineItem'
import Card, { SLRBaseProps } from './Card'

interface FinancialGoalProps extends SLRBaseProps {
    yearsToFinancialGoalUpdate: () => void,
    yearsOfFinancialGoalUpdate: () => void,
    discountRateUpdate: () => void,
    inflationRateUpdate: () => void,
    yearsToFinancialGoal: number
    yearsOfFinancialGoal: number
    discountRate: number
    inflationRate: number
}

class FinancialGoal extends Component <FinancialGoalProps> {
    constructor(props: FinancialGoalProps) {
        super(props)
    }

    render() {
        const { yearsToFinancialGoal, yearsOfFinancialGoal, onComponentSize } = this.props
        
        return (
            <Card title={'Preliminaries'} onComponentSize={onComponentSize}>
                <LineItem number={1} label={'Years Until Retirement'} value={yearsToFinancialGoal} onLeave={this.props.yearsToFinancialGoalUpdate} />
                <LineItem number={2} label={'Years In Retirement'} value={yearsOfFinancialGoal} onLeave={this.props.yearsOfFinancialGoalUpdate} />
            </Card>
        )
    }

}

const mapStateToProps = ({ standardLivingRisk }) => {
    const { years_to_financial_goal: yearsToFinancialGoal, years_in_financial_goal: yearsOfFinancialGoal, discount_rate: discountRate, inflation_rate: inflationRate } = standardLivingRisk
    
    return {
        yearsToFinancialGoal,
        yearsOfFinancialGoal,
        discountRate,
        inflationRate,
    }
}

export default connect(mapStateToProps, { yearsToFinancialGoalUpdate, yearsOfFinancialGoalUpdate, discountRateUpdate, inflationRateUpdate })(FinancialGoal)