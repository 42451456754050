import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, SxProps, TableCell, TableRow, Theme } from "@mui/material"
import React, { Component, FunctionComponent } from "react"
import { connect } from "react-redux"
import { addGuideCopyItem , deleteGuideCopyItem } from "../../../actions/adminCopyEditor"
import { CopyItem, GuideCopyItem } from "../../../actions/types/copy"
import { Membership, MEMBERSHIP_MAPPING } from "../../../actions/types/users"
import { SelectOption } from "../../../common/types"
import Button from "../../common/Button"
import { FieldItem } from "../../common/FormField"
import Panel, { PanelProps } from "../../common/Panel"
import { Select } from "../../common/styled/Select"
import Text, { FONT_STYLES_BY_TYPE } from "../../common/styled/Text"
import { Table, TableHeaderCell } from "../../common/Table"
import { BaseProps } from "../../types"

interface State {
  copyOptions: SelectOption[]
  selectedCopyItem: SelectOption | null
}

interface Props extends BaseProps {
    addGuideCopyItem: (membership: Membership, enterprise: boolean, copy_id: number) => void
    deleteGuideCopyItem: (id: number) => void
    membership: Membership
    enterprise?: boolean
    copyItems: CopyItem[]
    items: GuideCopyItem[]
    contentsSx?: SxProps<Theme>
}

const CopyItemSelectItem: FunctionComponent<{ name: string, title: string }> = ({ name, title }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Text sx={{ fontWeight: 'bold' }}>{title}</Text>
      <Text>{name}</Text>
    </Box>
  )
}

interface GuideCopyItemRowProps extends BaseProps {
  item: GuideCopyItem
  onDelete: (item: GuideCopyItem) => void
}

const GuideItemRow: FunctionComponent<GuideCopyItemRowProps> = ({ item, onDelete }) => {
  const { copy } = item
  const { title, name } = copy

  const onDeletePressed = React.useCallback(() => {
    onDelete(item)
}, [item, onDelete])

  return (
    <TableRow>
      <TableCell>
        <CopyItemSelectItem title={title} name={name} />
      </TableCell>
      <TableCell>
          <FontAwesomeIcon color={'#297ea1'} size={'2x'} icon={faTrash} onClick={onDeletePressed} />
      </TableCell>
    </TableRow>
  )
}

class GuidePanel extends Component<Props, State> {

  constructor(props) {
    super(props)

    this.state = {
      copyOptions: [],
      selectedCopyItem: null,
    }
  }

  componentDidMount() {
    const { copyItems } = this.props
    
    if(copyItems) {
      this.formatCopyItems(copyItems)
    }
  }

  componentDidUpdate(prevProps) {
    const { copyItems: prevCopyItems } = prevProps
    const { copyItems } = this.props

    if(copyItems !== prevCopyItems) {
      this.formatCopyItems(copyItems)
    }
  }

  formatCopyItems = (items: CopyItem[]) => {
    const copyOptions = items.map(({ title, name, id }) => {
      return { label: <CopyItemSelectItem title={title} name={name} />, value: id || 0 }
    })

    this.setState({ copyOptions })
  }

  onSelectedCopyItem = (item: SelectOption) => {
    this.setState({ selectedCopyItem: item })
  }

  onAddItem = () => {
    const { enterprise, membership } = this.props
    const { selectedCopyItem } = this.state
    if(!selectedCopyItem?.value) {
      return
    }

    this.props.addGuideCopyItem(membership, enterprise || false, selectedCopyItem.value as number)
    this.setState({ selectedCopyItem: null })
  }

  onDelete = (item: GuideCopyItem) => {
    this.props.deleteGuideCopyItem(item.id || 0)
  }


  render() {
    const { membership, enterprise, sx, contentsSx = { }, items } = this.props
    const { copyOptions, selectedCopyItem } = this.state
    
    const title = MEMBERSHIP_MAPPING[membership] ? `${MEMBERSHIP_MAPPING[membership]}${enterprise ? '(Enterprise)' : ''}` : 'Default'
    return(
        <Panel 
            title={title}
            sx={{
                margin: '1rem',
                ...sx,
            }}
            contentsSx={{
                padding: '1rem',
                font: FONT_STYLES_BY_TYPE.text,
                ...contentsSx,
            }}
        >
          <FieldItem titleSx={{ flex: 1 }} contentsSx={{ flex: 2 }} inline={false}>
              <Select sx={{ width: '35rem', marginRight: '1.5rem' }} options={copyOptions} value={selectedCopyItem} onChange={this.onSelectedCopyItem}/> <Button sx={{ minHeight: 'unset', height: '4.5rem' }} onClick={this.onAddItem} disabled={!selectedCopyItem} autoFit>Add Item</Button>
          </FieldItem>
          <Table
            headerColumns={[{
              key: 'Guides',
              children: 'Guides'
            },
            {
              key: 'Actions',
              children: 'Actions',
            }
            ]}
            
          >
            {items.length > 0 && items.map((item) => <GuideItemRow item={item} onDelete={this.onDelete} /> )}
            {items.length == 0 &&
              <TableRow>
                <TableCell colSpan={2}>
                  <Box sx={{ display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <Text>Add Some Items</Text>
                  </Box>
                </TableCell>
              </TableRow>
            }
          </Table>
        </Panel>
    )
  }
}

const mapStateToProps = ({ copy }, { enterprise = false, membership}) => {
  const { items: copyItems, guideCopyItems } = copy
  const items = guideCopyItems.filter((item: GuideCopyItem) => item.enterprise == enterprise && membership == item.membership )

  return { copyItems: copyItems.filter((item: CopyItem) => !item.system_managed), items }
}

export default connect(mapStateToProps, { addGuideCopyItem, deleteGuideCopyItem })(GuidePanel)