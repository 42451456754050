import { Box } from '@mui/material'
import React, { Component } from 'react'
import { withAuth, withAuthProps } from '../../../common/hooks/withAuth'
import { getUserSubscription } from '../../../actions/adminUserManagement'
import { ActivationCode, ACTIVATION_CODE_OPTION, DOMICILE_ITEMS, EditUser, MEMBERSHIP_MAPPING, MEMBERSHIP_OPTIONS, NEW_USER, SubscriptionSummary, UserDomicile } from '../../../actions/types/users'
import { SelectOption } from '../../../common/types'
import ConfirmModal from '../../common/ConfirmModal'
import { FieldItem, TextFieldItem } from '../../common/FormField'
import { FormRow } from '../../common/FormRow'
import { Select } from '../../common/styled/Select'
import { connect } from 'react-redux'

const ROLE_ITEMS = [{ label: 'Admin', value: 1 },{ label: 'Developer', value: 2 },{ label: 'Token Rep', value: 7 },{ label: 'Beta Tester', value: 3 },{ label: 'Subscriber', value: 4 },{ label: 'Enterprise Subscriber', value: 5 },{ label: 'Admin', value: 1 },{ label: 'Enterprise Beta', value: 6 }]
interface Props extends withAuthProps {
    modelUser?: EditUser
    visible: boolean
    onCancel: () => void
    onSave: (user: EditUser) => void
    activationCodes: ActivationCode[]
}

interface State extends EditUser {
    selectedMembership: SelectOption | null
    selectedRole: SelectOption | null
    subscriptionSummary?: SubscriptionSummary
    activationCodes: SelectOption[]
    selectedActivationCode: SelectOption | null
    selectedDomicile?: SelectOption | null
}

class UserModal extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        const { id, firstName, lastName, domicile, email, domain, phone, role_id, membership, external_subscription_id, password } = props.modelUser || { ...NEW_USER }

        this.state = { 
            id,
            firstName,
            lastName,
            email,
            domain,
            domicile,
            phone,
            role_id,
            membership,
            external_subscription_id,
            password,
            selectedMembership: null,
            selectedRole: null,
            activationCodes: [],
            selectedActivationCode: null,
            selectedDomicile: null,
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { modelUser: prevUser, activationCodes: prevActivationCodes } = prevProps
        const { modelUser: user, activationCodes } = this.props

        if(user !== prevUser) {
            const { id, firstName, lastName, email, domain, phone, role_id, membership, activation_code, external_subscription_id, password, domicile } = user || { ...NEW_USER }
            const selectedMembership = membership ? MEMBERSHIP_OPTIONS.find(({ value }) => value === membership) : null
            const selectedRole = membership ? ROLE_ITEMS.find(({ value }) => value === role_id) : null
            const selectedDomicile = domicile ? DOMICILE_ITEMS.find(({ value }) => value === domicile) : DOMICILE_ITEMS[0]

            if(id){
                this.pullSubscriptionSummary(id)
            }
            let selectedActivationCode = null
            if(activation_code) {
                const { assigned_user: assignedUser, code } = activation_code
                const label: string = `${MEMBERSHIP_MAPPING[membership]} (${assignedUser?.firstName[0]}. ${assignedUser?.lastName})`

                selectedActivationCode = { label, value: code }
            }
            this.setState({ id, firstName, lastName, email, domain, phone, role_id, membership, external_subscription_id, password, selectedMembership: selectedMembership || null, selectedRole: selectedRole || null, selectedActivationCode, domicile, selectedDomicile, })
        }
    

        if(prevActivationCodes !== activationCodes) {
            const formatted = this.formatActivationCodes(activationCodes)

            this.setState({ activationCodes: formatted })
        }
    }

    formatActivationCodes = (activationCodes: ActivationCode[]): SelectOption[] => {
        const codesWithUsers = activationCodes.filter((item) => item.assigned_user_id);
        const activationMembershipOptions = codesWithUsers.map(({ membership, assigned_user: assignedUser, code }) => {
            let label: string = MEMBERSHIP_MAPPING[membership]
            if (assignedUser) {
                label += ` (${assignedUser?.firstName[0]}. ${assignedUser?.lastName})`
            }

            return { label, value: code }
        })
        return [{ label: 'None', value: null }, ...activationMembershipOptions]
    }

    pullSubscriptionSummary = async(userId: number) => {
        const result = await getUserSubscription(userId)

        this.setState({ subscriptionSummary: result.subscription_summary })
    }

    onRoleChanged = (selection: SelectOption) => {
        this.setState({ selectedRole: selection, role_id: selection.value as number })
    }

    onMembershipChanged = (selection: SelectOption) => {
        this.setState({ selectedMembership: selection, membership: selection.value as any })
    }

    onDomicileChanged = (selection: SelectOption) => {
        this.setState({ selectedDomicile: selection, domicile: selection.value as UserDomicile })
    }


    onSave = () => {
        const { id, firstName, lastName, email, domain, phone, role_id, membership, password, external_subscription_id, selectedActivationCode, domicile } = this.state

        this.props.onSave({ id, firstName, lastName, email, domain, phone, role_id, membership, password, external_subscription_id, domicile, activation_code: selectedActivationCode?.value || undefined })
    }

    onSelectedActivationCode = (selectedActivationCode: SelectOption) => {
        this.setState({ selectedActivationCode })
    }

    render() {
        const { visible, onCancel, userDetails } = this.props
        const { scopes } = userDetails || { scopes: [''] }
        const { id, role_id, firstName, lastName, email, selectedDomicile, phone, selectedMembership, selectedRole, password = '', external_subscription_id = '', subscriptionSummary } = this.state
        const isEdit = !!id
        const action = isEdit ? `Edit` : 'Create'

        return (
            <ConfirmModal
                visible={visible}
                sx={{ width: '100%' }}
                title={`${action} User`}
                confirmLabel={'Save'}
                cancelLabel={'Cancel'}
                onConfirm={this.onSave}
                onCancel={onCancel}>
                        <FormRow>
                            <TextFieldItem
                                title={'First Name'}
                                value={firstName}
                                onValueChange={(value) => this.setState({ firstName: value })}
                            />
                            <TextFieldItem 
                                title={'Last Name'}
                                value={lastName}
                                onValueChange={(value) => this.setState({ lastName: value })}
                            />
                            <TextFieldItem
                                title={'Phone'}
                                value={phone}
                                onValueChange={(value) => this.setState({ phone: value })}
                            />
                            <TextFieldItem
                                title={'Email'}
                                sx={{ width: '25rem' }}
                                value={email}
                                disabled={isEdit}
                                onValueChange={(value) => this.setState({ email: value })}
                            />
                            <FieldItem titleSx={{ flex: 1 }} contentsSx={{ flex: 2 }} title={'Domicile'} inline={false}>
                                <Select sx={{ width: '25rem' }} options={DOMICILE_ITEMS} value={selectedDomicile} onChange={this.onDomicileChanged}/>
                            </FieldItem>
                            {!isEdit &&
                                <TextFieldItem
                                    title={'Password'}
                                    value={password}
                                    type={'password'}
                                    onValueChange={(value) => this.setState({ password: value })}
                                />
                            }
                        </FormRow>
                        {scopes.includes('pd:admin:*') && 
                            <FormRow>
                                    <FieldItem titleSx={{ flex: 1 }} contentsSx={{ flex: 2 }} title={'Role'} inline={false}>
                                        <Select sx={{ width: '25rem', marginRight: '1.5rem' }} options={ROLE_ITEMS} value={selectedRole} onChange={this.onRoleChanged}/>
                                    </FieldItem>
                                    <FieldItem titleSx={{ flex: 1 }} contentsSx={{ flex: 2 }} title={'Membership'} inline={false}>
                                        <Select sx={{ width: '25rem', marginRight: '1.5rem' }} options={MEMBERSHIP_OPTIONS} value={selectedMembership} onChange={this.onMembershipChanged}/>
                                    </FieldItem>
                                    <FieldItem titleSx={{ flex: 1 }} contentsSx={{ flex: 2 }} title={'Activation Code'} inline={false}>                
                                        <Select sx={{ width: '25rem', marginRight: '1.5rem' }} options={this.state.activationCodes} value={this.state.selectedActivationCode} onChange={this.onSelectedActivationCode} />
                                    </FieldItem>
                                    {role_id !== 5 && isEdit &&
                                    <TextFieldItem
                                            sx={{ width: '25rem' }}
                                            title={'Subscription ID'}
                                            value={external_subscription_id}
                                            onValueChange={(value) => this.setState({ external_subscription_id: value })}
                                        />
                                    }
                            </FormRow>
                        }
                        {scopes.includes('pd:admin:*') && subscriptionSummary &&
                            <FormRow>
                                <TextFieldItem
                                    sx={{ width: '25rem' }}
                                    title={'Subscription Plan'}
                                    value={subscriptionSummary?.plan}
                                    readOnly
                                />
                                <TextFieldItem
                                    sx={{ width: '25rem' }}
                                    title={'Subscription Start'}
                                    value={subscriptionSummary?.start_date}
                                    readOnly
                                />
                                <TextFieldItem
                                    sx={{ width: '25rem' }}
                                    title={subscriptionSummary?.cancel_at_period_end ? 'Cancels On' : 'Next Billing Date'}
                                    value={subscriptionSummary?.current_period_end}
                                    readOnly
                                />
                            </FormRow>
                        }
            </ConfirmModal>
        )
    }
}

const mapStateToProps = ({ activationCodes }) => {
    const { activationCodes: items } = activationCodes

    return { activationCodes: items }
}


export default withAuth(connect(mapStateToProps)(UserModal))