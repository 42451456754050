import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import { Box } from '@mui/material';

import ActivationCodes from './ActivationCodes/ActivationCodes';
import UserManagement from './UserManagement/UserManagement';
import PortfolioMapTool from "./PortfolioMapTool/PortfolioMapTool"
import GlobalCustomCMA from "./GlobalCustomCMA/GlobalCustomCMA"
import { AdminNavigation } from './AdminNavigation';
import { AdminCatchAll } from './AdminCatchAll';
import { CopyEditor, CopyItems } from './CopyEditor';
import GuideManager from './GuideManager/GuideManager';
import CopyPreview from './CopyEditor/CopyPreview';
import { useAuth } from '../../common/hooks/useAuth';
import { NotFound } from '../Errors';
import DesignerMetrics from './DesignerMetrics';
import { AnalyzerLogs, PdLogs } from './Debug';

const Admin = () => {
  const { details } = useAuth()
  const { scopes } = details || { scopes: [''] }
  const [showRoutes, setShowRoutes] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!details) {
      return;
    }

    //Need to harden this, it feels hacky
    setShowRoutes(!!details.routes.find((item) => item.path === '/admin'))

  }, [details, setShowRoutes])
  
  if(!showRoutes) {
    return (
        <Routes>
          <Route path='*' element={<NotFound />} />
        </Routes>
      )
  }

  return (
    <Box sx={{ display: 'flex', height: '100%', }}>
      <AdminNavigation />
      <Routes>
          {(scopes.includes('pd:admin:*') || scopes.includes('pd:admin:users')) && <Route path="/" element={<UserManagement />} />}
          {(scopes.includes('pd:admin:*') || scopes.includes('pd:admin:users')) && <Route path="/designer/metrics" element={<DesignerMetrics />} />}
          {scopes.includes('pd:admin:*') && <Route path="/dashboard" element={<PortfolioMapTool />} />}
          {(scopes.includes('pd:admin:*') || scopes.includes('pd:admin:users')) && <Route path="/users" element={<UserManagement />} />}
          {scopes.includes('pd:admin:*') && <Route path="/activation_codes" element={<ActivationCodes />} />}
          {scopes.includes('pd:admin:*') && <Route path="/tools/portfoliomap" element={<PortfolioMapTool />} />}
          {scopes.includes('pd:admin:*') && <Route path="/tools/global_custom_cma" element={<GlobalCustomCMA />} />}
          {scopes.includes('pd:admin:*') && <Route path="/copy" element={<CopyItems />} />}
          {scopes.includes('pd:admin:*') && <Route path="/copy/:copyId" element={<CopyEditor />} />}
          {scopes.includes('pd:admin:*') && <Route path="/copy/:copyId/preview" element={<CopyPreview />} />}
          {scopes.includes('pd:admin:*') && <Route path="/guides" element={<GuideManager />} />}
          {(scopes.includes('analyzer:admin') || scopes.includes('analyzer:admin:debug')) && <Route path="/analyzer/logs" element={<AnalyzerLogs source={'Analyzer'} />} />}
          {(scopes.includes('analyzer:admin') || scopes.includes('analyzer:admin:debug')) && <Route path="/pd/logs" element={<PdLogs source={'Pd'} />} />}
          {scopes.includes('pd:admin:*') && <Route path='*' element={<AdminCatchAll />} />}
      </Routes>
    </Box> 
  )
}

export default Admin