import { Box } from '@mui/material'
import React, { Component, FunctionComponent } from 'react'
import { CMAAsset, ReturnShiftForm } from '../../actions/types/customAssets'
import { getDisplayValue } from '../../common/utils'
import ConfirmModal from '../common/ConfirmModal'
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text'
import CalculatorRow from './CalculatorRow'

const HISTORICAL_MEAN_RETURN = 0.07

const generateDefaultState = (annualReturn = HISTORICAL_MEAN_RETURN) => {
    return {
        _1a: 0,
        _1b: 0,
        _1c: 0,
        _1d: annualReturn,
        _2a: 0,
        _2b: 0,
        _2c: 0,
        _2d: 0,
        _2e: annualReturn,
        _3a: 0,
        _3b: 0,
        _3c: 0,
        _3d: annualReturn,
        annualizedReturn: annualReturn,
        recalculate: undefined,
     }
}

interface ForecastShiftCalculatorProps {
    item?: CMAAsset
    visible: boolean
    onSave: (_3d: number, form: ReturnShiftForm) => void
    onCancel: () => void
}

interface ForecastShiftCalculatorState extends ReturnShiftForm {
    recalculate?: boolean
}

const StepContainer: FunctionComponent = ({ children }) => {
    return (
        <Box sx={{ marginTop: '5rem' }}>
            {children}
        </Box>
    )
}

export default class extends Component<ForecastShiftCalculatorProps, ForecastShiftCalculatorState> {

    constructor(props) {
        super(props)
        const { return_shift_form, annualizedReturn } = props.item || { }

        this.state = { ...generateDefaultState(annualizedReturn), ...(return_shift_form || { }) }
    }

    componentDidUpdate(prevProps, prevState) {
        const { visible: prevVisible, item: prevItem } = prevProps
        const { visible, item } = this.props

        if(item && prevItem != item || visible && !prevVisible) {
            const { return_shift_form, annualizedReturn } = this.props.item || { }
    
            this.setState({ ...generateDefaultState(annualizedReturn), annualizedReturn, ...(return_shift_form || { }) })
        }

        const { recalculate: prevRecalculate } = prevState
        const { recalculate } = this.state

        if(!prevRecalculate && recalculate) {
            this.setState({ recalculate: false })

            this.onCalculate()
        }
    }

    onCalculate = () => {
        const { _1a, _1b, _1c,
                _2a, _2b, _2c, _2d,
                _3b, _3c,
                annualizedReturn
              } = this.state
              
        const _1d = annualizedReturn + (_1a / 100) + (-_1b / 100) + (_1c / 100)
        const _2e = _1d + ((-_2a / 100) * (_2b / 100)) - ((_2c / 100) * (_2d / 100))
        const _3a =  _1d - (_2a / 100) - (_2c / 100)
        const _3d =  _2e - (_3a * _3b / 100 * _3c / 100)
        
        this.setState({ _1d, _2e, _3a, _3d })
    }

    onSave = () => {
        const { recalculate, ...calculations } = this.state

        if(this.props.onSave) {
            this.props.onSave(calculations._3d, calculations)
        }
    }

    render() {
        const { visible } = this.props
        const { _1a, _1b, _1c, _1d,
                _2a, _2b, _2c, _2d, _2e,
                _3a, _3b, _3c, _3d,
                annualizedReturn
              } = this.state
        
        return (
            <ConfirmModal
                visible={visible}
                sx={{ 
                    zIndex: 505,
                    "&::-webkit-scrollbar": {
                        '-webkit-appearance': 'none',
                        'width': '10px',
                    },
                    "&::-webkit-scrollbar-thumb": {
                    borderRadius: '5px',
                    backgroundColor: 'rgba(0,0,0,.5)',
                    '-webkit-box-shadow': '0 0 1px rgba(255,255,255,.5)',
                    },
                    font: FONT_STYLES_BY_TYPE.text
                }}
                title={`Forecast Shift Calculator`}
                confirmLabel={'Save'}
                cancelLabel={'Cancel'}
                onConfirm={this.onSave}
                onCancel={this.props.onCancel}>
                <Box sx={{ height: '50rem', paddingBottom: '5rem', overflowY: 'scroll', paddingLeft: '2.5rem', paddingRight: '2.5rem' }}>
                    <CalculatorRow title={'Historical Mean Return'} value={`${getDisplayValue(annualizedReturn * 100)}%`} bold readOnly />
                    <StepContainer>
                        <CalculatorRow stepTitle={'Step 1'} title={'Pre-Tax Adjustment'} bold underlineTitle header />
                        <CalculatorRow stepTitle={'1A'} title={'Secular Market Shift'} value={_1a} onChange={(value, focusLost) => this.setState({ _1a: value, recalculate: focusLost })}  />
                        <CalculatorRow stepTitle={'1B'} title={'Management Fees'} value={_1b} onChange={(value, focusLost) => this.setState({ _1b: value, recalculate: focusLost })}  />
                        <CalculatorRow stepTitle={'1C'} title={'Manager Alpha'} value={_1c} onChange={(value, focusLost) => this.setState({ _1c: value, recalculate: focusLost })} underlineTitle />
                        <CalculatorRow stepTitle={'1D'} title={'Pre-Tax Total Return'} value={`${getDisplayValue(_1d * 100)}%`} bold readOnly />
                    </StepContainer>
                    <StepContainer>
                        <CalculatorRow stepTitle={'Step 2'} title={'Dividend Tax Adjustment'} bold underlineTitle header />
                        <CalculatorRow stepTitle={'2A'} title={'Qualified Dividend Yield'} value={_2a} onChange={(value, focusLost) => this.setState({ _2a: value, recalculate: focusLost })}  />
                        <CalculatorRow stepTitle={'2B'} title={'Qualified Dividend Tax Rate'} value={_2b} onChange={(value, focusLost) => this.setState({ _2b: value, recalculate: focusLost })} />
                        <CalculatorRow stepTitle={'2C'} title={'Unqualified Dividend Yield'} value={_2c} onChange={(value, focusLost) => this.setState({ _2c: value, recalculate: focusLost })} />
                        <CalculatorRow stepTitle={'2D'} title={'Unqualified Dividend Tax Rate'} value={_2d} onChange={(value, focusLost) => this.setState({ _2d: value, recalculate: focusLost })}  underlineTitle />
                        <CalculatorRow stepTitle={'2E'} title={'Post-Dividend Tax Total Return'} value={`${getDisplayValue(_2e * 100)}%`} bold readOnly />
                    </StepContainer>
                    <StepContainer>
                        <CalculatorRow stepTitle={'Step 3'} title={'Capital Gains Tax Adjustment'} bold underlineTitle header />
                        <CalculatorRow stepTitle={'3A'} title={'Pre-Tax Price appreciation'} value={`${getDisplayValue(_3a * 100)}%`} readOnly />
                        <CalculatorRow stepTitle={'3B'} title={'Turnover'} value={_3b} onChange={(value, focusLost) => this.setState({ _3b: value, recalculate: focusLost })} />
                        <CalculatorRow stepTitle={'3C'} title={'Capital Gains Tax rate'} value={_3c} onChange={(value, focusLost) => this.setState({ _3c: value, recalculate: focusLost })} underlineTitle />
                        <CalculatorRow stepTitle={'3D'} title={'Post Dividend and C.G. Tax Return'} value={`${getDisplayValue(_3d * 100)}%`} bold readOnly />
                    </StepContainer>              
                </Box>
            </ConfirmModal>
        )
    }
}