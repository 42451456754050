import React, { Component } from 'react'
import { Asset } from '../../../actions/types/customAssets';
import ParameterList, { ParameterListProps } from '../../common/ParameterList';
import { PortfolioMapAsset } from '../../../actions/types/investmentPortfolio';
import AssesListRow from './AssesListRow';

interface Props extends ParameterListProps {
    onItemAdded: (type: string, parameterName: string) => void
    onItemChanged: (item: PortfolioMapAsset, value: PortfolioMapAsset) => void
    onItemRemoved: (type: string, item: PortfolioMapAsset, index: number) => void
    items: PortfolioMapAsset[]
    accountAssets: Asset[]
}

class AssetsList extends Component<Props> {

    constructor(props) {
        super(props)
    }

    onItemRemoved = (item: PortfolioMapAsset, index: number) => {
        const { type, onItemRemoved } = this.props

        if(onItemRemoved) {
            onItemRemoved(type, item, index)
        }
    }

    onRenderRow = (item: PortfolioMapAsset, index: number) => {
        const { onItemChanged, accountAssets } = this.props

        const key = `${item.id}_${item.asset_id}`
        return <AssesListRow 
                    key={key}
                    item={item}
                    index={index}
                    onItemRemoved={this.onItemRemoved}
                    onItemChanged={onItemChanged}
                    accountAssets={accountAssets}
                />
    }


    render() {
        const { accountAssets = [], ...restProps } = this.props
        return (
                <ParameterList
                    onRenderRow={this.onRenderRow}
                    {...restProps}
                />
        )
    }

}

export default AssetsList