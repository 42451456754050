import { Box } from '@mui/material'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Text from '../common/styled/Text'

export interface SLRGaugeProps {
    standardLivingRisk: number
}

class SLRGauge extends Component<SLRGaugeProps>{

    render() {
        const { standardLivingRisk = 0 } = this.props

        let displayValue: string | number = Math.round(standardLivingRisk)
        displayValue = `${displayValue}%`

        let progress = standardLivingRisk // 80 because 80% is the position that indicates 100%
            progress = isNaN(progress) ? 10 : progress
            progress = Math.min(progress, 100) //because 100% is the max position
            progress = Math.max(15, progress) //because 15% is minimum position

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                alignItems: 'center',
            }}>
                <Box sx={{
                    display: 'flex',
                    marginTop: '1rem',
                    background: (theme) => `${theme.palette.background.level2} 0% 0% no-repeat padding-box`,
                    border:`3px solid #6666CC40`,
                    borderRadius: '1.5rem',
                    height: '20rem',
                    width: '8rem',
                    position: 'relative',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}>
                   <Text type='text_tiny' sx={{ fontWeight: 'bold' }}>HIGH</Text>
                    <Box sx={{
                        display: 'flex',
                        borderRadius: '5rem',
                        position: 'absolute',
                        top: 20,
                        bottom: 20,
                        left: 15,
                        right: 15,
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        overflow: 'hidden',
                    }}>
                        <Box sx={{ 
                            background: (theme) => `${theme.palette.background.level10} 0% 0% no-repeat padding-box`,
                            borderRadius: '5rem',
                            width: '100%',
                            height: `${progress}%`,
                            paddingTop: progress > 20 ? '.5rem' : undefined
                            }}>
                                <Text sx={{ color: 'white', fontWeight: 'bold', paddingTop: '1rem' }}>{displayValue}</Text>
                        </Box>
                    </Box>
                    <Text type='text_tiny' sx={{ fontWeight: 'bold' }}>LOW</Text>
                </Box>
                <Box sx={{ marginTop: '1rem' }}><Text glossaryTerm={'ALM Risk'}>Asset-Liability<br />Risk</Text></Box>
            </Box>
        )
    }

}

const mapStateToProps = ({ standardLivingRisk }) => {
    const { standardLivingRisk: slr } = standardLivingRisk
    
    return {
        standardLivingRisk: slr
    }
}

export default connect(mapStateToProps)(SLRGauge)