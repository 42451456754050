import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'

import Alert, { AlertProps } from './Alert'

export default class SuccessAlert extends Component <AlertProps> {

    componentDidUpdate(prevProps) {
        const { visible: prevVisible } = prevProps
        const { visible } = this.props

        if(!prevVisible && visible) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }

    render() {
        const { children, className, titleIcon, sx = {}, ...restProps } = this.props
        
        return (
            <Alert 
                {...restProps}
                titleIcon={titleIcon ? titleIcon : () => <FontAwesomeIcon color={'#fff'}  icon={faBan} />}
                sx={{
                    height: 'unset !important',
                    width: '60rem',
                    backgroundColor: '#00CC00 !important',
                    color: 'white',
                    ...sx,
                }}>
                {children}
            </Alert>
        )
    }

}