import { Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { getAccount } from '../../actions/account';
import { Account, Advisor, Firm, Subscription } from '../../actions/types/account';
import { Page } from '../Page';
import { BaseProps } from '../types';
import AdvisorDetails from './AdvisorDetails';
import FirmDetails from './FirmDetails';
import SubscriptionDetails from './SubscriptionDetails';
import { AccountPanel } from './AccountPanel';
import Button from '../common/Button';
import { useAuth } from '../../common/hooks/useAuth';
import UpdatePassword from './UpdatePassword';
import OrionCredentials from './OrionCredentials';

const AccountRow: FunctionComponent<BaseProps> = ({ children }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: {
                xs: 'column',
                md: 'column'
            },
            marginBottom: '2.5rem'
        }}>
            {children}
        </Box>
    )
}

export const AccountComponent: FunctionComponent<BaseProps> = () => {
    const { details } = useAuth()
    const { scopes = [] } = details || { }

    const [{ advisor, firm, subscription }, setAccount] = React.useState<Account>({ })
    const getAccountData = async() => {
        const { success, firm, advisor, subscription } = await getAccount()
        if(success) {
            setAccount({ firm, advisor, subscription })
        }
    }

    const onAdvisorUpdated = React.useCallback((update: Advisor) => {
        const updatedItem: Advisor = { ...advisor, ...update }
        setAccount({ advisor: updatedItem, firm, subscription })
    }, [advisor, firm, subscription])

    const onFirmUpdated = React.useCallback((update: Firm) => {
        const updatedItem: Firm = { ...firm, ...update }
        setAccount({ firm: updatedItem, advisor, subscription })
    }, [advisor, firm, subscription])

    const onSubscriptionUpdate = React.useCallback((update: Subscription) => {
        const updatedItem: Subscription = { ...subscription, ...update }
        setAccount({ subscription: updatedItem, advisor, firm })
    }, [advisor, firm, subscription])

    React.useEffect(() => {
        getAccountData()
    }, [])

    return (
        <Page 
            contentsSx={{ 
            }}>
                <Box sx={{ display: 'flex', marginTop: '5rem', width: '100%', }}>
                    <AccountRow>
                        <AdvisorDetails {...(advisor ? advisor : {})} onAdvisorUpdated={onAdvisorUpdated} />
                        <UpdatePassword email={advisor?.email || ''} />
                        <SubscriptionDetails  {...(subscription ? subscription : {})} onSubscriptionUpdate={onSubscriptionUpdate} />
                        {scopes.includes('pd:portfolio_manager') && <OrionCredentials /> }
                        {scopes.includes('pd:keys:create') &&
                            <AccountPanel title={'Api Keys'}>
                                <Box sx={{ display: 'flex', height: '20rem', alignItems: 'center', justifyContent: 'center' }}>
                                    <Button 
                                        sx={{ width: '20rem', height: '4.5rem', marginRight: '.5rem' }}
                                        title={'Manage API Keys'}
                                        href='/software/keys'
                                    />
                                </Box>
                            </AccountPanel>
                        }
                    </AccountRow>
                    <AccountRow>
                        <FirmDetails {...(firm ? firm : {})} onFirmUpdated={onFirmUpdated} />
                    </AccountRow>
                </Box>
        </Page>
    )
}

export default AccountComponent