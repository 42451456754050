import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import SessionUserDetailsSync from '../common/SessionUserDetailsSync';

import NavBar from './NavBar';
import SiteFooter from './SiteFooter';
import { Home } from './Landing/Home';
import { About } from './Landing/About';
import { Pricing, SignUp } from './Landing/Plans';
import { Login } from './Login';
import { Software } from './Software';
import { ClientManagement } from './ClientManagement';
import { Print } from './Print';
import { Register } from './Login/Register';
import { Account } from './Account';
import { CustomAssets } from './CustomAssets';
import GuideComponent from './Guide/Guide';
import { HubspotChatbot } from './Hubspot/HubspotChatbot';
import { PrivacyPolicy, TermsAndConditions } from './TermsConditionsPrivacy';
import Contact from './Contact/Contact';
import { NotFound } from './Errors';
import ForgotPassword from './Login/ForgotPassword';
import ResetPassword from './Login/ResetPassword';
import { AuthProvider } from '../common/providers/AuthProvider';
import { RequireAuthWrapper } from '../common/RequireAuthWrapper';
import { BlogRedirect } from './Blog/BlogRedirect';
import Admin from './Admin';
import { RequiresValidToken } from '../common/RequiresValidToken';
import ClientRiskPreferencesExternal from './ClientRiskPreferences/ClientRiskPreferencesExternal';
import ClientRiskPreferences from './ClientRiskPreferences/ClientRiskPreferences';
import { TokenType } from '../actions/types/authentication';
import SingeUseSoftware from './SingeUseSoftware/SingeUseSoftware';
import SignupSuccess from './Landing/Plans/SignupSuccess';
import ClientFinancialPlan from './ClientFinancialPlan/ClientFinancialPlan';
import { useAuth } from '../common/hooks/useAuth';
import { Membership } from '../actions/types/users';
import ApiKeys from './ApiKeys';
import UpdatePassword from './Login/UpdatePassword';
import { UpdatePasswordPage } from './Login/UpdatePasswordPage';
import { FinancialPlanAssumptions } from './FinancialPlanAssumptions/FinancialPlanAssumptions';
import AssedEditPage from './CustomAssets/AssedEditPage';
import { RiskPreferences } from './RiskPreferences';

const App = () => {
  const { user, loading, details } = useAuth()
  const { membership } = user || {}
  const { scopes = [] } = details || { }

  return (
    <Router>
        <SessionUserDetailsSync excludeRoutes={['/client/questionnaire', '/enterprise/analyzer', '/software/clients/:clientId/plan' ]} />
        <NavBar excludeRoutes={['/client/questionnaire', '/enterprise/analyzer' ]} />
        <HubspotChatbot 
          routes={['/', '/about', '/pricing', '/signup', '/terms-and-conditions', '/privacy-policy', '/contact']}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/client/questionnaire" element={<RequiresValidToken type={TokenType.QUESTIONNAIRE}> <ClientRiskPreferencesExternal /> </RequiresValidToken>} />
          <Route path="/enterprise/analyzer" element={<RequiresValidToken type={TokenType.SINGLE_USE}> <SingeUseSoftware /> </RequiresValidToken>} />

          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/blog" element={<BlogRedirect />} />

          <Route path="/contact" element={<Contact />} />
          <Route path="/password/reset" element={<ForgotPassword />} />
          <Route path="/password/reset/:token" element={<ResetPassword />} />
          <Route path="/password/update" element={<UpdatePasswordPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {!loading &&
              <>
                  <Route path="/admin/*" element={<RequireAuthWrapper component={Admin}  />} />
                  <Route path="/guide" element={<RequireAuthWrapper component={GuideComponent}  /> } />
                  <Route path="/account" element={<RequireAuthWrapper component={Account}  /> } />
                  <Route path="/account/assumptions" element={<RequireAuthWrapper component={FinancialPlanAssumptions}  /> } />
                  <Route path="/account/assets" element={<RequireAuthWrapper component={CustomAssets}  /> } />
                  <Route path="/account/assets/:assetId" element={<RequireAuthWrapper component={AssedEditPage}  /> } />
                  
                  <Route path="/designer" element={<RequireAuthWrapper requiresMemberships={[Membership.PORTFOLIO_DESIGNER, Membership.SIMPLIFY_INTERNAL]} component={Software}  /> } />
                  <Route path="/dashboard" element={<RequireAuthWrapper requiresMemberships={[Membership.ADVISOR_WORKSTATION, Membership.SIMPLIFY_INTERNAL]} component={ClientManagement}  /> } />
                  <Route path="/research" element={<RequireAuthWrapper requiresMemberships={[Membership.ADVISOR_WORKSTATION, Membership.SIMPLIFY_INTERNAL]} component={Software}  /> } />
                  <Route path="/simplify/tools" element={<RequireAuthWrapper component={Software}  requiresScope={'pd:simplify:tools'} /> } />
                  <Route path="/software/clients/:clientId" element={<RequireAuthWrapper requiresMemberships={[Membership.ADVISOR_WORKSTATION, Membership.SIMPLIFY_INTERNAL]} component={Software}  /> } />
                  <Route path="/software/clients/:clientId/risk" element={<RequireAuthWrapper requiresMemberships={[Membership.ADVISOR_WORKSTATION, Membership.SIMPLIFY_INTERNAL]} component={ClientRiskPreferences}  /> } />
                  <Route path="/software/clients/:clientId/plan" element={<RequireAuthWrapper requiresMemberships={[Membership.ADVISOR_WORKSTATION, Membership.SIMPLIFY_INTERNAL]} component={ClientFinancialPlan}  /> } />
                  <Route path="/software/clients/:clientId/print" element={<RequireAuthWrapper requiresMemberships={[Membership.ADVISOR_WORKSTATION, Membership.SIMPLIFY_INTERNAL]} component={Print}  /> } />
        
                  {scopes.includes('pd:keys:create') &&  <Route path="/software/keys" element={<RequireAuthWrapper requiresMemberships={[Membership.CORE_API]} component={ApiKeys}  /> } /> }
                  <Route path='*' element={<NotFound />} />
              </>
          }
        </Routes>
        <SiteFooter />
      </Router> 
  )
}

export default App