import { Component } from "react"
import { connect } from 'react-redux'
import { ReduxState } from "../../../../reducers"
import { Box } from "@mui/material"
import Chart from "../../../common/Chart";
import { ChartOptions } from "../../../../common/types";
import { withWindowDimensions, WithWindowDimensionsProps } from "../../../common/withWindowDimensions";

export interface Props extends WithWindowDimensionsProps {
    cumReturnByAssetClassChartData: ChartOptions;
}
export interface State { }

class AssetClassTotalsByReturn extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
    }

    render() {
        const { cumReturnByAssetClassChartData } = this.props
        return (
            <Box>
                <Chart
                    id="cumReturnByAssetClassChartData" 
                    title={'Cumulative Asset Class Contributions'}
                    sx={{ 
                        width: { xs: this.props.dimensions.width - 100, lg: this.props.dimensions.width - 525} ,
                        height: '75rem',
                        backgroundColor: '#0000',
                        zIndex: 0,
                        position: 'relative',
                        border: (theme) => `.10rem ${theme.palette.primary.main} solid`,
                    }}
                    chartOptions={cumReturnByAssetClassChartData}
                />
            </Box>
        )
    }
}

const mapStateToProps = ({ performanceAttribution }: ReduxState ) => {
    const { cumReturnByAssetClassChartData } = performanceAttribution

    return { cumReturnByAssetClassChartData }
}

export default connect(mapStateToProps)(withWindowDimensions(AssetClassTotalsByReturn) as any as  any)