import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios'
import Highcharts from 'highcharts'

import StoreProvider from './store/StoreProvider';
import StoreThemeWrapper from './components/EntryWrapper/StoreThemeWrapper';
import App from './components/App';
import Maintenance from './components/Maintenance';
import { AuthProvider } from './common/providers/AuthProvider';

const IS_IN_MAINTENANCE = false;

//@ts-ignore
window.hsConversationsSettings = {
    loadImmediately: false
};

Highcharts.setOptions({
  credits: {
    enabled: false
  },
});

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Accept'] = 'application/json'

const store = StoreProvider.getStore()

export const bootstrap = (async () => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  return Promise.resolve(
    root.render(
      <React.StrictMode>
        <StoreThemeWrapper store={store}>
          <AuthProvider>
            {!IS_IN_MAINTENANCE && <App />}
            {IS_IN_MAINTENANCE && <Maintenance />}
          </AuthProvider>
        </StoreThemeWrapper>
      </React.StrictMode>
    )
  );
})();
