import axios from 'axios';
import { ApiKey, CreateKeysResult, ExpirationPeriod, RetrieveKeysResult } from "./types/keys";
import { StandardApiResponse } from "./types/responses";
import { SelectOption } from '../common/types';

const KEY_URL = `${process.env.REACT_APP_KEYS_API_URL || ''}/api/keys`

export const getApiKeys = async (): Promise<RetrieveKeysResult | StandardApiResponse > => {
    try {
        const { data } = await axios.get(KEY_URL)

        return data;
    } catch(e: any) {
        if (e?.response?.data?.message) {
            return { success: false, error: e?.response?.data?.message }
        } else {
            return { success: false, error: `An unexpected error occured, please try again` }
        }
    }
}

export const createApiKey = async (expirationPeriod: ExpirationPeriod): Promise<CreateKeysResult | StandardApiResponse> => {
    try {
        const { data } = await axios.post(KEY_URL, {
            expirationPeriod: expirationPeriod ? expirationPeriod : undefined
        })

        return data;
    } catch(e: any) {
        if (e?.response?.data?.message) {
            return { success: false, error: e?.response?.data?.message }
        } else {
            return { success: false, error: `An unexpected error occured, please try again` }
        }
    }
}

export const enableApiKey = async (params: ApiKey): Promise<StandardApiResponse> =>  {
    try {
        const { data } = await axios.post(`${KEY_URL}/${params?.id}/enable`)

        return data;
    } catch(e: any) {
        if (e?.response?.data?.message) {
            return { success: false, error: e?.response?.data?.message }
        } else {
            return { success: false, error: `An unexpected error occured, please try again` }
        }
    }
}

export const disableApiKey = async (params: ApiKey): Promise<StandardApiResponse> =>  {
    try {
        const { data } = await axios.post(`${KEY_URL}/${params?.id}/disable`)

        return data;
    } catch(e: any) {
        if (e?.response?.data?.message) {
            return { success: false, error: e?.response?.data?.message }
        } else {
            return { success: false, error: `An unexpected error occured, please try again` }
        }
    }
}
export const deleteApiKey = async (params: ApiKey): Promise<StandardApiResponse> =>  {
    try {
        const { data } = await axios.delete(`${KEY_URL}/${params?.id}`)

        return data;
    } catch(e: any) {
        if (e?.response?.data?.message) {
            return { success: false, error: e?.response?.data?.message }
        } else {
            return { success: false, error: `An unexpected error occured, please try again` }
        }
    }
}