import { Membership } from '../actions/types/users';
import { 
    PROFILE,
    SELECTED_KEY,
    ANNUAL_INCOME,
    RUNNING_CUSTOM_CMA_SCRIPT,
    CLIENT_DETAILS_COMPLETED,
    FIRM_WEBSITE,
    PAGE_CHANGE,
    PRINT_DETAILS_RECEIVED,
    LOGOUT,
    NAVBAR_HEIGHT,
    PROFILE_RESET,
    CLEAR_REGISTER_NAV_STATE,
    AUTH_TOKENS,
    GUIDES,
    LOGO_UPDATE,
    SESSION,
} from '../actions/types/actions';
import { CopyItem } from '../actions/types/copy';
import cookies from '../components/Software/cookies'

export interface SessionProfile {
    profile_id?: number
    annual_income?: number
    first_name?: string
    last_name?: string
    portfolio_manager?: string
    portfolio_manager_client_id?: number
}
export interface SessionState {
    csrfToken: string
    profile: SessionProfile
    error?: string
    clientDetails?: any
    firmWebsite?: string
    navbarHeight?: number
    defaultKey?: string
    selectedKey?: string
    guides: CopyItem[]
    logo?: string
    scopes: string[]
    views: string[]
    membership: Membership
    enterprise: boolean
}

const INITIAL_PROFILE_STATE = {
  profile: {
    profile_id: 0,
    annual_income: 100000,
    first_name: undefined,
    last_name: undefined,
  },
}

const INITIAL_STATE: SessionState = {
  ...INITIAL_PROFILE_STATE,
  csrfToken: '',
  error: undefined,
  clientDetails: undefined,
  firmWebsite: '',
  navbarHeight: undefined,
  defaultKey: '',
  selectedKey: localStorage.getItem(SELECTED_KEY) || undefined,
  guides: [],
  logo: undefined,
  scopes: [],
  views: [],
};

export default (state = INITIAL_STATE, action): SessionState => {
  const { payload, type } = action

  switch (type) {
    case AUTH_TOKENS:
        return { ...state, csrfToken: payload }
    case CLEAR_REGISTER_NAV_STATE:
        return { ...state, headerLogoOnly: false }
    case FIRM_WEBSITE:
        return { ...state, firmWebsite: payload }
    case PROFILE:
        return { ...state, profile: {...payload }}
    case PRINT_DETAILS_RECEIVED:
        return { ...state, profile: {...payload.client }}
    case SELECTED_KEY:
        return { ...state, selectedKey: payload }
    case ANNUAL_INCOME:
        return { ...state, profile: { ...state.profile, annual_income: payload } }
    case RUNNING_CUSTOM_CMA_SCRIPT:
        return { ...state, error: payload === true ? undefined : state.error}
    case CLIENT_DETAILS_COMPLETED:
        return { ...state, clientDetails: payload, profile: { ...state.profile, annual_income: payload } }
    case GUIDES:
        return { ...state, guides: payload }
    case LOGOUT:
        return { ...INITIAL_STATE }
    case PROFILE_RESET:
        return { ...state, ...INITIAL_PROFILE_STATE, defaultKey: state.defaultKey, selectedKey: state.defaultKey }
    case PAGE_CHANGE:
        return { ...state, [payload.tab]: payload.pageIndex }
    case NAVBAR_HEIGHT:
        return { ...state, navbarHeight: payload }
    case LOGO_UPDATE:
        return { ...state, logo: payload }
    case SESSION:
        return { ...state, ...payload }
    default:
        return state;
  }
};

