import React, { Component } from 'react'
import { connect } from 'react-redux';
import RiskAversion from '../RiskPreferences/RiskAversion'
import LossAversion from '../RiskPreferences/LossAversion'
import SubmitResult from './SubmitResult'

import { riskAversionQuestionUpdate, lossAversionQuestionUpdate, riskPreferencePage } from '../../actions/clientRiskPreferences'
import { PageContents } from '../Page/PageContents';
import PagerContainer from '../RiskPreferences/PagerContainer';
import Page from '../Page/Page';
import Footer from './Footer';
import { UpdateQuestionRequest } from '../../actions/types/requests';
import { onPageChange } from '../../actions/session';
import { AccountLogo } from '../common/styled/AccountLogo';
import { initialLoadClientRiskPreferences } from '../../actions/riskPreferences';
import { Box } from '@mui/material';

const tokenParameter = new URLSearchParams(window.location.search).get('token') || ''

export interface State {
    logo?: string
}

export interface ClientRiskPreferencesProps {
    initialLoadClientRiskPreferences: () => void
    riskPreferencePage: () => void
    riskAversionQuestionUpdate: (update: UpdateQuestionRequest) => void
    lossAversionQuestionUpdate: (update: UpdateQuestionRequest) => void
    onPageChange: (key: string, page: number) => void
    submitSuccess?: boolean
    firmWebsite?: string
    loading?: boolean
    activeIndex: number
}

class ClientRiskPreferences extends Component <ClientRiskPreferencesProps, State> {
    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
        this.props.initialLoadClientRiskPreferences()
    }

    componentWillUnmount(): void {
        this.props.onPageChange('clientQuestionnaire', 0)
    }

    onRiskAversionChoiceSelected = ({ questionNumber, value }) => {
        this.props.riskAversionQuestionUpdate({ questionNumber, value })
    }

    onLossAversionChoiceSelected = ({ questionNumber, value }) => {
        this.props.lossAversionQuestionUpdate({ questionNumber, value })
    }
    
    render() {
        const { activeIndex } = this.props

        return (
            <Page sx={{ 
                hight: '100%'
            }} contentsSx={{ display: 'flex', flexDirection: 'column', marginBottom: '5rem' }} disableResize>
                <Box sx={{ display: 'flex', mt: '5rem', mb: '5rem', justifyContent: 'center', alignItems: 'center', '& img': { maxWidth: 300, maxHeight: 60 } }}>
                    <AccountLogo />
                </Box>
                <PageContents sx={{ paddingBottom: '4rem' }}>
                    <PagerContainer activeIndex={activeIndex} gesturesEnabled={false}>
                        <RiskAversion footer={Footer} onChoiceSelected={this.onRiskAversionChoiceSelected} />
                        <LossAversion footer={Footer} onChoiceSelected={this.onLossAversionChoiceSelected} />
                        <SubmitResult />
                    </PagerContainer>
                </PageContents>
            </Page>
        )
    }
}

const mapStateToProps = ({ riskPreferences, session }) => {
    const { loading, submitSuccess } = riskPreferences
    const { firmWebsite, clientQuestionnaireActiveIndex } = session

    return {
        loading,
        activeIndex: clientQuestionnaireActiveIndex,
        submitSuccess,
        firmWebsite,
    }
}

export default connect(mapStateToProps, { onPageChange, initialLoadClientRiskPreferences, riskPreferencePage, riskAversionQuestionUpdate, lossAversionQuestionUpdate })(ClientRiskPreferences)