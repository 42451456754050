import React from 'react';
import Box from '@mui/material/Box';
import { ReportResourceItem } from '../../../actions/types/portfolioDesigner';
import {Row} from './Row';

export interface RowItem {
  section: number;
  items: ReportResourceItem[];
}

export interface CategoryItemsProps {
    category: string;
    items: ReportResourceItem[]
}

export const CategoryItems = ({items: propItems, category}: CategoryItemsProps) => {
  const [rows, setRows] = React.useState<RowItem[]>([]);

  React.useEffect(() => {
    const items = propItems.filter((item) => item.category === category);
    const sections = [...new Set(items.map((item) => item.section))].sort((a, b) => a - b);
    const rows = sections.map((section) => {
      return {
        section,
        items: items.filter((item) => item.section === section)
      };
    });

    setRows(rows);
  }, [propItems, category]);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {rows.map((item) => <Row key={`${category}-${item.section}`} items={item.items} />)}
    </Box>
  );
}