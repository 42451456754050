import React, { Component } from "react"
import { connect } from "react-redux"
import Panel from "../../common/Panel"
import { getAssetSets, createAssetSet, updateAssetSet, deleteAssetSet, addAssetToSet, removeAssetFromSet } from "../../../actions/adminCustomCMA"
import AssetsTable from "./AssetsTable"

import Button from "../../common/Button";
import AddEditModal from "./AddEditModal"
import ConfirmDeleteSetModal from "./ConfirmDeleteSetModal"
import { BaseProps } from "../../types"
import { Asset, AssetSet } from "../../../actions/types/customAssets"
import { Box } from "@mui/material"
import { CircleActionButton } from "../../common/styled/ShapedActionButton"
import { Select } from "../../common/styled/Select"
import { AddRemoveAssetRequest } from "../../../actions/types/requests"
import { AdminPage } from "../AdminPage"
import Text from "../../common/Text"

interface State {
  selectedAssetSet: any,
  selectedAsset: any,
  showConfirmDeleteModal: boolean,
  showAddEditModal: boolean,
  createMode: boolean
}

interface Props extends BaseProps {
  getAssetSets: () => void
  createAssetSet: (name: string) => void
  updateAssetSet: (item: AssetSet) => void
  deleteAssetSet: (item: AssetSet) => void
  addAssetToSet: (params: AddRemoveAssetRequest) => void
  removeAssetFromSet: (params: AddRemoveAssetRequest) => void
  asset_set: AssetSet
  assets: Asset[]
  asset_sets: AssetSet[]
}

class GlobalCustomCMA extends Component<Props, State> {

  constructor(props) {
    super(props)

    this.state = {
      selectedAssetSet: undefined,
      selectedAsset: undefined,
      showConfirmDeleteModal: false,
      showAddEditModal: false,
      createMode: false
    }
    this.props.getAssetSets()
  }

  componentDidUpdate(prevProps) {
    const { asset_sets: prev_asset_sets } = prevProps
    const { asset_sets } = this.props
    const { selectedAssetSet } = this.state

    if(selectedAssetSet && (prev_asset_sets != asset_sets)) {
      const updatedSelectedAssetSet = asset_sets.filter(({ id }) => id == selectedAssetSet.id)

      if(updatedSelectedAssetSet.length == 1) {
        this.setState({ selectedAssetSet: updatedSelectedAssetSet[0] })
      }
    }
  }

  onAddSet = () => {
    this.setState({ showAddEditModal: true, createMode: true })
  }
  
  onEditSet = () => {
    this.setState({ showAddEditModal: true, createMode: false })
  }

  onDeleteSet = () => {
    this.setState({ showConfirmDeleteModal: true }) 
  }

  onAddEditSetConfirmed = (name, createMode) => {
    if(createMode) {
      this.props.createAssetSet(name)
      this.setState({ selectedAssetSet: null, showAddEditModal: false })
    }

    if(!createMode) {
      const selectedAssetSet = { ...this.state.selectedAssetSet, name, label: name }
      this.setState({ selectedAssetSet: null, showAddEditModal: false }, () => this.setState({ selectedAssetSet }))
      this.props.updateAssetSet(selectedAssetSet)
    }
  }

  onDeleteSetConfirmed = () => {
    this.props.deleteAssetSet(this.state.selectedAssetSet)
    this.setState({ selectedAssetSet: null, showConfirmDeleteModal: false })
  }

  onAddAsset = () => {
    const { selectedAssetSet = { }, selectedAsset: { id: assetId } } = this.state
    const { id: assetSetId, assets = [] } = selectedAssetSet
    
    const matchingAssets = assets.filter(({ id }) => id == assetId)
    
    if(matchingAssets.length > 0) {
      alert('That Asset has already been added')

      return
    }

    this.props.addAssetToSet({ assetSetId,  assetId })
  }

  onRemoveAsset = (assetId) => {
    const { selectedAssetSet: { id: assetSetId } } = this.state

    this.props.removeAssetFromSet({ assetSetId,  assetId })
  }

  onAssetSetChanged = (item) => {
    this.setState({ selectedAssetSet: item })
  }

  onSelectedAssetChanged = (item) => {
    this.setState({ selectedAsset: item })
  }

  render() {
    const { asset_sets, assets } = this.props
    const { selectedAssetSet, selectedAsset, showConfirmDeleteModal, showAddEditModal, createMode } = this.state
    const { name = '', assets: assetSetAssets = [] } = selectedAssetSet || { }
    
    return(
        <AdminPage>
          <AddEditModal visible={showAddEditModal} createMode={createMode} name={createMode ? '' : name} onSave={this.onAddEditSetConfirmed} onCancel={() => this.setState({ showAddEditModal: false })} />
          <ConfirmDeleteSetModal visible={showConfirmDeleteModal} item={selectedAssetSet} onConfirm={this.onDeleteSetConfirmed} onCancel={() => this.setState({ showConfirmDeleteModal: false }) } />
            <Box sx={{
              minWidth: '30rem',
              maxWidth: '60rem',
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }}>
            <Text type={'h2'}>Custom CMA's</Text>
              <Box sx={{
                display: 'flex',
                marginRight: '1rem',
              }}>
                <Select sx={{ width: '25rem', marginRight: '1rem' }} placeholder={'Select Custom Asset Set...'} options={asset_sets} value={selectedAssetSet} onChange={this.onAssetSetChanged}/>
                <CircleActionButton 
                  type={'filled'}
                  action={'add'}
                  onClick={this.onAddSet}
                  sx={{ marginRight: '1rem' }}
                />
                <CircleActionButton 
                  type={'filled'}
                  action={'edit'}
                  disabled={!selectedAssetSet}
                  onClick={this.onEditSet}
                  sx={{ marginRight: '1rem' }}
                />
                <CircleActionButton 
                  type={'filled'}
                  action={'delete'}
                  disabled={!selectedAssetSet}
                  onClick={this.onDeleteSet}
                />
              </Box>
              
              <Box sx={{
                display: 'flex',
                marginTop: '1rem',
                marginRight: '1rem'
              }}>
                <Select sx={{ width: '25rem', marginRight: '1rem' }} placeholder={'Select Asset...'} isDisabled={!selectedAssetSet} value={selectedAsset} options={assets} onChange={this.onSelectedAssetChanged}/>
                <Button disabled={!selectedAsset} title={'Add'} onClick={this.onAddAsset} />
              </Box>
            </Box>
            <Box>
              <AssetsTable items={assetSetAssets} onRemoveAsset={this.onRemoveAsset} />
            </Box>
        </AdminPage>
    )
  }
}



const mapStateToProps = ({ capitalMarketAssumptions }) => {
  const { asset_sets, assets } = capitalMarketAssumptions

  return { asset_sets, assets }
}

export default connect(mapStateToProps, { getAssetSets, createAssetSet, updateAssetSet, deleteAssetSet, addAssetToSet, removeAssetFromSet })(GlobalCustomCMA)