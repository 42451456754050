import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { Box, List, ListItem } from '@mui/material';
import Text from '../../common/Text'
import { Select } from '../../common/styled/Select';
import { TextField } from '../../common/FormField';
import Button from '../../common/Button';
import { ParameterMapping } from '../../../actions/types/portfolioMap';
import { SelectOption } from '../../../common/types';

interface Props {
    items: ParameterMapping[]
    onItemAdded: (parameter: string, from: string, to: string) => void
    onItemRemoved: (item: ParameterMapping) => void
}

interface State {
    parameterItem?: SelectOption,
    from: string
    to: string
}
class ParameterMappings extends Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            from: '',
            to: ''
        }
    }

    onItemAdded = () => {
        const { onItemAdded } = this.props
        const { parameterItem, from, to } = this.state
        if(!parameterItem) {
            return
        }

        if(onItemAdded) {
            onItemAdded(`${parameterItem.value}`, from, to)
            this.setState({ from: '', to: '' })
        }
    }

    onItemRemoved = (item) => {
        const { onItemRemoved } = this.props

        if(onItemRemoved) {
            onItemRemoved(item)
        } 
    }

    onFromChanged = (e) => {
        this.onMappingChanged('from', e)
    }
    onToChanged =  (e) => {
        this.onMappingChanged('to', e)
    }

    onMappingChanged = (type: 'from' | 'to', e: any) => {
        const value = e.target.value as string

        this.setState({ [type]: value })
    }

    onParameterTypeChanged = (item) => {
        this.setState({ parameterItem: item })
    }


    render() {
        const { items = [] } = this.props
        const { parameterItem, from, to } = this.state

        return (
                <Box sx={{
                    flex: 1,
                    margin: '1rem',
                    flexDirection: 'column',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>
                        <Box sx={{
                            marginRight: '2rem',
                        }}>
                                <Text>Parameter</Text>
                                <Select sx={{ width: '15rem' }} value={parameterItem} options={[{ label: 'Gamma', value: 'gamma'}, { label: 'Lambda', value: 'lambda' }]} onChange={this.onParameterTypeChanged}/>
                        </Box>
                        <Box sx={{
                            marginRight: '2rem',
                        }}>
                                <Text>From</Text>
                                <TextField type="text" className='ParameterInput' value={from} onChange={this.onFromChanged} />
                        </Box>
                        <Box sx={{
                            marginRight: '2rem',
                        }}>
                                <Text>To</Text>
                                <TextField type="text" className='ParameterInput' value={to} onChange={this.onToChanged} />
                        </Box>
                        <Box sx={{
                            marginTop: '2rem',
                        }}>
                                <Button autoFit onClick={this.onItemAdded}>Add</Button>
                        </Box>
                    </Box>
                    <List sx={{
                        overflowY: 'scroll',
                        height: '25rem',
                    }}>
                        {items.map((item, index) => {
                            const { parameter, from, to } = item
                            const parameterLabel = `${parameter.substring(0,1).toUpperCase()}${parameter.substring(1)}`

                            return(
                                <ListItem key={`${index}-${item}`} sx={{ position: 'relative', border: (theme) => `1px solid ${theme.palette.primary.main}`, padding: '1.5rem' }}>
                                    <Box>
                                        <Text>{parameterLabel}: </Text>
                                        <Text sx={{ marginRight: '1rem', marginLeft: '1rem' }}>{from}</Text>
                                        <FontAwesomeIcon icon={faArrowRight} />
                                        <Text sx={{ marginRight: '1rem', marginLeft: '1rem' }}>{to}</Text>
                                    </Box>
                                    
                                    <Box 
                                        sx={{ 
                                            position: 'absolute',
                                            top: '1.5rem',
                                            bottom: 0,
                                            right: '2rem',
                                            display: 'flex',
                                            cursor: 'pointer',
                                            '-webkit-appearance': 'button',
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} onClick={() => this.onItemRemoved(item)}/>
                                    </Box>
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
        )
    }

}

export default ParameterMappings