import React, { Component } from 'react'
import { connect } from 'react-redux';

import { riskAversionQuestionUpdate, lossAversionQuestionUpdate, riskPreferencePage } from '../../actions/clientRiskPreferences'
import { clientDetailsCompleted, updateSingleUseSoftware } from '../../actions/singeUseSoftware'
import SingeUseQuestionnaire from './SingeUseQuestionnaire'
import EnterClientDetails from './EnterClientDetails';
import { TermsAndConditionsModal } from '../TermsConditionsPrivacy';
import Page from '../Page/Page';
import { HeaderLogo } from '../common/styled/HeaderLogo';
import { Client } from '../../actions/types/clientManagement';
import { UpdateQuestionRequest } from '../../actions/types/requests';
import { getTokenLogo } from '../../actions/session';
import { TokenType } from '../../actions/types/authentication';
import { logoHorizontal } from '../../assets';

const tokenParameter = new URLSearchParams(window.location.search).get('token') || ''

export interface SingleUseSoftwareProps {
    updateSingleUseSoftware: () => void
    riskPreferencePage: () => void
    riskAversionQuestionUpdate: (update: UpdateQuestionRequest) => void
    lossAversionQuestionUpdate: (update: UpdateQuestionRequest) => void
    clientDetailsCompleted: (client: Client) => void
    clientDetails: Client
}

export interface SingleUseSoftwareState {
    showTermsDialog: boolean
    acceptedTerms: boolean
    logo?: string
}

class SingeUseSoftware extends Component<SingleUseSoftwareProps, SingleUseSoftwareState> {
    constructor(props: SingleUseSoftwareProps) {
        super(props)
        this.state = {
            showTermsDialog: false,
            acceptedTerms: false,
        }
    }

    componentDidUpdate(prevProps: Readonly<SingleUseSoftwareProps>, prevState: Readonly<SingleUseSoftwareState>, snapshot?: any): void {
        if(!prevState.acceptedTerms && this.state.acceptedTerms) {
            this.props.updateSingleUseSoftware()
        }
    }

    async componentDidMount(): Promise<void> {
        const { logo } = await getTokenLogo(tokenParameter, TokenType.SINGLE_USE)
        this.setState({ logo: logo || logoHorizontal })
        setTimeout(() => {
            this.setState({ showTermsDialog: true })
        }, 100)
    }

    onRiskAversionChoiceSelected = ({ questionNumber, value }) => {
        this.props.riskAversionQuestionUpdate({ questionNumber, value })
    }

    onLossAversionChoiceSelected = ({ questionNumber, value }) => {
        this.props.lossAversionQuestionUpdate({ questionNumber, value })
    }

    checkTermsAgreed = () => {
        if(!this.state.acceptedTerms) {
            alert('You cannot proceed without accepting the terms and conditions')

            return
        }

        this.setState({ showTermsDialog: false })
    }
    
    render() {
        const { clientDetails } = this.props
        const { showTermsDialog, acceptedTerms, logo } = this.state
        const canShowViews = !showTermsDialog && acceptedTerms

        return (
                <Page sx={{ 
                    hight: '100%'
                }} contentsSx={{ display: 'flex', flexDirection: 'column' }} disableResize>
                    {logo && 
                        <HeaderLogo 
                            linkUrl={`${process.env.REACT_APP_API_URL}/api/enterprise/link?token=${tokenParameter}`}
                            logoUrl={logo}
                        />
                    }
                    <TermsAndConditionsModal visible={showTermsDialog} onTermsAccepted={() => this.setState({ acceptedTerms: true, showTermsDialog: false })} />
                    {canShowViews && !clientDetails && <EnterClientDetails onDetailsCompleted={this.props.clientDetailsCompleted} /> }
                    {canShowViews && clientDetails && <SingeUseQuestionnaire />}
                </Page>
        )
    }
}

const mapStateToProps = ({ riskPreferences, session }) => {
    const { clientDetails, selectedKey } = session
    const { loading } = riskPreferences
    
    return {
        loading,
        selectedKey,
        clientDetails,
    }
}

export default connect(mapStateToProps, { updateSingleUseSoftware, riskPreferencePage, riskAversionQuestionUpdate, lossAversionQuestionUpdate, clientDetailsCompleted })(SingeUseSoftware)