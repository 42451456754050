import React, { Component } from 'react'
import { connect } from 'react-redux'

import Label from '../common/Label'
import { runRiskAnalyzerResults } from '../../actions/riskPreferences'

import RiskPage from './RiskPage'
import Chart from '../common/Chart';
import { RiskResultsProps, RiskResultsState } from './RiskResults';
import { Box } from '@mui/material';
import RiskAnalyzerCharts from '../OptimizedPortfolio/RiskAnalyzerCharts';
import { PageDivider } from '../common/styled/PageDivider';
import { ScriptRunning } from '../OptimizedPortfolio/ScriptRunning';
import Text from '../common/styled/Text';
import { Select } from '../common/styled/Select'
import { RunRiskAnalyzerResultsRequest } from '../../actions/types/requests'

interface RiskAnalyzerResultsProps extends RiskResultsProps {
    runRiskAnalyzerResults: (options?: RunRiskAnalyzerResultsRequest) => void
}

class RiskAnalyzerResults extends Component <RiskAnalyzerResultsProps, RiskResultsState> {

    constructor(props) {
        super(props)
        this.state = {
            showGauges: false
        }
    }

    componentDidMount() {
        this.props.runRiskAnalyzerResults()
    }

    onOpAssetSetSelectionChange = ({ id: asset_set_id }) => {
        this.props.runRiskAnalyzerResults({ asset_set_id, renderCharts: false })
    }

    render() {
        const { readOnly, gammaMeasured, lambdaMeasured, holdings, asset_sets, analyzerAllocatioChartOptions, scriptRunning,
                riskAversionRiskLevelsChart, lossAversionRiskLevelsChart, currentSetSelection, onComponentSize } = this.props

        return (
            <RiskPage readOnly={readOnly} onComponentSize={onComponentSize}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    width: '100%',
                }}>
                    <RiskAnalyzerCharts 
                        gamma={gammaMeasured}
                        lambda={lambdaMeasured}
                        riskAversionRiskLevelsChart={riskAversionRiskLevelsChart}
                        lossAversionRiskLevelsChart={lossAversionRiskLevelsChart}
                    />
                    <PageDivider />
                    <Box sx={{
                        position: 'relative',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        padding: '1rem',
                        minWidth: '70rem',
                    }}>
                        {scriptRunning && <ScriptRunning />}

                        {!scriptRunning &&
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '1rem',
                                flex: 1,
                            }}>
                                <Box sx={{ marginLeft: '4rem', marginRight: '4rem' }}>
                                    <Text type={'h4'}>Capital Market Assumptions</Text>
                                    {readOnly && <Box><Label title={currentSetSelection ? currentSetSelection.label : ''} /></Box>}
                                    {!readOnly && <Select options={asset_sets} value={currentSetSelection} onChange={this.onOpAssetSetSelectionChange} />}
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Chart id="analyzerAllocatioChart" sx={{ width: '45rem', backgroundColor: '#0000', zIndex: 0 }} chartOptions={analyzerAllocatioChartOptions} />
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}>
                                    {holdings.map((item) => {
                                        const { title, value } = item
                                        const valuePercent = (value || 0) * 100
                                        return (
                                            <Box sx={{
                                                marginTop: '1rem',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}>
                                                <Text sx={{ fontWeight: 'bold' }}>{title}</Text>
                                                <Text sx={{ textAlign: 'end', paddingLeft: '.5rem' }}>{(valuePercent).toFixed(2)}%</Text>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </RiskPage>
        )
    }

}

const mapStateToProps = ({ riskPreferences, investmentPortfolio, capitalMarketAssumptions }) => {
    const { gammaMeasured, lambdaMeasured, scriptRunning, riskAversionRiskLevelsChart, lossAversionRiskLevelsChart } = riskPreferences
    const { holdings, portfolioMaps, opt_asset_set_id, analyzerAllocatioChartOptions } = investmentPortfolio
    const { asset_sets = [] } = capitalMarketAssumptions

    let currentSetSelection = asset_sets.find(({ id }) => opt_asset_set_id == id)
    if(!opt_asset_set_id && asset_sets && asset_sets.length > 0) {
        currentSetSelection = asset_sets[0]
    }
    
    return {
        scriptRunning,
        gammaMeasured,
        lambdaMeasured,
        holdings,
        asset_sets,
        portfolioMaps,
        currentSetSelection,
        analyzerAllocatioChartOptions,
        riskAversionRiskLevelsChart,
        lossAversionRiskLevelsChart,
    }
}

export default connect(mapStateToProps, { runRiskAnalyzerResults })(RiskAnalyzerResults)