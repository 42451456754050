import {
    PROFILE_RESET,
    LOGOUT,
    FORECAST_UPDATED,
} from '../actions/types/actions';
import { EquityForecast } from '../actions/types/forecast';

export interface ForecastState extends EquityForecast { }


const INITIAL_STATE: ForecastState = { };

export default (state = INITIAL_STATE, action: any): ForecastState => {
  const { payload, type } = action

  switch (type) {
    case FORECAST_UPDATED:
        return { ...state, ...payload }
    case PROFILE_RESET:
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

