import { RISK_PREFERENCE_PAGE_INDEX, RISK_PREFERENCES_LOADING, RISK_PREFERENCES_MEASURED_UPDATE, MEASURED_PARAMETER_UPDATE, COMPLETE_QUESTION_UPDATE, RISK_AVERSION, LOSS_AVERSION, ANNUAL_INCOME, SUBMIT_SUCCESS, STANDARD_LIVING_RISK, PROFILE, RISK_LEVEL_AVERAGES, RUNNING_PROFILE_SCRIPT, CLIENT_DETAILS_COMPLETED, LOGOUT, PROFILE_RESET, PRINT_DETAILS_RECEIVED } from '../actions/types/actions';
import { LossAversionQuestions, RiskAversionQuestions, RiskParameterLevelAverages, DEFAULT_PREFERENCE_VALUES } from '../actions/types/riskPreferences';

export interface RiskPreferencesState {
    lossAversion: LossAversionQuestions
    riskAversion: RiskAversionQuestions
    activeIndex: number
    standardLivingRisk: number
    riskLevelAverages: RiskParameterLevelAverages
    annualIncome: number
    gammaMeasured: number
    lambdaMeasured: number
}

const INITIAL_STATE: RiskPreferencesState = {
    ...DEFAULT_PREFERENCE_VALUES,
    activeIndex: 0,
    standardLivingRisk: 0,
    riskLevelAverages: {
        lambda: {
            LOW: 0,
            MED: 0,
            HIGH: 0,
        },
        gamma: {
            LOW: 0,
            MED: 0,
            HIGH: 0,
        },
    },
    annualIncome: 0,
    gammaMeasured: 0,
    lambdaMeasured: 0,
};

export default (state = INITIAL_STATE, action): RiskPreferencesState => {
  const { payload, type } = action

  switch (type) {
    case CLIENT_DETAILS_COMPLETED:
        return { ...state, annualIncome: payload.annual_income }
    case ANNUAL_INCOME:
        return { ...state, annualIncome: payload }
    case PROFILE:
        return { ...state, annualIncome: payload && payload.annual_income ? payload.annual_income : 0 }
    case PRINT_DETAILS_RECEIVED:
        return { ...state, annualIncome: payload && payload.annual_income ? payload.annual_income : 0 }
    case COMPLETE_QUESTION_UPDATE:
        return { ...state, ...payload }
    case RISK_AVERSION:
        return { ...state, riskAversion: {...state.riskAversion, [`question${payload.questionNumber}Value`]: payload.value } }
    case LOSS_AVERSION:
        return { ...state, lossAversion: {...state.lossAversion, [`question${payload.questionNumber}Value`]: payload.value } }
    case MEASURED_PARAMETER_UPDATE:
        return { ...state, ...payload }
    case RISK_PREFERENCE_PAGE_INDEX:
        return { ...state, activeIndex: payload }
    case RISK_PREFERENCES_MEASURED_UPDATE:
        return { ...state, loading: false, ...payload }
    case STANDARD_LIVING_RISK: 
        return { ...state, standardLivingRisk: payload }
    case RISK_LEVEL_AVERAGES:
        return { ...state, riskLevelAverages: payload}
    case PROFILE_RESET:
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

