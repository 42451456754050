import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import AlertContainer from '../AlertContainer/AlertContainer'
import BasePage from './PageContainer'
import { BaseProps } from '../types';

const HEIGHT_OFFSET = 150
interface PageProps extends BaseProps {
    contentsSx?: SxProps<Theme> 
    innerRef?: any
    alertViews?: any[],
    alertChildren?: any[],
    disableResize?: boolean
}

interface PageState {
    height: number
}

export class Page extends React.Component<PageProps, PageState> {
    private viewRef: any;

    constructor(props) {
        super(props)
        this.state = {
            height: this.getComponentHeight(),
        }
        this.viewRef = React.createRef()
        if(typeof(this.props.innerRef) === 'function') {
            this.props.innerRef(this)
        }
    }

    componentDidUpdate() {
        const { height } = this.state

        if(typeof(this.props.innerRef) === 'function') {
            this.props.innerRef(this)
        }

        if(height !== this.getComponentHeight()) {
            this.handleResize()
        }
    }

    getComponentHeight = () => {
        const { innerHeight: height } = window;

        return height - HEIGHT_OFFSET
    }

    handleResize = () => {
        const height = this.getComponentHeight()

        this.setState({ height })
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize)
    }

    scrollToTop = () => {
        this.viewRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }

    render() {
        const { children, sx = {}, contentsSx = {}, alertViews, alertChildren, disableResize = false } = this.props
        const { height } = this.state

        return (
            <BasePage
                innerRef={this.viewRef} 
                sx={{
                    maxHeight: disableResize ? '100%' : `${height}px`,
                    background: '#ecf0f5',
                    justifyContent: 'unset',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                    ...sx
                }}>
                <AlertContainer alertViews={alertViews} alertChildren={alertChildren} />
                <Box sx={{
                    display: 'flex',
                    flex: 1,
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#ecf0f5',
                    paddingLeft: '2.5rem',
                    paddingRight: '2.5rem',
                    marginRight: '5rem',
                    marginLeft: '2.5rem',
                    ...contentsSx,
                }}>
                    {children}
                </Box>
            </BasePage>
        )
    }
}

export default Page