import { AuthenticatedUser, RefreshResponse, UserDetails } from '../../actions/types/users';
import { AuthenticationParams } from '../context';
import { useAuth } from './useAuth';

export interface withAuthProps {
  authenticated: boolean
  user?: AuthenticatedUser
  userDetails?: UserDetails
  loading: boolean
  setAuthentication: (authenticationParams: AuthenticationParams) => void
  refresh: () => Promise<RefreshResponse>
}

export const withAuth = (Component: any) => {
  const Wrapper = (props: any) => {
    const { authenticated, user, details, loading, setAuthentication, refresh } = useAuth();

    return (
      <Component
        {...props}
        setAuthentication={setAuthentication}
        authenticated={authenticated}
        user={user}
        userDetails={details}
        loading={loading}
        refresh={refresh}
        />
    );
  };
  
  return Wrapper;
};