import React, {FC} from 'react';
import TickerToolTipContainer from '../../../components/PortfolioDesigner/TickerToolTipContainer';
import ListRow, { RowType } from './ListRow';
import { MetricDataMapped, SinglePortfolioSnapshotResultsDTO, SnapshotItems, Ticker } from '../../../actions/types/portfolioDesigner';
import { connect } from 'react-redux';
import { runSinglePortfolioSnapshot } from '../../../actions/portfolioDesigner';
import { ReduxState } from '../../../reducers';
import { getTickerSymbolAndDescription } from '../../../common/utils';

interface ComponentProps {
    ticker: string;
    checked: boolean;
    onEdit?: (item: string) => void;
    onRemove?: (item: string) => void;
    onCheckChanged?: (item: string, checked: boolean) => void;
}

interface ReduxStateProps {
    tickerItem?: Ticker;
}

type Props = ComponentProps & ReduxStateProps;

const TickerRow: FC<Props> = ({onCheckChanged, onRemove, onEdit, ticker, tickerItem, checked}) => {
  const [metrics, setMetrics] = React.useState<MetricDataMapped>({});
  const [snapshots, setSnapshots] = React.useState<SnapshotItems>({});
  const [snapshotLoading, setSnapshotLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setSnapshotLoading(true);
    const runSnapshot = async() => {
      if (!tickerItem) {
        return;
      }
      const { symbol } = getTickerSymbolAndDescription(tickerItem);
      const result = await runSinglePortfolioSnapshot({
        ids: { [tickerItem.id.toString() ?? '']:  symbol ?? '' },
        weights: { [symbol ?? '']: 1 }
      });

      setSnapshots((result as SinglePortfolioSnapshotResultsDTO).snapshots);
      setMetrics((result as SinglePortfolioSnapshotResultsDTO).metrics);
      
      setSnapshotLoading(false);
    }

    runSnapshot()
  }, [tickerItem]);

  return (
    <TickerToolTipContainer
        key={`${ticker}-${tickerItem?.id}`}
        ticker={tickerItem}
        snapshots={snapshots}
        metrics={metrics}
        snapshotLoading={snapshotLoading}
        showMCTR={false}
    >
        <ListRow type={RowType.CHECKBOX} item={ticker} onEdit={onEdit} checked={checked} onCheckChanged={onCheckChanged} onRemove={onRemove} showChecked showEdit showRemove/>
    </TickerToolTipContainer>
  );
}

const mapStateToProps = ({dueDiligence}: ReduxState, {ticker}: ComponentProps) => ({
  tickerItem: dueDiligence.tickers[ticker],
});

export default connect(mapStateToProps, {})(TickerRow);