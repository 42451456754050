import React, { Component, FunctionComponent } from 'react'
import { Box } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import ConfirmDelete from '../../common/ConfirmDelete'
import { AdminPage } from '../AdminPage'
import { CopyItem } from '../../../actions/types/copy'
import { addCopy, deleteCopy, getCopy } from '../../../actions/adminCopyEditor'
import Button from '../../common/Button'
import Text from '../../common/Text'
import { DataTable } from '../../common/DataTable'
import AddCopyModal from './AddCopyModal'
import { withRouter, WithRouterProps } from '../../common/withRouter'
import { connect } from 'react-redux'

interface CopyItemRow {
    id?: number
    name: string
    title: string
    systemManaged: React.ReactNode
    actions: React.ReactNode
}

interface Props extends WithRouterProps {
    getCopy: () => void
    addCopy: (name: string, title: string) => Promise<boolean>
    deleteCopy: (item: CopyItem) => void
    items: CopyItem[]
}

interface State {
  rows: CopyItemRow[]
  confirmDeleteItem?: CopyItem
  showAddCopyItem: boolean
}

interface ActionProps {
    onEdit: (copy: CopyItem) => void
    onDelete: (copy: CopyItem) => void
    onPreview: (copy: CopyItem) => void
    item: CopyItem
}


const Actions:FunctionComponent<ActionProps>  = ({ item, onEdit, onDelete, onPreview }) => {

    const onEditPressed = React.useCallback(() => {
        onEdit(item)
    }, [item, onEdit])
    
    const onPreviewPressed = React.useCallback(() => {
        onPreview(item)
    }, [item, onPreview])

    const onDeletePressed = React.useCallback(() => {
        onDelete(item)
    }, [item, onDelete])

    return (
        <Box sx={{ '& svg': { cursor: 'pointer' } }}>
            <FontAwesomeIcon style={{ marginRight: '.5rem' }} color={'#297ea1'} icon={faEye} onClick={onPreviewPressed} />
            <FontAwesomeIcon style={{ marginRight: '.5rem' }} color={'#297ea1'} icon={faEdit} onClick={onEditPressed} />
            <FontAwesomeIcon color={'#297ea1'} icon={faTrash} onClick={onDeletePressed} />
        </Box>
    )
}

class CopyItems extends Component<Props, State> {

    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            confirmDeleteItem: undefined,
            showAddCopyItem: false,
        }
    }

    componentDidMount() {
        this.props.getCopy()
    }

    componentDidUpdate(prevProps) {
        const { items: prevItems } = prevProps
        const { items } = this.props

        if(prevItems !== items) {
            const rows = this.formatRows(items)

            this.setState({ rows })
        }
    }

    loadCopyItems = async() => {
      this.props.getCopy()
    }

    formatRows = (items: CopyItem[]): CopyItemRow[] => {
        const rows:CopyItemRow[] = items.map((copy: CopyItem) => {
            const { id = 0, name, title, contents, system_managed } = copy

            return { 
                id,
                name,
                title,
                contents,
                systemManaged: system_managed ? 'Y' : 'N',
                actions:  <Actions item={copy} onEdit={this.onEditUser} onDelete={this.onConfirmDelete} onPreview={this.onPreviewItem} />
             }
        })

        return rows
    }

    onAddCopyItem = async(name: string, title: string) => {
      const success = await this.props.addCopy(name, title)
      if(!success) {
        return
      }

      this.setState({ showAddCopyItem: false })
    }

    onEditUser = (copy: CopyItem) => {
      this.props.navigate(`/admin/copy/${copy.id}`)
    }

    onPreviewItem = (copy: CopyItem) => {
      this.props.navigate(`/admin/copy/${copy.id}/preview`)
    }

    onShowAddCopyItem = () => {
      this.setState({ showAddCopyItem: true })
    }

    onHideAddCopyItem = () => {
      this.setState({ showAddCopyItem: false })
    }

    onConfirmDelete = (confirmDeleteItem: CopyItem) => {
      this.setState({ confirmDeleteItem })  
    }

    onCloseConfirmDelete = () => {
      this.setState({ confirmDeleteItem: undefined })
    }

    onDelete = async() => {
        const { confirmDeleteItem } = this.state
        if(!confirmDeleteItem) {
            return
        }

        await this.props.deleteCopy(confirmDeleteItem)

        this.setState({ confirmDeleteItem: undefined })
    }

    render() {
        const { rows, confirmDeleteItem, showAddCopyItem } = this.state

        return (
            <AdminPage>
                <ConfirmDelete
                    title={'Delete Activation Code'}
                    onCancel={this.onCloseConfirmDelete}
                    onDelete={this.onDelete}
                    visible={confirmDeleteItem !== undefined}
                >
                    <Text>Are you sure you want to delete this copy item?</Text>
                </ConfirmDelete>
                <AddCopyModal
                  visible={showAddCopyItem}
                  onSave={this.onAddCopyItem}
                  onCancel={this.onHideAddCopyItem}
                />
                <DataTable
                    actionSx={{ top: '-.5rem' }}
                    actions={<Button sx={{ minHeight: 'unset', height: '4rem', width: '15rem' }} title={'Add'} onClick={this.onShowAddCopyItem} />}
                    data={{ 
                        rows, 
                        columns: [{
                            label: 'Id',
                            field: 'id'
                        },{
                            label: 'Name',
                            field: 'name'
                        },{
                            label: 'Title',
                            field: 'title'
                        },{
                            label: 'System Managed',
                            field: 'systemManaged'
                        },{
                            label: 'Actions',
                            field: 'actions'
                        }]
                    }}
                /> 
            </AdminPage>
        )
    }
}

const mapStateToProps = ({ copy }) => {
    const { items } = copy

    return { items }
}

export default withRouter(connect(mapStateToProps, { addCopy, deleteCopy, getCopy })(CopyItems))