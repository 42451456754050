import React from 'react';
import {Helmet} from 'react-helmet';

const JsonLD = () => {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "Service",
    "serviceType": "Portfolio Design",
    "provider": {
      "@type": "Organization",
      "name": "Portfolio Designer",
      "contactPoint": {
        "@type": "ContactPoint",
        "email": "info@portfoliodesigner.com",
        "contactType": "Customer Service"
      }
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default JsonLD;
