import { ChartOptions } from 'highcharts';
import { 
  SHOW_NOTES_MODAL,
  HIDE_NOTES_MODAL,
  PROFILE_HISTORY_LOADING,
  PROFILE_HISTORY_RECEIVED,
  SHOW_ADD_HISTORY_SUCCESS,
  LOGOUT,
  PROFILE_RESET,
} from '../actions/types/actions';
import { HistoryItem, HistoryNote } from '../actions/types/profileHistory';

export interface ProfileHistoryState {
  profileHistory: HistoryItem[]
  profileNotes: HistoryNote[]
  showNotesModal: boolean
  showAddHistorySuccess: boolean
  notesItem?: HistoryNote
  risksChart: ChartOptions
  lambdaChart:  ChartOptions
  financialPlanChart:  ChartOptions
}

const INITIAL_STATE: ProfileHistoryState = {
    profileHistory: [],
    profileNotes: [],
    showNotesModal: false,
    showAddHistorySuccess: false,
    risksChart: {},
    lambdaChart: {},
    financialPlanChart: {},
};

export default (state = INITIAL_STATE, action): ProfileHistoryState => {
  const { payload, type } = action

  switch (type) {
    case PROFILE_HISTORY_RECEIVED:
      return { ...state, ...payload, loading: false }
    case SHOW_NOTES_MODAL:
      return { ...state, notesItem: payload, showNotesModal: true }
    case HIDE_NOTES_MODAL:
      return { ...state, notesItem: undefined, showNotesModal: false }
    case SHOW_ADD_HISTORY_SUCCESS:
      return { ...state, showAddHistorySuccess: payload }
    case PROFILE_RESET:
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

