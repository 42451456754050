import React, { FunctionComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faEdit, faTrash, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { BaseShapedButtonProps, ShapedButton, ShapedButtonProps } from './ShapedButton';

export type ActionType = 'add' | 'remove' | 'edit' | 'delete' | 'previous' | 'next'

interface BaseShapedActionButtonProps extends BaseShapedButtonProps {
    action: ActionType
}

interface ShapedActionButtonProps extends ShapedButtonProps {
    action: ActionType
}

const ACTION_ICON_MAP = {
    add: faPlus,
    remove: faMinus,
    edit: faEdit,
    delete: faTrash,
    previous: faChevronLeft,
    next: faChevronRight,
}

export const ShapedActionButton: FunctionComponent<ShapedActionButtonProps> = ({ action, sx = {}, ...restProps }) => {
    const icon = ACTION_ICON_MAP[action]

    return (
        <ShapedButton
            sx={{ fontSize: (theme) => theme.typography.pxToRem(45), ...sx }}
            {...restProps }>
            <FontAwesomeIcon icon={icon} />
        </ShapedButton>
    )
}


export const CircleActionButton: FunctionComponent<BaseShapedActionButtonProps> = (props) => {
    return <ShapedActionButton shape={'circle'} {...props } />
}

export const SquareActionButton: FunctionComponent<BaseShapedActionButtonProps> = (props) => {
    return <ShapedActionButton shape={'square'} {...props } />
}