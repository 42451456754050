import axios from "axios"
import { DispatchFunction, GetStateFunction } from "../reducers"
import { addError } from "./notifications"
import { FINANCIAL_PLAN_ASSUMPTIONS, FINANCIAL_PLAN_ASSUMPTIONS_CUSTOM_MODELS, FINANCIAL_PLAN_ASSUMPTIONS_UPDATE, FINANCIAL_PLAN_ASSUMPTION_INFLATION_RATE } from "./types/actions";
import { sanatizeNumericValue } from "../common/utils";
import { StandardThunkAction } from "../store/store";
import { FinancialPlanAssumptionsUpdate, ModelExpectedReturns } from "./types/financialPlanAssumptions";

const FINACIAL_PLAN_ASSUMPTIONS_ENDPOINT = "/api/account/assumptions"
const DEFAULT_ERROR_MESSAGE = 'An unexpected error occured retrieving your data';

export const getFinancialPlanAssumptions = () => {
    return async(dispatch: DispatchFunction) => {
        try {
            const { data } = await axios.get(FINACIAL_PLAN_ASSUMPTIONS_ENDPOINT)
            const { success, message, inflation_rate, models } = data
            if (!success) {
                addError('Financial Plan Assumptions', message ?? DEFAULT_ERROR_MESSAGE )
            }
            const simplifyModels = models.filter((item: ModelExpectedReturns) => item.isSystem)
            const customModels = models.filter((item: ModelExpectedReturns) => !item.isSystem)

            dispatch({
                type: FINANCIAL_PLAN_ASSUMPTIONS,
                payload: {
                    inflation_rate,
                    simplifyModels,
                    customModels,
                }
            });
        } catch (error) {
            dispatch(addError('Financial Plan Assumptions', DEFAULT_ERROR_MESSAGE))
        }
    }
}

export const inflationRateUpdate = (payload: number | string): StandardThunkAction => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        dispatch({ type: FINANCIAL_PLAN_ASSUMPTION_INFLATION_RATE, payload })
        dispatch(sendFinancialPlanAssumptionsUpdate({ inflation_rate: sanatizeNumericValue(payload) }))
    }
}

export const sendFinancialPlanAssumptionsUpdate = (payload: FinancialPlanAssumptionsUpdate): StandardThunkAction => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        try {
            const { data } = await axios.put(FINACIAL_PLAN_ASSUMPTIONS_ENDPOINT, payload)
            const { success, message, inflation_rate } = data
            if (!success) {
                addError('Financial Plan Assumptions', message ?? DEFAULT_ERROR_MESSAGE )
            }
            
            dispatch({
                type: FINANCIAL_PLAN_ASSUMPTIONS_UPDATE,
                payload: {
                    inflation_rate
                }
            });

        } catch(e: any) {
            console.log(e)
            dispatch(addError('Standard Living Risk', e.toString()))
        }
    }
}


export const updateDiscountRateForModel = (model: ModelExpectedReturns, discountRate: string | number): StandardThunkAction => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction | any): Promise<void> => {
        try {
            const { financialPlanAssumptions } = getState();
            const { customModels } = financialPlanAssumptions;
            const updatedModels = customModels.map((item: ModelExpectedReturns) => {
                if (item.id === model.id) {
                    return {
                        ...item,
                        discountRate: sanatizeNumericValue(discountRate)
                    }
                }
                return item;
            })
            dispatch({
                type: FINANCIAL_PLAN_ASSUMPTIONS_CUSTOM_MODELS,
                payload: updatedModels
            });
            const { data } = await axios.post(`${FINACIAL_PLAN_ASSUMPTIONS_ENDPOINT}/model/${model.id}/discountRate`, { discountRate })
            const { success, message } = data
            if (!success) {
                dispatch({
                    type: FINANCIAL_PLAN_ASSUMPTIONS_CUSTOM_MODELS,
                    payload: customModels
                });
                addError('Financial Plan Assumptions', message ?? DEFAULT_ERROR_MESSAGE )
            }
        } catch(e: any) {
            console.log(e)
            dispatch(addError('Standard Living Risk', e.toString()))
        }
    }
}


export const onUpdateStandardDeviation = (model: ModelExpectedReturns, standardDeviation: string | number): StandardThunkAction => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction | any): Promise<void> => {
        try {
            const { financialPlanAssumptions } = getState();
            const { customModels } = financialPlanAssumptions;
            const updatedModels = customModels.map((item: ModelExpectedReturns) => {
                if (item.id === model.id) {
                    return {
                        ...item,
                        standardDeviation: sanatizeNumericValue(standardDeviation)
                    }
                }
                return item;
            })
            dispatch({
                type: FINANCIAL_PLAN_ASSUMPTIONS_CUSTOM_MODELS,
                payload: updatedModels
            });
            const { data } = await axios.post(`${FINACIAL_PLAN_ASSUMPTIONS_ENDPOINT}/model/${model.id}/standardDeviation`, { standardDeviation })
            const { success, message } = data
            if (!success) {
                dispatch({
                    type: FINANCIAL_PLAN_ASSUMPTIONS_CUSTOM_MODELS,
                    payload: customModels
                });
                addError('Financial Plan Assumptions', message ?? DEFAULT_ERROR_MESSAGE )
            }
        } catch(e: any) {
            console.log(e)
            dispatch(addError('Standard Living Risk', e.toString()))
        }
    }
}