import React, {FC} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabPanel } from '../../../common/TabPanel';
import Text from '../../../common/Text';
import TotalReturnsVsBenchmark from './TotalReturnsVsBenchmark';
import AssetClassTotalsByReturn from './AssetClassTotalsByReturn';


const getTabTitleProps = (index: number, title: string) => {
  return {
    id: `tab-${index}`,
    index,
    label: <Text>{title}</Text>,
    'aria-controls': `tabpanel-${index}`,
  };
}

export interface Props {}


const PerformanceChartTabs: FC<Props> = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Performance Charts">
          <Tab {...getTabTitleProps(0, 'Total Returns (vs. Benchmark)')} />
          <Tab {...getTabTitleProps(1, 'Cumulative Asset Class Contributions')} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TotalReturnsVsBenchmark />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AssetClassTotalsByReturn />
      </TabPanel>
    </Box>
  );
}

export { PerformanceChartTabs }

export default PerformanceChartTabs;