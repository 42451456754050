import { Box } from '@mui/material'
import React, { FunctionComponent } from 'react'
import { StatusLevel } from '../../common/types'
import { BaseProps } from '../types'

const SUBSCRIPTION_STATUS_STYLE = {
    [StatusLevel.OK]: {
        backgroundColor: 'green',
        color: 'white',
    },
    [StatusLevel.WARNING]: {
        backgroundColor: 'orange',
        color: undefined,
    },
    [StatusLevel.DANGER]: {
        backgroundColor: 'red',
        color: 'white',
    },
}
interface StatusPillProps extends BaseProps {
    status: StatusLevel
}

export const StatusLevelPill:FunctionComponent<StatusPillProps>  = ({ status, sx = { }, children }) => {
    return (
        <Box sx={{
            padding: '.5rem',
            borderRadius: '1rem',
            textAlign: 'center',
            ...SUBSCRIPTION_STATUS_STYLE[status],
            ...sx,
        }}>
            {children}
        </Box>
    )
}
