import React, { FunctionComponent } from 'react'
import { useNavigate, useParams, useLocation, Params, NavigateFunction } from 'react-router-dom';
import { useQuery } from './useQuery';

export interface WithRouterProps {
  navigate: NavigateFunction
  params: Readonly<Params<string>>
  query: URLSearchParams
  location: Location
}

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const params = useParams()
    const query = useQuery()
    const location = useLocation()
    
    return (
      <Component
        navigate={navigate}
        params={params}
        location={location}
        query={query}
          {...props}
        />
    );
  };
  
  return Wrapper;
};