import React, { Component } from 'react'
import ConfirmModal from '../../common/ConfirmModal'
import { TextFieldItem } from '../../common/FormField'

interface Props {
    onCancel: () => void
    onSave: (name: string, createMode: boolean) => void
    visible: boolean
    name: string
    createMode: boolean
}

interface State {
    name: string
}

export default class extends Component<Props, State> {

    constructor(props) {
        super(props)

        this.state = {
            name: props.name
        }
    }

    componentDidUpdate(prevProps) {
        const { visible: prevVisible, name: prevName } = prevProps
        const { visible, name } = this.props
        const didNameChange = name !== prevName

        if(didNameChange) {
            this.setState({ name })
        }
    }

    onSave = () => {
        if(!this.props.onSave) {
            return
        }
        const { createMode } = this.props
        const { name } = this.state

        this.props.onSave(name, createMode)
    }

    render() {
        const { visible, createMode } = this.props
        const { name } = this.state

        return (
            <ConfirmModal
                visible={visible}
                title={createMode ? 'Create' : 'Edit'}
                confirmLabel={'Save'}
                cancelLabel={'Cancel'}
                confirmDisabled={!name}
                onConfirm={this.onSave}
                onCancel={this.props.onCancel}>
                    <TextFieldItem 
                        title={'Custom Asset Name'} 
                        value={name}
                        onValueChange={(value) => this.setState({ name: value })} 
                    />
            </ConfirmModal>
        )
    }
}