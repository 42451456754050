import { Box } from "@mui/material"
import React, { Component } from "react"
import { connect } from "react-redux"
import { getCopy, getGuideCopyItems } from "../../../actions/adminCopyEditor"
import { Membership } from "../../../actions/types/users"
import { AdminPage } from "../AdminPage"
import GuidePanel from "./GuidePanel"

interface State {

}

interface Props {
  getCopy: () => void
  getGuideCopyItems: () => void
}

class GuideManager extends Component<Props, State> {

  constructor(props) {
    super(props)

    this.state = {
    }
  }

  componentDidMount() {
    this.props.getCopy()
    this.props.getGuideCopyItems()
  }

  componentDidUpdate() {
    
  }


  render() {
    return(
        <AdminPage 
          containerComponent={Box}
          containerSx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          <GuidePanel membership={Membership.ADVISOR_WORKSTATION}/>
          <GuidePanel membership={Membership.PORTFOLIO_DESIGNER} />
          <GuidePanel membership={Membership.RISK_ANALYZER_LEGACY}/>
        </AdminPage>
    )
  }
}

export default connect(null, { getCopy, getGuideCopyItems })(GuideManager)