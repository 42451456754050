import React from 'react'
import { Box, SxProps, Theme } from "@mui/material"
import { MDBDataTable } from 'mdbreact';
import { FunctionComponent } from "react"
import { BaseProps } from "../types"
import { FONT_STYLES_BY_TYPE } from './styled/Text';

export interface Row {
    clickEvent?: () => void;
    [rest: string]: any;
}

export interface Column {
    label?: string;
    field?: string;
    sort?: string;
    width?: number;
    searchable?: boolean;
    requiresScopes?: string[];
    [rest: string]: any;
}

export interface Data {
    columns: Column[]
    rows: Row[]
}

export interface DataTableProps extends BaseProps {
    searchSx?: SxProps<Theme>
    actionSx?: SxProps<Theme>
    searchActionSx?: SxProps<Theme>
    actions?: React.ReactNode
    searchActions?: React.ReactNode
    data: Data
    autoWidth?: boolean;
    barReverse?: boolean;
    bordered?: boolean;
    borderless?: boolean;
    btn?: boolean;
    className?: string;
    dark?: boolean;
    displayEntries?: boolean;
    entries?: number;
    entrieslabel?: string;
    entriesOptions?: number[];
    exportToCSV?: boolean;
    fixed?: boolean;
    hover?: boolean;
    info?: boolean;
    infoLabel?: string[];
    noRecordsFoundLabel?: string;
    maxHeight?: string;
    noBottomColumns?: boolean;
    order?: string[];
    pagesAmount?: number;
    paging?: boolean;
    paginationLabel?: string[] | object[];
    responsive?: boolean;
    responsiveSm?: boolean;
    responsiveMd?: boolean;
    responsiveLg?: boolean;
    responsiveXl?: boolean;
    searching?: boolean;
    searchingLabel?: string;
    scrollX?: boolean;
    scrollY?: boolean;
    sortable?: boolean;
    small?: boolean;
    striped?: boolean;
    theadColor?: string;
    theadTextWhite?: boolean;
    tbodyColor?: string;
    tbodyTextWhite?: boolean;
    tag?: string;
    onSearch?: (value: string) => string | void;
    onSort?: (obj: { column: string; direction: string }) => object | void;
    onPageChange?: (obj: {
      activePage: number;
      pagesAmount: number;
    }) => object | void;
    [rest: string]: any;
}

export const DataTable: FunctionComponent<DataTableProps> = ({ sx = {}, searchSx = {}, actionSx = {}, searchActionSx = {}, className = 'dataTable', data, actions, searchActions, ...restProps }) => {
    return (
        <Box 
            sx={{
                position: 'relative',
                '& .dataTable': {
                    marginBottom: '1rem',
                },
                '& .dataTable [data-test="datatable-entries"]': {
                    display: 'none !important',
                },
                '& .dataTable [data-test="datatable-search"]': {
                    justifyContent: 'flex-start !important',
                    marginBottom: '1rem',
                    position: 'unset',
                    ...searchSx,
                },
                '& .dataTable [data-test="datatable-head"]>tr>th': {
                    backgroundColor: (theme) => theme.palette.secondary.main2,
                    border: (theme) => `1px solid ${theme.palette.primary.main}`,
                    color: (theme) => theme.palette.primary.main,
                    font: FONT_STYLES_BY_TYPE.table_header,
                },
                '& .dataTable>tbody>tr>td': {
                    verticalAlign: 'middle',
                    minWidth: '8rem',
                    padding: '.8rem',
                },
                '& td, th, thead, tbody': {
                    border: (theme) => `1px solid ${theme.palette.primary.main}`,
                },
            }}
        >
            {actions &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    position: 'absolute',
                    top: '-1rem',
                    right: '0rem',
                    ...actionSx
                }}>
                    {actions}
                </Box>
            }
            {searchActions &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    position: 'absolute',
                    top: '-.5rem',
                    left: '20rem',
                    ...searchActionSx
                }}>
                    {searchActions}
                </Box>
            }
            <MDBDataTable
                className={className}
                striped
                bordered
                small
                noBottomColumns
                entries={15}
                displayEntries={false}
                data={data}
                {...restProps}
            /> 
        </Box>
    )
}