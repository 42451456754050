import { Component } from "react"
import { connect } from 'react-redux'
import { ResultTable } from "../../../ResultTable"
import { ResultsTableData } from "../../../../../actions/types/portfolioDesigner"
import { ReduxState } from "../../../../../reducers"

export interface EquityRegionsTableProps {
    equity_regions: ResultsTableData
    benchmarkSnapshotLoading: boolean
    portfolioSnapshotLoading: boolean
}
export interface EquityRegionsTableState { }
class EquityRegionsTable extends Component<EquityRegionsTableProps, EquityRegionsTableState> {
    mainView?: any
    resultsRef?: any

    constructor(props: EquityRegionsTableProps) {
        super(props)

        this.state = {
            runningAnalyzer: false,
            runningHistoricalAnalysis: false,
            reportItems: [],
        };
    }

    render() {
        const { equity_regions, benchmarkSnapshotLoading, portfolioSnapshotLoading } = this.props
        if (!equity_regions) {
            return null;
        }

        return (
            <ResultTable
                loadingBenchmark={benchmarkSnapshotLoading}
                loadingPortfolio={portfolioSnapshotLoading}
                containerSx={{
                    width: '45rem',
                    marginTop: {
                        xs: '2rem',
                        md: undefined, 
                    },
                    marginRight: {
                        xs: undefined,
                        md: '5rem',
                    }
                }}
                results={equity_regions}
            />
        )
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState ) => {
    const { equity_regions, portfolioSnapshotLoading, benchmarkSnapshotLoading } = portfolioDesigner

    return { equity_regions, portfolioSnapshotLoading, benchmarkSnapshotLoading  }
}

export default connect(mapStateToProps)(EquityRegionsTable as  any)