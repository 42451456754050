import React, { Component, HTMLAttributeAnchorTarget } from 'react'
import ClipLoader from "react-spinners/ClipLoader";
import { SxProps, Theme, Link, Box } from '@mui/material';
import { FONT_STYLES_BY_TYPE } from './styled/Text';
import { Link as RouterLink } from 'react-router-dom';

export interface ButtonProps {
    title?: string | React.ReactNode
    component?: any
    icon?: React.ReactNode
    onClick?: () => Promise<void> | void
    href?: string
    className?: string
    style?: any
    color?: string
    textColor?: string
    loading?: boolean
    disabled?: boolean
    classes?: any
    children?: React.ReactNode
    sx?: SxProps<Theme>;
    target?: HTMLAttributeAnchorTarget
    inlineLoading?: boolean
}

export class BaseThemedButton extends Component <ButtonProps> {

    render() {
        const { title, icon: Icon, onClick, className, color, textColor, loading, disabled, children, href, inlineLoading, sx = { }, component, target } = this.props
        
        return (
            <Link
                component={component}
                sx={{ 
                    color: (theme) => textColor || theme.palette.primary.main,
                    backgroundColor: !disabled ? color : undefined,
                    minHeight: '5rem',
                    border: (theme) => `1px solid ${theme.palette.primary.main2}`,
                    borderRadius: 120,
                    opacity: 1,
                    font: FONT_STYLES_BY_TYPE.text,
                    letterSpacing: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    paddingLeft: 5,
                    paddingRight: 5,
                    textDecoration: 'none',
                    cursor: 'pointer',
                    position: 'relative',
                    pointerEvents: loading ? 'none' : undefined,
                    ...sx,
                }} 
                className={className} 
                href={href}
                to={href}
                target={target}
                onClick={ loading || disabled ? undefined : onClick}>
                {Icon &&
                    <Icon />
                }
                {title || children}
                {loading == true && !inlineLoading &&
                    <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, alignItems: 'center', justifyContent: 'center', backgroundColor: '#0005', zIndex: 500, pointerEvents: 'none' }}>
                        <ClipLoader color={'#FFF'} loading={true} size={25} />
                    </Box>
                }
                {loading == true && inlineLoading &&
                    <Box sx={{ borderRadius: 120, display: 'flex', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, alignItems: 'center', justifyContent: 'center', backgroundColor: '#0005', zIndex: 500, pointerEvents: 'none' }}>
                        <ClipLoader color={'#FFF'} loading={true} size={25} />
                    </Box>
                }
            </Link>
        )
    }

}

export default BaseThemedButton