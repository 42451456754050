import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';

interface CollapsableProps {
  duration?: number
  collapsed: boolean
  containerClassName?: string
  containerStyle?: any
  children?: React.ReactNode;
}

const Collapsable: React.FunctionComponent<CollapsableProps> = ({
  duration = 250,
  children,
  collapsed,
  containerClassName = '',
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setMaxHeight(ref.current.scrollHeight);
      ref.current.offsetHeight;
    }
  }, [children]);

  useEffect(() => {
    if (collapsed) {
      setMaxHeight(0);
    } else {
      setMaxHeight(ref?.current?.scrollHeight ?? 0);
    }
  }, [collapsed]);

  return (
    <Box
      ref={ref}
      className={containerClassName}
      sx={{
        overflow: 'hidden',
        transition: `max-height ${duration}ms ease-in-out`,
        maxHeight: collapsed ? 0 : maxHeight,
      }}
    >
      <Box>
        {children}
      </Box>
    </Box>
  );
};

export default Collapsable;

