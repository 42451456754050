import React from 'react';
import { Box, SxProps, TextField, Theme as MuiTheme } from '@mui/material';
import Text from '../../common/Text';
import { BaseProps } from '../../../components/types';
import { sanitizeNumericInput } from '../../../common/utils';
import { FONT_STYLES_BY_TYPE } from '../../../components/common/styled/Text';
import { Theme } from '../../../theme/theme';

interface ReduxStateProps { }
interface ReduxActionProps { }
interface Props extends BaseProps{
    title: string;
    value: string | number;
    isNumeric?: boolean;
    suffix?: string;
    allowEmptyString?: boolean;
    titleSx?: SxProps<Theme & MuiTheme>;
    inputSx?: SxProps<Theme & MuiTheme>;
    inline?: boolean;
    onValueChanged?: (value: string | number) => void | boolean | Promise<boolean> | Promise<void>;
}

type ComponentProps = Props & ReduxActionProps & ReduxStateProps

interface ComponentState {
    value: string | number;
}

export class NumericFilterInput extends React.Component<ComponentProps, ComponentState> {
    state = {
        value: ''
    }

    componentDidMount() {
        this.setState({ value: this.props.value });
    }
    componentDidUpdate(prevProps: Readonly<ComponentProps>, prevState: Readonly<ComponentState>, snapshot?: any): void {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    onValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { isNumeric } = this.props;
        const value = event.target.value;
        if (value === '' && this.props.allowEmptyString) {
            this.setState({ value: '' });
            return;
        }
        const updateValue = isNumeric ? sanitizeNumericInput(value) : value;
        this.setState({ value: updateValue });
    }

    onBlur = async() => {
        const { onValueChanged } = this.props;
        if(onValueChanged) {
            const response = await onValueChanged(this.state.value);
            if (typeof(response)  === 'boolean' && !response) {
                this.setState({ value: this.props.value });
            }
        }  
    }

    onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            this.onBlur();
        }
    }

    render() {
        const { title, suffix, sx = {}, inputSx = {}, titleSx = {}, inline = true } = this.props;
        const { value } = this.state;
        let inlineSx = {};
        if (inline) {
            inlineSx = {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '1rem',
            }
        }
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem', ...inlineSx, ...sx }}>
                <Text type={'form_header'} sx={titleSx} >{title}</Text>
                <TextField
                    variant="outlined"
                    value={value}
                    onChange={this.onValueChanged}
                    onBlur={this.onBlur}
                    margin="dense"
                    sx={{
                        width: '7.5rem',
                        ...inputSx,
                    
                    }}
                    inputProps={{
                        sx: {
                            height: '1rem',
                            font: FONT_STYLES_BY_TYPE.text,
                        }
                    }}
                    onKeyDown={this.onKeyDown}
                />
                {suffix &&
                    <Text type={'form_header'}>{suffix}</Text>
                }
            </Box>
        )
    }
}