import { Component } from 'react'
import { Box, List } from '@mui/material';
import { Ticker, SnapshotItems, TickerAllocation, MetricDataMapped } from '../../actions/types/portfolioDesigner';
import PortfolioListIem from './PortfolioListIem';
import Text from '../common/Text';
import TickerToolTipContainer from './TickerToolTipContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface PortfolioListProps {
    items: TickerAllocation[]
    weightTotal: number
    snapshots: SnapshotItems
    snapshotLoading: boolean
    snapshotTargetItem: TickerAllocation
    snapshotTargetItemUpdateType: 'weight' | 'ticker'
    metrics: MetricDataMapped
    onClearItems: () => void
    onAddItem: () => void
    onItemChange: (updatedItem: TickerAllocation, index: number) => void
    onItemRemoved: (item: TickerAllocation, index: number) => void
    onTickerProxyUpdated?: (ticker?: Ticker ) => void
    onTickerProxyEnabledToggled?: (enabled: boolean) => void
}

class PortfolioList extends Component<PortfolioListProps> {
    listRef: any
    itemRefs: { [key: string]: any } = { }

    constructor(props: PortfolioListProps) {
        super(props)
    }

    onItemRemoved = (item: TickerAllocation, index: number) => {
        const { onItemRemoved } = this.props

        if(onItemRemoved) {
            onItemRemoved(item, index)
        }
    }

    onItemRef = (view: PortfolioListIem, index: number) => {
        this.itemRefs[index] = view
    }

    onFocusNext = (view: PortfolioListIem) => {
        const nextView = this.itemRefs[view.props.index + 1]
        if (nextView) {
            nextView.focus()
        } else {
            this.itemRefs[view.props.index].blur()
        }
    }

    onRenderRow = (item: TickerAllocation, index: number) => {
        const { onItemChange, snapshots, snapshotLoading, snapshotTargetItem, snapshotTargetItemUpdateType, metrics } = this.props

        return (
                <PortfolioListIem
                    key={item.id}
                    proxyRef={this.onItemRef}
                    item={item}
                    index={index}
                    snapshots={snapshots}
                    metrics={metrics}
                    snapshotLoading={snapshotLoading}
                    snapshotTargetItem={snapshotTargetItem}
                    snapshotTargetItemUpdateType={snapshotTargetItemUpdateType}
                    showMCTR
                    onTickerProxyUpdated={this.props.onTickerProxyUpdated}
                    onTickerProxyEnabledToggled={this.props.onTickerProxyEnabledToggled}
                    onFocusNext={this.onFocusNext}
                    onItemRemoved={this.onItemRemoved}
                    onItemChanged={onItemChange}
                />
        )
    }


    scrollToBottom() {
        const scrollHeight = this.listRef.scrollHeight;
        const height = this.listRef.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.listRef.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    render() {
        const { items, onAddItem, weightTotal } = this.props
        return (
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%',
                '*::-webkit-scrollbar': {
                    width: '.5rem'
                },
                '*::-webkit-scrollbar-track': {
                    borderRadius: '2rem'
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    outline: '.1rem solid slategrey',
                    borderRadius: '2rem',
                },
                '*::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    outline: '.1rem solid slategrey',
                    borderRadius: '2rem',
                },
                }}>
                    <Box sx={{
                        border: (theme) => `1px solid ${theme.palette.primary.main}`,
                        padding: '1rem',
                        display: 'flex',
                        flex: 1,
                        width: '100%',
                        flexDirection: 'row',
                        marginRight: '1rem',
                        justifyContent: 'space-between',
                        alignItems: 'center',

                    }}>
                        <Text sx={{ flex: 1, marginRight: '2.5rem' }}>Ticker</Text>
                        <Text sx={{ flex: 1, marginRight: '1rem' }}>Historic Backfill</Text>
                        <Text sx={{ width: '9rem', marginRight: '2rem', }}>Weight (%)</Text>
                        <Box sx={{ display: 'flex', width: '2.5rem', cursor: 'pointer' }} onClick={this.props.onClearItems}>
                            <FontAwesomeIcon size={'2x'} icon={faTimes} />
                        </Box>
                    </Box>
                    <List
                     ref={(div) => {
                        this.listRef = div;
                     }}
                     sx={{
                        overflowY: 'scroll',
                        height: '41rem',
                        padding: 0,
                        border: (theme) => `1px solid ${theme.palette.primary.main}`,
                    }}>
                        {items.map((item, index) => {
                            return this.onRenderRow(item, index)
                        })}
                    </List>
                    <Box sx={{
                        padding: '1rem',
                        display: 'flex',
                        flex: 1,
                        width: '100%',
                        flexDirection: 'row',
                        marginRight: '1rem',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}>
                        <Text sx={{ flex: 1, marginRight: '1rem' }}></Text>
                        <Text sx={{ display: 'flex', flexDirection: 'row', marginRight: '3rem', alignItems: 'center', justifyContent: 'center' }}>Total: {weightTotal.toFixed(2) ?? 0.00}%</Text>
                        <Box sx={{ display: 'flex', width: '2rem', alignItems: 'center', justifyContent: 'center' }}>

                        </Box>
                    </Box>
                </Box>
        )
    }

}

export default PortfolioList