import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../reducers';
import { Box } from '@mui/material';
import { ChartOptions } from 'highcharts';
import { getHistoricalCurvesChart, getHistoricalTimesChart, getHistoricalPerformanceTable } from '../../actions/marketDashboard';
import { HISTORICAL_CURVE_CHART_OPTIONS, HistoricalCurveOptions, HistoricalCurveType } from '../../actions/types/marketDashboard';
import Chart from '../common/Chart';
import { Select } from '../common/styled/Select';
import { SelectOption } from '../../common/types';
import Checkbox from '../common/Checkbox';

import { WithWindowDimensionsProps, withWindowDimensions } from '../common/withWindowDimensions';
import Text from '../common/Text';
import PollingComponent from './PollingComponent';

interface ReduxStateProps {
    historicalCurveChart?: ChartOptions,
    historicalCurveLastTradeTime?: string,
}

interface ReduxActionProps extends WithWindowDimensionsProps {
    getHistoricalCurvesChart: typeof getHistoricalCurvesChart
}


interface ComponentState {
    selectedHistoricalCurveOption: SelectOption<HistoricalCurveType> | null;
    historicalCurveOptions: HistoricalCurveOptions
}

type ComponentProps = ReduxActionProps & ReduxStateProps

class HistoricalCurve extends PollingComponent<ComponentProps, ComponentState> {
    state: ComponentState = {
        selectedHistoricalCurveOption: HISTORICAL_CURVE_CHART_OPTIONS[0],
        historicalCurveOptions: { today: true },
    }

    poll = async (): Promise<void> => {
        const { selectedHistoricalCurveOption, historicalCurveOptions } = this.state;
        if (selectedHistoricalCurveOption?.item) {
            this.props.getHistoricalCurvesChart(selectedHistoricalCurveOption?.item, historicalCurveOptions)
        }
    }

    onHistoricalCurveChange = (option: SelectOption<HistoricalCurveType> | null) => {
        this.setState({ selectedHistoricalCurveOption: option })
        if (option?.item) {
            this.props.getHistoricalCurvesChart(option.item, this.state.historicalCurveOptions)
        }
    }

    onHistoricalOptionChange = (item: keyof HistoricalCurveOptions, checked: boolean) => {
        const { historicalCurveOptions } = this.state;
        const updatedOptions = { ...historicalCurveOptions, [item]: checked };
        this.setState({ historicalCurveOptions: updatedOptions })
        if (this.state.selectedHistoricalCurveOption?.item) {
            this.props.getHistoricalCurvesChart(this.state.selectedHistoricalCurveOption?.item, updatedOptions)
        }
    }
    
    render() {
        const { historicalCurveOptions } = this.state;
        const { today, oneWeek, oneMonth, oneYear } = historicalCurveOptions;
        const { historicalCurveChart, historicalCurveLastTradeTime } = this.props;

        return (
            <Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'column',  xl: 'row' } }}>
                    <Select sx={{ width: '18rem', height: '5rem' }} options={HISTORICAL_CURVE_CHART_OPTIONS} value={this.state.selectedHistoricalCurveOption} onChange={this.onHistoricalCurveChange} />
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: { xs: '0rem', md: '1rem', xl: '0rem' } }}>
                        <Checkbox sx={{ marginLeft: '1rem' }} item={'today'} label={'Today'} value={today ?? false} onCheckChanged={this.onHistoricalOptionChange} />
                        <Checkbox sx={{ marginLeft: '1rem' }} item={'oneWeek'} label={'1W Ago'} value={oneWeek ?? false} onCheckChanged={this.onHistoricalOptionChange} />
                        <Checkbox sx={{ marginLeft: '1rem' }} item={'oneMonth'} label={'1M Ago'} value={oneMonth ?? false} onCheckChanged={this.onHistoricalOptionChange} />
                        <Checkbox sx={{ marginLeft: '1rem' }} item={'oneYear'} label={'1Yr Ago'} value={oneYear ?? false} onCheckChanged={this.onHistoricalOptionChange} />
                    </Box>
                </Box>
                <Chart id='historicalCurveChart' chartOptions={historicalCurveChart as any} sx={{ width: { xs: this.props.dimensions.width - 100, lg: this.props.dimensions.width - 950} }} />
                {historicalCurveLastTradeTime && <Text sx={{ marginTop: '1rem' }}>As of: {historicalCurveLastTradeTime}</Text>}
            </Box>
        )
    }
}


const mapStateToProps = ({ marketDashboard }: ReduxState): ReduxStateProps => {
    const { historicalCurveChart, historicalCurveLastTradeTime } = marketDashboard;
    return {
        historicalCurveChart,
        historicalCurveLastTradeTime,
    }
}

export default withWindowDimensions(connect(mapStateToProps, { getHistoricalCurvesChart, getHistoricalTimesChart, getHistoricalPerformanceTable })(HistoricalCurve as any))