import React, { FunctionComponent } from 'react'
import Panel, { PanelProps } from '../common/Panel'

export const AccountPanel: FunctionComponent<PanelProps> = ({ children, title, sx = {}, contentsSx = {}, ...restProps}) => {
    return (
        <Panel
            sx={{
                flex: React.Children.count(children) > 0 ? 1 : undefined,
                width: {
                    xs: '100%',
                    sm: 'unset',
                    md: 'unset',
                },
                margin: {
                    xs: undefined,
                    md: '2rem',
                },
                marginBottom: {
                    xs: '2rem',
                    md: undefined,
                },
                maxWidth: '70rem',
                ...sx,
            }}
            contentsSx={{
                padding: '1rem',
                ...contentsSx,
            }}
            title={title}
            {...restProps}
        >
                {children}
            </Panel>
    )
}