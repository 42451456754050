import { Box } from '@mui/material'
import React, { FunctionComponent } from 'react'
import { BaseProps } from '../../types'

export const BorderedContainer: FunctionComponent<BaseProps> = ({ sx = { }, children }) => {
    return (
        <Box sx={{
            display: 'flex',
            border: '1px solid #ddd',
            borderRadius: 0,
            height: '35px',
            width: '35px',
            padding: '10px',
            alignItems: 'center',
            justifyContent: 'center',
            ...sx
        }}>{children}</Box>
    )
}