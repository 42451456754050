import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, ButtonBase, Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Text from '../../../components/common/Text';
import { FundCategoryItem } from './FundCategoryItem';


interface FundCategoryGroupProps {
  title: string;
  categories: string[];
  selectedCategories: string[];
  onCheckChanged: (title: string, checked: boolean) => void;
  onCheckAllChanged: (title: string, checked: boolean, categories: string[]) => void;
}

export const FundCategoryGroup = ({ title, categories, selectedCategories, onCheckAllChanged: propOnCheckAllChanged, onCheckChanged }: FundCategoryGroupProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [allChecked, setAllChecked] = React.useState<boolean>(categories.every((category) => selectedCategories.includes(category)));
  const onExpandChanged = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  React.useEffect(() => {
    setAllChecked(categories.every((category) => selectedCategories.includes(category)));
  }, [selectedCategories]);

  const onCheckAllChanged = React.useCallback((e: any) => {
    e.stopPropagation();
    propOnCheckAllChanged(title, !allChecked, categories);
  }, [allChecked, categories, propOnCheckAllChanged, title]);

    return (
      <Accordion expanded={expanded} onChange={onExpandChanged} key={title} sx={{ minWidth: 275, overflow: 'visible', maxWidth: '70rem', padding: 0 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
        sx={{ padding: 0, paddingRight: `1.5rem` }}
      >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box component={ButtonBase} onClick={onCheckAllChanged}>
                <Checkbox checked={allChecked} onChange={onCheckAllChanged} />
              </Box>
              <Text sx={{ fontWeight: 'bold' }}>
                {title}
              </Text>
            </Box>
        </AccordionSummary>
        <AccordionDetails>
          {categories.map((category) => {
            return (<FundCategoryItem key={category} title={category} checked={selectedCategories.includes(category)} onCheckChanged={onCheckChanged} />);
          })}
        </AccordionDetails>
      </Accordion>
      );
    
}