import React, { Component } from 'react';


export default class PdComponent<Props, State> extends Component<Props, State>{

    public async setStateAsync<K extends keyof State>(
        state: ((prevState: Readonly<State>, props: Readonly<Props>) => (Pick<State, K> | State | null)) | (Pick<State, K> | State | null),
    ): Promise<void> { 
        return new Promise((resolve) => {
            this.setState(state, resolve as any)
        });
    }

}