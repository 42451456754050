import { PortfolioDesignerConfiguration } from '../../../actions/types/portfolioDesigner';
import { SelectOption } from '../../../common/types';
import { portfolioModels, needsWishPortfolioModels } from './portfolioModels'
import benchmarkModels from './benchmarkModels'

const portfolioModelOptions: SelectOption[] = portfolioModels.map((item: PortfolioDesignerConfiguration) => {
    return {
        label: item.name,
        value: item.id,
        item 
    }
});

const needsWishPortfolioModelOptions: SelectOption[] = needsWishPortfolioModels.map((item: PortfolioDesignerConfiguration) => {
    return {
        label: item.name,
        value: item.id,
        item 
    }
});

const benchmarkModelsOptions: SelectOption[] = benchmarkModels.map((item: PortfolioDesignerConfiguration) => {
    return {
        label: item.name,
        value: item.id,
        item 
    }
});

export { benchmarkModels, benchmarkModelsOptions, portfolioModels, portfolioModelOptions, needsWishPortfolioModels, needsWishPortfolioModelOptions }