import React, { Component } from 'react'
import { connect } from 'react-redux'
import IrregularItemComponent from './IrregularItem'

import { 
    onUpdateIrregularItem,
    onIrregularItemCreate,
    onIrregularItemDelete,
} from '../../actions/standardLivingRisk'

import Text from '../common/styled/Text'
import { Table } from '../common/Table'
import { TableCell, TableRow } from '@mui/material'
import { CircleActionButton } from '../common/styled/ShapedActionButton'
import { onComponentSizeType, SizeAwareBox } from '../common/styled/SizeAwareBox'
import { IrregularItem } from '../../actions/types/standardLivingRisk'


interface IrregularTableProps {
    type: string
    title: string
    items: IrregularItem[]
    startYears: number[]
    numberOfYears: number[]
    itemLabel: string
    onIrregularItemCreate: (type: string) => void
    onUpdateIrregularItem: (type: string, item: IrregularItem) => void
    onIrregularItemDelete: (type: string, item: IrregularItem) => void
    onComponentSize?: onComponentSizeType
    showFundingType?: boolean
}


class IrregularTable extends Component <IrregularTableProps> {
    constructor(props) {
        super(props)
    }

    onIrregularItemAdd = () => {
        const { type } = this.props
        
        this.props.onIrregularItemCreate(type)
    }

    onIrregularItemChange = (item) => {
        const { type } = this.props

        this.props.onUpdateIrregularItem(type, item)
    }
    
    onIrregularItemDelete = (item) => {
        const { type } = this.props

        this.props.onIrregularItemDelete(type, item)
    }

    onFundingTypeChanged = (item: any) => {

    }

    getColumns = () => {
        const { showFundingType, itemLabel,  } = this.props
        const columns = [
            {
                key: 'actions',
                children: <CircleActionButton type='filled' action={'add'} onClick={this.onIrregularItemAdd} />
            },
            {
                key: 'Description',
                children: 'Description'
            },
            {
                ...(showFundingType ? { 
                    key: 'fundingType',
                    children: 'Type'
                } : {}) 
            },
            {
                key: 'itemLabel',
                children: itemLabel
            },
            {
                key: 'Start Year',
                children: 'Start Year'
            },
            {
                key: '# of Years',
                children: '# of Years'
            }
        ];

        return columns.filter((item) => item.key);
    }

    render() {
        const { title, items = [], startYears, numberOfYears, showFundingType, onComponentSize } = this.props
        
        return (
            <SizeAwareBox sx={{ marginTop: '5rem'}} onComponentSize={onComponentSize}>
                <Text type='h4'>
                    {title}
                </Text>
                <Table 
                    sx={{
                        "& th": {
                            textAlign: 'center',
                        },
                    }}
                    headerColumns={this.getColumns()}>
                    {items.length == 0 &&
                        <TableRow>
                            <TableCell colSpan={showFundingType ? 6 : 5} align="center"><Text>No Items</Text></TableCell>
                        </TableRow>
                    }
                    {items.map((item) => {
                        return  <IrregularItemComponent item={item} onChange={this.onIrregularItemChange} onDelete={this.onIrregularItemDelete}  startYears={startYears} numberOfYears={numberOfYears} showFundingType={showFundingType} />
                    })}
                </Table>
            </SizeAwareBox>
        )
    }
}

export default connect(null, { onUpdateIrregularItem, onIrregularItemCreate, onIrregularItemDelete, })(IrregularTable)