import React, { Component } from 'react'
import { connect } from 'react-redux'

import { checkQuestionnaireCompleted, runRiskAnalyzerResults } from '../../actions/riskPreferences'

import Chart from '../common/Chart';
import TabFooter from '../Software/TabFooter';
import { Holdings, PortfolioMapDetails } from '../../actions/types/investmentPortfolio';
import { getInvestmentPortfolio} from '../../actions/investmentPortfolio';
import { Size, SizeAwareBox } from '../common/styled/SizeAwareBox';
import Button from '../common/Button';
import { onPageChange } from '../../actions/session';
import Text from '../common/styled/Text';
import { Box } from '@mui/material';
import { LooseObject } from '../../common/types';
import RiskAnalyzerCharts from './RiskAnalyzerCharts';
import { PageDivider } from '../common/styled/PageDivider';
import { ScriptRunning } from './ScriptRunning';
import { withClientParameter } from '../common/withClientParameter';
import { hasClientIdChanged } from '../../common/utils';
import { RunRiskAnalyzerResultsRequest } from '../../actions/types/requests';

interface RiskAnalyzerOptimizedPortfolioProps {
    onComponentSize: (size: Size) => void
    getInvestmentPortfolio: () => void
    runRiskAnalyzerResults: (options?: RunRiskAnalyzerResultsRequest) => void
    checkQuestionnaireCompleted: () => boolean
    holdings: Holdings[]
    gamma: number
    lambda: number
    selectedKey: string
    riskAversion: number
    lossAversion: number
    scriptRunning: boolean
    disclaimerText: string | React.ReactNode
    tableDetail: PortfolioMapDetails
    gaugeType: string
    riskAversionRiskLevelsChart: LooseObject
    riskAnalyzerEnterpriseChart: LooseObject
    lossAversionRiskLevelsChart: LooseObject
    useSLR?: boolean
    onPageChange: (key: string, activeIndex: number) => void
    riskModuleName?: string
    footer?: React.ReactNode
    enterprise?: boolean
}

export interface RiskAnalyzerOptimizedPortfolioState {
    questionnaireCompleted?: boolean
}
class RiskAnalyzerOptimizedPortfolio extends Component<RiskAnalyzerOptimizedPortfolioProps, RiskAnalyzerOptimizedPortfolioState> {

    constructor(props) {
        super(props)

        this.state = {
            questionnaireCompleted: false,
        }

        this.checkQuestionnaireCompleted()
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = async() => {
        await this.props.getInvestmentPortfolio()
        this.props.runRiskAnalyzerResults()
    }

    componentDidUpdate(prevProps) {
        const { selectedKey: prevSelectedKey } = prevProps
        const { selectedKey, riskAversion, lossAversion  } = this.props

        if(hasClientIdChanged(prevProps, this.props)) {
            this.loadData()
        }
        
        if(
            selectedKey != prevSelectedKey ||
            riskAversion != prevProps.riskAversion ||
            lossAversion != prevProps.lossAversion
        ) {
            this.checkQuestionnaireCompleted()
        }
    }

    checkQuestionnaireCompleted = async() => {
        const completed = await this.props.checkQuestionnaireCompleted()

        this.setState({ questionnaireCompleted: completed })
    }

    onRestart = () => {
        this.props.onPageChange('singleUseSoftware', 0)
    }
    
    onOpAssetSetSelectionChange = ({ id: asset_set_id }) => {
        this.props.runRiskAnalyzerResults({ asset_set_id, renderCharts: false })
    }

    render() {
        const { gamma, lambda, holdings, riskAnalyzerEnterpriseChart, scriptRunning, disclaimerText, tableDetail, riskAversionRiskLevelsChart,
                gaugeType, lossAversionRiskLevelsChart, useSLR, onPageChange, riskModuleName = 'Risk Analyzer' } = this.props
        const { title, objective } = tableDetail || { }
        const { questionnaireCompleted } = this.state

        if(!questionnaireCompleted) {
            return (
                <SizeAwareBox sx={{ width: '100%' }} onComponentSize={this.props.onComponentSize}>
                    <Box sx={{ minHeight: '50rem', height: 'auto', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                        <Text>Before viewing your optimized portfolio results please complete the {riskModuleName} module.</Text>
                        <Button sx={{ width: '15rem', marginTop: '1rem' }} onClick={this.onRestart}>Go Back</Button>
                    </Box>
                </SizeAwareBox>
            )
        }

        const FooterComponent = this.props.footer || TabFooter
        return (
            <SizeAwareBox sx={{ display: 'flex', flexDirection: 'column', overflowX: 'scroll' }} onComponentSize={this.props.onComponentSize ? ({ height, width }) => this.props.onComponentSize({ height, width }) : undefined}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    width: '100%',
                }}>
                    <RiskAnalyzerCharts 
                        gamma={gamma}
                        lambda={lambda}
                        gaugeType={gaugeType}
                        riskAversionRiskLevelsChart={riskAversionRiskLevelsChart}
                        lossAversionRiskLevelsChart={lossAversionRiskLevelsChart}
                        hideRiskLevelChart={!useSLR}
                    />
                    <PageDivider />
                    <Box sx={{
                        position: 'relative',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        padding: '1rem',
                        minWidth: '70rem',
                        marginLeft: '3rem',
                    }}>
                        {scriptRunning && <ScriptRunning />}
                        {!scriptRunning &&
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '1rem',
                                flex: 1,
                            }}>
                                {tableDetail &&
                                <>
                                    <Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center', 
                                            marginBottom: '2rem',
                                        }}>
                                            <Text type={'h2'} sx={{ minWidth: '22.5rem' }}>Recommended Portfolio</Text>
                                            <Text type={'h3'}>{title}</Text>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}>
                                            <Text sx={{ minWidth: '22.5rem' }}>Investment Objective</Text>
                                            <Text>{objective}</Text>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            width: '100%',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <Chart id="analyzerAllocatioChart" sx={{ width: '45rem', height: '25rem', backgroundColor: '#0000', zIndex: 0 }} chartOptions={riskAnalyzerEnterpriseChart} />
                                        </Box>
                                    </Box>
                                </>
                                }
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}>
                                    {holdings?.map((item) => {
                                        const { title, value } = item
                                        const valuePercent = (value || 0) * 100
                                        return (
                                            <Box sx={{
                                                marginTop: '1rem',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}>
                                                <Text sx={{ fontWeight: 'bold' }}>{title}</Text>
                                                <Text sx={{ paddingLeft: '.5rem' }}>{(valuePercent).toFixed(2)}%</Text>
                                            </Box>
                                        )
                                    })}
                                </Box>
                                {disclaimerText && 
                                    <Box sx={{ marginTop: '2rem' }}>
                                        <Text>{disclaimerText}</Text>
                                    </Box>
                                }
                            </Box>
                        }
                    </Box>
                </Box>
                <Box>
                    <FooterComponent
                        onPageChange={onPageChange}
                        title={'Optimized Portfolio'}
                        activeIndex={0}
                        pageCount={1}
                        prevModuleKey={'riskAnalyzer'}
                        nextModuleKey={'profileHistory'}
                    />
                </Box>
            </SizeAwareBox>
        )
    }

}

const mapStateToProps = ({ session, riskPreferences, investmentPortfolio, capitalMarketAssumptions }, { useSLR }) => {
    const { activeIndex, gammaMeasured, lambdaMeasured, scriptRunning, riskAversion, lossAversion,
            riskAversionRiskLevelsChart, lossAversionRiskLevelsChart } = riskPreferences
    const { holdings, portfolioMaps, opt_asset_set_id, riskAnalyzerEnterpriseChart, tableDetail, recommended } = investmentPortfolio || { }
    const { asset_sets = [] } = capitalMarketAssumptions || { }
    const { enterprise, selectedKey } = session
    
    const gamma = useSLR ? recommended?.gamma : gammaMeasured
    const lambda = useSLR ? recommended?.lambda : lambdaMeasured
    const gaugeType = useSLR ? 'ALM-Adjusted' : undefined

    let currentSetSelection = asset_sets.find(({ id }) => opt_asset_set_id == id)
    if(!opt_asset_set_id && asset_sets && asset_sets.length > 0) {
        currentSetSelection = asset_sets[0]
    }
    
    return {
        selectedKey,
        activeIndex,
        scriptRunning,
        gamma,
        lambda,
        holdings,
        asset_sets,
        portfolioMaps,
        currentSetSelection,
        enterprise,
        riskAnalyzerEnterpriseChart,
        tableDetail,
        riskAversionRiskLevelsChart,
        lossAversionRiskLevelsChart,
        riskAversion,
        lossAversion,
        gaugeType,
    }
}

export default withClientParameter(connect(mapStateToProps, { onPageChange, runRiskAnalyzerResults, checkQuestionnaireCompleted, getInvestmentPortfolio })(RiskAnalyzerOptimizedPortfolio))