import React, { FunctionComponent } from 'react'
import Page from '../Page/Page'
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text'
import { PageContents } from '../Page/PageContents'
import { BaseProps } from '../types'
import { Box, BoxTypeMap, SxProps, Theme } from '@mui/material'

interface AdminPageProps extends BaseProps {
    containerComponent?: typeof Box | typeof PageContents
    containerSx?: SxProps<Theme>
}

export const AdminPage: FunctionComponent<AdminPageProps> = ({ children, containerSx = {}, containerComponent: Container = PageContents }) => {
    return (
        <Page>
            <Container 
                sx={{
                    height: '100%',
                    minHeight: '80rem',
                    position: 'relative',
                    marginTop: '2rem',
                    paddingBottom: '5rem',
                    font: FONT_STYLES_BY_TYPE.text,
                    overflowX: 'scroll',
                    ...containerSx,
                }}
            >
                {children}
            </Container>
        </Page>
    )
}