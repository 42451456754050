import React, { FunctionComponent } from 'react';
import UpdatePassword from './UpdatePassword';
import { Page } from '../Page';
import Panel from '../common/Panel';
import { useQuery } from '../common/useQuery';
import { Navigate } from 'react-router-dom';

export const UpdatePasswordPage: FunctionComponent = (props) => {
    const query = useQuery()
    const token = query.get('token') || ''
    if (!token) {
        return <Navigate to="/" />
    }
    return (
        <Page
            contentsSx={{ 
                justifyContent: 'center',
                marginTop: '10rem',
            }}>
            <Panel
                sx={{ height: '100%' }}
                title={'Update Password'}>
                <UpdatePassword {...props} />
            </Panel>
        </Page>
    )
}