import React, { Component } from 'react'

import { getStandardLivingRisk } from '../../actions/standardLivingRisk';
import { connect } from 'react-redux';
import ClientInputs from './ClientInputs';
import { Box } from '@mui/material';
import { hasClientIdChanged } from '../../common/utils';
import { withClientParameter } from '../common/withClientParameter';

interface Props {
    getStandardLivingRisk: () => void
}

class StandardLivingRiskSimple extends Component <Props> {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getStandardLivingRisk()
    }

    componentDidUpdate(prevProps) {
        if(hasClientIdChanged(prevProps, this.props)) {
            this.props.getStandardLivingRisk()
        }
    }

    render() {
        return (
            <Box sx={{ height: '52rem', overflow: 'visible', position: 'relative' }}>
                <ClientInputs />
            </Box>
        )
    }

}


export default withClientParameter(connect(null, { getStandardLivingRisk})(StandardLivingRiskSimple))