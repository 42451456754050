import axios, { Axios, AxiosError } from 'axios';
import { PORTFOLIO_MANAGER_MODELS_UPDATED, PORTFOLIO_MANAGER_SELECTED_MODEL_UPDATED, PORTFOLIO_MANAGER_UPDATE } from './types/actions';
import { SelectOption } from '../common/types';
import { StandardThunkAction } from '../store/store';
import { OrionAccountDetails, OrionAssetDetails, OrionPortfolioClient, PortfolioManagerConfigurationType, PortfolioManagerCredentialsMissing, PortfolioManagerInvalidCredentials, PortfolioManagerUnexpectedError } from './types/portfolioManager';
import { PortfolioDesignerConfiguration } from './types/portfolioDesigner';
import { getPathsClientId } from '../common/utils';
import { DispatchFunction, GetStateFunction } from '../reducers';
import { addError } from './notifications';

const ORION_CLIENTS_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/orion/clients`
const ORION_CREDENTIALS_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/orion/credentials`

export const handlePortfolioManagerError = (portfolioManager: string, error: any): void => {
    if (!axios.isAxiosError(error)) {
        throw new PortfolioManagerUnexpectedError(portfolioManager)
    }

    const axiosError: AxiosError = error;
    if (axiosError.response?.status !== 401) {
        new PortfolioManagerUnexpectedError(portfolioManager)
    }

    const { response: { data: { message } } } = axiosError as any;
    if(message?.includes('No credentials found.')) {
        throw new PortfolioManagerCredentialsMissing(portfolioManager)
    }
    if(message?.includes('We were unable to authenticate using the provided credentials')) {
        throw new PortfolioManagerInvalidCredentials(portfolioManager)
    }
}

export const getOrionConnected = async(): Promise<boolean> => {
    const { data } = await axios.get<{ success: boolean, connected: boolean}>(ORION_CREDENTIALS_URL)
    const { connected } = data

    return connected;
}

export const saveOrionCredentials = async(username: string, password: string): Promise<boolean> => {
    const { data } = await axios.post<{ success: boolean, connected: boolean}>(ORION_CREDENTIALS_URL, { username, password })
    const { connected } = data

    return connected;
}

export const removeOrionCredentials = async(): Promise<boolean> => {
    const { data } = await axios.delete<{ success: boolean, connected: boolean}>(ORION_CREDENTIALS_URL)
    const { connected } = data

    return connected;
}


export const getOrionClients = async(): Promise<OrionPortfolioClient[]> => {
    try {
        const { data } = await axios.get<{ success: boolean, items: OrionPortfolioClient[]}>(ORION_CLIENTS_URL)
        const { items } = data
    
        return items;
    } catch (e) {
        handlePortfolioManagerError('Orion', e);
    }

    return [];
}

export const getModels = async(): Promise<PortfolioDesignerConfiguration[]> => {
    const { data } = await axios.get(`/api/designer/models`)
    const { models } = data

    return models;
}

export const getExternalModels = async(): Promise<PortfolioDesignerConfiguration[]> => {
    const profileId = getPathsClientId()

    const { data } = await axios.get(`/api/clients/${profileId}/designer/models/external`)
    const { models } = data

    return models;
}

export const getOrionAccounts = async(portfolioManagerClientId: number | string): Promise<OrionAccountDetails[]> => {
    try {
        const { data } = await axios.get(`${ORION_CLIENTS_URL}/${portfolioManagerClientId}/accounts`)
        const { items } = data
    
        return items;
    } catch (e) {
        handlePortfolioManagerError('Orion', e);
    }

    return [];
}

export const getOrionAccountAssets = async(portfolioManagerClientId: number | string, accountId: number): Promise<OrionAssetDetails[]> => {
    try {
        const { data } = await axios.get(`${ORION_CLIENTS_URL}/${portfolioManagerClientId}/accounts/${accountId}/assets`)
        const { items } = data
    
        return items;
    } catch (e) {
        handlePortfolioManagerError('Orion', e);
    }

    return [];
}

export const updateModelsForType = (type: PortfolioManagerConfigurationType, models: SelectOption[]): StandardThunkAction => {
    return async(dispatch: any): Promise<void> => {
        dispatch({ type: PORTFOLIO_MANAGER_MODELS_UPDATED, payload: { type, models } })
    }
}

export const updateSelectedModelForType = (type: PortfolioManagerConfigurationType, selectedOption: SelectOption): StandardThunkAction => {
    return async(dispatch: any): Promise<void> => {
        dispatch({ type: PORTFOLIO_MANAGER_SELECTED_MODEL_UPDATED, payload: { type, selectedOption } })
    }
}

export const updateParameter = (payload: any) => {
    return { type: PORTFOLIO_MANAGER_UPDATE, payload}
}

export const loadAccountOptions = () => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<void> => {
        try {
            const { session: { profile, scopes } } = getState();
            const { portfolio_manager, portfolio_manager_client_id } = profile;
            if (!portfolio_manager || !portfolio_manager_client_id || !scopes.includes('pd:portfolio_manager')) {
                dispatch(updateModelsForType('account', []))

                return;
            }
            if (portfolio_manager === 'orion') {
                const accounts = await getOrionAccounts(portfolio_manager_client_id);
                const accountOptions: SelectOption<OrionAccountDetails>[] = accounts.map((item) => {
                    return {
                        label: `${item.name} (${item.accountType})`,
                        value: item.id,
                        item
                    }
                })

                dispatch(updateModelsForType('account', accountOptions))
            }
        
        } catch(error) {
            if ((error as any).type === 'PortfolioManagerError') {
                dispatch(addError('Orion', (error as any).message));
                return
            }
            console.error('Error loading orion clients', error)
            dispatch(addError('Orion','Error the selected account assets'));
        }
    }
}

export const loadSelectedAccountAssets = (item: SelectOption) => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<void> => {
        try {
            const { session: { profile } } = getState();
            const { portfolio_manager, portfolio_manager_client_id } = profile;
            if (!portfolio_manager || !portfolio_manager_client_id) {
                dispatch(updateModelsForType('account', []))
    
                return;
            }
            if (portfolio_manager === 'orion') {
                const assets = await getOrionAccountAssets(portfolio_manager_client_id, item.value as number);
                const assetsTotal = assets.reduce((total, { currentValue }) => total + currentValue, 0)
                const portfolio = assets.map(({ id, ticker, currentValue }) => ({ id, ticker, weight: (currentValue / assetsTotal) * 100 }))
                const updatedItem: SelectOption = {
                    ...item,
                    item: {
                        id: item.value,
                        name: item.label,
                        section: 'account',
                        type: 'account',
                        portfolio
                    }
                }
                dispatch(updateSelectedModelForType('account', updatedItem))
            }
        } catch(error) {
            if ((error as any).type === 'PortfolioManagerError') {
                dispatch(addError('Orion', (error as any).message));
                return
            }
            console.error('Error loading orion clients', error)
            dispatch(addError('Orion','Error the selected account assets'));
        }
    }
}

export const loadOrionClients = () => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        try {
            const clients = await getOrionClients();

            const clientOptions: SelectOption<OrionPortfolioClient>[] = clients?.length > 0 ? clients.map((item) => {
                return {
                    label: item.name,
                    value: Number(item.id),
                    item 
                }
            }) : [];

            dispatch({ type: PORTFOLIO_MANAGER_UPDATE, payload: { orionClients: [{ label: 'None', value: 'none'}, ...clientOptions] } })
    
        } catch (error) {
            if ((error as any).type === 'PortfolioManagerError') {
                dispatch(addError('Orion', (error as any).message, { contentBasedId: true }));
                return
            }
            console.error('Error loading orion clients', error, { contentBasedId: true })
            dispatch(addError('Orion','Error loading orion clients'));
        }
    }
}