import axios from 'axios'

import { StandardThunkAction } from '../store/store'
import { LOGOUT } from './types/actions'
import { UpdateAdvisorRequest, UpdateFirmRequest } from './types/requests'
import { GetAccountResponse, StandardApiResponse, UpdateLogoResponse } from './types/responses'


export const getAccount = async(): Promise<GetAccountResponse> => {
    try {
        const { data } = await axios.get(`/api/account`)
        const { success, advisor, firm, subscription  } = data
        
        return { success, advisor, firm, subscription }
    }catch (error: any) {
        return { success: false, message: error.toString() }
    }
}

export const updateAdvisor = async(payload: UpdateAdvisorRequest): Promise<StandardApiResponse> => {
    try {
        const { data } = await axios.post(`/api/account`, payload)
        const { success, message  } = data

        
        return { success, message }
    }catch (error: any) {
        return { success: false, message: error.toString() }
    }
}

export const updateFirm = async(payload: UpdateFirmRequest): Promise<StandardApiResponse> => {
    try {
        const { data } = await axios.post(`/api/account/firm`, payload)
        const { success, message  } = data

        return { success, message }
    }catch (error: any) {
        return { success: false, message: error.toString() }
    }
}

export const updateFirmLogo = async(logoFile: File, setProgressState: (value: number) => void): Promise<UpdateLogoResponse> => {
    try {
        const config = {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: function(progressEvent: any) {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              setProgressState(percentCompleted)
            }
          }
        
          const payload = new FormData()
          payload.append('logo', logoFile)

        const { data } = await axios.post(`/api/account/firm/logo`, payload, config)
        const { success, message, logo  } = data
        
        return { success, message, logo}
    }catch (error: any) {
        return { success: false, message: error.toString() }
    }
}

export const logout = (): StandardThunkAction => {
    return async(dispatch): Promise<any> => {
        try {
            dispatch({ type: LOGOUT })

            window.location.href = '/'
        } catch(e: any) {
            console.log(e)
        }
    }
}

export const cancelSubscription = async(): Promise<StandardApiResponse> => {
    try {
        const { data } = await axios.post(`/api/subscription/cancel`, { confirm: true })
        const { success, message  } = data

        return { success, message }
    }catch (error: any) {
        return { success: false, message: error.toString() }
    }
}

export const resumeSubscription = async(): Promise<StandardApiResponse> => {
    try {
        const { data } = await axios.post(`/api/subscription/continue`, { confirm: true })
        const { success, message  } = data

        return { success, message }
    }catch (error: any) {
        return { success: false, message: error.toString() }
    }
}

export const deleteAccount = async(confirmText: string): Promise<StandardApiResponse> => {
    try {
        const { data } = await axios.post(`/api/account/delete`, { confirm: true, confirmText })
        const { success, message  } = data
        
        return { success, message }
    }catch (error: any) {
        return { success: false, message: error.toString() }
    }
}