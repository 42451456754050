import { Box } from '@mui/material'
import React, { Component } from 'react'
import { logoHorizontal } from 'src/assets'

import { ActivationCode, Membership, MEMBERSHIP_MAPPING, NEW_ACTIVATION_CODE, User } from '../../../actions/types/users'
import { SelectOption } from '../../../common/types'
import ConfirmModal from '../../common/ConfirmModal'
import { FieldItem, TextFieldItem } from '../../common/FormField'
import { FormRow } from '../../common/FormRow'
import { Select } from '../../common/styled/Select'

const MEMBERSHIP_ITEMS: SelectOption[] = Object.keys(MEMBERSHIP_MAPPING).map((membership) => {
    return { label: MEMBERSHIP_MAPPING[membership], value: membership }
})
const UNASSIGNED_OPTION = { label: 'Unassigned', value: null }
const DOMAIN_LOCKED_OPTIONS: SelectOption[] = [{ label: 'Locked', value: 0 },{ label: 'Unlocked', value: 1 }]
const ACTIVE_OPTIONS:SelectOption[] = [{ label: 'Active', value: 1 },{ label: 'Disabled', value: 0 }]
const TOKEN_REP_ROLE_ID = 7
interface Props {
    activationCode?: ActivationCode
    visible: boolean
    users: User[]
    onCancel: () => void
    onSave: (activationCode: ActivationCode, logo?: string) => void
}

interface State extends ActivationCode {
    selectedMembership: SelectOption | null
    selectedStatus: SelectOption | null
    selectedDomainLock: SelectOption | null
    logoFile?: File
    logoSource?: string
    users: SelectOption[]
    selectedAssignedUser: SelectOption | null
}

class Modal extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = { ...this.getStateFromActivationCode(props.activationCode), users: this.formatUsers(props.users) }
        
    }

    formatUsers = (users: User[]): SelectOption[] => {
        const userOptions: SelectOption[] = users.filter((user) => user.role_id === TOKEN_REP_ROLE_ID).map((user) => {
            return { label: user.email, value: user.id || 0 }
        })
        
        const allUsers = [UNASSIGNED_OPTION, ...userOptions] 

        return allUsers
    }

    componentDidUpdate(prevProps: Props) {
        const { activationCode: prevActivationCode, users: prevUsers } = prevProps
        const { activationCode, users } = this.props

        if(activationCode !== prevActivationCode) {

            this.setState({ ...this.getStateFromActivationCode(activationCode), users: this.formatUsers(users) })
        }

        if(users !== prevUsers) {
            this.setState({ users: this.formatUsers(users) })
        }
    }
    
    getStateFromActivationCode = (activationCode?: ActivationCode): State  => {
        const { id, active, code, domain, domain_unlocked, enterprise_logo, enterprise_logo_url, membership, assigned_user_id } = activationCode || NEW_ACTIVATION_CODE

        const selectedMembership = MEMBERSHIP_ITEMS.find(({ value }) => value === membership) || null
        const selectedStatus = ACTIVE_OPTIONS.find(({ value }) => value === active) || null
        const selectedDomainLock = DOMAIN_LOCKED_OPTIONS.find(({ value }) => value === domain_unlocked) || null
        const selectedAssignedUser: SelectOption = activationCode?.assigned_user ? { label: activationCode?.assigned_user.email, value: activationCode?.assigned_user.id as any } : { ...UNASSIGNED_OPTION }
        let logoSource = enterprise_logo_url ?? logoHorizontal

        return { id, active, code, domain, domain_unlocked, enterprise_logo, enterprise_logo_url, membership, selectedMembership, selectedStatus, selectedDomainLock, logoSource, assigned_user_id, selectedAssignedUser, users: [] }

    }

    onActiveStatusChanged = (selection: SelectOption) => {
        this.setState({ active: selection.value as number, selectedStatus: selection })
    }

    onDomainLockChanged = (selection: SelectOption) => {
        this.setState({ selectedDomainLock: selection, domain_unlocked: selection.value as number })
    }

    onMembershipChanged = (selection: SelectOption) => {
        this.setState({ selectedMembership: selection, membership: selection.value as Membership })
    }

    onAssignedUserChanged = (selection: SelectOption) => {
        this.setState({ selectedAssignedUser: selection, assigned_user_id: selection.value as any })
    }

    onSave = () => {
        const { id, active, code, domain, domain_unlocked, enterprise_logo,enterprise_logo_url, membership, logoSource, assigned_user_id } = this.state
        let logo
        if(logoSource?.includes('base64')) {
            logo = logoSource
        }

        this.props.onSave({ id, active, code, domain, domain_unlocked, enterprise_logo, enterprise_logo_url, membership, assigned_user_id }, logo)
    }

    onImageSelected = (file: File) => {
        if(!file) {
            this.setState({ logoSource: this.state.enterprise_logo_url ?? logoHorizontal, logoFile: undefined })
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            this.setState({ logoFile: file, logoSource: reader.result as string })
        }
        reader.readAsDataURL(file);
    }

    render() {
        const { visible, onCancel } = this.props
        const { id, domain, selectedMembership, selectedStatus, users, selectedAssignedUser } = this.state
        const isEdit = !!id
        const action = isEdit ? `Edit` : 'Create'

        return (
            <ConfirmModal
                visible={visible}
                sx={{ width: '100%' }}
                title={`${action} Activation Code`}
                confirmLabel="Save"
                cancelLabel="Cancel"
                onConfirm={this.onSave}
                onCancel={onCancel}>
                    <FormRow>
                        <FieldItem titleSx={{ flex: 1 }} contentsSx={{ flex: 2 }} title="Membership" inline={false}>
                            <Select sx={{ width: '25rem', marginRight: '1.5rem' }} options={MEMBERSHIP_ITEMS} value={selectedMembership} onChange={this.onMembershipChanged}/>
                        </FieldItem>
                        <FieldItem titleSx={{ flex: 1 }} contentsSx={{ flex: 2 }} title="Status" inline={false}>
                            <Select sx={{ width: '25rem', marginRight: '1.5rem' }} options={ACTIVE_OPTIONS} value={selectedStatus} onChange={this.onActiveStatusChanged}/>
                        </FieldItem>
                        <FieldItem titleSx={{ flex: 1 }} contentsSx={{ flex: 2 }} title="Token Rep" inline={false}>
                            <Select sx={{ width: '25rem', marginRight: '1.5rem' }} options={users} value={selectedAssignedUser} onChange={this.onAssignedUserChanged}/>
                        </FieldItem>
                    </FormRow>
                    <FormRow sx={{ height: '5rem', marginBottom: '10rem'}}>
                        <TextFieldItem
                            title="Domain"
                            value={domain}
                            onValueChange={(value) => this.setState({ domain: value })}
                        />
                    </FormRow>
            </ConfirmModal>
        )
    }
}

export default Modal