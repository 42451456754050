import { PortfolioDesignerConfiguration } from "../../../actions/types/portfolioDesigner";


export const portfolioModels: PortfolioDesignerConfiguration[] = [{
    "id": -5,
    "type": "portfolio",
    "section": "model",
    "isSystem": true,
    "discountRate": 0.00,
    "name": "Simplify Income Model",
    "rebalancingFrequency": "quarterly",
    "portfolio": [{
        "id": "a0493763-dd7a-4bb8-8c3e-09d4938e7c3c",
        "weight": 10,
        "ticker": {
            "id": "B0YB1N-L",
            "type": "ETF_ETF",
            "symbol": "SPY-US",
            "symbol_display": "SPY",
            "name": "SPDR S&P 500 ETF Trust",
            "cusip": "78462F103",
            "isin": "US78462F1030"
        }
    }, {
        "id": "8a79bd5d-1392-479d-8c56-bc64e7d4570b",
        "weight": 50,
        "ticker": {
            "id": "TH8DPK-L",
            "type": "ETF_ETF",
            "symbol": "AGG-US",
            "symbol_display": "AGG",
            "name": "iShares Core U.S. Aggregate Bond ETF",
            "cusip": "464287226",
            "isin": "US4642872265"
        }
    }, {
        "id": "243a192f-ff63-43d0-a83b-5111cf1e6f4f",
        "weight": 10,
        "ticker": {
            "id": "LB7MH1-L",
            "type": "ETF_ETF",
            "symbol": "CTA-US",
            "symbol_display": "CTA",
            "name": "Simplify Managed Futures Strategy ETF",
            "cusip": "82889N699",
            "isin": "US82889N6994"
        }
    }, {
        "id": "72b20ef1-927c-4267-b962-f8ca631fab9a",
        "weight": 15,
        "ticker": {
            "id": "RWJY27-L",
            "type": "ETF_ETF",
            "symbol": "SVOL-US",
            "symbol_display": "SVOL",
            "name": "Simplify Volatility Premium ETF",
            "cusip": "82889N863",
            "isin": "US82889N8636"
        }
    }, {
        "id": "d0797142-589d-48d4-8b4b-7a98cf54483c",
        "weight": 15,
        "ticker": {
            "id": "L0GJGP-L",
            "type": "ETF_ETF",
            "symbol": "HIGH-US",
            "symbol_display": "HIGH",
            "name": "Simplify Enhanced Income ETF",
            "cusip": "82889N632",
            "isin": "US82889N6325"
        }
    }],
}, {
    "id": -6,
    "type": "portfolio",
    "section": "model",
    "isSystem": true,
    "discountRate": 0.05,
    "name": "Simplify Conservative Model",
    "rebalancingFrequency": "quarterly",
    "portfolio": [{
        "id": "2a2be243-8d21-4abf-8680-07890829853a",
        "weight": 14,
        "ticker": {
            "id": "B0YB1N-L",
            "type": "ETF_ETF",
            "symbol": "SPY-US",
            "symbol_display": "SPY",
            "name": "SPDR S&P 500 ETF Trust",
            "cusip": "78462F103",
            "isin": "US78462F1030"
        }
    }, {
        "id": "5eea603b-91c9-4875-9d48-e1bd5033a51d",
        "weight": 56,
        "ticker": {
            "id": "TH8DPK-L",
            "type": "ETF_ETF",
            "symbol": "AGG-US",
            "symbol_display": "AGG",
            "name": "iShares Core U.S. Aggregate Bond ETF",
            "cusip": "464287226",
            "isin": "US4642872265"
        }
    }, {
        "id": "a462b08a-9dd2-419e-aec9-a0c2ff20d8dc",
        "weight": 10,
        "ticker": {
            "id": "LB7MH1-L",
            "type": "ETF_ETF",
            "symbol": "CTA-US",
            "symbol_display": "CTA",
            "name": "Simplify Managed Futures Strategy ETF",
            "cusip": "82889N699",
            "isin": "US82889N6994"
        }
    }, {
        "id": "fdb39af1-1a3e-4fda-9918-db183803a813",
        "weight": 10,
        "ticker": {
            "id": "CN73VC-L",
            "type": "ETF_ETF",
            "symbol": "CDX-US",
            "symbol_display": "CDX",
            "name": "Simplify High Yield PLUS Credit Hedge ETF",
            "cusip": "82889N830",
            "isin": "US82889N8305"
        }
    }, {
        "id": "6daa11a6-84c6-45fa-b386-fe7f6d9154c0",
        "weight": 10,
        "ticker": {
            "id": "L0GJGP-L",
            "type": "ETF_ETF",
            "symbol": "HIGH-US",
            "symbol_display": "HIGH",
            "name": "Simplify Enhanced Income ETF",
            "cusip": "82889N632",
            "isin": "US82889N6325"
        }
    }],
}, {
    "id": -7,
    "type": "portfolio",
    "section": "model",
    "isSystem": true,
    "discountRate": 0.08,
    "name": "Simplify Moderate Model",
    "rebalancingFrequency": "quarterly",
    "portfolio": [{
        "id": "9f65637d-a8d5-44b1-bdcb-60edb6163571",
        "weight": 43,
        "ticker": {
            "id": "B0YB1N-L",
            "type": "ETF_ETF",
            "symbol": "SPY-US",
            "symbol_display": "SPY",
            "name": "SPDR S&P 500 ETF Trust",
            "cusip": "78462F103",
            "isin": "US78462F1030"
        }
    }, {
        "id": "c950bb37-af9d-4ede-b92f-ce8426b70920",
        "weight": 27,
        "ticker": {
            "id": "TH8DPK-L",
            "type": "ETF_ETF",
            "symbol": "AGG-US",
            "symbol_display": "AGG",
            "name": "iShares Core U.S. Aggregate Bond ETF",
            "cusip": "464287226",
            "isin": "US4642872265"
        }
    }, {
        "id": "68a5207f-2a68-471e-98b9-726099d65fb5",
        "weight": 20,
        "ticker": {
            "id": "LB7MH1-L",
            "type": "ETF_ETF",
            "symbol": "CTA-US",
            "symbol_display": "CTA",
            "name": "Simplify Managed Futures Strategy ETF",
            "cusip": "82889N699",
            "isin": "US82889N6994"
        }
    }, {
        "id": "39de32c7-0edb-46d0-ab4f-4cece9d9e8ad",
        "weight": 5,
        "ticker": {
            "id": "MJH976-L",
            "type": "ETF_ETF",
            "symbol": "HEQT-US",
            "symbol_display": "HEQT",
            "name": "Simplify Hedged Equity ETF",
            "cusip": "82889N764",
            "isin": "US82889N7646"
        }
    }, {
        "id": "eb142da3-cb03-4e20-9e6f-66aae9a58509",
        "weight": 5,
        "ticker": {
            "id": "RWJY27-L",
            "type": "ETF_ETF",
            "symbol": "SVOL-US",
            "symbol_display": "SVOL",
            "name": "Simplify Volatility Premium ETF",
            "cusip": "82889N863",
            "isin": "US82889N8636"
        }
    }],
}, {
    "id": -8,
    "type": "portfolio",
    "section": "model",
    "isSystem": true,
    "discountRate": 0.11,
    "name": "Simplify Growth Model",
    "rebalancingFrequency": "quarterly",
    "portfolio": [{
        "id": "2db6b011-0147-4bca-880d-e1ce2f2b358f",
        "weight": 58,
        "ticker": {
            "id": "B0YB1N-L",
            "type": "ETF_ETF",
            "symbol": "SPY-US",
            "symbol_display": "SPY",
            "name": "SPDR S&P 500 ETF Trust",
            "cusip": "78462F103",
            "isin": "US78462F1030"
        }
    }, {
        "id": "4053c2d6-f896-4951-9ccf-7d53159e6a2c",
        "weight": 12,
        "ticker": {
            "id": "TH8DPK-L",
            "type": "ETF_ETF",
            "symbol": "AGG-US",
            "symbol_display": "AGG",
            "name": "iShares Core U.S. Aggregate Bond ETF",
            "cusip": "464287226",
            "isin": "US4642872265"
        }
    }, {
        "id": "c6e88deb-370e-422b-bd20-677cdd6fb435",
        "weight": 20,
        "ticker": {
            "id": "LB7MH1-L",
            "type": "ETF_ETF",
            "symbol": "CTA-US",
            "symbol_display": "CTA",
            "name": "Simplify Managed Futures Strategy ETF",
            "cusip": "82889N699",
            "isin": "US82889N6994"
        }
    }, {
        "id": "34691f3d-977c-4840-80a6-1a8f3912d36d",
        "weight": 10,
        "ticker": {
            "id": "RWJY27-L",
            "type": "ETF_ETF",
            "symbol": "SVOL-US",
            "symbol_display": "SVOL",
            "name": "Simplify Volatility Premium ETF",
            "cusip": "82889N863",
            "isin": "US82889N8636"
        }
    }],
},
]

export const needsWishPortfolioModels = [ {
    "id": -9,
    "type": "portfolio",
    "section": "model",
    "isSystem": true,
    "discountRate": 0.04,
    "name": "Simplify Needs Model",
    "rebalancingFrequency": "quarterly",
    "portfolio": [{
        "id": "7976ecd5-7abc-45d8-9f51-492837a0cf7b",
        "weight": 40,
        "ticker": {
            "id": "GV4QK9-L",
            "type": "ETF_ETF",
            "regional_id": "M1MG7N-R",
            "symbol": "AGGH-US",
            "symbol_display": "AGGH",
            "description": "Simplify Aggregate Bond PLUS Credit Hedge ETF",
            "cusip": "82889N723",
            "isin": "US82889N7232",
        }
    }, {
        "id": "c0a34555-e003-45c0-96d4-bbe5c2e8240a",
        "weight": 30,
        "ticker": {
            "id": "XNW2B2-L",
            "type": "ETF_ETF",
            "regional_id": "V7JBV9-R",
            "symbol": "BUCK-US",
            "symbol_display": "BUCK",
            "description": "Simplify Stable Income ETF",
            "cusip": "82889N640",
            "isin": "US82889N6408",
        }
    }, {
        "id": "7dba3700-70d4-41fb-b15f-0bbca553aef9",
        "weight": 30,
        "ticker": {
            "id": "L0GJGP-L",
            "type": "ETF_ETF",
            "regional_id": "MSPJYM-R",
            "symbol": "HIGH-US",
            "symbol_display": "HIGH",
            "description": "Simplify Enhanced Income ETF",
            "cusip": "82889N632",
            "isin": "US82889N6325",
        }
    }]
}, {
    "id": -10,
    "type": "portfolio",
    "section": "model",
    "isSystem": true,
    "discountRate": 0.14,
    "name": "Simplify Wishes Model",
    "rebalancingFrequency": "quarterly",
    "portfolio": [{
        "id": "ab2b9165-a625-436b-b885-33c8a333a53e",
        "weight": 50,
        "ticker": {
            "id": "B0YB1N-L",
            "type": "ETF_ETF",
            "regional_id": "M75BNK-R",
            "symbol": "SPY-US",
            "symbol_display": "SPY",
            "description": "SPDR S&P 500 ETF Trust",
            "cusip": "78462F103",
            "isin": "US78462F1030",
        }
    }, {
        "id": "70c6e6d2-b728-4c1f-b498-734c43096c32",
        "weight": 20,
        "ticker": {
            "id": "LB7MH1-L",
            "type": "ETF_ETF",
            "regional_id": "NL55ZQ-R",
            "symbol": "CTA-US",
            "symbol_display": "CTA",
            "description": "Simplify Managed Futures Strategy ETF",
            "cusip": "82889N699",
            "isin": "US82889N6994",
        }
    }, {
        "id": "dbd1c2c4-5323-448a-8280-5a416475fcfc",
        "weight": 10,
        "ticker": {
            "id": "RWJY27-L",
            "type": "ETF_ETF",
            "regional_id": "MHZM38-R",
            "symbol": "SVOL-US",
            "symbol_display": "SVOL",
            "description": "Simplify Volatility Premium ETF",
            "cusip": "82889N863",
            "isin": "US82889N8636",
        }
    }, {
        "id": "f8ce3085-2e61-400f-8486-3e46b45a27ef",
        "ticker": {
            "id": "L0GJGP-L",
            "type": "ETF_ETF",
            "regional_id": "MSPJYM-R",
            "symbol": "HIGH-US",
            "symbol_display": "HIGH",
            "description": "Simplify Enhanced Income ETF",
            "cusip": "82889N632",
            "isin": "US82889N6325",
        },
        "weight": 10
    }, {
        "id": "23594fca-d75f-4c89-b836-fad6f9dde1b8",
        "ticker": {
            "id": "LL50WQ-L",
            "type": "ETF_ETF",
            "regional_id": "QGZ4MC-R",
            "symbol": "SPBC-US",
            "symbol_display": "SPBC",
            "description": "Simplify US Equity PLUS GBTC ETF",
            "cusip": "82889N848",
            "isin": "US82889N8487",
        },
        "weight": 10
    }]
}];
