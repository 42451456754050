import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import React, { Component } from 'react'
import { PortfolioMapAsset } from '../../../actions/types/investmentPortfolio';
import { FONT_STYLES_BY_TYPE } from '../../common/styled/Text';
import TooltipContainer from '../../common/styled/TooltipContainer';
import { TableHeaderCell } from '../../common/Table';
import Text from '../../common/Text';

interface Props {
    onValueChanged: (item: any) => void
    editMode: boolean
    lambda: string
    gammas: string[]
    assets: PortfolioMapAsset[]
}

interface State {
    hoverId?: number
}

class PortfolioMapValues extends Component<Props, State> {
    constructor(props) {
        super(props)

        this.state = {
            hoverId: undefined,
        }
    }

    renderGammaHeaderItem = (gamma) => {
        return (
            <TableHeaderCell key={gamma}>
                <Text>{gamma}</Text>
            </TableHeaderCell>                
        )
    }

    onValueChange = (item, value) => {
        this.props.onValueChanged({ ...item, value })
    }

    onCellEnter = ({ id }) => {
        this.setState({ hoverId: id })
    }

    onCellLeave = ({ id }) => {
        if(id === this.state.hoverId) {
            this.setState({ hoverId: undefined })
        }
    }

    renderAssetValue = (item) => {
        const { editMode } = this.props
        const { hoverId } = this.state
        const { id, gamma, value, dirty, saveFailed, message,  } = item
        const dirtySx = dirty ? { backgroundColor: '#555' } : { }
        const saveFailedSx = saveFailed ? { backgroundColor: 'red' } : { }
        
        return (
            <TableCell 
                sx={{  
                    font: FONT_STYLES_BY_TYPE.text,
                    ...dirtySx,
                    ...saveFailedSx,
                    '& input': {
                        width: '5rem',
                    }
                }}
                onMouseEnter={!editMode ? () => this.onCellEnter({ id }) : undefined}
                onMouseLeave={() => this.onCellLeave({ id }) } key={`lambda_row_detail gamma_${gamma.toString().replace(".", "_")} ${dirty ? 'dirty' : ''} ${saveFailed ? 'save_failed' : '' }`} style={{ overflow: 'visible', position: 'relative' }}
            >
            <TooltipContainer
                hoverDisabled={!saveFailed || hoverId != id}
                hoverContents={message}
            >
                <Box>
                    {editMode && 
                        <input value={value} onChange={({ target }) => this.onValueChange(item, target.value)}/>
                    }
                    {!editMode && 
                        <span>{(value * 100).toFixed(2)}%</span>
                    }
                </Box>
            </TooltipContainer>
            </TableCell>            
        )
    }

    renderAssetRow = ({ asset_name: assetName, values }, index, total, lambda) => {
        return (
            <TableRow key={`${assetName}_${lambda}`}>
                <TableCell>
                    <Text>{assetName}</Text>
                </TableCell>
                {
                    values.filter(({ lambda: valueLambda }) => lambda == valueLambda).map((value: any) => this.renderAssetValue(value))
                }
            </TableRow>
        )
    }

    render() {
        const { lambda, assets = [], gammas = [] } = this.props

        const gammaCount = gammas.length
        const assetCount = assets.length
        const rowCount = assetCount + 2
        return (
            <Table  
                sx={{
                    border: '1px  solid #bfbfbf !important',
                    borderCollapse: 'collapse',
                    margin: '1rem',
                    '& th': {
                        minHeight: '7.5rem',
                        backgroundColor: '#e9eef3',
                    },
                    '& thead>th, & tbody>tr>th, & tbody>tr>td': {
                        borderTop: '0px',
                        verticalAlign: 'middle',
                        textAlign: 'center',
                    },
                    '& tbody>tr>td': {
                        border: '1px  solid #bfbfbf',
                        width: '5.5rem',
                    },
                }}
                key={`lambda${lambda.replace('.', '_')}`}
            >
                <TableBody>
                    <TableRow>
                        <TableHeaderCell rowSpan={rowCount}>
                            <Box>
                                Loss Aversion <Box>{lambda}</Box>
                            </Box>
                        </TableHeaderCell>
                        <TableHeaderCell rowSpan="2" colSpan="1" className="EmptyCell"></TableHeaderCell>
                        <TableHeaderCell colSpan={gammaCount} className="RiskAversionLabel">
                            <Box className="header_content">Risk Aversion</Box>
                        </TableHeaderCell>
                    </TableRow>
                    <TableRow>
                        {
                            gammas.map(gamma => this.renderGammaHeaderItem(`${gamma}`))
                        }
                    </TableRow>
                        {
                            assets.map((asset, index) => this.renderAssetRow(asset, index, assets.length, lambda))
                        }
                </TableBody>
            </Table>
        )
    }

}

export default PortfolioMapValues