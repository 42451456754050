import React from "react"
import ThemeWrapper from "../EntryWrapper/ThemeWrapper"
import SiteFooter from "./SiteFooter"

export const ThemeWrappedSiteFooter = () => {
    return (
      <ThemeWrapper>
          <SiteFooter />
      </ThemeWrapper> 
    )
}


export default SiteFooter