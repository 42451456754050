import { matchPath } from "react-router-dom";
import { Styles, withStyles } from '@mui/styles';
import React from "react"
import { Nav, Navbar } from "react-bootstrap"
import { connect } from "react-redux"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import { onNavBarHeightChanged } from "../../actions/session"
import StoreProvider from "../../store/StoreProvider"
import StoreThemeWrapper from "../EntryWrapper/StoreThemeWrapper"
import Button from '../common/Button';

//@ts-ignore
import { NAV_RIGHT_SECTION, NAV_USER_SECTION, Route } from "./types"
import Collapsable from '../common/Collapsable';
import { Link } from 'react-router-dom';
import { withRouter, WithRouterProps } from '../common/withRouter';
import { NavLogoHorizontalImage  } from '../../assets';
import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { withLogin } from '../../common/hooks/withLogin';
import { Box } from "@mui/material";
import { FONT_STYLES_BY_TYPE } from "../common/styled/Text";
const store = StoreProvider.getStore()

interface NavBarComponentProps extends WithRouterProps, withAuthProps {
  excludeRoutes?: string[]
  useAsLink: true
  refresh: () => Promise<any>
  performLogout: () => Promise<any>
  onNavBarHeightChanged: (height: number) => void
  navbarHeight: number
  classes: any
  removeLogin?: boolean
}
interface NavBarComponentState {
  showUserItems: boolean
  navExpanded: boolean
  activeKey: string
}

const stylesF: Styles<any, any> = (theme) => {
  return {
    '@global': {
      html: {
          fontSize: '10px',
      },
      '.navbar .nav-link': {
        fontSize: 16,
        fontWeight: 300,
        letterSpacing: 2,
        color: 'unset',
      },
      '.navbar .navbar-nav': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
      },
      '.navbar a.nav-link': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '16px',
          fontWeight: 300,
          letterSpacing: '2px',
      },
      '.navbar a.nav-link.active': {
          textShadow: '0 0 15px #fff',
          color: '#555',
      },
      '.userItemsNav a.nav-link': {
          paddingTop: '15px',
          paddingRight: '15px',
          paddingBottom: '10px',
          paddingLeft: '15px',
          alignItems: 'flex-start',
      },
      '.navbar a:focus, a:hover': {
        color: 'unset',
        textDecoration: 'none',
      },
      '.userItemsNav': {
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'start',
        marginTop: '-.3rem',
      }
    },
    navbar: {
      width: '100%',
      display: 'flex',
      marginBottom: 'unset',
      alignItems: 'center',
      minHeight: 80
    },
    navbarBrand: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      '&.navbar-brand': {
        maxWidth: 250,
        padding: 'unset',
        "& img": {
          maxHeight: '100%',
        }
      },
    },
    horizontalRoutesContainer: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
      flex: 1,
      justifyContent: 'flex-end',
      marginRight: '2rem',
    },
    routeLink: {
      color: `${theme.palette.primary.main} !important`,
      font: 'normal normal normal 15px/24px Montserrat',
    },
    navHeader: {
      borderBottomColor: '#0004',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 5000,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: 'white',
      ['@media print']: {
          display: 'none',
      },
    },
    navbarContainer: {
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 50,
        borderRadius: 0,
        marginLeft: 20,
        width: '100%',
        paddingTop: 10,
        paddingBottom: 10,
        '-webkit-transition': 'margin-left .3s ease-in-out',
        '-o-transition': 'margin-left .3s ease-in-out',
        'transition': 'margin-left .3s ease-in-out',
    },
    navbarContainerCentered: {
      justifyContent: 'center',
    },
    navDropdown: {
        fontSize: 16,
        fontWeight: 300,
        letterSpacing: 2,
        color: '#777',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    userDropdownButton: {
        padding: '10px 15px',
    },
    userItemsNav: {
        position: 'absolute',
        right: 0,
        minWidth: 220,
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        boxShadow: 'none',
        borderColor: '#eee',
        border: '1px solid rgba(0,0,0,.15)',
        borderRadius: 4,
    },
    divider: {
        height: 1,
        margin: '9px 0',
        width: '100%',
        overflow: 'hidden',
        borderBottom: '2px solid #eee',
    },
    navItemContainer: {
        display: 'flex',
        position: 'relative',
    },
    horizontalNavItemContainer: {
      display: 'flex',
      position: 'relative',
      padding: '10px 15px',
    },
    activeBorder: {
        borderBottom: `1px solid ${theme.palette.primary.main2} !important`,
    },
    userIcon: {
      marginRight: 10,
    },
    navExpandContainer: {
      width: '100%',
    },
    horizontalRouteLink: {
      border: '1px solid #0000',
    },
    verticalRouteLink: {
      padding: '10px',
      width: '100%',
      borderBottom: '1px solid #0002',
    },
    hamburgerButton: {
      marginLeft: 10,
      background: 'none',
      color: 'inherit',
      border: 'none',
      padding: 0,
      font: 'inherit',
      cursor: 'pointer',
      outline: 'inherit',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    activeNavItemBackground: {
      background: '#0001'
    }
  }
}

class NavBarComponent extends React.Component <NavBarComponentProps, NavBarComponentState> {
  navItemsButton: any
  userItemsButton: any

  constructor(props: NavBarComponentProps) {
    super(props)

    const path = window.location.href.split('?')[0]
    const activeKey = path.replace(`${location.protocol}//${location.host}`, '')
    this.state = {
      showUserItems: false,
      navExpanded: false,
      activeKey,
    }
  }

  componentDidMount(): void {
    document.addEventListener('click', this.onClickAnywhere, true);
    window.addEventListener('resize', this.onResize)

  }

  componentWillUnmount(): void {
    document.removeEventListener('click', this.onClickAnywhere, true);
    window.removeEventListener('resize', this.onResize)
  }

  componentDidUpdate(prevProps: NavBarComponentProps) {
    const { location: prevLocation } = prevProps
    const { location } = this.props
    
    if(prevLocation && prevLocation.pathname && prevLocation.pathname !== location.pathname) {
      this.setState({ showUserItems: false  })
    }

    if(prevLocation.pathname !== location.pathname) {
      this.setState({ activeKey: location.pathname  })
    }
  }
  onResize = () => {
    this.setState({ showUserItems: false, navExpanded: false })
  }
  onUserNameClicked = () => {
    if(this.props.authenticated) {
      this.setState({ showUserItems: !this.state.showUserItems })
    } else {
      this.props.navigate('/login')
    }
  }

  onClickAnywhere = (event: any) => {
    if (this.navItemsButton && !this.navItemsButton.contains(event.target as Node)) {
      this.setState({ navExpanded: false })
    }

    if (this.userItemsButton && !this.userItemsButton.contains(event.target as Node)) {
      this.setState({ showUserItems: false })
    }
  };

  toggleNavMenu = () => {
    this.setState({ navExpanded: !this.state.navExpanded })
  };

  render() {
    const { authenticated, user, userDetails, loading, classes, useAsLink = true, navbarHeight = 55, excludeRoutes, removeLogin } = this.props
    const { firstName } = user || { }
    const { logo, logoInHeader, headerLogoOnly, routes = [] } = userDetails || { }
    const { showUserItems, activeKey } = this.state
    const rightRoutes = routes.filter(({ placement }) => placement === NAV_RIGHT_SECTION)
    const userRoutes = routes.filter(({ placement }) => placement === NAV_USER_SECTION)
    const userName = authenticated ? firstName : 'Login'.toUpperCase()
   

    if (excludeRoutes) {
      const matchedExclusionRoutes = excludeRoutes?.find((route) => {
        return matchPath(route, window.location.pathname)
      })
      if(matchedExclusionRoutes) {
        return null;
      }
    }
    return(
      <div>
        <div ref={(view) => {
          if(view && view.clientHeight !== navbarHeight) {
            this.props.onNavBarHeightChanged(view.clientHeight)
          }
        }} className={[classes.navHeader, 'main-header'].join(' ')}>
          <Navbar bg="light" variant="light" className={classes.navbar}>
            {!headerLogoOnly && 
              <button ref={(view) => this.navItemsButton = view} className={classes.hamburgerButton} onClick={this.toggleNavMenu.bind(this)}>
                <FontAwesomeIcon size={'2x'} color={'#0008'} icon={faBars}  />
              </button>
            }
            <div className={[classes.navbarContainer, headerLogoOnly ? classes.navbarContainerCentered : ''].join(' ')}>
              <Navbar.Brand as={useAsLink ? Link : undefined} to={'/'} href={'/'} className={classes.navbarBrand}>
                  <NavLogoHorizontalImage  src={logoInHeader ? logo : undefined} />
              </Navbar.Brand>
              {!loading && !headerLogoOnly &&
                <div className={classes.horizontalRoutesContainer}>
                  <Nav className="me-auto" activeKey={activeKey}>
                      {rightRoutes.map(({ title, path, raw_link }) => {
                        return (
                          <div key={`${title}_${path}`} className={classes.horizontalNavItemContainer}>
                            <Nav.Link as={useAsLink && !raw_link ? Link : undefined} className={[classes.routeLink, classes.horizontalRouteLink, (activeKey === path ? classes.activeBorder : '')].join(' ')} to={path} href={path}>{title.toUpperCase()}</Nav.Link>
                          </div>
                        )
                      })}
                      <div className={classes.navDropdown}>
                        {!removeLogin && 
                          <Button variant='outline' sx={{ maxWidth: '20rem' }} onClick={this.onUserNameClicked}>
                            <div ref={(view) => this.userItemsButton = view} className={classes.userDropdownButton}>
                              {authenticated && <i className={`fa fa-user ${classes.userIcon}`}></i>}
                              {userName}
                              {authenticated && <span className="caret"></span>}
                            </div>
                          </Button>
                          }
                          {authenticated &&
                            <Box className={[classes.userItemsNav, 'userItemsNav'].join(' ')} sx={{ top: navbarHeight }} >
                                <Collapsable containerStyle={{ width: '100%' }} collapsed={!showUserItems}>
                                      {userRoutes.map(({ title, type, path, raw_link }) => {
                                        if(type === 'divider') {
                                          return <div key={`${title}_${path}`} className={classes.divider}>{title}</div>
                                        }
                                        if(path.includes('logout')) {
                                          return <Nav.Link key={`${title}_${path}`} href="#" className={classes.routeLink} onClick={() => {
                                            this.setState({ showUserItems: false })
                                            this.props.performLogout();
                                          }}>{title}</Nav.Link>
                                        } else {
                                          return <Nav.Link key={`${title}_${path}`} as={useAsLink && !raw_link ? Link : undefined} className={classes.routeLink} to={path} href={path}>{title}</Nav.Link>
                                        }
                                      })}
                                </Collapsable>
                            </Box>
                          }
                    </div>
                  </Nav>
                </div>
              }
            </div>
          </Navbar>
        </div>
        <Box sx={{
          position: 'fixed',
          background: 'white',
          width: '100%',
          zIndex: 4999,
        }}>
          <Collapsable containerClassName={classes.navExpandContainer} collapsed={!this.state.navExpanded}>
              {rightRoutes.map(({ title, path, raw_link }) => {
                  const isActive = activeKey === path
                  const classNames = [classes.navItemContainer]
                  if(isActive) {
                    classNames.push(classes.activeNavItemBackground)
                  }
                  return (
                    <div key={`${title}_${path}`} className={classNames.join(' ')}>
                      <Nav.Link as={useAsLink && !raw_link ? Link : undefined} className={[classes.routeLink, classes.verticalRouteLink].join(' ')} to={path} href={path}>{title.toUpperCase()}</Nav.Link>
                    </div>
                  )
              })}
              {!authenticated && 
                <div className={classes.navItemContainer}>
                  <Nav.Link as={Link} className={[classes.routeLink, classes.verticalRouteLink].join(' ')} to={'/login'} href={'/login'}>Login</Nav.Link>
                </div>
              }
              {authenticated && <Box sx={{ marginLeft: '1rem', marginTop: '2rem', font: FONT_STYLES_BY_TYPE.table_header }}>{userName}</Box>}
              {authenticated && <div className={classes.divider}></div>}
              {authenticated && userRoutes.map(({ type, title, path, raw_link }) => {
                const isActive = activeKey === path
                const classNames = [classes.navItemContainer]
                if(isActive) {
                  classNames.push(classes.activeNavItemBackground)
                }
                if(type === 'divider') {
                  return null
                }
                const isLogout = path.includes('logout')
                return (
                  <div key={`${title}_${path}`} className={classNames.join(' ')}>
                    {isLogout && <Nav.Link as={Box} className={[classes.routeLink, classes.verticalRouteLink].join(' ')} onClick={() => {
                      this.props.performLogout();
                    }}>{title.toUpperCase()}</Nav.Link>}
                    {!isLogout && <Nav.Link as={useAsLink && !raw_link ? Link : undefined} className={[classes.routeLink, classes.verticalRouteLink].join(' ')} to={path} href={path}>{title.toUpperCase()}</Nav.Link>}
                  </div>                  
                )
              })}
          </Collapsable>
        </Box>
      </div>
    )
  }
}

const mapStateToProps = ({ session }) => {
  const { routes, navbarHeight } = session

  return { routes, navbarHeight }
}

export const NavBarComponentWithStyles = withStyles(stylesF)(withLogin(withAuth(connect(mapStateToProps, { onNavBarHeightChanged })(NavBarComponent))))
export const NavBar = withLogin(withAuth(withRouter(NavBarComponentWithStyles)))
export const NavBarMaintenance = withRouter(withStyles(stylesF)(connect(mapStateToProps, { onNavBarHeightChanged })(NavBarComponent)))

export const NavBarWrapped = () => {
  return (
    <StoreThemeWrapper store={store}>
      <NavBarComponentWithStyles useAsLink={false} />
    </StoreThemeWrapper>
  )
}

export default withRouter(NavBar)
