import { Box, SxProps, Theme } from '@mui/material'
import React, { FunctionComponent } from 'react'
import { BaseProps } from '../types'

interface TotalDividerProps extends BaseProps {
    lineSx?: SxProps<Theme>
}

export const TotalDivider: FunctionComponent<TotalDividerProps> = ({ sx = {}, lineSx = { }}) => {
    return (
        <Box sx={{
            display: 'flex',
            ...sx,
        }}>
            <Box sx={{
                width: '100%',
                borderBottom: (theme) => `2px solid ${theme.palette.borders.level6}`,
                ...lineSx,
            }}></Box>
        </Box>
    )
}