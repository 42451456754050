import React from 'react'
import { useLocation } from 'react-router-dom'
import NotFound from '../Errors/NotFound'
import { AdminPage } from './AdminPage'

export const AdminCatchAll = () => {
    const path = useLocation()?.pathname
    if(path.startsWith('/admin')) {
        return <NotFound />
    }
    window.location.href = path
    
    return <AdminPage />
}