import * as React from 'react';
import { Provider } from 'react-redux'
import ThemeWrapper from './ThemeWrapper';

export default ({ children, ...props }) => {
    return (
        <Provider {...props}>
            <ThemeWrapper>
                {children}
            </ThemeWrapper>
        </Provider>
    )
}