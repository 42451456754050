import React, { Component } from 'react'
import { connect } from 'react-redux'

import { decimalFormat, getDisplayValue, legacyDecimalFormat } from '../../common/utils'
import { Asset, CMAAsset } from '../../actions/types/customAssets'
import { assetHasValues } from '../../actions/customCMA'
import { ShiftEditItem } from './AssetsTable'
import { Box, TableCell, TableRow } from '@mui/material'
import { CircleActionButton } from '../common/styled/ShapedActionButton'
import Text from '../common/styled/Text'
import { Select } from '../common/styled/Select'
import TooltipContainer from '../common/styled/TooltipContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

interface AssetItemProps {
    item: CMAAsset
    readOnly?: boolean
    assets: Asset[]
    onSelectedAssetChanged: (id: number, asset: any) => void
    onRemoveAsset: (id: number) => void
    onReturnShiftEnableToggled: (id: number, value: boolean) => void
    onReturnScaleChanged: (id: number, value: string) => void
    onForecastShiftEdit: (item: ShiftEditItem) => void
}

class AssetItem extends Component<AssetItemProps> {
    constructor(props) {
        super(props)
    }

    onSelectedAssetChanged = (asset) => {
        const { item } = this.props
        const { id } = item
        if(this.props.onSelectedAssetChanged) {
            this.props.onSelectedAssetChanged(id, asset)
        }
    }

    onRemoveAsset = () => {
        const { item } = this.props
        const { id } = item
        if(this.props.onRemoveAsset) {
            this.props.onRemoveAsset(id)
        }
    }

    onReturnShiftEnableToggled = () => {
        const { item } = this.props
        const { id, return_shift_enabled } = item

        if(this.props.onReturnShiftEnableToggled) {
            this.props.onReturnShiftEnableToggled(id, !return_shift_enabled)
        }
    }

    onReturnScaleChanged = (e) => {
        const { item } = this.props
        const { id } = item

        let value = e.target.value
        if(value != '') {
            value = parseFloat(value).toFixed(2)
            if(isNaN(value)) {
                return
            }
        }

        if(this.props.onReturnScaleChanged) {
            this.props.onReturnScaleChanged(id, value)
        }
    }

    getPositiveColorClassForValue = (value) => {
        value = decimalFormat({ value })

		if(value == 0.0) {
			return { };
		}

		return value > 0.0 ? { backgroundColor: '#D1EFE6'} : { backgroundColor: '#FFD9D9' }
    }

    getNegativeColorClassForValue = (value) => {
        value = decimalFormat({ value })

		if(value == 0.0) {
			return { };
		}

		return value > 0.0 ? { backgroundColor: '#FFD9D9' } : { backgroundColor: '#D1EFE6'}
    }

	hasTop3 = () => {
        const { item } = this.props
        const { mpteTop3Assets } = item

		return mpteTop3Assets ? true : false;
	}

	topThreeAssets = () => {
		if(!this.hasTop3()) {
			return undefined;
		}

        const { item } = this.props
        const { mpteTop3Assets: mpteTop3AssetsRaw } = item
		const mpteTop3Assets = JSON.parse(mpteTop3AssetsRaw);

		const top3TextArray = mpteTop3Assets.map(({ name, assetPercentage }, index) => {
            if(index > 2) {
                return undefined
            }

            return `${name} -> ${decimalFormat({ value: assetPercentage })}%`
        });
        const top3Text = top3TextArray.filter((text) => text !== undefined).map((text) => <Box>{text}</Box>)

        return top3Text
	}

    render() {
        const { item, assets = [], readOnly } = this.props
        const { asset, mctr, mctv, mcts, mpte, return: returnValue, returnShift = 0.0, return_shift_enabled  } = item
        const { first_date } = asset || { }
        const { formatted } = first_date || { }
        const annualizedReturn = returnValue == 0 ? 0 : returnValue * 12
        const returnShiftSafe = assetHasValues(item) ? returnShift : 0.0
        
        return (
            <TableRow sx={{
                "& td": {
                    padding: '1rem',
                    border: (theme) => `1px solid ${theme.palette.borders.level7}`,
                    textAlign: 'center',
                }
            }}>
                <TableCell>
                    <CircleActionButton action={'remove'} onClick={this.onRemoveAsset} />
                </TableCell>
                <TableCell>
                    {!readOnly && 
                        <Select 
                            sx={{ width: '25rem' }} 
                            styles={{
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 100,
                                }),
                            }}
                            value={asset}
                            options={assets}
                            onChange={this.onSelectedAssetChanged}
                            borderless 
                        /> 
                    }
                    {readOnly && <Text>{asset?.name}</Text> }
                </TableCell>
                <TableCell><Text>{formatted}</Text></TableCell>
                <TableCell sx={this.getPositiveColorClassForValue(mctr)}>
                    <Text>{legacyDecimalFormat({ value: mctr })}%</Text>
                </TableCell>
                <TableCell sx={this.getNegativeColorClassForValue(mctv)}>
                    <Text>{legacyDecimalFormat({ value: mctv })}%</Text>
                </TableCell>
                <TableCell sx={this.getPositiveColorClassForValue(mcts)}>
                    <Text>{legacyDecimalFormat({ value: mcts, multiplier: 1 })}</Text>
                </TableCell>
                <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', '& svg': { marginLeft: '1rem' } }}>
                        <Text>{legacyDecimalFormat({ value: mpte })}%</Text>
                        {this.hasTop3() &&
                            <TooltipContainer hoverContents={this.topThreeAssets()}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </TooltipContainer>
                        }
                    </Box>
                </TableCell>
                <TableCell sx={{ borderLeft: (theme) => `5px solid ${theme.palette.borders.level7}` }}>
                    <Text>{decimalFormat({ value: annualizedReturn })}%</Text>
                </TableCell>
                <TableCell>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <input 
                            type={'checkbox'}
                            disabled={readOnly}
                            checked={return_shift_enabled}
                            onChange={this.onReturnShiftEnableToggled}
                        />
                        {!readOnly && return_shift_enabled == true &&
                            <Box sx={{ background: 'none', border: 'none' }} component={'button'} onClick={() => this.props.onForecastShiftEdit({ ...item, annualizedReturn })}>
                                <Text sx={{
                                    textDecoration: 'underline',
                                    color: '#297ea1',
                                }}>Edit</Text>
                            </Box>
                        }
                    </Box>
                </TableCell>
                <TableCell>
                    <Text>
                        {return_shift_enabled == true && 
                            `${getDisplayValue(returnShiftSafe * 100, 2)}%`
                        }
                        {!return_shift_enabled && `--` }
                    </Text>
                </TableCell>
            </TableRow>
        )
    }

}

const mapStateToProps = ({ capitalMarketAssumptions }) => {
    const { assets } = capitalMarketAssumptions

    return {
        assets
    }
}

export default connect(mapStateToProps)(AssetItem)