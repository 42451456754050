import axios from 'axios'
import { 
    COMPLETE_QUESTION_UPDATE,
    MEASURED_PARAMETER_UPDATE, ANNUAL_INCOME,
    RISK_AVERSION,
    LOSS_AVERSION,
    RISK_PREFERENCE_PAGE_INDEX,
    RISK_LEVEL_AVERAGES,
    STANDARD_LIVING_RISK_RECEIVED,
    RISK_PREFERENCES_MEASURED_UPDATE,
    INVESTMENT_PORTFOLIO_UPDATE,
    CAPITAL_MARKET_ASSUMPTIONS_RECEIVED,
    ASSET_SET_ID_CHANGED,
    USER_LOADED,
    SESSION
} from "./types/actions"

import { calculateMeasured, calculateModerated, MEASURED_KEY_TYPE_MAPPING } from './measuredCalculations'
import { updatePortfolioRiskParameter } from './investmentPortfolio'
import { onPageChange, updateProfile } from './session'
import { getAssetAllocatioChart, getRiskAnalyzerEnterpriseChart, getRiskLevelChart } from '../components/OptimizedPortfolio/chartOptions'
import { LEVEL_MAPPING } from '../components/RiskPreferences/mappings'
import { addError, addStatusNotification, removeNotification } from './notifications'
import { getPathsClientId, sanatizeNumericValue } from '../common/utils'
import { StandardAction, StandardThunkAction } from '../store/store'
import { EnterprisePortfolioMapRequest, RunRiskAnalyzerResultsRequest, UpdateMeasuredParameterRequest, UpdateQuestionRequest, UpdateRiskPreferenceQuestions, UpdateRiskPreferenceRequest } from './types/requests'
import { DEFAULT_PREFERENCE_VALUES, LossAversionQuestions, RECOMMENDED_PORTFOLIO_MATRIX, RiskAversionQuestions } from './types/riskPreferences'
import { RiskAnalyzerScriptResponse } from './types/responses'
import { ChartOptions } from 'highcharts'
import { Holdings, PortfolioMapDetail } from './types/investmentPortfolio'
import { AssetSet } from './types/customAssets'
import { portfolioModels } from '../components/PortfolioDesigner/models'
import { PortfolioDesignerConfiguration } from './types/portfolioDesigner'

export const CLIENTS_ENDPOINT = '/api/clients'
export const PREFERENCES_ENDPOINT = 'preferences'
export const ANALYZE_ENDPOINT = `${PREFERENCES_ENDPOINT}/analyze`

export const VALID_MEASURED_TYPES = ['gamma', 'lambda']

export const initialLoadClientRiskPreferences = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const profileId = getPathsClientId()
            if(!profileId) {
                return
            }
            dispatch(getLatestRiskPreferences())

            dispatch(onPageChange('clientQuestionnaire', 0));
            dispatch({ type: USER_LOADED, payload: { views: ['client_questionnaire'], enterprise: true, defaultKey: 'clientQuestionnaire', selectedKey: 'clientQuestionnaire' } })
            dispatch({ type: SESSION, payload: { defaultKey: 'clientQuestionnaire', selectedKey: 'clientQuestionnaire'}})
            dispatch(completeQuestionUpdate(DEFAULT_PREFERENCE_VALUES))
        } catch(e: any) {
            dispatch(addError('Risk Preferences', e.toString()))
        }
    }
}

export const getLatestRiskPreferences = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const profileId = getPathsClientId()
            if(!profileId) {
                return
            }

            const { data } = await axios.get(`${CLIENTS_ENDPOINT}/${profileId}/${PREFERENCES_ENDPOINT}`)
            const { success, risk_preferences, client, standardLivingRisk, message, risk_level_averages: riskLevelAverages } = data
            if(!success) {
                if(message) {
                    dispatch(addError('Risk Preferences', message))
                }

                return
            }
            const { loss_q1, loss_q2, loss_q3, loss_q4, risk_willing_q1, risk_willing_q2, risk_willing_q3, risk_willing_q4, risk_willing_q5, gamma_measured: gammaMeasured, lambda_measured: lambdaMeasured } = risk_preferences || { }

            const riskAversion = { question1Value: risk_willing_q1, question2Value: risk_willing_q2, question3Value: risk_willing_q3, question4Value: risk_willing_q4, question5Value: risk_willing_q5 }
            const lossAversion = { question1Value: loss_q1, question2Value: loss_q2, question3Value: loss_q3, question4Value: loss_q4 }
            
            dispatch(updateProfile(client))
            dispatch({ type: STANDARD_LIVING_RISK_RECEIVED, payload: { standardLivingRisk }})
            dispatch({ type: RISK_PREFERENCES_MEASURED_UPDATE, payload: { gammaMeasured, lambdaMeasured, riskLevelAverages }})


            dispatch(renderCharts())
        } catch(e: any) {
            dispatch(addError('Risk Preferences', e.toString()))
        }
    }
}

export const getRiskAnalyzer = (): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        try {
            const profileId = getPathsClientId()
            if(!profileId) {
                return
            }

            const { data } = await axios.get(`${CLIENTS_ENDPOINT}/${profileId}/${ANALYZE_ENDPOINT}`)
            const { success, message } = data
            if(!success) {
                dispatch(addError('Risk Preferences', message))

                return
            }
            const { portfolioMap, portfolioMaps = [], cma_asset_sets: payloadCMASets = [], opt_asset_set_id, holdings: dataHoldings } = data
            
            const portfolioMapsWithLabel = portfolioMaps ? portfolioMaps.map((item: any) => {
                const { name: label, id: value, details: itemDetails = [] } = item

                const details = itemDetails.reduce((accumulator: any, { mappings, ...details }: any) => {
                    const mappingsReduced = mappings.reduce((mappingAccum: any, { gamma_risk_level, lambda_risk_level }: any) => {

                        return { ...mappingAccum, [`${gamma_risk_level}_${lambda_risk_level}`]: { ...details } }
                    }, { })
                    return { ...accumulator, ...mappingsReduced  }
                }, { })

                return { ...item, details, label, value }
            }) : []
            
            dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { portfolioMap, portfolioMaps: portfolioMapsWithLabel, opt_asset_set_id } })
            
            const asset_sets = payloadCMASets ? payloadCMASets.map((item: AssetSet) => { return { ...item,  label: item.name, value: item.id } }) : []
            dispatch({ type: CAPITAL_MARKET_ASSUMPTIONS_RECEIVED, payload: { asset_sets }})


            const holdings = dataHoldings.map(({ title, value }: { title: string, value: string}) => { 
                return { title, value: Number(value) || 0 }
            })

            dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { holdings, analyzerAllocatioChartOptions: getAssetAllocatioChart(holdings) } })

            const { session } = getState()
            const { enterprise } = session
            
            if(enterprise) {
                dispatch(runRiskAnalyzerResults())
            }
        } catch(e: any) {
            dispatch(addError('Risk Preferences', e.toString()))
        }
    }
}

export const updateRiskPreferencesAnswer = ({ type, questionNumber, value }: UpdateRiskPreferenceRequest): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        try {
            dispatch({ type, payload: { questionNumber, value} })

            const { riskPreferences, standardLivingRisk } = getState()
            const { standardLivingRisk: slr } = standardLivingRisk

            const measured = calculateMeasured(type, riskPreferences)
            const moderated = calculateModerated(type, measured, slr)

            dispatch(updateMeasuredParameter({ type: MEASURED_KEY_TYPE_MAPPING[type], value: measured }))
            dispatch(updatePortfolioRiskParameter('preferred', MEASURED_KEY_TYPE_MAPPING[type], measured ))
            dispatch(updatePortfolioRiskParameter('recommended', MEASURED_KEY_TYPE_MAPPING[type], moderated ))

            const paths = window.location.pathname.split('/')
            const profileId = paths[paths.length - 1]

            const { data } = await axios.put(`${CLIENTS_ENDPOINT}/${profileId}/${PREFERENCES_ENDPOINT}`, { type, questionNumber, value, measured, moderated })
            const { success, message, risk_level_averages } = data
            if(!success) {
                dispatch(addError('Risk Preferences', message))

                return
            }
            dispatch({ type: RISK_LEVEL_AVERAGES, payload: risk_level_averages })
            dispatch(renderCharts())
        } catch(e: any) {
            dispatch(addError('Risk Preferences', e.toString()))
        }
    }
}

export const annualIncomeUpdate = (value: number): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: ANNUAL_INCOME, payload: value })

        const profileId = getPathsClientId()
        const { data } = await axios.put(`${CLIENTS_ENDPOINT}/${profileId}/${PREFERENCES_ENDPOINT}`, { type: 'income', value: sanatizeNumericValue(value) })
        const { success, message } = data

        
        if(!success) {
            dispatch(addError('Risk Preferences', message))

            return
        }
    }
}
export const getClientQuestionnaireLink = (): StandardThunkAction => {
    return async(dispatch): Promise<any> => {
        try {
            const profileId = getPathsClientId()
            if (!profileId) {
                return ''
            }

            const { data } = await axios.post(`${CLIENTS_ENDPOINT}/${profileId}/preferences/token`)
            
            const { success, message, link } = data
            if(!success) {
                dispatch(addError('', message))

                return ''
            }

            return `${link}`
        } catch(e: any) {
            dispatch(addError('', e.toString()))
        }
    }
}

export const backToStart = (): StandardAction => {
    return { type: RISK_PREFERENCE_PAGE_INDEX, payload: 0 }
}

export const riskPreferencePage = (index: number): StandardAction => {
    return { type: RISK_PREFERENCE_PAGE_INDEX, payload: index }
}

export const updateMeasuredParameter = ({ type, value }: UpdateMeasuredParameterRequest): StandardAction => {
    if(!VALID_MEASURED_TYPES.includes(type)) {
        throw new Error('Received invalid type')
    }

    return { type: MEASURED_PARAMETER_UPDATE, payload: { [`${type}Measured`]: value } }
}
export const getEnterpriseHoldings = ({ gamma: gammaParameter, lambda: lambdaParameter, portfolioMap }: EnterprisePortfolioMapRequest) => {
    const holdings = portfolioMap?.assets?.map(({ asset_name: title, values = [] }) => {
        try {

            const { value } = values.find(({ gamma, lambda }) => gamma == gammaParameter && lambda == lambdaParameter) || { }

            return { title, value: Number(value) }
        } catch(e: any) {
            return { title, value: undefined }
        }
    })

    return holdings
}

export const getTableDetails = ({ gamma, lambda, portfolioMap }: EnterprisePortfolioMapRequest): PortfolioMapDetail | undefined => {
    try {
        const  { details } = portfolioMap
        const detailsKeys = `${LEVEL_MAPPING.gamma[gamma]}_${LEVEL_MAPPING.lambda[lambda]}}`
        return details[detailsKeys]
    } catch(e: any) {
        return undefined
    }
}

export const runRiskAnalyzerResults = ({ asset_set_id = undefined, renderCharts: shouldRenderCharts = true, suppressErrors = false }: RunRiskAnalyzerResultsRequest = { }): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { session, riskPreferences, investmentPortfolio } = getState()
        const { enterprise } = session
        
        if(shouldRenderCharts) {
            dispatch(renderCharts())
        }
        
        const id = asset_set_id ? asset_set_id : investmentPortfolio.opt_asset_set_id
        if(asset_set_id) {
            dispatch({ type: ASSET_SET_ID_CHANGED, payload: asset_set_id })
        }
        
        if(enterprise) {
            const { portfolioMaps, recommended } = investmentPortfolio
            const { gammaMeasured, lambdaMeasured } = riskPreferences

            const hasRecommended = recommended?.gamma && recommended.lambda
            const gamma = hasRecommended ? recommended?.gamma : gammaMeasured
            const lambda = hasRecommended ? recommended?.lambda : lambdaMeasured

            const holdings = getEnterpriseHoldings({ gamma, lambda, portfolioMap: portfolioMaps[0] })
            const tableDetail = getTableDetails({ gamma, lambda, portfolioMap: portfolioMaps[0] })

            dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { holdings, tableDetail, riskAnalyzerEnterpriseChart: getRiskAnalyzerEnterpriseChart(tableDetail) } })
            return
        }
        const notification = await dispatch(addStatusNotification('Robots Optimizing Portfolio...'))
        const paths = window.location.pathname.split('/')
        const profileId = paths[paths.length - 1]
        let data
        try {
            const { data: payload } = await axios.post(`${CLIENTS_ENDPOINT}/${profileId}/${ANALYZE_ENDPOINT}`, { asset_set_id: id })
            data = payload
        } catch(e: any) {
            data = { success: false, message: `${e}` }
        }
        
        const { success, message, result, errors }: RiskAnalyzerScriptResponse = data
        const { holdings: resultHoldings = [] } = result || { }
        if(!success && message) {
            dispatch(addError('Script Error', message))

            dispatch(removeNotification(notification))
            return
        }

        const holdings = resultHoldings.map(({ title, value }: Holdings) => { 
            return { title, value: Number(value) || 0 }
        })

        if(errors && errors.length > 0 && !suppressErrors) {
            dispatch(addError('Script Finished with Errors!', errors.join('\r\n')))
        }

        dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { holdings, analyzerAllocatioChartOptions: getAssetAllocatioChart(holdings) } })

        dispatch(removeNotification(notification))
    }
}
export const renderCharts = (type: string = ''): StandardThunkAction => {
    return async(dispatch, getState): Promise<void> => {
        const { riskPreferences } = getState()
        const { riskLevelAverages } = riskPreferences
        const { lambda, gamma } = riskLevelAverages
        
        const riskAversionRiskLevelsChart: ChartOptions = getRiskLevelChart('Risk Aversion', `${type} Risk Aversion`.trim(), gamma)
        const lossAversionRiskLevelsChart: ChartOptions = getRiskLevelChart('Loss Aversion', `${type} Loss Aversion`.trim(), lambda)

        dispatch({ type: RISK_PREFERENCES_MEASURED_UPDATE, payload: { riskAversionRiskLevelsChart, lossAversionRiskLevelsChart }})
    }
}

export const completeQuestionUpdate = ({ lossAversion, riskAversion }: UpdateRiskPreferenceQuestions): StandardAction => {
    return { type: COMPLETE_QUESTION_UPDATE, payload: { lossAversion, riskAversion } }
}

export const riskAversionQuestionUpdate = ({ questionNumber, value }: UpdateQuestionRequest): StandardThunkAction => {
    return updateRiskPreferencesAnswer({ type: RISK_AVERSION, questionNumber, value })
}

export const lossAversionQuestionUpdate = ({ questionNumber, value }: UpdateQuestionRequest): StandardThunkAction => {
    return updateRiskPreferencesAnswer({ type: LOSS_AVERSION, questionNumber, value })
}

const checkQuestionsCompleted = (questions: LossAversionQuestions | RiskAversionQuestions, count: number): boolean => {
    for(let i = 0; i < count; i++) {
        const questionValue = questions[`question${i + 1}Value`]

        if(questionValue === null || questionValue === undefined) {
            return false
        }
    }

    return true
}

export const checkQuestionnaireCompleted = (): StandardThunkAction<boolean> => {
    return async(dispatch, getState): Promise<boolean> => {
        const { riskPreferences } = getState()
        const { riskAversion, lossAversion } = riskPreferences

        const riskAversionCompleted = checkQuestionsCompleted(riskAversion, 5)
        const lossAversionCompleted = checkQuestionsCompleted(lossAversion, 4)
        
        return riskAversionCompleted && lossAversionCompleted
    }
}

export const getRecommendedPortfolio = (lossAversion: number, riskAversion: number): PortfolioDesignerConfiguration | undefined => {
    if (!lossAversion || !riskAversion) {
        return undefined
    }
    try {
        const lossAversionLevel = LEVEL_MAPPING.lambda[lossAversion];
        const riskAversionLevel = LEVEL_MAPPING.gamma[riskAversion];
        const strategy = RECOMMENDED_PORTFOLIO_MATRIX[lossAversionLevel][riskAversionLevel];
        
        const recommendedPortfolio = portfolioModels.find((item) => item.name.includes(strategy));

        return recommendedPortfolio as PortfolioDesignerConfiguration;
    } catch(e) {

    }


  return undefined;
}
