import React, { FunctionComponent } from 'react'
import { Link, LinkProps } from "@mui/material"

export interface BaseShapedButtonProps extends LinkProps {
    onClick: () => void | Promise<void>
    type?: 'filled' | 'outline'
    disabled?: boolean
}

export interface ShapedButtonProps extends BaseShapedButtonProps {
    shape: 'circle' | 'square'
}

export const ShapedButton: FunctionComponent<ShapedButtonProps> = ({ shape, type = 'outline', sx={}, onClick, disabled, children, ...restProps }) => {
    const colorByType = type === 'outline' ? (theme) => theme.palette.primary.dark : () => '#FFF'
    const color = disabled ? (theme) => theme.palette.text.level9 : (colorByType)
    const backgroundColor = disabled || type === 'outline' ? (theme) => undefined : (theme) => theme.palette.primary.main2
    const borderColor = disabled ? (theme) => theme.palette.borders.level9 : (theme) => theme.palette.borders.level7
    const borderRadius = shape === 'circle' ? '5rem' : '2rem'

    return (
        <Link 
            sx={{
                height: '5rem',
                width: '5rem',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: disabled ? 'none' : 'pointer',
                pointerEvents: disabled ? 'none' : 'auto',
                fontSize: (theme) => theme.typography.pxToRem(45),
                color,
                borderRadius,
                backgroundColor,
                border: (theme) => `.4rem solid ${borderColor(theme)}`,
                ...sx
            }} 
            onClick={onClick}
            {...restProps}
            >
            {children}
        </Link>
    )
}

export const CircleButton: FunctionComponent<BaseShapedButtonProps> = (props) => {
    return <ShapedButton shape={'circle'} {...props } />
}

export const SquareButton: FunctionComponent<BaseShapedButtonProps> = (props) => {
    return <ShapedButton shape={'square'} {...props } />
}