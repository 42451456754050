import { Box, SxProps } from '@mui/material'
import { Theme } from '@mui/system'
import React, { FunctionComponent } from 'react'
import { BaseProps } from '../types'
import Text from './styled/Text'

export interface PanelProps extends BaseProps {
    title?: string | React.ReactNode
    actionView?: React.ReactNode
    contentsSx?: SxProps<Theme>
}
export const Panel: FunctionComponent<PanelProps> = ({ title, children, actionView: ActionView, sx = {}, contentsSx = {}}) => {
    return (
            <Box sx={{
                background: (theme) => theme.palette.primary.white,
                border: (theme) => `1px solid ${theme.palette.borders.level7}`,
                borderTopLeftRadius: '.5rem',
                borderTopRightRadius: '.5rem',
                boxShadow: '0 1px 1px rgb(0 0 0 / 5%)',
                '-webkit-box-shadow': '0 1px 1px rgb(0 0 0 / 5%)',
                ...sx
            }}>
                <Box sx={{
                    padding: '1rem 1.5rem',
                    borderTopLeftRadius: '.5rem',
                    borderTopRightRadius: '.5rem',
                    borderBottom: (theme) => `1px solid ${theme.palette.borders.level7}`,
                    backgroundColor: (theme) => theme.palette.background.level8,
                }}>
                    <Text>{title}</Text>
                    {ActionView != undefined &&
                        <Box>
                            <ActionView />
                        </Box>
                    }
                </Box>
                <Box sx={contentsSx}>
                    {children}
                </Box>
            </Box>      
    )
}

export default Panel