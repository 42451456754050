import axios from 'axios'
import { copyToClipboard, getQueryParams } from '../common/utils'
import { StandardAction, StandardThunkAction } from '../store/store'
import { addError } from './notifications'
import { ADVISOR_DASHBOARD_LOADING, CLIENTS_RETRIEVED, CLIENT_EMAILED_SUCCESS, CLIENT_ADDED, CLIENT_EMAILED_SUCCESS_CLEAR, LOGO_AVAILABLE, QUERY_SHOW_ADD } from "./types/actions"
import { ClientSkinny } from './types/adminUserUtils'
import { Client, ClientUpdateRequest } from './types/clientManagement'

export const CLIENTS_ENDPOINT = '/api/clients'
export const EMAIL_ENDPOINT = `${CLIENTS_ENDPOINT}/email`


export const getAdvisorClients = (simple: boolean = false): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: ADVISOR_DASHBOARD_LOADING, payload: true })
        try {
            const { data } = await axios.get(`${CLIENTS_ENDPOINT}?simple=${simple}`)
            const { success, message, clients, firmLogoAvailable } = data
            dispatch({ type: QUERY_SHOW_ADD, payload: false })
            if(!success) {
                dispatch(addError('', message))

                return
            }
            const { add } = getQueryParams()
            if(add) {
                dispatch({ type: QUERY_SHOW_ADD, payload: true })
            }
            
            const mappedClients = clients.map((client: ClientSkinny) => {
                return { ...client, key: client.id, value: client.name }
            })
            
            dispatch({ type: CLIENTS_RETRIEVED, payload: mappedClients })
            dispatch({ type: LOGO_AVAILABLE, payload: firmLogoAvailable })
        } catch(e: any) {
            dispatch(addError('', `An unknown error occurred trying to retrieve clients`))
        }
    }
}


export const deleteClient = (id: number): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: ADVISOR_DASHBOARD_LOADING, payload: true })
        try {
            const { data } = await axios.delete(`${CLIENTS_ENDPOINT}/${id}`)
            
            const { success, message, clients } = data
            if(!success) {
                dispatch(addError('', message))

                return
            }

            dispatch({ type: CLIENTS_RETRIEVED, payload: clients })
        } catch(e: any) {
            dispatch(addError('', e.toString()))
        }
    }
}

export const addClient = (client: Client): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: ADVISOR_DASHBOARD_LOADING, payload: true })
        try {
            const { data } = await axios.post(`${CLIENTS_ENDPOINT}`, client)
            
            const { success, message, client: createdClient } = data
            if(!success) {
                dispatch(addError('', message))

                return
            }
            const { id, first_name, last_name, annual_income, phone, email, standard_living_risk } = createdClient

            dispatch({ type: CLIENT_ADDED, payload: { id, first_name, last_name, annual_income, phone, email, financial_plan_source: createdClient.standard_living_risk?.financial_plan_source, standard_living_risk } })
        } catch(e: any) {
            dispatch(addError('', e.toString()))
        }
    }
}

export const updateClient = (client: Client): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: ADVISOR_DASHBOARD_LOADING, payload: true })
        try {
            dispatch(updateLocalClient(client))
            const { data } = await axios.put(`${CLIENTS_ENDPOINT}/${client.id}`, client)
            
            const { success, message, client: updatedClient } = data
            if(!success) {
                dispatch(addError('', message))

                return
            }

            dispatch(updateLocalClient({ ...updatedClient, financial_plan_source: updatedClient.standard_living_risk?.financial_plan_source }))
        } catch(e: any) {
            dispatch(addError('', e.toString()))
        }
    }
}


export const getClientQuestionnaireLink = (client: Client): StandardThunkAction => {
    return async(dispatch): Promise<any> => {
        try {
            const { data } = await axios.post(`${CLIENTS_ENDPOINT}/${client.id}/preferences/token`)
            
            const { success, message, link } = data
            if(!success) {
                dispatch(addError('', message))

                return
            }
            copyToClipboard(`${window.location.protocol}//${window.location.host}${link}`)

            return link
        } catch(e: any) {
            dispatch(addError('', e.toString()))
        }
    }
}

export const getClientFinancialPlanLink = (client: Client): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            copyToClipboard(`${window.location.protocol}//${window.location.host}/software/clients/${client.id}/plan`)
        } catch(e: any) {
            dispatch(addError('', e.toString()))
        }
    }
}

export const emailClientLink = (clientId: number): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: ADVISOR_DASHBOARD_LOADING, payload: true })
        try {
            const { data } = await axios.post(EMAIL_ENDPOINT, { clientId })
            const { success, message } = data

            if(!success) {
                dispatch(addError('', message))

                return
            }

            dispatch({ type: CLIENT_EMAILED_SUCCESS, payload: true })
        } catch(e: any) {
            dispatch({ type: CLIENT_EMAILED_SUCCESS, payload: true })
        }
    }
}

export const clearEmailSuccess = (): StandardAction => {
    return { type: CLIENT_EMAILED_SUCCESS_CLEAR }
}

const updateLocalClient = ({ id, first_name, last_name, annual_income, phone, email, financial_plan_source, portfolio_manager, portfolio_manager_client_id }: ClientUpdateRequest): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        const { clientManagement } = getStore()
        const { clients } = clientManagement
        const updateClients = clients.map((client: Client) => {
            if(client.id == id) {
                return { ...client, id, first_name, last_name, annual_income, phone, email, portfolio_manager, portfolio_manager_client_id, standard_living_risk: { ...client.standard_living_risk, financial_plan_source } }
            }

            return client
        })

        dispatch({ type: CLIENTS_RETRIEVED, payload: updateClients })
    }
}
