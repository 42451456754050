import React, { Component } from 'react'
import QuestionTable from './QuestionTable'
import Question, { QuestionSelection } from './Question'
import { connect } from 'react-redux'
import RiskPage, { BaseRiskPageProps } from './RiskPage'

export interface RiskAversionProps extends BaseRiskPageProps {
    annualIncome: number
    question1Value: number
    question2Value: number
    question3Value: number
    question4Value: number
    question5Value: number
    footer: React.ReactNode
    enterprise: boolean
    riskAnalyzer: boolean
    readOnly: boolean
    onChoiceSelected: (selection: QuestionSelection) => void
}

export interface RiskAversionState {
    choiceA1: number | string
    choiceA2: number | string
    choiceB1: number | string
    choiceB2: number | string
}

class RiskAversion extends Component<RiskAversionProps, RiskAversionState> {
    constructor(props: RiskAversionProps) {
        super(props)
        this.state = {
            choiceA1: 0,
            choiceA2: 0,
            choiceB1: 0,
            choiceB2: 0,
        }
    }

    componentDidMount() {
        this.updateChoiceRiskValues()
    }

    componentDidUpdate(prevProps: RiskAversionProps) {
        const { annualIncome: prevAnnualIncome } = prevProps
        const { annualIncome } = this.props
        
        if(prevAnnualIncome !== annualIncome) {
            this.updateChoiceRiskValues()
        }
    }

    updateChoiceRiskValues = () => {
        const { annualIncome } = this.props

        const choiceARisk1 = Math.round(annualIncome / 200).toLocaleString();
        const choiceARisk2 = Math.round(32 / 40 * annualIncome / 200).toLocaleString();
        const choiceBRisk1 = Math.round(77 / 40 * annualIncome / 200).toLocaleString();
        const choiceBRisk2 = Math.round(2 / 40 * annualIncome / 200).toLocaleString();

        this.setState({ choiceA1: choiceARisk1 , choiceA2: choiceARisk2, choiceB1: choiceBRisk1, choiceB2: choiceBRisk2 })
    }

    getChoiceQuestions = (firstPercentage, secondPercentage) => {
        const {  choiceA1: choiceARisk1 , choiceA2: choiceARisk2, choiceB1: choiceBRisk1, choiceB2: choiceBRisk2 } = this.state

        const choiceAText = this.getChoiceRiskQuestion(firstPercentage, secondPercentage, choiceARisk1, choiceARisk2)
        const choiceBText = this.getChoiceRiskQuestion(firstPercentage, secondPercentage, choiceBRisk1, choiceBRisk2)

        return { choiceAText, choiceBText }
    }

    getChoiceRiskQuestion = (firstPercentage, secondPercentage, riskOne, riskTwo) => {
        return <div>{firstPercentage}% chance to win $<span>{riskOne}</span>,<br />{secondPercentage}% chance to win $<span>{riskTwo}</span>.</div>
    }

    render() {
        const { question1Value, question2Value, question3Value, question4Value, question5Value, readOnly, onComponentSize } = this.props

        return (
            <RiskPage readOnly={readOnly} footer={this.props.footer} onComponentSize={onComponentSize}>
                <QuestionTable title='Risk Aversion'>
                    <Question questionNumber={1} question={'Between the following two options, which do you prefer?'} {...this.getChoiceQuestions(50, 50)} selectedChoice={question1Value} onChoiceSelected={this.props.onChoiceSelected} />
                    <Question questionNumber={2} question={'Between the following two options, which do you prefer?'} {...this.getChoiceQuestions(60, 40)} selectedChoice={question2Value} onChoiceSelected={this.props.onChoiceSelected} />
                    <Question questionNumber={3} question={'Between the following two options, which do you prefer?'} {...this.getChoiceQuestions(70, 30)} selectedChoice={question3Value} onChoiceSelected={this.props.onChoiceSelected} />
                    <Question questionNumber={4} question={'Between the following two options, which do you prefer?'} {...this.getChoiceQuestions(80, 20)} selectedChoice={question4Value} onChoiceSelected={this.props.onChoiceSelected} />
                    <Question questionNumber={5} question={'Between the following two options, which do you prefer?'} {...this.getChoiceQuestions(90, 10)} selectedChoice={question5Value} onChoiceSelected={this.props.onChoiceSelected} />
                </QuestionTable>
            </RiskPage>
        )
    }

}

const mapStateToProps = ({ riskPreferences }) => {
    const { riskAversion, annualIncome } = riskPreferences
    
    return {
        ...riskAversion,
        annualIncome,
    }
}

export default connect(mapStateToProps)(RiskAversion)