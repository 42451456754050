import axios from 'axios';
import React, { FunctionComponent } from 'react';

import { AuthContext, AuthContextProps, AuthenticationParams } from "../context";
import { login, logout, registeredLogin } from '../../actions/authentication';
import { LoginResponse, Authorization } from '../..//actions/types/responses';
import { AuthenticatedUser, RefreshResponse, UserDetails } from '../../actions/types/users';
import { getUserDetails } from '../../actions/session';
import StoreProvider from '../../store/StoreProvider';
import { safeParseJSONObject } from '../utils';
const store = StoreProvider.getStore()


const AUTH = 'AUTH'
const USER = 'USER'
const USER_DETAILS = 'USER_DETAILS'

const initialState: AuthContextProps = {
  authenticated: false,
  loading: true,
  setAuthentication: (authenticationParams: AuthenticationParams) => { },
  performLogin: (email, password): Promise<LoginResponse> | any => { },
  performRegisteredLogin: (sessionId): Promise<LoginResponse> | any => { },
  performLogout: () => {},
  refresh: (): any => { },
  updateLogo: (logoUrl: string): any => { }
};

export interface AuthProviderProps {
  children?: React.ReactNode;
}

export const AuthProvider: FunctionComponent<
AuthProviderProps
> = ({ children }): JSX.Element => {
  const [props, setProps] = React.useState<AuthContextProps>(initialState)
  const [auth, setAuth] = React.useState<Authorization | null | undefined>(localStorage.getItem(AUTH) ? safeParseJSONObject(localStorage.getItem(AUTH) as string, null) : null)
  const [user, setUser] = React.useState<AuthenticatedUser | null |  undefined>(localStorage.getItem(USER) ? safeParseJSONObject(localStorage.getItem(USER) as string, null) : null)
  const [details, setDetails] = React.useState<UserDetails | null |  undefined>(localStorage.getItem(USER_DETAILS) ? safeParseJSONObject(localStorage.getItem(USER_DETAILS) as string, null) : null)
  const [loading, setLoading] = React.useState<boolean>(true);
  const [onAuthUpdated, setOnAuthUpdated] = React.useState<Function | null>(null)
  
  const refresh = React.useCallback(async(): Promise<RefreshResponse> => {
    setLoading(true)
    try {
      const { success, user, details } = await getUserDetails()
    
      if(success) {
        setDetails(details)

        if(user) {
          setUser(user)
          localStorage.setItem(USER, JSON.stringify(user));
        }

        if(details) {
          setDetails(details);
          localStorage.setItem(USER_DETAILS, JSON.stringify(details));
        }

        if(onAuthUpdated) {
          onAuthUpdated();
          setOnAuthUpdated(null);
        }
      }

      setLoading(false)

      return { success: true, details, user };
    } catch(e) {
      setLoading(false)

      return { success: false, errors: [`An unexpected error occured while trying to retrieve user details, please try again.`]};
    }
  }, []);

  if (auth?.access_token) {
    axios.defaults.headers['X-PD-ACCESS-TOKEN'] = auth?.access_token;
  }
  
  React.useEffect(() => {
    if (auth?.access_token) {
      axios.defaults.headers['X-PD-ACCESS-TOKEN'] = auth?.access_token;
    }

    axios.interceptors.response.use(
        response => response,
        error => {
          const {data} = error.response;
          const message = data?.message || ''
          if (message.includes('InvalidToken: ')) {
            performLogout()
            store.dispatch(logout())
          }
          return Promise.reject(error);
      }
    );
    
    refresh()
  }, [auth, refresh])

  const performLogout = React.useCallback(() => {
    setAuth(null)
    setUser(null);
    setDetails(null);
    localStorage.removeItem(USER_DETAILS)
    localStorage.removeItem(USER)
    localStorage.removeItem(AUTH);
    window.location.href = '/login'
  }, [])

  const handleLoginResponse = React.useCallback((response: LoginResponse
    ): LoginResponse => {
    const { authorization, user, details, errors } = response
    if (errors) {
      setLoading(false)

      return response as LoginResponse;
    }

    if (!authorization?.access_token) {
      throw new Error('Token not retrieved');
    }
    if (authorization) {
      localStorage.setItem(AUTH, JSON.stringify(authorization));
      setAuth(authorization)
    }
    if (user) {
      localStorage.setItem(USER, JSON.stringify(user));
      setUser(user)
    }
    if (details) {
      localStorage.setItem(USER_DETAILS, JSON.stringify(details));
      setDetails(details)
    }
    
    setLoading(false)
    return response as LoginResponse;
  },[setAuth, setUser, setDetails, setLoading]);

  const performLogin = React.useCallback(async(email: string, password: string): Promise<LoginResponse> => {
    setLoading(true)
    try {
      const response = await login(email, password)

      return handleLoginResponse(response) as LoginResponse
    } catch(e) {
      setLoading(false)
      return { success: false, errors: [`An unexpected error occured while trying to login, please try again.`]}
    }
  }, [])

  const performRegisteredLogin = React.useCallback(async(sessionId: string): Promise<LoginResponse> => {
    setLoading(true)
    try {
      const response = await registeredLogin(sessionId)
      
      return handleLoginResponse(response) as LoginResponse
    } catch(e) {
      setLoading(false)
      return { success: false, errors: [`An unexpected error occured while trying to login, please try again.`]}
    }
  }, [])

  React.useEffect(() => {
    setProps({
      authenticated: !!auth?.access_token,
      user: user ?? undefined,
      auth: auth ?? undefined,
      details: details ?? undefined,
      loading,
      updateLogo: (logo: string) => {
        setDetails({ ...(details as UserDetails), logo})
      },
      setAuthentication: ({ user, auth, details, onAuthUpdated }) => {
        if(auth) {
          setAuth(auth)
          localStorage.setItem(AUTH, JSON.stringify(auth));
        }
        if(user) {
          setUser(user)
          localStorage.setItem(USER, JSON.stringify(user));
        }
        if(details) {
          setDetails(details)
          localStorage.setItem(USER_DETAILS, JSON.stringify(details));
        }
        if(onAuthUpdated) {
          setOnAuthUpdated(onAuthUpdated);
        }
      },
      performLogout,
      performLogin,
      performRegisteredLogin,
      refresh,
    })
  }, [user, auth, auth?.access_token, details, refresh, performLogin, performLogout, loading])

  return (
    <AuthContext.Provider value={props}>
      {children}
    </AuthContext.Provider>
  );
};
