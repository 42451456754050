import React, { Component } from 'react'

import AssetItem from './AssetItem'
import Text from '../../common/Text'
import { Box, TableCell, TableRow } from '@mui/material'
import { Table } from '../../common/Table'
import { AssetSet } from '../../../actions/types/customAssets'

interface Props {
    onRemoveAsset: (id: number) => void
    items: AssetSet[]
}

class AssetsTable extends Component<Props> {

    constructor(props) {
        super(props)
    }

    render() {
        const { items = []  } = this.props
        
        return (
            <Box>
                <Table
                    headerColumns={[
                        {
                            key: '',
                            sx: {
                                width: '6rem'
                            }
                        },
                        {
                            key: 'Asset',
                            children: <Text>Asset</Text>
                        }
                    ]}
                >
                {items.length == 0 &&
                    <TableRow>
                        <TableCell colSpan={2} align="center">No Items</TableCell>
                    </TableRow>
                }
                {items.map((item) => {
                    return  (<AssetItem key={`${item.id}`} item={item} onRemoveAsset={this.props.onRemoveAsset} />)
                })}
                </Table>
            </Box>
        )
    }
}


export default AssetsTable