import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { TextFieldItem } from '../common/FormField';
import { updateAdvisor } from '../../actions/account';
import Button from '../common/Button';
import { addError, addStatusNotification, updateNotification } from '../../actions/notifications';
import { BaseProps } from '../types';
import { AccountPanel } from './AccountPanel';
import { UpdateAdvisorRequest } from '../../actions/types/requests';
import { StatusNotificationItem } from '../../actions/types/notifications';
import UpdatePasswordComponent from '../Login/UpdatePassword';

interface UpdatePasswordProps extends BaseProps {
    email: string
}

const UpdatePassword: FunctionComponent<UpdatePasswordProps> = ({ email }) => {
    return (
        <AccountPanel
            title={'Update Password'}>
            <UpdatePasswordComponent email={email} skipRedirect skipAuthUpdate />
        </AccountPanel>
    )
}

export default UpdatePassword