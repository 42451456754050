import { Component } from 'react'
import { connect } from 'react-redux';
import { Box, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

import ConfirmModal from '../../common/ConfirmModal'
import { ReduxState } from '../../../reducers';
import { addError } from '../../../actions/notifications';
import { DueDiligenceChartDTO } from '../../../actions/types/dueDiligence'
import { FONT_STYLES_BY_TYPE } from '../../../components/common/styled/Text';

interface ComponentProps {
    visible: boolean
    onConfirm: (chart: DueDiligenceChartDTO) => void
    onCancel: () => void
}

interface ReduxStateProps {
    chart: DueDiligenceChartDTO
}

interface ReduxActionProps {
    addError: (title: string, message: string) => void
}

interface RollingReturnsInputsModalState {
    rolling_returns_window: '1M' | '3M' | '1Y'
}

type RollingReturnsInputsModalProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class RollingReturnsInputsModal extends Component<RollingReturnsInputsModalProps, RollingReturnsInputsModalState> {
    selectedLinePath: any;

    constructor(props: RollingReturnsInputsModalProps) {
        super(props);
        this.state = {
            rolling_returns_window: '1M',
        }
    }

    componentDidUpdate(prevProps: Readonly<RollingReturnsInputsModalProps>, prevState: Readonly<RollingReturnsInputsModalState>, snapshot?: any): void {
        if(prevProps.chart.rolling_returns_window !== this.props.chart.rolling_returns_window) {
            this.setState({rolling_returns_window: this.props.chart.rolling_returns_window || '1M'})
        }
    }

    
    onConfirm = () => {
        if(!this.props.onConfirm) {
            return
        }
        const {rolling_returns_window} = this.state
        this.props.onConfirm({
            ...this.props.chart,
            rolling_returns_window,
        })
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        this.setState({ rolling_returns_window: value as '1M' | '3M' | '1Y' })
    }

    render() {
        const { visible } = this.props
        const { rolling_returns_window } = this.state

        return (
            <ConfirmModal
                visible={visible}
                title={`Rolling Returns`}
                confirmLabel={'Confirm'}
                cancelLabel={'Cancel'}
                onConfirm={this.onConfirm}
                onCancel={this.props.onCancel}>
                <FormLabel sx={{ font: FONT_STYLES_BY_TYPE.text}}>Rolling Lookback (Trading Days)</FormLabel>
                <RadioGroup
                    row
                    aria-label="options"
                    name="options"
                    value={rolling_returns_window}
                    onChange={this.handleChange}
                >
                    <FormControlLabel componentsProps={{ typography: { sx: { font: FONT_STYLES_BY_TYPE.text } } }} value="1M" control={<Radio />} label="Month" />
                    <FormControlLabel componentsProps={{ typography: { sx: { font: FONT_STYLES_BY_TYPE.text } } }} sx={{ font: FONT_STYLES_BY_TYPE.text}} value="3M" control={<Radio />} label="Quarter" />
                    {/* <FormControlLabel componentsProps={{ typography: { sx: { font: FONT_STYLES_BY_TYPE.text } } }} sx={{ font: FONT_STYLES_BY_TYPE.text}} value="1Y" control={<Radio />} label="Year" /> */}
                </RadioGroup>
            </ConfirmModal>
        )
    }
}

const mapStateToProps = ({dueDiligence}: ReduxState) => ({
    chart: dueDiligence.selectedChartItem,
})

export default connect(mapStateToProps, { addError })(RollingReturnsInputsModal)