import { useAuth } from './useAuth';

export const withLogin = (Component: any) => {
  const Wrapper = (props: any) => {
    const { performLogin, performLogout } = useAuth();
    return (
      <Component
        performLogin={performLogin}
        performLogout={performLogout}
        {...props}
        />
    );
  };
  
  return Wrapper;
};