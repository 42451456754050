import { Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import PageContainer from '../../Page/PageContainer';

import { BaseProps } from '../../types';
import { FooterBanner } from './FooterBanners';

export const Page: FunctionComponent<BaseProps>  = ({ children }) => {
    return (
        <PageContainer sx={{ background: 'white'}}>
            <Box sx={{
                width: '100%',
                height: '100%',
                maxWidth: (theme) => theme.breakpoints.up('md') ?  '150rem' : undefined,
                backgroundColor: 'white',
            }}>
                {children}
            </Box>
            <FooterBanner />
        </PageContainer>
    )
}

export default Page