import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Checkbox, Button, Paper, Box } from '@mui/material';
import { Order, ScreenerResultItem } from '../../../actions/types/dueDiligence';
import Text from '../../../components/common/Text';
import { getTickerSymbolAndDescription } from '../../../common/utils';
import { TablePagination } from './TablePagination';
import { ClipLoader } from 'react-spinners';


interface Column {
  id: keyof ScreenerResultItem | 'actions'; // Extend this for the actions column
  label: string;
  minWidth?: number;
  align?: 'right' | 'inherit' | 'center' | 'left';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'actions', label: 'Add To Chart', minWidth: 120, align: 'center' },
  { id: 'symbol_display', label: 'Symbol', minWidth: 100 },
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'fund_category', label: 'Fund Category', minWidth: 170 },
  { id: 'expense_ration', label: 'Expense Ratio', minWidth: 100, format: (value: number) => (value / 100).toFixed(2) + '%'},
  { id: 'distribution_yield', label: 'Distribution Yield', minWidth: 100, format: (value: number) => value.toFixed(2) + '%'},
  { id: 'performance_rank_6mo', label: '6M Perf. %ile', minWidth: 100, format: (value: number) => `${Math.max(1, Math.ceil(value * 100 - 0.01))}%` },
  { id: 'performance_rank_1yr', label: '1YR Perf. %ile', minWidth: 100, format: (value: number) => `${Math.max(1, Math.ceil(value * 100 - 0.01))}%` },
];

interface State {
  data: ScreenerResultItem[];
}

interface Props {
    page: number;
    rowsPerPage: number;
    orderBy: keyof ScreenerResultItem;
    order: Order;
    items: ScreenerResultItem[];
    loading?: boolean;
    onPageChange: (newPage: number) => void;
    onRowsPerPageChange: (value: number) => void;
    onItemToggled: (item: ScreenerResultItem) => void;
    onSortChange: (orderBy: keyof ScreenerResultItem, order: Order) => void;
}

class ScreenerResultTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.items,
    };
  }

  componentDidMount() {
    this.setState({ data: this.props.items });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.items !== this.props.items) {
      this.setState({ data: this.props.items });
    }
  }

  handleRequestSort = (property: keyof ScreenerResultItem) => {
    const isAsc = this.props.orderBy === property && this.props.order === 'asc';
    this.props.onSortChange(property, isAsc ? 'desc' : 'asc');
  };

  handleChangePage = (newPage: number) => {
    this.props.onPageChange(newPage);
  };

  handleChangeRowsPerPage = (value: number) => {
    this.props.onRowsPerPageChange(value);
  };

  handleToggleChecked = (id: string) => {
    const targetItem = this.state.data.find((item) => item.id === id);
    if (!targetItem) return;
    const updatedItem = { ...targetItem, checked: !targetItem.checked };
    const newData = this.state.data.map((item) => {
      if (item.id === id) {
        return updatedItem;
      }
      return item;
    });
    this.props.onItemToggled(updatedItem as ScreenerResultItem);
    this.setState({ data: newData });
  };

  stableSort = (array: ScreenerResultItem[], comparator: (a: ScreenerResultItem, b: ScreenerResultItem) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [ScreenerResultItem, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  getComparator = (order: Order, orderBy: keyof ScreenerResultItem): (a: ScreenerResultItem, b: ScreenerResultItem) => number => {
    return order === 'desc'
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
  };

  render() {
    const { order, orderBy, rowsPerPage, page } = this.props;
    const { data } = this.state;
    const { loading } = this.props;

    // Custom sort implementation
    const sortedData = this.stableSort(data, this.getComparator(order, orderBy));

    // Paginate sorted data
    const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
      const { symbol } = getTickerSymbolAndDescription(item as any);
      return { ...item, symbol_display: symbol };
    });

    return (
      <Paper sx={{ width: '100%', overflow: 'hidden', border: '1px solid #000000', position: 'relative'  }}>
      {loading &&
          <Box sx={{ display: 'flex', backgroundColor: '#FFF', opacity: 0.4, position: 'absolute', justifyContent: 'center', alignItems: 'center', top: 0, left: 0, right: 0, bottom: 0, zIndex: 500 }}>
              <ClipLoader />
          </Box>
      }
        <TableContainer sx={{ maxHeight: '80rem', position: 'relative' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align || 'left'}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.id !== 'actions' ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={() => this.handleRequestSort(column.id as keyof ScreenerResultItem)}
                      >
                        <Text>{column.label}</Text>
                      </TableSortLabel>
                    ) : (
                        <Text>{column.label}</Text>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Text>No Data</Text>
                  </TableCell>
                </TableRow>
              )}
              {paginatedData.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id as keyof ScreenerResultItem];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'actions' ? (
                            <Checkbox
                              checked={!!row.checked}
                              onChange={() => this.handleToggleChecked(row.id)}
                            />
                          ) : column.format && typeof value === 'number' ? <Text>{column.format(value)}</Text> : <Text>{value}</Text>}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', pr: '2.5rem' }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    );
  }
}

export default ScreenerResultTable;
