import React from 'react';
import { Styles, withStyles } from '@mui/styles';
import FeatureCard from '../common/FeatureCard';
import CopyCard from '../common/CopyCard';
import QuotesCard from './QuotesCard';
import Page from '../common/Page';
import { BaseProps } from '../../types';
import { ScheduleMeetingButton } from '../common/ScheduleMeetingButton';
import { Box } from '@mui/material';
import JsonLD from '../../common/JsonLD';
import SocialMetaTags from '../../common/SocialMetaTags';

const videoImage = require('../assets/video.png')
const videoImageWebp = require('../assets/video.webp')

export const styles: Styles<any, any> = (theme) => {
    return {
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingTop: '5em',
            paddingBottom: '7em',
            [theme.breakpoints.up('md')]: {
                height: '60em',
                paddingTop: 'unset',
                paddingBottom: 'unset',
            },
        },
        mainTitle: {
            font: `normal normal bold ${theme.typography.pxToRem(88)}/${theme.typography.pxToRem(80)} Montserrat`,
            letterSpacing: 0,
            color: theme.palette.primary.main,
            marginBottom: 15,
            marginTop: 0,
            [theme.breakpoints.up('md')]: {
                font: `normal normal bold ${theme.typography.pxToRem(88)}/${theme.typography.pxToRem(80)} Montserrat`,
            },
        },
        copyHeader: {
            font: `normal bold ${theme.typography.pxToRem(50)}/${theme.typography.pxToRem(57)} Montserrat`,
            [theme.breakpoints.up('md')]: {
                font: `normal normal bold ${theme.typography.pxToRem(40)}/${theme.typography.pxToRem(47)} Montserrat`,
            },
            color: theme.palette.primary.main,
            borderBottom: `3px solid ${theme.palette.borders.level6};`,
            textDecoration: 'none !important',
        },
        copy: {
            font: `normal normal 300 ${theme.typography.pxToRem(28)}/${theme.typography.pxToRem(38)} Montserrat`,
            letterSpacing: 0,
            color: theme.palette.primary.dark,
            marginTop: 15,
            marginBottom: 15,
            maxWidth: '25em',
        },
        leftCard: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
                alignItems: 'flex-end',
                paddingRight: '5em',
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: '1.5em',
            },
        },
        rightCard: {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
        },
        videoCard: {
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
        },
        videoImage: {
            width: '100%',
            height: '60em',
            [theme.breakpoints.down('sm')]: {
                height: '45em',
            },
            objectFit: 'cover',
        },
        videoOverlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: 'black',
            opacity: .2,
        },
        whiteText: {
            font: `font: normal normal 300 ${theme.typography.pxToRem(18)}/${theme.typography.pxToRem(28)} Montserrat;`,
            color: 'white',
        },
        videoText: {
            position: 'absolute',
        },
        playButton: {
            marginTop: 20,
            height: '5.3em',
            width: '5.3em',
            borderRadius: '5.3em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            border: `2px solid ${theme.palette.button.invert}`,
            '& div': {
                width: 0,
                height: 0,
                borderTop: '15px solid transparent',
                borderBottom: '15px solid transparent',
                borderLeft: '15px solid white',
                marginLeft: 5,
            }
        },
        joinListInput: { 
            position: 'absolute',
            top: 9,
            left: 10,
            width: '10em',
            bottom: 5,
            zIndex: 1,
            color: 'black',
            borderStyle: 'hidden',
            borderColor: 'transparent',
            fontSize: theme.typography.pxToRem(25),
            "&:focus": {
                borderStyle: 'hidden',
                borderColor: 'transparent',
                outline: 'none',
            }
        }
    }
}

class Home extends React.Component <BaseProps> {

    render() {
        const { classes = { } } = this.props

        return (
            <Page>
                <JsonLD />
                <SocialMetaTags />
                <FeatureCard 
                    sx={{
                        background: (theme) => theme.palette.secondary.main2,
                    }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <div className={classes.titleContainer}>
                            <h1 className={classes.mainTitle}>Simplifying<br />Life for<br />Advisors</h1>
                            <div className={classes.copy}>Portfolio Designer lets advisors build highly personalized client portfolios and manage underlying investments in just a few simple steps.</div>
                            <ScheduleMeetingButton />
                        </div>
                    </Box>
                    <div className={[classes.rightCard, classes.videoCard].join(' ')}>
                        <picture>
                            <source type="image/webp" srcSet={videoImageWebp}></source>
                            <img className={classes.videoImage} src={videoImage} alt={'Abstract image with glowing blue overlay and directional arrows symbolizing navigation and forward movement in advisor services'}></img>
                        </picture>
                        <div className={classes.videoOverlay}></div>
                    </div>
                </FeatureCard>
                
                <CopyCard 
                    title={<span>Goals-Based Portfolios…Simplified</span>}
                    copy={'Our streamlined client financial plans are run through a simple yet sophisticated asset-liability matching engine to generate hyper-personalized portfolios in seconds.'}
                    imageSource={require('../assets/forecasting.png')}
                    imageWebp={require('../assets/forecasting.webp')}
                    imageAlt="Isometric illustration of financial planning tools with charts, graphs, and a notepad on a desk."
                    copyPosition={'left'}
                />
                <CopyCard 
                    title={'Easily Build Model Portfolios'}
                    copy={'Our risk and analytics engine helps you build robust portfolios that can be deployed across your client base through the goals-based portfolio designer.'}
                    imageSource={require('../assets/goals.png')}
                    imageWebp={require('../assets/goals.webp')}
                    imageAlt="Digital illustration showing a 3D pie chart and bar graphs representing model portfolio analytics"
                    copyPosition={'right'}
                />
                <CopyCard 
                    title={'A Better Client Risk Assessment'}
                    copy={'Our behavioral risk assessment provides a more realistic view of human risk preferences than traditional risk tolerance tools, helping advisors build the most appropriate portfolios for their clients.'}
                    imageSource={require('../assets/bra.png')}
                    imageWebp={require('../assets/bra.webp')}
                    imageAlt="Conceptual illustration of a compass and gauge for assessing investment risks and client preferences."
                    copyPosition={'left'}
                />
                <QuotesCard />
            </Page>
        )
    }
}

export default withStyles(styles)(Home)