import React, { FunctionComponent } from 'react'
import { Table, TableProps } from '../common/Table'

interface QuestionTableProps extends TableProps {
    title: string
}

export const QuestionTable: FunctionComponent<QuestionTableProps>  = ({ title, children }) => {
    return (
        <Table
            sx={{
                //@ts-ignore
                border: (theme) => `1px solid ${theme.palette.borders.level7}`,
                minWidth: '76rem',
                '& td': {
                    border: 'unset',
                },
            }}
            headProps={{ sx: { 
                border: 'unset',
            }}}
            headerColumns={[{
                colSpan: 2,
                children: title,
                sx: {
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    color: (theme) => theme.palette.primary.main,
                    fontSize: (theme) => theme.typography.pxToRem(40),
                    textTransform: 'unset',
                    border: (theme) => `1px solid ${theme.palette.borders.level7}`,
                    borderBottom: (theme) => `1px solid ${theme.palette.borders.level7}`,
                }
            }]}>
                {children}
            
        </Table>
    )
}


export default QuestionTable