import React, { Component } from 'react'
import { onSLRPage } from '../../actions/standardLivingRisk';
import { connect } from 'react-redux';
import TabFooter from '../Software/TabFooter';
import { BaseProps } from '../types';
import { Box, SxProps } from '@mui/material';
import { Text } from '../common/styled/Text';
import { onComponentSizeType, SizeAwareBox } from '../common/styled/SizeAwareBox';
import { Theme } from '@emotion/react';

export interface SLRBaseProps {
    onComponentSize?: onComponentSizeType
}

interface CardProps extends BaseProps {
    onComponentSize?: onComponentSizeType
    title: string | React.ReactNode
    hideHeader?: boolean,
    hideFooter?: boolean,
    containerSx?: SxProps<Theme>;
}

class Card extends Component <CardProps> {

    render() {
        const { title, children, hideHeader = false, hideFooter = false, sx = { }, containerSx = {}, onComponentSize } = this.props

        return (
            <div>
                <SizeAwareBox onComponentSize={onComponentSize} sx={sx}>
                    {!hideHeader &&
                        <>
                            <Text type='h2'>{title}</Text>
                            <hr />
                        </>
                    }
                    <Box sx={containerSx}>
                        {children}
                    </Box>
                    {!hideFooter &&    
                        <TabFooter /> 
                    }
                </SizeAwareBox>
            </div>
        )
    }

}

const mapStateToProps = ({ session, standardLivingRisk }) => {
    const { activeIndex } = standardLivingRisk
    const { views } = session

    return { activeIndex, views }
}

export default connect(mapStateToProps, { onSLRPage })(Card)