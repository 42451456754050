import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import ClipLoader from "react-spinners/ClipLoader";

import Alert, { AlertProps } from '../common/Alert'
import { BaseProps } from '../types';
import { Box } from '@mui/material';

interface StatusNotificationProps extends AlertProps, BaseProps {
    showSpinner?: boolean
}
export default class StatusNotification extends Component <StatusNotificationProps> {

    componentDidUpdate(prevProps) {
        const { visible: prevVisible } = prevProps
        const { visible } = this.props

        if(!prevVisible && visible) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }

    render() {
        const { children, className, titleIcon, sx = {}, showSpinner = true, ...restProps } = this.props
        
        return (
            <Alert {...restProps} sx={{
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: '#0004 !important',
                backgroundColor: 'white !important',
                zIndex: '10001 !important',
                ...sx,
            }} titleIcon={titleIcon ? titleIcon : () => <FontAwesomeIcon color={'#fff'} icon={faBan} />}>
                {children}
               {showSpinner && 
                    <Box sx={{
                        display: 'flex',
                        flex: 1,
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '1rem',
                    }}>
                        <ClipLoader color={'#297ea1'} loading={true} size={35} />
                    </Box>
                }
            </Alert>
        )
    }

}