import React, { FunctionComponent,  } from 'react'

import { useAuth } from '../../../common/hooks/useAuth'
import { logoHorizontal } from '../../../assets'
import { StandardImage, ImageProps } from '../../../components/common/styled/StandardImage';


export const AccountLogo: FunctionComponent<ImageProps> = ({ alt, ...props}) => {
    const { details } = useAuth()
    const { logo } = details || { }
    return (
        <StandardImage {...props} alt={alt || 'Account Logo'} src={logo|| logoHorizontal} />
    )
}