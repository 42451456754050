import { Box } from '@mui/material';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { absoluteRiskUpdate, relativeRiskUpdate, liquidityUpdate, taxConcernsUpdate, legalAndRegulatoryFactorsUpdate, uniqueCircumstancesUpdate, updateTimeHorizon, getInvestmentPolicyStatement } from '../../actions/investmentPolicyStatement';
import { hasClientIdChanged } from '../../common/utils';
import Button from '../common/Button';
import { withClientParameter } from '../common/withClientParameter';
import TabFooter from '../Software/TabFooter';
import { LineItem } from '../ClientFinancialPlan/LineItem';
import { LineTextArea, LineTextAreaOnChange } from '../ClientFinancialPlan/LineTextArea';

interface InvestmentPolicyStatementProps {
    getInvestmentPolicyStatement: () => void
    absoluteRiskUpdate: LineTextAreaOnChange
    relativeRiskUpdate: LineTextAreaOnChange
    liquidityUpdate: LineTextAreaOnChange
    taxConcernsUpdate: LineTextAreaOnChange
    legalAndRegulatoryFactorsUpdate: LineTextAreaOnChange
    uniqueCircumstancesUpdate: LineTextAreaOnChange
    updateTimeHorizon: LineTextAreaOnChange
    absolute_risk: string
    relative_risk: string
    liquidity: string
    tax_concerns: string
    legal_and_regulatory_factors: string
    unique_circumstances: string
    time_horizon: number
}


class InvestmentPolicyStatement extends Component <InvestmentPolicyStatementProps> {
    containerRef: any
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getInvestmentPolicyStatement()
    }

    componentDidUpdate(prevProps) {
        if(hasClientIdChanged(prevProps, this.props)) {
            this.props.getInvestmentPolicyStatement()
        }
    }

    onPrint = () => {
      window.open(`${window.location.pathname}/print`, '_blank');
    }

    render() {
        const { absoluteRiskUpdate, relativeRiskUpdate, liquidityUpdate, taxConcernsUpdate, legalAndRegulatoryFactorsUpdate, uniqueCircumstancesUpdate, updateTimeHorizon,
            absolute_risk, relative_risk, liquidity, tax_concerns, legal_and_regulatory_factors, unique_circumstances, time_horizon } = this.props

        return (
                <Box ref={view => this.containerRef = view } sx={{ height: '80rem', position: 'relative' }}>
                    <LineItem number={1} sx={{ width: '750px' }} label={'Time Horizon'} value={time_horizon} onChange={this.props.updateTimeHorizon} extendedInput />
                    <LineItem number={2} sx={{ width: '750px' }} label={'Absolute Risk'} value={absolute_risk} onChange={absoluteRiskUpdate} alphaNumeric extendedInput />
                    <LineTextArea number={3} label={'Relative Risk'} value={relative_risk} onChange={relativeRiskUpdate} extendedInput />
                    <LineTextArea number={4} label={'Liquidity'} value={liquidity} onChange={liquidityUpdate} extendedInput />
                    <LineTextArea number={5} label={'Tax Concerns'} value={tax_concerns} onChange={taxConcernsUpdate} extendedInput />
                    <LineTextArea number={6} label={'Legal And Regulatory Factors'} value={legal_and_regulatory_factors} onChange={legalAndRegulatoryFactorsUpdate} extendedInput />
                    <LineTextArea number={7} label={'Unique Circumstances'} value={unique_circumstances} onChange={uniqueCircumstancesUpdate} extendedInput />
                    
                    <Box sx={{
                        width: '75rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                    }}>
                        <TabFooter
                            title={'IPS'}
                            showAddProfileHistory={false}
                            hidePageInfo
                            additionalActions={<Button sx={{ minHeight: 'unset', width: '15rem', height: '4.5rem' }} title={'Print'} onClick={this.onPrint} />}
                        />
                        
                    </Box>
                </Box>
        )
    }

}

const mapStateToProps = ({ investmentPolicyStatement }) => {
    const { absolute_risk, relative_risk, liquidity, tax_concerns, legal_and_regulatory_factors, unique_circumstances, time_horizon } = investmentPolicyStatement

    return { absolute_risk, relative_risk, liquidity, tax_concerns, legal_and_regulatory_factors, unique_circumstances, time_horizon }
}

export default withClientParameter(connect(mapStateToProps, { getInvestmentPolicyStatement, absoluteRiskUpdate, relativeRiskUpdate, liquidityUpdate, taxConcernsUpdate, legalAndRegulatoryFactorsUpdate, uniqueCircumstancesUpdate, updateTimeHorizon })(InvestmentPolicyStatement))