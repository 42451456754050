import { combineReducers } from 'redux'

import PortfolioMapToolReducers, { PortfolioMapToolState } from './PortfolioMapToolReducers'
import RiskPreferencesReducer, { RiskPreferencesState } from './RiskPreferencesReducer'
import InvestmentPortfolio, { InvestmentPortfolioState } from './InvestmentPortfolioReducer'
import ClientManagementReducer, { ClientManagementState } from './ClientManagementReducer'
import StandardLivingRiskReducer, { StandardLivingRiskState } from './StandardLivingRiskReducer'
import CapitalMarketAssumptionsReducer, { CapitalMarketAssumptionState } from './CapitalMarketAssumptionsReducer'
import ProfileHistoryReducer, { ProfileHistoryState } from './ProfileHistoryReducer'
import SessionReducer, { SessionState } from './SessionReducer'
import UserManagementReducer, { UserManagementState } from './UserManagementReducer'
import NotificationsReducer from './NotificationsReducer'
import InvestmentPolicyStatementReducer, { InvestmentPolicyStatementState } from './InvestmentPolicyStatementReducer'
import ActivationCodesReducer, { ActivationCodesState } from './ActivationCodesReducer'
import CopyReducer, { CopyState } from './CopyReducer'
import PortfolioDesignerReducer, { PortfolioDesignerState } from './PortfolioDesignerReducer'
import PortfolioManagerReducer, { PortfolioManagerState } from './PortfolioManagerReducer'
import ForecastReducer, { ForecastState } from './ForecastReducer'
import { NotificationState } from './NotificationsReducer'
import { StandardThunkAction } from '../store/store'
import FinancialPlanAssumptionsReducer, { FinancialPlanAssumptionsState } from './FinancialPlanAssumptionsReducer'
import DueDiligenceReducer, { DueDiligenceState } from './DueDiligenceReducer'
import MarketDashboardReducer, { MarketDashboardState } from './MarketDashboardReducer'
import PerformanceAttributionReducer, { PerformanceAttributionState } from './PerformanceAttributionReducer'
import SimplifyRiskAnalyzer, { SimplifyRiskAnalyzerState } from './SimplifyRiskAnalyzer'

export interface ReduxState {
    riskPreferences: RiskPreferencesState;
    investmentPortfolio: InvestmentPortfolioState;
    clientManagement: ClientManagementState;
    standardLivingRisk: StandardLivingRiskState;
    capitalMarketAssumptions: CapitalMarketAssumptionState;
    profileHistory: ProfileHistoryState;
    session: SessionState;
    notifications: NotificationState;
    activationCodes: ActivationCodesState;
    investmentPolicyStatement: InvestmentPolicyStatementState
    userManagment: UserManagementState;
    portfolioMapTool: PortfolioMapToolState;
    copy: CopyState;
    portfolioDesigner: PortfolioDesignerState;
    portfolioManager: PortfolioManagerState;
    forecast: ForecastState;
    financialPlanAssumptions: FinancialPlanAssumptionsState;
    dueDiligence: DueDiligenceState;
    marketDashboard: MarketDashboardState;
    performanceAttribution: PerformanceAttributionState;
    simplifyRiskAnalyzer: SimplifyRiskAnalyzerState;
}

export interface ReduxAction { type: string, payload: any };
export type GetStateFunction = () => ReduxState;
export type DispatchFunction = (action: ReduxAction | StandardThunkAction<any>) => any;

export const softwareReducers = combineReducers({
    riskPreferences: RiskPreferencesReducer,
    investmentPortfolio: InvestmentPortfolio,
    clientManagement: ClientManagementReducer,
    standardLivingRisk: StandardLivingRiskReducer,
    capitalMarketAssumptions: CapitalMarketAssumptionsReducer,
    profileHistory: ProfileHistoryReducer,
    session: SessionReducer,
    notifications: NotificationsReducer,
    activationCodes: ActivationCodesReducer,
    investmentPolicyStatement: InvestmentPolicyStatementReducer,
    userManagment: UserManagementReducer,
    portfolioMapTool : PortfolioMapToolReducers,
    copy: CopyReducer,
    portfolioDesigner: PortfolioDesignerReducer,
    portfolioManager: PortfolioManagerReducer,
    forecast: ForecastReducer,
    financialPlanAssumptions: FinancialPlanAssumptionsReducer,
    dueDiligence: DueDiligenceReducer,
    marketDashboard: MarketDashboardReducer,
    performanceAttribution: PerformanceAttributionReducer,
    simplifyRiskAnalyzer: SimplifyRiskAnalyzer
});

export type SoftwareState = typeof softwareReducers