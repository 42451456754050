export type PortfolioManagerConfigurationType = 'account' | 'portfolio'



export interface OrionPortfolioClient {
    aum: number;
    isActive: boolean;
    representativeName: string;
    representativeNumber: string;
    representativeId: number;
    brokerDealerName: string;
    categoryId: number;
    category: string;
    email: string;
    dob: string;
    id: number;
    name: string;
  }
  
  export interface OrionAssetDetails {
    costBasis: any;
    name: string;
    ticker: string;
    currentShares: number;
    currentValue: number;
    currentPrice: number;
    isManaged: boolean;
    assetClass: string;
    productCategory: string;
    productCategoryAbbreviation: string;
    productId: number;
    accountId: number;
    clientId: number;
    isActive: boolean;
    downloadSymbol: number;
    accountType: string;
    productType: string;
    productSubType: string;
    assetPercentOfAccount: number;
    householdName: string;
    riskCategory: string;
    modelName: string;
    id: number;
  }
  export interface OrionAccountDetails {
    isActive: boolean;
    managementStyle: string;
    currentValue: number;
    fundFamily: string;
    accountStartValue: number;
    clientId: number;
    plan: string;
    planName: string;
    household: string;
    cashBalance: number;
    id: number;
    name: string;
    number: string;
    modelName: string;
    subAdvisor: string;
    accountType: string;
  }

  export class PortfolioManagerError extends Error {
    protected type: string = 'PortfolioManagerError'
    constructor(message: string) {
      super(message)
    }
  }

  export class PortfolioManagerInvalidCredentials extends PortfolioManagerError {
    constructor(portfolioManager: string) {
      super(`We were unable to authenticate with ${portfolioManager} using the provided credentials, please update them in the Account Settings page`)
    }
  }

  export class PortfolioManagerCredentialsMissing extends PortfolioManagerError {
    constructor(portfolioManager: string) {
      super(`We are missing the credentials for ${portfolioManager}, please vist the Account Settings page to add them`)
    }
  }
  export class PortfolioManagerUnexpectedError extends PortfolioManagerError {
    constructor(portfolioManager: string) {
      super(`An unexpected error occurred while trying to connect to ${portfolioManager}, please try again later`)
    }
  }