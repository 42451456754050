import React, { FunctionComponent, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Page } from '../Page';
import { Box } from '@mui/material';
import { BaseProps } from '../types';
import { Panel } from '../common/Panel';
import { Button } from '../common/Button';
import { TextFieldItem } from '../common/FormField';
import { sendPasswordResetEmail as sendPasswordResetEmailAction } from '../../actions/authentication';
import ErrorAlert from '../common/ErrorAlert';
import SuccessAlert from '../common/SuccessAlert';

interface Props extends BaseProps {
    sendPasswordResetEmail: (email: string) => Promise<any>
}

const ForgotPassword: FunctionComponent<Props> = ({ sendPasswordResetEmail }) => {
    const [errors, setErrors] = useState<string[]>([])
    const [message, setMessage] = useState<string>()
    const [email, setEmail] = useState<string>('')
    const hasErrors = errors?.length > 0
    const hasMessage = !!message

    const onClearErrors = useCallback(() => {
        setErrors([])
    }, [])

    const onClearMessage = useCallback(() => {
        setMessage('')
    }, [])

    const onClearAllMessage = useCallback(() => {
        onClearErrors()
        onClearMessage()
    }, [])

    const onLogin = useCallback(async() => {
        onClearAllMessage()
        const { success, message, errors } = await sendPasswordResetEmail(email)

        if(success && message) {
            setMessage(message)
        } if(!success && errors) {
            setErrors(errors)
        } else if(!success) {
            setErrors(['Login was not successful, please try again'])
        }
        
    }, [onClearAllMessage, email]);
    
    return (
        <Page 
            contentsSx={{ 
                justifyContent: 'center',
                marginTop: '10rem',
            }}>
            <Panel
                sx={{ width: '85rem' }}
                title={'Reset Password'}>
                <ErrorAlert
                    sx={{ margin: '1rem' }}
                    visible={hasErrors}
                    wrapChildrenInArray={false}
                    onDismiss={onClearErrors}>
                        <Box sx={{ display: 'flex', flexDirection: 'column '}}>
                            {errors}
                        </Box>
                </ErrorAlert>
                <SuccessAlert
                    sx={{ margin: '1rem' }}
                    visible={hasMessage}
                    onDismiss={onClearMessage}>
                        <Box sx={{ display: 'flex', flexDirection: 'column '}}>
                            {message}
                        </Box>
                </SuccessAlert>
                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '2rem', alignItems: 'center' }}>
                        <Box>
                            <TextFieldItem
                                sx={{ 
                                    width: {
                                        xs: '100%',
                                        md: '35rem'
                                    }
                                }}
                                type={'email'}
                                title={'Email Address'}
                                value={email}
                                onValueChange={setEmail}
                                inline
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2rem'}}>
                                <Button 
                                    title={'Send Password Reset Link'} 
                                    onClick={onLogin}
                                />
                            </Box>
                        </Box>
                    </Box>
            </Panel>
        </Page>
    )
}

export default connect(null, { sendPasswordResetEmail: sendPasswordResetEmailAction })(ForgotPassword)