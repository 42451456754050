import React, { DOMElement, FunctionComponent, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Page } from '../Page';
import { Box, Link as MUILink, Typography } from '@mui/material';
import { BaseProps } from '../types';
import { Panel } from '../common/Panel';
import { Button } from '../common/Button';
import { TextFieldItem } from '../common/FormField';
import { Link, useNavigate } from 'react-router-dom';
import { login as loginAction } from '../../actions/authentication';
import ErrorAlert from '../common/ErrorAlert';
import Text from '../common/styled/Text';
import { LoginResponse } from '../../actions/types/responses';
import { useAuth } from '../../common/hooks/useAuth';
import { getUserDetails } from '../../actions/session';
import { AUTH_CODES_REQUIRING_PASSWORD_UPDATE } from '../../actions/types/authentication';

interface LoginProps extends BaseProps {
    login: (email: string, password: string) => Promise<LoginResponse>
}

const Login: FunctionComponent<LoginProps> = ({ login }) => {
    const navigate = useNavigate()
    const { performLogin, loading } = useAuth()
    const [errors, setErrors] = useState<string[]>([])
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [requiresSubscription, setRequiresSubscription] = useState<boolean>(false)
    const hasErrors = errors?.length > 0
    
    const onClearErrors = useCallback(() => {
        setErrors([])
    }, [setErrors])

    const onLogin = useCallback(async() => {
        const response = await performLogin(email, password)
        const { success, path, errors, requiresSubscription, code, token } = response

        if(!success && errors) {
            if (AUTH_CODES_REQUIRING_PASSWORD_UPDATE.includes(Number(code))) {
                navigate(`/password/update?email=${email}&error_code=${code}&token=${token}`)

                return
            }
            setErrors(errors)
            setRequiresSubscription(requiresSubscription === undefined ? false : requiresSubscription)

            return;
        }

        if(!success) {
            setErrors(['Login was not successful, please try again'])

            return
        }

       if(path) {
            navigate(path)
        }
    }, [email, password]);
    

    const handleKeyDown = React.useCallback(({ key }) => {
        if (key === 'Enter' || key === 'Return') {
            onLogin()
        }
    }, [onLogin])

    return (
        <Page 
            contentsSx={{ 
                justifyContent: 'center',
                marginTop: '10rem',
            }}>
            <Panel
                sx={{ width: '85rem' }}
                title={'Login'}>
                <ErrorAlert
                    title={'Alert'}
                    subtitle={<Typography sx={{ fontSize: 15, display: 'flex', flexDirection: 'row', }}> <Typography sx={{ fontSize: 15, fontWeight: 'bold', marginRight: '1rem', }}>Whoops!</Typography>There were some problems with your input.</Typography>}
                    sx={{ margin: '1rem' }}
                    visible={hasErrors}
                    wrapChildrenInArray={false}
                    onDismiss={onClearErrors}>
                        <Box sx={{ display: 'flex', flexDirection: 'column '}}>
                        {Array.isArray(errors) && errors.map((error: string) => {
                                return <Box>{error}</Box>
                        })}
                        {!Array.isArray(errors) && errors}
                        {requiresSubscription &&
                                <MUILink sx={{
                                    color: (theme) => theme.palette.text.inverted,
                                    textDecoration: 'underline',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    textTransform: 'uppercase',
                                }} component={Link} to={'/signup'}>Subscribe?</MUILink>
                        }
                        </Box>
                </ErrorAlert>
                    <Box onKeyDown={handleKeyDown} sx={{ display: 'flex', flexDirection: 'column', padding: '2rem', alignItems: 'center' }}>
                        <Box>
                            <TextFieldItem
                                sx={{ 
                                    width: {
                                        xs: '100%',
                                        md: '35rem'
                                    }
                                }}
                                type={'email'}
                                title={'Email Address'}
                                value={email}
                                onValueChange={setEmail}
                                autoFocus
                                inline
                            />
                            <TextFieldItem
                                sx={{ 
                                    width: {
                                        xs: '100%',
                                        md: '35rem'
                                    }
                                }}
                                type={'password'}
                                title={'Password'}
                                value={password}
                                onValueChange={setPassword}
                                inline
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2rem'}}>
                                <Button 
                                    title={'Login'} 
                                    sx={{ width: '15rem' }}
                                    onClick={onLogin}
                                    loading={loading}
                                    inlineLoading
                                />
                                <MUILink sx={{
                                    marginLeft: {
                                        xs: '1rem',
                                        md: '2rem',
                                    },
                                    color: '#23527c',
                                    textDecoration: 'underline',
                                    textTransform: 'uppercase',
                                }} component={Link} to={'/password/reset'}><Text>Forgot Your Password?</Text></MUILink>
                            </Box>
                        </Box>
                    </Box>
            </Panel>
        </Page>
    )
}

export default connect(null, { login: loginAction })(Login)