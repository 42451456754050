import { Box, TableCell, TableRow } from '@mui/material'
import React, { Component } from 'react'
import { FundingType, FUNDING_TYPE_OPTIONS, IrregularItem } from '../../actions/types/standardLivingRisk'
import { SelectOption } from '../../common/types'
import { TextField } from '../common/FormField'
import { Select } from '../common/styled/Select'
import { CircleActionButton } from '../common/styled/ShapedActionButton'
import Text, { FONT_STYLES_BY_TYPE } from '../common/styled/Text'

interface IrregularItemProps {
    item: IrregularItem
    startYears: SelectOption[]
    numberOfYears: SelectOption[]
    funding_type?: FundingType
    showFundingType?: boolean
    onChange: (value: IrregularItem) => void
    onDelete: (value: IrregularItem) => void
}


class IrregularItemComponent extends Component <IrregularItemProps>{
    constructor(props) {
        super(props)
    }

    onDescriptionChange = (value: string) => {
        const { item, onChange } = this.props
        if(onChange && item.description !== value) {
            onChange({ ...(item || {}), description: value })
        }
    }

    onAmountChange = (value: any) => {
        const { item, onChange } = this.props
        let amount = value
        if(amount != '') {
            amount = parseInt(amount.replace(/,/g, '').replace(/\D/g,''), 10)
            if(isNaN(amount)) {
                return
            }
        }

        if(onChange && item.value !== amount) {
            onChange({ ...(item || {}), value: amount })
        }
    }

    onFundingTypeChanged = ({ value }: SelectOption) => {
        const { item, onChange } = this.props
        if(onChange) {
            onChange({ ...(item || {}), funding_type: value as FundingType })
        }
    }

    onStartYearChange = ({ value }: SelectOption) => {
        const { item, onChange } = this.props
        if(onChange) {
            onChange({ ...(item || {}), start_year: value as number })
        }
    }

    onNumberOfYearsChange = ({ value }: SelectOption) => {
        const { item, onChange } = this.props
        if(onChange) {
            onChange({ ...(item || {}), number_of_years: value as number })
        }
    }

    onDelete = () => {
        const { item, onDelete } = this.props
        if(onDelete) {
            onDelete(item)
        }
    }

    valueFormatter = (value: string) => {
        if (!value) {
            return value;
        }

        const valueAsNumber = Number(value.toString().replace(/,/g, '').replace(/\D/g,''))
        if(isNaN(valueAsNumber)) {
            return value
        }

        return valueAsNumber.toLocaleString('en-US');
    }

    render() {
        const { item, startYears = [], numberOfYears = [], showFundingType } = this.props
        const { description, value, start_year, number_of_years, funding_type } = item || { }
        const currentYear = new Date().getUTCFullYear()

        const delta = start_year - currentYear
        const numberOfYearsAdjusted = [...numberOfYears].splice(0, numberOfYears.length - delta)
        const numberOfYearAdjusted = number_of_years > numberOfYearsAdjusted.length ? numberOfYearsAdjusted.length : number_of_years
        const selectedFundingType = FUNDING_TYPE_OPTIONS.find(({ value }) => value === funding_type);

        return (

            <TableRow sx={{
                border: (theme) => `1px solid ${theme.palette.borders.level7}`,
                "& td": {
                    padding: '1rem',
                    border: (theme) => `1px solid ${theme.palette.borders.level7}`,
                },
            }}>
                <TableCell>
                    <CircleActionButton action={'remove'} onClick={this.onDelete} />
                </TableCell>
                <TableCell>
                    <TextField sx={{ width: '25rem'}} value={description ? description : ''} onLeave={this.onDescriptionChange} />
                </TableCell>
                {showFundingType &&
                    <TableCell>
                        <Box>
                            <Select sx={{ width: '15rem', marginLeft: '1rem' }} options={FUNDING_TYPE_OPTIONS} value={selectedFundingType} onChange={this.onFundingTypeChanged} />
                        </Box>
                    </TableCell>
                }
                <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Text sx={{ marginRight: '1rem' }}>$</Text><TextField sx={{ width: '15rem'}} value={value} valueFormatter={this.valueFormatter} onLeave={this.onAmountChange}></TextField>
                    </Box>
                </TableCell>
                <TableCell>
                        <Select sx={{ width: '12rem' }} value={{ label: start_year, value: start_year }} options={startYears} onChange={this.onStartYearChange} borderless />
                </TableCell>
                <TableCell>
                    <Select sx={{ width: '12rem' }} value={{ label: numberOfYearAdjusted, value: numberOfYearAdjusted }} options={numberOfYearsAdjusted} onChange={this.onNumberOfYearsChange} borderless />
                </TableCell>
            </TableRow>
        )
    }

}

export default IrregularItemComponent